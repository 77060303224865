import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Table, TableBody, TableCell, TableHead, TableRow,
	Typography, Grid, Button, Dialog, DialogActions,
	DialogContent, DialogTitle, TextField,
	FormControl, FormControlLabel, Radio, RadioGroup, CircularProgress,
	FormHelperText, Toolbar, Switch, FormLabel, FormGroup, MenuItem, TablePagination
} from '@mui/material';
import { fetchSettings, addSettings, updateSettings } from '../../actions/settingsAction';
import TablePaginationActions from "../Dashboard/TablePaginationActions";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { withRouter } from '../../withRouter';
import theme from '../../theme';
import { headerFont } from '../../helpers/Constants';

/**Created common styles for UI.*/
const styles = {
	textStyle: {
		fontFamily: "Futura-Heavy",
		color: "#37474f",
		fontSize: 20,
	},
	addButton: {
		backgroundColor: theme.palette.primary.main,
		color: "white",
		fontFamily: "unicode.futurab",
		borderRadius: "10",
		textTransform: 'none',
	},
	cancelButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		fontFamily: "unicode.futurab",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(143, 163, 173, 0.8)',
		}
	},
	editButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		fontFamily: "unicode.futurab",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(143, 163, 173, 0.8)',
		}
	},
	fontCellHeader: {
		fontFamily: "Futura-Book",
		color: "#fff",
		fontWeight: 900,
		padding: '14px 20px 14px 0px',

	},
	fontTableCellBody: {
		fontFamily: "Futura-Medium-bt",
		color: "#37474f",
		padding: '14px 20px 14px 0px',
	},
	inputStyle: {
		fontFamily: "Futura-Book",
		fontSize: 14
	},
	labelStyle: {
		fontFamily: "Futura-Book",
		fontSize: 14
	},
	inputStyleForSearchText: {
		fontSize: "15px !important",
		padding: "5px !important",
		width: 250,
	},
	inputRoot: {
		borderRadius: "0px !important"
	},
	inputLabel: {
		color: "#4B86E3"
	},
	dialogPaper: {
		backgroundColor: "rgba(0,0,0,0)",
		boxShadow: "none",
		overflow: "hidden"
	}
};

/**
 * @class
 *
 * Class representing Settings component
 *
 */
class Settings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			name_error: false,
			code: "",
			code_error: false,
			value: false,
			value_error: false,
			description: "",
			description_error: false,
			status: "ACTIVE",
			status_error: false,
			settings: [],
			dialogOpen: false,
			id: null,
			mode: "",
			search_text: "",
			rowsPerPage: 10,
			page: 0,
			isLoading: false,
			valueType: "BOOLEAN",
			sortBy: "NAME_ASC"
		}
	}

	/**This is a component life cycle method and used to assigning props values to state variable.*/
	static getDerivedStateFromProps(props, state) {
		if (props.settings !== state.settings) {
			return {
				settings: props.settings.filter(x => x.isVisible === true),
			}
		}
		return null;
	};

	/** This is a component life cycle method and used to fetch datas*/
	async componentDidMount() {
		/** fetching Sttings details. */
		await this.props.dispatch(fetchSettings())
	};

	/**
		 * @function
		 *
		 * function representing table pagination.
		 * @param {Object} _event
		 * @param {Object} newPage
		 */
	handleChangePage = (_event, newPage) => {
		this.setState({
			page: newPage
		});
	};

	/**
		 * @function
		 *
		 * function representing table pagination rows.
		 * @param {Object} event
		 */
	handleChangeRowsPerPage = (event) => {
		this.setState({
			page: 0, rowsPerPage: parseInt(event.target.value, 10)
		});
	};

	/**
	* @function 
	* This function representing add or edit Setting dialog screen will open.
	* @param {Object} item The item json object
	* @param {String} mode The mode string
	*/
	handleClick = (item, mode) => {
		if (mode === "ADD") {
			this.setState({ mode: mode, dialogOpen: true })
		}
		else {
			this.setState({
				id: item.id,
				dialogOpen: true,
				mode: mode,
				name: item.name,
				name_error: false,
				code: item.code,
				code_error: false,
				value: item.valueType === "BOOLEAN" ? item.value === "true" ? true : false : item.value,
				valueType: item.valueType,
				value_error: false,
				description: item.description,
				description_error: false,
				status: item.status,
				status_error: false,
			})
		}
	};

	/**
	 * @function 
	 * This function representing close add or edit Stting dialog screen.
	 */
	handleClose = () => {
		this.setState({
			dialogOpen: false,
			id: null,
			name: "",
			name_error: false,
			code: "",
			code_error: false,
			value: false,
			value_error: false,
			description: "",
			status: "ACTIVE",
			status_error: false,
			mode: "",
			valueType: "BOOLEAN"
		})
	};

	/**
	* @function 
	* This function representing add or edit Setting.
	* @param {String} mode The mode string
	*/
	handleSubmit = (mode) => {

		let { name, code, value, description, status, valueType } = this.state;

		let isError = false;
		if (name === "" || name === null) {
			this.setState({ name_error: true })
			isError = true;
		}

		if (code === "" || code === null) {
			this.setState({ code_error: true })
			isError = true;
		}
		if (value === "" || value === null) {
			this.setState({ value_error: true })
			isError = true;
		}

		if (status === "" || status === null) {
			this.setState({ status_error: true, })
			isError = true;
		}

		if (isError === false) {
			let sttingsObj = {};
			sttingsObj.name = name;
			sttingsObj.code = code;
			sttingsObj.value = valueType === "BOOLEAN" ? value === true ? "true" : "false" : value;
			sttingsObj.description = description;
			sttingsObj.status = status;
			sttingsObj.valueType = valueType;

			if (mode === "ADD") {
				this.setState({ isLoading: true })
				this.props.dispatch(addSettings(this, sttingsObj))
			}
			else {
				this.setState({ isLoading: true })
				this.props.dispatch(updateSettings(this, sttingsObj, code))
			}
		}
	};

	/** rendering settings information. */
	render() {
		const { page, rowsPerPage } = this.state;
		let settings = this.state.sortBy === "NAME_ASC" ? this.state.settings.sort((a, b) => a.name.localeCompare(b.name)) : this.state.settings.sort((a, b) => b.name.localeCompare(a.name));
		let result = this.state.search_text !== '' ? settings.filter(x => (x.name).toString().toLowerCase().includes((this.state.search_text).toString().toLowerCase())) : settings
		return (
			<div style={{ paddingLeft: 2, paddingRight: 10 }}>
				<Grid container spacing={0} style={{ marginTop: 5, }}>
					<Grid item xs={12} sm={12}>
						<Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
							<div>
								<Typography style={{  color: theme.palette.secondary.light, fontWeight: headerFont.fontWeight, fontFamily: headerFont.fontFamily, fontSize: headerFont.fontSize, }}>Settings</Typography>
							</div>
							<TablePagination
								rowsPerPageOptions={[20]}
								component="div"
								count={result.length}
								rowsPerPage={this.state.rowsPerPage}
								page={this.state.page}
								slotProps={{
									select: {
										inputProps: { 'aria-label': 'rows per page' },
										native: true,
									}
								}}
								onPageChange={this.handleChangePage}
								onRowsPerPageChange={this.handleChangeRowsPerPage}
								labelRowsPerPage={null}
								labelDisplayedRows={
									() => null
								}
								ActionsComponent={
									TablePaginationActions
								}
							/>
							<div style={{ display: "flex", padding: 10 }}>
								<Button size="small" variant="contained" color="primary" style={{ ...styles.addButton, marginRight: 10 }} onClick={() => this.handleClick(null, "ADD")} >
									Add Settings
								</Button>
							</div>
							<TextField
								margin="normal"
								id="search-text"
								variant='standard'
								label="Search"
								placeholder="Name"
								name="mobile-number"
								autoComplete="off"
								value={this.state.search_text}
								onChange={event =>
									this.setState({ search_text: event.target.value, page: 0 })
								}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ classes: { input: styles.inputStyleForSearchText, root: styles.inputRoot, inputlabel: styles.inputLabel } }}
								autoFocus
							/>
						</Toolbar>
					</Grid>
					<Grid item xs={8} sm={12} style={{ paddingTop: 10 }}>
						<Table>
							<TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10 }}>
								<TableRow>
									<TableCell align="center" style={{ fontFamily: "Futura-Book", color: ' #fff', fontWeight: 900, padding: '14px 0px 14px 20px', display: "flex", justifyContent: "center", alignItems: "center" }}>
										Name
										{/* <div style={{ display: "grid", cursor: "pointer" }}> */}
										<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_ASC" })} />
										<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_DSC" })} />
										{/* </div> */}
									</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Code</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Value</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Description</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Status</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Edit</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									result.length > 0 ? result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) =>
										<TableRow key={item.id} style={{ backgroundColor: index % 2 === 0 ? "#fff" : "whitesmoke" }}>
											<TableCell align="center" style={{ ...styles.fontTableCellBody, paddingLeft: 5 }}>{item.name}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.code}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.value}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.description}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.status}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>
												<Button size="small" style={styles.editButton} onClick={() => this.handleClick(item, "EDIT")}>EDIT</Button>
											</TableCell>
										</TableRow>
									)
										:
										<TableRow>
											<TableCell colSpan={6} style={{...styles.fontTableCellBody, textAlign:'center'}}>There are no settings</TableCell>
										</TableRow>
								}
							</TableBody>
						</Table>
					</Grid>
				</Grid>
				<Dialog
					maxWidth={"sm"}
					open={this.state.dialogOpen}>
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "1%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{this.state.mode === "ADD" ? "Add Settings" : "Update Settings"}</Typography>
					</DialogTitle>
					<DialogContent style={{ overflowY: "hidden" }}>
						<Grid container justify="space-between" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									id="name"
									label="Name"
									name="name"
									style={styles.margin}
									value={this.state.name.charAt(0).toUpperCase() + this.state.name.slice(1)}
									autoComplete="new_password"
									onChange={(event) => this.setState({ name: event.target.value.trimStart(), name_error: false })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle }
									}}
									fullWidth
									error={this.state.name_error === true ? true : false}
									helperText={this.state.name_error === true ? "Please enter name" : false}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }} >
								<TextField
									id="code"
									label="Code"
									name="code"
									autoComplete="code"
									disabled={this.state.mode === "EDIT" ? true : false}
									style={styles.margin}
									value={this.state.code}
									onChange={(event) => this.setState({ code: event.target.value.trimStart(), code_error: false })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle }
									}}
									fullWidth
									error={this.state.code_error === true ? true : false}
									helperText={this.state.code_error === true ? "Please enter code" : false}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }} >
								<TextField
									id="valueType"
									select
									label="Value Type"
									name="valueType"
									value={this.state.valueType}
									onChange={(event) => this.setState({ valueType: event.target.value, value: event.target.value === "BOOLEAN" ? false : "", value_error: false })}
									margin="normal"
									InputLabelProps={{
										shrink: true
									}}
									InputProps={{
										classes: { input: styles.inputStyle }
									}}
									required
									fullWidth
								>
									<MenuItem value={"BOOLEAN"} >BOOLEAN</MenuItem>
									<MenuItem value={"INTEGER"} >INTEGER</MenuItem>
									<MenuItem value={"STRING"} >STRING</MenuItem>
								</TextField>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }} >
								{this.state.valueType === "BOOLEAN" ?
									<FormControl margin="normal" component="fieldset">
										<FormLabel style={styles.inputStyle} component="legend">{`${this.state.value === true ? "Disable" : "Enable"} Value`}</FormLabel>
										<FormGroup>
											<FormControlLabel
												control={<Switch
													checked={this.state.value}
													onChange={(event) => this.setState({ value: event.target.checked, value_error: false })}
													color="primary"
													name="checkedB"
													inputProps={{ 'aria-label': 'primary checkbox' }}
												/>}
											/></FormGroup>
									</FormControl>
									:
									<TextField
										id="value"
										label="Value"
										name="value"
										autoComplete="value"
										style={styles.margin}
										value={this.state.value}
										onChange={(event) => this.setState({ value: this.state.valueType === "INTEGER" ? event.target.value.replace(/[^0-9.]/g, '') : event.target.value, value_error: false })}
										margin="normal"
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											classes: { input: styles.inputStyle }
										}}
										fullWidth
										error={this.state.value_error === true ? true : false}
										helperText={this.state.value_error === true ? "Please enter value" : false}
									/>}
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									id="description"
									label="Description"
									name="description"
									autoComplete="description"
									style={styles.margin}
									value={this.state.description}
									onChange={(event) => this.setState({ description: event.target.value.trimStart() })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle }
									}}
									fullWidth
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<div style={{ padding: "2% 0 2% 0" }}>
									<Typography style={{ fontFamily: "Futura-Book", fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>Status</Typography>
									<FormControl component="fieldset">
										<RadioGroup aria-label="position" name="position" value={this.state.status}
											onChange={(event) => this.setState({ status: event.target.value, status_error: false })} row>
											<FormControlLabel
												value="ACTIVE"
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>Active</span>}
												labelPlacement="end"
											/>
											<FormControlLabel
												value="INACTIVE"
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>In Active</span>}
												labelPlacement="end"
											/>
										</RadioGroup>
										{this.state.status_error === true && <FormHelperText style={{ color: "red" }}>{"Please select status"}</FormHelperText>}
									</FormControl>
								</div>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={() => this.handleSubmit(this.state.mode)} variant="contained" color="primary" style={styles.addButton}>Submit</Button>
						<Button size="small" onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.isLoading}
					classes={{
						paper: styles.dialogPaper,
					}}
				>
					<div style={{ boxShadow: 0, overflow: "none" }}>
						<CircularProgress color="primary" size={40} />
					</div>
				</Dialog>
			</div>
		)
	}
}

/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
	return {
		settings: state.settingsReducer.settings,
	}
}
Settings.propTypes = {
	dispatch: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps)(Settings));