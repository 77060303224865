import React, { Component } from "react";
import { Typography, Grid, Table, TableBody, TableHead, TableCell, TableRow, Button } from "@mui/material";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchUserSubscriptionPlan } from '../../actions/userSubscriptionPlanAction';
import Premiumicon from "../../assets/images/Premiumicon.png";
import Standardicon from "../../assets/images/Standardicon.png";
import Basicicon from "../../assets/images/Basicicon.png"
import moment from "moment";
import { withRouter } from "../../withRouter";
import theme from '../../theme';

const styles = {
    fontCellHeader: {
        // color: "#fff",
        // fontSize: 18,
        // fontWeight: "bold"
        fontFamily: "Futura-Book",
        color: ' #fff',
        fontWeight: 900,
        padding: '14px 20px 14px 0px'
    },
    addButton: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        fontFamily: "unicode.futurab",
        fontSize: 13,
        fontWeight: "bold",
        margin: 10,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    }
}

class ViewUserSubscriptionPlan extends Component {
    constructor() {
        super();
        this.state = {
            user_profile_id: null
        }
    }

    componentDidMount() {
        let id = this.props.params.id;
        this.setState({ user_profile_id: id });
        this.props.dispatch(fetchUserSubscriptionPlan(this, id))
    }

    render() {


        return (
            <div style={{ padding: 20 }}>
                <Button size="small" variant="contained" color="secondary" style={styles.addButton} onClick={() => this.props.navigate(-1)}>GO BACK</Button>
                <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", fontSize: 20 }}> User Details </Typography>
                {this.props.user_subscription_plan !== null && this.props.user_subscription_plan !== undefined &&
                    <div>
                        <Grid container style={{ margin: "15px 15px" }} >
                            <Grid item xs={9}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Name</Typography>
                                        <Typography style={{ fontFamily: "Avenir-Book" }}>{this.props.user_subscription_plan.firstName + " " + this.props.user_subscription_plan.lastName}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Phone Number</Typography>
                                        <Typography style={{ fontFamily: "Avenir-Book" }}>{this.props.user_subscription_plan.phone}</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Active Plan</Typography>
                                        <Typography style={{ fontFamily: "Avenir-Book" }}>{this.props.user_subscription_plan.userSubscriptionPlan !== null ? (this.props.user_subscription_plan.userSubscriptionPlan.subscriptionPlan.isUnlimited === true) ? <img alt="premium" src={Premiumicon} height={25} width="auto" /> : (this.props.user_subscription_plan.userSubscriptionPlan.subscriptionPlan.subscriptionType === 'ANNUAL') ? <img alt="standard" src={Standardicon} height={25} width="auto" /> : <img alt="basic" src={Basicicon} height={25} width="auto" /> : "NO-PLAN"}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div style={{ margin: "10px 15px" }}>
                            <Table >
                                <TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10 }}>
                                    <TableRow >
                                        <TableCell align="center" size="small" style={styles.fontCellHeader}>Subscription Plan</TableCell>
                                        <TableCell align="center" size="small" style={styles.fontCellHeader}>Start Date</TableCell>
                                        <TableCell align="center" size="small" style={styles.fontCellHeader}>End Date</TableCell>
                                        <TableCell align="center" size="small" style={styles.fontCellHeader}>Exhausted Date</TableCell>
                                        <TableCell align="center" size="small" style={styles.fontCellHeader}>Plan Type</TableCell>
                                        <TableCell align="center" size="small" style={styles.fontCellHeader}>Plan For</TableCell>
                                        <TableCell align="center" size="small" style={styles.fontCellHeader}>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.user_subscription_plan.userSubscriptionPlanHistories.length > 0 && this.props.user_subscription_plan.userSubscriptionPlanHistories.map((item, index) =>
                                        <TableRow key={item.id} style={{ backgroundColor: item.isActivePlan === true ? "#B8D8BE" : "#fff" }}>
                                            <TableCell align="center" style={styles.fontCellBody}><Typography>{item.subscriptionPlan.name}</Typography></TableCell>
                                            <TableCell align="center" style={styles.fontCellBody}><Typography>{item.subscriptionDate !== null ? moment(item.subscriptionDate).format("MM/DD/YYYY") : "-"}</Typography></TableCell>
                                            <TableCell align="center" style={styles.fontCellBody}><Typography>{item.expiryDate !== null ? moment(item.expiryDate).format("MM/DD/YYYY") : "-"}</Typography></TableCell>
                                            <TableCell align="center" style={styles.fontCellBody}><Typography>{item.exhaustedDate !== null ? moment(item.exhaustedDate).format("MM/DD/YYYY") : "-"}</Typography></TableCell>
                                            <TableCell align="center" style={styles.fontCellBody}><Typography>{item.isFreePlan === true ? "FREE" : "PAID"}</Typography></TableCell>
                                            <TableCell align="center" style={styles.fontCellBody}><Typography>{item.planFor === "ORG" ? "ORGANIZATION" : item.planFor === "IND" ? "INDIVIDUAL" : "SHARED"}</Typography></TableCell>
                                            <TableCell align="center" style={styles.fontCellBody}><Typography> <Typography style={{ borderRadius: 15, fontSize: 14, textAlign: "center", fontWeight: "bold", padding: "2px 3px", color: "#fff", backgroundColor: item.status === "ACTIVE" ? "#28A745" : item.status === "EXPIRED" ? "#D73A49" : "#FBCA04" }}>{item.status}</Typography></Typography></TableCell>
                                        </TableRow>)}
                                </TableBody>
                            </Table>
                            {this.props.user_subscription_plan.userSubscriptionPlanHistories.length === 0 && <Typography style={{ textAlign: "center", padding: 15, fontSize: 17 }}>There are no plan histories</Typography>}
                        </div>
                    </div>
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user_subscription_plan: state.userSubscriptionPlanReducer.user_subscription_plan,
    }
}

ViewUserSubscriptionPlan.propTypes = {
    // classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps)(ViewUserSubscriptionPlan));
