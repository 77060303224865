import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Table, TableBody, TableCell, TableHead, TableRow,
	Typography, Grid, Button, Dialog, DialogActions,
	DialogContent, DialogTitle, TextField
} from '@mui/material';
import { fetchMessageConfiguration, updateMessageConfiguration } from "../../actions/messageConfigAction";
import { withRouter } from "../../withRouter";
import theme from "../../theme";

/** Created common styles for UI  */
const styles = {
	textStyle: {
		color: "#37474f",
		fontSize: 20,
	},
	fontCellHeader: {
		color: "#fff",
		fontWeight: 900,
		padding: '14px 20px 14px 0px',
	},
	fontTableCellBody: {
		color: "#37474f",
		padding: '14px 20px 14px 0px',
	},
	editButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(143, 163, 173, 0.8)',
		}
	},
};

class MessageConfiguration extends Component {
	constructor() {
		super();
		this.state = {
			messages: [],
			message_body: "",
			message_for: "",
			status: "",
			id: null,
			dialogOpen: false,
			message_for_error: false,
			message_body_error: false,
			previewDialogOpen: false
		}
	};

	/** This is a component life cycle method and used to assigning props values to state variable  */
	static getDerivedStateFromProps(props, state) {
		if (props.messages !== state.messages) {
			return {
				messages: props.messages
			}
		}
		return null;
	};

	componentDidMount() {
		this.props.dispatch(fetchMessageConfiguration())
	};

	editMessage = (message) => {
		this.setState({
			dialogOpen: true,
			message_body: message.message_body,
			message_for: message.message_for,
			status: message.status,
			code: message.code,
			id: message.id,
			message_for_error: false,
			message_body_error: false,
		})
	};

	handleClose = () => {
		this.setState({
			message_body: "",
			message_for: "",
			status: "",
			id: null,
			dialogOpen: false,
			message_for_error: false,
			message_body_error: false,
			previewDialogOpen: false
		})
	};

	handleSubmit = () => {
		let { id, message_for, message_body } = this.state
		let isError = false;
		if (!(!!message_for)) {
			this.setState({ message_for_error: true })
			isError = true;
		}

		if (!(!!message_body)) {
			this.setState({ message_body_error: true })
			isError = true;
		}

		if (isError === false) {
			let data = {};
			data.message_for = message_for;
			data.message_body = message_body;
			// data.status = status;
			this.props.dispatch(updateMessageConfiguration(this, data, id))
		}
	};

	render() {
		// const { styles } = this.props;
		return (
			<div style={{ paddingLeft: 10 }}>
				<Grid container spacing={0} style={{ marginTop: 10 }}>
					<Grid item xs={12} sm={9}>
						<Typography style={{ fontWeight: "bold", paddingTop: 10, color: theme.palette.secondary.light, fontSize: 20 }}>Messages</Typography>
					</Grid>
					<Grid item xs={8} sm={12} style={{ padding: 10 }}>
						<Table>
							<TableHead>
								<TableRow style={{ backgroundColor: theme.palette.secondary.dark, }}>
									<TableCell align="center" style={styles.fontCellHeader}>Message For</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Message Code</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Message Body</TableCell>
									{/* <TableCell align="center" style={styles.fontCellHeader}>Status</TableCell> */}
									<TableCell align="center" style={styles.fontCellHeader}>Edit</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									this.state.messages.length > 0 ? this.state.messages.map((item, index) => (
										<TableRow key={item.id} style={{ backgroundColor: index % 2 === 0 ? "#fff" : "whitesmoke" }}>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.message_for}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.code}</TableCell>
											<TableCell align="center" style={{ width: 450, color: "#37474f", padding: '14px 20px 14px 0px' }}>{item.message_body}</TableCell>
											{/* <TableCell align="center" style={styles.fontTableCellBody}>{item.status}</TableCell> */}
											<TableCell align="center" style={styles.fontTableCellBody}>
												<Button size="small" style={styles.editButton} onClick={() => this.editMessage(item)}>EDIT</Button>
											</TableCell>
										</TableRow>)
									)
										:
										<TableRow>
											<TableCell colSpan={6} style={{ ...styles.fontTableCellBody, textAlign: 'center' }}>There are no messages</TableCell>
										</TableRow>
								}
							</TableBody>
						</Table>
					</Grid>
				</Grid>
				<Dialog
					maxWidth={"sm"}
					open={this.state.previewDialogOpen}>
					<DialogTitle id="form-dialog-title">
						<Typography style={{ padding: "2% 5% 0 5%", color: "#000000", fontSize: 17, fontWeight: "bold" }}>{"Preview Message"}</Typography>
					</DialogTitle>
					<DialogContent style={{ overflowY: "hidden" }}>
						<div>
							<Typography>{"Hi Jane Doe, " + this.state.message_body}</Typography>
						</div>
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={() => this.handleSubmit()} variant="contained" color="primary" style={styles.addButton}>Submit</Button>
						<Button size="small" onClick={() => this.setState({ previewDialogOpen: false })} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					maxWidth={"sm"}
					open={this.state.dialogOpen}>
					<DialogTitle id="form-dialog-title">
						<Typography style={{ padding: "2% 5% 0 5%", color: "#000000", fontSize: 17, fontWeight: "bold" }}>{"Update Message"}</Typography>
					</DialogTitle>
					<DialogContent style={{ overflowY: "hidden" }}>
						<Grid container justify="space-between" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									label="Message For"
									style={styles.margin}
									value={this.state.message_for}
									onChange={(event) => this.setState({ message_for: event.target.value, message_for_error: false })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										styles: { input: styles.inputStyle }
									}}
									fullWidth
									error={this.state.message_for_error === true ? true : false}
									helperText={this.state.message_for_error === true ? "Please enter message for" : false}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									label="Message Code"
									style={styles.margin}
									value={this.state.code}
									onChange={(event) => this.setState({ code: event.target.value, type_error: false })}
									margin="normal"
									disabled={true}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										styles: { input: styles.inputStyle }
									}}
									fullWidth
									error={this.state.code_error === true ? true : false}
									helperText={this.state.code_error === true ? "Please enter message code" : false}
								/>
							</Grid>
							{/* <Grid item xs={6} style={{ paddingRight: 8 }}>
								<div style={{ padding: "2% 0 2% 0" }}>
									<Typography style={{ fontFamily: "Futura-Book", fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>Status</Typography>
									<FormControl component="fieldset">
										<RadioGroup aria-label="position" name="position" value={this.state.status}
											onChange={(event) => this.setState({ status: event.target.value })} row>
											<FormControlLabel
												value="ACTIVE"
												control={<Radio style={{ color: "#20478e" }} />}
												label={<span style={styles.labelStyle}>Active</span>}
												labelPlacement="end"
											/>
											<FormControlLabel
												value="INACTIVE"
												control={<Radio style={{ color: "#20478e" }} />}
												label={<span style={styles.labelStyle}>In Active</span>}
												labelPlacement="end"
											/>
										</RadioGroup>
									</FormControl>
								</div>
							</Grid> */}
							<Grid item xs={12} style={{ paddingRight: 8 }}>
								<TextField
									label="Message Body"
									style={styles.margin}
									value={this.state.message_body}
									onChange={(event) => this.setState({ message_body: event.target.value, message_body_error: false })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										styles: { input: styles.inputStyle }
									}}
									fullWidth
									minRows={5}
									multiline
									error={this.state.message_body_error === true ? true : false}
									helperText={this.state.message_body_error === true ? "Please enter message body" : false}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={() => this.setState({ previewDialogOpen: true })} variant="contained" color="primary" style={styles.addButton}>Preview</Button>
						<Button size="small" onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		messages: state.messageConfigReducer.messages,
	}
}
MessageConfiguration.propTypes = {
	// styles: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps)(MessageConfiguration));