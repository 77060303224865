import { FETCH_TESTTYPE, ALREADY_VACCINATED_TEST, USER_PROFILE_TEST_RESULT, FETCH_TEST_TYPES, FETCH_VACCINE_TEST_TYPES } from "../actions/actionTypes"

const initialstate = {
    testtype: [],
    already_vaccinated: null,
    userTestTypeResult: [],
    testTypeRecords: [],
    vaccineRecords: []
}

export default function testtypeReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_TESTTYPE:
            return {
                ...state,
                testtype: action.data
            }
        case ALREADY_VACCINATED_TEST:
            return {
                ...state,
                already_vaccinated: action.data
            }
        case USER_PROFILE_TEST_RESULT:
            return {
                ...state,
                userTestTypeResult: action.data
            }
        case FETCH_TEST_TYPES:
            return {
                ...state,
                testTypeRecords: action.data
            };
        case FETCH_VACCINE_TEST_TYPES:
            return {
                ...state,
                vaccineRecords: action.data
            };
        default:
            return state;
    }
}

