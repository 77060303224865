import { combineReducers } from 'redux';
import sessionReducer from "./sessionReducer";
import userReducer from "./userReducer";
import vaccineReducer from "./vaccineReducer";
import testtypeReducer from "./testtypeReducer";
import vaccineShotReducer from "./vaccineShotReducer";
import vaccineManufacturerReducer from "./vaccineManufacturerReducer";
import activityReducer from "./activityReducer";
import testReducer from "./testReducer";
import registerReducer from "./registerReducer";
import organizationReducer from "./organizationReducer";
import memberReducer from "./memberReducer";
import partnerReducer from "./partnerReducer";
import roletypeReducer from "./roletypeReducer"
import donorReducer from "./donorReducer"
import settingsReducer from './settingsReducer';
import creditCouponReducer from "./creditCouponReducer";
import appVersionReducer from "./appVersionReducer";
import userSubscriptionPlanReducer from "./userSubscriptionPlanReducer";
import userPassCodeReducer from "./userPassCodeReducer";
import labTestResultsReducer from "./labTestResultsReducer";
import messageConfigReducer from "./messageConfigReducer";

const allReducers = combineReducers({
	sessionReducer,
	userReducer,
	vaccineReducer,
	testtypeReducer,
	vaccineShotReducer,
	vaccineManufacturerReducer,
	activityReducer,
	testReducer,
	registerReducer,
	organizationReducer,
	memberReducer,
	partnerReducer,
	roletypeReducer,
	donorReducer,
	settingsReducer,
	creditCouponReducer,
	appVersionReducer,
	userSubscriptionPlanReducer,
	userPassCodeReducer,
	labTestResultsReducer,
	messageConfigReducer
});

export default allReducers;