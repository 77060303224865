import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Table, TableBody, TableCell, TableHead, TableRow,
    Typography, Grid, Button, Dialog, DialogActions,
    DialogContent, DialogTitle, TextField,
    FormControl, FormControlLabel, Radio, RadioGroup,
    FormHelperText
} from '@mui/material';
import { fetchRoleType, addRoleType, updateRoleType } from "../../actions/roletypeAction";
import { withRouter } from "../../withRouter";
import theme from "../../theme";

const styles = {
    textStyle: {
        fontFamily: "Futura-Heavy",
        color: "#37474f",
        //fontFamily: "bold",
        fontSize: 20,
        //padding: 10
    },
    creditTextStyle: {
        fontFamily: "Futura-Heavy",
        color: "#37474f",
        // fontFamily: "bold",
        fontSize: 15,
        paddingTop: 10
    },
    addButton: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        fontFamily: "unicode.futurab",
        borderRadius: "10",

    },
    cancelButton: {
        backgroundColor: "#8fa3ad",
        color: "white",
        fontFamily: "unicode.futurab",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(143, 163, 173, 0.8)',
        }
    },
    editButton: {
        backgroundColor: "#8fa3ad",
        color: "white",
        fontFamily: "unicode.futurab",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(143, 163, 173, 0.8)',
        }
    },
    fontCellHeader: {
        fontFamily: "Futura-Book",
        color: "#78909c",
        fontWeight: 900,
        padding: '14px 20px 14px 0px',

    },
    fontTableCellBody: {
        fontFamily: "Futura-Medium-bt",
        color: "#37474f",
        padding: '14px 20px 14px 0px',
    },
    inputStyle: {
        fontFamily: "Futura-Book",
        fontSize: 14
    },
    labelStyle: {
        fontFamily: "Futura-Book",
        fontSize: 14
    },
}


class RoleType extends Component {
    constructor() {
        super();
        this.state = {
            roletype: [],
            dialogOpen: false,
            id: null,
            name: "",
            name_error: false,
            code: "",
            code_error: false,
            status: "ACTIVE",
            status_error: false,
            mode: ""
        }
    }



    static getDerivedStateFromProps(props, state) {
        if (props.roletype !== state.roletype) {
            return {
                roletype: props.roletype
            }
        }
        return null;
    }

    componentDidMount() {
        this.props.dispatch(fetchRoleType())

    }

    handleClick = (item, mode) => {
        if (mode === "ADD") {
            this.setState({ mode: mode, dialogOpen: true })
        }
        else {
            this.setState({
                id: item.id,
                dialogOpen: true,
                mode: mode,
                name: item.name,
                name_error: false,
                code: item.code,
                code_error: false,
                status: item.status,
                status_error: false,

            })
        }
    }

    handleClose = () => {
        this.setState({
            dialogOpen: false,
            id: null,
            name: "",
            name_error: false,
            code: "",
            code_error: false,
            status: "ACTIVE",
            status_error: false,
            mode: ""
        })
    }
    handleSubmit = (mode) => {
        let { id, name, code, status } = this.state
        let isError = false;
        if (name === "" || name === null) {
            this.setState({ name_error: true })
            isError = true;
        }

        if (code === "" || code === null) {
            this.setState({ code_error: true })
            isError = true;
        }
        if (status === "" || status === null) {
            this.setState({ status_error: true })
            isError = true;
        }

        if (isError === false) {
            let roletypeObj = {};
            roletypeObj.name = name;
            roletypeObj.code = code;
            roletypeObj.status = status;
            if (mode === "ADD") {
                this.props.dispatch(addRoleType(this, roletypeObj))
            }
            else {
                this.props.dispatch(updateRoleType(this, roletypeObj, id))
            }
        }
    }



    render() {
        // const { styles } = this.props;
        return (
            <div style={{ paddingLeft: 10 }}>
                <Grid container spacing={0} style={{ marginTop: 10, }}>
                    <Grid item xs={12} sm={9}>
                        <Typography style={styles.textStyle}>Role Type</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3} style={{ float: 'left' }}>
                        <Button size="small" variant="contained" color="primary" style={styles.addButton} onClick={() => this.handleClick(null, "ADD")} >
                            Add
                        </Button>
                    </Grid>
                    <Grid item xs={8} sm={12} style={{ paddingTop: 10 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" style={styles.fontCellHeader}>Name</TableCell>
                                    <TableCell align="left" style={styles.fontCellHeader}>Code</TableCell>
                                    <TableCell align="left" style={styles.fontCellHeader}>Status</TableCell>
                                    <TableCell align="left" style={styles.fontCellHeader}>Edit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {
                                    this.state.roletype.map((item) => (
                                        <TableRow key={item.id}>
                                            <TableCell align="left" style={styles.fontTableCellBody}>{item.name}</TableCell>
                                            <TableCell align="left" style={styles.fontTableCellBody}>{item.code}</TableCell>

                                            <TableCell align="left" style={styles.fontTableCellBody}>{item.status}</TableCell>
                                            <TableCell align="left" style={styles.fontTableCellBody}>
                                                {/* <IconButton onClick={() => this.handleClick(item, "EDIT")}><img src={Edit} height="20" alt="icon" /></IconButton > */}
                                                <Button size="small" style={styles.editButton} onClick={() => this.handleClick(item, "EDIT")}>EDIT</Button>
                                            </TableCell>
                                        </TableRow>)
                                    )}

                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Dialog
                    maxWidth={"sm"}
                    open={this.state.dialogOpen} >
                    <DialogTitle id="form-dialog-title">
                        <Typography style={{ padding: "2% 5% 0 5%", fontFamily: "Futura-Heavy" }}>{this.state.mode === "ADD" ? "Add Role type" : "Update Role Type"}</Typography>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "hidden" }}>

                        <Grid container justify="space-between" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
                            <Grid item xs={6} style={{ paddingRight: 8 }}>

                                <TextField
                                    id="name"
                                    label="Name"
                                    name="name"
                                    style={styles.margin}
                                    value={this.state.name}
                                    onChange={(event) => this.setState({ name: event.target.value, name_error: false })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        styles: { input: styles.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.name_error === true ? true : false}
                                    helperText={this.state.name_error === true ? "Please enter name" : false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <TextField
                                    id="code"
                                    label="Code"
                                    name="code"
                                    disabled={this.state.mode === "EDIT" ? true : false}
                                    style={styles.margin}
                                    value={this.state.code}
                                    onChange={(event) => this.setState({ code: event.target.value, code_error: false })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        styles: { input: styles.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.code_error === true ? true : false}
                                    helperText={this.state.code_error === true ? "Please enter code" : false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <div style={{ padding: "2% 0 2% 0" }}>
                                    <Typography style={{ fontFamily: "Futura-Book", fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>Status</Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="position" name="position" value={this.state.status}
                                            onChange={(event) => this.setState({ status: event.target.value, status_error: false })} row>
                                            <FormControlLabel
                                                value="ACTIVE"
                                                control={<Radio style={{ color: "#20478e" }} />}
                                                label={<span style={styles.labelStyle}>Active</span>}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="INACTIVE"
                                                control={<Radio style={{ color: "#20478e" }} />}
                                                label={<span style={styles.labelStyle}>In Active</span>}
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                        {this.state.status_error === true && <FormHelperText style={{ color: "red" }}>{"Please select status"}</FormHelperText>}
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={() => this.handleSubmit(this.state.mode)} variant="contained" color="primary" style={styles.addButton}>Submit</Button>
                        <Button size="small" onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
                    </DialogActions>

                </Dialog>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        roletype: state.roletypeReducer.roletype,
    }
}
RoleType.propTypes = {
    // styles: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps)(RoleType));
