import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Table, TableBody, TableCell, TableHead, TableRow,
	Typography, Grid, Button, Dialog, DialogActions,
	DialogContent, DialogTitle, TextField,
	FormControl, FormControlLabel, Radio, RadioGroup,
	FormHelperText, InputAdornment, Toolbar, TablePagination, CircularProgress, Autocomplete
} from '@mui/material';
import { fetchPartner, addPartner, updatePartner } from '../../actions/partnerAction';
import { searchUser } from "../../actions/memberAction";
import TablePaginationActions from "../Dashboard/TablePaginationActions";
import { USA_STATES } from '../../helpers/Constants';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { withRouter } from '../../withRouter';
import theme from '../../theme';
import { headerFont } from '../../helpers/Constants';


/**Created common styles for UI.*/
const styles = {
	textStyle: {
		fontFamily: "Futura-Heavy",
		color: "#37474f",
		fontSize: 20,
	},
	addButton: {
		backgroundColor: theme.palette.primary.main,
		color: "white",
		fontFamily: "unicode.futurab",
		borderRadius: 5,
		height: "auto"
	},
	cancelButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		fontFamily: "unicode.futurab",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(143, 163, 173, 0.8)',
		}
	},
	editButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		fontFamily: "unicode.futurab",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(143, 163, 173, 0.8)',
		}
	},
	fontCellHeader: {
		fontFamily: "Futura-Book",
		color: "#fff",
		fontWeight: 900,
		padding: '14px 20px 14px 0px',

	},
	fontTableCellBody: {
		fontFamily: "Futura-Medium-bt",
		color: "#37474f",
		padding: '14px 20px 14px 0px',
	},
	inputStyle: {
		fontFamily: "Futura-Book",
		fontSize: 14
	},
	labelStyle: {
		fontFamily: "Futura-Book",
		fontSize: 14
	},
	inputStyleForSearchText: {
		fontSize: "15px !important",
		padding: "5px !important",
		width: 250,
	},
	inputRoot: {
		borderRadius: "0px !important"
	},
	inputLabel: {
		color: "#4B86E3"
	},
	dialogPaper: {
		backgroundColor: "rgba(0,0,0,0)",
		boxShadow: "none",
		overflow: "hidden"
	}
}

// const CssTextField = withStyles({
// 	root: {
// 		'& label.Mui-focused': {
// 			color: '#4B86E3',
// 		},
// 		'& .MuiInput-underline:after': {
// 			borderBottomColor: '#4B86E3',
// 		},
// 		'& .MuiOutlinedInput-root': {
// 			'& fieldset': {
// 				borderColor: '#4B86E3',
// 			},
// 			'&:hover fieldset': {
// 				borderColor: '#4B86E3',
// 			},
// 			'&.Mui-focused fieldset': {
// 				borderColor: '#4B86E3',
// 			},
// 		},
// 	},
// })(TextField);

/**
 * @class
 *
 * Class representing Partner component
 *
 */
class Partner extends Component {
	constructor(props) {
		super(props)
		this.state = {
			partner: [],
			dialogOpen: false,
			id: 0,
			name: "",
			name_error: false,
			email: "",
			email_error: false,
			invalid_email_error: false,
			phone_number: "",
			phone_number_error: false,
			invalid_phone_number_error: false,
			contact_person_name: "",
			contact_person_name_error: false,
			address_line1: "",
			address_line1_error: false,
			address_line2: "",
			address_line2_error: false,
			city: "",
			city_error: false,
			selectedState: "",
			selectedState_error: false,
			inputValue: '',
			country: "United States of America",
			country_error: false,
			zipcode: "",
			zipcode_error: false,
			invalid_zipcode_error: false,
			status: "ACTIVE",
			status_error: false,
			mode: "",
			search_phone: "",
			invalid_search_phone_error: false,
			search_phone_error: false,
			primary_contact_first_name: "",
			primary_contact_first_name_error: false,
			primary_contact_last_name: "",
			primary_contact_last_name_error: false,
			primary_contact_email: "",
			primary_contact_email_error: false,
			primary_contact_country_code: "+1",
			errorText: "",
			invalid_primary_contact_email_error: false,
			isLoading: false,
			search_text: "",
			rowsPerPage: 10,
			page: 0,
			sortBy: "NAME_ASC"
		}
	}

	/**This is a component life cycle method and used to assigning props values to state variable.*/
	static getDerivedStateFromProps(props, state) {
		if (props.partner !== state.partner) {
			return {
				partner: props.partner
			}
		}
		return null;
	}

	/**
	 * @function
	 *
	 * function representing table pagination.
	 * @param {Object} _event
	 * @param {Object} newPage
	 */
	handleChangePage = (_event, newPage) => {
		this.setState({
			page: newPage
		});
	}

	/**
		 * @function
		 *
		 * function representing table pagination row.
		 * @param {Object} event
		 */
	handleChangeRowsPerPage = (event) => {
		this.setState({
			page: 0, rowsPerPage: parseInt(event.target.value, 10)
		});
	}

	/** This is a component life cycle method and used to fetch datas*/
	componentDidMount() {
		/** fetching Partners details. */
		this.props.dispatch(fetchPartner())
	}

	/**
	 *@function
	 * This function representing email validation.
	 * @param {String} email The email string
	 */
	validateEmail(email) {
		var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	/**
	*@function
	* This function representing add or edit Partner dialog screen will open.
	* @param {Object} item The item json object
	* @param {String} mode The mode string
	*/
	handleClick = (item, mode) => {
		if (mode === "ADD") {
			this.setState({ mode: mode, dialogOpen: true })
		} else {
			this.setState({
				id: item.id,
				dialogOpen: true,
				mode: mode,
				name: item.name,
				name_error: false,
				email: item.email,
				email_error: false,
				phone_number: item.phoneNumber,
				phone_number_error: false,
				invalid_phone_number_error: false,
				contact_person_name: item.contactPersion,
				contact_person_name_error: false,
				address_line1: item.addressLine1,
				address_line1_error: false,
				address_line2: item.addressLine2,
				address_line2_error: false,
				city: item.city,
				city_error: false,
				selectedState: item.state,
				selectedState_error: false,
				country: item.country,
				zipcode: item.zipcode,
				zipcode_error: false,
				invalid_zipcode_error: false,
				country_error: false,
				status: item.status,
				status_error: false,
				invalid_email_error: false,

			})
		}
	}

	/**
	 * @function
	 * This function representing close add or edit partner dialog screen.
	 */
	handleClose = () => {
		this.setState({
			dialogOpen: false,
			id: 0,
			name: "",
			name_error: false,
			email: "",
			email_error: false,
			invalid_email_error: false,
			phone_number: "",
			phone_number_error: false,
			invalid_phone_number_error: false,
			contact_person_name: "",
			contact_person_name_error: false,
			address_line1: "",
			address_line1_error: false,
			address_line2: "",
			address_line2_error: false,
			city: "",
			city_error: false,
			selectedState: "",
			selectedState_error: false,
			country: "United States of America",
			country_error: false,
			zipcode: "",
			zipcode_error: false,
			invalid_zipcode_error: false,
			status: "ACTIVE",
			status_error: false,
			mode: "",
			search_phone: "",
			invalid_search_phone_error: false,
			search_phone_error: false,
			primary_contact_first_name: "",
			primary_contact_first_name_error: false,
			primary_contact_last_name: "",
			primary_contact_last_name_error: false,
			primary_contact_email: "",
			primary_contact_email_error: false,
			primary_contact_country_code: "+1",
			errorText: "",
			invalid_primary_contact_email_error: false
		})
	}

	/**
	 *@function
	 * This function representing Number validation.
	 * @param {Number} number The number Number
	 */
	checkNumber = (number) => {
		var isNumber = /^-{0,1}\d+$/.test(number)
		return isNumber;
	}

	/**
	*@function
	* This function representing Zipcode validation.
	* @param {String} code The code String
	*/
	validateZipCode(code) {
		var re = /^[\d]{5}$/;
		return code === "" ? true : re.test(code);
	}

	/**
	 *@function
	 * This function representing add or edit Partner.
	 * @param {String} mode The mode string
	 */
	handleSubmit = (mode) => {

		let { id, name, email, phone_number, address_line1, address_line2, city, selectedState,
			country, zipcode, status, primary_contact_first_name, primary_contact_last_name,
			primary_contact_email, search_phone, primary_contact_country_code } = this.state;

		let isError = false;

		if (name === "" || name === null) {
			this.setState({ name_error: true })
			isError = true;
		}

		if (email === "" || email === null) {
			this.setState({ email_error: true })
			isError = true;
		} else {
			if (!this.validateEmail(this.state.email)) {
				this.setState({ invalid_email_error: true })
				isError = true
			}
		}

		if (phone_number === "" || phone_number === null) {
			this.setState({ phone_number_error: true })
			isError = true;
		} else {
			if (this.checkNumber(phone_number) !== true || phone_number.length !== 10) {
				this.setState({ invalid_phone_number_error: true })
				isError = true
			}
		}

		if (address_line1 === "" || address_line1 === null) {
			this.setState({ address_line1_error: true })
			isError = true;
		}

		if (city === "" || city === null) {
			this.setState({ city_error: true })
			isError = true;
		}

		if (selectedState === "" || selectedState === null) {
			this.setState({ selectedState_error: true })
			isError = true;
		}

		if (country === "" || country === null) {
			this.setState({ country_error: true })
			isError = true;
		}

		if (zipcode === "" || zipcode === null) {
			this.setState({ zipcode_error: true });
			isError = true;
		} else {
			if (!this.validateZipCode(zipcode)) {
				this.setState({ invalid_zipcode_error: true });
				isError = true;
			}
		}

		if (status === "" || status === null) {
			this.setState({ status_error: true, })
			isError = true;
		}

		if (this.state.mode === "ADD") {
			if (primary_contact_first_name === "" || primary_contact_first_name === null) {
				this.setState({ primary_contact_first_name_error: true, })
				isError = true;
			}

			if (primary_contact_last_name === "" || primary_contact_last_name === null) {
				this.setState({ primary_contact_last_name_error: true, })
				isError = true;
			}

			if (primary_contact_email === "" || primary_contact_email === null) {
				this.setState({ primary_contact_email_error: true, })
				isError = true;
			} else {
				if (!this.validateEmail(primary_contact_email)) {
					this.setState({ invalid_primary_contact_email_error: true });

					isError = true;
				}
			}

			if (search_phone === "" || search_phone === null) {
				this.setState({ search_phone_error: true })
				isError = true;
			} else {
				if (this.checkNumber(search_phone) !== true || search_phone.length !== 10) {
					this.setState({ invalid_search_phone_error: true })
					isError = true
				}
			}
		}

		if (isError === false) {

			let partnerObj = {};
			partnerObj.name = name;
			partnerObj.email = email;
			partnerObj.phoneNumber = phone_number;
			partnerObj.addressLine1 = address_line1;
			partnerObj.addressLine2 = address_line2;
			partnerObj.city = city;
			partnerObj.state = selectedState;
			partnerObj.country = country;
			partnerObj.zipcode = zipcode;
			partnerObj.status = status;

			if (mode === "ADD") {
				partnerObj.primaryContactPhone = search_phone;
				partnerObj.firstName = primary_contact_first_name;
				partnerObj.lastName = primary_contact_last_name;
				partnerObj.primaryContactemail = primary_contact_email;
				partnerObj.countryCode = primary_contact_country_code;
				this.props.dispatch(addPartner(this, partnerObj))
				this.setState({ isLoading: true })
			} else {
				this.setState({ isLoading: true })
				this.props.dispatch(updatePartner(this, partnerObj, id))
			}
		}
	}

	/**
	* @function
	* This function representing  searchUser.
	*/
	searchUser = () => {
		if (this.state.search_phone !== "" && this.state.search_phone !== null) {
			this.props.dispatch(searchUser(this, this.state.search_phone))
		}
	}

	/** rendering Partners information. */
	render() {
		// const { styles } = this.props;
		const { page, rowsPerPage } = this.state;
		let partners = this.state.sortBy === "NAME_ASC" ? this.props.partner.sort((a, b) => a.name.localeCompare(b.name)) : this.props.partner.sort((a, b) => b.name.localeCompare(a.name));
		let result = this.state.search_text !== '' ? partners.filter(x => (x.name).toString().toLowerCase().includes((this.state.search_text).toString().toLowerCase()) || (x.phoneNumber).toString().includes((this.state.search_text).toString()) || (x.email).toString().toLowerCase().includes((this.state.search_text).toString().toLowerCase())) : partners
		return (
			<div style={{ paddingLeft: 2, paddingRight: 10 }}>
				<Grid container spacing={0} style={{ marginTop: 10 }}>
					<Grid item xs={12} sm={12} style={{ marginBottom: 10, marginLeft: 5}}>
						<Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
							<div>
								<Typography style={{color: theme.palette.secondary.light, fontWeight: headerFont.fontWeight, fontFamily: headerFont.fontFamily, fontSize: headerFont.fontSize,  }}>Partners</Typography>
							</div>
							<TablePagination
								rowsPerPageOptions={[20]}
								component="div"
								count={result.length}
								rowsPerPage={this.state.rowsPerPage}
								page={this.state.page}
								slotProps={{
									select: {
										inputProps: { 'aria-label': 'rows per page' },
										native: true,
									}
								}}
								onChangePage={this.handleChangePage}
								onChangeRowsPerPage={this.handleChangeRowsPerPage}
								labelRowsPerPage={null}
								labelDisplayedRows={
									() => null
								}
								ActionsComponent={
									TablePaginationActions
								}
							/>
							<div style={{ display: "flex", padding: 10 }}>
								<Button size="small" variant="contained" color="primary" style={{ ...styles.addButton, marginRight: 10 }} onClick={() => this.handleClick(null, "ADD")} >
									Add Partner
								</Button>
							</div>
							<TextField
								margin="normal"
								id="search-text"
								variant='standard'
								label="Search"
								placeholder="Name,Phone or Email"
								name="mobile-number"
								autoComplete="off"
								value={this.state.search_text}
								onChange={event =>
									this.setState({ search_text: event.target.value, page: 0 })
								}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ styles: { input: styles.inputStyleForSearchText, root: styles.inputRoot, inputlabel: styles.inputLabel } }}
								autoFocus
							/>
						</Toolbar>
					</Grid>
					<Grid item xs={8} sm={12} style={{ paddingTop: 10 }}>
						<Table>
							<TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10 }}>
								<TableRow>
									<TableCell align="left" style={{ ...styles.fontCellHeader, paddingLeft: 5, display: 'flex', alignItems: "center" }}>
										Name
										{/* <div style={{ display: "grid", cursor: "pointer" }}> */}
											<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_ASC" })} />
											<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_DSC" })} />
										{/* </div> */}
									</TableCell>
									<TableCell align="left" style={styles.fontCellHeader}>E-mail</TableCell>
									<TableCell align="left" style={styles.fontCellHeader}>Phone Number</TableCell>
									<TableCell align="left" style={styles.fontCellHeader}>Contact Person</TableCell>
									<TableCell align="left" style={styles.fontCellHeader}>Status</TableCell>
									<TableCell align="left" style={styles.fontCellHeader}>Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									result.length > 0 ? result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) =>
										<TableRow key={item.id}>
											<TableCell align="left" size="small" style={{ ...styles.fontTableCellBody, paddingLeft: 5 }}>
												<Typography style={{ fontWeight: "bold", fontSize: 15 }}> {item.name}</Typography>
												<Typography style={{ fontSize: 12 }}>{item.addressLine1 + "," + ((item.addressLine2 !== "" && item.addressLine2 !== null) ? item.addressLine2 + "," : "") + item.city + "," + item.state + "," + item.country + "," + item.zipcode}</Typography>
											</TableCell>
											<TableCell align="left" style={styles.fontTableCellBody}>{item.email}</TableCell>
											<TableCell align="left" style={styles.fontTableCellBody}>{item.phoneNumber}</TableCell>
											<TableCell align="left" style={styles.fontTableCellBody}>{item.primaryContactName}</TableCell>
											<TableCell align="left" style={styles.fontTableCellBody}>{item.status}</TableCell>
											<TableCell align="left" style={styles.fontTableCellBody}>
												<Button size="small" style={styles.editButton} onClick={() => this.handleClick(item, "EDIT")}>EDIT</Button>
												{/* <Button size="small" style={{ backgroundColor: "#20478e", color: "white", marginLeft: 10 }} onClick={() => this.props.navigate(`/view-partner/${item.id}`)}>VIEW</Button> */}
											</TableCell>
										</TableRow>)
										:
										<TableRow>
											<Typography style={styles.fontTableCellBody} >There are no partners</Typography>
										</TableRow>
								}
							</TableBody>
						</Table>
					</Grid>
				</Grid>

				<Dialog
					maxWidth={this.state.mode === "ADD" ? "lg" : "sm"}
					scroll="body"
					fullWidth
					open={this.state.dialogOpen} >
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "1%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{this.state.mode === "ADD" ? "Add Partner" : "Update partner"}</Typography>
					</DialogTitle>
					<DialogContent style={{ overflowY: "hidden" }}>
						<Grid container justify="space-between">
							<Grid item xs={this.state.mode === "ADD" ? 6 : 12} >
								<Grid container justify="space-between" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
									<Grid item xs={12}>
										<Typography style={{ fontFamily: "Futura-Heavy" }}>Partner Details</Typography>
									</Grid>
									<Grid item xs={12} style={{ paddingRight: 8 }}>
										<TextField
											id="name"
											label="Name"
											name="name"
											style={styles.margin}
											value={this.state.name.charAt(0).toUpperCase() + this.state.name.slice(1)}
											onChange={(event) => this.setState({ name: event.target.value, name_error: false })}
											margin="normal"
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												styles: { input: styles.inputStyle }
											}}
											autoComplete="off"
											fullWidth
											error={this.state.name_error === true ? true : false}
											helperText={this.state.name_error === true ? "Please enter name" : false}
										/>
									</Grid>
									<Grid item xs={6} style={{ paddingRight: 8 }}>
										<TextField
											id="email"
											label="E-mail"
											name="email"
											style={styles.margin}
											value={this.state.email}
											onChange={(event) => this.setState({ email: event.target.value, invalid_email_error: false, email_error: false })}
											margin="normal"
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												styles: { input: styles.inputStyle }
											}}
											autoComplete="off"
											fullWidth
											error={this.state.email_error === true ? true : this.state.invalid_email_error === true ? true : false}
											helperText={this.state.email_error === true ? "Please enter Email" : this.state.invalid_email_error === true ? "Please enter valid email" : false}
										/>
									</Grid>
									<Grid item xs={6} style={{ paddingRight: 8 }} >
										<TextField
											id="phone_number"
											label="Phone Number"
											name="phone_number"
											style={styles.margin}
											value={this.state.phone_number}
											onChange={(event) => event.target.value.match(/^\d*$/g) && this.setState({ phone_number: event.target.value, phone_number_error: false, invalid_phone_number_error: false, })}
											margin="normal"
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												styles: { input: styles.inputStyle }
											}}
											autoComplete="off"
											fullWidth
											error={this.state.phone_number_error === true ? true : this.state.invalid_phone_number_error === true ? true : false}
											helperText={this.state.phone_number_error === true ? "Please enter phone number" : this.state.invalid_phone_number_error === true ? "Invalid phone number" : false}
										/>
									</Grid>
									<Grid item xs={6} style={{ paddingRight: 8 }} >
										<TextField
											id="address_line1"
											label="Address Line1"
											name="address_line1"
											style={styles.margin}
											value={this.state.address_line1}
											onChange={(event) => this.setState({ address_line1: event.target.value, address_line1_error: false, })}
											margin="normal"
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												styles: { input: styles.inputStyle }
											}}
											autoComplete="off"
											fullWidth
											error={this.state.address_line1_error === true ? true : false}
											helperText={this.state.address_line1_error === true ? "Please enter address" : false}
										/>
									</Grid>
									<Grid item xs={6} style={{ paddingRight: 8 }} >
										<TextField
											id="address_line2"
											label="Address Line2"
											name="address_line1"
											style={styles.margin}
											value={this.state.address_line2}
											onChange={(event) => this.setState({ address_line2: event.target.value, })}
											margin="normal"
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												styles: { input: styles.inputStyle }
											}}
											autoComplete="off"
											fullWidth
										/>
									</Grid>
									<Grid item xs={6} style={{ paddingRight: 8 }} >
										<TextField
											id="city"
											label="City"
											name="city"
											style={styles.margin}
											value={this.state.city.charAt(0).toUpperCase() + this.state.city.slice(1)}
											onChange={(event) => this.setState({ city: event.target.value, city_error: false })}
											margin="normal"
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												styles: { input: styles.inputStyle }
											}}
											autoComplete="off"
											fullWidth
											error={this.state.city_error === true ? true : false}
											helperText={this.state.city_error === true ? "Please enter city" : false}
										/>
									</Grid>
									<Grid item xs={6} style={{ paddingRight: 8 }}>
										<Autocomplete
											value={this.state.selectedState}
											margin="normal"
											style={{ paddingTop: 13 }}
											onChange={(event, value) => this.setState({ selectedState: value, selectedState_error: false })}
											inputValue={this.state.inputValue}
											onInputChange={(event, value) => this.setState({ inputValue: value, selectedState_error: false })}
											id="state"
											options={USA_STATES}

											renderInput={(params) => <TextField {...params} InputLabelProps={{
												shrink: true
											}}
												label="State"
												error={this.state.selectedState_error === true ? true : false}
												helperText={this.state.selectedState_error === true ? "Please enter state" : false}
											/>
											}
										/>
									</Grid>
									<Grid item xs={6} style={{ paddingRight: 8 }}>
										<TextField
											id="country"
											label="Country"
											name="country"
											style={styles.margin}
											value={this.state.country.charAt(0).toUpperCase() + this.state.country.slice(1)}
											onChange={(event) => this.setState({ country: event.target.value, country_error: false })}
											margin="normal"
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												styles: { input: styles.inputStyle }
											}}
											autoComplete="off"
											fullWidth
											error={this.state.country_error === true ? true : false}
											helperText={this.state.country_error === true ? "Please enter country" : false}
										/>
									</Grid>
									<Grid item xs={6} style={{ paddingRight: 8 }}>
										<TextField
											id="zipcode"
											label="Zipcode"
											name="zipcode"
											style={styles.margin}
											value={this.state.zipcode}
											onChange={(event) => event.target.value.match(/^\d{0,5}$/g) && this.setState({ zipcode: event.target.value, zipcode_error: false, invalid_zipcode_error: false })}
											margin="normal"
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												styles: { input: styles.inputStyle }
											}}
											autoComplete="off"
											fullWidth
											error={
												this.state.zipcode_error ? true
													: this.state.invalid_zipcode_error
														? true
														: false
											}
											helperText={this.state.zipcode_error === true ? "Please enter zipcode" : this.state.invalid_zipcode_error ? "Please enter 5 digits valid zip code" : ""}
										/>
									</Grid>
									{this.state.mode === "EDIT" &&
										<Grid item xs={6} style={{ paddingRight: 8 }}>
											<div style={{ padding: "2% 0 2% 0" }}>
												<Typography style={{ fontFamily: "Futura-Book", fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>Status</Typography>
												<FormControl component="fieldset">
													<RadioGroup aria-label="position" name="position" value={this.state.status}
														onChange={(event) => this.setState({ status: event.target.value, status_error: false })} row>
														<FormControlLabel
															value="ACTIVE"
															control={<Radio style={{ color: theme.palette.primary.main }} />}
															label={<span style={styles.labelStyle}>Active</span>}
															labelPlacement="end"
														/>
														<FormControlLabel
															value="INACTIVE"
															control={<Radio style={{ color: theme.palette.primary.main }} />}
															label={<span style={styles.labelStyle}>In Active</span>}
															labelPlacement="end"
														/>
													</RadioGroup>
													{this.state.status_error === true && <FormHelperText style={{ color: "red" }}>{"Please select status"}</FormHelperText>}
												</FormControl>
											</div>
										</Grid>
									}
								</Grid>
							</Grid>
							{this.state.mode === "ADD" &&
								<Grid item xs={6} >
									<Grid container justify="space-between" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
										<Grid item xs={12}>
											<Typography style={{ fontFamily: "Futura-Heavy" }}>Primary contact</Typography>
										</Grid>
										<Grid item xs={6} style={{ paddingRight: 8 }}>
											<TextField
												id="search-phone"
												label="Phone number"
												name="search-phone"
												style={styles.margin}
												value={this.state.search_phone}
												onChange={(event) => event.target.value.match(/^\d{0,10}$/g) && this.setState({ search_phone: event.target.value, search_phone_error: false, invalid_search_phone_error: false, primary_contact_first_name: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_first_name, primary_contact_last_name: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_last_name, primary_contact_email: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_email })}
												margin="normal"
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													styles: { input: styles.inputStyle },
													startAdornment: (
														<InputAdornment position="start">
															<Typography>{this.state.primary_contact_country_code}</Typography>
														</InputAdornment>
													)
												}}
												autoComplete="off"
												fullWidth
												error={this.state.search_phone_error ? true : this.state.invalid_search_phone_error ? true : false}
												helperText={this.state.search_phone_error === true ? "Please enter phone number" : this.state.invalid_search_phone_error ? "Please enter valid phone number" : ""}
											/>
										</Grid>
										<Grid item xs={6} >
											<div style={{ padding: 8, justifyContent: "center", alignItems: "center", display: "flex" }}>
												<Button size="small" onClick={() => this.searchUser()} variant="contained" color="primary" style={styles.addButton}>Find User</Button>
											</div>
											<Typography style={{ fontSize: "10px", color: "#20478E", textAlign: "center" }}>(If user already exist)</Typography>
										</Grid>
										<Grid item xs={6} style={{ paddingRight: 8 }}>
											<TextField
												id="first name"
												label="First Name"
												name="first name"
												style={styles.margin}
												value={this.state.primary_contact_first_name.charAt(0).toUpperCase() + this.state.primary_contact_first_name.slice(1)}
												autoComplete="off"
												onChange={(event) => this.setState({ primary_contact_first_name: event.target.value, primary_contact_first_name_error: false })}
												margin="normal"
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													styles: { input: styles.inputStyle }
												}}
												fullWidth
												error={this.state.primary_contact_first_name_error === true ? true : false}
												helperText={this.state.primary_contact_first_name_error === true ? "Please enter the first name" : false}
											/>
										</Grid>
										<Grid item xs={6} style={{ paddingRight: 8 }}>
											<TextField
												id="last name"
												label="Last Name"
												name="last name"
												autoComplete="off"
												style={styles.margin}
												value={this.state.primary_contact_last_name.charAt(0).toUpperCase() + this.state.primary_contact_last_name.slice(1)}
												onChange={(event) => this.setState({ primary_contact_last_name: event.target.value, primary_contact_last_name_error: false })}
												margin="normal"
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													styles: { input: styles.inputStyle }
												}}
												fullWidth
												error={this.state.primary_contact_last_name_error === true ? true : false}
												helperText={this.state.primary_contact_last_name_error === true ? "Please enter last name" : false}
											/>
										</Grid>
										<Grid item xs={6} style={{ paddingRight: 8 }} >
											<TextField
												id="email"
												label="E-mail"
												name="email"
												autoComplete="off"
												style={styles.margin}
												value={this.state.primary_contact_email}
												onChange={(event) => this.setState({ primary_contact_email: event.target.value, primary_contact_email_error: false, invalid_primary_contact_email_error: false })}
												margin="normal"
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													styles: { input: styles.inputStyle }
												}}
												fullWidth
												error={this.state.primary_contact_email_error === true ? true : this.state.invalid_primary_contact_email_error ? true : false}
												helperText={this.state.primary_contact_email_error === true ? "Enter the email" : this.state.invalid_primary_contact_email_error ? "Please enter valid mail" : false}
											/>
										</Grid>
									</Grid>
								</Grid>}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={() => this.handleSubmit(this.state.mode)} variant="contained" color="primary" style={styles.addButton}>Submit</Button>
						<Button size="small" onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.isLoading}
					styles={{
						paper: styles.dialogPaper,
					}}
				>
					<div style={{ boxShadow: 0, overflow: "none" }}>
						<CircularProgress color="primary" size={40} />
					</div>
				</Dialog>
			</div>
		)
	}
}

/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
	return {
		partner: state.partnerReducer.partner,
		fetch_user: state.userReducer.fetch_user,
	}
}

Partner.propTypes = {
	styles: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(Partner));