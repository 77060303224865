import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Alert } from "@mui/material";
import { ALERT_DETAIL } from "../../actions/actionTypes"
import { connect } from "react-redux";
import { compose } from "redux";
import { Outlet} from 'react-router-dom';

const styles = {
  root: {
    // paddingTop: 64,
    height: '100%'
  },
  content: {
    height: '100%'
  }
};

const Minimal = props => {
 
  return (
    <div style={styles.root}>
      <Snackbar
        open={props.openAlert}
        onClose={() => props.dispatch({ type: ALERT_DETAIL, data: false, message: "", severity: "" })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000} >
        <Alert icon={false} variant="filled" severity={props.alertSeverity} >{props.alertMessage}</Alert>
       </Snackbar>
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openAlert: state.sessionReducer.openAlert,
    alertSeverity: state.sessionReducer.alertSeverity,
    alertMessage: state.sessionReducer.alertMessage
  };
};

Minimal.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default compose( connect(mapStateToProps))(Minimal);