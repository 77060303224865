import { FETCH_PARTNER, FETCH_PARTNER_DETAIL } from "../actions/actionTypes"


const initialstate = {
    partner: [],
    partnerDetail: null
}

export default function partnerReducer(state = initialstate, action) {
    switch (action.type) {

        case FETCH_PARTNER:
            return {
                ...state,
                partner: action.data
            }
        case FETCH_PARTNER_DETAIL:
            return {
                ...state,
                partnerDetail: action.data
            }
        default:
            return state;
    }
}