import React, { Component } from "react";
import { Typography, TextField, Grid, Button, InputAdornment, IconButton } from "@mui/material";
import { connect } from "react-redux";
import { resetPassword } from "../../actions/registerAction"
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { ALERT_DETAIL } from "../../actions/actionTypes"
import theme from "../../theme";
import { withRouter } from "../../withRouter";
import { THEMECOLOR } from "../../helpers/Constants";

const DOT_LENGTH = 10;

/** Created common styles for UI  */
const styles ={
	paper: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		height: '1vh'
	},
	topContainer: {
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
		height: "200px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderBottomLeftRadius: "10%",
		borderBottomRightRadius: "10%"
	},
	bottomContainer: {
		backgroundColor: "#fff",
		borderRadius: 10,
		margin: "0px 20px",
		padding: "10px",
		boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
		transition: "0.3s",
		width: '30px%'
	},
	titleHead: {
		fontSize: 20,
		fontWeight: "bold",
		textAlign: "center",
		padding: 5
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
		textAlign: "center",
		padding: 5,
		color: "#fff",
	},
	titleText: {
		fontSize: 15,
		color: "#444444",
		textAlign: "center",
		padding: "0px 15px"
	},
	buttonContainer: {
		padding: 25,
		paddingTop: 35
	},
	bottomTextContainer: {
		padding: 30,
		textAlign: "center",
	},
	inputStyle: {
		fontSize: "15px !important",
		padding: "5px !important",
	},
	inputRoot: {
		borderRadius: "0px !important"
	},
	topText: {
		textAlign: "center",
		fontSize: 18,
		paddingVertical: 5
	},
	secondaryText: {
		textAlign: "center",
		fontSize: 16,
		paddingVertical: 5,
		color: "#000"
	},
	innerInpuView: {
		display: "flex",
		flexDirection: 'row',
		alignItems: 'center',
		borderBottomWidth: 1,
		borderBottomColor: "#F6F6F6"
	},
	textView: {
		paddingVertical: 5
	},
	pickView: {
		display: "flex",
		flexDirection: "row",
		alignContent: "center",
		alignItems: "center",
		alignSelf: "center"
	},
	borderDot: {
		width: 24,
		height: 24,
		borderRadius: 12,
		backgroundColor: "#fff",
		borderWidth: 6,
		borderColor: "rgba(265,265,265,0.2)"
	},
	bottomTextView: {
		display: "flex",
		flexDirection: "row",
		alignItems: 'center',
		justifyContent: "center",
		marginTop: 15
	},
	labelText: {
		fontSize: 16,
		color: "#7F8EB8",
	},
	bottomPolicyTextView: {
		display: "flex",
		flexDirection: "row",
		alignItems: 'center',
		justifyContent: "flex-start",
		marginLeft: 10,
		marginTop: 5
	},
	buttons: {
		marginTop: 50,
		marginBottom: 10,
		borderRadius: 5,
		fontSize: 15,
		fontWeight: "bold",
		backgroundColor:  theme.palette.primary.main
	},
	continueText: {
		marginTop: 5,
		fontSize: 15,
		color: "#ffffff",
		fontWeight: "bold",
		textTransform: 'capitalize'
	},
	infoContainer: {
		display: 'flex',
		width: "100%",
		justifyContent: "center",
		alignItems: "center",
		marginBottom: 15
	},
	dotView: {
		display: 'flex',
		flexDirection: "row",
		width: "90%",
		alignItems: "center",
		justifyContent: "space-around",
		marginTop: -100
	},
	dotStyle: {
		width: 12,
		height: 12,
		borderRadius: 6,
		backgroundColor: "rgba(265,265,265,0.5)"
	},
};

/**
* @class
*
* Class representing ResetPassword component
*
*/
class ResetPassword extends Component {
	constructor() {
		super();
		this.state = {
			password: "",
			confirm_password_values: "",
			password_error: false,
			invalid_password_error: false,
			confirm_password_error: false,
			invalid_confirm_password_error: false,
			secureEntry: true,
			secureEntryConfirm: true
		}
	}

	/** RenderDotView function using to returning a dotted view */
	renderDotView = () => {
		let DotArray = []
		for (let i = 0; i < DOT_LENGTH; i++) {
			DotArray.push(<div key={i.toString()} id={i} style={{
				width: 4,
				height: 4,
				borderRadius: 2,
				backgroundColor: "rgba(265,265,265,0.5)"
			}} />)
		}
		return DotArray;
	}

	/**
	 * This function checks if the given password is valid or not
	 * @function
	 * @param {string} params - given password
	 */
	passwordValidation = (params) => {
		var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(\S){8,}$/
		return re.test(params)
	}

	/**
	* This function validates if both passwords are same or not
	* @function
	* @param {string} confirm_password that given confirm password
	* @param {string} password that given password
   */
	passwordConfirmValidation = (confirm_password, password) => {
		if (confirm_password === password) {
			return true;
		}
		return false;
	};

	/** This function used to change secure entry */
	handleClickShowPassword = () => {
		this.setState({ secureEntry: !this.state.secureEntry })
	}

	/** This function used to change secure entry */
	handleClickConfirmShowPassword = () => {
		this.setState({ secureEntryConfirm: !this.state.secureEntryConfirm })
	}

	/**
	 * It validates password and confirm_password to change, it shows error text if wrong
	 * @function 
	 */
	onSubmit = () => {
		let { password, confirm_password_values } = this.state;
		let isError = false;

		if (password === "" || password === null) {
			this.setState({ password_error: true });
			isError = true;
		} else {
			if (!this.passwordValidation(password)) {
				this.setState({ invalid_password_error: true });
				isError = true;
			}
		}

		if (confirm_password_values === "" || confirm_password_values === null) {
			this.setState({ confirm_password_error: true });
			isError = true;
		} else {
			if (!this.passwordConfirmValidation(confirm_password_values, password)) {
				this.setState({ invalid_confirm_password_error: true });
				isError = true;
			}
		}

		if (isError === false) {
			if (this.props.userConfirmationId !== null) {
				var data = {};
				data.phone = this.props.phone;
				data.password = this.state.password;
				data.userId = this.props.userConfirmationId;
				this.props.dispatch(resetPassword(this, data))
			} else {
				this.props.dispatch({ type: ALERT_DETAIL, data: true, message: `Your session has  expired. Please try again`, severity: "error" })
				this.props.navigate("/")
			}
		}
	}

	/** Rendering reset password */
	render() {
		return (
			<div style={styles.paper}>
				<Grid container justifyContent="center">
					<Grid item xs={12}>
						<div style={styles.topContainer}>
							<Typography style={styles.title}>Sign Up</Typography>
						</div>
						<div style={styles.infoContainer}>
							<div style={styles.dotView}>
								<div cstyle={styles.dotStyle} />
								{this.renderDotView()}
								<div style={styles.borderDot} />
								{this.renderDotView()}
								<div style={styles.dotStyle} />
							</div>
						</div>
						<Grid container justifyContent="center" style={{marginTop:-30}}>
							<Grid item xs={12} sm={6} md={4}>
								<div style={styles.bottomContainer}>
									<Typography style={styles.topText}>Set Your Password</Typography>
									<Typography style={styles.secondaryText}>Enter your Password to sign up</Typography>
									<div style={styles.textView}>
										<div style={styles.innerInpuView}>
											<div style={styles.pickView} />
											<TextField
												margin="normal"
												fullWidth
												id="password"
												label="Password"
												variant="standard"
												type={this.state.secureEntry ? "password" : "text"}
												name="password"
												autoComplete="off"
												InputProps={{ // <-- This is where the toggle button is added.
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={this.handleClickShowPassword}
															>
																{this.state.secureEntry ? <Visibility /> : <VisibilityOff />}
															</IconButton>
														</InputAdornment>
													)
												}}
												value={this.state.password}
												onChange={(event) => this.setState({ password: event.target.value, password_error: false, invalid_password_error: false })}
												error={this.state.password_error === true ? true : this.state.invalid_password_error === true ? true : false}
												helperText={this.state.password_error === true ? "Enter password" : this.state.invalid_password_error === true ? "Please enter a password with at least 8 characters, a number, an uppercase and a lowercase character." : ""}
											/>
										</div>
									</div>
									<div style={styles.textView}>
										<div style={styles.innerInpuView}>
											<TextField
												margin="normal"
												fullWidth
												id="confirm_password"
												type={this.state.secureEntryConfirm ? "password" : "text"}
												label="Confirm Password"
												name="password"
												variant="standard"
												autoComplete="off"
												InputProps={{ // <-- This is where the toggle button is added.
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={this.handleClickConfirmShowPassword}
															>
																{this.state.secureEntryConfirm ? <Visibility /> : <VisibilityOff />}
															</IconButton>
														</InputAdornment>
													)
												}}
												value={this.state.confirm_password_values}
												onChange={(event) => this.setState({ confirm_password_values: event.target.value, confirm_password_error: false, invalid_confirm_password_error: false })}
												error={this.state.confirm_password_error === true ? true : this.state.invalid_confirm_password_error === true ? true : false}
												helperText={this.state.confirm_password_error === true ? "Enter confirm password" : this.state.invalid_confirm_password_error === true ? "confirm password doesn't match with password" : ""}
											/>
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
						<Grid container justifyContent="center">
							<Grid item xs={12} sm={6} md={4}>
								<div style={styles.bottomTextContainer}>
									<Button
										type="Button"
										fullWidth
										variant="contained"
										color="primary"
										style={styles.buttons}
										onClick={() => this.onSubmit()}
									>
										<Typography style={styles.continueText}>Continue</Typography>
									</Button>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		);
	}
}

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {
		phone: state.registerReducer.phone,
		userConfirmationId: state.sessionReducer.userConfirmationId
	};
};

ResetPassword.propTypes = {
	
};

export default  withRouter(connect(mapStateToProps)(ResetPassword));