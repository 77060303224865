import React, { Component } from "react";
import { Typography, Grid, Button, Dialog, DialogTitle, List, ListItem, ListItemText, IconButton } from "@mui/material";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { fetchUnverifiedRecord, fetchUserTestInformation } from "../../actions/testAction";
import { END_POINT } from "../../helpers/Constants";
import moment from 'moment';
import { fetchVaccineWithoutBooster } from '../../actions/vaccineAction';
import { fetchTesttype, fetchAlreadyVaccinated } from '../../actions/testtypeAction';
import { SELECTED_TEST_TYPE } from "../../actions/actionTypes";
import _ from "underscore";
import Covid from "../../assets/images/covid.png";
import Antibody from "../../assets/images/antibody.png";
import Vaccination from "../../assets/images/vaccination.png";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import CancelIcon from '@mui/icons-material/Cancel';
import User from "../../assets/images/account_circle.png";
import { withRouter } from "../../withRouter";
import { THEMECOLOR } from "../../helpers/Constants";
/** Created common styles for UI  */
const styles = {
	paper: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		// backgroundColor: "#20478E",
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
		height: '100vh',
		padding: 20
	},
	container: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundColor: "#FFF",
		margin: 30,
		borderRadius: 10
	},
	row: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex"
	},
	userImage: {
		height: 130,
		width: "auto",
		marginTop: -30,
		borderRadius: 10,
		objectFit: "cover"
	},
	typeText: {
		fontSize: 15,
		paddingTop: 10,
		textAlign: "center",
		color: "#848484"
	},
	nameText: {
		fontSize: 17,
		paddingTop: 5,
		paddingBottom: 10,
		textAlign: "center",
		color: "#000",
		fontWeight: "bold"
	},
	divider: {
		width: "100%",
		height: 1,
		backgroundColor: "#D1CFCF"
	},
	innerContainer: {
		padding: 10
	},
	testText: {
		fontSize: 15,
		paddingTop: 3,
		color: "#000"
	},
	resultText: {
		fontSize: 15,
		paddingTop: 3,
		color: "#000"
	},
	dateText: {
		fontSize: 13,
		paddingTop: 3,
		color: "#000"
	},
	buttonContainer: {
		padding: "0px 30px"
	},
	button: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		marginTop: 5,
		padding: 7,
		fontSize: 15,
		backgroundColor: "#fff",
		color: THEMECOLOR.SECONDARY,
		fontWeight: "bold",
		borderRadius: 5,
		'&:hover': {
			backgroundColor: "#4B86E3",
		},
	},
	secondButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		marginTop: 5,
		padding: 7,
		fontSize: 15,
		backgroundColor: "#B0BAD7",
		color: "#fff",
		fontWeight: "bold",
		borderRadius: 5,
		'&:hover': {
			backgroundColor: "#B0BAD7",
		},
	},
	panelButton: {
		paddingVertical: 10,
		borderBottomColor: "#D4D2CA",
		borderBottomWidth: 1,
		borderStyle: "solid"
	},
	panelTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: "#556599"
	},
	panelText: {
		fontSize: 13,
		color: "#96A1C4",
	},
	lineStyle: {
		height: 3,
		width: 120,
		background: "#727272",
	},
	divStyle: {
		display: 'flex',
		padding: 5,
		justifyContent: 'center'
	},
		deleteText: {
		cursor: "pointer",
		textAlign: "center",
		fontSize: 16,
		color: "#000",
		textTransform: "Capitalize",
		padding: "8px 15px"
	},
	closeIcon: {
		padding: 5,
		color: "#B22222",
		backgroundColor: "#ffffff",
		'&:hover': {
			backgroundColor: "#ffffff",
		}
	},
};

/**
 * @class
 *
 * Class representing ScanResult component
 *
 */
class ScanResult extends Component {
	constructor() {
		super();
		this.state = {
			testtypes: [],
			openTestList: false,
			imageDialog: false,
			selectedTest: null,
			imageUrl: null
		}
	}

	/** This is a component life cycle method and used to fetch datas*/
	async componentDidMount() {
		// this.props.dispatch(fetchUploadedDocument(this));
		/** fetching Vaccine details. */

		console.log("****componentDidMount*****" + JSON.stringify(this.props.location.state?.qrCode))


		this.props.dispatch(fetchVaccineWithoutBooster())
		/** fetching Testtype details. */
		this.props.dispatch(fetchTesttype());
		/** fetching TestResult details. */
		this.props.dispatch(fetchUserTestInformation(this.props.location.state.qrCode))
	}

	/**
	 * This function represents to fetch UnverifiedRecord and redirected to  TestHistory screen.
	 */
	unverifiedRecord = () => {
		let id = this.props.test_result !== null ? this.props.test_result.userProfileId : null
		this.props.dispatch(fetchUnverifiedRecord(this, id))
		this.props.navigate("/test-history", { state: { "from_scan_result": "from_scan_result", "id": id } })

	}

	/**
	 * This function represents to display vaccine result
	 * @function 
	 * @param {*} test 
	 * @param {*} styles 
	 * @param {*} uploadRecord 
	 * @returns 
	 */
	customVaccineResult = (test, styles, uploadRecord) => {
		let activeVaccines = this.props.allVaccine !== null ? this.props.allVaccine.filter(x => x.status === "ACTIVE") : null
		let vaccine = (activeVaccines !== null && test.vaccinResult !== null) ? activeVaccines.find(x => test.vaccinResult.filter(y => y.vaccineId === x.id && y.status === "COMPLETED").length > 0) : null

		return (
			<div>

				{(test.booster !== null && test.booster !== undefined) ?
					<Typography style={styles.resultText}>{(vaccine !== null && vaccine !== undefined) ? vaccine.vaccineShots.length === test.shotNumber ? "VACCINATED" + (test.booster !== 0 ? ` + ${test.booster} Booster` : '') : `${test.shotNumber} of ${vaccine.vaccineShots.length} shots of ${vaccine.name}` : (uploadRecord !== undefined && uploadRecord !== null) && uploadRecord.testUploadRecords.filter(r => r.testTypeId === test.testTypeId && r.status === "PENDING").length > 0 ? "Record Uploaded but Not Verified" : "NOT VACCINATED"}</Typography>
					:
					<Typography style={styles.resultText}>{(vaccine !== null && vaccine !== undefined) ? vaccine.vaccineShots.length === test.shotNumber ? "VACCINATED" : `${test.shotNumber} of ${vaccine.vaccineShots.length} shots of ${vaccine.name}` : (uploadRecord !== undefined && uploadRecord !== null) && uploadRecord.testUploadRecords.filter(r => r.testTypeId === test.testTypeId && r.status === "PENDING").length > 0 ? "Record Uploaded but Not Verified" : "NOT VACCINATED"}</Typography>
				}
				{
					(test.boosterDate !== undefined && test.boosterDate !== null) ?
						<Typography style={styles.dateText}>Vaccination Date: {test.boosterDate !== null ? `${moment.utc(test.boosterDate, 'YYYY-MM-DD').format('MMM DD, YYYY')}` : ""}</Typography>
						:
						<Typography style={styles.dateText}>{(vaccine !== null && vaccine !== undefined && test.vaccinResult.length > 0) ? `Vaccination Date: ${moment.utc(test.vaccinResult[0].vaccinationDate, 'YYYY-MM-DD').format("MMM DD, YYYY")}` : "----"}</Typography>
				}

			</div>
		)
	}

	/**
	 * This function represents to open dialog
	 * @function 
	 * @param {*} type 
	 * @returns 
	 */
	openRecord = (type) => {
		let filterTesttypes = [];
		if (type === "C19") {
			filterTesttypes = this.props.testtype.filter(x => x.isCovid === true);
		} else {
			filterTesttypes = this.props.testtype.filter(x => x.isCovid === false);
		}
		this.setState({
			openTestList: true,
			testtypes: filterTesttypes
		})
	}

	/**
	 * This function represents to test upload
	 * @function 
	 * @param {*} testType 
	 * @returns 
	 */
	handleTestUpload = (testType) => {
		this.setState({ openTestList: false })
		if (testType.isVaccine === true) {
			let clientID = this.props.test_result !== null ? this.props.test_result.userProfileId : null
			this.props.dispatch(fetchAlreadyVaccinated(this, testType, clientID))
		} else {
			this.props.dispatch({ type: SELECTED_TEST_TYPE, data: testType })
			this.props.navigate("/upload-test-type-image")
		}
	}

	openDialog = (test) => {
		this.setState({ imageDialog: true, selectedTest: test, imageUrl: `${END_POINT}image/${test.isVaccine === true ? "v" : "t"}/${test.storageKey}` })
	}

	closeDialog = () => {
		this.setState({ imageDialog: false, selectedTest: null, imageUrl: null })
	}

	/** Rendering a screen for displaying the test results and for uploading the test documents */
	render() {
		const {
			// styles,
			test_result } = this.props;
		let imageurl = (test_result !== null && test_result.userImage !== null) ? `${END_POINT}image/i/${test_result.userImage}` : null;
		let uploadRecord = _.pick(test_result, "testUploadRecords")
		return (
			<div style={styles.paper}>
				<Grid container justifyContent="center">
					{test_result !== null &&
						<Grid item xs={12} sm={6} md={4}>
							<Typography style={{textAlign:"center", paddingBottom:20, color:'#fff', fontWeight:'bold'}}>Patient’s Profile</Typography>
							<div style={styles.container}>
								<div style={styles.row}>
									{/* <img src={imageurl} alt="profile" style={styles.userImage} /> */}
									{imageurl !== null ?
										<img
											alt="Logo"
											src={imageurl}
											style={styles.userImage}
										/> :
										<img src={User} height="80" width="80" alt="icon" style={{
											backgroundColor: "white", border: "1px solid #ddd",
											borderRadius: "10px", objectFit: "cover"

										}} />}
								</div>
								<div>
									{/* <Typography style={styles.typeText}>User</Typography> */}
									<Typography style={styles.nameText}>{test_result.firstName + " " + test_result.lastName}</Typography>
								</div>
								<div style={styles.divider} />
								<div style={styles.innerContainer}>
									{test_result.test.map(test =>
										test.isCovid === true &&
										(test.isVaccine === false ?
											test.displayDate !== null && test.testResult !== null && test.testResult.length > 0 &&
											<div>
												<div style={{ display: "flex", padding: "5px 0px" }}>
													<div>
														{test.isAntibody ?
															<img src={Antibody} width={30} height="auto" alt="Antibody" />
															: <img src={Covid} width={30} height="auto" alt="Covid" />}
													</div>
													<div style={{ paddingLeft: "10px" }}>
														<Typography style={styles.testText}> <span style={{ fontWeight: "bold" }}>{test.testname}</span></Typography>
														{test.type === "TEST" &&
															(test.isAntibody === true ?
																<Typography style={styles.resultText}>{(test.testResult !== null && test.testResult.length > 0) ? test.testResult[0].result_value === "POSITIVE" ? "Antibody Detected" : "No Antibody Detected" : (uploadRecord !== undefined && uploadRecord !== null) && uploadRecord.testUploadRecords.filter(r => r.testTypeId === test.testTypeId && r.status === "PENDING").length > 0 ? "Test Uploaded but Not Verified" : "NOT TESTED"}</Typography>
																:
																<Typography style={styles.resultText}>{(test.testResult !== null && test.testResult.length > 0) ? test.testResult[0].result_value : (uploadRecord !== undefined && uploadRecord !== null) && uploadRecord.testUploadRecords.filter(r => r.testTypeId === test.testTypeId && r.status === "PENDING").length > 0 ? "Test Uploaded but Not Verified" : "NOT TESTED"}</Typography>
															)}
														<Typography style={styles.dateText}>{(test.testResult !== null && test.testResult.length > 0 && test.displayDate !== null) ? `Test Date: ${moment.utc(test.displayDate, 'YYYY-MM-DD').format("MMM DD, YYYY")}` : "----"}</Typography>
													</div>
												</div>
												<div style={styles.divider} />
												<div>
													{(test.isLabTest === true && test.pdfReportStorageKey !== null) && <Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography>}
													{(test.isLabTest === false && test.storageKey !== null) && <Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography>}
												</div>
												<div style={styles.divider} />
											</div> :
											test.vaccinResult.length > 0 && test.vaccinResult[0].vaccinationDate !== null &&
											<div>
												<div style={{ padding: "5px 0px", display: "flex" }}>
													<div>
														<img src={Vaccination} width={30} height="auto" alt="Vaccination" />
													</div>
													<div style={{ paddingLeft: "10px" }}>
														<Typography style={styles.testText}>Latest <span style={{ fontWeight: "bold" }}>{test.testname}</span></Typography>
														{this.customVaccineResult(test, styles, uploadRecord)}
													</div>
												</div>
												<div>
													{(test.isLabTest === true && test.pdfReportStorageKey !== null) && <Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography>}
													{(test.isLabTest === false && test.storageKey !== null) && <Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography>}
												</div>
											</div>
										)
									)}
								</div>
							</div>
							{this.props.user_type === "MSP" ?
								<div style={styles.buttonContainer}>
									<Button
										type="Button"
										fullWidth
										variant="contained"
										onClick={() => this.unverifiedRecord()}
										style={styles.button}
									>
										See Patient’s Unverified Records
									</Button>
									{/* <Button
										type="Button"
										fullWidth
										variant="contained"
										onClick={() => this.openRecord('C19')}
										style={styles.secondButton}
									>
										Upload Patient's Record
									</Button> */}
								</div>
								:
								<div style={styles.buttonContainer}>
									<Button
										type="Button"
										fullWidth
										variant="contained"
										onClick={() => this.props.navigate("qr-scanner")}
										style={styles.button}
									>
										Scan Another File
									</Button>
									<Button
										type="Button"
										fullWidth
										variant="contained"
										onClick={() => this.props.navigate("my-activity")}
										style={styles.secondButton}
									>
										Return Home
									</Button>
								</div>}
						</Grid>}
				</Grid>
				<Dialog maxWidth={"xs"} fullWidth open={this.state.imageDialog} onClose={() => this.setState({ imageDialog: false })}>
					{this.state.selectedTest !== null && <div style={{ overflow: "hidden" }}>
						<div style={{ display: "flex", justifyContent: "space-between", padding: 5, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
							<Typography style={{ ...styles.description, display: "flex", alignItems: "center", color: '#fff' }}>{this.state.selectedTest !== null ?
								`${this.state.selectedTest.testname}`
								//  ${this.state.selectedTest.isVaccine ? (this.state.selectedTest.vaccinResult.length > 0 && this.state.selectedTest.vaccinResult[0].vaccinationDate !== null) ? `${moment(this.state.selectedTest.vaccinResult[0].vaccinationDate).format("MM/DD/YYYY")}` : "" : this.state.selectedTest.displayDate !== null ? `${moment(this.state.selectedTest.displayDate).format("MM/DD/YYYY")}` : ""}
								: ""}</Typography>
							<IconButton onClick={() => this.closeDialog()} style={styles.closeIcon}>
								<CancelIcon fontSize="medium" />
							</IconButton>
						</div>

						{this.state.selectedTest.isLabTest === true ?
							this.state.selectedTest.pdfReportStorageKey !== null ?
								<iframe title={`${this.state.selectedTest.pdfReportStorageKey}`} src={`${END_POINT}pdf-test-report/${this.state.selectedTest.pdfReportStorageKey}#toolbar=0`} style={{ width: "100%", height: "90vh" }} />
								:
								<Typography style={{ display: "flex", alignItems: "center", height: "40vh", justifyContent: "center" }}>There is no document to view</Typography>
							: <TransformWrapper>
								<TransformComponent>
									<img src={this.state.imageUrl} alt="test-img" style={{ width: "100%", height: "auto" }} />
								</TransformComponent>
							</TransformWrapper>}

					</div>}
				</Dialog>
				<Dialog onClose={() => this.setState({ openTestList: false })} aria-labelledby="simple-dialog-title" open={this.state.openTestList}>
					<DialogTitle id="simple-dialog-title">
						<div style={styles.divStyle}>
							<div style={styles.lineStyle} />
						</div>
					</DialogTitle>
					<List>
						{this.state.testtypes.map((testType, index) => (
							<ListItem button onClick={() => this.handleTestUpload(testType)} key={index} disabled={testType.status === "PENDING" ? true : false}>
								<ListItemText
									primary={
										<React.Fragment >
											<Typography style={styles.panelTitle}>{`${testType.name}`}</Typography>
											{testType.status !== "ACTIVE" && <Typography style={styles.panelTitle}>{`(Coming soon)`}</Typography>}
										</React.Fragment>
									}
									secondary={
										<React.Fragment>
											<Typography style={styles.panelText}>{`${testType.description}`}</Typography>
										</React.Fragment>
									}
								/>
							</ListItem>
						))}
					</List>
				</Dialog>
			</div>
		);
	}
}

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {
		user_type: state.sessionReducer.user_type,
		test_result: state.testReducer.test_result,
		allVaccine: state.vaccineReducer.vaccine,
		testtype: state.testtypeReducer.testtype
	};
};

ScanResult.propTypes = {
	// styles: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(ScanResult));