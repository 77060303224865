import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { MenuItem, TextField, Grid, Typography, Button, FormControl, RadioGroup, FormControlLabel, Radio, Card, IconButton, Chip, Dialog, CircularProgress, Checkbox, FormGroup } from "@mui/material";
import moment from "moment";
import { sendCustomSMS, findUserForSMS } from "../../actions/userAction";
import { ALERT_DETAIL } from "../../actions/actionTypes";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import _ from "underscore";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { withRouter } from "../../withRouter";
import theme from "../../theme";

const styles = {
	paper: {
		padding: 20,
		paddingBottom: window.screen.width >= 640 ? 80 : 20
	},
	inputRoot: {
		backgroundColor: "#FFFFFF",
		border: "1px solid #E1E7ED",
		borderRadius: "4px",
		fontSize: "15px !important",
		fontFamily: "Lato-Regular !important",
		"&:hover": {
			border: "1px solid #0E465E",
			backgroundColor: "#FFFFFF"
		}
	},
	inputStyle: {
		fontFamily: "Lato-Regular !important",
		fontSize: "15px !important",
		padding: "10px !important",
		color: "#0E465E",
		opacity: 1,
		"&&:after": {
			color: "#0E465E",
		}
	},
	
	multiline: {
		padding: "5px",
	},
	underline: {
		"&&&:before": {
			borderBottom: "none"
		},
		"&&:after": {
			borderBottom: "none"
		}
	},
	button: {
		backgroundColor: theme.palette.primary.main,
		color: "white",
		borderRadius: "10",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: '#20478e',
		}
	},
	fontCellHeader: {
		color: "#fff",
		fontSize: 18,
		fontWeight: "bold"
	},
	fontCell: {
		padding: 0
	},
	searchButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		padding: "5px 15px",
		fontSize: 12,
		maxHeight: 40,
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		fontWeight: "bold",
		borderRadius: 5,
		// '&:hover': {
		// 	backgroundColor: "#4B86E3",
		// },
	},
	sendButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		padding: "5px 20px",
		fontSize: 12,
		maxHeight: 40,
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		fontWeight: "bold",
		borderRadius: 5,
		'&:hover': {
			backgroundColor: "#20478e",
		},
	},
	dialogPaper: {
		backgroundColor: "rgba(0,0,0,0)",
		boxShadow: "none",
		overflow: "hidden"
	}
};

class SendSms extends Component {
	constructor() {
		super();
		this.state = {
			sms_type: "PHONE",
			test_type: "ALL",
			test_result_status: "ALL",
			zip_code: "",
			phone_number: "",
			start_date: moment().format("MM/DD/YYYY"),
			end_date: moment().format("MM/DD/YYYY"),
			message: "Your test results are ready. Please click to download the MedFilo app by Premier on Apple or Android to log in and view your results now: http://onelink.to/confirmd.\nThe MedFilo app can also be used as a secure digital wallet to access all your medical records in one place for travel, work, and taking control of your health records today.\nAny questions, please call 866-451-2571",
			users: [],
			messageDisable: false,
			findUser: null,
			isLoading: false,
			is_sms: false,
			is_notification: false
		}
	}

	sendSMS = () => {
		const { sms_type, test_result_status, test_type, zip_code, users, start_date, end_date, message, is_sms, is_notification } = this.state;
		let isError = false;

		switch (sms_type) {
			case "PHONE":
				if (users.length === 0) {
					isError = true;
					this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Please add minimum one user", severity: "error" })
				}
				break;
			case "ZIP_CODE":
				if (!!zip_code === false || zip_code.length > 6 || zip_code.length < 5) {
					isError = true;
					this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Please enter valid zip code", severity: "error" })
				}
				break;
			case "TEST":
				if (start_date === "" || end_date === "" || start_date === null || end_date === null) {
					isError = true;
					this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Please enter valid start date and end date", severity: "error" })
				} else if (!moment(start_date).isValid() || !moment(end_date).isValid() || moment(start_date).isAfter(end_date)) {
					isError = true;
					this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Please enter valid start date and end date", severity: "error" })
				}
				break;
			case "PENDING_LAB_TEST":
				if (!moment(start_date).isValid() || !moment(end_date).isValid() || moment(start_date).isAfter(end_date)) {
					isError = true;
					this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Please enter valid start date and end date", severity: "error" })
				}
				break;
			case "REGISTERED_USERS":
				if (is_notification === false && is_sms === false) {
					isError = true;
					this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Please select one checkbox", severity: "error" })
				}
				break;
			default:
				break;
		}

		if (isError === false) {
			let data = {}
			data.sms_type = sms_type;
			data.phone_numbers = _.pluck(users, "phone");
			data.test_type = test_type;
			data.test_result_status = test_result_status;
			data.zip_code = zip_code;
			data.start_date = moment(start_date).format("YYYY-MM-DD");
			data.end_date = moment(end_date).format("YYYY-MM-DD");
			data.message = message;
			data.is_sms = is_sms;
			data.is_notification = is_notification;

			this.setState({ isLoading: true })
			this.props.dispatch(sendCustomSMS(this, data))
		}
	}

	selectSmsType = (event) => {
		this.setState({
			test_type: "ALL",
			test_result_status: "ALL",
			zip_code: "",
			phone_number: "",
			users: [],
			findUser: null,
			start_date: moment().format("MM/DD/YYYY"),
			end_date: moment().format("MM/DD/YYYY"),
			sms_type: event.target.value,
			is_sms: false,
			is_notification: false,
			messageDisable: event.target.value === "PENDING_LAB_TEST" ? true : false,
			message: event.target.value === "REGISTERED_USERS" ? "" : "Your test results are ready. Please click to download the MedFilo app by Premier on Apple or Android to log in and view your results now: http://onelink.to/confirmd.\nThe MedFilo app can also be used as a secure digital wallet to access all your medical records in one place for travel, work, and taking control of your health records today.\nAny questions, please call 866-451-2571"
		})
	}

	findUser = () => {
		if (!!this.state.phone_number && this.state.phone_number.length === 10) {
			this.props.dispatch(findUserForSMS(this, this.state.phone_number))
		} else {
			this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Please enter valid phone number", severity: "error" })
		}
	}
	addUser = () => {
		let users = [...this.state.users, this.state.findUser];
		this.setState({ users: [...new Set(users)], findUser: null, phone_number: "" })
	}

	clearUser = () => {
		this.setState({ findUser: null, phone_number: "" })
	}

	removeUser = (id) => {
		let users = this.state.users.filter(x => x.id !== id)
		this.setState({ users: [...new Set(users)] })
	}

	render() {
		// const { styles } = this.props;
		const { sms_type } = this.state;

		return (
			<div style={styles.paper}>
				<Typography style={{ padding: 5, color: theme.palette.secondary.light, fontSize: 17, fontWeight: "bold" }}>Message</Typography>
				<div>
					<FormControl component="fieldset">
						<RadioGroup aria-label="position" name="position" value={this.state.sms_type}
							onChange={(event) => this.selectSmsType(event)} row>
							<FormControlLabel
								value="PHONE"
								control={<Radio style={{ color: theme.palette.primary.main }} />}
								label={<span style={styles.labelStyle}>Phone</span>}
								labelPlacement="end"
							/>
							<FormControlLabel
								value="TEST"
								control={<Radio style={{ color: theme.palette.primary.main }} />}
								label={<span style={styles.labelStyle}>Tests</span>}
								labelPlacement="end"
							/>
							{/* <FormControlLabel
								value="ZIP_CODE"
								control={<Radio style={{ color: "#20478e" }} />}
								label={<span style={styles.labelStyle}>Zipcode</span>}
								labelPlacement="end"
							/> */}
							<FormControlLabel
								value="PENDING_LAB_TEST"
								control={<Radio style={{ color: theme.palette.primary.main }} />}
								label={<span style={styles.labelStyle}>Unregistered Users</span>}
								labelPlacement="end"
							/>
							<FormControlLabel
								value="REGISTERED_USERS"
								control={<Radio style={{ color: theme.palette.primary.main }} />}
								label={<span style={styles.labelStyle}>Registered Users</span>}
								labelPlacement="end"
							/>
						</RadioGroup>
					</FormControl>
				</div>
				<Grid container>
					{sms_type === "PHONE" && <Grid item xs={12}>
						<Grid container>
							<Grid item xs={12} md={6}>
								<div style={{ padding: "10px" }}>
									<Typography style={{ fontSize: 15, color: "#000000", padding: "10px 0px", fontWeight: "bold" }}>Phone Numbers</Typography>
									<div style={{ display: "flex", alignItems: "center" }}>
										<TextField
											autoComplete="off"
											required={true}
										    value={this.state.phone_number}
											inputProps={{ maxLength: 10 }}
											InputProps={{ styles: { input: styles.inputStyle, multiline: styles.multiline, underline: styles.underline, root: styles.inputRoot } }}
											onChange={(event) => this.setState({ phone_number: event.target.value.replace(/[^\d]+/g, '') })}
										/>
										<IconButton onClick={() => this.clearUser()}><HighlightOffIcon /></IconButton>
										<Button onClick={() => this.findUser()} style={styles.searchButton} >
											find User
										</Button>
									</div>
									<div>
										{this.state.findUser !== null && <Card style={{ backgroundColor: "#DDFFD4", padding: 10, marginTop: 10, width: "fit-content" }}>
											<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>
												{this.state.findUser.firstName} {this.state.findUser.lastName}
											</Typography >
											<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>
												{this.state.findUser.phone}
											</Typography>
											<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>
												{this.state.findUser.email}
											</Typography>
											<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>
												DOB : {this.state.findUser.dateOfBirth !== null ? moment(this.state.findUser.dateOfBirth).format("MM/DD/YYYY") : ""}
											</Typography>
											<Button onClick={() => this.addUser()} style={styles.button}>
												Add User
											</Button>
										</Card>}
									</div>
								</div>
							</Grid>
							<Grid item xs={12} md={6}>
								{this.state.users.length > 0 && <div>
									<Typography style={{ fontSize: 15, fontWeight: "bold" }}>Users List</Typography>
									<Card style={{ padding: 10 }}>
										{this.state.users.map(item => <Chip
											size="small"
											label={`${item.firstName} ${item.lastName} (${item.phone})`}
											color={"primary"}
											onDelete={() => this.removeUser(item.id)}
											deleteIcon={<HighlightOffIcon />}
											style={{ ...styles.activeChip, padding: "5px 15px", margin: "0px 10px" }}
										/>)}
									</Card>
								</div>}
							</Grid>
						</Grid>
					</Grid>}
					{sms_type === "TEST" && <Grid item xs={6} md={3}>
						<div style={{ padding: "10px" }}>
							<Typography style={{ fontSize: 15, color: "#000000", padding: "10px 0px" }}>Test Type</Typography>
							<TextField
								fullWidth
								size="small"
								autoComplete="off"
								required={true}
								value={this.state.test_type}
								InputProps={{ classes: { input: styles.inputStyle, underline: styles.underline, root: styles.inputRoot } }}
								onChange={(event) => this.setState({ test_type: event.target.value })}
								select
							>
								<MenuItem value={"ALL"}>{"All Test"}</MenuItem>
								<MenuItem value={"COVID"}>{"Covid Test"}</MenuItem>
								<MenuItem value={"ANTIBODY"}>{"Antibody Test"}</MenuItem>
							</TextField>
						</div>
					</Grid>}
					{sms_type === "TEST" && <Grid item xs={6} md={3}>
						<div style={{ padding: "10px" }}>
							<Typography style={{ fontSize: 15, color: "#000000", padding: "10px 0px" }}>Test Result</Typography>
							<TextField
								fullWidth
								size="small"
								autoComplete="off"
								required={true}
								value={this.state.test_result_status}
								//variant="standard"
								InputProps={{ styles: { input: styles.inputStyle, underline: styles.underline, root: styles.inputRoot } }}
								onChange={(event) => this.setState({ test_result_status: event.target.value })}
								select
							>
								<MenuItem value={"ALL"}>{"All"}</MenuItem>
								<MenuItem value={"POSITIVE"}>{"Detected"}</MenuItem>
								<MenuItem value={"NEGATIVE"}>{"Not Detected"}</MenuItem>
								<MenuItem value={"INVALID"}>{"Inconclusive"}</MenuItem>
								<MenuItem value={"PENDING"}>{"Pending"}</MenuItem>
							</TextField>
						</div>
					</Grid>}
					{sms_type === "ZIP_CODE" && <Grid item xs={6} md={4}>
						<div style={{ padding: "10px" }}>
							<Typography style={{ fontSize: 15, color: "#000000", padding: "10px 0px" }}>Zip Code</Typography>
							<TextField
								fullWidth
								size="small"
								autoComplete="off"
								required={true}
								value={this.state.zip_code}
								//variant="filled"
								
								inputProps={{ maxLength: 6 }}
								InputProps={{ styles: { input: styles.inputStyle, underline: styles.underline, root: styles.inputRoot } }}
								onChange={(event) => this.setState({ zip_code: event.target.value.replace(/[^\d]+/g, '') })}
							/>
						</div>
					</Grid>}
					{(sms_type === "PENDING_LAB_TEST" || sms_type === "TEST") &&
						<Grid item xs={6} md={3}>
							<div style={{ padding: "10px" }}>
								<Typography style={{ fontSize: 15, color: "#000000", padding: "10px 0px" }}>Start Date</Typography>
								<LocalizationProvider dateAdapter={AdapterMoment} locale="en-gb">
									<DatePicker
										fullWidth
										disableToolbar
										format="MM/DD/YYYY"
										value={moment(this.state.start_date)}
										onChange={(date) => this.setState({ start_date: date })}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										variant="filled"
										slotProps={{ textField: { size: 'small' } }}
									/>
								</LocalizationProvider>
							</div>
						</Grid>}
					{(sms_type === "PENDING_LAB_TEST" || sms_type === "TEST") &&
						<Grid item xs={6} md={3}>
							<div style={{ padding: "10px" }}>
								<Typography style={{ fontSize: 15, color: "#000000", padding: "10px 0px" }}>End Date</Typography>
								<LocalizationProvider dateAdapter={AdapterMoment} locale="en-gb">
									<DatePicker
										fullWidth
										disableToolbar
										format="MM/DD/YYYY"
										value={moment(this.state.end_date)}
										onChange={(date) => this.setState({ end_date: date })}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										variant="filled"
										slotProps={{ textField: { size: 'small' } }}
									/>
								</LocalizationProvider>
							</div>
						</Grid>}
					{sms_type === "REGISTERED_USERS" &&
						<Grid item xs={6} md={3}>
							<div style={{ padding: "10px" }}>
								<FormControl component="fieldset" variant="standard">
									<FormGroup row>
										<FormControlLabel
											control={
												<Checkbox checked={this.state.is_sms} color="primary" onChange={() => this.setState({ is_sms: !this.state.is_sms })} />
											}
											label="SMS"
										/>
										<FormControlLabel
											control={
												<Checkbox checked={this.state.is_notification} color="primary" onChange={() => this.setState({ is_notification: !this.state.is_notification })} />
											}
											label="Push Notification"
										/>
									</FormGroup>
								</FormControl>
							</div>
						</Grid>}
				</Grid>
				<div style={{ padding: "10px" }}>
					<Typography style={{ padding: 5, fontSize: 15, fontWeight: "bold" }}>Message Content</Typography>
					<TextField
						fullWidth
						autoComplete="off"
						required={true}
						rows={5}
						value={this.state.message}
						// multiline
						InputProps={{ styles: { input: styles.inputStyle, multiline: styles.multiline, underline: styles.underline, root: styles.inputRoot } }}
						onChange={(event) => this.setState({ message: event.target.value })}
						 />
					<Button onClick={() => this.setState({ message: "" })} style={{ ...styles.button, marginTop: "10px" }}>
						Clear Message
					</Button>
				</div>
				<div style={{ padding: "10px", textAlign: "center" }}>
					{sms_type === "REGISTERED_USERS" ? <Button onClick={() => this.sendSMS()} style={styles.sendButton} disabled={this.state.message.length === 0 ? true : (this.state.is_sms === false && this.state.is_notification === false) ? true : false}>
						Send Message
					</Button> :
						<Button onClick={() => this.sendSMS()} style={styles.sendButton} disabled={this.state.message.length === 0 ? true : false}>
							Send SMS
						</Button>
					}
				</div>
				<Dialog open={this.state.isLoading}
					styles={{
						paper: styles.dialogPaper,
					}}
				>
					<div style={{ boxShadow: 0, overflow: "none" }}>
						<CircularProgress color="primary" size={40} />
					</div>
				</Dialog>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {

	};
};

SendSms.propTypes = {
	// styles: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(SendSms));