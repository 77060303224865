import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { Sidebar, Topbar } from './components';
import { Snackbar, Alert } from "@mui/material";
import { ALERT_DETAIL } from "../../actions/actionTypes"
import { connect } from "react-redux";
import { compose } from "redux";
import { Outlet, useNavigate } from 'react-router-dom';

// root: {
//   paddingTop: 56,
//   height: '100%',
//   paddingLeft: 250,
//   [defaultTheme.breakpoints.up('sm')]: {
//     paddingTop: 64
//   }
// },


const styles = {
  root: {
    paddingTop: 56,
    height: '100%',
    paddingLeft: 250,
  },

  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  }
};

const Main = props => {
  const { openAlert, alertSeverity, alertMessage } = props;

 const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
        navigate('/');
    }
 }, [navigate]);

    

  return (
    <div style={styles.root}>
      <Snackbar
        open={openAlert}
        onClose={() => props.dispatch({ type: ALERT_DETAIL, data: false, message: "", severity: "" })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000} >
        <Alert icon={false} variant="filled" severity={alertSeverity} >{alertMessage}</Alert>
      </Snackbar>
      <Topbar />
       <Sidebar />
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openAlert: state.sessionReducer.openAlert,
    alertSeverity: state.sessionReducer.alertSeverity,
    alertMessage: state.sessionReducer.alertMessage
  };
};

Main.propTypes = {
  children: PropTypes.node
};

export default compose(connect(mapStateToProps))(Main);