import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Grid, Typography, Button } from "@mui/material";
import Webcam from "react-webcam";
import { isMobile, THEMECOLOR } from "../../helpers/Constants";
import { reUploadTestDocument } from "../../actions/testAction";
import theme from "../../theme";
import { withRouter } from "../../withRouter";

const { innerWidth: width, innerHeight: height } = window;

/**Created common styles for UI.*/
const styles = {
    paper: {
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
        height: '100vh'
    },
    mainDiv: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'hidden',
        overflowX: 'hidden'
    },
    photoContainer: {
        height: '100vh',
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up("sm")]: {
            justifyContent: 'center',
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute'
    },
    infoText: {
        fontSize: 18,
        paddingTop: 10,
        color: "#fff",
        textAlign: "center"
    },
    textContainer: {
        display: 'inline-block',
        position: 'relative',
        textAlign: 'center',
        padding: 10,
        zIndex: 100
    },
    cameraRectangle: {
        height: height / 1.7,
        width: width / 1.3,
        border: '4px solid #fff',
        display: 'inline-block',
        position: 'relative',
        zIndex: 101
    },
    buttonContainer: {
        display: 'inline-block',
        position: 'relative',
        zIndex: 100
    },
    roundButton: {
        margin: 20,
        width: height / 15
    },
    roundButtonCircle: {
        width: '100%',
        height: 0,
        paddingBottom: '100%',
        borderRadius: '50%',
        border: '10px solid #fff',
        overflow: 'hidden',
        background: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        backgroundColor: "#fff",
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    topContainer: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageView: {
        width: 210,
        height: 250,
        elevation: 2,
        padding: 15,
        borderRadius: 15,
        backgroundColor: "#fff",
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageStyle: {
        height: "100%",
        width: "100%",
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    textView: {
        paddingHorizontal: 15,
        paddingTop: 30
    },
    topText: {
        fontSize: 22,
        textAlign: "center"
    },
    bottomText: {
        fontSize: 18,
        paddingTop: 10,
        color: "#000",
        textAlign: "center",
    },
    bottomViewText: {
        fontSize: 18,
        paddingTop: 2,
        color: "#000",
        textAlign: "center"
    },
    bottomContainer: {
        flex: 1,
        width: "90%",
        justifyContent: "center",
        alignItems: "center"
    },
    submitButton: {
        backgroundColor: theme.palette.primary.main,
        width: "90%",
        padding: 15,
        borderRadius: 10,
        justifyContent: "center",
        alignItems: "center",
        margin: "10px 30px"
    },
    retakeButton: {
        marginVertical: 10,
        backgroundColor: "#B0BAD7",
        width: "90%",
        padding: 15,
        borderRadius: 10,
        justifyContent: "center",
        alignItems: "center",
        margin: "0px 30px"
    },
    buttonText: {
        color: "#fff",
        textAlign: "center",
        fontSize: 16
    },
};

/**
 * @class
 *
 * Class representing upload test image component
 *
 */
class ReUploadTest extends Component {
    // static contextTypes = {
    //     router: PropTypes.object,
    // }
    constructor() {
        super();
        this.webcamRef = React.createRef();
        this.state = {
            openTestList: false,
            parentWidth: 0,
            test_image: null,
            file: null
        }
        this.myInput = React.createRef()
        this.onImageChange = this.onImageChange.bind(this);
        this.handleUnload = this.handleUnload.bind(this);
    }

    handleUnload(e) {
        var message = "\o/";

        (e || window.event).returnValue = message;
        return message;
    };

    /**
    * @function
    *
    * function representing change image.
    * @param {Object} event
    */
    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({ test_image: URL.createObjectURL(img), file: img });
        }
    };

    /**
        * @function
        *
        * function representing base64 image to blob.
        * @param {String} dataURI
        * @param {String} type
        */
    base64toBlob = (dataURI, type) => {
        // convert base64 to raw binary data held in a string
        var byteString = atob(dataURI.split(',')[1]);
        // separate out the mime component

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var bb = new Blob([ab], { type: type });
        return bb;
    };

    /**
    * @function
    *
    * function representing capture image.
    */
    capture = async () => {
        const imageSrc = await this.webcamRef.current.getScreenshot();
        var filepath = await this.base64toBlob(imageSrc, "image/jpeg");
        this.setState({ test_image: URL.createObjectURL(filepath), file: URL.createObjectURL(filepath) });
    };

    /** This is a component life cycle method and used to fetch data and add events  */
    componentDidMount() {
        window.addEventListener('beforeunload', this.handleUnload);
        window.addEventListener('resize', this.handleResize());
    };

    /** This is a component life cycle method and used to remove event  */
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleUnload);
        window.removeEventListener('resize', this.handleResize());
    };

    /**
    * @function
    *
    * function representing resize the image.
    */
    handleResize = () => {
        this.setState({ parentWidth: !!this.myInput.current ? this.myInput.current.offsetWidth : 0 })
    };

    uploadDocument = async () => {
        let form = new FormData();

        //Convert blob to a file to upload it to server
        const blob = await fetch(this.state.test_image).then((res) => res.blob());
        form.append('file', new File([blob], 'file_name.jpg', { lastModified: new Date().getTime(), type: 'image/jpeg' }))
        this.props.dispatch(reUploadTestDocument(this, this.props.location.state.id, form, this.props.location.state.isVaccine))
    };

    /** Rendering upload test image . */
    render() {
        // const { styles } = this.props;
        const videoConstraints = {
            width: 1280,
            height: 720,
            facingMode: "environment"
        };
        let isMobileView = isMobile();
        return (
            <div style={{ ...styles.paper, background: this.state.test_image === null ? `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})` : "#ffffff" }}>
                <Grid container justifyContent="center">
                    {this.state.test_image === null ?
                        (isMobileView === true ?
                            <Grid item xs={12} ref={this.myInput}>
                                <div style={{ ...styles.photoContainer, width: this.state.parentWidth }}>
                                    <div style={styles.textContainer}>
                                        <Typography style={styles.infoText}>
                                            Take a picture of the Patient's Test Results or Vaccination Record
                                        </Typography>
                                    </div>
                                    <div style={styles.cameraRectangle}>
                                    </div>
                                    <div style={styles.buttonContainer} onClick={() => this.capture()}>
                                        <div style={styles.roundButton}>
                                            <div style={styles.roundButtonCircle}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={styles.mainDiv}>
                                    <Webcam
                                        height={720}
                                        width={1280}
                                        audio={false}
                                        ref={this.webcamRef}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={videoConstraints}
                                    />
                                </div>
                            </Grid>
                            :
                            <Grid item xs={12} ref={this.myInput}>
                                <div style={{ ...styles.photoContainer, width: this.state.parentWidth }}>
                                    <div style={styles.textContainer}>
                                        <Typography style={styles.infoText}>
                                            Upload an image of your Patient's Test Results or Vaccination Record
                                        </Typography>
                                    </div>
                                    <div>
                                        <input type="file" name="myImage" onChange={this.onImageChange} accept=".jpg,.jpeg,.png" />
                                    </div>
                                </div>
                            </Grid>
                        ) : (
                            <Grid item xs={12} md={4} sm={6}>
                                <div style={styles.container}>
                                    <div style={styles.imageView}>
                                        <img style={styles.imageStyle} src={this.state.test_image} alt="icon" />
                                    </div>
                                    <div style={styles.textView}>
                                        <Typography style={styles.topText}>Is this clear enough?</Typography>
                                        <Typography style={styles.bottomText}>Make sure the full document is in </Typography>
                                        <Typography style={styles.bottomViewText}>view and the picture is not blurry. </Typography>
                                    </div>
                                    <Button style={styles.submitButton} onClick={() => this.uploadDocument()}>
                                        <Typography style={styles.buttonText}>
                                            Upload
                                        </Typography>
                                    </Button>
                                    <Button style={styles.retakeButton} onClick={() => this.setState({ test_image: null })}>
                                        <Typography style={styles.buttonText}>
                                            Retake Photo
                                        </Typography>
                                    </Button>
                                </div>
                            </Grid>
                        )}
                </Grid>
            </div>
        );
    }
};

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
    return {
        selected_test_type: state.testReducer.selected_test_type,
        user_type: state.sessionReducer.user_type,
    };
};

ReUploadTest.propTypes = {
    // styles: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(ReUploadTest));