import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Table, TableBody, TableCell, TableHead, TableRow,
    Typography, Grid, Button, Dialog, DialogActions,
    DialogContent, DialogTitle, TextField, Card, Toolbar,
    TablePagination, Chip, CircularProgress, InputAdornment,
    Radio, Badge, IconButton, Menu, MenuItem
} from '@mui/material';
import { addMember, removeMember, searchUser } from "../../actions/memberAction";
import { fetchOrganization, fetchOrganizationDetail, applyCredits, uploadMember, addOrganizationCredits, fetchOrganizationPlan, addSubscriptionPlan, assignSubscriptionPlan, fetchOrganizationPlanBalance } from '../../actions/organizationAction';
import UserIcon from "../../assets/images/account_circle.png"
// import PremiumIcon from "../../assets/images/star.svg"
import { pluck, sortBy } from "underscore";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TablePaginationActions from "../Dashboard/TablePaginationActions";
import moment from 'moment';
import { fetchSettingByCode } from '../../actions/settingsAction';
import { fetchUser } from '../../actions/userAction'
import api from "../../api";
import { END_POINT } from "../../helpers/Constants";
// import { loadStripe } from "@stripe/stripe-js";
import { fetchVaccine } from "../../actions/vaccineAction";
import { FaUserAlt, FaUsers, FaDownload, FaUpload } from "react-icons/fa";
import { BiWallet } from "react-icons/bi";
import Basicicon from "../../assets/images/Basicicon.png"
import PendingAssignmenticon from "../../assets/images/PendingAssignmenticon.png"
import Premiumicon from "../../assets/images/Premiumicon.png"
import Standardicon from "../../assets/images/Standardicon.png"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountCircle from '@mui/icons-material/AccountCircle';
import theme from "../../theme";
import { withRouter } from "../../withRouter";

const saveAs = require('file-saver');

// const stripePromise = loadStripe(PUBLISHABLE_KEY);

/**Created common styles for UI.*/
const styles = {
    textStyle: {
        fontFamily: "Futura-Heavy",
        color: "#37474f",
        //fontFamily: "bold",
        fontSize: 20,
        //padding: 10
    },
    creditTextStyle: {
        fontFamily: "Futura-Heavy",
        color: "#37474f",
        fontStyle: "bold",
        fontSize: 15,
        paddingTop: 10
    },
    addButton: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        fontFamily: "unicode.futurab",
        borderRadius: "10",

    },
    buyButton: {
        backgroundColor: "#E06901",
        color: "white",
        fontFamily: "unicode.futurab",
        borderRadius: 5,
        padding: 0,
        fontSize: 11,
        textTransform: "none",
        '&:hover': {
            backgroundColor: "#E06901",
        },
    },
    downloadButton: {
        backgroundColor: "#5186E7",
        color: "white",
        fontFamily: "unicode.futurab",
        borderRadius: 5,
        textTransform: "none",
        '&:hover': {
            backgroundColor: "#5186E7",
        },
    },
    cancelButton: {
        backgroundColor: "#8fa3ad",
        color: "white",
        fontFamily: "unicode.futurab",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(143, 163, 173, 0.8)',
        }
    },
    editButton: {
        backgroundColor: "#8fa3ad",
        color: "white",
        fontFamily: "unicode.futurab",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(143, 163, 173, 0.8)',
        }
    },
    fontCellHeader: {
        fontFamily: "Futura-Book",
        color: "#fff",
        fontWeight: 900,
        padding: '14px 20px 14px 0px',

    },
    fontTableCellBody: {
        fontFamily: "Futura-Medium-bt",
        color: "#37474f",
        padding: '14px 20px 14px 0px',
    },
    inputStyle: {
        fontFamily: "Futura-Book",
        fontSize: 14
    },
    labelStyle: {
        fontFamily: "Futura-Book",
        fontSize: 14
    },
    input: {
        display: "none"
    },
    chip: {
        margin: 3,
        color: '#fff',
        padding: "3px 1px",
        fontFamily: "Futura-Heavy"
    },
    label: {
        textOverflow: "clip"
    },
    dialogPaper: {
        backgroundColor: "rgba(0,0,0,0)",
        boxShadow: "none",
        overflow: "hidden"
    },
    textField: {
        padding: 0,
        textAlign: "center",
        width: 70
    },
    activeChip: {
        // margin: theme.spacing(1),
        backgroundColor: '#3f51b5',
        color: "#FFFFFF",
        // padding: "3px 3px",
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#3f51b5',
        }
    },
    inActiveChip: {
        // padding: "3px 3px",
        margin: theme.spacing(1),
        fontWeight: 'bold',
        backgroundColor: 'gray',
        color: "#FFFFFF",
        '&:hover': {
            backgroundColor: 'rgba(39,44,15, 0.8)',
        }
    },
    chipBadge: {
        backgroundColor: 'red',
        color: "#FFFFFF"
    },
    userImage: {
        height: 150,
        width: 150,
        borderRadius: 15,
        objectFit: "cover"
    },
    userAccount: {
        height: 150,
        width: 150,
        color: "gray"
    }
}

/**
 * @class
 *
 * Class representing Member component
 *
 */
class Member extends Component {
    constructor() {
        super();
        this.state = {
            member: [],
            view_member_details: false,
            dialogOpen: false,
            id: null,
            primary_contact_first_name: "",
            primary_contact_first_name_error: false,
            primary_contact_last_name: "",
            primary_contact_last_name_error: false,
            primary_contact_email: "",
            primary_contact_email_error: false,
            primary_contact_gender: "",
            primary_contact_gender_error: "",
            primary_contact_date_of_birth: "",
            primary_contact_date_of_birth_error: false,
            primary_contact_zip_code: "",
            primary_contact_zip_code_error: "",
            primary_contact_country_code: "+1",
            memberdetails: "",
            mode: "",
            status: "ACTIVE",
            search_phone: "",
            add_credit: "",
            add_credit_error: false,
            credit_dialog: false,
            nonPremiumUsers: [],
            apply_credit_dialog: false,
            rowsPerPage: 10,
            page: 0,
            filter: "ALL",
            isLoading: false,
            invalid_primary_contact_date_of_birth_error: false,
            invalid_date_error: false,
            invalid_primary_contact_zip_code_error: false,
            invalid_search_phone_error: false,
            invalid_primary_contact_email_error: false,
            result_dialog: false,
            result_array: [],
            uploadMemberdialogOpen: false,
            planData: [],
            assignSeatDialog: false,
            selectedMember: null,
            selectedPlan: null,
            selectedOrganizationPlan: null,
            selectedPlanCountError: false,
            corporateEmail: "",
            invalid_corporateEmail_error: false,
            corporateEmail_error: false,
            menu: null,
            removeDialog: false,
            detailDialog: false,
            selectedMemberDetail: null
        }
    }

    componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        // this.addCredits(query);
        this.addSubscriptionPlan(query);
        /** fetching organizations . */
        this.props.dispatch(fetchOrganization())
        /** fetching organization details. */
        this.props.dispatch(fetchOrganizationDetail())
        /** fetching Setting details. */
        this.props.dispatch(fetchSettingByCode("PUS"))
        /** fetching Users details. */
        this.props.dispatch(fetchUser())
        /** fetching Vaccine details. */
        this.props.dispatch(fetchVaccine())
        /** fetching organization plan. */
        this.props.dispatch(fetchOrganizationPlan())
        /** fetching organization plan Balance. */
        this.props.dispatch(fetchOrganizationPlanBalance())

    }

    /**
    *
    * This function representing to shows memeber details.
    * @param {Object} item The item json object
    */
    viewMemeberDetails = (item) => {
        this.setState({ view_member_details: true, memberdetails: item })
    }

    /**
    *
    * This function representing add Member dialog screen will open.
    * @param {Object} item The item json object
    * @param {String} mode The mode string
    */
    handleClick = (item, mode) => {
        if (mode === "ADD") {
            this.setState({ mode: mode, dialogOpen: true })
        }
        // else {
        //     this.setState({
        //         id: item.id,
        //         dialogOpen: true,
        //         mode: mode,
        //         first_name: item.first_name,
        //         first_name_error: false,
        //         last_name: item.last_name,
        //         last_name_error: false,
        //         email: item.email,
        //         email_error: false,
        //         gender: item.gender,
        //         gender_error: false,
        //         data_of_birth: item.data_of_birth,
        //         data_of_birth_error: false,
        //         zip_code: item.zip_code,
        //         zip_code_error: false
        //     })
        // }
    }

    /**
     * This function representing close add  member dialog screen.
     */
    handleClose = () => {
        this.setState({
            assignSeatDialog: false,
            uploadMemberdialogOpen: false,
            dialogOpen: false,
            view_member_details: false,
            id: null,
            primary_contact_first_name: "",
            primary_contact_first_name_error: false,
            primary_contact_last_name: "",
            primary_contact_last_name_error: false,
            primary_contact_email: "",
            primary_contact_email_error: false,
            primary_contact_gender: "",
            primary_contact_gender_error: "",
            primary_contact_date_of_birth: "",
            primary_contact_date_of_birth_error: false,
            primary_contact_zip_code: "",
            primary_contact_zip_code_error: "",
            primary_contact_country_code: "+1",
            mode: "",
            search_phone: "",
            add_credit: "",
            add_credit_error: false,
            credit_dialog: false,
            apply_credit_dialog: false,
            nonPremiumUsers: [],
            invalid_primary_contact_date_of_birth_error: false,
            invalid_date_error: false,
            invalid_primary_contact_zip_code_error: false,
            search_phone_error: false,
            invalid_search_phone_error: false,
            invalid_primary_contact_email_error: false,
            selectedPlan: null,
            selectedPlanCountError: false,
            selectedOrganizationPlan: null,
            corporateEmail: "",
            invalid_corporateEmail_error: false,
            corporateEmail_error: false
        })
    }

    /**
     *
     * This function checks whether the number is Number or not and returns true or false
     * @param {Number} number The number Number
     */
    checkNumber = (number) => {
        var isNumber = /^-{0,1}\d+$/.test(number)
        return isNumber;
    }

    /**
    *
    * This function representing Zipcode validation.
    * @param {String} code The code String
    */
    validateZipCode(code) {
        var re = /^[\d]{5}$/;
        return code === "" ? true : re.test(code);
    }

    /**
     *
     * This function representing email validation.
     * @param {String} email The email string
     */
    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    /**
     *
     * This function representing add Member.
     * @param {String} mode The mode string
     */
    handleSubmit = (mode) => {

        let { primary_contact_first_name, corporateEmail,
            primary_contact_last_name, primary_contact_email,
            // primary_contact_gender, primary_contact_date_of_birth, primary_contact_zip_code, 
            search_phone, primary_contact_country_code } = this.state
        let isError = false;

        if (primary_contact_first_name === "" || primary_contact_first_name === null) {
            this.setState({ primary_contact_first_name_error: true, })
            isError = true;
        }

        if (primary_contact_last_name === "" || primary_contact_last_name === null) {
            this.setState({ primary_contact_last_name_error: true, })
            isError = true;
        }

        // if (corporateEmail === "" || corporateEmail === null) {
        //     this.setState({ corporateEmail_error: true, })
        //     isError = true;
        // } else {
        //     if (!this.validateEmail(corporateEmail)) {
        //         this.setState({ invalid_corporateEmail_error: true });
        //         isError = true;
        //     }
        // }
        if (primary_contact_email === "" || primary_contact_email === null) {
            this.setState({ primary_contact_email_error: true, })
            isError = true;
        } else {
            if (!this.validateEmail(primary_contact_email)) {
                this.setState({ invalid_primary_contact_email_error: true });
                isError = true;
            }
        }

        if (corporateEmail !== "" && corporateEmail !== null) {
            if (!this.validateEmail(corporateEmail)) {
                this.setState({ invalid_corporateEmail_error: true });
                isError = true;
            }
        }

        // if (primary_contact_gender === "" || primary_contact_gender === null) {
        //     this.setState({ primary_contact_gender_error: true, })
        //     isError = true;
        // }

        // if (primary_contact_date_of_birth === "" || primary_contact_date_of_birth === null) {
        //     this.setState({ primary_contact_date_of_birth_error: true, })
        //     isError = true;
        // }

        // if (primary_contact_zip_code === "" || primary_contact_zip_code === null) {
        //     this.setState({ primary_contact_zip_code_error: true, })
        //     isError = true;
        // } else {

        //     if (!this.validateZipCode(primary_contact_zip_code)) {
        //         this.setState({ invalid_primary_contact_zip_code_error: true });
        //         isError = true;
        //     }
        // }

        if (search_phone === "" || search_phone === null) {
            this.setState({ search_phone_error: true })
            isError = true;
        } else {
            if (this.checkNumber(search_phone) !== true || search_phone.length !== 10) {
                this.setState({ invalid_search_phone_error: true })
                isError = true
            }
        }

        // if (primary_contact_date_of_birth === "" || primary_contact_date_of_birth === null) {
        //     this.setState({ primary_contact_date_of_birth_error: true });
        //     isError = true;
        // }

        // if (new Date(primary_contact_date_of_birth) == 'Invalid Date' || primary_contact_date_of_birth.length < 10) {
        //     this.setState({ invalid_primary_contact_date_of_birth_error: true, errorText: "Please enter valid date of birth(MM/DD/YYYY)" });
        //     isError = true;
        // }

        // if (new Date().getTime() < new Date(moment(primary_contact_date_of_birth).format('MM/DD/YYYY')).getTime()) {
        //     this.setState({ invalid_date_error: true, errorText: "Future date not allowed" });
        //     isError = true;
        // }

        if (isError === false) {
            let memberObj = {};
            memberObj.primaryContactPhone = search_phone;
            memberObj.firstName = primary_contact_first_name;
            memberObj.lastName = primary_contact_last_name;
            memberObj.primaryContactemail = primary_contact_email;
            // memberObj.dateOfBirth = primary_contact_date_of_birth;
            // memberObj.gender = primary_contact_gender;
            // memberObj.zipCode = primary_contact_zip_code;
            memberObj.countryCode = primary_contact_country_code;
            memberObj.organizationId = this.props.organizationDetail.id;
            memberObj.corporateEmail = corporateEmail;

            if (mode === "ADD") {
                this.setState({ isLoading: true })
                this.props.dispatch(addMember(this, memberObj))
            }
        }
    }

    returnDateTime = (test) => {
        let data = "----";
        let today = moment();
        if (test.dateOfTest !== undefined && test.timeOfTest !== undefined && test.dateOfTest !== "undefined" && test.dateOfTest !== null) {
            let date = moment(test.dateOfTest).format("YYYY/MM/DD");
            let datetime = test.timeOfTest !== null ? date + " " + test.timeOfTest : moment(date).format();
            let isoFormat = new Date(datetime).toISOString()
            let diff = today.diff(isoFormat, "hours")
            if (typeof diff === "number") {
                if (diff > 72) {
                    data = `Tested ${moment(isoFormat).fromNow()}`;
                } else {
                    data = `Tested ${diff} hours ago`;
                }
            }
        } else {
            let datetime = (test.testResultItems[0].created !== undefined && test.testResultItems[0].created !== null) ? test.testResultItems[0].created : moment().format();
            let isoFormat = new Date(datetime).toISOString()
            let diff = today.diff(isoFormat, "hours")
            if (typeof diff === "number") {
                if (diff > 72) {
                    data = `Tested ${moment(isoFormat).fromNow()}`;
                } else {
                    data = `Tested ${diff} hours ago`;
                }
            }
        }
        return data;
    }

    returnCovidTest = (item) => {
        let organizationPlan = item.userSubscriptionPlanHistories !== null ? item.userSubscriptionPlanHistories.find(x => x.planFor === "ORG" && x.status !== "EXPIRED") : null
        if (organizationPlan !== null && organizationPlan !== undefined) {
            let filterItem = item.clientTestResults.filter(x => x.testType.isCovid === true && x.testType.isAntibody === false && x.testType.isVaccine === false).sort((a, b) => new Date(b.dateOfTest) - new Date(a.dateOfTest))
            let findItem = filterItem[filterItem.length - 1]
            if (findItem === undefined) {
                return "-"
            } else {
                if (findItem.status === "COMPLETED" && findItem.testResultItems.length > 0) {
                    return <div>
                        <Typography style={{ fontFamily: "Futura-Medium-bt" }}>{findItem.testResultItems[0].result_value}</Typography>
                        <Typography style={{ fontFamily: "Futura-Medium-bt", fontSize: 13 }}>{this.returnDateTime(findItem)}</Typography>
                    </div>
                } else if (findItem.status === "PENDING") {
                    return "Test Uploaded but Not Verified"
                } else {
                    return "-"
                }
            }
        }
    }

    returnAntibodyTest = (item) => {
        let organizationPlan = item.userSubscriptionPlanHistories !== null ? item.userSubscriptionPlanHistories.find(x => x.planFor === "ORG" && x.status !== "EXPIRED") : null
        if (organizationPlan !== null && organizationPlan !== undefined) {
            let filterItem = item.clientTestResults.filter(x => x.testType.isCovid === true && x.testType.isAntibody === true && x.testType.isVaccine === false).sort((a, b) => new Date(b.dateOfTest) - new Date(a.dateOfTest))
            let findItem = filterItem[filterItem.length - 1]
            if (findItem === undefined) {
                return "-"
            } else {
                if (findItem.status === "COMPLETED" && findItem.testResultItems.length > 0) {
                    return <div>
                        <Typography style={{ fontFamily: "Futura-Medium-bt" }}>{findItem.testResultItems[0].result_value}</Typography>
                        <Typography style={{ fontFamily: "Futura-Medium-bt", fontSize: 13 }}>{this.returnDateTime(findItem)}</Typography>
                    </div>
                } else if (findItem.status === "PENDING") {
                    return "Test Uploaded but Not Verified"
                } else {
                    return "-"
                }
            }
        }
    }

    returnVaccineTest = (item) => {
        let organizationPlan = item.userSubscriptionPlanHistories !== null ? item.userSubscriptionPlanHistories.find(x => x.planFor === "ORG" && x.status !== "EXPIRED") : null
        if (organizationPlan !== null && organizationPlan !== undefined) {
            let filterItem = item.clientTestResults.filter(x => x.testType.isCovid === true && x.testType.isAntibody === false && x.testType.isVaccine === true)
            let sortItem = sortBy(filterItem, "shotNumber")
            if (sortItem.length === 0) {
                return "NOT VACCINATED"
            } else {
                sortItem = sortItem.filter(x => x.status === "COMPLETED")
                if (sortItem.length > 0) {
                    if (sortItem[sortItem.length - 1].status === "COMPLETED" && sortItem[sortItem.length - 1].vaccineResults.length > 0) {
                        let vaccine = (this.props.vaccine !== null) ? this.props.vaccine.find(x => sortItem[sortItem.length - 1].vaccineResults.filter(y => y.vaccineId === x.id && y.status === "COMPLETED").length > 0) : null
                        return <div>
                            <Typography style={{ fontFamily: "Futura-Medium-bt" }}>{(vaccine !== null && vaccine !== undefined) ? vaccine.vaccineShots.length === sortItem[sortItem.length - 1].shotNumber ? "VACCINATED" : `${sortItem[sortItem.length - 1].shotNumber} of ${vaccine.vaccineShots.length} shots of ${vaccine.name}` : "-"}</Typography>
                            <Typography style={{ fontFamily: "Futura-Medium-bt", fontSize: 13 }}>{(vaccine !== null && vaccine !== undefined && sortItem[sortItem.length - 1].vaccineResults.length > 0) ? `Verified on ${moment(sortItem[sortItem.length - 1].vaccineResults[0].vaccinationDate).format("MMM DD, YYYY")} at ${moment(sortItem[sortItem.length - 1].vaccineResults[0].vaccinationDate).format("hh:mm A")}` : "----"}</Typography>
                        </div>
                    }
                } else {
                    return "Record Uploaded but Not Verified"
                }
            }
        }

    }

    returnPlan = (item) => {
        let filterOrganizationPlan = item.userSubscriptionPlanHistories !== null ? item.userSubscriptionPlanHistories.filter(x => x.planFor === "ORG" && x.status !== "EXPIRED") : null

        if (filterOrganizationPlan === null || filterOrganizationPlan === undefined || filterOrganizationPlan.length === 0) {
            return <div style={{ display: "grid", justifyContent: "center" }}>
                <img alt="pending" src={PendingAssignmenticon} height={25} width="auto" />
                <Typography onClick={() => this.setState({ assignSeatDialog: true, selectedMember: item })} style={{ color: "blue", marginTop: 5, cursor: "pointer", textAlign: "center", textDecoration: "underline" }}>Assign Seat</Typography>
            </div>
        } else {
            let premiumPlan = filterOrganizationPlan.find(x => x.subscriptionPlan.code === "OPRM")
            if (premiumPlan !== undefined) {
                return <div style={{ display: "grid", justifyContent: "center" }}>
                    <img alt="premium" src={Premiumicon} height={25} width="auto" />
                </div>
            } else {
                let standardPlan = filterOrganizationPlan.find(x => x.subscriptionPlan.code === "OSTD")
                if (standardPlan !== undefined) {
                    return <div style={{ display: "grid", justifyContent: "center" }}>
                        <img alt="standard" src={Standardicon} height={25} width="auto" />
                    </div>
                } else {
                    let basicPlan = filterOrganizationPlan.find(x => x.subscriptionPlan.code === "OBSC")
                    if (basicPlan !== undefined) {
                        return <div style={{ display: "grid", justifyContent: "center" }}>
                            <img alt="basic" src={Basicicon} height={25} width="auto" />
                            <Typography onClick={() => this.setState({ assignSeatDialog: true, selectedMember: item })} style={{ color: "blue", marginTop: 5, cursor: "pointer", textAlign: "center", textDecoration: "underline" }}>Upgrade</Typography>
                        </div>
                    }
                }
            }
        }
    }

    returnPlanCount = (item) => {
        let filter = []
        if (item === "ALL") {
            return this.props.organizationMembers.length
        } else if (item === "BASIC") {
            filter = this.props.organizationMembers.filter(x => x.memberProfile.userSubscriptionPlan !== null && x.memberProfile.userSubscriptionPlanHistories.filter(x => x.planFor === "ORG" && x.status !== "EXPIRED").length === 1 && x.memberProfile.userSubscriptionPlanHistories.find(x => x.planFor === "ORG" && x.status !== "EXPIRED" && x.subscriptionPlan.code === "OBSC") !== undefined)
            return filter.length
        } else if (item === "STANDARD") {
            filter = this.props.organizationMembers.filter(x => x.memberProfile.userSubscriptionPlan !== null && x.memberProfile.userSubscriptionPlanHistories.find(x => x.planFor === "ORG" && x.subscriptionPlan.code === "OSTD" && x.status !== "EXPIRED") !== undefined)
            return filter.length
        } else if (item === "PREMIUM") {
            filter = this.props.organizationMembers.filter(x => x.memberProfile.userSubscriptionPlan !== null && x.memberProfile.userSubscriptionPlanHistories.find(x => x.planFor === "ORG" && x.subscriptionPlan.code === "OPRM" && x.status !== "EXPIRED") !== undefined)
            return filter.length
        } else {
            filter = this.props.organizationMembers.filter(x => x.memberProfile.userSubscriptionPlan === null || x.memberProfile.userSubscriptionPlanHistories.find(x => x.planFor === "ORG" && x.status !== "EXPIRED") === undefined)
            return filter.length
        }
    }

    returnOrgPlan = (item) => {
        let organizationPlan = item.userSubscriptionPlanHistories !== null ? item.userSubscriptionPlanHistories.find(x => x.planFor === "ORG" && x.status !== "EXPIRED") : null
        if (organizationPlan !== null && organizationPlan !== undefined) {
            return true;
        } else {
            return false
        }

    }

    /**
    * @function
    * This function representing  searchUser.
    */
    searchUser = () => {
        if (this.state.search_phone !== "" && this.state.search_phone !== null) {
            this.props.dispatch(searchUser(this, this.state.search_phone))
        }
    }

    /**
    * @function
    * This function representing  add Credits .
    */
    // addCredits = () => {
    //     let isError = false;
    //     if (this.state.add_credit === "" || this.state.add_credit === null) {
    //         this.setState({ add_credit_error: true, })
    //         isError = true;
    //     }
    //     if (isError === false) {
    //         let data = {}
    //         data.credits = this.state.add_credit
    //         this.setState({ isLoading: true })
    //         this.props.dispatch(addCredits(this, data))
    //     }
    // }

    addCredits = (query) => {
        let session_id = query.get("session_id");
        let successfulPayment = query.get("success");
        let cancelledPayment = query.get("cancel");

        let paymentStatus = successfulPayment !== null ? ((successfulPayment === true || successfulPayment === "true") ? 'success' : 'cancel') : 'cancel';

        // console.log(`${successfulPayment} ===== ${cancelledPayment} ===== ${session_id}`);
        if ((successfulPayment !== null || cancelledPayment !== null) && session_id !== null && session_id !== "") {
            this.props.dispatch(addOrganizationCredits(this, {}, session_id, paymentStatus));
        }
    }

    addSubscriptionPlan = (query) => {
        let session_id = query.get("session_id");
        let successfulPayment = query.get("success");
        let cancelledPayment = query.get("cancel");
        let organization_payment_data = JSON.parse(localStorage.getItem('organization_payment_data'))
        let paymentStatus = successfulPayment !== null ? ((successfulPayment === true || successfulPayment === "true") ? 'success' : 'cancel') : 'cancel';

        // console.log(`${successfulPayment} ===== ${cancelledPayment} ===== ${session_id}`);
        if ((successfulPayment !== null || cancelledPayment !== null) && session_id !== null && session_id !== "" && organization_payment_data !== null) {
            let data = {}
            data.session_id = session_id;
            data.organizationId = organization_payment_data.organizationId;
            data.planData = organization_payment_data.planData;
            this.props.dispatch(addSubscriptionPlan(this, data, paymentStatus));
        }
    }

    submitPayment = async (event) => {
        // const stripe = await stripePromise;
        // let plan_data = this.state.planData.filter(x => x.count > 0 && x.count !== "")
        // let data = {};
        // data.planData = plan_data;
        // data.organizationId = this.props.organizationDetail.id;
        // localStorage.setItem('organization_payment_data', JSON.stringify(data));

        // if (plan_data.length > 0) {

        //     const response = await api.post(`organization-subscription-plan/create-payment-session`, data);

        //     if (response.status === "success") {
        //         // When the customer clicks on the button, redirect them to Checkout.
        //         let session = response.payload;
        //         const result = await stripe.redirectToCheckout({
        //             sessionId: session.id,
        //         });

        //         if (result.error) {
        //             // If `redirectToCheckout` fails due to a browser or network
        //             // error, display the localized error message to your customer
        //             // using `result.error.message`.
        //         }
        //     }
        // } else {
        //     alert('Plan counts should not be empty')
        // }
    };


    handlePayment = async (event) => {
        // const stripe = await stripePromise;
        // let data = {};
        // data.credits = parseInt(this.state.add_credit);
        // data.price_per_credit = 1;

        // if (parseInt(this.state.add_credit) > 0) {

        //     const response = await api.post(`organization/create-payment-session/${this.props.fetch_user.id}`, data);

        //     if (response.status === "success") {
        //         // When the customer clicks on the button, redirect them to Checkout.
        //         let session = response.payload;
        //         const result = await stripe.redirectToCheckout({
        //             sessionId: session.id,
        //         });

        //         if (result.error) {
        //             // If `redirectToCheckout` fails due to a browser or network
        //             // error, display the localized error message to your customer
        //             // using `result.error.message`.
        //         }
        //     }
        // } else {
        //     alert('Credits should not be empty')
        // }

        // const response = await fetch("/create-checkout-session", {
        //   method: "POST",
        // });

        // const session = await response.json();
    };

    /**
    * @function
    * This function representing  ApplyCreditDialog screen will open .
    */
    openApplyCreditDialog = () => {
        let nonPremiumUsers = this.props.organizationDetail !== null ? this.props.organizationDetail.organizationMembers.filter(x => x.memberProfile.isPremiumUser === false) : []
        this.setState({ nonPremiumUsers: nonPremiumUsers, apply_credit_dialog: true })
    }

    /**
    * @function
    * This function representing  applyBulkCredits .
    */
    applyBulkCredit = () => {
        let data = {}
        data.organizationMembers = pluck(this.state.nonPremiumUsers, "userProfileId")
        this.setState({ isLoading: true })
        this.props.dispatch(applyCredits(this, data))
    }

    /**
    * @function
    * This function representing  applyCredits to  individual Member.
    * @param {Object} user The user json object
    */
    applyIndividualCredit = (user) => {
        let memberId = []
        memberId.push(user.id)
        let data = {}
        data.organizationMembers = memberId
        this.props.dispatch(applyCredits(this, data))
    }

    downloadTemplate = () => {
        let filename = `MemberUploadTemplate.xlsx`;
        api.blobGet(`organization-member/download/template`).then((blob) => saveAs(blob, filename))
    }

    fileHandler = (event) => {
        let file = event.target.files[0];
        this.setState({ isLoading: true })
        this.props.dispatch(uploadMember(this, file))
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0, rowsPerPage: parseInt(event.target.value, 10)
        });
    }


    onChange = (event) => {
        this.setState({
            primary_contact_date_of_birth: event.target.value,
            primary_contact_date_of_birth_error: false,
            invalid_primary_contact_date_of_birth_error: false,
            invalid_date_error: false
        });
    }

    setPlanCount = (event, item) => {
        if (event.target.value.match(/^\d{0,10}$/g)) {
            let isExist = this.state.planData.find(x => x.subscriptionPlanId === item.id)
            if (isExist === undefined) {
                isExist = {};
                isExist.count = event.target.value;
                isExist.subscriptionPlanId = item.id;
                isExist.total_price = event.target.value !== "" ? parseInt(event.target.value) * parseFloat(item.price) : 0
                this.setState({ planData: [...this.state.planData, isExist] })
            } else {
                isExist.count = event.target.value;
                isExist.total_price = event.target.value !== "" ? parseInt(isExist.count) * parseFloat(item.price) : 0
                this.setState({ planData: this.state.planData.map(x => x.subscriptionPlanId === item.id ? isExist : x) })
            }
        }
    }

    returnCount = (item) => {
        let isExist = this.state.planData.find(x => x.subscriptionPlanId === item.id)
        if (isExist === undefined) {
            return 0;
        } else {
            return isExist.count;
        }
    }

    planSeatsBalance = (item) => {

        let data = this.props.organizationPlanBalance !== null ? this.props.organizationPlanBalance.find(x => x.subscriptionPlanId === item.id) : null;
        if (data === null || data === undefined) {
            return 0
        } else {
            return data.availableCount
        }
    }

    returnTotalAmount = () => {
        let totalPrice = this.state.planData.length > 0 ? this.state.planData.reduce((accumulator, currentValue) => { return accumulator + currentValue.total_price }, 0) : 0
        return totalPrice.toFixed(2);
    }

    assignSubscriptionPlan = () => {
        let isError = false;
        if (this.state.selectedOrganizationPlan === null || this.state.selectedOrganizationPlan === undefined) {
            isError = true;
            alert("Please select plan")
        } else {
            if (this.state.selectedPlan.code === "OBSC" && (this.state.selectedPlanCount === "0" || this.state.selectedPlanCount === "" || this.state.selectedPlanCount === null || this.state.selectedPlanCount === undefined)) {
                isError = true;
                this.setState({ selectedPlanCountError: true })
            }
        }
        if (isError === false) {
            let data = {}
            data.organizationSubscribedPlanId = this.state.selectedOrganizationPlan.id;
            data.userProfileId = this.state.selectedMember.id;
            data.organizationId = this.props.organizationDetail.id;
            data.planCount = this.state.selectedPlanCount;
            data.isBasic = this.state.selectedPlan.code === "OBSC" ? true : false;
            this.props.dispatch(assignSubscriptionPlan(this, data))
        }

    }

    selectOrganizationPlan = (item) => {
        let data = this.props.organizationPlanBalance !== null ? this.props.organizationPlanBalance.find(x => x.subscriptionPlanId === item.id) : null;
        if (data !== null && data !== undefined) {
            this.setState({ selectedOrganizationPlan: data, selectedPlan: item })
        }
    }

    removeMember = () => {
        let data = {}
        data.id = this.state.selectedMemberDetail.id;
        this.props.dispatch(removeMember(this, data))
    }

    returnUserImage = (user_profile) => {
        let imageurl = user_profile.imageStorageKey !== null ? `${END_POINT}image/i/${user_profile.imageStorageKey}` : null;
        return imageurl;
    }

    /** rendering Organization and Members information. */
    render() {
        // const { styles } = this.props;
        const { page, rowsPerPage, filter, result_array } = this.state;
        let result = this.props.organizationMembers !== null ? this.props.organizationMembers : []
        if (filter === "ALL") {
            result = this.props.organizationMembers
        } else if (filter === "BASIC") {
            result = this.props.organizationMembers.filter(x => x.memberProfile.userSubscriptionPlan !== null && x.memberProfile.userSubscriptionPlanHistories.filter(x => x.planFor === "ORG" && x.status !== "EXPIRED").length === 1 && x.memberProfile.userSubscriptionPlanHistories.find(x => x.planFor === "ORG" && x.status !== "EXPIRED" && x.subscriptionPlan.code === "OBSC") !== undefined)
        } else if (filter === "STANDARD") {
            result = this.props.organizationMembers.filter(x => x.memberProfile.userSubscriptionPlan !== null && x.memberProfile.userSubscriptionPlanHistories.find(x => x.planFor === "ORG" && x.subscriptionPlan.code === "OSTD" && x.status !== "EXPIRED") !== undefined)
        } else if (filter === "PREMIUM") {
            result = this.props.organizationMembers.filter(x => x.memberProfile.userSubscriptionPlan !== null && x.memberProfile.userSubscriptionPlanHistories.find(x => x.planFor === "ORG" && x.subscriptionPlan.code === "OPRM" && x.status !== "EXPIRED") !== undefined)
        } else {
            result = this.props.organizationMembers.filter(x => x.memberProfile.userSubscriptionPlan === null || x.memberProfile.userSubscriptionPlanHistories.find(x => x.planFor === "ORG" || x.status === "EXPIRED") === undefined)
        }

        let members = sortBy(result, function (member) {
            return member.memberProfile.firstName;
        })
        return (
            <div style={{ paddingLeft: 10 }}>
                <Grid container spacing={0} style={{ marginTop: 10, marginRight: 30 }} justify="space-between">
                    <Grid item xs={12} sm={3}>
                        <Typography style={{ fontWeight: "bold", fontFamily: "Futura-Heavy", color: "#37474f", fontSize: 20 }}>{this.props.organizationDetail !== null && this.props.organizationDetail.name}</Typography>
                        <Card style={{ margin: 10, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main},${theme.palette.primary.dark}`, borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 15 }}>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <Typography style={{ color: "white", fontWeight: "bold", fontSize: 16 }}>MedFilo Balance</Typography>
                                <BiWallet style={{ fontSize: 35, color: '#ffffff' }} />
                            </div>
                            <div>
                                {this.props.organizationPlan.map(item => <div key={item.id.toString()} style={{ display: "flex", justifyContent: 'space-between', marginBottom: 10 }}>
                                    <Typography style={{ color: "#fff", fontSize: 14 }}>+ {item.name} Seats available :</Typography>
                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <Typography style={{ color: "#fff", fontSize: 14, paddingRight: 30 }}>{this.planSeatsBalance(item)}</Typography>
                                    </div>
                                </div>)}
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4} >
                        {/* {this.props.premiumUserSupporting !== null && this.props.premiumUserSupporting.value === 'true' && 
                        <div style={{ display: "flex", justifyContent: "flex-end", padding: "0px 20px" }}>
                            <Button size="small" variant="contained" color="primary" style={styles.addButton} style={{ marginLeft: 10 }} onClick={() => this.setState({ credit_dialog: true })}>
                                Buy Credits
                            </Button>
                            <Button size="small" variant="contained" color="primary" style={styles.addButton} style={{ marginLeft: 10 }} onClick={() => this.openApplyCreditDialog()}>
                                Apply Credits
                            </Button>
                        </div>
                        } */}
                        <Card style={{ margin: 10, borderRadius: 10, padding: 15, boxShadow: "none", borderWidth: 1, borderColor: "#DAD9DE", borderStyle: "solid" }}>
                            <div style={{ paddingBottom: 15 }}>
                                <Typography style={{ color: theme.palette.secondary.light, fontWeight: "bold", fontSize: 16 }}>Purchase Seats</Typography>
                            </div>
                            <div>
                                {this.props.organizationPlan.map(item => <div style={{ display: "flex", justifyContent: 'space-between', marginBottom: 10, paddingBottom: 5, borderBottomWidth: 1, borderBottomColor: "#DAD9DE", borderBottomStyle: "solid" }}>
                                    <Typography style={{ color: theme.palette.secondary.light, fontSize: 14 }}>Buy {item.name} Plans (${item.price} per seat)</Typography>
                                    <div style={{ display: "flex", justifyContent: 'space-between', borderLeftWidth: 1, borderLeftColor: "#DAD9DE", borderLeftStyle: "solid", paddingLeft: 15 }}>
                                        <TextField
                                            style={styles.textField}
                                            value={this.returnCount(item)}
                                            onChange={(event) => this.setPlanCount(event, item)}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>)}
                                <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: 15, marginTop: 15 }}>
                                    <Typography style={{ color: theme.palette.secondary.light, fontSize: 14 }}>Total</Typography>
                                    <div style={{ display: "flex", paddingLeft: 30 }}>
                                        <Typography style={{ color: theme.palette.secondary.light, fontSize: 18 }}>{this.returnTotalAmount()}</Typography>
                                        <Typography style={{ color: theme.palette.secondary.light, fontSize: 13, paddingLeft: 5 }}>USD</Typography>
                                    </div>
                                </div>
                                <Button onClick={() => this.submitPayment()} disabled={this.returnTotalAmount() > 0 ? false : true} size="small" variant="contained" color="primary" fullWidth style={styles.addButton} >
                                    PROCEED WITH PAYMENT
                                </Button>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12}>

                        <Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
                            <div>
                                <Typography style={{ color: theme.palette.secondary.light, fontWeight: "bold", fontFamily: "Futura-Heavy", fontSize: 20 }}>Members</Typography>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[20]}
                                component="div"
                                count={members.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                slotProps={{
                                    select: {
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                labelRowsPerPage={null}
                                labelDisplayedRows={
                                    ({ from, to, count }) => null
                                }
                                ActionsComponent={
                                    TablePaginationActions
                                }
                            />
                            <div style={{ display: "flex" }}>
                                <Button size="small" variant="contained" color="primary" style={{
                                    marginRight: 5, paddingLeft: 10, paddingRight: 10, backgroundColor: theme.palette.primary.main,
                                    color: "white",
                                    fontFamily: "unicode.futurab",
                                    borderRadius: "10",
                                }} onClick={() => this.handleClick(null, "ADD")} >
                                    <FaUserAlt style={{ marginRight: 5, fontSize: 15, color: '#ffffff' }} /> ADD 1 MEMBER
                                </Button>
                                <Button size="small" variant="contained" color="primary" style={{
                                    marginRight: 5, paddingLeft: 10, paddingRight: 10, backgroundColor: theme.palette.primary.main,
                                    color: "white",
                                    fontFamily: "unicode.futurab",
                                    borderRadius: "10"
                                }} onClick={() => this.setState({ uploadMemberdialogOpen: true })}>
                                    <FaUsers style={{ marginRight: 5, fontSize: 15, color: '#ffffff' }} /> UPLOAD GROUP OF MEMBERS
                                </Button>
                            </div>
                        </Toolbar>
                        <Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
                            <Chip
                                size="small"
                                label={`ALL`}
                                // color={this.state.filter === "ALL" ? "primary" : "secondary"}
                                onClick={() => this.setState({ filter: "ALL", page: 0 })}
                                // style={{ label: styles.label }}
                                style={filter === 'ALL' ? styles.activeChip : styles.inActiveChip}
                                clickable
                                onDelete={() => null}
                                deleteIcon={<Badge styles={{ badge: styles.chipBadge }} badgeContent={this.returnPlanCount("ALL")} max={9999}></Badge>}
                            />
                            {this.state.filter === "PENDING-ASSIGNMENT" ?
                                <img alt="PendingAssignment" src={PendingAssignmenticon} width="auto" height={25} style={{ margin: 2 }} />
                                : <Chip
                                    size="small"
                                    label={`PENDING ASSIGNMENT `}
                                    // color={this.state.filter === "PENDING-ASSIGNMENT" ? "primary" : "secondary"}
                                    onClick={() => this.setState({ filter: "PENDING-ASSIGNMENT", page: 0 })}
                                    style={filter === 'PENDING-ASSIGNMENT' ? styles.activeChip : styles.inActiveChip}
                                    clickable
                                    onDelete={() => null}
                                    deleteIcon={<Badge styles={{ badge: styles.chipBadge }} badgeContent={this.returnPlanCount("PENDING-ASSIGNMENT")} max={9999}></Badge>}
                                />}
                            {this.state.filter === "BASIC" ?
                                <img alt="basic" src={Basicicon} width="auto" height={25} style={{ margin: 2 }} />
                                :
                                <Chip
                                    size="small"
                                    label={`BASIC `}
                                    // color={this.state.filter === "BASIC" ? "primary" : "secondary"}
                                    onClick={() => this.setState({ filter: "BASIC", page: 0 })}
                                    style={filter === 'BASIC' ? styles.activeChip : styles.inActiveChip}
                                    clickable
                                    onDelete={() => null}
                                    deleteIcon={<Badge styles={{ badge: styles.chipBadge }} badgeContent={this.returnPlanCount("BASIC")} max={9999}></Badge>}
                                />}
                            {this.state.filter === "STANDARD" ?
                                <img alt="Standard" src={Standardicon} width="auto" height={25} style={{ margin: 2 }} />
                                :
                                <Chip
                                    size="small"
                                    label={`STANDARD `}
                                    // color={this.state.filter === "STANDARD" ? "primary" : "secondary"}
                                    onClick={() => this.setState({ filter: "STANDARD", page: 0 })}
                                    style={filter === 'STANDARD' ? styles.activeChip : styles.inActiveChip}
                                    clickable
                                    onDelete={() => null}
                                    deleteIcon={<Badge styles={{ badge: styles.chipBadge }} badgeContent={this.returnPlanCount("STANDARD")} max={9999}></Badge>}
                                />}
                            {this.state.filter === "PREMIUM" ?
                                <img alt="premium" src={Premiumicon} width="auto" height={25} style={{ margin: 2 }} />
                                :
                                <Chip
                                    size="small"
                                    label={`PREMIUM `}
                                    // color={this.state.filter === "PREMIUM" ? "primary" : "secondary"}
                                    onClick={() => this.setState({ filter: "PREMIUM", page: 0 })}
                                    style={filter === 'PREMIUM' ? styles.activeChip : styles.inActiveChip}
                                    clickable
                                    onDelete={() => null}
                                    deleteIcon={<Badge styles={{ badge: styles.chipBadge }} badgeContent={this.returnPlanCount("PREMIUM")} max={9999}></Badge>}
                                />}
                        </Toolbar>
                        <Table>
                            <TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10 }}>
                                <TableRow >
                                    <TableCell align="left" style={{
                                        paddingLeft: 5, fontFamily: "Futura-Book",
                                        color: "#fff",
                                        fontWeight: 900,
                                        padding: '14px 20px 14px 0px',
                                    }}>Name</TableCell>
                                    <TableCell align="center" style={styles.fontCellHeader}>MedFilo Plan</TableCell>
                                    <TableCell align="left" style={styles.fontCellHeader}>COVID-19 Vaccine Status</TableCell>
                                    <TableCell align="left" style={styles.fontCellHeader}>COVID-19 Virus Test Status</TableCell>
                                    <TableCell align="left" style={styles.fontCellHeader}>COVID-19 Antibody Test Status</TableCell>
                                    <TableCell align="center" style={styles.fontCellHeader}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {members.length > 0 ? members.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell align="left" style={styles.fontTableCellBody}>
                                            <div style={{ display: "flex" }}>
                                                <img alt="user" src={UserIcon} height={25} />
                                                <div>
                                                    <Typography style={{ paddingLeft: 10 }}>{item.memberProfile.firstName} {item.memberProfile.lastName}</Typography>
                                                    <Typography style={{ paddingLeft: 10 }}>{item.memberProfile.phone}</Typography>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" style={styles.fontTableCellBody}>{this.returnPlan(item.memberProfile)}</TableCell>
                                        <TableCell align="left" style={{
                                            fontFamily: "Futura-Medium-bt",
                                            color: "#37474f",
                                            padding: '14px 20px 14px 0px', backgroundColor: this.returnOrgPlan(item.memberProfile) === true ? "#ffffff" : "#DAD9DE"
                                        }}>{this.returnOrgPlan(item.memberProfile) === true ? this.returnVaccineTest(item.memberProfile) : " -"}</TableCell>
                                        <TableCell align="left" style={{
                                            fontFamily: "Futura-Medium-bt",
                                            color: "#37474f",
                                            padding: '14px 20px 14px 0px', backgroundColor: this.returnOrgPlan(item.memberProfile) === true ? "#ffffff" : "#DAD9DE"
                                        }}>{this.returnOrgPlan(item.memberProfile) === true ? this.returnCovidTest(item.memberProfile) : "-"}</TableCell>
                                        <TableCell align="left" style={{
                                            fontFamily: "Futura-Medium-bt",
                                            color: "#37474f",
                                            padding: '14px 20px 14px 0px', backgroundColor: this.returnOrgPlan(item.memberProfile) === true ? "#ffffff" : "#DAD9DE"
                                        }}>{this.returnOrgPlan(item.memberProfile) === true ? this.returnAntibodyTest(item.memberProfile) : "-"}</TableCell>
                                        <TableCell align="center" style={styles.fontTableCellBody}>
                                            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => this.setState({ menu: event.currentTarget, selectedMemberDetail: item })}><MoreVertIcon /></IconButton>
                                        </TableCell>
                                    </TableRow>))
                                    :
                                    <Typography>There are no members</Typography>
                                }
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.menu}
                    keepMounted
                    open={Boolean(this.state.menu)}
                    onClose={() => this.setState({ menu: null })}
                >
                    <MenuItem onClick={() => this.setState({ menu: null, detailDialog: true })}>See More</MenuItem>
                    <MenuItem onClick={() => this.setState({ menu: null, removeDialog: true })}>Remove</MenuItem>
                </Menu>
                <Dialog
                    maxWidth={"sm"}
                    open={this.state.uploadMemberdialogOpen} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>UPLOAD GROUP OF MEMBERS</Typography>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "hidden" }}>
                        <div style={{ display: "flex", paddingBottom: 15, paddingTop: 15 }}>
                            <div style={{ width: "60%" }}>
                                <Typography style={{ color: "#20478E", fontSize: 15, paddingRight: 20 }}><span style={{ fontWeight: "bold" }}>1.</span> You can upload bulks of members / employees of your organization, all at once, by filing our template</Typography>
                            </div>
                            <div style={{ width: "40%" }}>
                                <Button size="small" variant="contained" color="primary" style={styles.downloadButton} onClick={() => this.downloadTemplate()}>
                                    <FaDownload style={{ fontSize: 30, color: '#ffffff' }} /> <span style={{ fontSize: 10, color: "#fff" }}>DOWNLOAD TEMPLATE FOR GROUP OF MEMBERS (Excel Files)</span>
                                </Button>
                            </div>
                        </div>
                        <div style={{ display: "flex", paddingBottom: 15, paddingTop: 15 }}>
                            <div style={{ width: "60%" }}>
                                <Typography style={{ color: "#20478E", fontSize: 15, paddingRight: 20 }}><span style={{ fontWeight: "bold" }}>2.</span> Once the template is fulfilled, just upload it here:</Typography>
                            </div>
                            <div style={{ width: "40%" }}>
                                <input
                                    accept=".xlsx, .xls"
                                    style={styles.input}
                                    id="text-button-file1"
                                    type="file"
                                    onChange={(event) => this.fileHandler(event)}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                                <label htmlFor="text-button-file1">
                                    <Button component="span" size="small" variant="contained" color="primary" style={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: "white",
                                        fontFamily: "unicode.futurab",
                                        borderRadius: "10", textTransform: "none"
                                    }}>
                                        <FaUpload style={{ fontSize: 30, color: '#ffffff' }} /> <span style={{ fontSize: 10, color: "#fff", textAlign: "center" }}>UPLOAD THE FULFILLED TEMPLATE (Excel Files)</span>
                                    </Button>
                                </label>
                            </div>
                        </div>
                        <div style={{ display: "flex", paddingBottom: 15, paddingTop: 15 }}>
                            <Typography style={{ color: "#20478E", fontSize: 15 }}><span style={{ fontWeight: "bold" }}>3.</span> Once done, you’ll see all those new members in the Pending Assignment segment. Follow  through to assign them seats to activate their onboarding, then we’ll take it from there for you.</Typography>
                        </div>

                    </DialogContent>
                    <DialogActions style={{ display: "flex", justifyContent: "center" }}>
                        <Button size="small" onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth={"sm"}
                    open={this.state.dialogOpen} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>ADD 1 MEMBER</Typography>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container justify="space-between" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <TextField
                                    id="search-phone"
                                    label="Phone number (Required)"
                                    name="search-phone"
                                    style={styles.margin}
                                    value={this.state.search_phone}
                                    // onChange={(event) => this.setState({ search_phone: event.target.value, search_phone_error: false, invalid_search_phone_error: false })}
                                    onChange={(event) => event.target.value.match(/^\d{0,10}$/g) && this.setState({
                                        search_phone: event.target.value, search_phone_error: false, invalid_search_phone_error: false,
                                        primary_contact_first_name: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_first_name,
                                        primary_contact_last_name: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_last_name, primary_contact_email: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_email, 
                                        primary_contact_zip_code: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_zip_code, primary_contact_gender: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_gender,
                                        primary_contact_date_of_birth: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_date_of_birth
                                    })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        styles: { input: styles.inputStyle },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>{this.state.primary_contact_country_code}</Typography>
                                            </InputAdornment>
                                        )
                                    }}
                                    autoComplete="off"
                                    fullWidth
                                    error={this.state.search_phone_error ? true : this.state.invalid_search_phone_error ? true : false}
                                    helperText={this.state.search_phone_error === true ? "Please enter phone number" : this.state.invalid_search_phone_error ? "Please enter valid phone number" : ""}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <div style={{ padding: 8, justifyContent: "center", alignItems: "center", display: "flex" }}>
                                    <Button size="small" onClick={() => this.searchUser()} variant="contained" color="primary" style={styles.addButton}>Find USer</Button>
                                </div>
                                <Typography style={{ fontSize: "10px", color: "#20478E", textAlign: "center" }}>(If user already exist)</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <TextField
                                    id="first name"
                                    label="First Name (Required)"
                                    name="first name"
                                    style={styles.margin}
                                    value={this.state.primary_contact_first_name.charAt(0).toUpperCase() + this.state.primary_contact_first_name.slice(1)}
                                    autoComplete="off"
                                    onChange={(event) => this.setState({ primary_contact_first_name: event.target.value, primary_contact_first_name_error: false })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        styles: { input: styles.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.primary_contact_first_name_error === true ? true : false}
                                    helperText={this.state.primary_contact_first_name_error === true ? "Please enter the first name" : false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <TextField
                                    id="last name"
                                    label="Last Name (Required)"
                                    name="last name"
                                    autoComplete="off"
                                    style={styles.margin}
                                    value={this.state.primary_contact_last_name.charAt(0).toUpperCase() + this.state.primary_contact_last_name.slice(1)}
                                    onChange={(event) => this.setState({ primary_contact_last_name: event.target.value, primary_contact_last_name_error: false })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        styles: { input: styles.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.primary_contact_last_name_error === true ? true : false}
                                    helperText={this.state.primary_contact_last_name_error === true ? "Please enter last name" : false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }} >
                                <TextField
                                    id="email"
                                    label="E-mail (Required)"
                                    name="email"
                                    autoComplete="off"
                                    style={styles.margin}
                                    value={this.state.primary_contact_email}
                                    onChange={(event) => this.setState({ primary_contact_email: event.target.value, primary_contact_email_error: false, invalid_primary_contact_email_error: false })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        styles: { input: styles.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.primary_contact_email_error === true ? true : this.state.invalid_primary_contact_email_error ? true : false}
                                    helperText={this.state.primary_contact_email_error === true ? "Enter the email" : this.state.invalid_primary_contact_email_error ? "Please enter valid mail" : false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }} >
                                <TextField
                                    id="email"
                                    label="Work E-mail (Differ from E-Mail)"
                                    name="email"
                                    autoComplete="off"
                                    style={styles.margin}
                                    value={this.state.corporateEmail}
                                    onChange={(event) => this.setState({ corporateEmail: event.target.value, corporateEmail_error: false, invalid_corporateEmail_error: false })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                        style: { width: "max-content" }
                                    }}
                                    InputProps={{
                                        styles: { input: styles.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.corporateEmail_error === true ? true : this.state.invalid_corporateEmail_error ? true : false}
                                    helperText={this.state.corporateEmail_error === true ? "Enter the email" : this.state.invalid_corporateEmail_error ? "Please enter valid mail" : false}
                                />
                            </Grid>
                            {/* <Grid item xs={6} style={{ paddingRight: 8 }} >
                                <TextField
                                    id="gender"
                                    select
                                    label="Gender"
                                    name="gender"
                                    value={this.state.primary_contact_gender}
                                    onChange={(event) => this.setState({ primary_contact_gender: event.target.value, primary_contact_gender_error: false })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        styles: { input: styles.inputStyle }
                                    }}
                                    autoComplete="off"
                                    fullWidth
                                    error={this.state.primary_contact_gender_error === true ? true : false}
                                    helperText={this.state.primary_contact_gender_error === true ? "Please select the gender" : false}
                                >
                                    <MenuItem value="Male" >Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    <MenuItem value="Others">Others</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }} >
                                <div style={styles.textView}>
                                    <Typography style={{ fontSize: 13, paddingTop: 9, color: this.state.primary_contact_date_of_birth_error || this.state.invalid_date_error || this.state.invalid_primary_contact_date_of_birth_error ? "#F44336" : "gray" }}>Date of Birth</Typography>
                                    <InputMask
                                        placeholder={"MM/DD/YYYY"}
                                        fullWidth
                                        style={{ borderBottomStyle: 'solid', color: this.state.primary_contact_date_of_birth_error ? "#F44336" : "rgba(0,0,0,0.9)", borderBottomWidth: this.state.primary_contact_date_of_birth_error || this.state.invalid_date_error || this.state.invalid_primary_contact_date_of_birth_error ? 2 : 1, borderBottomColor: this.state.primary_contact_date_of_birth_error || this.state.invalid_date_error || this.state.invalid_primary_contact_date_of_birth_error ? "#F44336" : 'gray' }}
                                        type={'datetime'}
                                        mask="99/99/9999"
                                        maskChar={null}
                                        InputProps={{
                                            styles: { input: styles.inputStyle }
                                        }}
                                        value={this.state.primary_contact_date_of_birth}
                                        onChange={this.onChange} >
                                        {(inputProps) => <Input   {...inputProps} type="datetime" disableUnderline onChange={this.props.onChange} error={this.state.primary_contact_date_of_birth_error ? true : this.state.invalid_primary_contact_date_of_birth_error ? true : false} />}
                                    </InputMask>
                                    {this.state.primary_contact_date_of_birth_error ? <Typography style={{ color: '#F44336', fontSize: 12 }}>Please enter date of birth </Typography> : this.state.invalid_date_error || this.state.invalid_primary_contact_date_of_birth_error ? <Typography style={{ fontSize: 12, color: '#F44336', }}> {this.state.errorText}  </Typography> : null}
                                </div>
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }} >
                                <TextField
                                    id="zip code"
                                    label="Zip code"
                                    name="zip code"
                                    autoComplete="off"
                                    style={styles.margin}
                                    value={this.state.primary_contact_zip_code}
                                    onChange={(event) => event.target.value.match(/^\d{0,5}$/g) && this.setState({ primary_contact_zip_code: event.target.value, primary_contact_zip_code_error: false, invalid_primary_contact_zip_code_error: false })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{

                                        styles: { input: styles.inputStyle, }
                                    }}
                                    fullWidth
                                    error={this.state.primary_contact_zip_code_error === true ? true : this.state.invalid_primary_contact_zip_code_error === true ? true : false}
                                    helperText={this.state.primary_contact_zip_code_error === true ? "Please enter zipcode" : this.state.invalid_primary_contact_zip_code_error === true ? "Please enter 5 digits valid zip code" : false}
                                />
                            </Grid> */}
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ display: "flex", justifyContent: "center" }}>
                        <Button size="small" onClick={() => this.handleSubmit(this.state.mode)} variant="contained" color="primary" style={styles.addButton}>Submit</Button>
                        <Button size="small" onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth={"xs"}
                    fullWidth
                    open={this.state.credit_dialog} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>BUY CREDITS</Typography>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "hidden" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Card style={{ margin: 10, width: 200, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 15 }}>
                                <Typography style={{ color: "white", fontSize: 11 }}>AVAILABLE CREDITS</Typography>
                                <Typography style={{ color: "white", fontSize: 22, fontWeight: "bold", paddingTop: 8 }}>{this.props.organizationDetail !== null && this.props.organizationDetail.availableCredits !== null ? this.props.organizationDetail.availableCredits : 0}</Typography>
                                <div style={{ textAlign: "end", marginTop: "-50px" }}>
                                    <AccountBalanceWalletIcon style={{ color: "#cfd4d6", fontSize: "50px" }} />
                                </div>
                            </Card>
                        </div>
                        <div>
                            <TextField
                                id="add-credit"
                                label="Buy Credits"
                                name="add-credit"
                                autoComplete="off"
                                style={styles.margin}
                                value={this.state.add_credit}
                                onChange={(event) => this.setState({ add_credit: event.target.value, add_credit_error: false })}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    styles: { input: styles.inputStyle }
                                }}
                                fullWidth
                                error={this.state.add_credit_error === true ? true : false}
                                helperText={this.state.add_credit_error === true ? "Please enter your buy credits " : false}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions style={{ display: "flex", justifyContent: "center" }}>
                        {/* <Button size="small" onClick={() => this.addCredits()} variant="contained" color="primary" style={styles.addButton}>Submit</Button> */}
                        <Button size="small" onClick={(e) => this.handlePayment(e)} variant="contained" color="primary" style={styles.addButton}>Checkout</Button>
                        <Button size="small" onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth={"sm"}
                    fullWidth
                    open={this.state.apply_credit_dialog} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>APPLY CREDITS</Typography>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "hidden" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Card style={{ margin: 10, width: 200, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 15 }}>
                                <Typography style={{ color: "white", fontSize: 11 }}>AVAILABLE CREDITS</Typography>
                                <Typography style={{ color: "white", fontSize: 22, fontWeight: "bold", paddingTop: 8 }}>{(this.props.organizationDetail !== null && this.props.organizationDetail.availableCredits !== null) ? this.props.organizationDetail.availableCredits : 0}</Typography>
                                <div style={{ textAlign: "end", marginTop: "-50px" }}>
                                    <AccountBalanceWalletIcon style={{ color: "#cfd4d6", fontSize: "50px" }} />
                                </div>
                            </Card>
                            <Card style={{ margin: 10, width: 200, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 15 }}>
                                <Typography style={{ color: "white", fontSize: 11 }}>NON PREMIUM USERS</Typography>
                                <Typography style={{ color: "white", fontSize: 22, fontWeight: "bold", paddingTop: 8 }}>{this.state.nonPremiumUsers.length}</Typography>
                                <div style={{ textAlign: "end", marginTop: "-50px" }}>
                                    <img src={UserIcon} alt="UserIcon" height={25} style={{ color: "#cfd4d6" }} />
                                    {/* <AccountBalanceWalletIcon style={{ color: "#cfd4d6", fontSize: "50px" }} /> */}
                                </div>
                            </Card>
                        </div>
                        <div style={{ padding: "10px" }}>
                            {(parseInt((this.props.organizationDetail !== null && this.props.organizationDetail.availableCredits !== null) ? this.props.organizationDetail.availableCredits : 0) >= parseInt(this.state.nonPremiumUsers.length)) ?
                                <div >
                                    <Typography style={{ fontSize: 17, color: "#000", display: "flex" }}>
                                        You have enough credits for all non premium users. Do you want to apply?
                                        <Button size="small" onClick={() => this.applyBulkCredit()} variant="contained" color="primary" style={styles.addButton}>Yes</Button>
                                    </Typography>
                                </div>
                                :
                                <div>
                                    <Typography style={{ fontSize: 17, color: "#000", display: "flex" }}>
                                        You don't have enough credits for all non premium users. Do you want to buy more credits?
                                        <Button size="small" variant="contained" color="primary" onClick={() => this.setState({ credit_dialog: true })} style={styles.addButton}>Yes</Button>
                                    </Typography>
                                </div>
                            }
                        </div>
                    </DialogContent>
                    <DialogActions style={{ display: "flex", justifyContent: "center" }}>
                        <Button size="small" onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.isLoading}
                    styles={{
                        paper: styles.dialogPaper,
                    }}
                >
                    <div style={{ boxShadow: 0, overflow: "none" }}>
                        <CircularProgress color="primary" size={40} />
                    </div>
                </Dialog>
                <Dialog
                    maxWidth={"lg"}
                    fullWidth
                    open={this.state.result_dialog} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>Result</Typography>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "hidden" }}>
                        <Table>
                            <TableHead style={{ backgroundColor: "#20478E", padding: 10 }}>
                                <TableRow >
                                    <TableCell align="left" style={styles.fontCellHeader}>Phone</TableCell>
                                    <TableCell align="left" style={{
                                        paddingLeft: 5, fontFamily: "Futura-Book",
                                        color: "#fff",
                                        fontWeight: 900,
                                        padding: '14px 20px 14px 0px',
                                    }}>Name</TableCell>
                                    <TableCell align="left" style={styles.fontCellHeader}>Email</TableCell>
                                    <TableCell align="left" style={styles.fontCellHeader}>Data of Birth</TableCell>
                                    <TableCell align="left" style={styles.fontCellHeader}>Gender</TableCell>
                                    <TableCell align="left" style={styles.fontCellHeader}>Zipcode</TableCell>
                                    <TableCell align="left" style={styles.fontCellHeader}>Status</TableCell>
                                    <TableCell align="left" style={styles.fontCellHeader}>Note</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result_array.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                                    <TableRow key={item.primaryContactPhone}>
                                        <TableCell align="left" style={styles.fontTableCellBody}>
                                            {item.primaryContactPhone !== null ? `${item.countryCode} ${item.primaryContactPhone}` : ""}
                                        </TableCell>
                                        <TableCell align="left" style={styles.fontTableCellBody}>
                                            {`${item.firstName !== null ? item.firstName : ""} ${item.lastName !== null ? item.lastName : ""}`}
                                        </TableCell>
                                        <TableCell align="left" style={styles.fontTableCellBody}>
                                            {item.primaryContactemail}
                                        </TableCell>
                                        <TableCell align="left" style={styles.fontTableCellBody}>
                                            {moment(item.dateOfBirth).format("MM/DD/YYYY")}
                                        </TableCell>
                                        <TableCell align="left" style={styles.fontTableCellBody}>
                                            {item.gender}
                                        </TableCell>
                                        <TableCell align="left" style={styles.fontTableCellBody}>
                                            {item.zipCode}
                                        </TableCell>
                                        <TableCell align="left" style={styles.fontTableCellBody}>
                                            {item.status}
                                        </TableCell>
                                        <TableCell align="left" style={styles.fontTableCellBody}>
                                            {item.note}
                                        </TableCell>
                                    </TableRow>))
                                }
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions style={{ display: "flex", justifyContent: "center" }}>
                        <Button size="small" onClick={() => this.setState({
                            result_dialog: false,
                            result_array: []
                        })} variant="contained" color="secondary" style={styles.cancelButton}>Close</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth={"sm"}
                    open={this.state.assignSeatDialog} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main},${theme.palette.primary.dark}`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>Assign seat to member</Typography>
                    </DialogTitle>
                    <DialogContent >
                        <div>
                            {this.props.organizationPlan.map(item =>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Radio
                                        checked={this.state.selectedPlan !== null ? this.state.selectedPlan.id === item.id : ""}
                                        onChange={() => this.selectOrganizationPlan(item)}
                                        value={item.id}
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <Typography style={{ color: "#000", fontSize: 14 }}>{item.name} Seat</Typography>
                                    </div>
                                </div>)}
                            {this.state.selectedPlan !== null && this.state.selectedPlan.code === "OBSC" &&
                                <TextField
                                    id="basic-plan-cunt"
                                    label="Plan Count"
                                    name="basic-plan-count"
                                    autoComplete="off"
                                    style={styles.margin}
                                    value={this.state.selectedPlanCount}
                                    onChange={(event) => this.setState({ selectedPlanCount: event.target.value, selectedPlanCountError: false })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        styles: { input: styles.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.selectedPlanCountError === true ? true : false}
                                    helperText={this.state.selectedPlanCountError === true ? "Please enter your plan Count " : ""}
                                />}
                        </div>
                    </DialogContent>
                    <DialogActions style={{ display: "flex", justifyContent: "center" }}>
                        <Button size="small" onClick={() => this.assignSubscriptionPlan()} variant="contained" color="primary" style={styles.addButton}>Submit</Button>
                        <Button size="small" onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth={"xs"}
                    open={this.state.removeDialog} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main},${theme.palette.primary.dark}`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"Confirmation"}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div style={styles.qrContainer}>
                            {`Are you sure, you want to remove this member from organization?`}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={() => this.removeMember()} variant="contained" color="primary" style={styles.viewGreenButton}>Yes</Button>
                        <Button size="small" onClick={() => this.setState({ removeDialog: false, selectedMemberDetail: null })} variant="contained" color="clearButton" style={styles.viewRedButton}>No</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth
                    open={this.state.detailDialog} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main},${theme.palette.primary.dark}`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"Member Details"}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.selectedMemberDetail !== null &&
                            <Grid container >
                                <Grid item xs={6}>
                                    <div style={{ display: 'flex', justifyContent: "center" }}>
                                        {this.returnUserImage(this.state.selectedMemberDetail.memberProfile) !== null ? <img alt="user" src={this.returnUserImage(this.state.selectedMemberDetail.memberProfile)} style={styles.userImage} /> : <AccountCircle style={styles.userAccount} />}
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>First Name</Typography>
                                    <Typography style={{ fontSize: 13, fontWeight: "bold" }}>{this.state.selectedMemberDetail.memberProfile.firstName}</Typography>
                                    <Typography>Last Name</Typography>
                                    <Typography style={{ fontSize: 13, fontWeight: "bold" }}>{this.state.selectedMemberDetail.memberProfile.lastName}</Typography>
                                    <Typography>Email</Typography>
                                    <Typography style={{ fontSize: 13, fontWeight: "bold" }}>{this.state.selectedMemberDetail.memberProfile.email}</Typography>
                                    <Typography>Phone Number</Typography>
                                    <Typography style={{ fontSize: 13, fontWeight: "bold" }}>{this.state.selectedMemberDetail.memberProfile.phone}</Typography>
                                </Grid>
                            </Grid>}
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button size="small" onClick={() => this.setState({ detailDialog: false, selectedMemberDetail: null })} variant="contained" color="clearButton" style={styles.viewRedButton}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
    return {
        organizationDetail: state.organizationReducer.organizationDetail,
        premiumUserSupporting: state.settingsReducer.premiumUserSupporting,
        fetch_user: state.userReducer.fetch_user,
        vaccine: state.vaccineReducer.vaccine,
        organizationPlan: state.organizationReducer.organizationPlan,
        organizationPlanBalance: state.organizationReducer.organizationPlanBalance,
        organizationMembers: state.organizationReducer.organizationMembers
    }
}
Member.propTypes = {
    styles: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps)(Member));