import { FETCH_APP_VERSIONS, ALERT_DETAIL } from './actionTypes';
import API from '../api';

export function fetchAppVersions() {
	return async dispatch => {
        API.get("app-version").then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_APP_VERSIONS, data: response.payload });
			} 
		});
	}
}


export function addAppVersion(self, item) {
	return dispatch => {
		API.post('app-version', item).then((response) => {
			if (response.status === "success") {
				dispatch(fetchAppVersions());
				dispatch({ type: ALERT_DETAIL, data: true, message: "App Version added successfully", severity: "success" })
                self.handleClose()
			}
			else {
				self.setState({ isLoading: false, })
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
			}
		})
	}
}

export function updateAppVersion(self, item, id) {
	return dispatch => {
		API.put('app-version/' + id, item).then((response) => {
			if (response.status === "success") {
				dispatch(fetchAppVersions());
				dispatch({ type: ALERT_DETAIL, data: true, message: "App Version updated successfully", severity: "success" })
                self.handleClose()
			}
			else {
				self.setState({ isLoading: false, })
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
			}
		})
	}
}