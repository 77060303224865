import React, { Component } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { connect } from "react-redux";
import Passport from '../../assets/images/passportimg.png';
import PropTypes from 'prop-types';
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { THEMECOLOR } from "../../helpers/Constants";

const DOT_LENGTH = 10;

const styles = {
    paper: {
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        height: '1vh'
    },
    topContainer: {
        // backgroundColor: "#20478E",
        backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
        height: "200px",
        justifyContent: "center",
        alignItems: "center",
        padding: 25,
        borderBottomLeftRadius: "10%",
        borderBottomRightRadius: "10%",

    },
    bottomContainer: {
        marginTop: -70,
        backgroundColor: "#fff",
        borderRadius: 10,
        margin: "0px 30px",
        padding: "10px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s"
    },
    textStyle: {
        fontSize: 20,
        color: "#fff"
    },
    imageView: {
        // width: (DEVICE_WIDTH / 2)+20,
        // height: DEVICE_HEIGHT / 3.25,
        display: 'flex',
        marginTop: -50,
        borderRadius: 5,
        justifyContent: "center",
        alignItems: "center",
    },
    imageStyle: {
        height: "300px",
        width: '300px',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        resizeMode: 'cover',
        marginBottom: 0
    },
    bottomText: {
        fontSize: 15,
        color: "#000",
        textAlign: "center"
    },
    bottomTextView: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 5
    },
    textView: {
        marginTop: -25,
        paddingHorizontal: 15,
        paddingVertical: 15
    },
    topText: {
        fontSize: 20,
        textAlign: "center",
        paddingBottom: 15
    },
    button: {
        padding: 10,
        borderRadius: 5,
        fontSize: 15,
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.main
    },
    continueText: {
        marginTop: 5,
        fontSize: 15,
        color: "#ffffff",
        textTransform: 'capitalize'
    },
    infoContainer: {
        display: 'flex',
        height: '100%',
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
    },
    dotView: {
        display: 'flex',
        flexDirection: "row",
        width: "90%",
        alignItems: "center",
        justifyContent: "space-around",
        marginTop: -80
    },
    borderDot: {
        width: 15,
        height: 15,
        borderRadius: 12,
        backgroundColor: "#fff",
        borderWidth: 6,
        borderColor: "rgba(265,265,265,0.2)"
    },
    dotStyle: {
        width: 10,
        height: 10,
        borderRadius: 6,
        backgroundColor: "rgba(265,265,265,0.5)"
    },
    privacyText: {
        fontSize: 10,
        fontWeight: "bold",
        padding: 5
    },
};

class IDVerification extends Component {
    constructor() {
        super();
        this.state = {

        }
    }

    renderDotView = () => {
        let DotArray = []
        for (let i = 0; i < DOT_LENGTH; i++) {
            DotArray.push(<div key={i.toString()} id={i} style={{
                width: 4,
                height: 4,
                borderRadius: 2,
                backgroundColor: "rgba(265,265,265,0.5)"
            }} />)
        }
        return DotArray;
    };

    render() {

        return (
            <div style={styles.paper}>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <div style={styles.topContainer}>
                            <Typography style={{ ...styles.textStyle, color: "#fff", padding: "5px 0px", textAlign: 'center' }} >ID Verification</Typography>
                            <div style={styles.infoContainer}>
                                <div style={styles.dotView}>
                                    <div style={styles.dotStyle} />
                                    {this.renderDotView()}
                                    <div style={styles.borderDot} />
                                    {this.renderDotView()}
                                    <div style={styles.dotStyle} />
                                </div>
                            </div>
                        </div>
                        <Grid container justifyContent="center" style={{ marginTop: -60 }}>
                            <Grid item xs={12} sm={6} md={4}>
                                <div style={styles.bottomContainer}>
                                    <div style={styles.imageView}>
                                        <img
                                            alt="Passport"
                                            src={Passport}
                                            style={styles.imageStyle}
                                        />
                                    </div>
                                    <div style={styles.textView}>
                                        <Typography style={styles.topText}>Scan a government-issued photo ID (e.g. Driver’s license, Passport, etc.)</Typography>
                                        <div style={styles.bottomTextView}>
                                            <Typography style={{ ...styles.bottomText, fontWeight: "bold" }} >Do : </Typography>
                                            <Typography style={styles.bottomText}> Make sure entire ID is within frame </Typography>
                                        </div>
                                        <div style={styles.bottomTextView}>
                                            <Typography style={{ ...styles.bottomText, fontWeight: "bold" }} >Don't : </Typography>
                                            <Typography style={styles.bottomText}>Use scan or photocopy </Typography>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={6} md={4}>
                                <div style={{ padding: 30 }}>
                                    <Typography style={styles.privacyText}>Privacy Notice: Your government-issued photo ID will NOT be stored in our system not shared for any reason.</Typography>
                                    <Button
                                        fullWidth
                                        type="Button"
                                        variant="contained"
                                        color="primary"
                                        style={styles.button}
                                        onClick={() => this.props.navigate("/id-camera")}
                                    >
                                        <Typography style={styles.continueText}>Open Camera</Typography>
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {

    };
};

IDVerification.propTypes = {
    navigate: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(IDVerification));