import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Toolbar, Typography } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { addDonor, fetchDonor } from "../../actions/donorAction";
import { searchUser } from "../../actions/memberAction";
import { headerFont } from '../../helpers/Constants';
import theme from "../../theme";
import { withRouter } from "../../withRouter";
import TablePaginationActions from "../Dashboard/TablePaginationActions";

/**Created common( styles for UI.*/
const styles = {
	textStyle: {
		fontFamily: "Futura-Heavy",
		color: "#37474f",
		fontSize: 20,
	},
	creditTextStyle: {
		fontFamily: "Futura-Heavy",
		color: "#37474f",
		fontStyle: "bold",
		fontSize: 15,
		paddingTop: 10,
	},
	addButton: {
		color: "white",
		fontFamily: "unicode.futurab",
		borderRadius: "10",
	},
	cancelButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		fontFamily: "unicode.futurab",
		textTransform: "none",
		"&:hover": {
			backgroundColor: "rgba(143, 163, 173, 0.8)",
		},
	},
	editButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		fontFamily: "unicode.futurab",
		textTransform: "none",
		"&:hover": {
			backgroundColor: "rgba(143, 163, 173, 0.8)",
		},
	},
	fontCellHeader: {
		fontFamily: "Futura-Book",
		color: "#fff",
		fontWeight: 900,
		padding: "14px 20px 14px 0px",
	},
	fontTableCellBody: {
		fontFamily: "Futura-Medium-bt",
		color: "#37474f",
		padding: "14px 20px 14px 0px",
	},
	inputStyle: {
		fontFamily: "Futura-Book",
		fontSize: 14,
	},
	labelStyle: {
		fontFamily: "Futura-Book",
		fontSize: 14,
	},
	inputStyleForSearchText: {
		fontSize: "15px !important",
		padding: "5px !important",
		width: 250,
	},
	inputRoot: {
		borderRadius: "0px !important",
	},
	inputLabel: {
		color: "#4B86E3",
	},
	dialogPaper: {
		backgroundColor: "rgba(0,0,0,0)",
		boxShadow: "none",
		overflow: "hidden",
	},
};

/**
 * @class
 *
 * Class representing Donor component
 *
 */
class AddDonor extends Component {
	constructor() {
		super();
		this.state = {
			member: [],
			view_member_details: false,
			dialogOpen: false,
			id: null,
			primary_contact_first_name: "",
			primary_contact_first_name_error: false,
			primary_contact_last_name: "",
			primary_contact_last_name_error: false,
			primary_contact_email: "",
			primary_contact_email_error: false,
			invalid_primary_contact_email_error: false,
			primary_contact_country_code: "+1",
			mode: "",
			status: "ACTIVE",
			search_phone: "",
			search_phone_error: false,
			invalid_search_phone_error: false,
			organization_name: "",
			search_text: "",
			rowsPerPage: 10,
			page: 0,
			isLoading: false,
			sortBy: "NAME_ASC"
		};
	}

	/** This is a component life cycle method and used to fetch datas  */
	componentDidMount() {
		/** fetching Donor details. */
		this.props.dispatch(fetchDonor());
	};

	/**
		 *@function
		 * This function representing view member details in dialog.
		 * @param {Object} item The item json object
		 */
	viewMemeberDetails = (item) => {
		this.setState({ view_member_details: true, memberdetails: item });
	};

	/**
	 *@function
	 * This function representing add or edit Donor dialog screen will open.
	 * @param {Object} item The item json object
	 * @param {String} mode The mode string
	 */
	handleClick = (item, mode) => {
		if (mode === "ADD") {
			this.setState({ mode: mode, dialogOpen: true });
		} else {
			this.setState({
				id: item.id,
				dialogOpen: true,
				mode: mode,
				first_name: item.first_name,
				first_name_error: false,
				last_name: item.last_name,
				last_name_error: false,
				email: item.email,
				email_error: false,
				gender: item.gender,
				gender_error: false,
				data_of_birth: item.data_of_birth,
				data_of_birth_error: false,
				zip_code: item.zip_code,
				zip_code_error: false,
			});
		}
	};

	/**
	 * @function
	 * This function representing table pagination.
	 * @param {newPage} newPage
	 */
	handleChangePage = (_event, newPage) => {
		this.setState({
			page: newPage,
		});
	};

	/**
	 * @function
	 * This function representing table pagination rows.
	 * @param {event} event
	 */
	handleChangeRowsPerPage = (event) => {
		this.setState({
			page: 0,
			rowsPerPage: parseInt(event.target.value, 10),
		});
	};

	/**
	 * @function
	 * This function representing close add or edit Donor dialog screen.
	 */
	handleClose = () => {
		this.setState({
			dialogOpen: false,
			organization_name: "",
			view_member_details: false,
			id: null,
			primary_contact_first_name: "",
			primary_contact_first_name_error: false,
			primary_contact_last_name: "",
			primary_contact_last_name_error: false,
			primary_contact_email: "",
			primary_contact_email_error: false,
			invalid_primary_contact_email_error: false,
			search_phone: "",
			search_phone_error: false,
			invalid_search_phone_error: false,
			primary_contact_country_code: "+1",
			mode: "",
		});
	};

	/**
	 *@function
	 * This function representing Number validation.
	 * @param {Number} number The number Number
	 */
	checkNumber = (number) => {
		var isNumber = /^-{0,1}\d+$/.test(number);
		return isNumber;
	};

	/**
	 *@function
	 * This function representing email validation.
	 * @param {String} email The email string
	 */
	validateEmail(email) {
		var re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};

	/**
	 *@function
	 * This function representing add or edit Donor.
	 * @param {String} mode The mode string
	 */
	handleSubmit = (mode) => {
		let {
			primary_contact_first_name,
			organization_name,
			primary_contact_last_name,
			primary_contact_email,
			search_phone,
			primary_contact_country_code,
		} = this.state;

		let isError = false;

		if (search_phone === "" || search_phone === null) {
			this.setState({ search_phone_error: true });
			isError = true;
		} else {
			if (
				this.checkNumber(search_phone) !== true ||
				search_phone.length !== 10
			) {
				this.setState({ invalid_search_phone_error: true });
				isError = true;
			}
		}
		if (
			primary_contact_first_name === "" ||
			primary_contact_first_name === null
		) {
			this.setState({ primary_contact_first_name_error: true });
			isError = true;
		}

		if (
			primary_contact_last_name === "" ||
			primary_contact_last_name === null
		) {
			this.setState({ primary_contact_last_name_error: true });
			isError = true;
		}
		if (primary_contact_email === "" || primary_contact_email === null) {
			this.setState({ primary_contact_email_error: true });
			isError = true;
		}

		if (primary_contact_email !== null && primary_contact_email !== "") {
			if (!this.validateEmail(primary_contact_email)) {
				this.setState({ invalid_primary_contact_email_error: true });
				isError = true;
			}
		}
		if (isError === false) {
			let memberObj = {};
			memberObj.primaryContactPhone = search_phone;
			memberObj.firstName = primary_contact_first_name;
			memberObj.lastName = primary_contact_last_name;
			memberObj.primaryContactemail = primary_contact_email;
			memberObj.countryCode = primary_contact_country_code;
			memberObj.donarOrganization = organization_name;
			if (mode === "ADD") {
				this.setState({ isLoading: true });
				this.props.dispatch(addDonor(this, memberObj));
			}
		}
	};

	/**
	 * @function
	 * This function representing  searchUser.
	 */
	searchUser = () => {
		if (this.state.search_phone !== "" && this.state.search_phone !== null) {
			this.props.dispatch(searchUser(this, this.state.search_phone));
		}
	};

	/** rendering Donors information. */
	render() {

		const { page, rowsPerPage } = this.state;
		let donors = this.state.sortBy === "NAME_ASC" ? this.props.donors.sort((a, b) => a.userProfile.firstName.localeCompare(b.userProfile.firstName)) : this.props.donors.sort((a, b) => b.userProfile.firstName.localeCompare(a.userProfile.firstName));
		let result =
			this.state.search_text !== ""
				? donors.filter(
					(x) =>
						x.userProfile.firstName
							.toString()
							.toLowerCase()
							.includes(this.state.search_text.toString().toLowerCase()) ||
						x.userProfile.phone
							.toString()
							.includes(this.state.search_text.toString()) ||
						x.userProfile.email
							.toString()
							.toLowerCase()
							.includes(this.state.search_text.toString().toLowerCase())
				)
				: donors;
		return (
			<div style={{ paddingLeft: 2, paddingRight: 10 }}>
				<Grid container spacing={0} style={{ marginTop: 10, }}>
					<Grid item xs={12} sm={12} style={{ marginBottom: 10, marginLeft: 5 }}>
						<Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
							<div>
								<Typography
									style={{ color: theme.palette.secondary.light, fontWeight: headerFont.fontWeight, fontFamily: headerFont.fontFamily, fontSize: headerFont.fontSize, }}>
									Donors
								</Typography>
							</div>
							<TablePagination
								rowsPerPageOptions={[20]}
								component="div"
								count={result.length}
								rowsPerPage={this.state.rowsPerPage}
								page={this.state.page}
								slotProps={{
									select: {
										inputProps: { 'aria-label': 'rows per page' },
										native: true,
									}
								}}
								onChangePage={this.handleChangePage}
								onChangeRowsPerPage={this.handleChangeRowsPerPage}
								labelRowsPerPage={null}
								labelDisplayedRows={() => null}
								ActionsComponent={TablePaginationActions}
							/>
							<div style={{ display: "flex", padding: 10 }}>
								<Button
									size="small"
									variant="contained"
									color={"primary"}
									style={{ ...styles.addButton, marginRight: 10 }}
									onClick={() => this.handleClick(null, "ADD")}
								>
									Add Donor
								</Button>
							</div>
							<TextField
								margin="normal"
								id="search-text"
								variant="standard"
								label="Search" styles
								placeholder="Name,Phone or Email"
								name="mobile-number"
								autoComplete="off"
								value={this.state.search_text}
								onChange={(event) =>
									this.setState({ search_text: event.target.value, page: 0 })
								}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									classes: {
										input: styles.inputStyleForSearchText,
										root: styles.inputRoot,
										inputlabel: styles.inputLabel,
									},
								}}
								autoFocus
							/>
						</Toolbar>
					</Grid>
					<Grid item xs={8} sm={12} style={{ paddingTop: 10 }}>
						<Table>
							<TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10 }}>
								<TableRow>
									<TableCell align="center" style={{ fontFamily: "Futura-Book", color: ' #fff', fontWeight: 900, padding: '14px 0px 14px 20px', display: "flex", justifyContent: "center", alignItems: "center" }}>
										Donor Name
										<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_ASC" })} />
										<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_DSC" })} />
									</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>
										Mobile Number
									</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>
										Email
									</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>
										Organization Name
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{result.length > 0 ? (
									result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
										<TableRow key={item.id}>
											<TableCell align="center" style={{ ...styles.fontTableCellBody, paddingLeft: 5 }}>
												{`${item.userProfile.firstName} ${item.userProfile.lastName}`}
											</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>
												{`${item.userProfile.countryCode} ${item.userProfile.phone}`}
											</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>
												{item.userProfile.email}
											</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody} >
												{item.userProfile.donorOrganization}
											</TableCell>
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell colSpan={4} style={{ ...styles.fontTableCellBody, textAlign: "center" }}>
											There are no donors
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</Grid>
				</Grid>
				<Dialog maxWidth={"sm"} open={this.state.dialogOpen}>
					<DialogTitle
						id="form-dialog-title"
						style={{
							padding: 0,
							marginBottom: 20,
							backgroundImage:
								`linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
							boxShadow:
								"0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
						}}
					>
						<Typography
							style={{
								padding: "2%",
								fontFamily: "Futura-Heavy",
								color: "#fff",
								fontWeight: "bold",
							}}
						>
							{this.state.mode === "ADD" ? "Add Donor" : "Update Donor"}
						</Typography>
					</DialogTitle>
					<DialogContent style={{ overflowY: "hidden" }}>
						<Grid
							container
							justify="space-between"
							style={{ marginTop: -20, padding: "2% 5% 0 5%" }}
						>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									id="search-phone"
									label="Phone Number"
									name="search-phone"
									style={styles.margin}
									value={this.state.search_phone}
									onChange={(event) =>
										event.target.value.match(/^\d{0,10}$/g) &&
										this.setState({
											search_phone: event.target.value,
											search_phone_error: false,
											invalid_search_phone_error: false,
											primary_contact_first_name:
												event.target.value === "" ||
													event.target.value.length < 10
													? ""
													: this.state.primary_contact_first_name,
											primary_contact_last_name:
												event.target.value === "" ||
													event.target.value.length < 10
													? ""
													: this.state.primary_contact_last_name,
											primary_contact_email:
												event.target.value === "" ||
													event.target.value.length < 10
													? ""
													: this.state.primary_contact_email,
										})
									}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle },
										startAdornment: (
											<InputAdornment position="start">
												<Typography>
													{this.state.primary_contact_country_code}
												</Typography>
											</InputAdornment>
										),
									}}
									autoComplete="off"
									fullWidth
									error={
										this.state.search_phone_error === true
											? true
											: this.state.invalid_search_phone_error === true
												? true
												: false
									}
									helperText={
										this.state.search_phone_error === true
											? "Please enter phone number"
											: this.state.invalid_search_phone_error === true
												? "Invalid phone number"
												: false
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<div
									style={{
										padding: 8,
										justifyContent: "center",
										alignItems: "center",
										display: "flex",
									}}
								>
									<Button
										size="small"
										onClick={() => this.searchUser()}
										variant="contained"
										color="primary"
										style={styles.addButton}
									>
										Find User
									</Button>
								</div>
								<Typography
									style={{
										fontSize: "10px",
										color: theme.palette.secondary.light,
										textAlign: "center",
									}}
								>
									(If user already exist)
								</Typography>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									id="first name"
									label="First Name"
									name="first name"
									style={styles.margin}
									value={
										this.state.primary_contact_first_name
											.charAt(0)
											.toUpperCase() +
										this.state.primary_contact_first_name.slice(1)
									}
									autoComplete="off"
									onChange={(event) =>
										this.setState({
											primary_contact_first_name: event.target.value,
											primary_contact_first_name_error: false,
										})
									}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle },
									}}
									fullWidth
									error={
										this.state.primary_contact_first_name_error === true
											? true
											: false
									}
									helperText={
										this.state.primary_contact_first_name_error === true
											? "Please enter the first name"
											: false
									}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									id="last name"
									label="Last Name"
									name="last name"
									autoComplete="off"
									style={styles.margin}
									value={
										this.state.primary_contact_last_name
											.charAt(0)
											.toUpperCase() +
										this.state.primary_contact_last_name.slice(1)
									}
									onChange={(event) =>
										this.setState({
											primary_contact_last_name: event.target.value,
											primary_contact_last_name_error: false,
										})
									}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle },
									}}
									fullWidth
									error={
										this.state.primary_contact_last_name_error === true
											? true
											: false
									}
									helperText={
										this.state.primary_contact_last_name_error === true
											? "Please enter last name"
											: false
									}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									id="email"
									label="E-mail"
									name="email"
									autoComplete="off"
									style={styles.margin}
									value={this.state.primary_contact_email}
									onChange={(event) =>
										this.setState({
											primary_contact_email: event.target.value,
											primary_contact_email_error: false,
											invalid_primary_contact_email_error: false,
										})
									}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle },
									}}
									fullWidth
									error={
										this.state.primary_contact_email_error === true
											? true
											: this.state.invalid_primary_contact_email_error === true
												? true
												: false
									}
									helperText={
										this.state.primary_contact_email_error === true
											? "Please enter Email"
											: this.state.invalid_primary_contact_email_error === true
												? "Please enter valid email"
												: false
									}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									id="organization-name"
									label="Organization Name"
									name="organization-name"
									style={styles.margin}
									value={
										this.state.organization_name.charAt(0).toUpperCase() +
										this.state.organization_name.slice(1)
									}
									autoComplete="off"
									onChange={(event) =>
										this.setState({ organization_name: event.target.value })
									}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle },
									}}
									fullWidth
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button
							size="small"
							onClick={() => this.handleSubmit(this.state.mode)}
							variant="contained"
							color="primary"
							style={styles.addButton}
						>
							Submit
						</Button>
						<Button
							size="small"
							onClick={() => this.handleClose()}
							variant="contained"
							color="secondary"
							style={styles.cancelButton}
						>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={this.state.isLoading}
					classes={{
						paper: styles.dialogPaper,
					}}
				>
					<div style={{ boxShadow: 0, overflow: "none" }}>
						<CircularProgress color="primary" size={40} />
					</div>
				</Dialog>
			</div>
		);
	}
};

/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
	return {
		donors: state.donorReducer.donors,
	};
};

AddDonor.propTypes = {

};

export default withRouter(connect(mapStateToProps)(AddDonor));
