import { FETCH_ROLETYPE } from "../actions/actionTypes"

const initialstate = {
    roletype: []
}

export default function roletypeReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_ROLETYPE:
            return {
                ...state,
                roletype: action.data
            }
        default:
            return state;
    }
}