import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControl, FormControlLabel, Radio, RadioGroup, FormHelperText } from '@mui/material';
import { fetchAppVersions, addAppVersion, updateAppVersion } from "../../actions/appVersionAction";
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { headerFont } from '../../helpers/Constants';

/** Created common styles for UI  */
const styles = {
	textStyle: {
		fontFamily: "Futura-Heavy",
		color: "#37474f",
		fontSize: 20,
	},
	creditTextStyle: {
		fontFamily: "Futura-Heavy",
		color: "#37474f",
		fontSize: 15,
		paddingTop: 10
	},
	addButton: {
		backgroundColor: theme.palette.primary.main,
		color: "white",
		fontFamily: "unicode.futurab",
		borderRadius: "10",
		textTransform: 'none',
	},
	cancelButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		fontFamily: "unicode.futurab",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(143, 163, 173, 0.8)',
		}
	},
	editButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		fontFamily: "unicode.futurab",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(143, 163, 173, 0.8)',
		}
	},
	fontCellHeader: {
		fontFamily: "Futura-Book",
		color: "#fff",
		fontWeight: 900,
		padding: '20px 20px 14px 0px',

	},
	fontTableCellBody: {
		fontFamily: "Futura-Medium-bt",
		color: "#37474f",
		padding: '14px 20px 14px 0px',
	},
	inputStyle: {
		fontFamily: "Futura-Book",
		fontSize: 14
	},
	labelStyle: {
		fontFamily: "Futura-Book",
		fontSize: 14
	},
};

/**
 * @class
 *
 * Class representing App Version component
 *
 */
class AppVersion extends Component {
	constructor() {
		super();
		this.state = {
			appVersions: [],
			dialogOpen: false,
			id: null,
			versionNumber: "",
			versionNumber_error: false,
			buildNumber: "",
			buildNumber_error: false,
			status: "ACTIVE",
			status_error: false,
			platform: "Android",
			platform_error: false,
			isCritical: true,
			isCritical_error: false,
			mode: ""
		}
	};

	/** This is a component life cycle method and used to assigning props values to state variable  */
	static getDerivedStateFromProps(props, state) {
		if (props.appVersions !== state.appVersions) {
			return {
				appVersions: props.appVersions
			}
		}
		return null;
	};

	/** This is a component life cycle method and used to fetch datas  */
	componentDidMount() {
		/** fetching App versions details. */
		this.props.dispatch(fetchAppVersions())
	};

	/**
	 *@function
	 * This function representing add or edit app version dialog screen will open.
	 * @param {Object} item The item json object
	 * @param {String} mode The mode string
	 */
	handleClick = (item, mode) => {
		if (mode === "ADD") {
			this.setState({ mode: mode, dialogOpen: true })
		}
		else {
			this.setState({
				id: item.id,
				dialogOpen: true,
				mode: mode,
				versionNumber: item.versionNumber,
				buildNumber: item.buildNumber,
				platform: item.platform,
				isCritical: item.isCritical,
				status: item.status,
			})
		}
	};

	/**
	 * @function
	 * This function representing close add or edit Donor dialog screen.
	 */
	handleClose = () => {
		this.setState({
			dialogOpen: false,
			id: null,
			versionNumber: "",
			versionNumber_error: false,
			buildNumber: "",
			buildNumber_error: false,
			status: "ACTIVE",
			status_error: false,
			platform: "Android",
			platform_error: false,
			isCritical: true,
			isCritical_error: false,
			mode: ""
		})
	};

	/**
	 *@function
	 * This function representing add or edit app version.
	 * @param {String} mode The mode string
	 */
	handleSubmit = (mode) => {
		let { id, versionNumber, buildNumber, platform, isCritical, status } = this.state
		let isError = false;
		if (versionNumber === "" || versionNumber === null) {
			this.setState({ versionNumber_error: true })
			isError = true;
		}

		if (buildNumber === "" || buildNumber === null) {
			this.setState({ buildNumber_error: true })
			isError = true;
		}
		if (status === "" || status === null) {
			this.setState({ status_error: true })
			isError = true;
		}

		if (isError === false) {
			let data = {};
			data.versionNumber = versionNumber;
			data.buildNumber = buildNumber;
			data.platform = platform;
			data.isCritical = isCritical;
			data.status = status;
			if (mode === "ADD") {
				this.props.dispatch(addAppVersion(this, data))
			}
			else {
				this.props.dispatch(updateAppVersion(this, data, id))
			}
		}
	};

	/** rendering App Version information. */
	render() {
		return (
			<div style={{ paddingLeft: 10 }}>
				<Grid container spacing={0} style={{ marginTop: 15 }}>
					<Grid item xs={12} style={{ marginTop: 10, display: "flex", marginBottom: 10, marginLeft: 5 }}>
						<Grid item xs={9} sm={9}>
							<Typography style={{ color: theme.palette.secondary.light, fontWeight: headerFont.fontWeight, fontFamily: headerFont.fontFamily, fontSize: headerFont.fontSize, }}>App Versions</Typography>
						</Grid>
						<Grid item xs={3} sm={3} style={{ float: 'left' }}>
							<Button size="small" variant="contained" color="primary" style={styles.addButton} onClick={() => this.handleClick(null, "ADD")} >
								Add
							</Button>
						</Grid>
					</Grid>
					<Grid item xs={8} sm={12} style={{ padding: 10 }}>
						<Table>
							<TableHead>
								<TableRow style={{ backgroundColor: theme.palette.secondary.dark, margin: 10 }}>
									<TableCell align="center" style={styles.fontCellHeader}>OS Type</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Build Number</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Version Number</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Critical Update</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Status</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Edit</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									this.state.appVersions.length > 0 ? this.state.appVersions.map((item, index) => (
										<TableRow key={item.id} style={{ backgroundColor: index % 2 === 0 ? "#fff" : "whitesmoke" }}>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.platform}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.buildNumber}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.versionNumber}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.isCritical === true ? "YES" : "NO"}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.status}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>
												<Button size="small" style={styles.editButton} onClick={() => this.handleClick(item, "EDIT")}>EDIT</Button>
											</TableCell>
										</TableRow>)
									)
										:
										<TableRow>
											<TableCell colSpan={6} style={{ ...styles.fontTableCellBody, textAlign: 'center' }}>There are no app versions</TableCell>
										</TableRow>
								}
							</TableBody>
						</Table>
					</Grid>
				</Grid>
				<Dialog
					maxWidth={"sm"}
					open={this.state.dialogOpen} >
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "1%", fontFamily: "Futura-Heavy", color: '#fff' }}>{this.state.mode === "ADD" ? "Add App Version" : "Update App Version"}</Typography>
					</DialogTitle>
					<DialogContent style={{ overflowY: "hidden" }}>

						<Grid container justify="space-between" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>

							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									id="build_number"
									label="Build Number"
									name="build_number"
									style={styles.margin}
									value={this.state.buildNumber}
									onChange={(event) => this.setState({ buildNumber: event.target.value.trimStart(), buildNumber_error: false })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle }
									}}
									fullWidth
									error={this.state.buildNumber_error === true ? true : false}
									helperText={this.state.buildNumber_error === true ? "Please enter build number" : false}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									id="version_number"
									label="Version Number"
									name="version_number"
									style={styles.margin}
									value={this.state.versionNumber}
									onChange={(event) => this.setState({ versionNumber: event.target.value.trimStart(), versionNumber_error: false })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle }
									}}
									fullWidth
									error={this.state.versionNumber_error === true ? true : false}
									helperText={this.state.versionNumber_error === true ? "Please enter version number" : false}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<div style={{ padding: "2% 0 2% 0" }}>
									<Typography style={{ fontFamily: "Futura-Book", fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>OS Type</Typography>
									<FormControl component="fieldset">
										<RadioGroup aria-label="position" name="position" value={this.state.platform}
											onChange={(event) => this.setState({ platform: event.target.value, platform_error: false })} row>
											<FormControlLabel
												value="Android"
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>Android</span>}
												labelPlacement="end"
											/>
											<FormControlLabel
												value="iOS"
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>iOS</span>}
												labelPlacement="end"
											/>
										</RadioGroup>
										{this.state.platform_error === true && <FormHelperText style={{ color: "red" }}>{"Please select OS Type"}</FormHelperText>}
									</FormControl>
								</div>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<div style={{ padding: "2% 0 2% 0" }}>
									<Typography style={{ fontFamily: "Futura-Book", fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>Critical Update</Typography>
									<FormControl component="fieldset">
										<RadioGroup aria-label="position" name="position" value={this.state.isCritical === true ? "YES" : "NO"}
											onChange={(event) => this.setState({ isCritical: event.target.value === "YES" ? true : false, isCritical_error: false })} row>
											<FormControlLabel
												value="YES"
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>Yes</span>}
												labelPlacement="end"
											/>
											<FormControlLabel
												value="NO"
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>No</span>}
												labelPlacement="end"
											/>
										</RadioGroup>
										{this.state.isCritical_error === true && <FormHelperText style={{ color: "red" }}>{"Please select Critical update"}</FormHelperText>}
									</FormControl>
								</div>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<div style={{ padding: "2% 0 2% 0" }}>
									<Typography style={{ fontFamily: "Futura-Book", fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>Status</Typography>
									<FormControl component="fieldset">
										<RadioGroup aria-label="position" name="position" value={this.state.status}
											onChange={(event) => this.setState({ status: event.target.value, status_error: false })} row>
											<FormControlLabel
												value="ACTIVE"
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>Active</span>}
												labelPlacement="end"
											/>
											<FormControlLabel
												value="INACTIVE"
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>In Active</span>}
												labelPlacement="end"
											/>
										</RadioGroup>
										{this.state.status_error === true && <FormHelperText style={{ color: "red" }}>{"Please select status"}</FormHelperText>}
									</FormControl>
								</div>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={() => this.handleSubmit(this.state.mode)} variant="contained" color="primary" style={styles.addButton}>Submit</Button>
						<Button size="small" onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
					</DialogActions>

				</Dialog>
			</div>
		)
	}
};

/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
	return {
		appVersions: state.appVersionReducer.appVersions,
	}
};

AppVersion.propTypes = {
	dispatch: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps)(AppVersion));