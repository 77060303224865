import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
    FormControl, FormControlLabel, Radio, RadioGroup, FormHelperText, MenuItem, Avatar, Toolbar, Chip, TablePagination, CircularProgress
} from "@mui/material"
import { fetchVaccine, addVaccine, updateVaccine } from '../../actions/vaccineAction';
import { fetchVaccineShot, addVaccineShot, updataVaccineShot, deleteVaccineShot } from '../../actions/vaccineshotAction';
import EDIT from "../../assets/images/edit.svg";
import { fetchVaccineManufacturer } from '../../actions/vaccinemanufacturerAction';
import TablePaginationActions from "../Dashboard/TablePaginationActions";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { withRouter } from "../../withRouter";
import theme from '../../theme';
import { THEMECOLOR, headerFont } from '../../helpers/Constants';

/**Created common styles for UI.*/
const styles = {
    textStyle: {
        fontFamily: "Futura-Heavy",
        color: "#20478E",
        fontSize: 20,
    },
    addButton: {
        // backgroundColor: "#20478e",
        color: "white",
        fontFamily: "unicode.futurab",
        marginRight: "20px",
        textTransform: 'none',
    },
    addShotButton: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        fontFamily: "unicode.futurab",
        borderRadius: "10",
        marginRight: "20",

    },
    cancelButton: {
        backgroundColor: "#8fa3ad",
        color: "white",
        fontFamily: "unicode.futurab",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(143, 163, 173, 0.8)',
        }
    },
    deleteButton: {
        backgroundColor: "#8fa3ad",
        color: "white",
        fontFamily: "unicode.futurab",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(143, 163, 173, 0.8)',
        }
    },
    editButton: {
        backgroundColor: "#8fa3ad",
        color: "white",
        fontFamily: "unicode.futurab",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(143, 163, 173, 0.8)',
        }
    },
    fontCellHeader: {
        fontFamily: "Futura-Book",
        color: ' #fff',
        fontWeight: 900,
        padding: '14px 20px 14px 0px',
    },
    fontTableCellBody: {
        fontFamily: "Futura-Medium-bt",
        color: "#37474f",
        padding: '14px 20px 14px 0px',
    },
    inputStyle: {
        fontFamily: "Futura-Book",
        fontSize: 14
    },
    labelStyle: {
        fontFamily: "Futura-Book",
        fontSize: 14
    },
    inputStyleForSearchText: {
        fontSize: "15px !important",
        padding: "5px !important",
        width: 250,
    },
    inputRoot: {
        borderRadius: "0px !important"
    },
    inputLabel: {
        color: "#4B86E3"
    },
    dialogPaper: {
        backgroundColor: "rgba(0,0,0,0)",
        boxShadow: "none",
        overflow: "hidden"
    }
};

/**
 * @class
 *
 * Class representing Vaccine component
 *
 */
class Vaccine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: "",
            dialogOpen: false,
            OpenShotDialog: false,
            isClear: false,
            shotId: null,
            manufacturerId: 0,
            selectedVaccineId: null,
            vaccine: [],
            vaccineManufacturer: [],
            name: "",
            name_error: false,
            vaccineCode: "",
            vaccineCode_error: false,
            description: "",
            description_error: false,
            note: "",
            note_error: false,
            status: "ACTIVE",
            status_error: false,
            day: "",
            notes: "",
            notes_error: false,
            notifyFirstDay: "",
            notifyFirstMessage: "",
            notifySecondDay: "",
            notifySecondMessage: "",
            notifyThirdDay: "",
            notifyThirdMessage: "",
            day_error: false,
            notifyFirstDay_error: false,
            notifyFirstMessage_error: false,
            notifySecondDay_error: false,
            notifySecondMessage_error: false,
            notifyThirdDay_error: false,
            notifyThirdMessage_error: false,
            manufacturerId_error: false,
            manufacturerName: "",
            search_text: "",
            rowsPerPage: 10,
            page: 0,
            isLoading: false,
            isConfirmationDialog: false,
            deleteShotID: null,
            sortBy: "NAME_ASC",
            booster: "NO",
            displayName: ''
        }
    }

    /**This is a component life cycle method and used to assigning props values to state variable.*/
    static getDerivedStateFromProps(props, state) {
        if (props.vaccine !== state.vaccine || props.vaccineManufacturer !== state.vaccineManufacturer) {
            return {
                vaccine: props.vaccine,
                vaccineManufacturer: props.vaccineManufacturer
            }
        }
        return null;
    };

    /** This is a component life cycle method and used to fetch datas*/
    componentDidMount() {
        /** fetching  vaccines details */
        this.props.dispatch(fetchVaccine())
        /** fetching  vaccine shots details. */
        this.props.dispatch(fetchVaccineShot())
        /** fetching  vaccine manufactureres deatails. */
        this.props.dispatch(fetchVaccineManufacturer())
    };

    /**
      * @function
     *
     * function representing table pagination.
     * @param {Object} _event
     * @param {Object} newPage
     */
    handleChangePage = (_event, newPage) => {
        this.setState({
            page: newPage
        });
    };

    /**
     * @function
     *
     * function representing table pagination rows.
     * @param {Object} event
     */
    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0, rowsPerPage: parseInt(event.target.value, 10)
        });
    };

    /**
     * @function
     * This function representing add or edit vaccine dialog screen will open.
     * @param {Object} mode The mode json object
     * @param {String} mode The mode string
     */
    vaccineUpdate = (item, mode) => {

        if (mode === "ADD") {
            this.setState({ mode: mode, dialogOpen: true })
        }
        else {
            this.setState({
                selectedVaccineId: item.id,
                dialogOpen: true,
                manufacturerId: item.vaccineManufacturerId,
                name: item.name,
                name_error: false,
                vaccineCode: item.vaccineCode,
                vaccineCode_error: false,
                description: item.description !== null ? item.description : "",
                description_error: false,
                note: item.notes !== null ? item.notes : "",
                note_error: false,
                status: item.status,
                status_error: false,
                displayName: item.displayName ? item.displayName : " "
            })
        }
    };

    /**
     * @function
     * This function representing add or edit VaccineShot dialog screen will open.
     * @param {Object} item The item json object
     * @param {String} mode The mode string
     */
    addVaccineShot = (item, mode) => {
        if (mode === "ADD") {
            this.setState({ booster: "NO", mode: mode, OpenShotDialog: true, selectedVaccineId: item.id })
        }
        else {
            let vaccine = this.state.vaccine.find(x => x.id === item.vaccineId)
            let vaccineShots = vaccine !== undefined ? vaccine.vaccineShots.sort((a, b) => a.id - b.id) : null;
            let deleteShotID = vaccineShots !== null ? vaccineShots.length > 2 ? vaccineShots[2].id : null : null
            this.setState({
                mode: mode,
                OpenShotDialog: true,
                shotId: item.id,
                day: item.day,
                notes: item.notes,
                notifyFirstDay: item.notifyFirstDay,
                notifyFirstMessage: item.notifyFirstMessage,
                notifySecondDay: item.notifySecondDay,
                notifySecondMessage: item.notifySecondMessage,
                notifyThirdDay: item.notifyThirdDay,
                notifyThirdMessage: item.notifyThirdMessage,
                description: item.description,
                status: item.status,
                deleteShotID: deleteShotID,
                booster: item.isBooster ? "YES" : "NO"
            })
        }
    };

    /**
     * @function
    * This function representing close add or edit vaccine shot dialog screen.
    */
    closeVaccineShot = () => {
        this.setState({
            OpenShotDialog: false,
            selectedVaccineId: null,
            day: "",
            notes: "",
            notifyFirstDay: "",
            notifyFirstMessage: "",
            notifySecondDay: "",
            notifySecondMessage: "",
            notifyThirdDay: "",
            notifyThirdMessage: "",
            description: "",
            status: "ACTIVE",
            day_error: false,
            note_error: false,
            notifyFirstDay_error: false,
            notifyFirstMessage_error: false,
            notifySecondDay_error: false,
            notifySecondMessage_error: false,
            notifyThirdDay_error: false,
            notifyThirdMessage_error: false,
            description_error: false,
            status_error: false,
            mode: ""
        })
    };

    /**
     * @function
     * This function representing add or edit vaccine shot
     * @param {String} mode The mode string
     */
    submitAddVaccineShot = (mode) => {

        let { day, notes, description, status, selectedVaccineId, shotId, booster } = this.state
        let isError = false;

        if (day === "" || day === null) {
            this.setState({ day_error: true, })
            isError = true;
        }
        if (status === "" || status === null) {
            this.setState({ status_error: true, })
            isError = true;
        }
        if (isError === false) {
            let shotVaccineObj = {};
            shotVaccineObj.day = day;
            shotVaccineObj.notes = notes;
            shotVaccineObj.notifyFirstDay = null;
            shotVaccineObj.notifyFirstMessage = null;
            shotVaccineObj.notifySecondDay = null;
            shotVaccineObj.notifySecondMessage = null;
            shotVaccineObj.notifyThirdDay = null;
            shotVaccineObj.notifyThirdMessage = null;
            shotVaccineObj.description = description;
            shotVaccineObj.status = status;
            shotVaccineObj.vaccineId = selectedVaccineId;
            shotVaccineObj.isBooster = booster === "YES" ? true : false;
            if (mode === "ADD") {
                this.setState({ isLoading: true })
                this.props.dispatch(addVaccineShot(this, shotVaccineObj))
            }
            else {
                this.setState({ isLoading: true })
                this.props.dispatch(updataVaccineShot(this, shotVaccineObj, shotId))
            }
        }
    };

    /**
     * @function
    * This function representing close add or edit vaccine dialog screen.
    */
    handleClose = () => {
        this.setState({
            dialogOpen: false,
            selectedVaccineId: null,
            manufacturerId: null,
            name: "",
            name_error: false,
            vaccineCode: "",
            vaccineCode_error: false,
            description: "",
            description_error: false,
            note: "",
            note_error: false,
            status: "ACTIVE",
            status_error: false,
            manufacturerId_error: false,
            mode: "",
            displayName: ""
        })
    };

    /**
     * @function
     * This function representing add and edit vaccine.
     * @param {String} mode The mode string
     */
    vaccineSubmit = (mode) => {

        let { manufacturerId, name, vaccineCode, description, note, status, selectedVaccineId, displayName } = this.state;
        let isError = false;

        if (manufacturerId === "" || manufacturerId === null || manufacturerId === 0) {
            this.setState({ manufacturerId_error: true, })
            isError = true;
        }
        if (name === "" || name === null) {
            this.setState({ name_error: true, })
            isError = true;
        }

        /* Later on this commented code will be added */

        // if (vaccineCode === "" || vaccineCode === null) {
        //     this.setState({ vaccineCode_error: true, })
        //     isError = true;
        // }
        if (status === "" || status === null) {
            this.setState({ status_error: true, })
            isError = true;
        }
        if (isError === false) {
            let vaccineObj = {};
            vaccineObj.vaccineManufacturerId = manufacturerId;
            vaccineObj.name = name;
            vaccineObj.vaccineCode = vaccineCode;
            vaccineObj.description = description;
            vaccineObj.note = note;
            vaccineObj.status = status;
            vaccineObj.displayName = displayName;

            if (mode === "ADD") {
                this.setState({ isLoading: true })
                this.props.dispatch(addVaccine(this, vaccineObj))
            }
            else {
                this.setState({ isLoading: true })
                this.props.dispatch(updateVaccine(this, vaccineObj, selectedVaccineId))
            }
        }
    };

    /**
     * @function
     *
     * function representing delete vaccine shot
     */
    deleteVaccineShot = () => {
        this.setState({ OpenShotDialog: false })
        this.props.dispatch(deleteVaccineShot(this, this.state.shotId))
    };

    /**
     * @function
     *
     * function representing selected manufacturer
     *@param {Object} event
     */
    handleManufacturerChange = (event) => {
        this.setState({ manufacturerId: parseInt(event.target.value), isClear: true, manufacturerId_error: false });
    };

    /** rendering vaccines information. */
    render() {
        const { page, rowsPerPage } = this.state;
        let vaccineManufacturer = this.props.vaccineManufacturer
        let vaccines = this.state.sortBy === "NAME_ASC" ? this.state.vaccine.sort((a, b) => a.name.localeCompare(b.name)) : this.state.vaccine.sort((a, b) => b.name.localeCompare(a.name))
        let result = this.state.search_text !== '' ? vaccines.filter(x => (x.name).toString().toLowerCase().includes((this.state.search_text).toString().toLowerCase()) || (x.vaccineCode).toString() === (this.state.search_text).toString()) : vaccines
        return (
            <div style={{ paddingLeft: 2, paddingRight: 10 }}>
                <Grid container spacing={0} style={{ marginTop: 5, }}>
                    <Grid item xs={12} sm={12}>
                        <Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
                            <div>
                                <Typography style={{ color: theme.palette.secondary.light, fontWeight: headerFont.fontWeight, fontFamily: headerFont.fontFamily, fontSize: headerFont.fontSize, }} >Vaccines</Typography>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[20]}
                                component="div"
                                count={result.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                slotProps={{
                                    select: {
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                labelRowsPerPage={null}
                                labelDisplayedRows={
                                    ({ from, to, count }) => null
                                }
                                ActionsComponent={
                                    TablePaginationActions
                                }
                            />
                            <div style={{ display: "flex", padding: 10 }}>
                                <Button size="small" variant="contained" color="primary" style={{ ...styles.addButton, marginRight: 10 }} onClick={() => this.vaccineUpdate(null, "ADD")} >
                                    Add Vaccine
                                </Button>
                            </div>
                            <TextField
                                margin="normal"
                                id="search-text"
                                color={"primary"}
                                variant='standard'
                                label="Search"
                                placeholder="Name or Vaccine Code"
                                name="mobile-number"
                                autoComplete="off"
                                value={this.state.search_text}
                                onChange={event =>
                                    this.setState({ search_text: event.target.value, page: 0 })
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ classes: { input: styles.inputStyleForSearchText, root: styles.inputRoot, inputlabel: styles.inputLabel } }}
                                autoFocus
                            />
                        </Toolbar>
                    </Grid>
                    <Grid item xs={8} sm={12} style={{ paddingTop: 10 }}>
                        <Table>
                            <TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10 }}>
                                <TableRow>
                                    <TableCell align="center" style={{ fontFamily: "Futura-Book", color: ' #fff', fontWeight: 900, padding: '14px 0px 14px 20px', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        Name
                                        <ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_ASC" })} />
                                        <ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_DSC" })} />
                                    </TableCell>
                                    <TableCell align="center" style={styles.fontCellHeader}>Manufacturer Name</TableCell>
                                    {/* <TableCell align="center" style={styles.fontCellHeader}>Vaccine Code</TableCell> */}
                                    <TableCell align="center" style={styles.fontCellHeader}>Display Name</TableCell>
                                    <TableCell align="center" style={styles.fontCellHeader}>Vaccine Shots</TableCell>
                                    <TableCell align="center" style={styles.fontCellHeader}>Status</TableCell>
                                    <TableCell align="center" style={{ ...styles.fontCellHeader, justifyContent: "space-around", paddingLeft: 40 }} >Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    result.length > 0 ? result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) =>
                                        <TableRow key={item.id} style={{ backgroundColor: index % 2 === 0 ? "#fff" : "whitesmoke" }}>
                                            <TableCell align="center" style={styles.fontTableCellBody}>{item.name}</TableCell>
                                            <TableCell align="center" style={styles.fontTableCellBody}>{vaccineManufacturer.length > 0 ? vaccineManufacturer.find(x => (x.id === parseInt(item.vaccineManufacturerId))).name : ""}</TableCell>
                                            {/* <TableCell align="center" style={styles.fontTableCellBody}>{item.vaccineCode}</TableCell> */}
                                            <TableCell align="center" style={styles.fontTableCellBody}>{item.displayName}</TableCell>
                                            <TableCell align="center" style={styles.fontTableCellBody}>
                                                {item.vaccineShots.length > 0 &&
                                                    item.vaccineShots.sort((a, b) => a.id - b.id).map((x, index) =>
                                                        <div style={{ padding: 5 }} key={x.id}>
                                                            <Chip
                                                                size="small"
                                                                label={`Day ${x.day} ${x.isBooster ? "(Booster)" : ""}`}
                                                                clickable
                                                                color={x.isBooster ? "secondary" : "primary"}
                                                                avatar={<Avatar><img src={EDIT} height="20" alt="icon" style={{ backgroundColor: "white" }} /></Avatar>}
                                                                onClick={() => this.addVaccineShot(x, "EDITSHOT")}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell align="center" style={styles.fontTableCellBody}>{item.status}</TableCell>
                                            <TableCell align="center" style={styles.fontTableCellBody}>
                                                {/* <Toolbar> */}
                                                <Button size="small" style={{ ...styles.addButton, marginRight: 10 }} variant="contained" color="primary" onClick={() => this.addVaccineShot(item, "ADD")}>Add shot</Button>
                                                <Button size="small" style={styles.editButton} onClick={() => this.vaccineUpdate(item, "EDIT")}>EDIT</Button>
                                                {/* </Toolbar> */}
                                            </TableCell>
                                        </TableRow>)
                                        :
                                        <TableRow>
                                            <TableCell colSpan={6} style={{ ...styles.fontTableCellBody, textAlign: "center" }}>There are no vaccines</TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Dialog
                    maxWidth={"sm"}
                    open={this.state.dialogOpen}>
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "1%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{this.state.mode === "ADD" ? "Add Vaccine" : "Update Vaccine"}</Typography>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container justify="center" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <TextField
                                    id="manufacturer"
                                    select
                                    label="Manufacturer"
                                    name="Manufacturer"
                                    value={this.state.manufacturerId || ''}
                                    onChange={this.handleManufacturerChange}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        classes: { input: styles.inputStyle }
                                    }}
                                    required
                                    fullWidth
                                    error={this.state.manufacturerId_error === true}
                                    helperText={this.state.manufacturerId_error === true ? "Please select valid Manufacturer" : false}
                                >
                                    {vaccineManufacturer.map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }} >
                                <TextField
                                    id="name"
                                    label="Name"
                                    name="name"
                                    autoComplete="off"
                                    style={styles.margin}
                                    value={this.state.name.charAt(0).toUpperCase() + this.state.name.slice(1)}
                                    onChange={(event) => this.setState({ name: event.target.value.trimStart(), name_error: false })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: { input: styles.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.name_error === true}
                                    helperText={this.state.name_error === true ? "Please enter name" : false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }} >
                                <TextField
                                    id="vaccineCode"
                                    label="Vaccine Code"
                                    name="vaccineCode"
                                    autoComplete="off"
                                    style={styles.margin}
                                    value={this.state.vaccineCode}
                                    onChange={(event) => this.setState({ vaccineCode: event.target.value.trimStart(), vaccineCode_error: false })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: { input: styles.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.vaccineCode_error === true}
                                    helperText={this.state.vaccineCode_error === true ? "Please enter vaccineCode" : false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }} >
                                <TextField
                                    id="description"
                                    label="Description"
                                    name="description"
                                    autoComplete="off"
                                    style={styles.margin}
                                    value={this.state.description}
                                    onChange={(event) => this.setState({ description: event.target.value.trimStart() })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: { input: styles.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.description_error === true}
                                    helperText={this.state.description_error === true ? "Please enter description" : false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }} >
                                <TextField
                                    id="displayName"
                                    label="Display Name"
                                    name="displayName"
                                    autoComplete="off"
                                    style={styles.margin}
                                    value={this.state.displayName.charAt(0).toUpperCase() + this.state.displayName.slice(1)}
                                    onChange={(event) => this.setState({ displayName: event.target.value.trimStart()})}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: { input: styles.inputStyle }
                                    }}
                                    fullWidth
                                    // error={this.state.name_error === true}
                                    // helperText={this.state.name_error === true ? "Please enter name" : false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }} >
                                <TextField
                                    id="note"
                                    label="Notes"
                                    name="note"
                                    autoComplete="off"
                                    style={styles.margin}
                                    value={this.state.note}
                                    onChange={(event) => this.setState({ note: event.target.value.trimStart() })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: { input: styles.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.note_error === true}
                                    helperText={this.state.note_error === true ? "Please enter note" : false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <div style={{ padding: "2% 0 2% 0" }}>
                                    <Typography style={{ fontFamily: "Futura-Book", fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>Status</Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="position" name="position" value={this.state.status}
                                            onChange={(event) => this.setState({ status: event.target.value, status_error: false })} row>
                                            <FormControlLabel
                                                value="ACTIVE"
                                                control={<Radio style={{ color: theme.palette.primary.main }} />}
                                                label={<span style={styles.labelStyle}>Active</span>}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="INACTIVE"
                                                control={<Radio style={{ color: theme.palette.primary.main }} />}
                                                label={<span style={styles.labelStyle}>In Active</span>}
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                        {this.state.status_error === true && <FormHelperText style={{ color: "red" }}>{"Please select status"}</FormHelperText>}
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={() => this.vaccineSubmit(this.state.mode)} variant="contained" color="primary" style={styles.addButton}>Submit</Button>
                        <Button size="small" onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
                    </DialogActions>
                </Dialog>

                <Dialog maxWidth={"sm"}
                    open={this.state.OpenShotDialog}>
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "1%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{this.state.mode === "ADD" ? "Add Vaccine Shot" : "Update Vaccine Shot"}</Typography>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "hidden" }}>
                        <Grid container justify="center" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <TextField
                                    id="day"
                                    label="Day"
                                    name="day"
                                    autoComplete="off"
                                    style={styles.margin}
                                    value={this.state.day}
                                    onChange={(event) => event.target.value.match(/^\d*$/g) && this.setState({ day: event.target.value, day_error: false })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: { input: styles.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.day_error === true}
                                    helperText={this.state.day_error === true ? "Please enter day" : false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <div style={{ padding: "2% 0 2% 0" }}>
                                    <Typography style={{ fontFamily: "Futura-Book", fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>Booster Dose</Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="position" name="position" value={this.state.booster}
                                            onChange={(event) => this.setState({ booster: event.target.value, booster_error: false })} row>
                                            <FormControlLabel
                                                value="YES"
                                                control={<Radio style={{ color: theme.palette.primary.main }} />}
                                                label={<span style={styles.labelStyle}>Yes</span>}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="NO"
                                                control={<Radio style={{ color: theme.palette.primary.main }} />}
                                                label={<span style={styles.labelStyle}>No</span>}
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                        {this.state.booster_error === true && <FormHelperText style={{ color: "red" }}>{"Please select Booster"}</FormHelperText>}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <TextField
                                    id="description"
                                    label="Description"
                                    name="description"
                                    autoComplete="off"
                                    style={styles.margin}
                                    value={this.state.description}
                                    onChange={(event) => this.setState({ description: event.target.value })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: { input: styles.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.description_error === true}
                                    helperText={this.state.description_error === true ? "Please enter description" : false}
                                />
                            </Grid>

                            {/* <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <TextField
                                    id="notifyFirstDay"
                                    label="Notify First Day"
                                    name="notifyFirstDay"
                                    autoComplete="off"
                                    className={classes.margin}
                                    value={this.state.notifyFirstDay}
                                    onChange={(event) => event.target.value.match(/^\d*$/g) && this.setState({ notifyFirstDay: event.target.value })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: { input: classes.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.notifyFirstDay_error === true ? true : false}
                                    helperText={this.state.notifyFirstDay_error === true ? "Please enter notifyFirstDay" : false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <TextField
                                    id="notifyFirstMessage"
                                    label="Notify First Message"
                                    name="notifyFirstMessage"
                                    autoComplete="off"
                                    className={classes.margin}
                                    value={this.state.notifyFirstMessage}
                                    onChange={(event) => this.setState({ notifyFirstMessage: event.target.value })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: { input: classes.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.notifyFirstMessage_error === true ? true : false}
                                    helperText={this.state.notifyFirstMessage_error === true ? "Please enter NotifyFirstMessage" : false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <TextField
                                    id="notifySecondDay"
                                    label="Notify Second Day"
                                    name="notifySecondDay"
                                    autoComplete="off"
                                    className={classes.margin}
                                    value={this.state.notifySecondDay}
                                    onChange={(event) => event.target.value.match(/^\d*$/g) && this.setState({ notifySecondDay: event.target.value })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: { input: classes.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.notifySecondDay_error === true ? true : false}
                                    helperText={this.state.notifySecondDay_error === true ? "Please enter NotifySecondDay" : false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <TextField
                                    id="notifySecondMessage"
                                    label="Notify Second Message"
                                    name="notifySecondMessage"
                                    autoComplete="off"
                                    className={classes.margin}
                                    value={this.state.notifySecondMessage}
                                    onChange={(event) => this.setState({ notifySecondMessage: event.target.value })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: { input: classes.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.notifySecondMessage_error === true ? true : false}
                                    helperText={this.state.notifySecondMessage_error === true ? "Please enter NotifySecondMessage" : false}
                                />
                            </Grid>

                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <TextField
                                    id="notifyThirdDay"
                                    label="Notify Third Day"
                                    name="notifyThirdDay"
                                    autoComplete="off"
                                    className={classes.margin}
                                    value={this.state.notifyThirdDay}
                                    onChange={(event) => event.target.value.match(/^\d*$/g) && this.setState({ notifyThirdDay: event.target.value })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: { input: classes.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.notifyThirdDay_error === true ? true : false}
                                    helperText={this.state.notifyThirdDay_error === true ? "Please enter notifyThirdDay" : false}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <TextField
                                    id="notifyThirdMessage"
                                    label="Notify Third Message"
                                    name="notifyThirdMessage"
                                    autoComplete="off"
                                    className={classes.margin}
                                    value={this.state.notifyThirdMessage}
                                    onChange={(event) => this.setState({ notifyThirdMessage: event.target.value })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: { input: classes.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.notifyThirdMessage_error === true ? true : false}
                                    helperText={this.state.notifyThirdMessage_error === true ? "Please enter notifyThirdMessage" : false}
                                />
                            </Grid> */}
                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <TextField
                                    id="notes"
                                    label="Notes"
                                    name="notes"
                                    autoComplete="off"
                                    style={styles.margin}
                                    value={this.state.notes}
                                    onChange={(event) => this.setState({ notes: event.target.value })}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        classes: { input: styles.inputStyle }
                                    }}
                                    fullWidth
                                    error={this.state.notes_error === true}
                                    helperText={this.state.notes_error === true ? "Please enter notes" : false}
                                />
                            </Grid>

                            <Grid item xs={6} style={{ paddingRight: 8 }}>
                                <div style={{ padding: "2% 0 2% 0" }}>
                                    <Typography style={{ fontFamily: "Futura-Book", fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>Status</Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="position" name="position" value={this.state.status}
                                            onChange={(event) => this.setState({ status: event.target.value, status_error: false })} row>
                                            <FormControlLabel
                                                value="ACTIVE"
                                                control={<Radio style={{ color: theme.palette.primary.main }} />}
                                                label={<span style={styles.labelStyle}>Active</span>}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="INACTIVE"
                                                control={<Radio style={{ color: theme.palette.primary.main }} />}
                                                label={<span style={styles.labelStyle}>In Active</span>}
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                        {this.state.status_error === true && <FormHelperText style={{ color: "red" }}>{"Please select status"}</FormHelperText>}
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={6} style={{ paddingRight: 8 }} />
                        </Grid>
                    </DialogContent>
                    {/* <DialogActions> */}
                    <div style={{ display: 'flex', justifyContent: (this.state.deleteShotID !== null && this.state.deleteShotID <= this.state.shotId) ? "space-between" : "flex-end", padding: 10 }}>
                        {/* {(this.state.mode !== "ADD" && this.state.deleteShotID !== null && parseInt(this.state.deleteShotID) <= parseInt(this.state.shotId)) && <Button size="small" onClick={() => this.setState({ isConfirmationDialog: true, OpenShotDialog: false })} variant="contained" className={classes.deleteButton}>Delete</Button>} */}
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button size="small" onClick={() => this.submitAddVaccineShot(this.state.mode)} variant="contained" color="primary" style={styles.addButton}>Submit</Button>
                            <Button size="small" onClick={() => this.closeVaccineShot()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
                        </div>
                    </div>
                    {/* </DialogActions> */}
                </Dialog>
                <Dialog
                    maxWidth={"xs"}
                    open={this.state.isConfirmationDialog} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"Confirmation"}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div style={styles.qrContainer}>
                            {`Are you sure want to  delete this vaccine shot?`}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={() => this.deleteVaccineShot()} variant="contained" color="primary" style={styles.addButton}>Yes</Button>
                        <Button size="small" onClick={() => this.setState({ isConfirmationDialog: false })} variant="contained" color="secondary" style={styles.cancelButton}>No</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.isLoading}
                    classes={{
                        paper: styles.dialogPaper,
                    }}
                >
                    <div style={{ boxShadow: 0, overflow: "none" }}>
                        <CircularProgress color="primary" size={40} />
                    </div>
                </Dialog>
            </div>
        )
    }
};

/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
    return {
        vaccine: state.vaccineReducer.vaccine,
        vaccineshot: state.vaccineShotReducer.vaccineshot,
        vaccineManufacturer: state.vaccineManufacturerReducer.vaccinemanufacturer,
    }
}

Vaccine.propTypes = {
    dispatch: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps)(Vaccine));
