import React, { Component } from "react";
import { Route, Routes, Navigate } from 'react-router-dom';
import {Main as MainLayout, Minimal as MinimalLayout, Mobile as MobileLayout,MobileWithoutBottom} from '../layout'
import Login from "../screens/LoginScreen"
import ChangePassword from '../screens/ChangePassword';
import ForgotPassword from '../screens/RegisterScreen/ForgotPassword';
import RegisterIdentityVerified from '../screens/RegisterScreen/RegisterIdentityVerified';
import PrivacyPolicy from '../screens/RegisterScreen/PrivacyPolicy'
import ResetPassword from '../screens/RegisterScreen/ResetPassword';
import OTPVerification from '../screens/RegisterScreen/OTPScreen';
import Password from '../screens/RegisterScreen/PasswordScreen';
import TermsOfServices from '../screens/RegisterScreen/TermsOfServices'
import SignUp from '../screens/RegisterScreen/SigupScreen';
import Home from "../screens/HomeScreen";
import Dashboard from '../screens/Dashboard';
import Vaccine from "../screens/VaccineScreen";
import Testtype from "../screens/TesttypeScreen";
import VaccineManufacturerScreen from "../screens/VaccineManufacturerScreen";
import MyAccount from '../screens/MyProfile/MyAccount';
import MoreInformation from '../screens/MyProfile/MoreInformation';
import ProfessionalRegistration from '../screens/ProfessionalRegistration';
import OpenCamera from '../screens/ProfessionalRegistration/OpenCamera';
import VerifierTestResult from '../screens/VerifierTestResult';
import UserAndAccess from '../screens/UserAndAccess';
import UsersScreen from '../screens/UsersScreen';
import AllTestResults from '../screens/AllTestResults';
import AppVersion from '../screens/AppVersion';
import MessageConfiguration from '../screens/MessageConfiguration';
import UserSubscriptionPlans from '../screens/UserSubscriptionPlans';
import ViewUserSubscriptionPlan from '../screens/UserSubscriptionPlans/ViewUserSubscriptionPlan';
import YourInformation from '../screens/RegisterScreen/YourInformation';
import UserHome from '../screens/UserHome';
import MyBadge from "../screens/MyBadge";
import MyHealth from "../screens/MyHealth";
import IDVerification from '../screens/RegisterScreen/IDVerification';
import IDCamera from '../screens/RegisterScreen/IDCamera';
import IDVerificationDetails from '../screens/RegisterScreen/IDVerificationDetails';
import SelfieVerification from '../screens/RegisterScreen/SelfieVerification';
import SelfieCamera from '../screens/RegisterScreen/SelfieCamera';
import SelfieVerificationDetails from '../screens/RegisterScreen/SelfieVerificationDetails';
import IDcardandSelfieVerification from "../screens/RegisterScreen/IDcardandSelfieVerification";
import ActivityScreen from "../screens/ActivityScreen"
import TestHistoryScreen from '../screens/TestHistoryScreen'
import ProfessionalDoc from "../screens/ProfessionalRegistration/professionalDoc";
import ProfessionalInformation from "../screens/ProfessionalRegistration/ProfessionalInformation";
import HealthCareHero from "../screens/HealthCareHero"
import SupportHome from '../screens/SupportHome';
import SendSms from '../screens/SendSms'
import UploadTestTypeImage from "../screens/MyHealth/UploadTestTypeImage";
import UploadedTestImageVerification from "../screens/MyHealth/UploadedTestImageVerification";
import UserEnterTestResult from '../screens/EnterTestResult/UserEnterTestResult';
import Donor from '../screens/Donor'
import AddDonor from '../screens/AddDonor'
import Partner from '../screens/Partner'
import RoleType from '../screens/RoleTypeScreen'
import PartnerDashboard from '../screens/PartnerDashboard'
import UserProfile from "../screens/UserProfileScreen"
import CreditCoupon from "../screens/CreditCoupon";
import Organization from '../screens/Organization'
import Member from '../screens/Member'
import ViewOrganization from '../screens/Organization/ViewOrganization'
import Settings from '../screens/Settings'
import QRScanScreen from '../screens/QRScanScreen'
import ScanResult from '../screens/ScanResult'
import TestImageVerification from '../screens/TestImageVerification'
import Vaccination from '../screens/Vaccination'
import EnterTestResult from '../screens/EnterTestResult'
import TestSuccess from "../screens/TestSuccess";
import ReUploadDocument from '../screens/TestHistoryScreen/ReUploadDocument';
import VerifierTest from '../screens/VerifierTest';
import NotFound from '../screens/NotFound';
import SessionExpired from '../screens/SessionExpired';
import UserVaccine from "../screens/Vaccination/UserVaccine";
import LotNumberCamera from "../screens/Vaccination/LotNumberCamera";
import { connect } from "react-redux";
import { withRouter } from "../withRouter";
import HipaaWaiver from "../screens/MyProfile/HipaaWaiver";
import DeleteAccount from "../screens/DeleteAccount";
import DeleteOtpScreen from "../screens/DeleteAccount/DeleteOtpScreen";
import MobileSignUp from "../screens/RegisterScreen/MobileSignUp";

class Root extends Component {

    render() {
         const token = window.localStorage.getItem('token');
        return (
            <Routes>
                <Route path='/' element={<MinimalLayout />}>
                    <Route index element={<Login />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/register" element={<RegisterIdentityVerified />} />
                    <Route path="/otp-verification" element={<OTPVerification />} />
                    <Route path="/password" element={<Password />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-services" element={<TermsOfServices />} />
                    <Route path="/more-information" element={<MoreInformation />} />
                    <Route path="/professional-registration" element={<ProfessionalRegistration />} />
                    <Route path="/open-camera" element={<OpenCamera />} />
                    <Route path="/public-profile/:qrcode" element={<VerifierTestResult />} />
                    <Route path="/delete-account" element={<DeleteAccount />} />
                    <Route path="/otp-verification-for-deletion" element={<DeleteOtpScreen />} />
                </Route>
                <Route element={token !== null ? (<MobileLayout />) : (<Navigate to="/expired" element={<SessionExpired />} />)}>
                    <Route path="/my-activity" element={<ActivityScreen />} />
                    <Route path="/my-account" element={<MyAccount />} />
                    <Route path="/user/home" element={<UserHome />} />
                    <Route path="/my-badge" element={<MyBadge />} />
                    <Route path="/my-health" element={<MyHealth />} />
                    <Route path="/my-queue" element={<VerifierTest />} />
                   
                </Route>
                <Route element={token !== null ? (<MainLayout />) : (<Navigate to="/expired" element={<SessionExpired />} />)}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/user-access" element={<UserAndAccess />} />
                    <Route path="/users-test-results" element={<UsersScreen />} />
                    <Route path="/all-test-results" element={<AllTestResults />} />
                    <Route path="/app-versions" element={<AppVersion />} />
                    <Route path="/messages-configuration" element={<MessageConfiguration />} />
                    <Route path='/user-subscription-plans' element={<UserSubscriptionPlans />} />
                    <Route path='/user-subscription-plan/:id' element={<ViewUserSubscriptionPlan />} />
                    <Route path='/vaccine' element={<Vaccine />} />
                    <Route path='/testTypes' element={<Testtype />} />
                    <Route path='/vaccinemanufacturer' element={<VaccineManufacturerScreen />} />
                    <Route path='/userprofile' element={<UserProfile />} />
                    <Route path='/donor' element={<AddDonor />} />
                    <Route path='/generate-credit-coupons' element={<CreditCoupon />} />
                    <Route path='/organization' element={<Organization />} />
                    <Route path='/partner' element={<Partner />} />
                    <Route path='/partner-dashboard' element={<PartnerDashboard />} />
                    <Route path='/organization-dashboard' element={<Member />} />
                    <Route path='/role-type' element={<RoleType />} />
                    <Route path='/donor-dashboard' element={<Donor />} />
                    <Route path='/view-organization/:id' element={<ViewOrganization />} />
                    <Route path='/settings' element={<Settings />} />
                    <Route path='/health-care-hero' element={<HealthCareHero />} />
                    <Route path='/support/home' element={<SupportHome />} />
                    <Route path='/sms/support' element={<SendSms />} />
                </Route>
                <Route element={<MobileWithoutBottom />}>
                    <Route path="/your-information" element={<YourInformation />} />
                    <Route path="/id-verification" element={<IDVerification />} />
                    <Route path="/id-camera" element={<IDCamera />} />
                    <Route path="/professional-document" element={<ProfessionalDoc />} />
                    <Route path="/professional-information" element={<ProfessionalInformation />} />
                    <Route path="/id-verification-details" element={<IDVerificationDetails />} />
                    <Route path="/selfie-verification" element={<SelfieVerification />} />
                    <Route path="/selfie-camera" element={<SelfieCamera />} />
                    <Route path="/selfie-verification-details" element={<SelfieVerificationDetails />} />
                    <Route path="/id-card-selfie-verification" element={<IDcardandSelfieVerification />} />
                    <Route path="/selfie-verification" element={<SelfieVerification />} />
                    <Route path="/test-history" element={<TestHistoryScreen />} />
                    <Route path="/qr-scanner" element={<QRScanScreen />} />
                    <Route path="/scan-result" element={<ScanResult />} />
                    <Route path="/test-image-verification" element={<TestImageVerification />} />
                    <Route path="/vaccination" element={<Vaccination />} />
                    <Route path="/enter-test-result" element={<EnterTestResult />} />
                    <Route path="/test-success" element={<TestSuccess />} />
                    <Route path="/user-enter-test-result" element={<UserEnterTestResult />} />
                    <Route path="/upload-test-type-image" element={<UploadTestTypeImage />} />
                    <Route path="/re-upload-test-image" element={<ReUploadDocument />} />
                    <Route path="/uploaded-test-image-verification" element={<UploadedTestImageVerification />} />
                    <Route path="/user-vaccination" element={<UserVaccine />} />
                    <Route path="/lot-number-camera" element={<LotNumberCamera />} />
                    <Route path="/hipaa-waiver" element={<HipaaWaiver/>} />
                    <Route path="/mobile-signup" element={< MobileSignUp/>}/>
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    };
};

export default withRouter(connect(mapStateToProps)(Root));