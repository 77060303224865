import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Table, TableBody, TableCell, TableHead, TableRow,
	Typography, Grid, Button, Dialog, DialogActions,
	DialogContent, DialogTitle, TextField,
	FormControl, FormControlLabel, Radio, RadioGroup,
	FormHelperText, IconButton, Card, Toolbar, MenuItem, CircularProgress, Pagination, Chip, Select, Box, InputLabel, OutlinedInput, Avatar,
} from '@mui/material';
import { fetchTestTypeByLimit, addTesttype, updateTesttype } from '../../actions/testtypeAction';
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { withRouter } from '../../withRouter';
import theme from '../../theme';
import { THEMECOLOR, headerFont } from '../../helpers/Constants';
import { fetchVaccine } from '../../actions/vaccineAction';
import _ from 'underscore';

import REJECT from "../../assets/images/reject.svg";
/**Created common styles for UI.*/
const styles = {
	textStyle: {
		fontFamily: "Futura-Heavy",
		color: "#37474f",
		fontSize: 20,
	},
	addButton: {
		backgroundColor: theme.palette.primary.light,
		color: "white",
		fontFamily: "unicode.futurab",
		borderRadius: "10",
		textTransform: 'none',
	},
	cancelButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		fontFamily: "unicode.futurab",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(143, 163, 173, 0.8)',
		}
	},
	editButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		fontFamily: "unicode.futurab",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(143, 163, 173, 0.8)',
		}
	},
	fontCellHeader: {
		fontFamily: "Futura-Book",
		color: "#fff",
		fontWeight: 900,
		padding: '14px 20px 14px 0px',

	},
	fontTableCellBody: {
		fontFamily: "Futura-Medium-bt",
		color: "#37474f",
		padding: '14px 20px 14px 0px',
	},
	inputStyle: {
		fontFamily: "Futura-Book",
		fontSize: 14
	},
	labelStyle: {
		fontFamily: "Futura-Book",
		fontSize: 14
	},
	inputStyleForSearchText: {
		fontSize: "15px !important",
		padding: "5px !important",
		width: 250,
	},
	inputRoot: {
		borderRadius: "0px !important"
	},
	inputLabel: {
		color: theme.palette.primary.main
	},
	dialogPaper: {
		backgroundColor: "rgba(0,0,0,0)",
		boxShadow: "none",
		overflow: "hidden"
	}
};

/**
 * @class
 *
 * Class representing TestType component
 *
 */
class Testtype extends Component {
	constructor(props) {
		super(props);
		this.state = {
			paginationCount: 0,
			testtype: [],
			dialogOpen: false,
			id: null,
			testTypeName: "",
			testTypeName_error: false,
			type: "",
			type_error: false,
			isVaccine: "",
			isVaccine_error: false,
			testTypeDescription: "",
			testTypeDescription_error: false,
			testTypeStatus: "PENDING",
			testTypeStatus_error: false,
			invalid_code_error: null,
			mode: "",
			isCovid: "",
			isCovid_error: false,
			isAntibody: "",
			isAntibody_error: false,
			tempStatus: "",
			name: '',
			name_error: false,
			valueType: "",
			valueType_error: "",
			description: "",
			description_error: false,
			minimumBound: "",
			minimumBound_error: false,
			maximumBound: "",
			maximumBound_error: false,
			status: "ACTIVE",
			status_error: false,
			testTypeItems: [],
			testTypeItemId: 1,
			errorObjs: [],
			testTypeItems_error: false,
			search_text: "",
			rowsPerPage: 10,
			page: 1,
			isLoading: false,
			sortBy: "NAME_ASC",
			vaccineTags: [],
			taggedVaccines: [],
			selectedVaccines: [],
			removedVaccines: []
		}
	}

	/**This is a component life cycle method and used to assigning props values to state variable.*/
	static getDerivedStateFromProps(props, state) {
		if (props.testtype !== state.testtype) {
			return {
				testtype: props.testtype,
			}
		}
		return null;
	};

	/** This is a component life cycle method and used to fetch datas*/
	async componentDidMount() {
		/** fetching TestType details. */
		await this.props.dispatch(fetchTestTypeByLimit(this, 0));

		/** fetching  vaccines details */
		this.props.dispatch(fetchVaccine())
	};

	/**
		 * @function
		 *
		 * function representing table pagination.
		 * @param {Object} _event
		 * @param {Object} newPage
		 */
	handleChangePage = (_event, newPage) => {
		this.setState({
			page: newPage
		});
		this.props.dispatch(fetchTestTypeByLimit(this, this.props.testtype.length * (newPage - 1)))
	};

	/**
		 * @function
		 *
		 * function representing table pagination row.
		 * @param {Object} event
		 */
	// handleChangeRowsPerPage = (event) => {
	// 	this.setState({
	// 		page: 0, rowsPerPage: parseInt(event.target.value, 10)
	// 	});
	// }

	/**
		 * @function
		 *
		 * function representing add or edit dialog open.
		 * @param {Object} item
		 * @param {String} mode
		 */
	handleClick = (item, mode) => {
		// console.log("item ========> " + JSON.stringify(item));
		if (mode === "ADD") {
			this.setState({ mode: mode, dialogOpen: true })
		}
		else {
			let taggingVaccine = [];

			if (!!item && item?.vaccineTags.length > 0) {
				let indexVaccinesById = _.indexBy(this.props.vaccine, 'id');
				for (let { vaccineId } of item.vaccineTags) {
					taggingVaccine.push(indexVaccinesById[vaccineId])
				}
			}
			this.setState({
				id: item.id,
				dialogOpen: true,
				mode: mode,
				testTypeName: item.name,
				testTypeName_error: false,
				type: item.type,
				type_error: false,
				isVaccine: item.type === "TEST" ? false : true,
				isVaccine_error: false,
				isCovid: item.isCovid,
				isCovid_error: false,
				isAntibody: item.isAntibody,
				isAntibody_error: false,
				testTypeDescription: item.description,
				testTypeDescription_error: false,
				testTypeStatus: item.status,
				tempStatus: item.status,
				testTypeStatus_error: false,
				invalid_code_error: null,
				testTypeItems: item.testTypeItems,
				errorObjs: [],
				vaccineTags: item.vaccineTags,
				taggedVaccines: taggingVaccine,
				selectedVaccines: [],
				removedVaccines: []
			})
		}
	};

	/**
 * @function
 * This function representing close dialog.
 * 
 */
	handleClose = () => {
		this.setState({
			dialogOpen: false,
			id: null,
			testTypeName: "",
			testTypeName_error: false,
			type: "",
			type_error: false,
			isVaccine: "",
			isVaccine_error: false,
			testTypeDescription: "",
			testTypeDescription_error: false,
			testTypeStatus: "PENDING",
			testTypeStatus_error: false,
			invalid_code_error: null,
			mode: "",
			isCovid: "",
			isCovid_error: false,
			isAntibody: "",
			isAntibody_error: false,
			testTypeItems: [],
			errorObjs: [],
			testTypeItemId: 1,
		})
	};

	/**
	 * @function
	 * This function representing add and edit TestType.
	 * @param {String} mode The mode string
	 * 
	 */
	handleSubmit = (mode) => {
		// let { testTypeItems, } = this.state
		let errorObjs = []
		let isError = false;
		let { id, testTypeName, type, isCovid, isAntibody, testTypeDescription, testTypeStatus } = this.state;
		if (testTypeName === "" || testTypeName === null) {
			this.setState({ testTypeName_error: true, })
			isError = true;
		}
		if (type === "" || type === null) {
			this.setState({ type_error: true, })
			isError = true;
		}
		if (isCovid === "" || isCovid === null) {
			this.setState({ isCovid_error: true, })
			isError = true;
		}
		if (isAntibody === "" || isAntibody === null) {
			this.setState({ isAntibody_error: true, })
			isError = true;
		}
		if (testTypeStatus === "" || testTypeStatus === null) {
			this.setState({ testTypeStatus_error: true, })
			isError = true;
		}
		if (type === "TEST") {
			// for (let testTypeItem of testTypeItems) {
			// for (let key of Object.keys(testTypeItem)) {
			// 	if ((key !== "minimumBound" && key !== "maximumBound" && key !== "description" && key !== "status") && (testTypeItem[key] === null || testTypeItem[key] === "")) {
			// 		let errorObj = {}
			// 		errorObj.errorKey = key;
			// 		errorObj.errorId = testTypeItem.id
			// 		errorObjs.push(errorObj)
			// 		isError = true
			// 	}
			// }
			// }
		}

		if (isError === false) {
			let testtypeObj = {};
			testtypeObj.name = testTypeName;
			testtypeObj.type = type;
			testtypeObj.isVaccine = type === "TEST" ? false : true;
			testtypeObj.isCovid = isCovid;
			testtypeObj.isAntibody = isAntibody;
			testtypeObj.status = testTypeStatus;
			testtypeObj.description = testTypeDescription

			if (mode === "ADD") {
				this.setState({ isLoading: true })
				this.props.dispatch(addTesttype(this, testtypeObj, this.state.testTypeItems, this.state.selectedVaccines))
			}
			else {
				this.setState({ isLoading: true })
				this.props.dispatch(updateTesttype(this, testtypeObj, id, this.state.testTypeItems, this.state.selectedVaccines, this.state.removedVaccines))
			}

			this.setState({ binDialog: false, testTypeItems: [], errorObjs: [], testTypeItemId: 1 })
		}
		else {
			this.setState({ errorObjs: errorObjs })
		}
	};

	/**
	 * @function
	 * This function representing add new TestTypeItem .
	 * @param {String} modeItem The modeItem string
	 * 
	 */
	addNewTestTypeItem = (modeItem) => {
		let { testTypeItems, testTypeItemId } = this.state
		let lastIndex = testTypeItems.length > 0 ? testTypeItems.length - 1 : 0
		let testTypeItem = {};
		testTypeItem.id = modeItem === "EDIT" ? testTypeItems[lastIndex].id + 1 : testTypeItemId;
		testTypeItem.name = "";
		testTypeItem.valueType = "";
		testTypeItem.minimumBound = "";
		testTypeItem.maximumBound = "";
		testTypeItem.description = "";
		testTypeItem.status = "ACTIVE";
		this.setState({ testTypeItems: [...testTypeItems, testTypeItem], testTypeItemId: testTypeItemId + 1 })
	};

	/**
	 * @function
	 * This function representing remove TestTypeItem.
	 * @param {Number} id The id Number
	 * 
	 */
	removeTestTypeItem = (id) => {
		let { testTypeItems } = this.state;
		let filterTestTypeItems = testTypeItems.filter(item => item.id !== id)
		this.setState({ testTypeItems: filterTestTypeItems.length > 0 ? filterTestTypeItems : testTypeItems })
	};

	/**
	 * @function
	 * This function representing get key value to return.
	 * @param {Number} id The id Number
	 * @param {String} key The key String
	 */
	getTextValue = (key, id) => {
		let { testTypeItems } = this.state
		let isExist = testTypeItems.find(v => v.id === id);
		return isExist !== undefined ? isExist[key] : ""
	};

	/**
		 * @function
		 * This function representing validate key.
		 * @param {Number} id The id Number
		 * @param {String} key The key String
		 */
	checkError = (id, key) => {
		let { errorObjs } = this.state;
		let findObj = errorObjs.find(item => (item.errorKey === key && item.errorId === id))
		if (findObj !== undefined) {
			return true
		}
	};

	/**
		 * @function
		 * This function representing change text input value.
		 * @param {Number} id The id Number
		 * @param {String} key The key String
		 * @param {String} value The value String
		 */
	onTextChange = (value, key, id) => {
		let { testTypeItems, errorObjs } = this.state
		let isExist = testTypeItems.find(v => v.id === id);
		isExist[key] = value
		let findobj = errorObjs.find(item => (item.errorKey === key && item.errorId === id))
		let errorObjsFilter = errorObjs.filter(item => item !== findobj)
		this.setState({ testTypetems: testTypeItems.map(testTypeItem => (testTypeItem.id === id) ? isExist : testTypeItem), errorObjs: errorObjsFilter })
	};

	/** rendering TestTypes information. */
	render() {
		let testTypes = this.state.sortBy === "NAME_ASC" ? this.state.testtype.sort((a, b) => a.name.localeCompare(b.name)) : this.state.testtype.sort((a, b) => b.name.localeCompare(a.name));
		let result = this.state.search_text !== '' ? testTypes.filter(x => (x.name).toString().toLowerCase().includes((this.state.search_text).toString().toLowerCase())) : testTypes;
		return (
			<div style={{ paddingLeft: 2, paddingRight: 10 }}>
				<Grid container spacing={0} style={{ marginTop: 5, }}>
					<Grid item xs={12} sm={12}>
						<Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
							<div>
								<Typography style={{ color: theme.palette.secondary.light, fontWeight: headerFont.fontWeight, fontFamily: headerFont.fontFamily, fontSize: headerFont.fontSize, }}>Test Types</Typography>
							</div>
							{/* <TablePagination
								rowsPerPageOptions={[20]}
								component="div"
								count={result.length}
								rowsPerPage={this.state.rowsPerPage}
								page={this.state.page}
								slotProps={{
									select: {
										inputProps: { 'aria-label': 'rows per page' },
										native: true,
									}
								}}
								onChangePage={this.handleChangePage}
								onChangeRowsPerPage={this.handleChangeRowsPerPage}
								labelRowsPerPage={null}
								labelDisplayedRows={
									() => null
								}
								ActionsComponent={
									TablePaginationActions
								}
							/> */}
							<div style={{ display: "flex", padding: 10 }}>
								<Button size="small" variant="contained" color="primary" style={{ ...styles.addButton, marginRight: 10, height: 40, margin: 20 }} onClick={() => this.handleClick(null, "ADD")} >
									Add Test Type
								</Button>
								<TextField
									margin="normal"
									id="search-text"
									variant='standard'
									label="Search"
									placeholder="Name"
									name="mobile-number"
									autoComplete="off"
									value={this.state.search_text}
									onChange={event =>
										this.setState({ search_text: event.target.value, page: 0 })
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{ styles: { input: styles.inputStyleForSearchText, root: styles.inputRoot, inputlabel: styles.inputLabel } }}
									autoFocus
								/>
							</div>

						</Toolbar>
					</Grid>
					<Grid item xs={8} sm={12} style={{ paddingTop: 10 }}>
						<Table>
							<TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10 }}>
								<TableRow>
									<TableCell align="center" style={{ fontFamily: "Futura-Book", color: ' #fff', fontWeight: 900, padding: '14px 0px 14px 20px', display: "flex", justifyContent: "center", alignItems: "center" }}>
										Name
										<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_ASC" })} />
										<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_DSC" })} />
									</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Type</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Description</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Status</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Edit</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{result.length > 0 ? result.map((item, index) =>
									<TableRow key={item.id} style={{ backgroundColor: index % 2 === 0 ? "#fff" : "whitesmoke" }}>
										<TableCell align="center" style={{ ...styles.fontTableCellBody, paddingLeft: 5 }}>{item.name}</TableCell>
										<TableCell align="center" style={styles.fontTableCellBody}>{item.type}</TableCell>
										<TableCell align="center" style={styles.fontTableCellBody}>{item.description}</TableCell>
										<TableCell align="center" style={styles.fontTableCellBody}>{item.status}</TableCell>
										<TableCell align="center" style={styles.fontTableCellBody}>
											<Button size="small" style={styles.editButton} onClick={() => this.handleClick(item, "EDIT")}>EDIT</Button>
										</TableCell>
									</TableRow>
								)
									:
									<TableRow>
										<TableCell colSpan={5} style={{ ...styles.fontTableCellBody, textAlign: 'center' }}>There are no test types</TableCell>
									</TableRow>
								}
							</TableBody>
						</Table>
						<Grid display={'flex'} flexDirection={'row'} justifyContent={'center'} padding={10}>
							<Pagination
								count={this.state.paginationCount}
								color="primary"
								size="large"
								page={this.state.page}
								variant="outlined"
								shape="rounded"
								onChange={this.handleChangePage}
							/>
						</Grid>

						{/* <TablePagination
            component="div"
            rowsPerPageOptions={[10, 25]}
            count={!!result && result.length}
            page={this.state.page}
            rowsPerPage={this.state.rowsPerPage}
            labelRowsPerPage="No. of items per page"
            // onPageChange={handleChangePage}
            // onRowsPerPageChange={(e) => handleChangeRowsPerPage(e)}
          /> */}
					</Grid>
				</Grid>
				<Dialog
					maxWidth={"md"}
					// disableBackdropClick={true}
					// disableEscapeKeyDown={true}
					scroll='body'
					fullWidth={true}
					aria-labelledby="form-dialog-title"
					open={this.state.dialogOpen} >
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "1%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{this.state.mode === "ADD" ? "Add Test Type" : "Update Test Type"}</Typography>
					</DialogTitle>

					<DialogContent style={{ overflowY: "hidden" }}>
						<Grid container justify="center" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									id="testTypeName"
									label="Name"
									name="testTypeName"
									style={styles.margin}
									value={this.state.testTypeName.charAt(0).toUpperCase() + this.state.testTypeName.slice(1)}
									onChange={(event) => this.setState({ testTypeName: event.target.value.trimStart(), testTypeName_error: false })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										styles: { input: styles.inputStyle }
									}}
									fullWidth
									error={this.state.testTypeName_error === true ? true : false}
									helperText={this.state.testTypeName_error === true ? "Please enter name" : false}
								/>
							</Grid>

							<Grid item xs={6} style={{ paddingRight: 8, marginTop: -2 }}>
								<TextField
									id="type"
									select
									label="Type"
									name="type"
									value={this.state.type}
									onChange={(event) => this.setState({ type: event.target.value, type_error: false })}
									margin="normal"
									InputLabelProps={{
										shrink: true
									}}
									InputProps={{
										styles: { input: styles.inputStyle }
									}}
									required
									fullWidth
									error={this.state.type_error === true ? true : false}
									helperText={this.state.type_error === true ? "Please select the type" : false}
								>
									<MenuItem value={"TEST"} >TEST</MenuItem>

									<MenuItem value={"VACCINE"} >VACCINE</MenuItem>

								</TextField>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<div style={{ padding: "2% 0 2% 0" }}>
									<Typography style={{ fontFamily: "Futura-Book", fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>IS Covid</Typography>
									<FormControl component="fieldset">
										<RadioGroup aria-label="position" name="isCovid" value={this.state.isCovid.toString()}
											onChange={(event) => this.setState({ isCovid: event.target.value, isCovid_error: false })}
											row>
											<FormControlLabel
												value={"true"}
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>Yes</span>}
												labelPlacement="end"
											/>
											<FormControlLabel
												value={"false"}
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>No</span>}
												labelPlacement="end"
											/>
										</RadioGroup>
										{this.state.isCovid_error === true && <FormHelperText style={{ color: "red" }}>{"Please select iscode"}</FormHelperText>}
									</FormControl>
								</div>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<div style={{ padding: "2% 0 2% 0" }}>
									<Typography style={{ fontFamily: "Futura-Book", fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>IS Antibody</Typography>
									<FormControl component="fieldset">
										<RadioGroup aria-label="position" name="isAntibody" value={this.state.isAntibody.toString()}
											onChange={(event) => this.setState({ isAntibody: event.target.value, isAntibody_error: false })}
											row>
											<FormControlLabel
												value={"true"}
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>Yes</span>}
												labelPlacement="end"
											/>
											<FormControlLabel
												value={"false"}
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>No</span>}
												labelPlacement="end"
											/>
										</RadioGroup>
										{this.state.isAntibody_error === true && <FormHelperText style={{ color: "red" }}>{"Please select isantibody"}</FormHelperText>}
									</FormControl>
								</div>
							</Grid>
							<Grid item xs={12} style={{ paddingRight: 8 }}>
								<TextField
									id="testTypeDescription"
									label="Description"
									name="testTypeDescription"
									style={styles.margin}
									value={this.state.testTypeDescription}
									onChange={(event) => this.setState({ testTypeDescription: event.target.value.trimStart(), testTypeDescription_error: false, })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										styles: { input: styles.inputStyle }
									}}
									fullWidth
									error={this.state.testTypeDescription_error === true ? true : false}
									helperText={this.state.testTypeDescription_error === true ? "Please enter description" : false}
								/>
							</Grid>
							<Grid item xs={12} style={{ paddingRight: 8 }}>
								<div style={{ padding: "2% 0 2% 0" }}>
									<Typography style={{ fontFamily: "Futura-Book", fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>Status</Typography>
									<FormControl component="fieldset">
										<RadioGroup aria-label="position" name="position" value={this.state.testTypeStatus}
											onChange={(event) => this.setState({ testTypeStatus: event.target.value, testTypeStatus_error: false })} row>
											<FormControlLabel
												value="ACTIVE"
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>Active</span>}
												labelPlacement="end"
											/>
											<FormControlLabel
												value="INACTIVE"
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>In Active</span>}
												labelPlacement="end"
											/>
											<FormControlLabel
												value="DISABLE"
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>Disable</span>}
												labelPlacement="end"
											/>
											{
												this.state.mode === "EDIT" ?
													this.state.tempStatus === "PENDING" &&
													<FormControlLabel
														value="PENDING"
														control={<Radio style={{ color: theme.palette.primary.main }} />}
														label={<span style={styles.labelStyle}>Pending(coming soon)</span>}
														labelPlacement="end"
													/>
													:
													<FormControlLabel
														value="PENDING"
														control={<Radio style={{ color: theme.palette.primary.main }} />}
														label={<span style={styles.labelStyle}>Pending(coming soon)</span>}
														labelPlacement="end"
													/>
											}
										</RadioGroup>
										{this.state.testTypeStatus_error === true && <FormHelperText style={{ color: "red" }}>{"Please select status"}</FormHelperText>}
									</FormControl>
								</div>
							</Grid>
						</Grid>
						{this.state.type === "TEST" &&
							<div>
								<DialogTitle id="form-dialog-title" >
									<div style={{ display: 'flex', justifyContent: "space-between" }}>
										<Typography style={{ fontFamily: "Futura-Heavy", color: theme.palette.secondary.light, fontWeight: "bold", paddingLeft: 20 }}>{this.state.mode !== "EDIT" ? "Add TestType Items" : "Update TestType Items"}</Typography>
										<Button onClick={() => this.addNewTestTypeItem(this.state.mode)} variant="contained" color="primary" style={styles.newButton}>{this.state.mode !== "EDIT" ? "Add" : "Add New Item"}</Button>
									</div>
								</DialogTitle>
								{this.state.testTypeItems.length === 0 ?
									<div>
										{this.state.testTypeStatus !== "PENDING" && this.addNewTestTypeItem("ADDMODE")}
									</div> :
									this.state.testTypeItems.length > 0 &&
									this.state.testTypeItems.map((testTypeItem, index) =>
										<Card style={{ padding: 10, margin: 5 }} key={testTypeItem}>
											<Grid container justify="center" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
												<Grid item xs={6} style={{ paddingRight: 8 }}>
													<TextField
														id="name"
														label="Name"
														name="name"
														autoComplete="off"
														style={styles.margin}

														margin="normal"
														InputLabelProps={{
															shrink: true,
														}}
														InputProps={{
															styles: { input: styles.inputStyle }
														}}
														fullWidth

														value={this.getTextValue("name", testTypeItem.id)}
														onChange={(event) => this.onTextChange(event.target.value, "name", testTypeItem.id, null)}
														error={this.checkError(testTypeItem.id, "name") === true ? true : false}
														helperText={this.checkError(testTypeItem.id, "name") === true ? "Please enter Name" : false}
													/>
												</Grid>
												<Grid item xs={6} style={{ paddingRight: 8, marginTop: -2 }}>
													<TextField
														id="valueType"
														select
														label="Value Type"
														name="valueType"

														value={this.getTextValue("valueType", testTypeItem.id)}
														onChange={(event) => this.onTextChange(event.target.value, "valueType", testTypeItem.id, null)}

														margin="normal"
														InputLabelProps={{
															shrink: true
														}}
														InputProps={{
															styles: { input: styles.inputStyle }
														}}
														required
														fullWidth
														error={this.checkError(testTypeItem.id, "valueType") === true ? true : false}
														helperText={this.checkError(testTypeItem.id, "valueType") === true ? "Please select the valueType" : false}
													>
														<MenuItem value={"BOOLEAN"} >BOOLEAN</MenuItem>
														<MenuItem value={"NUMBER"} >INTEGER</MenuItem>
														<MenuItem value={"INTEGER"} >FLOAT</MenuItem>
														<MenuItem value={"STRING"} >STRING</MenuItem>
													</TextField>
												</Grid>
												<Grid item xs={6} style={{ paddingRight: 8 }}>
													<TextField
														id="minimumBound"
														label="Minimum Bound"
														name="minimumBound"
														autoComplete="off"
														style={styles.margin}
														value={this.getTextValue("minimumBound", testTypeItem.id)}
														onChange={(event) => this.onTextChange(event.target.value, "minimumBound", testTypeItem.id, null)}
														margin="normal"
														InputLabelProps={{
															shrink: true,
														}}
														InputProps={{
															styles: { input: styles.inputStyle }
														}}
														fullWidth
													/>
												</Grid>
												<Grid item xs={6} style={{ paddingRight: 8 }}>
													<TextField
														id="maximumBound"
														label="Maximum Bound"
														name="maximumBound"
														autoComplete="off"
														style={styles.margin}
														value={this.getTextValue("maximumBound", testTypeItem.id)}
														onChange={(event) => this.onTextChange(event.target.value, "maximumBound", testTypeItem.id, null)}
														margin="normal"
														InputLabelProps={{
															shrink: true,
														}}
														InputProps={{
															styles: { input: styles.inputStyle }
														}}
														fullWidth
													/>
												</Grid>
												<Grid item xs={12} style={{ paddingRight: 8 }}>
													<TextField
														id="description"
														label="Description"
														name="description"
														autoComplete="off"
														style={styles.margin}
														value={this.getTextValue("description", testTypeItem.id)}
														onChange={(event) => this.onTextChange(event.target.value, "description", testTypeItem.id, null)}
														margin="normal"
														InputLabelProps={{
															shrink: true,
														}}
														InputProps={{
															styles: { input: styles.inputStyle }
														}}
														fullWidth
													/>
												</Grid>
												{this.state.mode !== "EDIT" &&
													<IconButton style={{ height: 50 }} onClick={() => this.removeTestTypeItem(testTypeItem.id)}><ClearIcon style={{ color: "red" }} /></IconButton>
												}
											</Grid>
										</Card>
									)}
							</div>
						}

						{this.state.type === "VACCINE" &&
							<div>
								<DialogTitle id="form-dialog-title" >
									<div style={{ display: 'flex', justifyContent: "space-between" }}>
										<Typography style={{ fontFamily: "Futura-Heavy", color: theme.palette.secondary.light, fontWeight: "bold", paddingLeft: 20 }}>{this.state.mode !== "EDIT" ? "Add Vaccine Tags" : "Update Vaccine Tags"}</Typography>
									</div>
								</DialogTitle>

								<Grid container justify="center" spacing={2} style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
									<Grid item xs={12}>
										{this.state.taggedVaccines.length > 0 ? (
											this.state.taggedVaccines.map((element, index) =>
												<Chip
													key={`tag-${index}`}
													size="small"
													label={`${element.name}`}
													// clickable
													color={"primary"}
													avatar={
														<Avatar
															onClick={() => {
																let taggingVaccine = [];
																taggingVaccine = this.state.taggedVaccines.filter((z) => (z.id !== element.id) ? z : null);
																this.setState({
																	taggedVaccines: taggingVaccine,
																	removedVaccines: [...this.state.removedVaccines, element]
																});
															}}
														>
															<img src={REJECT} height="20" alt="icon" style={{ backgroundColor: "white" }} />
														</Avatar>
													}
												/>
											)
										) : <p>There are no content</p>
										}

									</Grid>
									<Grid item xs={6}>
										<FormControl fullWidth>
											<InputLabel id="demo-multiple-chip-label">Select the vaccines</InputLabel>
											<Select
												labelId="demo-multiple-chip-label"
												id="demo-multiple-chip"
												fullWidth
												multiple
												value={this.state.selectedVaccines}
												label="Select the vaccines"
												onChange={(event) => this.setState({ selectedVaccines: [...event.target.value] })}
												input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
												renderValue={(selected) => (
													<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
														{selected.map((value, index) => (
															<Chip key={`value-${index}`} label={value.name} />
														))}
													</Box>
												)}
												MenuProps={{
													PaperProps: {
														style: {
															maxHeight: 48 * 4.5 + 8,
															width: 250,
														},
													},
												}}
											>
												{this.props.vaccine.map((item, vaccineIndex) => (
													<MenuItem
														key={`vaccineIndex-${vaccineIndex}`}
														value={item}
													>
														{item.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>

							</div>
						}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.handleSubmit(this.state.mode)} variant="contained" style={styles.addButton}>Submit</Button>
						<Button onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.isLoading}
					styles={{
						paper: styles.dialogPaper,
					}}
				>
					<div style={{ boxShadow: 0, overflow: "none" }}>
						<CircularProgress color="primary" size={40} />
					</div>
				</Dialog>
			</div>
		)
	}
}
/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
	return {
		testtype: state.testtypeReducer.testtype,
		vaccine: state.vaccineReducer.vaccine,
	}
}
Testtype.propTypes = {
	// styles: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps)(Testtype));