import { FETCH_MESSAGE_CONFIGURATION } from "../actions/actionTypes"

const initialstate = {
	messages: []
}

export default function messageConfigReducer(state = initialstate, action) {
	switch (action.type) {
		case FETCH_MESSAGE_CONFIGURATION:
			return {
				...state,
				messages: action.data
			}

		default:
			return state;
	}
}