import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Typography, TextField, Grid, Button, Toolbar, FormHelperText } from "@mui/material";
import { registerwithOtp } from "../../actions/registerAction";
import { connect } from "react-redux";
import { ADD_PHONE, FORGOT_PASSWORD } from "../../actions/actionTypes";
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { THEMECOLOR } from "../../helpers/Constants";

const DOT_LENGTH = 10;

/** Created common styles for UI  */
const styles = {
	paper: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		height: '1vh'
	},
	topContainer: {
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
		height: "200px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderBottomLeftRadius: "10%",
		borderBottomRightRadius: "10%"
	},
	bottomContainer: {
		backgroundColor: "#fff",
		borderRadius: 10,
		margin: "0px 10px",
		padding: "10px",
		boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
		transition: "0.3s",
		width: "40px%"
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
		textAlign: "center",
		padding: 5,
		color: "#fff",
	},
	button: {
		fontSize: 15,
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		fontWeight: "bold",
		borderRadius: 5,
	},
	bottomTextContainer: {
	   textAlign: "left",
	},
	toolbar: {
		padding: 0,
		marginLeft: 20,
		//justifyContent: "center",
		minHeight: "0px"
	},
	topText: {
		textAlign: "center",
		fontSize: 18,
		paddingVertical: 5
	},
	secondaryText: {
		textAlign: "center",
		fontSize: window.screen.width >= 1280 ? 16 : 13,
		paddingVertical: 5,
		color: "#7F8EB8"
	},
	innerInpuView: {
		display: "flex",
		flexDirection: 'column',
		// alignItems: 'center',
		borderBottomWidth: 1,
		borderBottomColor: "#F6F6F6"
	},
	textView: {
		paddingVertical: 5
	},
	pickView: {
		display: "flex",
		flexDirection: "row",
		alignContent: "center",
		alignItems: "center",
		alignSelf: "center"
	},
	borderDot: {
		width: 24,
		height: 24,
		borderRadius: 12,
		backgroundColor: "#fff",
		borderWidth: 6,
		borderColor: "rgba(265,265,265,0.2)"
	},
	linkText: {
		color: theme.palette.primary.main,
		marginLeft: 5,
		textDecorationLine: 'underline'
	},
	buttonContainer: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center",
		paddingTop: 50,
		margin: 30
	},
	infoContainer: {
		display: 'flex',
		width: "100%",
		justifyContent: "center",
		alignItems: "center",
		marginBottom: 15
	},
	dotView: {
		display: 'flex',
		flexDirection: "row",
		width: "90%",
		alignItems: "center",
		justifyContent: "space-around",
		marginTop: -100
	},
	dotStyle: {
		width: 12,
		height: 12,
		borderRadius: 6,
		backgroundColor: "rgba(265,265,265,0.5)"
	},
};

/**
 * @class
 *
 * Class representing Signup component
 *
 */
class Signup extends Component {

	constructor() {
		super();
		this.state = {
			mobile_number: "",
			mobile_number_error: false,
			invalid_mobile_number_error: false
		}
	}

	/** This function using to returning a dotted view */
	renderDotView = () => {
		let DotArray = []
		for (let i = 0; i < DOT_LENGTH; i++) {
			DotArray.push(<div key={i.toString()} id={i} style={{
				width: 4,
				height: 4,
				borderRadius: 2,
				backgroundColor: "rgba(265,265,265,0.5)"
			}} />)
		}
		return DotArray;
	};

	/**
	 * Checks phone number if valid or not
	 * @function
	 * @param {string} phone - given phone number
	 */
	validatePhone(phone) {
		if (phone.length === 10) {
			let re = /^\d{10}$/;
			return re.test(parseInt(phone));
		}
		else {
			return false
		}
	};

	/**
	 * This function used to add phone number to server
	 * @function 
	 */
	submit = () => {
		let isError = false;

		if (this.state.mobile_number === "" || this.state.mobile_number === null) {
			this.setState({ mobile_number_error: true })
			isError = true
		} else if (!this.validatePhone(this.state.mobile_number)) {
			this.setState({ invalid_mobile_number_error: true })
			isError = true
		}

		if (isError === false) {
			this.props.dispatch({ type: FORGOT_PASSWORD, data: false })
			this.props.dispatch({ type: ADD_PHONE, data: this.state.mobile_number })
			this.props.dispatch(registerwithOtp(this));
		}
	};

	/** rendering Signup screen*/
	render() {
		return (
			<div style={styles.paper}>
				<Grid container justifyContent="center">
					<Grid item xs={12} >
						<div style={styles.topContainer}>
							<Typography style={styles.title}>Sign Up</Typography>
						</div>
						<div>
							<div style={styles.infoContainer}>
								<div style={styles.dotView}>
									<div style={styles.dotStyle} />
									{this.renderDotView()}
									<div style={styles.borderDot} />
									{this.renderDotView()}
									<div style={styles.dotStyle} />
								</div>
							</div>
						</div>
						<Grid container justifyContent="center">
							<Grid item style={{ marginTop: -40 }} xs={12} sm={6} md={4}>
								<div style={styles.bottomContainer}>
									<Typography style={styles.topText}>Enter Mobile Number</Typography>
									<Typography style={styles.secondaryText}>Enter your phone number to sign up</Typography>
									<div style={styles.textView}>
										<div style={styles.innerInpuView}>
											<div style={styles.pickView} />
											<TextField
												margin="normal"
												fullWidth
												color={"primary"}
												variant="standard"
												id="mobile-number"
												label="Phone Number"
												name="mobile-number"
												autoComplete="off"
												value={this.state.mobile_number}
												onChange={(event) => this.setState({ mobile_number: event.target.value, mobile_number_error: false, invalid_mobile_number_error: false })}
												error={this.state.mobile_number_error}
											/>
											{this.state.mobile_number_error &&
												<FormHelperText style={{ color: theme.palette.secondary.error, marginLeft: "5px" }}>Enter mobile number</FormHelperText>
											}
											{this.state.invalid_mobile_number_error &&
												<FormHelperText style={{ color: theme.palette.secondary.error, marginLeft: "5px" }}>Enter Valid mobile number</FormHelperText>
											}
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
						<Grid container justifyContent="center">
							<Grid item xs={12} sm={6} md={4}>
								<div style={styles.buttonContainer}>
									<Button
										type="Button"
										fullWidth
										variant="contained"
										color={"primary"}
										style={styles.button}
										onClick={() => this.submit()}
									>
										Sign Up
									</Button>
								</div>
								<Grid style={{ alignItems: "flex-end" }}>
									<div style={styles.bottomTextContainer}>
										<Toolbar style={styles.toolbar}>
											<Typography style={{
												fontSize: window.screen.width >= 1280 ? 16 : 14,
												color: "#7F8EB8",
											}}>By continuing you agree to our </Typography>
											<a style={styles.linkText} rel="noopener noreferrer" target='_blank' href={"https://medfilo.com/privacy-policy/"}><Typography style={{ fontSize: window.screen.width >= 1280 ? 16 : 14 }}>Privacy Policy </Typography></a>
										</Toolbar>
										<Toolbar style={styles.toolbar}>
											<Typography style={{ fontSize: window.screen.width >= 1280 ? 16 : 14, color: "#7F8EB8" }}>
												and
											</Typography>
											<a style={styles.linkText} rel="noopener noreferrer" target='_blank' href={"https://medfilo.com/terms-of-use/"}><Typography style={{ fontSize: window.screen.width >= 1280 ? 16 : 14 }}>Terms of Services. </Typography></a>
										</Toolbar>
									</div>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		)
	}
};

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {

	};
};

Signup.propTypes = {
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(Signup));