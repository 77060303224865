import React, { Component } from 'react';
import InputIcon from '@mui/icons-material/Input';
import Logo from '../../../../assets/images/medfilo.png';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from "../../../../actions/sessionAction";
import { fetchUser, fetchUserTypes, changeUserType } from "../../../../actions/userAction"
import theme from "../../../../theme"
import { AppBar, Toolbar, IconButton, Hidden, Tooltip, Button, Typography, Menu, MenuItem } from "@mui/material";

const styles = {
	root: {
		boxShadow: 'none',
		background: `${theme.palette.primary.main}`
	},
	flexGrow: {
		flexGrow: 1
	},
	Label: {
		marginLeft: 75
	},
	button: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		marginTop: 5,
		padding: 7,
		fontSize: 15,
		backgroundColor: "red",
		color: "#ffffff",
		fontWeight: "bold",
		borderRadius: 5,
		'&:hover': {
			backgroundColor: "#12bcdf",
		},
	},
}
class Topbar extends Component {
	constructor() {
		super()
		this.state = {
			anchorEl: null,
			user: {},
			fetch_user: {}
		}
	}

	/** This is a component life cycle method and used to assigning props values to state variable  */
	static getDerivedStateFromProps(props, state) {
		if (props.user !== state.user || props.fetch_user !== state.fetch_user) {
			return {
				user: props.user,
				fetch_user: props.fetch_user,
			}
		}
		return null;
	}

	/**
		 * @function
		 *
		 * function representing clicking event open menu.
		 *
		 */
	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	/**
		 * @function
		 *
		 * function representing clicking event close menu.
		 *
		 */
	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	/**
		 * @function
		 *
		 * function representing clicking event changing user type.
		 *
		 */
	changeUserType = (switchUserType) => {
		this.props.dispatch(changeUserType(this, switchUserType.id))
		this.setState({ anchorEl: null });
	}

	/** This is a component life cycle method and used to fetch datas  */
	componentDidMount() {
		/** fetching user profile details. */
		this.props.dispatch(fetchUser())
		/** fetching user types. */
		this.props.dispatch(fetchUserTypes())
	}

	/**
		 * @function
		 *
		 * function representing return user type name.
		 *
		 */
	userTypeName = (userType) => {
		if (userType === "PSV") {
			return "Partner Supervisor"
		} else if (userType === "OSV") {
			return "Organization Supervisor"
		} else if (userType === "DNR") {
			return "Donor Supervisor"
		} else if (userType === "MSP") {
			return "Medical Service Provider"
		} else if (userType === "VFR") {
			return "Verifier"
		} else if (userType === "CST") {
			return "Customer Support"
		} else if (userType === "ADM" || userType === "Admin") {
			return "Administrator"
		} else {
			return "User"
		}
	}

	/** rendering App bar */
	render() {
		// const { ...rest } = this.props;
		return (
			<AppBar
				// {...rest}
				color="default"
				style={styles.root}
			>
				<Toolbar>
					<img
						alt="Logo"
						src={Logo}
						style={{ marginTop: -10 }}
						height={65}
						width={65}
					/>
					<div style={styles.flexGrow} />
					<Hidden xsDown>
						{this.props.user_types.length > 1 && <div>
							<Button
								aria-controls="simple-menu" aria-haspopup="true"
								type="Button"
								fullWidth
								variant="contained"
								onClick={this.handleClick}
								style={styles.button}
							>
								Switch User Type
							</Button>
						</div>}
						<div style={{ display: "flex" }}>
							<Tooltip title="Go to profile" placement="bottom-end">
								<IconButton size="small" style={{ borderRadius: 0, textTransform: "none" }} component={Link}
									to={"/userprofile"}
								>
									<div style={{ paddingLeft: "10px" }}>
										<Typography variant="subtitle2" style={{ color: "white", fontSize: 16, lineHeight: 0.8, fontFamily: "Futura-Medium-bt", paddingTop: 5 }}>
											{Object.keys(this.state.fetch_user).length > 0 && this.state.fetch_user.firstName + " " + this.state.fetch_user.lastName}
										</Typography>
										<Typography variant="subtitle2" style={{ color: "white", fontSize: 16, fontFamily: "Futura-Book" }}>
											{Object.keys(this.state.fetch_user).length > 0 && this.userTypeName(this.state.fetch_user.userType)}
										</Typography>
									</div>
								</IconButton>
							</Tooltip>
						</div>

						<div style={{ marginRight: 15, height: 40, width: 1, backgroundColor: "gray", marginLeft: 15, marginTop: 5 }}></div>
					</Hidden>
					<Hidden xsDown>
						<Tooltip title="Log out" placement="bottom-end">
							<IconButton
								size="small"
								color="inherit"
								onClick={() => this.props.dispatch(logout(this))}
							>
								<InputIcon style={{ color: "#fff" }} />
							</IconButton>
						</Tooltip>
					</Hidden>
					{/* <Hidden lgUp>
						{this.props.user_types.length > 1 && <div style={{ margin: 3 }}>
							<Button
								aria-controls="simple-menu" aria-haspopup="true"
								type="Button"
								fullWidth
								variant="contained"
								onClick={this.handleClick}
								style={styles.button}
							>
								Switch User Type
							</Button>
						</div>} */}
						{/* <Tooltip title="Log out" placement="bottom-end">
							<IconButton
								size="small"
								color="inherit"
								onClick={() => this.props.dispatch(logout())}
							>
								<InputIcon style={{ color: "#FFFFFF" }} />
							</IconButton>
						</Tooltip> */}
					{/* </Hidden> */}
				</Toolbar>
				<Menu
					id="simple-menu"
					anchorEl={this.state.anchorEl}
					keepMounted
					open={Boolean(this.state.anchorEl)}
					onClose={this.handleClose}
				>
					{this.props.user_types.map((x, index) => x.isDefault !== true &&
						<MenuItem key={`USER-${index.toString()}`}
							disabled={Object.keys(this.state.fetch_user).length > 0 && this.state.fetch_user.status === "PENDING" && (x.roleType.code === "VFR" || x.roleType.code === "MSP") ? true : false}
							onClick={() => this.changeUserType(x)}>
							<Typography>{Object.keys(this.state.fetch_user).length > 0 && this.state.fetch_user.status === "PENDING" && (x.roleType.code === "VFR" || x.roleType.code === "MSP") ? this.userTypeName(x.roleType.code) + "(Pending)" : this.userTypeName(x.roleType.code)} </Typography>
						</MenuItem>)}
				</Menu>
			</AppBar>
		);
	}
};

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {
		user: state.sessionReducer.user,
		fetch_user: state.userReducer.fetch_user,
		user_types: state.userReducer.user_types
	}
}

export default connect(mapStateToProps)(Topbar);