import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Typography, Grid, TextField, MenuItem, Dialog, IconButton } from "@mui/material";
import { fetchUserProfile, fetchUserProfiles } from "../../actions/userAction";
import { fetchIndividualTetResult, fetchTestResultsValues } from "../../actions/testAction";
import { logout } from "../../actions/sessionAction";
import { END_POINT, THEMECOLOR } from "../../helpers/Constants";
import moment from 'moment';
import User from "../../assets/images/account_circle.png";
import { fetchVaccineWithoutBooster } from '../../actions/vaccineAction';
import Covid from "../../assets/images/covid.png";
import Antibody from "../../assets/images/antibody.png";
import Vaccination from "../../assets/images/vaccination.png";
import CancelIcon from '@mui/icons-material/Cancel';
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { saveAs } from 'file-saver';
import theme from "../../theme";
import { withRouter } from "../../withRouter";

/** Created common styles for UI  */
const styles = {
    paper: {
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        minHeight: '100vh',
    },
    topContainer: {
        backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
        height: "200px",
        padding: 25,
        borderBottomLeftRadius: "10%",
        borderBottomRightRadius: "10%"
    },
    row: {
        display: "flex",
        justifyContent: "center",
    },
    bottomContainer: {
        backgroundColor: "#fff",
        borderRadius: 5,
        margin: "10px 30px",
        padding: "10px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s",
        marginBottom: 70,
    },
    userImage: {
        width: "100%",
        height: "100%",
        borderRadius: 15,
        objectFit: "cover"
    },
    textStyle: {
        fontSize: 17,
        color: "#fff",
        // paddingLeft: 10
    },
    textStyleBold: {
        fontSize: 17,
        color: "#fff",
        paddingLeft: 10,
        fontWeight: "bold"
    },
    divider: {
        width: "100%",
        height: 1,
        backgroundColor: "#D1CFCF",
        marginTop: 10
    },
    inputRoot: {
        backgroundColor: "#FFFFFF",
        border: "1px solid #E1E7ED",
        borderRadius: "4px",
        fontSize: "15px !important",
        fontFamily: "Lato-Regular !important",
        "&:hover": {
            border: "1px solid #0E465E",
            backgroundColor: "#FFFFFF"
        }
    },
    inputStyle: {
        fontFamily: "Lato-Regular !important",
        fontSize: "15px !important",
        padding: "10px !important",
        color: "#0E465E",
        opacity: 1,
        "&&:after": {
            color: "#0E465E",
        }
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    },
    itemContainer: {
        // boxShadow: "2px 2px 2px 2px #D1CFCF",
        marginTop:6,
        backgroundColor: "#EBECF0",
        borderRadius: 10,
        // margin: 10,
        padding: 15,
        // border: "1px solid red"
    },
    testTitle: {
        fontSize: 14
    },
    resultText: {
        fontSize: 15,
        fontWeight: "600",
    },
    dateText: {
        fontSize: 12
    },
    buttons: {
        padding: "5px 15px",
        borderRadius: 10,
        fontSize: 15,
        fontWeight: "bold",
        backgroundColor: '#ffffff',
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: '#ffffff',
        }
    },
    buttonStaticDiv: {
        position: "absolute",
        top: 10,
        right: 20
    },
    buttonDiv: {
        position: "relative",
        float: "right",
        paddingLeft: 10
    },
    dialogPaper: {
        overflow: "hidden"
    },
    deleteText: {
        cursor: "pointer",
        textAlign: "center",
        fontSize: 16,
        color: "#000",
        textTransform: "Capitalize",
        padding: "8px 15px"
    },
    closeIcon: {
        padding: 5,
        color: "#B22222",
        backgroundColor: "#ffffff",
        '&:hover': {
            backgroundColor: "#ffffff",
        }
    },
    verticalDivider: {
        marginTop: 5,
        width: 1,
        // height: "100%",
        backgroundColor: "#D1CFCF"
    },
};

/**
 * @class
 *
 * Class representing my activity component
 *
 */
class UserHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_profile: null,
            userType: null,
            selected_id: "",
            selectedUser: null,
            test_report_url: null,
            dialogOpen: false,
            imageDialog: false,
            selectedTest: null,
            imageUrl: null
        }
    }

    /** This is a component life cycle method and used to assigning props values to state variable  */
    static getDerivedStateFromProps(props, state) {
        if (props.user_profile !== state.user_profile) {
            return {
                user_profile: props.user_profile,
                userType: props.user_profile.userType
            }
        }
        return null;
    };

    /** This is a component life cycle method and used to fetch datas  */
    componentDidMount() {
        /** fetching user profile details. */
        this.props.dispatch(fetchUserProfile(this, "USERHOME"));
        /** fetching my activity details. */
        this.props.dispatch(fetchUserProfiles());
        /** fetching test result details. */
        this.props.dispatch(fetchTestResultsValues());
        this.props.dispatch(fetchVaccineWithoutBooster())
    };

    selectedUserTestResult = (event) => {
        let findUser = this.props.user_profiles.find(x => x.id === event.target.value)
        this.setState({ selected_id: event.target.value, selectedUser: findUser !== undefined ? findUser : null })
        this.props.dispatch(fetchIndividualTetResult(this, event.target.value))
    };

    onClickAction = () => {
        this.props.dispatch(logout());
    };

    handleClick = (test) => {
        if (test.pdfReportStorageKey !== null) {
            let test_report_url = `${END_POINT}pdf-test-report/${test.pdfReportStorageKey}#toolbar=0`
            this.setState({ test_report_url: test_report_url, dialogOpen: true })
        }
    };

    showVaccineResult = (test) => {
        let result = "-"
        if (this.props.vaccine !== null && test.vaccineResults.length > 0) {
            let vaccineId = test.vaccineResults[0].vaccineId;
            let findVaccine = this.props.vaccine.find(x => x.id === vaccineId);
            if (findVaccine !== undefined) {
                if (test.booster !== undefined) {
                    result = findVaccine.vaccineShots.length === test.shotNumber ? `Vaccinated${test.booster !== 0 ? ` + ${test.booster} Booster` : ''}` : "Partially Vaccinated"
                } else {
                    result = findVaccine.vaccineShots.length === test.shotNumber ? "Vaccinated" : "Partially Vaccinated"
                }
            }
        }
        return result;
    };

    openDialog = (test) => {
        this.setState({ imageDialog: true, selectedTest: test, imageUrl: `${END_POINT}image/${test.testType.isVaccine === true ? "v" : "t"}/${test.storageKey}` })
    };

    closeDialog = () => {
        this.setState({ imageDialog: false, selectedTest: null, imageUrl: null })
    };

    download = (test) => {
        if (test.isLabTest && test.pdfReportStorageKey !== null) {
            saveAs(`${END_POINT}pdf-test-report/${test.pdfReportStorageKey}`, 'document.pdf') // Put your url here.
        } else {
            saveAs(`${END_POINT}image/${test.testType.isVaccine === true ? "v" : "t"}/${test.storageKey}`, 'document.jpg') // Put your url here.
        }
    };

    /** rendering user profile and test result information. */
    render() {
        const { user_profile, user_profiles, individual_test_results } = this.props;
        let imageurl = (user_profile !== null && user_profile.imageStorageKey !== null) ? `${END_POINT}image/i/${user_profile.imageStorageKey}` : null;
        return (
            <div style={styles.paper}>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <div style={styles.topContainer}>
                            {/* <img
                                alt="Logo"
                                src={Logo}
                                height={20}
                                width={window.screen.width >= 1280 ? null : "30%"}
                            />
                            <div style={window.screen.width >= 550 ? styles.buttonStaticDiv : styles.buttonDiv}>
                                <Button
                                    type="Button"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={styles.buttons}
                                    onClick={() => this.onClickAction()}
                                >Logout
                                </Button>
                            </div> */}
                            <div style={styles.row}>
                                <div style={{
                                    width: "80px",
                                    height: "80px",
                                    borderBottomLeftRadius: 15,
                                    borderBottomRightRadius: 15,
                                    borderTopLeftRadius: 15,
                                    border: `5px solid ${THEMECOLOR.PRIMARY}`,
                                    backgroundColor: THEMECOLOR.PRIMARY
                                }}>
                                    {imageurl !== null ?
                                        <img
                                            alt="Logo"
                                            src={imageurl}
                                            style={styles.userImage}
                                        /> :
                                        <img src={User} height="80" width="80" alt="icon" style={{
                                            backgroundColor: "white", border: "1px solid #ddd",
                                            borderRadius: "10px", objectFit: "cover"

                                        }} />}
                                </div>
                                <div>
                                    <div style={{
                                        marginBottom: 5,
                                        backgroundColor: this.state.userType === "CLT" ? THEMECOLOR.PRIMARY : this.state.userType === "MSP" ? '#00B0F0' : '#9C1A8D',
                                        borderBottomRightRadius: 15,
                                        borderTopRightRadius: 15,
                                        border: this.state.userType === "CLT" ? `5px solid ${THEMECOLOR.PRIMARY}` : this.state.userType === "MSP" ? '5px solid #00B0F0' : '5px solid #9C1A8D',
                                    }}>
                                        <Typography style={styles.textStyle}>{this.state.userType !== null && (
                                            this.state.userType === "CLT" ?
                                                "User"
                                                :
                                                this.state.userType === "MSP" ?
                                                    "Medical Service Provider"
                                                    :
                                                    "Verifier")}
                                        </Typography>
                                    </div>
                                    <Typography style={styles.textStyleBold}>
                                        {user_profile !== null && `${user_profile.firstName} ${user_profile.lastName}`}
                                    </Typography>
                                </div>
                            </div>
                            {user_profiles.length > 1 && <Grid container justifyContent="center">
                                <Grid item xs={12} sm={6} md={3} style={{ padding: "10px 0px" }}>
                                    <Typography style={{ fontSize: 15, color: "#ffffff", padding: "10px 0px" }}>Select user to view the results</Typography>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id="select"
                                        name="select"
                                        autoComplete="off"
                                        required={true}
                                        value={this.state.selected_id}
                                        variant="filled"
                                        InputProps={{ classes: { input: styles.inputStyle, underline: styles.underline, root: styles.inputRoot } }}
                                        onChange={(event) => this.selectedUserTestResult(event)}
                                        select
                                    >
                                        {user_profiles.map(item => <MenuItem key={item.id} value={item.id}>{item.firstName + " " + item.lastName}</MenuItem>)}
                                    </TextField>
                                </Grid>
                            </Grid>}
                        </div>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={6} md={4}>
                                <div style={{
                                    backgroundColor: "#fff", borderRadius: 10, margin: "10px 30px", padding: "10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", transition: "0.3s",
                                    marginBottom: 70, marginTop: user_profiles.length > 1 ? 0 : -40
                                }}>
                                    <Typography style={{ fontSize: 17, paddingLeft: 10, color: "#000", padding: "5px 0px", fontWeight: "bold" }}>Quick View {this.state.selectedUser !== null ? `for ${this.state.selectedUser.firstName} ${this.state.selectedUser.lastName}` : ""}</Typography>
                                    {/* <div style={styles.divider} /> */}
                                    <div>
                                        {individual_test_results.length > 0 ?
                                            individual_test_results.sort((a, b) => Date.parse(b.displayDate) - Date.parse(a.displayDate)).map(test =>
                                                ((test.testType.isVaccine && test.vaccineResults.length > 0 && test.vaccineResults[0].vaccinationDate !== null) || (!test.testType.isVaccine && test.displayDate !== null && test.testResultItems.length > 0)) &&
                                                <div style={styles.itemContainer} key={test.id}>
                                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                                        <div>
                                                            {test.testType.isVaccine ?
                                                                <img src={Vaccination} width={30} height="auto" alt="Vaccination" />
                                                                : test.testType.isAntibody ?
                                                                    <img src={Antibody} width={30} height="auto" alt="Antibody" />
                                                                    : <img src={Covid} width={30} height="auto" alt="Covid" />}
                                                        </div>
                                                        <div style={{ paddingLeft: 10, width: "100%", display: "flex", justifyContent: 'space-between' }}>
                                                            <div>
                                                                <div>
                                                                    {/* {test.testType.isVaccine === true ?
                                                            <Typography style={styles.testTitle}>{test.testType.name} {test.shotNumber !== null ? ("Shot n°" + test.shotNumber) : ""}</Typography>
                                                            :
                                                            <Typography style={styles.testTitle}>{test.testType.name} </Typography>
                                                        } */}
                                                                    <Typography style={styles.testTitle}>{test.testType.name} </Typography>
                                                                </div>
                                                                <div >
                                                                    {/* {test.testType.type === "TEST" && 
                                                            (test.testType.isAntibody === true ?
                                                                <Typography style={styles.resultText}>
                                                                    {test.testResultItems.length > 0 ? test.testResultItems[0].result_value === "POSITIVE" ? "Antibody Detected" : "No Antibody Detected" : "-"}
                                                                </Typography>
                                                                :
                                                                <Typography style={{ fontSize: 15, fontWeight: "600", color: test.testResultItems.length > 0 && test.testResultItems[0].result_value === "INCONCLUSIVE" ? "red" : "#000"}}>
                                                                    {test.testResultItems.length > 0 ? test.testResultItems[0].result_value === "POSITIVE" ? "Detected" : test.testResultItems[0].result_value === "NEGATIVE" ? "Not Detected" : test.testResultItems[0].result_value === "INCONCLUSIVE" ? "Invalid" : test.testResultItems[0].result_value === "PENDING" ? "Pending" : "-" : "-"}
                                                                </Typography>
                                                            )} */}
                                                                    {test.testType.type === "TEST" && test.testType.isVaccine === false && test.displayDate !== null && test.testResultItems.length > 0 &&
                                                                        <div>
                                                                            <Typography style={{ fontSize: 15, fontWeight: "600", color: test.testResultItems.length > 0 && (test.testResultItems[0].result_value === "INCONCLUSIVE" || (test.testResultItems[0].result_value === "POSITIVE" && test.testType.isAntibody === false)) ? "red" : "#000" }}>
                                                                                {test.testResultItems.length > 0 ? test.testResultItems[0].result_value === "POSITIVE" ? "Detected" : test.testResultItems[0].result_value === "NEGATIVE" ? "Not Detected" : test.testResultItems[0].result_value === "INCONCLUSIVE" ? "Invalid" : test.testResultItems[0].result_value === "PENDING" ? "Pending" : "-" : "-"}
                                                                            </Typography>
                                                                            <Typography style={styles.dateText}>Test Date: {test.displayDate !== null ? `${moment.utc(test.displayDate, 'YYYY-MM-DD').format('MMM DD, YYYY')}` : "----"}</Typography>
                                                                        </div>
                                                                    }
                                                                    {test.testType.type === "VACCINE" && test.testType.isVaccine === true && test.vaccineResults.length > 0 && test.vaccineResults[0].vaccinationDate !== null &&
                                                                        <div >
                                                                            <Typography style={{ fontSize: 15, fontWeight: "600", color: "#000" }}>
                                                                                {this.showVaccineResult(test)}
                                                                            </Typography>
                                                                            {
                                                                                (test.boosterDate !== undefined && test.boosterDate !== null) ?
                                                                                    <Typography style={styles.dateText}>Vaccination Date: {test.boosterDate !== null ? `${moment.utc(test.boosterDate, 'YYYY-MM-DD').format('MMM DD, YYYY')}` : ""}</Typography>
                                                                                    :
                                                                                    <Typography style={styles.dateText}>Vaccination Date: {test.vaccineResults[0].vaccinationDate !== null ? `${moment.utc(test.vaccineResults[0].vaccinationDate, 'YYYY-MM-DD').format('MMM DD, YYYY')}` : ""}</Typography>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {/* <div>
                                                            {test.pdfReportStorageKey !== null && test.testResultItems.length > 0 && test.testResultItems[0].result_value !== "PENDING" &&
                                                                <IconButton onClick={() => this.handleClick(test)}>
                                                                    <NoteAddIcon color="primary" />
                                                                </IconButton>
                                                            }
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                    <div style={styles.divider} />
                                                    {(test.isLabTest && test.pdfReportStorageKey !== null) && <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography>
                                                        <div style={styles.verticalDivider} />
                                                        <Typography style={styles.deleteText} onClick={() => this.download(test)}>Download</Typography>
                                                    </div>}
                                                    {(test.isLabTest === false && test.storageKey !== null) && <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography>
                                                        <div style={styles.verticalDivider} />
                                                        <Typography style={styles.deleteText} onClick={() => this.download(test)}>Download</Typography>
                                                    </div>}
                                                </div>)
                                            :
                                            <div style={{ display: "flex", alignItems: "center", height: "70vh", justifyContent: "center" }} >
                                                <Typography style={styles.emptyText} > There are no test results</Typography>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog maxWidth={"xs"} fullWidth open={this.state.imageDialog} onClose={() => this.setState({ imageDialog: false })}>
                    {this.state.selectedTest !== null && <div style={{ overflow: "hidden" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: 5, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                            <Typography style={{ display: "flex", alignItems: "center", color: '#fff' }}>
                                {this.state.selectedTest !== null ?
                                    `${this.state.selectedTest.testType.name}  `
                                    //  ${this.state.selectedTest.testType.isVaccine ? (this.state.selectedTest.vaccineResults.length > 0 && this.state.selectedTest.vaccineResults[0].vaccinationDate !== null) ? `${moment.utc(this.state.selectedTest.vaccineResults[0].vaccinationDate, 'YYYY-MM-DD').format("MM/DD/YYYY")}` : "" : this.state.selectedTest.displayDate !== null ? `${moment.utc(this.state.selectedTest.displayDate, 'YYYY-MM-DD').format("MM/DD/YYYY")}` : ""}
                                    : ""}
                            </Typography>
                            <IconButton onClick={() => this.closeDialog()} style={styles.closeIcon}>
                                <CancelIcon fontSize="medium" />
                            </IconButton>
                        </div>
                        {this.state.selectedTest.isLabTest === true ?
                            this.state.selectedTest.pdfReportStorageKey !== null ?
                                <iframe title={`${this.state.selectedTest.pdfReportStorageKey}`} src={`${END_POINT}pdf-test-report/${this.state.selectedTest.pdfReportStorageKey}#toolbar=0`} style={{ width: "100%", height: "90vh" }} />
                                :
                                <Typography style={{ display: "flex", alignItems: "center", height: "40vh", justifyContent: "center" }}>There is no document to view</Typography>
                            : <TransformWrapper>
                                <TransformComponent>
                                    <img src={this.state.imageUrl} alt="test-img" style={{ width: "100%", height: "auto" }} />
                                </TransformComponent>
                            </TransformWrapper>}
                    </div>}
                </Dialog>
                {/* <Dialog maxWidth={"md"} open={this.state.dialogOpen} fullWidth
                    onClose={() => this.setState({ dialogOpen: false })}
                    classes={{
                        paper: classes.dialogPaper,
                    }}>
                    <iframe src={this.state.test_report_url} width="100%" height="500px" />
                </Dialog> */}
            </div>
        );
    }
};

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
    return {
        user_profile: state.userReducer.user_profile,
        user_profiles: state.userReducer.user_profiles,
        individual_test_results: state.testReducer.individual_test_results,
        vaccine: state.vaccineReducer.vaccine
    };
};

UserHome.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(UserHome));