import React, { Component } from "react";
import { Typography, TextField, Button, Grid, InputAdornment, IconButton, FormHelperText } from "@mui/material";
import { login } from "../../actions/sessionAction";
import { connect } from "react-redux";
import Logo from '../../assets/images/medfilo.png';
import { Link } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import theme from "../../theme";
import { withRouter } from "../../withRouter";
import { THEMECOLOR } from "../../helpers/Constants";

const styles = {
    paper: {
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        height: '1vh'
    },
    topContainer: {
        backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderBottomLeftRadius: "10%",
        borderBottomRightRadius: "10%"
    },
    bottomContainer: {
        margin: "0px 10px",
        backgroundColor: "#fff",
        borderRadius: 10,
        padding: "18px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s",
    },
    titleHead: {
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "center",
        padding: 5
    },
    titleText: {
        fontSize: 15,
        color: "#444444",
        textAlign: "center",
        padding: "0px 15px"
    },
    buttonContainer: {
        padding: 25,
        paddingTop: 35
    },
    button: {
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
        marginTop: 5,
        padding: 7,
        fontSize: 15,
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        fontWeight: "bold",
        borderRadius: 5,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    linkText: {
        color: theme.palette.primary.main,
        textDecoration: "underline",
        cursor: "pointer",
    },
    bottomTextContainer: {
        padding: 20,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    toolbar: {
        padding: 0,
        margin: 0,
        justifyContent: "center",
        minHeight: "0px"
    },
    inputStyle: {
        fontSize: "15px !important",
        padding: "5px !important",
    },
    inputRoot: {
        borderRadius: "0px !important"
    },
};

class Login extends Component {
    constructor() {
        super();
        this.state = {
            mobile_number: "",
            mobile_number_error: false,
            password: "",
            password_error: false,
            isProcessing: false,
            invalid_mobile_number_error: false,
            loginError: null,
            openAlert: false,
            severity: "",
            alertMessage: "",
            secureEntry: true,
        }
    };

    onSubmit = () => {
        let isError = false;

        if (this.state.mobile_number === "" || this.state.mobile_number === null) {
            this.setState({ mobile_number_error: true })
            isError = true;
        } else if (this.state.mobile_number.length < 9 || this.state.mobile_number.length > 10) {
            this.setState({ invalid_mobile_number_error: true })
            isError = true;
        }

        if (this.state.password === "" || this.state.password === null) {
            this.setState({ password_error: true })
            isError = true;
        }

        if (isError === false) {
            this.setState({ isProcessing: true })
            let dataObj = {};
            dataObj.phone = this.state.mobile_number;
            dataObj.password = this.state.password;
            this.props.dispatch(login(dataObj, this))
        }
    };

    handleClickShowPassword = () => {
        this.setState({ secureEntry: !this.state.secureEntry })
    };

    render() {
        return (
            <div style={styles.paper}>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <div style={styles.topContainer}>
                            <img
                                alt="Logo"
                                src={Logo}
                                height={150}
                                width={150}
                                style={{ marginTop: -60 }}
                            // height={window.screen.width >= 1280 ? 200 : 40}
                            // width={window.screen.width >= 1280 ? 200 : 50}
                            />
                        </div>
                        <Grid container style={{ marginTop: -30 }} justifyContent="center">
                            <div style={styles.bottomContainer}>
                                <Typography style={styles.titleHead}>Log In</Typography>
                                <Typography style={styles.titleText}>Enter your phone number and password to log in</Typography>
                                <form onSubmit={this.onSubmit}>
                                    <TextField
                                        required
                                        fullWidth
                                        autoFocus
                                        margin="normal"
                                        color={"primary"}
                                        variant="standard"
                                        id="mobile-number"
                                        label="Phone Number"
                                        name="mobile-number"
                                        autoComplete="off"
                                        value={this.state.mobile_number}
                                        onChange={event => this.setState({ mobile_number: event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'), mobile_number_error: false, invalid_mobile_number_error: false, loginError: null })}
                                        error={this.state.mobile_number_error}
                                        onKeyDown={e => {
                                            if (e.key === "Enter") {
                                                this.onSubmit();
                                            }
                                        }}
                                    />
                                    {this.state.mobile_number_error &&
                                        <FormHelperText style={{ color: theme.palette.secondary.error, marginLeft: "5px" }}>Please enter mobile number</FormHelperText>
                                    }
                                    {this.state.invalid_mobile_number_error &&
                                        <FormHelperText style={{ color: theme.palette.secondary.error, marginLeft: "5px" }}>Please enter valid mobile number</FormHelperText>
                                    }
                                    <TextField
                                        required
                                        fullWidth
                                        id="password"
                                        label="Password"
                                        name="password"
                                        color={"primary"}
                                        variant="standard"
                                        type={this.state.secureEntry ? "password" : "text"}
                                        margin="normal"
                                        value={this.state.password}
                                        onChange={event =>
                                            this.setState({ password: event.target.value, password_error: false, loginError: null })
                                        }
                                        autoComplete="off"
                                        error={this.state.password_error}
                                        InputProps={{
                                            styles: { input: styles.inputStyle, root: styles.inputRoot },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.handleClickShowPassword}
                                                    >
                                                        {this.state.secureEntry ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        onKeyDown={e => {
                                            if (e.key === "Enter") {
                                                this.onSubmit();
                                            }
                                        }}
                                    />
                                    {this.state.password_error &&
                                        <FormHelperText style={{ color: theme.palette.secondary.error, marginLeft: "10px" }}>Please enter Password</FormHelperText>
                                    }
                                    {this.state.loginError !== null &&
                                        <Typography align="center" style={{ color: "red", fontSize: 15 }}>{this.state.loginError}</Typography>
                                    }
                                    <div style={styles.buttonContainer}>
                                        <Button
                                            fullWidth
                                            type="Button"
                                            variant="contained"
                                            onClick={() => this.onSubmit()}
                                            style={styles.button}
                                        >
                                            Login
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </Grid>
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={12} style={styles.bottomTextContainer}>
                                <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                    <Typography style={{ paddingRight: 10 }}>Don't have an account? </Typography>
                                    <Link style={styles.linkText} to="/register">Sign Up</Link>
                                </Grid>
                                <Grid item xs={6} justifyContent={"center"}>
                                    <Link style={styles.linkText} to="/forgot-password"><Typography >Forgot Password</Typography></Link>
                                </Grid>
                                <Grid item xs={7} style={{ justifyContent: "center", marginTop: 10 }}>
                                    <Link style={{ ...styles.linkText, fontStyle: "italic" }} to="/delete-account"><Typography>Request account deletion</Typography></Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div >
        )
    }
};

const mapStateToProps = (state) => {
    return {

    };
};

export default withRouter(connect(mapStateToProps)(Login));
