import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Toolbar, AppBar, Link } from '@mui/material';
import Logo from "../../assets/images/medfilo.png";
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from "redux";
import theme from "../../theme"

const styles = {
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
};

class SessionExpired extends Component {
  
    componentDidMount() {
    
    }

  render() {
  return (
      <div style={styles.root}>
        <AppBar
          color="default"
          style={{
            boxShadow: 'none',
            background: `${theme.palette.primary.dark}`
          }}
        >
          <Toolbar>
            <RouterLink to="/">
              <img
                alt="Logo"
                src={Logo}
                height={60}
              />
            </RouterLink>
          </Toolbar>
        </AppBar>
        <Grid
          container
          justifyContent="center"
          spacing={4}
        >
          <Grid
            item
            lg={8}
            xs={12}
          >
            <div style={styles.content}>
              <Typography variant="h5" style={{ fontFamily: "Futura-Heavy" }}>
                Your Session has Expired
              </Typography>
              <div style={{ height: 2 }}></div>
              <Typography variant="h8" style={{ fontFamily: "Futura-Book" }}>
                 Please sign in to your MedFilo account <Link variant="contained" color="primary" style={{ color: "#12bcdf", textTransform: "initial", fontFamily: "Futura-Heavy", textDecoration: "underline" }} href="/">go to home</Link>
              </Typography>
              <div style={{ height: 13 }}></div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

SessionExpired.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default compose(connect(null)(SessionExpired));