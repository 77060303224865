import api from '../api';
import { FETCH_TESTTYPE, SELECTED_TEST_TYPE, ALERT_DETAIL, ALREADY_VACCINATED_TEST, FETCH_TEST_TYPES, FETCH_VACCINE_TEST_TYPES } from "./actionTypes";
import _ from 'underscore';

export function findFloatType(paginationCount) {
    return !!(paginationCount % 1);
}

export function fetchTesttype() {
    return function (dispatch) {
        api.get('test-type').then(async (response) => {
            if (response.status === "success") {
                let testTypes = response.payload !== null ? response.payload.filter(x => x.status !== "DISABLE") : []
                await dispatch({
                    type: FETCH_TESTTYPE,
                    data: testTypes
                });
            }
        });
    }
};

export function fetchTestTypeByLimit(self, offset) {
    return function (dispatch) {
        api.get(`test-type/v2/limit?offset=${offset}&limit=${50}`).then(async (response) => {
            if (response.status === "success") {
                let isFloat = findFloatType(response.paginationCount);
                self.setState({ paginationCount: isFloat ? parseInt(response.paginationCount) + 1 : response.paginationCount })
                await dispatch({ type: FETCH_TESTTYPE, data: response.payload });
            }
        });
    }
}

export function fetchAllTesttype() {
    return function (dispatch) {
        api.get(`test-type?fetchquery=${"ALL"}`).then(async (response) => {
            if (response.status === "success") {
                await dispatch({
                    type: FETCH_TESTTYPE,
                    data: response.payload
                });
            }
        });
    }
};

export function fetchForTestTypes() {
    return function (dispatch) {
        api.get("test-type/test-record-by-type/T").then(async (response) => {
            if (response.status === "success") {
                dispatch({ type: FETCH_TEST_TYPES, data: response.payload });
            }
        });
    }
};

/**  
 * This action creator used for get test type records from server
 * @function 
 */
export function fetchForVaccine() {
    return function (dispatch) {
        api.get("test-type/test-record-by-type/V").then(async (response) => {
            if (response.status === "success") {
                dispatch({ type: FETCH_VACCINE_TEST_TYPES, data: response.payload });
            }
        });
    }
};

export function addTesttype(self, item, testTypeItems, selectedVaccines) {
    return dispatch => {
        api.post('test-type', item).then(async (response) => {
            if (response.status === "success") {
                if (testTypeItems.length > 0) {
                    for (let testTypeItem of testTypeItems) {
                        let data = {}
                        data.testTypeId = response.payload.id
                        data.name = testTypeItem.name
                        data.valueType = testTypeItem.valueType
                        data.minimumBound = testTypeItem.minimumBound
                        data.maximumBound = testTypeItem.maximumBound
                        data.description = testTypeItem.description
                        data.status = testTypeItem.status
                        await api.post('test-type-item', data).then(async (response) => {
                            if (response.status === "success") {
                                self.setState({ isLoading: false, })
                                // await dispatch(fetchTesttype())
                            }
                            else {
                                self.setState({ isLoading: false, })
                                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
                            }
                        })
                    }
                }

                if (selectedVaccines.length > 0) {
                    let vaccineData = {};
                    vaccineData.testTypeId = response.payload.id;
                    vaccineData.vaccineIds = _.pluck(selectedVaccines, 'id');

                    await api.post('vaccine-tag/bulk-create', vaccineData).then(async (tagResponse) => {
                        if (tagResponse.status === "success") {
                            self.setState({ isLoading: false, })
                            // await dispatch(fetchTesttype())
                        }
                        else {
                            self.setState({ isLoading: false, })
                            dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
                        }
                    });
                }

                await dispatch(fetchAllTesttype())
                dispatch({ type: ALERT_DETAIL, data: true, message: "Test type added successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    dialogOpen: false,
                    id: null,
                    testTypeName: "",
                    testTypeName_error: false,
                    type: "",
                    type_error: false,
                    isVaccine: "",
                    isVaccine_error: false,
                    isCovid: "",
                    isCovid_error: false,
                    isAntibody: "",
                    isAntibody_error: false,
                    testTypeDescription: "",
                    testTypeDescription_error: false,
                    testTypeStatus: "PENDING",
                    testTypeStatus_error: false,
                    mode: "",
                    taggedVaccines: [],
                    selectedVaccines: [],
                    removedVaccines: []
                })
            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
};

export function updateTesttype(self, item, id, testTypeItems, selectedVaccines, removedVaccines) {
    return dispatch => {
        api.put('test-type/' + id, item).then(async (response) => {
            if (response.status === "success") {
                if (testTypeItems.length > 0) {
                    for (let testTypeItem of testTypeItems) {

                        let data = {}
                        data.testTypeId = response.payload.id;
                        data.name = testTypeItem.name;
                        data.valueType = testTypeItem.valueType;
                        data.minimumBound = testTypeItem.minimumBound;
                        data.maximumBound = testTypeItem.maximumBound;
                        data.description = testTypeItem.description;
                        data.status = testTypeItem.status;

                        await api.put('test-type-item/' + testTypeItem.id, data).then(async (response) => {
                            if (response.status === "success") {
                                self.setState({ isLoading: false, })
                                // await dispatch(fetchTesttype())
                            }
                            else {
                                self.setState({ isLoading: false, })
                                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
                            }
                        })
                    }
                }
                if (selectedVaccines.length > 0 || removedVaccines.length > 0) {
                    let vaccineData = {};
                    vaccineData.testTypeId = response.payload.id;
                    vaccineData.vaccineIds = _.pluck(selectedVaccines, 'id');
                    vaccineData.removedVaccineIds = _.pluck(removedVaccines, 'id');
                    await api.put('vaccine-tag/bulk-update', vaccineData).then(async (tagResponse) => {
                        if (tagResponse.status === "success") {
                            self.setState({ isLoading: false, })
                            // await dispatch(fetchTesttype())
                        }
                        else {
                            self.setState({ isLoading: false, })
                            dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
                        }
                    });
                }

                await dispatch(fetchAllTesttype());
                dispatch({ type: ALERT_DETAIL, data: true, message: "Test type updated successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    dialogOpen: false,
                    id: null,
                    testTypeName: "",
                    testTypeName_error: false,
                    type: "",
                    type_error: false,
                    isVaccine: "",
                    isVaccine_error: false,
                    isCovid: "",
                    isCovid_error: false,
                    isAntibody: "",
                    isAntibody_error: false,
                    testTypeDescription: "",
                    testTypeDescription_error: false,
                    testTypeStatus: "PENDING",
                    testTypeStatus_error: false,
                    mode: ""
                })
            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
};

export function fetchAlreadyVaccinated(self, data, clientID) {
    return async dispatch => {
        api.get(`test-type/${data.id}/user-test-type/${clientID}`).then(async (response) => {
            if (response.status === "success") {
                if (response.payload.isAllowNextRecord === true) {
                    localStorage.setItem("testType", JSON.stringify(data))
                    dispatch({ type: SELECTED_TEST_TYPE, data: data })
                    dispatch({ type: ALREADY_VACCINATED_TEST, data: response.payload })
                    self.props.navigate("/upload-test-type-image")
                } else {
                    dispatch({ type: ALERT_DETAIL, data: true, message: response.payload.recordMessage, severity: "error" })
                }
            }
        });
    }
};