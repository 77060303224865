import React, { Component } from "react";
import {
    Typography, TextField, Table, TableHead, TableBody, TableCell, TableRow, Tabs, Tab, Grid, Chip, Popover, List, ListItem, Divider,
    Dialog, DialogTitle, DialogContent, Button, DialogActions, InputAdornment, MenuItem, CircularProgress, FormControl, FormLabel, RadioGroup,
    FormControlLabel, Radio
} from "@mui/material";
import { connect } from "react-redux";
import { searchAllUser, updateStatusUser, findExistingUserTypes, revokeUserRole } from '../../actions/userAction';
import { FETCH_USER_BY_PHONE, USER_PROFILE_TEST_RESULT, FETCH_SEARCH_USER } from "../../actions/actionTypes";
import MoreVert from '@mui/icons-material/MoreVert';
import QRCode from "react-qr-code";
import { fetchTestByUserProfileId, updateUserVaccines } from "../../actions/testAction";
import { resendSmsToUser } from '../../actions/registerAction';
import moment from "moment";
import { findExistingUser, updateUserRole } from "../../actions/memberAction";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { withRouter } from "../../withRouter";
import theme from "../../theme";

/**Created common styles for UI.*/
const styles = {
    inputStyle: {
        fontSize: "15px !important",
        padding: "5px !important",
        width: 300
    },
    inputRoot: {
        borderRadius: "0px !important"
    },
    inputLabel: {
        color: theme.palette.primary.main
    },
    button: {
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
        margin: 15,
        padding: "20px 25px",
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        fontWeight: "bold",
        borderRadius: 5,
        '&:hover': {
            backgroundColor: "#4B86E3",
        },
    },
    buttonIcon: {
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
        margin: 15,
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        fontWeight: "bold",
        borderRadius: 5,
    },
    clearButton: {
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
        margin: 15,
        padding: "10px 25px",
        fontSize: 12,
        backgroundColor: "#808080",
        color: "#fff",
        fontWeight: "bold",
        borderRadius: 5,
        '&:hover': {
            backgroundColor: "#808080",
        },
    },
    fontCellHeader: {
        fontFamily: "Futura-Book",
        color: "#fff",
        fontWeight: 900,
        // padding: '14px 20px 14px 0px',
    },
    tableHeader: {
        color: "#fff"
    },
    fontCellBody: {
        fontFamily: "Futura-Medium-bt",
        color: "#37474f",
        // padding: '14px 20px 14px 0px',
    },
    addButton: {
        color: '#ffff'
    },
    cancelButton: {
        color: '#ffff'
    }
};

// const CssTextField = withStyles({
//     root: {
//         '& label.Mui-focused': {
//             color: '#4B86E3',
//         },
//         '& .MuiInput-underline:after': {
//             borderBottomColor: '#4B86E3',
//         },
//         '& .MuiOutlinedInput-root': {
//             '& fieldset': {
//                 borderColor: '#4B86E3',
//             },
//             '&:hover fieldset': {
//                 borderColor: '#4B86E3',
//             },
//             '&.Mui-focused fieldset': {
//                 borderColor: '#4B86E3',
//             },
//         },
//     },
// })(TextField);

/**
 * @class
 *
 * Class representing UserAndAccess component
 *
 */
class UserAndAccess extends Component {
    constructor() {
        super();
        this.state = {
            search_text: "",
            rowsPerPage: 10,
            page: 0,
            selectedUser: null,
            dialogOpen: false,
            vaccinDialog: false,
            selected_user_profile_id: null,
            isConfirmationDialog: false,
            deleteConfirmationDialog: false,
            moreButtonAction: false,
            anchorEl: null,
            popoverId: null,
            selectedTab: 0,
            roleDialog: false,
            search_phone: "",
            invalid_search_phone_error: false,
            search_phone_error: false,
            primary_contact_first_name: "",
            primary_contact_first_name_error: false,
            primary_contact_last_name: "",
            primary_contact_last_name_error: false,
            primary_contact_email: "",
            primary_contact_email_error: false,
            invalid_primary_contact_email_error: false,
            primary_contact_country_code: "+1",
            selected_role: "ADM",
            role_error: false,
            phone: "",
            zip_code: "",
            isLoading: false,
            removeDialog: false,
            userRoleTypes: [],
            userDetails: null,
            selectedRoleId: null,
            access_role_error: false
        }
    }

    /** This is a component life cycle method and used to fetch datas  */
    componentDidMount = () => {
        this.props.dispatch({ type: FETCH_USER_BY_PHONE, data: null })
        /** fetching users details. */
        // this.props.dispatch(fetchAllUsers())
    }

    handleChange = (event, newValue) => {
        this.setState({
            selectedTab: newValue,
            search_text: "",
            phone: "",
            zip_code: ""
        })
        this.props.dispatch({ type: FETCH_SEARCH_USER, data: [] })
    };

    addNewRole = () => {
        this.setState({
            roleDialog: true, search_phone: "", userRoleTypes: [], access_role_error: false
        })
    }

    revokeExistingRole = () => {
        this.setState({
            removeDialog: !this.state.removeDialog, search_phone: "", userRoleTypes: [], userDetails: null, selectedRoleId: null
        });
    }
    /**
      * @function
      *
      * function representing table pagination.
      * @param {Object} _event
      * @param {number} newPage
      */
    handleChangePage = (_event, newPage) => {
        this.setState({
            page: newPage
        });
    }

    /**
     * @function
     *
     * function representing table pagination rows.
     * @param {Object} event
     */
    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0, rowsPerPage: parseInt(event.target.value, 10)
        });
    }

    /**
     * @function
     *
     * function representing click event select user.
     * @param {Object} event 
     * @param {Object} user 
     */
    handleClick = (event, user) => {
        this.setState({ selectedUser: user })
        this.setState({ anchorEl: event.currentTarget, moreButtonAction: Boolean(event.currentTarget), popoverId: Boolean(event.currentTarget) ? 'simple-popover' : undefined });
    };

    /**
     * @function
     *
     * function representing click event open menu.
     * @param {Object} event 
     */
    handleClose = (event) => {
        this.setState({ anchorEl: event.currentTarget, moreButtonAction: false, popoverId: Boolean(event.currentTarget) ? 'simple-popover' : undefined });
    };

    /**
     *@function
     * This function representing to shows vaccine details.
     * @param {Number} id The id Number
     */
    showTestDetails = (id) => {
        this.props.dispatch(fetchTestByUserProfileId(id))
        this.setState({
            vaccinDialog: true,
            selected_user_profile_id: id, moreButtonAction: false
        })
    }

    /**
     *@function
     * This function representing resend SMS to user.
     * @param {Number} phone The phone Number
     */
    resendSMS = (phone) => {
        this.props.dispatch(resendSmsToUser(phone))
    }

    /**
     * @function
     * This function representing close vaccine deatails dialog screen.
     */
    closeDialog = () => {
        this.setState({ vaccinDialog: false, selected_user_profile_id: null })
        this.props.dispatch({ type: USER_PROFILE_TEST_RESULT, data: [] })
    }

    /**
     * @function
     *
     * function representing update user vaccine details.
     *
     */
    updateShots = () => {
        if (this.state.selected_user_profile_id !== null) {
            this.props.dispatch(updateUserVaccines(this.state.selected_user_profile_id, this))
        }
    }

    /**
     * @function
     *
     * function representing update vaccine details confirmation dialog screen will open.
     *
     */
    confirmationDialog = () => {
        this.setState({
            isConfirmationDialog: true
        })
    }

    /**
     * @function
     *
     * function representing close confirmation dialog screen.
     * @param {Object} user
     */
    deleteConfirmationAction = (user) => {
        this.setState({ deleteConfirmationDialog: true, selectedUser: user, moreButtonAction: false })
    }

    /**
     * @function
     *
     * function representing close confirmation dialog screen.
     * @param {Object} param
     */
    deleteConfirmationButtonAction = (param) => {
        if (param === "Y") {
            this.setState({ deleteConfirmationDialog: false })
            let dataObj = {};
            dataObj.hashedUserId = this.state.selectedUser.hashedUserId;
            dataObj.updateUserStatus = this.state.selectedUser.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
            this.props.dispatch(updateStatusUser(this.state.selectedUser.id, dataObj))
        } else {
            this.setState({ deleteConfirmationDialog: false })
        }

    }

    roleAction = (role) => {
        return <Chip
            size="small"
            label={role.roleType.code === "CLT" ? "Individual" : role.roleType.name}
            color={"primary"}
            clickable={false}
            style={{ padding: "5px 15px", margin: "10px 10px" }}
        />
    }

    handleRoleDialogClose = () => {
        this.setState({
            roleDialog: false,
            search_phone: "",
            invalid_search_phone_error: false,
            search_phone_error: false,
            primary_contact_first_name: "",
            primary_contact_first_name_error: false,
            primary_contact_last_name: "",
            primary_contact_last_name_error: false,
            primary_contact_email: "",
            primary_contact_email_error: false,
            invalid_primary_contact_email_error: false,
            primary_contact_country_code: "+1"
        })
    }

    /**
 * @function
 * This function representing email validation.
 * @param {String} email The email string
 */
    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    /**
* @function
* This function representing Number validation.
* @param {Number} number The number Number
*/
    checkNumber = (number) => {
        var isNumber = /^-{0,1}\d+$/.test(number)
        return isNumber;
    }
    /**
* @function
* This function representing  searchUser.
*/
    searchUser = () => {
        if (this.state.search_phone !== "" && this.state.search_phone !== null) {
            this.props.dispatch(findExistingUser(this, this.state.search_phone))
        }
    }

    searchUserType = () => {
        if (this.state.search_phone !== "" && this.state.search_phone !== null) {
            this.props.dispatch(findExistingUserTypes(this, this.state.search_phone))
        }
    }

    /**
     * @function
     * This function representing add or edit organization.
     */
    handleSubmit = () => {
        let { primary_contact_first_name, primary_contact_last_name, primary_contact_email, search_phone, selected_role,
            primary_contact_country_code, userRoleTypes } = this.state;

        let isError = false;

        if (primary_contact_first_name === "" || primary_contact_first_name === null) {
            this.setState({ primary_contact_first_name_error: true, })
            isError = true;
        }
        if (primary_contact_last_name === "" || primary_contact_last_name === null) {
            this.setState({ primary_contact_last_name_error: true, })
            isError = true;
        }
        if (primary_contact_email === "" || primary_contact_email === null) {
            this.setState({ primary_contact_email_error: true, })
            isError = true;
        }
        if (primary_contact_email !== null && primary_contact_email !== "") {
            if (!this.validateEmail(this.state.primary_contact_email)) {
                this.setState({ invalid_primary_contact_email_error: true })
                isError = true
            }
        }
        if (search_phone === "" || search_phone === null) {
            this.setState({ search_phone_error: true })
            isError = true;
        } else {
            if (this.checkNumber(search_phone) !== true || search_phone.length !== 10) {
                this.setState({ invalid_search_phone_error: true })
                isError = true
            }
        }

        if (selected_role === "" || selected_role === null) {
            this.setState({ role_error: true, })
            isError = true;
        }

        if (selected_role === "MSP") {
            let filterAccessUserRole = userRoleTypes.filter(x => x.roleType.code === "VFR");
            if (filterAccessUserRole.length > 0) {
                this.setState({ access_role_error: true })
                isError = true;
            }
        }

        if (isError === false) {
            let userObj = {};
            userObj.country_code = primary_contact_country_code
            userObj.phone_number = search_phone;
            userObj.role_code = selected_role;
            this.props.dispatch(updateUserRole(this, userObj))
        }
    }

    handleRevokeSubmit = () => {
        let { userDetails, selectedRoleId } = this.state;
        if (!!userDetails && !!selectedRoleId) {
            this.props.dispatch(revokeUserRole(this, userDetails.id, selectedRoleId))
        }
    }

    searchAllUser = () => {
        if (this.state.phone !== "" && this.state.phone !== null) {
            this.setState({ isLoading: true })
            this.props.dispatch(searchAllUser(this, this.state.phone))
        }
        //else if (this.state.phone === "" && this.state.phone === null) {
        // this.props.dispatch({ type: ALERT_DETAIL, message: "No User Found", severity: "Error" })
        //<Typography style={{ fontWeight: "bold", color: "#000", backgroundColor: "red", border: "1px solid red" }}>No User Found</Typography>
        //}
    }

    clear = () => {
        this.props.dispatch({ type: FETCH_SEARCH_USER, data: [] })
        this.setState({
            zip_code: "",
            phone: ""
        })
    }

    /** rendering Dashboard information. */
    render() {
        // const { styles } = this.props;
        const { page, rowsPerPage, selectedTab } = this.state;
        let totalUsers = [];
        if (selectedTab === 0) {
            totalUsers = this.props.search_all_user.filter(x => x.roleTypes.find(y => y.roleType.code === "CLT"));
        } else {
            totalUsers = this.props.search_all_user.filter(x => x.roleTypes.find(y => y.roleType.code !== "CLT"))
        }
        let result = totalUsers.sort((a, b) => a.id - b.id)
        // let result = this.state.search_text !== '' ? users.filter(x => (x.phone).toString() === (this.state.search_text).toString() || (x.email).toString() === (this.state.search_text).toString() || (x.zipCode !== null ? x.zipCode.toString() : "") === (this.state.search_text).toString()) : users
        return (
            <div style={styles.paper}>
                <Tabs
                    value={this.state.selectedTab}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    fontWeight="900"
                    style={{ margin: 0, width: 400, backgroundColor: 'white' }}
                >
                    <Tab
                        label={<span style={{ fontSize: "16", color: theme.palette.secondary.light, fontWeight: "bold" }}>Users</span>}>
                    </Tab>
                    <Tab
                        label={<span style={{ fontSize: "16", color: theme.palette.secondary.light, fontWeight: "bold" }}>Access</span>}>
                    </Tab>
                </Tabs>
                <Grid item xs={8} sm={12} style={{ paddingTop: 0 }}>
                    {this.state.selectedTab === 0 &&
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between", margin: "0px 15px" }}>
                                {/* <TablePagination
                                    rowsPerPageOptions={[20]}
                                    component="div"
                                    count={result.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    slotProps={{
                                        select: {
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    labelRowsPerPage={null}
                                    labelDisplayedRows={
                                        () => null
                                    }
                                    ActionsComponent={
                                        TablePaginationActions
                                    }
                                /> */}
                                <div style={{ display: "flex", justifyContent: "space-between", width: "50%" }}>
                                    <TextField
                                        margin="normal"
                                        variant="standard"
                                        id="search-mobile"
                                        label="Search Phone"
                                        name="mobile-number"
                                        autoComplete="off"
                                        value={this.state.phone}
                                        onChange={event =>
                                            this.setState({ phone: event.target.value.replace(/[^\d]+/g, ''), page: 0 })
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ maxLength: 10 }}
                                        InputProps={{ styles: { input: styles.inputStyle, root: styles.inputRoot } }}
                                        autoFocus
                                    />
                                    {/* <CssTextField
                                        margin="normal"
                                        id="search-zip-code"
                                        label="Search Zip Code"
                                        name="zipcode"
                                        autoComplete="off"
                                        value={this.state.zip_code}
                                        onChange={event =>
                                            this.setState({ zip_code: event.target.value.replace(/[^\d]+/g, ''), page: 0 })
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ maxlength: 6 }}
                                        InputProps={{ styles: { input: styles.inputStyle, root: styles.inputRoot } }}
                                    /> */}
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Button size="small" variant="contained" color="secondary" style={{ ...styles.clearButton, marginRight: 27, height: 40 }} onClick={() => this.clear()} >
                                        Clear
                                    </Button>
                                    <Button size="small" variant="contained" color="primary" style={{ ...styles.button, marginRight: 27, height: 40 }} onClick={() => this.searchAllUser()} >
                                        Search
                                    </Button>
                                </div>
                            </div>
                            {this.props.search_all_user.length > 0 ?
                                <div style={{ margin: "0px 15px" }}>
                                    <Table >
                                        <TableHead style={{ backgroundColor: '#36454F', padding: 10 }}>
                                            <TableRow >
                                                <TableCell align="center" size="small" style={styles.fontCellHeader}>S.no</TableCell>
                                                <TableCell align="center" size="small" style={styles.fontCellHeader}>Phone</TableCell>
                                                <TableCell align="center" size="small" style={styles.fontCellHeader}>Email</TableCell>
                                                <TableCell align="center" size="small" style={styles.fontCellHeader}>User Code</TableCell>
                                                <TableCell align="center" size="small" style={styles.fontCellHeader}>Zipcode	</TableCell>
                                                <TableCell align="center" size="small" style={styles.fontCellHeader}>Action	</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {result.length > 0 ? result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) =>
                                                <TableRow key={index.toString()} style={{ backgroundColor: index % 2 === 0 ? "#fff" : "whitesmoke" }}  >
                                                    <TableCell align="center" style={styles.fontCellBody} > {(index + 1)} </TableCell>
                                                    <TableCell align="center" style={styles.fontCellBody}>{user.phone}</TableCell>
                                                    <TableCell align="center" style={styles.fontCellBody}>{user.email}</TableCell>
                                                    <TableCell align="center" style={styles.fontCellBody}>{user.userCode}</TableCell>
                                                    <TableCell align="center" style={styles.fontCellBody}>{user.zipCode}</TableCell>
                                                    <TableCell align="center" style={styles.fontCellBody}>
                                                        <div style={{ textAlign: "center", marginTop: "10px", cursor: "pointer" }}>
                                                            <MoreVert style={{ color: "blue", fontSize: "25px" }} onClick={(e) => { this.handleClick(e, user) }} />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>)
                                                :
                                                <TableCell colSpan={4} align="center" style={{...styles.fontCellBody, textAlign:'center'}}> There are no users</TableCell>}
                                        </TableBody>
                                    </Table>
                                </div>
                                : <div style={{ display: "flex", justifyContent: "center" }}>
                                    {this.state.isLoading ? <CircularProgress />
                                        : <Typography style={{ padding: 10, textAlign: "center" }}>Please use search box to look up users.</Typography>
                                    }
                                </div>}
                        </div>
                    }
                </Grid>
                <Grid item xs={8} sm={12} style={{ paddingTop: 0 }}>
                    {this.state.selectedTab === 1 &&
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between", margin: "0px 15px" }}>
                                {/* <TablePagination
                                    rowsPerPageOptions={[20]}
                                    component="div"
                                    count={result.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    slotProps={{
									    select: {
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    labelRowsPerPage={null}
                                    labelDisplayedRows={
                                        () => null
                                    }
                                    ActionsComponent={
                                        TablePaginationActions
                                    }
                                /> */}

                                <div style={{ display: "flex", justifyContent: "space-between", width: "50%" }}>
                                    <TextField
                                        margin="normal"
                                        variant="standard"
                                        id="search-mobile"
                                        label="Search Phone"
                                        name="mobile-number"
                                        autoComplete="off"
                                        value={this.state.phone}
                                        onChange={event =>
                                            this.setState({ phone: event.target.value.replace(/[^\d]+/g, ''), page: 0 })
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ maxLength: 10 }}
                                        InputProps={{ styles: { input: styles.inputStyle, root: styles.inputRoot } }}
                                        autoFocus
                                    />
                                    {/* <CssTextField
                                        margin="normal"
                                        id="search-zip-code"
                                        label="Search Zip Code"
                                        name="zipcode"
                                        autoComplete="off"
                                        value={this.state.zip_code}
                                        onChange={event =>
                                            this.setState({ zip_code: event.target.value.replace(/[^\d]+/g, ''), page: 0 })
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ maxlength: 6 }}
                                        InputProps={{ styles: { input: styles.inputStyle, root: styles.inputRoot } }}
                                    /> */}
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Button size="small" variant="contained" color="secondary" style={{ ...styles.clearButton, marginRight: 3, height: 40 }} onClick={() => this.clear()} >
                                        Clear
                                    </Button>
                                    <Button size="small" variant="contained" color="primary" style={{ ...styles.button, marginRight: 3, height: 40 }} onClick={() => this.searchAllUser()} >
                                        Search
                                    </Button>
                                    <Button size="small" variant="contained" color="primary" style={{ ...styles.buttonIcon, marginRight: 3, height: 40 }} onClick={() => this.addNewRole()} >
                                        <AddCircleIcon style={{ marginRight: 5 }} /> Add Access
                                    </Button>
                                    <Button size="small" variant="contained" color="primary" style={{ ...styles.buttonIcon, marginRight: 3, height: 40 }} onClick={() => this.revokeExistingRole()} >
                                        <AddCircleIcon style={{ marginRight: 5 }} /> Revoke Role
                                    </Button>

                                </div>
                            </div>

                            {this.props.search_all_user.length > 0 ?
                                <div style={{ margin: "0px 15px" }}>
                                    <Table >
                                        <TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10, justifyContent:'space-evenly'}}>
                                            <TableRow >
                                                <TableCell align="left" size="small" style={styles.fontCellHeader}>S.no</TableCell>
                                                <TableCell align="left" size="small" style={styles.fontCellHeader}>Phone</TableCell>
                                                <TableCell align="left" size="small" style={styles.fontCellHeader}>Email</TableCell>
                                                <TableCell align="left" size="small" style={styles.fontCellHeader}>Roles</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {result.length > 0 ? result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) =>
                                                <TableRow key={index.toString()} >
                                                    <TableCell style={styles.fontCellBody}> {(index + 1)} </TableCell>
                                                    <TableCell align="left" style={styles.fontCellBody}>{user.phone}</TableCell>
                                                    <TableCell align="left" style={styles.fontCellBody}>{user.email}</TableCell>
                                                    <TableCell align="left" style={styles.fontCellBody}>
                                                        {user.roleTypes.map((role, index) =>
                                                            <Grid item xs={12} key={index.toString()}>
                                                                <Grid container key={index} style={{ display: 'flex', flexDirection:'row' , justifyContent: 'space-between', alignItems: 'center', }}>
                                                                    {/* <div key={index.toString()}> */}
                                                                        {this.roleAction(role)}
                                                                    {/* </div> */}
                                                                </Grid>
                                                            </Grid>
                                                        )}

                                                    </TableCell>
                                                </TableRow>)
                                                :
                                                <TableCell colSpan={4} align="center" style={{...styles.fontCellBody,textAlign:'center'}}>There are no users</TableCell>}
                                        </TableBody>
                                    </Table>
                                </div>
                                : <div style={{ display: "flex", justifyContent: "center" }}>
                                    {this.state.isLoading ? <CircularProgress />
                                        : <Typography style={{ padding: 10, textAlign: "center" }}>Please use search box to look up users.</Typography>
                                    }
                                </div>}
                        </div>
                    }
                </Grid>
                <div>
                    <Popover
                        id={this.state.popoverId}
                        open={this.state.moreButtonAction}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose}
                        style={{ borderRadius: '20px', border: '1px', borderColor: "red" }}>
                        <List style={styles.root}>
                            <ListItem>
                                <Typography style={{ fontSize: 15, paddingTop: 4, cursor: "pointer" }} onClick={() => this.setState({ dialogOpen: true, moreButtonAction: false })} > View QR Code </Typography>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <Typography style={{ fontSize: 15, paddingTop: 4, cursor: "pointer" }} onClick={() => this.showTestDetails(this.state.selectedUser !== null && this.state.selectedUser.id)}> Details</Typography>
                            </ListItem>
                            {this.state.selectedUser !== null && this.state.selectedUser.roleTypes[0].roleType.code !== "ADM" &&
                                <Divider />
                            }
                            {this.state.selectedUser !== null && this.state.selectedUser.isChangePassword === true &&
                                <ListItem>
                                    <Typography style={{ fontSize: 15, paddingTop: 4, cursor: "pointer" }} onClick={() => this.showTestDetails(this.state.selectedUser !== null && this.state.selectedUser.id)}> Resend SMS</Typography>
                                </ListItem>
                            }

                            {this.state.selectedUser !== null && this.state.selectedUser.roleTypes[0].roleType.code !== "ADM" &&
                                <ListItem>
                                    <Typography style={{ fontSize: 15, paddingTop: 4, textTransform: 'capitalize', cursor: "pointer" }} onClick={() => this.deleteConfirmationAction(this.state.selectedUser)} > {this.state.selectedUser !== null && this.state.selectedUser.status === "ACTIVE" ? "Active" : "Inactive"} </Typography>
                                </ListItem>
                            }
                        </List>
                    </Popover>
                </div>
                <Dialog
                    maxWidth={"xs"}
                    open={this.state.dialogOpen} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"QR Code"}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div style={styles.qrContainer}>
                            <QRCode value={this.state.selectedUser !== null ? this.state.selectedUser.hashedUserId : ""} size={200} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={() => this.setState({ dialogOpen: false, selectedUser: null })} variant="contained" color="clearButton" style={styles.cancelButton}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth={"xs"}
                    open={false} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold", textAlign: "center" }}>{this.state.selectedUser !== null && this.state.selectedUser.status === "ACTIVE" ? "InActive " : "Active "}  User Confirmation!</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div style={styles.qrContainer}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="left" size="small" style={styles.tableHeader}>Test Type</TableCell>
                                        <TableCell align="left" size="small" style={styles.tableHeader}>Document Uploaded Date</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={() => this.deleteConfirmationButtonAction("Y")} variant="contained" color="secondary" style={styles.addButton}>Yes</Button>
                        <Button size="small" onClick={() => this.deleteConfirmationButtonAction("N")} variant="contained" color="clearButton" style={styles.cancelButton}>No</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth={"xs"}
                    open={this.state.isConfirmationDialog} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"Confirmation"}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div style={styles.qrContainer}>
                            Are you sure want to update the Vaccine Record ?
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={() => this.updateShots()} variant="contained" color="secondary" style={styles.addButton}>Update</Button>
                        <Button size="small" onClick={() => this.setState({ isConfirmationDialog: false })} variant="contained" color="clearButton" style={styles.cancelButton}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth={"xs"}
                    open={this.state.deleteConfirmationDialog} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", ffontSize: 18, color: "#fff", fontWeight: "bold", textAlign: "center" }}>Confirmation!</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div style={styles.qrContainer}>
                            Are you sure want to {this.state.selectedUser !== null && this.state.selectedUser.status === "ACTIVE" ? "inactive" : "active"} the {this.state.selectedUser !== null && this.state.selectedUser.phone}?
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={() => this.deleteConfirmationButtonAction("Y")} variant="contained" color="secondary" style={styles.yesButton}>Yes</Button>
                        <Button size="small" onClick={() => this.deleteConfirmationButtonAction("N")} variant="contained" color="clearButton" style={styles.cancelButton}>No</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth={"lg"}
                    fullWidth
                    open={this.state.vaccinDialog} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"Vaccine Records"}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        {this.props.userTestTypeResult.length > 0 ?
                            <Table>
                                <TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10 }}>
                                    <TableRow>
                                        <TableCell align="center" size="small" style={styles.tableHeader}>Test Type</TableCell>
                                        <TableCell align="center" size="small" style={styles.tableHeader}>Document Uploaded Date</TableCell>
                                        <TableCell align="center" size="small" style={styles.tableHeader}>Vaccination Date</TableCell>
                                        <TableCell align="center" size="small" style={styles.tableHeader}>Vaccination Manufacturer</TableCell>
                                        <TableCell align="center" size="small" style={styles.tableHeader}>Status	</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.userTestTypeResult.map((data, index) =>
                                        <TableRow key={index.toString()} style={{ backgroundColor: index % 2 === 0 ? "#fff" : "whitesmoke" }}>
                                            <TableCell align="center" style={styles.fontCellBody}>{`${data.testType.name} - shot ${data.shotNumber}`}</TableCell>
                                            <TableCell align="center" style={styles.fontCellBody}>{moment(data.createdAt).format("MM/DD/YYYY")}</TableCell>
                                            <TableCell align="center" style={styles.fontCellBody}>{data.vaccineResults.length > 0 ? moment(data.vaccineResults[0].vaccinationDate).format("MM/DD/YYYY") : "-"}</TableCell>
                                            <TableCell align="center" style={styles.fontCellBody}>{data.vaccineResults.length > 0 ? data.vaccineResults[0].vaccine.name : "-"}</TableCell>
                                            <TableCell align="center" style={styles.fontCellBody}>{data.vaccineResults.length > 0 ? data.vaccineResults[0].status !== "PENDING" ? data.vaccineResults[0].status : "NOT VACCINATED" : "NOT VACCINATED"}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            :
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography>There are no vaccine record for this user </Typography>
                            </div>
                        }
                    </DialogContent>
                    <DialogActions>
                        {
                            this.props.userTestTypeResult.length > 0 &&
                            <Button size="small" onClick={() => this.confirmationDialog()} variant="contained" color="secondary" style={styles.addButton}>Update</Button>
                        }
                        <Button size="small" onClick={() => this.closeDialog()} variant="contained" color="clearButton" style={styles.cancelButton}>Close</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth={"sm"}
                    scroll="body"
                    fullWidth
                    open={this.state.removeDialog} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "1%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>Revoke Role</Typography>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "hidden", padding: 10 }}>
                        <Grid container justify="space-between">
                            <Grid item xs={12} >
                                <Grid container justify="space-between" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
                                    <Grid item xs={6} style={{ paddingRight: 8 }}>
                                        <TextField
                                            id="search-phone"
                                            label="Phone number"
                                            name="search-phone"
                                            style={styles.margin}
                                            value={this.state.search_phone}
                                            onChange={(event) => event.target.value.match(/^\d{0,10}$/g) && this.setState({ search_phone: event.target.value, search_phone_error: false, invalid_search_phone_error: false, primary_contact_first_name: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_first_name, primary_contact_last_name: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_last_name, primary_contact_email: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_email })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Typography>{this.state.primary_contact_country_code}</Typography>
                                                    </InputAdornment>
                                                )
                                            }}
                                            autoComplete="off"
                                            fullWidth
                                            error={this.state.search_phone_error ? true : this.state.invalid_search_phone_error ? true : false}
                                            helperText={this.state.search_phone_error === true ? "Please enter phone number" : this.state.invalid_search_phone_error ? "Please enter valid phone number" : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <div style={{ padding: 8, justifyContent: "center", alignItems: "center", display: "flex" }}>
                                            <Button size="small" onClick={() => this.searchUserType()} variant="contained" color="primary" style={styles.addButton}>Find User</Button>
                                        </div>
                                        <Typography style={{ fontSize: "10px", color: "#20478E", textAlign: "center" }}>(If user already exist)</Typography>
                                    </Grid>
                                    {!!this.state.userDetails &&
                                        <Grid item xs={12} style={{ paddingTop: 10 }}>
                                            <FormControl>
                                                <FormLabel id="demo-radio-buttons-group-label" style={{ fontSize: 13 }}>Name</FormLabel>
                                                <Typography >{`${this.state.userDetails.firstName} ${this.state.userDetails.lastName}`}</Typography>
                                            </FormControl>
                                        </Grid>
                                    }
                                    <Grid item xs={12} style={{ paddingRight: 8, paddingTop: 10 }}>
                                        {this.state.userRoleTypes.length > 0 &&
                                            <FormControl>
                                                <FormLabel id="demo-radio-buttons-group-label" style={{ fontSize: 13 }}>Please Select a Role to Revoke</FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="female"
                                                    name="radio-buttons-group"
                                                    onChange={(event) => this.setState({
                                                        selectedRoleId: event.target.value.toString()
                                                    })}
                                                >
                                                    {this.state.userRoleTypes.map((data, index) =>
                                                        data.roleType.code === "MSP" ?
                                                            <FormControlLabel value={data.id.toString()} control={<Radio />} label={"Medical Service Provider"} />
                                                            :
                                                            <FormControlLabel value={data.id.toString()} control={<Radio />} label={data.roleType.name} />
                                                    )}
                                                </RadioGroup>
                                            </FormControl>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" disabled={this.state.selectedRoleId !== null ? false : true} onClick={() => this.handleRevokeSubmit()} variant="contained" color="primary" style={styles.addButton}>Submit</Button>
                        <Button size="small" onClick={() => this.revokeExistingRole()} variant="contained" color="clearButton" style={styles.cancelButton}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth={"sm"}
                    scroll="body"
                    fullWidth
                    open={this.state.roleDialog} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "1%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>Assign Role</Typography>
                    </DialogTitle>

                    <DialogContent style={{ overflowY: "hidden", padding: 10 }}>
                        <Grid container justify="space-between">
                            <Grid item xs={12} >
                                <Grid container justify="space-between" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
                                    <Grid item xs={6} style={{ paddingRight: 8 }}>
                                        <TextField
                                            id="search-phone"
                                            label="Phone number"
                                            name="search-phone"
                                            style={styles.margin}
                                            value={this.state.search_phone}
                                            onChange={(event) => event.target.value.match(/^\d{0,10}$/g) && this.setState({ search_phone: event.target.value, search_phone_error: false, invalid_search_phone_error: false, primary_contact_first_name: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_first_name, primary_contact_last_name: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_last_name, primary_contact_email: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_email })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Typography>{this.state.primary_contact_country_code}</Typography>
                                                    </InputAdornment>
                                                )
                                            }}
                                            autoComplete="off"
                                            fullWidth
                                            error={this.state.search_phone_error ? true : this.state.invalid_search_phone_error ? true : false}
                                            helperText={this.state.search_phone_error === true ? "Please enter phone number" : this.state.invalid_search_phone_error ? "Please enter valid phone number" : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <div style={{ padding: 8, justifyContent: "center", alignItems: "center", display: "flex" }}>
                                            <Button size="small" onClick={() => this.searchUser()} variant="contained" color="primary" style={styles.addButton}>Find User</Button>
                                        </div>
                                        <Typography style={{ fontSize: "10px", color: "#20478E", textAlign: "center" }}>(If user already exist)</Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{ paddingRight: 8 }}>
                                        <TextField
                                            id="first name"
                                            label="First Name"
                                            name="first name"
                                            style={styles.margin}
                                            value={this.state.primary_contact_first_name.charAt(0).toUpperCase() + this.state.primary_contact_first_name.slice(1)}
                                            autoComplete="off"
                                            onChange={(event) => this.setState({ primary_contact_first_name: event.target.value, primary_contact_first_name_error: false })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            fullWidth
                                            error={this.state.primary_contact_first_name_error === true ? true : false}
                                            helperText={this.state.primary_contact_first_name_error === true ? "Please enter the first name" : false}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ paddingRight: 8 }}>
                                        <TextField
                                            id="last name"
                                            label="Last Name"
                                            name="last name"
                                            autoComplete="off"
                                            style={styles.margin}
                                            value={this.state.primary_contact_last_name.charAt(0).toUpperCase() + this.state.primary_contact_last_name.slice(1)}
                                            onChange={(event) => this.setState({ primary_contact_last_name: event.target.value, primary_contact_last_name_error: false })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            fullWidth
                                            error={this.state.primary_contact_last_name_error === true ? true : false}
                                            helperText={this.state.primary_contact_last_name_error === true ? "Please enter last name" : false}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ paddingRight: 8 }} >
                                        <TextField
                                            id="email"
                                            label="E-mail"
                                            name="email"
                                            autoComplete="off"
                                            style={styles.margin}
                                            value={this.state.primary_contact_email}
                                            onChange={(event) => this.setState({ primary_contact_email: event.target.value, primary_contact_email_error: false, invalid_primary_contact_email_error: false })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            fullWidth
                                            error={this.state.primary_contact_email_error === true ? true : this.state.invalid_primary_contact_email_error === true ? true : false}
                                            helperText={this.state.primary_contact_email_error === true ? "Enter the email" : this.state.invalid_primary_contact_email_error === true ? "Please enter valid email" : false}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ paddingRight: 8 }} >
                                        <TextField
                                            id="type"
                                            select
                                            label="Role"
                                            name="role"
                                            value={this.state.selected_role}
                                            onChange={(event) => this.setState({ selected_role: event.target.value, access_role_error: false })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            required
                                            fullWidth
                                            error={this.state.role_error === true ? true : false}
                                            helperText={this.state.role_error === true ? "Please select the Role" : false}
                                        >
                                            <MenuItem value={"ADM"} >Admin</MenuItem>
                                            <MenuItem value={"CST"} >Customer Support</MenuItem>
                                            <MenuItem value={"MSP"} >Medical Service Provider</MenuItem>
                                        </TextField>
                                    </Grid>
                                </Grid>
                                {
                                    this.state.access_role_error &&
                                    <Typography style={{ fontFamily: "Futura-Heavy", color: "red", fontWeight: "bold", fontSize: 11, textAlign: "center" }}>Can not able to assign Medical Service Provider role to this user, because this user already have access user role</Typography>
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={() => this.handleSubmit()} variant="contained" color="primary" style={styles.addButton}>Submit</Button>
                        <Button size="small" onClick={() => this.handleRoleDialogClose()} variant="contained" color="clearButton" style={styles.cancelButton}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
    return {
        user_by_phone: state.userReducer.user_by_phone,
        search_all_user: state.userReducer.search_all_user,
        userTestTypeResult: state.testtypeReducer.userTestTypeResult
    };
};

// UserAndAccess.propTypes = {
//     styles: PropTypes.object.isRequired,
// };

export default withRouter(connect(mapStateToProps)(UserAndAccess));