import React, { Component } from "react";
import { Typography, Grid, Dialog, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, IconButton } from "@mui/material";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import moment from 'moment';
import { fetchTestResultsValues, deleteTest, fetchUnverifiedRecord, addQueue, addAllDocumentToQueue } from "../../actions/testAction";
import { END_POINT } from "../../helpers/Constants";
import CancelIcon from '@mui/icons-material/Cancel';
import { VERIFICATION_TEST_VALUE } from "../../actions/actionTypes";
import { fetchUserProfile } from "../../actions/userAction"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import { saveAs } from 'file-saver'
import Covid from "../../assets/images/covid.png"
import Antibody from "../../assets/images/antibody.png"
import Vaccination from "../../assets/images/vaccination.png"
import { withRouter } from "../../withRouter";
import theme from '../../theme';
import { THEMECOLOR } from "../../helpers/Constants";

/** Created common styles for UI  */
const styles = {
	paper: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundColor: theme.palette.primary.main,
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
		minHeight: '100vh',
		padding: 20,
	},
	container: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundColor: "#FFF",
		margin: 10,
		borderRadius: 10,
		padding: 10,
		minHeight: "70vh"
	},
	row: {
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex"
	},
	itemContainer: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundColor: "#FFF",
		borderRadius: 5,
		margin: 10,
	},
	titleText: {
		fontSize: 22,
		fontWeight: "bold",
		color: "#fff",
		padding: 10,
		textAlign: "center"
	},
	testTitle: {
		fontSize: 18,
		color: "#000",
		fontWeight: "bold",
		textAlign: "left",
		paddingBottom: 10,
		paddingLeft: 10
	},
	description: {
		fontSize: 16,
		color: "#000",
		// marginLeft: 5,
		textAlign: "left"
	},
	divider: {
		marginTop: 5,
		width: "100%",
		height: 1,
		backgroundColor: "#D1CFCF"
	},
	deleteText: {
		cursor: "pointer",
		textAlign: "center",
		fontSize: 16,
		color: "#000",
		textTransform: "Capitalize",
		padding: "8px 15px"
	},
	closeIcon: {
		padding: 5,
		color: "#B22222",
		backgroundColor: "#ffffff",
		'&:hover': {
			backgroundColor: "#ffffff",
		}
	},
	verticalDivider: {
		marginTop: 5,
		width: 1,
		// height: "100%",
		backgroundColor: "#D1CFCF"
	},
	emptyText: {
		fontSize: 14,
		color: "#000",
		textAlign: "center"
	},
	dialogPaper: {
		backgroundColor: "rgba(0,0,0,0)",
		boxShadow: "none",
		overflow: "hidden"
	},
};

/**
 * @class
 *
 * Class representing TestHistory component
 *
 */
class TestHistory extends Component {
	constructor() {
		super();
		this.state = {
			imageDialog: false,
			deleteDialog: false,
			imageurl: null,
			selectedTest: null,
			user_profile: null,
			userType: null,
			is_loading: false,
			fromButton: null
		}
	}
	/** This is a component life cycle method and used to assigning props values to state variable  */
	static getDerivedStateFromProps(props, state) {
		if (props.user_profile !== state.user_profile) {
			return {
				user_profile: props.user_profile,
				userType: props.user_profile.userType
			}
		}
		return null;
	}

	/** This is a component life cycle method and used to fetch datas*/
	componentDidMount = () => {
		if (this.props.location.state?.from_scan_result === 'from_scan_result') {
			/** fetching UnverifiedHealthRecord details. */
			this.props.dispatch(fetchUnverifiedRecord(this, this.props.location.state.id))
		}
		else {
			/** fetching all HealthRecord details. */
			this.props.dispatch(fetchTestResultsValues());
		}

		/** fetching user profile details. */
		this.props.dispatch(fetchUserProfile())
	}

	/**
	* @function
	*
	* function representing open image dialog Screen.
	*
	*/
	openDialog = (test) => {
		this.setState({ imageDialog: true, selectedTest: test, imageurl: `${END_POINT}image/${test.testType.isVaccine === true ? "v" : "t"}/${test.storageKey}` })
	}

	/**
	* @function
	*
	* function representing close image dialog Screen.
	*
	*/
	closeDialog = () => {
		this.setState({ imageDialog: false, selectedTest: null, imageurl: null })
	}

	/**
	* @function
	*
	* function representing testType is vaccine redirecting to Vaccinenation screen or else TestImageVerification Screen.
	* @param {Object} test
	*/
	verification = (test) => {
		if (test.testType.isVaccine === true) {
			this.props.dispatch({ type: VERIFICATION_TEST_VALUE, data: test })
			this.props.navigate("/vaccination")
		} else {
			this.props.dispatch({ type: VERIFICATION_TEST_VALUE, data: test })
			this.props.navigate("/test-image-verification")
		}
	}

	/**
	* @function
	*
	* function representing assigning test to queue
	* @param {number} id
	*/
	addToQueue = (id) => {
		this.props.dispatch(addQueue(this, id))
	}

	/**
		* @function
		*
		* function representing assigning all test document to queue
		* @param {number} userProfileId
		*/
	addDocumentToQueue = (userProfileId) => {
		this.props.dispatch(addAllDocumentToQueue(this, userProfileId))
	}

	/**
	* @function
	*
	* function representing delet test result record.
	*
	*/
	deleteTest = () => {
		this.setState({ deleteDialog: false, is_loading: true })
		this.props.dispatch(deleteTest(this))
	}

	download = (test) => {
		if (test.isLabTest && test.pdfReportStorageKey !== null) {
			saveAs(`${END_POINT}pdf-test-report/${test.pdfReportStorageKey}`, 'document.pdf') // Put your url here.
		} else {
			saveAs(`${END_POINT}image/${test.testType.isVaccine === true ? "v" : "t"}/${test.storageKey}`, 'document.jpg') // Put your url here.
		}
	}

	/** rendering  TestHistory screen to view test history of user. */
	render() {
		const { fetch_test_results } = this.props;
		let result = this.state.userType === "MSP" ? fetch_test_results.filter(x => x.status !== "COMPLETED") : this.props.params.from_button === "unverified" ? fetch_test_results.filter(x => x.status !== "COMPLETED") : fetch_test_results
		let unverfiedRecords = result.filter(item => item.status === "PENDING");
		let assignedDocument = result.filter(item => item.isQueue === false);
		let sortResult = result.length > 0 ? result.sort(function (a, b) { return new Date(b.displayDate) - new Date(a.displayDate) }) : []
		return (
			<div style={styles.paper}>
				<Grid container justifyContent="center">
					<Grid item xs={12} sm={6} md={4}>
						<Typography style={styles.titleText}> {this.state.userType === "CLT" ? "My Health Records" : "Patient Records"}</Typography>
						<div style={styles.container}>
							{sortResult.length > 0 ?
								sortResult.map((test, index) =>
									(this.props.user_type === "MSP" || (this.props.user_type === "CLT" && (test.testType.isVaccine && test.vaccineResults.length > 0 && test.vaccineResults[0].vaccinationDate !== null) || (!test.testType.isVaccine && test.displayDate !== null && test.testResultItems.length > 0))) && <div key={index} style={styles.itemContainer}>
										<div style={{ display: "flex", flexDirection: 'column', padding: 20 }}>
											<div style={{ display: 'flex' }}>
												<div>
													{test.testType.isVaccine ?
														<img src={Vaccination} width={30} height="auto" alt="Vaccination" />
														: test.testType.isAntibody ?
															<img src={Antibody} width={30} height="auto" alt="Antibody" />
															: <img src={Covid} width={30} height="auto" alt="Covid" />}
												</div>
												<div>
													{test.testType.isVaccine === true ?
														<Typography style={styles.testTitle}>{test.testType.name} {test.shotNumber !== null ? ("Shot n°" + test.shotNumber) + (test.vaccineResults.length > 0 && test.vaccineResults[0].vaccineShot.isBooster === true ? " (Booster)" : "") : ""}</Typography>
														:
														<Typography style={styles.testTitle}>{test.testType.name} </Typography>
													}
												</div>
											</div>
											<div>
												<div style={{ width: "100%" }}>
													<div style={styles.row}>
														<Typography style={styles.description}>{test.testType.isVaccine ? "Vaccination Date:" : "Test Date"}</Typography>
														<Typography style={styles.description}>{test.testType.isVaccine ? (test.vaccineResults.length > 0 && test.vaccineResults[0].vaccinationDate !== null) ? `${moment.utc(test.vaccineResults[0].vaccinationDate, 'YYYY-MM-DD').format("MM/DD/YYYY")}` : "" : (test.displayDate !== null && test.displayDate !== undefined) ? `${moment.utc(test.displayDate, 'YYYY-MM-DD').format("MM/DD/YYYY")}` : ""}</Typography>
													</div>
													<div style={styles.row}>
														<Typography style={styles.description}>{test.testType.isVaccine ? "Document Status:" : "Test Status"}</Typography>
														<Typography style={styles.description}>{test.status === "PENDING" ? <span style={{ color: "red" }}>Unverified</span> : test.status === "REJECTED" ? <span style={{ color: "red" }}>Rejected</span> : test.status === "COMPLETED" ? <span style={{ color: "green" }}> Verified</span> : <span style={{ color: "orange" }}> Partially Verified</span>}</Typography>
													</div>
													{test.status === "REJECTED" &&
														<div style={styles.row}>
															<Typography style={styles.description}>Reject Reason: </Typography>
															<Typography style={{ color: "red", fontSize: 16, textAlign: "end", }}>{test.rejectReason}</Typography></div>}
													{test.status === "PENDING" &&
														<div style={{ marginTop: 10 }}>
															<Button disabled={test.isQueue} fullWidth style={{ backgroundColor: test.isQueue ? '#E0E0E0' : 'red', borderRadius: 10, fontSize: 12, fontWeight: "bold", padding: 5, color: 'white', textTransform: "Capitalize" }} onClick={() => this.addToQueue(test.id)}>
																Submit for verification
															</Button>
														</div>}
													{(this.props.user_type === "CLT" && test.status === "REJECTED") &&
														<div style={{ marginTop: 10 }}>
															<Button fullWidth style={{ backgroundColor: 'red', borderRadius: 10, fontSize: 12, fontWeight: "bold", padding: 5, color: 'white', textTransform: "Capitalize" }}
																onClick={() => this.props.navigate("/re-upload-test-image", { state: { "id": test.id, "isVaccine": test.testType.isVaccine } })}
															// this.props.navigate("/test-history", { state: { 'queue': 'queueRecords', 'id': param.id }})
															>
																Resubmit for verification
															</Button>
														</div>}
												</div>
											</div>
										</div>
										<div style={styles.divider} />
										<div style={{ display: "flex", justifyContent: "space-between" }}>
											{(test.isLabTest === false ?
												(test.storageKey !== null ?
													<Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography> : "")
												:
												(test.isLabTest && test.pdfReportStorageKey === null) ? "" : <Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography>)}
											<div style={styles.verticalDivider} />
											{this.props.user_type === "MSP" ?
												<Typography style={styles.deleteText} onClick={() => this.verification(test)}>View For Verification</Typography>
												: test.status === "COMPLETED" ?
													test.isLabTest === false ?
														(test.storageKey !== null ?
															<Typography style={styles.deleteText} onClick={() => this.download(test)}>Download</Typography> : "")
														: (test.isLabTest && test.pdfReportStorageKey === null) ? "" : <Typography style={styles.deleteText} onClick={() => this.download(test)}>Download</Typography>
													: <Typography style={styles.deleteText} onClick={() => this.setState({ deleteDialog: true, selectedTest: test })}>Delete</Typography>
											}
										</div>
										{/* <div style={{ display: "flex", justifyContent: "space-between" }}>

											<Typography style={styles.deleteText} onClick={() => this.setState({ deleteDialog: true, selectedTest: test })}>Delete</Typography>
											<div style={styles.verticalDivider} />
											<Typography style={styles.deleteText} onClick={() => this.verification(test)}>View For Verification</Typography>
										</div> */}
									</div>)
								:
								<div style={{ display: "flex", alignItems: "center", height: "70vh", justifyContent: "center" }} >
									<Typography style={styles.emptyText} > {this.state.userType === "MSP" ? "You have not uploaded any documents yet. Go to “Upload Test/Vaccine Record” to start your MedFilo journey." : "You have not uploaded any documents yet. Go to “Upload Test/Vaccine Record” to start your MedFilo journey."}</Typography>
								</div>
							}
							{unverfiedRecords.length > 0 && assignedDocument.length > 0 &&
								<div style={{ margin: 10, borderRadius: 10, padding: 10 }}>
									<Button fullWidth style={{ backgroundColor: 'red', orderRadius: 10, padding: 5, color: 'white', textTransform: "Capitalize" }} onClick={() => this.addDocumentToQueue(this.state.user_profile.id)}>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<Typography style={{ fontSize: 12, fontWeight: "bold", }}>
												Submit for verification
											</Typography>
											<Typography style={{ fontSize: 12, fontWeight: "bold", }}>
												All my unverfied health records
											</Typography>
										</div>
									</Button>
								</div>}
						</div>
					</Grid>
				</Grid>
				<Dialog maxWidth={"xs"} fullWidth open={this.state.imageDialog} onClose={() => this.setState({ imageDialog: false })}>
					{this.state.selectedTest !== null && <div style={{ overflow: "hidden" }}>
						<div style={{ display: "flex", justifyContent: "space-between", padding: 5, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
							<Typography style={{ display: "flex", alignItems: "center", color: '#fff', fontSize: 16, textAlign: "left" }}>{this.state.selectedTest !== null ?
								`${this.state.selectedTest.testType.name}`
								//  ${this.state.selectedTest.testType.isVaccine ? (this.state.selectedTest.vaccineResults.length > 0 && this.state.selectedTest.vaccineResults[0].vaccinationDate !== null) ? `${moment(this.state.selectedTest.vaccineResults[0].vaccinationDate).format("MM/DD/YYYY")}` : "" : this.state.selectedTest.displayDate !== null ? `${moment(this.state.selectedTest.displayDate).format("MM/DD/YYYY")}` : ""}
								: ""}</Typography>
							<IconButton onClick={() => this.closeDialog()} style={styles.closeIcon}>
								<CancelIcon fontSize="medium" />
							</IconButton>
						</div>

						{this.state.selectedTest.isLabTest === true ?
							this.state.selectedTest.pdfReportStorageKey !== null ?
								<iframe title={`${this.state.selectedTest.pdfReportStorageKey}`} src={`${END_POINT}pdf-test-report/${this.state.selectedTest.pdfReportStorageKey}#toolbar=0`} style={{ width: "100%", height: "90vh" }} />
								:
								<Typography style={{ display: "flex", alignItems: "center", height: "40vh", justifyContent: "center" }}>There is no document to view</Typography>
							: <TransformWrapper>
								<TransformComponent>
									<img src={this.state.imageurl} alt="test-img" style={{ width: "100%", height: "auto" }} />
								</TransformComponent>
							</TransformWrapper>}
					</div>}
				</Dialog>
				<Dialog open={this.state.deleteDialog} onClose={() => this.setState({ deleteDialog: false })} aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"Confirmation"}</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText >
							<span style={styles.dialogContentText}>Are you sure you want to delete this test? </span>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.setState({ deleteDialog: false, selectedTest: null })} style={{ color: 'red' }}>
							No
						</Button>
						<Button onClick={() => this.deleteTest()} style={{ color: THEMECOLOR.PRIMARY }}>
							Yes
						</Button>
					</DialogActions>
				</Dialog>
				{this.state.is_loading &&
					(<div style={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(255, 255, 255, 0.8)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 9999
					}}>
						<CircularProgress style={{ color: '#000' }} />
					</div>)}
			</div >
		);
	}
}

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {
		user_type: state.sessionReducer.user_type,
		fetch_test_results: state.testReducer.fetch_test_results,
		user_profile: state.userReducer.user_profile,
	};
};

TestHistory.propTypes = {
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(TestHistory));