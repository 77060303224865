import {
	TEST_RESULT, FETCH_TEST_RESULTS, FETCH_USER_RESULTS, VERIFICATION_TEST_VALUE,
	SELECTED_TEST_TYPE, SELECTED_TEST_TYPE_IMAGE, FETCH_QUEUE_USERS, USERS_WITH_TEST_RESULTS,
	NEW_USERS_WITH_TEST_RESULTS, ALL_TEST_RESULTS, TEST_RESULTS_COUNT, ALL_TEST_RESULTS_UPDATE,
	UPDATE_TEST_RESULTS, INDIVIDUAL_TEST_RESULTS
} from "../actions/actionTypes"

const initialstate = {
	test_result: null,
	fetch_test_results: [],
	user_test_results: null,
	verification_test: null,
	selected_test_type: null,
	selected_test_type_image: null,
	queue_users: null,
	users_pending_test_results: [],
	all_test_results: [],
	test_results_count: 0,
	individual_test_results: []
}

export default function testReducer(state = initialstate, action) {
	switch (action.type) {
		case TEST_RESULT:
			return {
				...state,
				test_result: action.data
			}
		case FETCH_TEST_RESULTS:
			return {
				...state,
				fetch_test_results: action.data
			}

		case FETCH_USER_RESULTS:
			return {
				...state,
				user_test_results: action.data,
			};
		case VERIFICATION_TEST_VALUE:
			return {
				...state,
				verification_test: action.data
			};
		case SELECTED_TEST_TYPE:
			return {
				...state,
				selected_test_type: action.data
			};
		case SELECTED_TEST_TYPE_IMAGE:
			return {
				...state,
				selected_test_type_image: action.data
			};
		case FETCH_QUEUE_USERS:
			return {
				...state,
				queue_users: action.data
			};
		case USERS_WITH_TEST_RESULTS:
			return {
				...state,
				users_pending_test_results: action.data
			};
		case NEW_USERS_WITH_TEST_RESULTS:
			return {
				...state,
				users_pending_test_results: state.users_pending_test_results.map(x => action.data.find(y => y.id === x.id) !== undefined ? action.data.find(y => y.id === x.id) : x)
			};
		case ALL_TEST_RESULTS:
			return {
				...state,
				all_test_results: action.data
			};
		case ALL_TEST_RESULTS_UPDATE:
			return {
				...state,
				all_test_results: [...state.all_test_results, ...action.data]
			};
		case TEST_RESULTS_COUNT:
			return {
				...state,
				test_results_count: action.data
			};
		case UPDATE_TEST_RESULTS:
			return {
				...state,
				all_test_results: state.all_test_results.map(item => item.id === action.data.id ? action.data : item)
			};
		case INDIVIDUAL_TEST_RESULTS:
			return {
				...state,
				individual_test_results: action.data
			};
		default:
			return state;
	}
}