import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, FormHelperText, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import { THEMECOLOR } from "../../helpers/Constants";
import theme from "../../theme";
import { withRouter } from "../../withRouter";

const DOT_LENGTH = 10;

/** Created common styles for UI  */
const styles = {
    paper: {
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        height: '1vh'
    },
    topContainer: {
        backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderBottomLeftRadius: "10%",
        borderBottomRightRadius: "10%"
    },
    bottomContainer: {
        backgroundColor: "#fff",
        borderRadius: 10,
        margin: "0px 10px",
        padding: "10px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s",
        width: "40px%"
    },
    title: {
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "center",
        padding: 5,
        color: "#fff"
    },
    button: {
        fontSize: 15,
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        fontWeight: "bold",
        borderRadius: 5
    },
    topText: {
        textAlign: "center",
        fontSize: 18,
        paddingVertical: 5,
        fontWeight: "bold"
    },
    secondaryText: {
        textAlign: "center",
        fontSize: window.screen.width >= 1280 ? 16 : 13,
        paddingVertical: 5,
        color: "#7F8EB8"
    },
    innerInpuView: {
        display: "flex",
        flexDirection: 'column',
        borderBottomWidth: 1,
        borderBottomColor: "#F6F6F6"
    },
    textView: {
        paddingVertical: 5
    },
    pickView: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center"
    },
    borderDot: {
        width: 24,
        height: 24,
        borderRadius: 12,
        backgroundColor: "#fff",
        borderWidth: 6,
        borderColor: "rgba(265,265,265,0.2)"
    },
    buttonContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        margin: 30
    },
    infoContainer: {
        display: 'flex',
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 15
    },
    dotView: {
        display: 'flex',
        flexDirection: "row",
        width: "90%",
        alignItems: "center",
        justifyContent: "space-around",
        marginTop: -100
    },
    dotStyle: {
        width: 12,
        height: 12,
        borderRadius: 6,
        backgroundColor: "rgba(265,265,265,0.5)"
    },
    inputStyle: {
        fontSize: "15px !important",
        padding: "5px !important",
    },
    inputRoot: {
        borderRadius: "0px !important"
    },
};

class DeleteAccount extends Component {
    constructor() {
        super();
        this.state = {
            mobileNumber: "",
            mobileNumberError: false,
            invalidMobileNumberError: false,
            password: "",
            passwordError: false,
            secureEntry: true,
            loginError: null
        }
    }

    renderDotView = () => {
        let DotArray = [];
        for (let i = 0; i < DOT_LENGTH; i++) {
            DotArray.push(<div key={i.toString()} id={i} style={{
                width: 4,
                height: 4,
                borderRadius: 2,
                backgroundColor: "rgba(265,265,265,0.5)"
            }} />)
        }
        return DotArray;
    };

    validatePhone(phone) {
        if (phone.length === 10) {
            let re = /^\d{10}$/;
            return re.test(parseInt(phone));
        }
        else {
            return false
        }
    };

    handleClickShowPassword = () => {
        this.setState({ secureEntry: !this.state.secureEntry })
    };

    handleSubmit = () => {
        this.props.navigate("/otp-verification-for-deletion")
        // let isError = false;

        // if (this.state.mobileNumber === "" || this.state.mobileNumber === null) {
        //     this.setState({ mobileNumberError: true })
        //     isError = true
        // } else if (!this.validatePhone(this.state.mobileNumber)) {
        //     this.setState({ invalidMobileNumberError: true })
        //     isError = true
        // }

        // if (isError === false) {

        // }
    };

    render() {
        return (
            <div style={styles.paper}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} >
                        <div style={styles.topContainer}>
                            <Typography style={styles.title}>Delete Account</Typography>
                        </div>
                        <div>
                            <div style={styles.infoContainer}>
                                <div style={styles.dotView}>
                                    <div style={styles.dotStyle} />
                                    {this.renderDotView()}
                                    <div style={styles.borderDot} />
                                    {this.renderDotView()}
                                    <div style={styles.dotStyle} />
                                </div>
                            </div>
                        </div>
                        <Grid container justifyContent="center">
                            <Grid item style={{ marginTop: -40 }} xs={12} sm={6} md={4}>
                                <div style={styles.bottomContainer}>
                                    <Typography style={styles.topText}>Enter Mobile Number and Password</Typography>
                                    <Typography style={styles.secondaryText}>Enter your phone number and password to delete account</Typography>
                                    <div style={styles.textView}>
                                        <div style={styles.innerInpuView}>
                                            <div style={styles.pickView} />
                                            <TextField
                                                required
                                                fullWidth
                                                autoFocus
                                                margin="normal"
                                                color={"primary"}
                                                variant="standard"
                                                id="mobile-number"
                                                label="Phone Number"
                                                name="mobile-number"
                                                autoComplete="off"
                                                value={this.state.mobileNumber}
                                                onChange={event => this.setState({ mobileNumber: event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'), mobileNumberError: false, invalidMobileNumberError: false, loginError: null })}
                                                error={this.state.mobileNumberError}
                                                onKeyDown={e => {
                                                    if (e.key === "Enter") {
                                                        this.onSubmit();
                                                    }
                                                }}
                                            />
                                            {this.state.mobileNumberError &&
                                                <FormHelperText style={{ color: theme.palette.secondary.error, marginLeft: "5px" }}>Please enter mobile number</FormHelperText>
                                            }
                                            {this.state.invalidMobileNumberError &&
                                                <FormHelperText style={{ color: theme.palette.secondary.error, marginLeft: "5px" }}>Please enter valid mobile number</FormHelperText>
                                            }
                                            <TextField
                                                required
                                                fullWidth
                                                id="password"
                                                label="Password"
                                                name="password"
                                                color={"primary"}
                                                variant="standard"
                                                type={this.state.secureEntry ? "password" : "text"}
                                                margin="normal"
                                                value={this.state.password}
                                                onChange={event =>
                                                    this.setState({ password: event.target.value, passwordError: false, loginError: null })
                                                }
                                                autoComplete="off"
                                                error={this.state.passwordError}
                                                InputProps={{
                                                    styles: { input: styles.inputStyle, root: styles.inputRoot },
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={this.handleClickShowPassword}
                                                            >
                                                                {this.state.secureEntry ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onKeyDown={e => {
                                                    if (e.key === "Enter") {
                                                        this.handleSubmit();
                                                    }
                                                }}
                                            />
                                            {this.state.passwordError &&
                                                <FormHelperText style={{ color: theme.palette.secondary.error, marginLeft: "10px" }}>Please enter Password</FormHelperText>
                                            }
                                            {this.state.loginError !== null &&
                                                <Typography align="center" style={{ color: "red", fontSize: 15 }}>{this.state.loginError}</Typography>
                                            }
                                            <div style={styles.buttonContainer}>
                                                <Button
                                                    fullWidth
                                                    type="Button"
                                                    variant="contained"
                                                    onClick={() => this.handleSubmit()}
                                                    style={styles.button}
                                                >
                                                    Continue
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
};

const mapStateToProps = () => {
    return {

    }
};

export default withRouter(connect(mapStateToProps)(DeleteAccount));