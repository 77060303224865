import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Table, TableBody, TableCell, TableHead, TableRow,
    Typography, Grid,
} from '@mui/material';
import { fetchPartnerDetail } from "../../actions/partnerAction";
import { withRouter } from "../../withRouter";

const styles = {
    fontCellHeader: {
        fontFamily: "Futura-Book",
        color: "#78909c",
        fontWeight: 900,
        padding: '14px 20px 14px 0px',

    },
    fontTableCellBody: {
        fontFamily: "Futura-Medium-bt",
        color: "#37474f",
        padding: '14px 20px 14px 0px',
    },
}

class PartnerDashBoard extends Component {
    constructor() {
        super();
        this.state = {

        }
    }

    componentDidMount() {
        this.props.dispatch(fetchPartnerDetail())
    }

    render() {
        // let { styles } = this.props;
        return (
            <div style={{ padding: 20 }}>
                <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", fontSize: 20 }}> Partner Details </Typography>
                {this.props.partnerDetail !== null && this.props.partnerDetail !== undefined &&
                    <Grid container >
                        <Grid item xs={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Name</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{this.props.partnerDetail.name}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Email</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{this.props.partnerDetail.email}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Phone Number</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{this.props.partnerDetail.phoneNumber}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Address</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{this.props.partnerDetail.addressLine1}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>City</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{this.props.partnerDetail.city}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>State</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{this.props.partnerDetail.state}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Country</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{this.props.partnerDetail.country}</Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Zipcode</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{this.props.partnerDetail.zipcode}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Status</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{this.props.partnerDetail.status}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} sm={12} style={{ paddingTop: 10 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" style={styles.fontCellHeader}>Name</TableCell>
                                        <TableCell align="left" style={styles.fontCellHeader}>E-mail</TableCell>
                                        <TableCell align="left" style={styles.fontCellHeader}>Phone Number</TableCell>
                                        <TableCell align="left" style={styles.fontCellHeader}>Contact Person</TableCell>
                                        <TableCell align="left" style={styles.fontCellHeader}>Organization Type</TableCell>
                                        <TableCell align="left" style={styles.fontCellHeader}>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.props.partnerDetail.organizationPartners.length > 0 ? this.props.partnerDetail.organizationPartners.map((item) => (
                                            <TableRow key={item.id}>
                                                <TableCell align="left" size="small" style={styles.fontTableCellBody}>
                                                    <Typography style={{ fontWeight: "bold", fontSize: 15 }}> {item.name}</Typography>
                                                    <Typography style={{ fontSize: 12 }}>{item.addressLine1 + "," + item.addressLine1 + "," + item.city + "," + item.state + "," + item.country + "," + item.zipcode}</Typography>
                                                </TableCell>
                                                <TableCell align="left" style={styles.fontTableCellBody}>{item.email}</TableCell>
                                                <TableCell align="left" style={styles.fontTableCellBody}>{item.phoneNumber}</TableCell>
                                                <TableCell align="left" style={styles.fontTableCellBody}>{item.primaryContactName}</TableCell>
                                                <TableCell align="left" style={styles.fontTableCellBody}>{item.organizationType}</TableCell>
                                                <TableCell align="left" style={styles.fontTableCellBody}>{item.status}</TableCell>
                                            </TableRow>))
                                            :
                                            <TableRow>
                                                <Typography style={styles.fontTableCellBody}>There are no organizations</Typography>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>}


            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        partnerDetail: state.partnerReducer.partnerDetail,
    }
}

PartnerDashBoard.propTypes = {
    styles: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps)(PartnerDashBoard));