import { FETCH_DONOR, ADD_DONOR } from "../actions/actionTypes"

const initialstate = {
    donors: []
}

export default function donorReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_DONOR:
            return {
                ...state,
                donors: action.data
            }
        case ADD_DONOR:
            return {
                ...state,
                donors: state.donors.find(x => x.id === action.data.id) === undefined ? [...state.donors, ...action.data] : state.donors.map(x => x.id === action.data.id ? action.data : x)
            }
        default:
            return state;
    }
}