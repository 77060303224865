import { Grid, Typography } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import Webcam from "react-webcam";
import { ADD_USER_SELFIE } from "../../actions/actionTypes";
import { THEMECOLOR, isMobile } from "../../helpers/Constants";
// import _ from 'underscore';
import { createTheme } from '@mui/material/styles';
import { withRouter } from "../../withRouter";

const { innerWidth: width, innerHeight: height } = window;

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600, // breakpoint for small screens
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    }
});

const styles = {
    paper: {
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
        height: '100vh'
    },
    mainDiv: {
        // backgroundColor: '#0098cd',
        // height: height,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        // position: 'absolute',
        overflowY: 'hidden',
        overflowX: 'hidden'
    },
    photoContainer: {
        height: '100vh',
        backgroundColor: 'rgba(111, 111, 111, 0.2)',
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up("sm")]: {
            justifyContent: 'center',
        },
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute'
    },
    infoText: {
        fontSize: 18,
        paddingTop: 10,
        color: "#fff",
        textAlign: "center",
    },
    textContainer: {
        display: 'inline-block',
        position: 'relative',
        textAlign: 'center',
        padding: 10,
        zIndex: 100
    },
    cameraRectangle: {
        height: height / 1.7,
        width: width / 1.3,
        border: '4px solid #fff',
        display: 'inline-block',
        position: 'relative',
        zIndex: 101
    },
    buttonContainer: {
        display: 'inline-block',
        position: 'relative',
        zIndex: 100
    },
    roundButton: {
        margin: 20,
        width: height / 15,
    },
    roundButtonCircle: {
        width: '100%',
        height: 0,
        paddingBottom: '100%',
        borderRadius: '50%',
        border: '10px solid #fff',
        overflow: 'hidden',
        background: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`
    }
};

class SelfieCamera extends Component {
    constructor() {
        super();
        this.webcamRef = React.createRef();
        this.state = {
            openTestList: false,
            testtypes: [],
            parentWidth: 0,
            mode: ""
        }
        this.myInput = React.createRef()
        this.onImageChange = this.onImageChange.bind(this);
    };

    componentDidMount() {
        console.log(this.myInput.current.offsetWidth)
        // let param = _.isEmpty(this.props.history.location.state) !== true ? this.props.history.location.state.reTakeSelfie : ""
        let param = ""
        this.setState({ mode: param })
        window.addEventListener('resize', this.handleResize());
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize());
    };

    handleResize = (event) => {
        this.setState({ parentWidth: this.myInput.current.offsetWidth })
    };

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    onImageChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            let baseString = await this.toBase64(img)
            this.props.dispatch({ type: ADD_USER_SELFIE, data: URL.createObjectURL(img), base64: baseString });
            if (this.state.mode === "reTakeSelfie") {
                this.props.navigate("/id-card-selfie-verification")
            }
            else {
                this.props.navigate("/selfie-verification-details")
            }
        }
    };

    base64toBlob = (dataURI, type) => {
        // convert base64 to raw binary data held in a string
        let byteString = atob(dataURI.split(',')[1]);
        // separate out the mime component
        // let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        let bb = new Blob([ab], { type: type });
        return bb;
    };

    capture = async () => {
        const imageSrc = await this.webcamRef.current.getScreenshot();

        let filepath = await this.base64toBlob(imageSrc, "image/jpeg");

        this.setState({ imgSrc: URL.createObjectURL(filepath) })

        this.props.dispatch({ type: ADD_USER_SELFIE, data: URL.createObjectURL(filepath), base64: imageSrc.split(',')[1] });

        if (this.state.mode === "reTakeSelfie") {
            this.props.navigate("/id-card-selfie-verification")
        }
        else {
            this.props.navigate("/selfie-verification-details")
        }
    };

    render() {
        const videoConstraints = {
            width: 1280,
            height: 720,
            facingMode: "user"
        };
        let isMobileView = isMobile();
        return (
            <div style={styles.paper}>
                <Grid container justifyContent="center">
                    {isMobileView === true ?
                        <Grid item xs={12} ref={this.myInput}>
                            <div style={{ ...styles.photoContainer, width: this.state.parentWidth }}>
                                <div style={styles.textContainer}>
                                    <Typography style={styles.infoText}>
                                        Align your face in the center of the frame and take a picture
                                    </Typography>
                                </div>
                                <div style={styles.cameraRectangle}>
                                </div>
                                <div style={styles.buttonContainer} onClick={() => this.capture()}>
                                    <div style={styles.roundButton}>
                                        <div style={styles.roundButtonCircle}>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={styles.mainDiv}>
                                <Webcam
                                    height={720}
                                    width={1280}
                                    audio={false}
                                    ref={this.webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                />
                            </div>
                        </Grid>
                        :
                        <Grid item xs={12} ref={this.myInput}>
                            <div style={{ ...styles.photoContainer, width: this.state.parentWidth, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={styles.textContainer}>
                                    <Typography style={styles.infoText}>
                                        Upload an image of yourself
                                    </Typography>
                                </div>
                                <div>
                                    <input type="file" name="myImage" onChange={this.onImageChange} accept=".jpg,.jpeg,.png" />
                                </div>
                            </div>
                        </Grid>
                    }
                </Grid>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {

    };
};

export default withRouter(connect(mapStateToProps)(SelfieCamera));