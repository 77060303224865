import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { TextField, Button, IconButton, Tab, Tabs, MenuItem, Paper, Grid, Card, Dialog, CircularProgress, Input, Typography } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { fetchRegisteredUserTestResultByPhone, fetchUnProcessedTestResult, resendSMS, searchUserTestResultByPhone } from "../../actions/userAction";
import { ALERT_DETAIL, USER_TEST_RESULTS } from "../../actions/actionTypes";
import moment from "moment";
import InputMask from 'react-input-mask';
import _ from "underscore";
import { withRouter } from "../../withRouter";
import theme from "../../theme";
const TEST_RESULT_VALUE = { "POSITIVE": "Detected", "NEGATIVE": "Not Detected", "INCONCLUSIVE": "Invalid", "PENDING": 'Pending' }

/**Created common styles for UI.*/
const styles = {
	addButton: {
		backgroundColor: theme.palette.primary.main,
		color: "white",
		fontFamily: "unicode.futurab",
		marginRight: "20",
		fontSize: 13,
		fontWeight: "bold",
		'&:hover': {
			backgroundColor: 'rgba(32,71,142, 0.8)',
		}
	},

	inputRoot: {
		backgroundColor: "#FFFFFF",
		border: "1px solid #E1E7ED",
		borderRadius: "4px",
		fontSize: "15px !important",
		fontFamily: "Lato-Regular !important",
		"&:hover": {
			border: "1px solid #0E465E",
			backgroundColor: "#FFFFFF"
		}
	},
	inputStyle: {
		fontFamily: "Lato-Regular !important",
		fontSize: "15px !important",
		padding: "10px !important",
		color: "#0E465E",
		opacity: 1,
		"&&:after": {
			color: "#0E465E",
		}
	},
	underline: {
		"&&&:before": {
			borderBottom: "none"
		},
		"&&:after": {
			borderBottom: "none"
		}
	},
	itemContainer: {
		boxShadow: "2px 2px 2px 2px #D1CFCF",
		margin: 10,
		padding: 10
	},
	testTitle: {
		fontSize: 14
	},
	resultText: {
		fontSize: 15,
		fontWeight: "600",
	},
	dateText: {
		fontSize: 12
	},
	dialogPaper: {
		backgroundColor: "rgba(0,0,0,0)",
		boxShadow: "none",
		overflow: "hidden"
	},
	buttons: {
		padding: "5px 15px",
		borderRadius: 10,
		fontSize: 15,
		fontWeight: "bold",
		backgroundColor: theme.palette.primary.main,
		color: "#ffffff",
		'&:hover': {
			backgroundColor: '#5186E7',
		}
	},
};

// const CssTextField = withStyles({
// 	root: {
// 		'& label.Mui-focused': {
// 			color: '#4B86E3',
// 		},
// 		'& .MuiInput-underline:after': {
// 			borderBottomColor: '#4B86E3',
// 		},
// 		'& .MuiOutlinedInput-root': {
// 			'& fieldset': {
// 				borderColor: '#4B86E3',
// 			},
// 			'&:hover fieldset': {
// 				borderColor: '#4B86E3',
// 			},
// 			'&.Mui-focused fieldset': {
// 				borderColor: '#4B86E3',
// 			},
// 		},
// 	},
// })(TextField);

/**
 * @class
 *
 * Class representing user test result component
 *
 */
class SupportHome extends Component {
	constructor() {
		super();
		this.state = {
			phone: "",
			selectedTab: 0,
			selectedUser: null,
			selected_id: "",
			firstName: "",
			lastName: "",
			dateOfBirth: "",
			openDialog: false,
			allUserProfiles: [],
			labUsers: [],
			selectedLabUser: null,
			selected_lab_test_id: "",
			isLoading: false,
			allLabUsers: [],
			unprocessedResults: [],
			profileLoading: false
		}
	}

	handleChange = (event, newValue) => {
		this.setState({
			selectedTab: newValue
		})
	};

	search = () => {
		let isError = false;
		if ((this.state.firstName === "") &&
			(this.state.lastName === "") &&
			(this.state.phone === "") &&
			(this.state.dateOfBirth === "")) {
			this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Please enter values to search", severity: "error" })
			isError = true;
		}

		if ((this.state.phone !== "" && this.state.phone !== null) && (this.state.phone.length !== 10)) {
			this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Please enter valid phone number", severity: "error" });
			isError = true;
		}

		if ((this.state.firstName !== "" && this.state.firstName !== null) && (this.state.firstName.length < 3)) {
			this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Please enter minimum 3 characters to search first name", severity: "error" });
			isError = true;
		}

		if ((this.state.lastName !== "" && this.state.lastName !== null) && (this.state.lastName.length < 3)) {
			this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Please enter minimum 3 characters to search last name", severity: "error" });
			isError = true;
		}

		if ((this.state.dateOfBirth !== "" && this.state.dateOfBirth !== null)) {
			if (new Date(this.state.dateOfBirth) === 'Invalid Date' || this.state.dateOfBirth.length < 10 || !moment(this.state.dateOfBirth).isValid()) {
				this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Please enter valid date of birth", severity: "error" });
				isError = true;
			}
		}

		if (isError === false) {
			this.setState({
				selectedTab: 0,
				selectedUser: null,
				selected_id: "",
				pendingLabTests: [],
				allUserProfiles: [],
				labUsers: [],
				selectedLabUser: null,
				selected_lab_test_id: "",
				allLabUsers: [],
				isLoading: true,
				unprocessedResults: []
			})
			this.props.dispatch({ type: USER_TEST_RESULTS, data: null })
			let url = `?phone=${this.state.phone}&firstName=${this.state.firstName}&lastName=${this.state.lastName}&dateOfBirth=${this.state.dateOfBirth}`
			this.props.dispatch(searchUserTestResultByPhone(this, url))
		}
	}

	clear = () => {
		this.setState({
			phone: "",
			selectedTab: 0,
			selectedUser: null,
			selected_id: "",
			firstName: "",
			lastName: "",
			dateOfBirth: "",
			pendingLabTests: [],
			allUserProfiles: [],
			labUsers: [],
			selectedLabUser: null,
			selected_lab_test_id: "",
			allLabUsers: [],
			openDialog: false,
			isLoading: false,
			unprocessedResults: []
		})
		this.props.dispatch({ type: USER_TEST_RESULTS, data: null })
	}

	selectedUserTestResult = (event) => {
		const { allUserProfiles } = this.state;
		let findUser = allUserProfiles.find(x => x.id === parseInt(event.target.value))
		this.setState({ selected_id: event.target.value, selectedUser: findUser !== undefined ? findUser : null })
		if (findUser !== undefined) {
			this.props.dispatch(fetchUnProcessedTestResult(this, findUser))
		}
	}

	selectedLabUserTestResult = (event) => {
		const { allLabUsers } = this.state;
		let findLabUser = allLabUsers.find(x => x.id === parseInt(event.target.value))
		let groupLabUsers = (allLabUsers !== null && allLabUsers !== undefined) ? _.groupBy(allLabUsers, "dateOfBirth") : [];
		this.setState({ selected_lab_test_id: event.target.value, selectedLabUser: findLabUser !== undefined ? findLabUser : null, labUsers: findLabUser !== undefined ? groupLabUsers[findLabUser.dateOfBirth] : [] })
		if (findLabUser !== undefined) {
			this.props.dispatch(fetchUnProcessedTestResult(this, findLabUser))
		}
	}

	onChangeDate = (event) => {
		this.setState({
			dateOfBirth: event.target.value,
		});
	}

	selectParentUser = (x, id) => {
		let parentUser = this.props.user_test_results.groupUserProfiles[x] !== undefined ? this.props.user_test_results.groupUserProfiles[x].length > 1 ? this.props.user_test_results.groupUserProfiles[x].find(x => x.isParent === true) : this.props.user_test_results.groupUserProfiles[x][0] : null;
		let labUsers = this.props.user_test_results.groupLabTests[x] !== undefined ? _.sortBy(this.props.user_test_results.groupLabTests[x], 'dateOfBirth') : null;
		if (parentUser !== null) {
			if (parentUser !== undefined) {
				this.setState({ openDialog: false, selectedTab: 0, selectedUser: parentUser, selected_id: parentUser.id, allUserProfiles: this.props.user_test_results.groupUserProfiles[x], labUsers: labUsers, selectedLabUser: null })
				this.props.dispatch(fetchUnProcessedTestResult(this, parentUser))
				if ((!!this.state.firstName || !!this.state.lastName || !!this.state.dateOfBirth)) {
					this.setState({ profileLoading: true })
					this.props.dispatch(fetchRegisteredUserTestResultByPhone(this, parentUser))
				}
			} else {
				parentUser = this.props.user_test_results.groupUserProfiles[x].find(x => x.id === id);
				this.setState({ openDialog: false, selectedTab: 0, selectedUser: parentUser, selected_id: parentUser.id, allUserProfiles: this.props.user_test_results.groupUserProfiles[x], labUsers: labUsers, selectedLabUser: null })
				this.props.dispatch(fetchUnProcessedTestResult(this, parentUser))
				if ((!!this.state.firstName || !!this.state.lastName || !!this.state.dateOfBirth)) {
					this.setState({ profileLoading: true })
					this.props.dispatch(fetchRegisteredUserTestResultByPhone(this, parentUser))
				}
			}

		} else {
			let selectedLabUser = (labUsers !== null && labUsers !== undefined) ? labUsers[0] : null;
			let groupLabUsers = (labUsers !== null && labUsers !== undefined) ? _.groupBy(labUsers, "dateOfBirth") : [];
			this.setState({ openDialog: false, selectedTab: 1, labUsers: selectedLabUser !== null ? groupLabUsers[selectedLabUser.dateOfBirth] : [], allLabUsers: labUsers, selectedUser: null, selected_id: "", allUserProfiles: [], selectedLabUser: selectedLabUser, selected_lab_test_id: selectedLabUser !== null ? selectedLabUser.id : "" })
			if (selectedLabUser !== null) {
				this.props.dispatch(fetchUnProcessedTestResult(this, selectedLabUser))
			}
		}
	}

	returnParentUser = (x) => {
		let users = this.props.user_test_results.groupUserProfiles[x] !== undefined ? this.props.user_test_results.groupUserProfiles[x] : null;
		if (users !== null && users !== undefined) {
			return (
				users.map(parentUser => <Grid item xs={12} md={4} key={x.toString()} >
					<Card style={{ backgroundColor: "#DDFFD4", padding: 10, cursor: "pointer" }} onClick={() => this.selectParentUser(x, parentUser.id)}>
						<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{parentUser.firstName + " " + parentUser.lastName}</Typography>
						<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{parentUser.phone}</Typography>
						<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{parentUser.email}</Typography>
						<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{parentUser.dateOfBirth !== null ? moment(parentUser.dateOfBirth).format("MM/DD/YYYY") : ""}</Typography>
						<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{parentUser.Gender}</Typography>
						<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold", color: "green" }}>REGISTERED</Typography>
					</Card>
				</Grid>)
			)
		}
	}

	returnLabParentUser = (x) => {
		let parentUser = this.props.user_test_results.groupUserProfiles[x] !== undefined ? this.props.user_test_results.groupUserProfiles[x] : null;
		let sortUser = this.props.user_test_results.groupLabTests[x] !== undefined ? _.sortBy(this.props.user_test_results.groupLabTests[x], 'dateOfBirth') : null;
		if ((parentUser === null || parentUser === undefined) && sortUser !== null && sortUser !== undefined && sortUser.length > 0) {
			let parentUser = sortUser[0]
			return (
				<Grid item xs={12} md={4} key={x.toString()} onClick={() => this.selectParentUser(x, parentUser.id)}>
					<Card style={{ backgroundColor: "#FFDDDD", padding: 10, cursor: "pointer" }}>
						<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{parentUser.firstName + " " + parentUser.lastName}</Typography>
						<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{parentUser.phoneNumber}</Typography>
						<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{parentUser.email}</Typography>
						<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{parentUser.dateOfBirth !== null ? moment(parentUser.dateOfBirth).format("MM/DD/YYYY") : ""}</Typography>
						<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{parentUser.gender}</Typography>
						<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold", color: "red" }}>UNREGISTERED</Typography>
					</Card>
				</Grid>
			)
		}
	}

	sendLoginSMS = (phone) => {
		let data = {};
		data.phone = phone;
		data.login = true;
		this.props.dispatch(resendSMS(this, data))
	}

	sendRegisterSMS = (phone) => {
		let data = {};
		data.phone = phone;
		data.login = false;
		this.props.dispatch(resendSMS(this, data))
	}

	render() {
		const {
			// styles, 
			user_test_results } = this.props;
		const { labUsers, allLabUsers } = this.state;
		return (
			<div style={styles.paper}>
				<div>
					<div style={{ display: "flex", justifyContent: "space-between", margin: "0px 15px" }}>
						<Typography style={{ textAlign: "center", fontWeight: "bold", padding: "10px 0px", color: theme.palette.secondary.light, fontSize: 18, textTransform: "uppercase" }}>User Test Results</Typography>
					</div>
				</div>
				<div style={{ margin: "0px 15px" }}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={3}>
							<Typography style={{ fontSize: 13 }}>Phone Number</Typography>
							<TextField
								fullWidth
								variant="standard"
								autoComplete="off"
								value={this.state.phone}
								onChange={event =>
									this.setState({ phone: event.target.value.toString().replace(/\D/g, "") })
								}
								InputLabelProps={{
									shrink: true,
								}}
								autoFocus
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<Typography style={{ fontSize: 13 }}>First Name</Typography>
							<TextField
								fullWidth
								variant="standard"
								autoComplete="off"
								value={this.state.firstName}
								onChange={event =>
									this.setState({ firstName: event.target.value })
								}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<Typography style={{ fontSize: 13 }}>Last Name</Typography>
							<TextField
								fullWidth
								autoComplete="off"
								variant="standard"
								value={this.state.lastName}
								onChange={event =>
									this.setState({ lastName: event.target.value })
								}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} md={3}>
							<div style={{ display: "flex" }}>
								<div>
									<Typography style={{ fontSize: 13 }}>Date of Birth</Typography>
									<InputMask
										placeholder={"MM/DD/YYYY"}
										fullWidth
										style={{ borderBottomStyle: 'solid', color: "rgba(0,0,0,0.9)", borderBottomWidth: 1, borderBottomColor: 'gray' }}
										type={'datetime'}
										mask="99/99/9999"
										maskChar={null}
										value={this.state.dateOfBirth}
										onChange={this.onChangeDate} >
										{(inputProps) => <Input {...inputProps} type="datetime" disableUnderline onChange={this.props.onChange} />}
									</InputMask>
								</div>
								<IconButton onClick={() => this.clear()}><HighlightOffIcon /></IconButton>
								<Button size="small" onClick={() => this.search()} variant="contained" color="secondary" style={styles.addButton} >Search</Button>
							</div>
						</Grid>

					</Grid>
				</div>
				{(user_test_results !== null) ?
					<div style={{ margin: "10px 15px" }}>
						{(this.state.selectedUser !== null && this.state.selectedUser !== undefined) &&
							<Grid container spacing={1}>
								<Grid item xs={12} md={8}>
									<Grid container spacing={2}>
										<Grid item xs={12} md={4}>
											<Card style={{ backgroundColor: "#DDFFD4", padding: 10 }}>
												<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{this.state.selectedUser.firstName + " " + this.state.selectedUser.lastName}</Typography>
												<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{this.state.selectedUser.phone}</Typography>
												<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{this.state.selectedUser.email}</Typography>
												<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{this.state.selectedUser.dateOfBirth !== null ? moment(this.state.selectedUser.dateOfBirth).format("MM/DD/YYYY") : ""}</Typography>
												<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{this.state.selectedUser.Gender}</Typography>
												<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold", color: "green" }}>REGISTERED</Typography>
												<Button style={styles.buttons} onClick={() => this.sendLoginSMS(this.state.selectedUser.phone)}>
													Resend SMS
												</Button>
											</Card>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} md={3}>
									{!this.state.profileLoading ? ((this.state.allUserProfiles !== null && this.state.allUserProfiles.length > 1) ? (
										<div>
											<Typography style={{ fontSize: 15, color: "#000000", padding: "10px 0px" }}>Select user to view the results</Typography>
											<TextField
												fullWidth
												size="small"
												id="select"
												name="select"
												autoComplete="off"
												required={true}
												value={this.state.selected_id}
												variant="filled"
												InputProps={{ styles: { input: styles.inputStyle, underline: styles.underline, root: styles.inputRoot } }}
												onChange={(event) => this.selectedUserTestResult(event)}
												select
											>
												{this.state.allUserProfiles.map(item => <MenuItem key={item.id} value={item.id}>{item.firstName + " " + item.lastName}</MenuItem>)}
											</TextField>
										</div>
									) :
										<Typography style={{ fontWeight: "bold", padding: 10 }}>Only one profile available for this result.</Typography>
									) :
										<CircularProgress color="primary" size={40} />}
								</Grid>
							</Grid>}
						{(this.state.selectedLabUser !== null && this.state.selectedLabUser !== undefined) &&
							<Grid container spacing={1}>
								<Grid item xs={12} md={8}>
									<Grid container spacing={2}>
										<Grid item xs={12} md={4}>
											<Card style={{ backgroundColor: "#FFDDDD", padding: 10 }}>
												<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{this.state.selectedLabUser.firstName + " " + this.state.selectedLabUser.lastName}</Typography>
												<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{this.state.selectedLabUser.phoneNumber}</Typography>
												<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{this.state.selectedLabUser.email}</Typography>
												<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{this.state.selectedLabUser.dateOfBirth !== null ? moment(this.state.selectedLabUser.dateOfBirth).format("MM/DD/YYYY") : ""}</Typography>
												<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold" }}>{this.state.selectedLabUser.gender}</Typography>
												<Typography style={{ fontSize: 14, padding: 2, fontWeight: "bold", color: "red" }}>UNREGISTERED</Typography>
												<Button style={styles.buttons} onClick={() => this.sendRegisterSMS(this.state.selectedLabUser.phoneNumber)}>
													Resend SMS
												</Button>
											</Card>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} md={3}>
									{(allLabUsers !== null && allLabUsers.length > 1) && (
										<div>
											<Typography style={{ fontSize: 15, color: "#000000", padding: "10px 0px" }}>Select user to view the results</Typography>
											<TextField
												fullWidth
												size="small"
												id="select-lab"
												name="select-lab"
												autoComplete="off"
												required={true}
												value={this.state.selected_lab_test_id}
												variant="filled"
												InputProps={{ styles: { input: styles.inputStyle, underline: styles.underline, root: styles.inputRoot } }}
												onChange={(event) => this.selectedLabUserTestResult(event)}
												select
											>
												{allLabUsers.map(item => <MenuItem key={item.id} value={item.id}>{item.firstName + " " + item.lastName}</MenuItem>)}
											</TextField>
										</div>
									)
									}
								</Grid>
							</Grid>}
						<Tabs
							value={this.state.selectedTab}
							onChange={this.handleChange}
							indicatorColor="primary"
							textColor="primary"
							fontWeight="900"
							style={{ margin: 0, backgroundColor: 'white', width: "100%" }}
						>
							<Tab fullWidth
								label={<span style={{ fontSize: 15, color: "#20478E", fontWeight: "bold" }}>Profile Test Results</span>}>
							</Tab>
							<Tab fullWidth
								label={<Typography style={{ fontSize: 15, color: "#20478E", fontWeight: "bold" }}><span >Queue Test Results</span> {(labUsers !== null && labUsers !== undefined && labUsers.length > 0) && <span style={{ fontSize: 15, color: "#ffffff", fontWeight: "bold", backgroundColor: "red", borderRadius: 20, padding: "2px 10px" }}>{labUsers.length}</span>}</Typography>}>
							</Tab>
							<Tab fullWidth style={{ maxWidth: 300 }}
								label={<Typography style={{ fontSize: 15, color: "#20478E", fontWeight: "bold" }}><span >Unprocessed Test Results</span>{(this.state.unprocessedResults !== null && this.state.unprocessedResults !== undefined && this.state.unprocessedResults.length > 0) && <span style={{ fontSize: 15, color: "#ffffff", fontWeight: "bold", backgroundColor: "red", borderRadius: 20, padding: "2px 10px" }}>{this.state.unprocessedResults.length}</span>}</Typography>}>
							</Tab>
						</Tabs>
						<div>
							{this.state.selectedTab === 0 ?
								(this.state.selectedUser !== null ?
									<div>
										<div>
											<Paper style={{ padding: 15, margin: 10 }}>
												<Typography style={{ fontWeight: "bold", fontSize: 14, padding: 5 }}> Test Results</Typography>
												<Grid container>
													{this.state.selectedUser.testResults.sort((a, b) => Date.parse(b.dateOfTest) - Date.parse(a.dateOfTest)).map(test =>
														test.testType.isVaccine === false && <Grid key={test.id} item xs={12} md={6}>
															<div style={styles.itemContainer}>
																<div style={{ display: "flex", justifyContent: "space-between" }}>
																	{test.testType.isVaccine === true ?
																		<Typography style={styles.testTitle}>{test.testType.name} {test.shotNumber !== null ? ("Shot n°" + test.shotNumber) : ""}</Typography>
																		:
																		<Typography style={styles.testTitle}>{test.testType.name} </Typography>
																	}
																</div>
																<div style={{ padding: 5 }}>
																	{test.testType.type === "TEST" &&
																		(test.testType.isAntibody === true ?
																			<Typography style={styles.resultText}>
																				{test.testResultItems.length > 0 ? test.testResultItems[0].result_value === "POSITIVE" ? "Antibody Detected" : "No Antibody Detected" : "-"}
																			</Typography>
																			:
																			<Typography style={styles.resultText}>
																				{test.testResultItems.length > 0 ? TEST_RESULT_VALUE[test.testResultItems[0].result_value] : "-"}
																			</Typography>
																		)}
																	<Typography style={styles.dateText}>Test Date: {test.dateOfTest !== null ? `${moment(test.dateOfTest).format("MM/DD/YYYY")}` : "----"}</Typography>
																</div>
															</div>
														</Grid>)}
												</Grid>
											</Paper>
										</div>
									</div>
									:
									<div>
										<Typography style={{ fontSize: 16, padding: 5, textAlign: "center" }}>There are no test results available.</Typography>
									</div>) :
								this.state.selectedTab === 1 ? (<div>
									{(labUsers !== null && labUsers !== undefined && labUsers.length > 0) ?
										<div>
											<Paper style={{ padding: 15, margin: 10 }}>
												<Typography style={{ fontWeight: "bold", fontSize: 14, padding: 5 }}> Test Results</Typography>
												<Grid container>
													{labUsers.map(test =>
														<Grid key={test.id} item xs={12} md={6}>
															<div style={styles.itemContainer}>
																<div style={{ display: "flex", justifyContent: "space-between" }}>
																	<Typography style={styles.testTitle}>Name: <span style={{ fontWeight: "bold" }}>{test.firstName + " " + test.lastName}</span> </Typography>
																	<Typography style={styles.testTitle}>DOB: <span style={{ fontWeight: "bold" }}>{test.dateOfBirth !== null ? moment(test.dateOfBirth).format("MM/DD/YYYY") : "-"}</span> </Typography>
																</div>
																<div style={{ display: "flex", justifyContent: "space-between", paddingTop: 10 }}>
																	<Typography style={styles.testTitle}>{test.testName} </Typography>
																</div>
																<div style={{ padding: 5 }}>
																	<Typography style={styles.resultText}>
																		{test.resultText}
																	</Typography>
																	<Typography style={styles.dateText}>Test Date: {test.orderDate !== null ? `${moment(test.orderDate).format("MM/DD/YYYY")}` : "----"}</Typography>
																</div>
															</div>
														</Grid>)}
												</Grid>
											</Paper>
										</div> :
										<div>
											<Typography style={{ fontSize: 16, padding: 5, textAlign: "center" }}>There are no test results available.</Typography></div>}
								</div>)
									: (<div>
										{(this.state.unprocessedResults !== null && this.state.unprocessedResults !== undefined && this.state.unprocessedResults.length > 0) ?
											<div>
												<Paper style={{ padding: 15, margin: 10 }}>
													<Typography style={{ fontWeight: "bold", fontSize: 14, padding: 5 }}> Test Results</Typography>
													<Grid container>
														{this.state.unprocessedResults.map(test =>
															<Grid key={test.id} item xs={12} md={6}>
																<div style={styles.itemContainer}>
																	<div style={{ display: "flex", justifyContent: "space-between" }}>
																		<Typography style={styles.testTitle}>Name: <span style={{ fontWeight: "bold" }}>{test.firstName + " " + test.lastName}</span> </Typography>
																		<Typography style={styles.testTitle}>DOB: <span style={{ fontWeight: "bold" }}>{test.dateOfBirth !== null ? moment(test.dateOfBirth).format("MM/DD/YYYY") : "-"}</span> </Typography>
																	</div>
																	<div style={{ display: "flex", justifyContent: "space-between", paddingTop: 10 }}>
																		<Typography style={styles.testTitle}>{test.testName} </Typography>
																	</div>
																	<div style={{ padding: 5 }}>
																		<Typography style={styles.resultText}>
																			{test.resultText}
																		</Typography>
																		<Typography style={styles.dateText}>Test Date: {test.orderDate !== null ? `${moment(test.orderDate).format("MM/DD/YYYY")}` : "----"}</Typography>
																	</div>
																</div>
															</Grid>)}
													</Grid>
												</Paper>
											</div> :
											<div>
												<Typography style={{ fontSize: 16, padding: 5, textAlign: "center",marginTop:10 }}>There are no test results available.</Typography></div>}
									</div>)}
						</div>
					</div> :
					<div>
						<Typography style={{ fontSize: 16, padding: 5, textAlign: "center",marginTop:100 }}>There are no available data to show.</Typography>
					</div>}
				<Dialog open={this.state.isLoading}
					styles={{
						paper: styles.dialogPaper,
					}}
				>
					<div style={{ boxShadow: 0, overflow: "none" }}>
						<CircularProgress color="primary" size={40} />
					</div>
				</Dialog>
				<Dialog onClose={() => this.clear()} open={this.state.openDialog} fullWidth maxWidth={"md"}>
					{user_test_results !== null && <Paper style={{ padding: 10, margin: 10 }}>
						<Grid container spacing={2}>
							{Object.keys(user_test_results.groupUserProfiles).map(x => this.returnParentUser(x))}
							{Object.keys(user_test_results.groupLabTests).map(x => this.returnLabParentUser(x))}
						</Grid>
					</Paper>}
				</Dialog>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user_test_results: state.userReducer.user_test_results
	};
};

SupportHome.propTypes = {
// 	styles: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(SupportHome));