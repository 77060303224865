import React, { Component } from "react";
import { Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "../../withRouter";
import theme from "../../theme";

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        backgroundColor: "#fff",
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    topContainer: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageView: {
        width: 210,
        height: 250,
        elevation: 2,
        padding: 15,
        borderRadius: 15,
        backgroundColor: "#fff",
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageStyle: {
        height: "100%",
        width: "100%",
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    textView: {
        padding: "15px 5px 15px 5px",
    },
    topText: {
        fontSize: 20,
        textAlign: "center",
    },
    bottomText: {
        fontSize: 15,
        paddingTop: 10,
        color:"#000",
        textAlign: "center",
    },
    submitButton: {
        backgroundColor: theme.palette.primary.main,
        width: "90%",
        padding: 15,
        borderRadius: 5,
        justifyContent: "center",
        alignItems: "center",
        margin: "70px 0 0 0",
        color: "#fff",
        textAlign: "center",
        fontSize: 16,
        textTransform: 'uppercase'
    },
    retakeButton: {
        backgroundColor: "#B6BCB9",
        width: "90%",
        padding: 15,
        borderRadius: 5,
        justifyContent: "center",
        alignItems: "center",
        margin: "10px 0 0 0",
        color: "#fff",
        textAlign: "center",
        fontSize: 16,
        textTransform: 'uppercase'
    }
};

class IDVerificationDetails extends Component {
    constructor() {
        super();
        this.state = {

        }
    }

    render() {

        return (
            <Grid container justifyContent="center" style={{ display: "flex", flexDirection: 'row' }}>
                <Grid item xs={12} md={4} sm={6}>
                    <div style={styles.container}>
                        <div style={styles.imageView}>
                            <img style={styles.imageStyle} src={this.props.id_file} alt="icon" />
                        </div>
                        <Typography style={styles.textView}>
                            <Typography style={styles.topText}>Is this clear enough?</Typography>
                            <Typography style={styles.bottomText}>Ensure that your ID is sharp and the photo is free from blurriness.</Typography>
                        </Typography>
                        <Typography style={styles.submitButton} onClick={() => this.props.navigate("/selfie-verification")}>
                            Continue
                        </Typography>
                        <Typography style={styles.retakeButton} onClick={() => this.props.navigate("/id-camera")}>
                            Retake Photo
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        id_file: state.registerReducer.id_file
    };
};

IDVerificationDetails.propTypes = {

};

export default withRouter(connect(mapStateToProps)(IDVerificationDetails));