import API from '../api/index';
import { LOGIN_SUCCESS, AUTHENTICATION_ERROR, AUTHENTICATION_SUCCESS, ALERT_DETAIL } from "./actionTypes";
import { fetchUser } from './userAction'


/**
 * This action creator used for login
 * @function
 * @param {self} self 
 * @param {data} data
 * @returns dispatch
 */
export function login(data, self) {

	return dispatch => API.post('users/login', data)
		.then((response) => {
			if (response.status === "success") {
				// console.log("*******response*****"+JSON.stringify(response))
				window.localStorage.setItem('token', JSON.stringify(response.token));
				dispatch({ type: LOGIN_SUCCESS, data: response.payload })
				if (response.isChangePassword === true) {
					self.props.navigate("/change-password")
				} else {
					if (response.payload.userType === "PSV") {
						self.props.navigate("/partner-dashboard")
					} else if (response.payload.userType === "OSV") {
						self.props.navigate("/organization-dashboard")
					} else if (response.payload.userType === "DNR") {
						self.props.navigate("/donor-dashboard")
					} else if (response.payload.userType === "CST") {
						self.props.navigate("/support/home")
					} else if (response.payload.userType === "Admin" || response.payload.userType === "ADM") {
						self.props.navigate("/dashboard")
					} else {
						if (response.info === "UID" && response.payload.userType !== "Admin" && response.payload.userType !== "ADM") {
							dispatch({ type: ALERT_DETAIL, data: true, message: "Missing Your ID", severity: "success" })
							self.props.navigate("/id-verification")
						} else {
							self.props.navigate("/my-activity")
						}
						// if (response.payload.userType === "CLT") {
						// 	self.props.history.push("/user/home")
						// } else {
						// 	self.props.history.push("/my-activity")
						// }
					}
				}

			} else {
				dispatch({ type: ALERT_DETAIL, data: true, message: "Please check your information and try again.", severity: "error" })
			}
		});
}

/**
 * This action creator used for unauthenticate
 * @function
 * @returns dispatch
 */
export function unauthenticate() {
	return (dispatch) => {
		dispatch({
			type: AUTHENTICATION_ERROR
		});
		localStorage.removeItem('token');
		localStorage.removeItem('roleCode');
	}
}

/**
 * This action creator used for authenticate
 * @function
 * @returns dispatch
 */
export function authenticate() {
	return (dispatch) => {
		return API.get('users/authenticate')
			.then((response) => {
				if (response.status === "success") {
					localStorage.setItem('token', JSON.stringify(response.token));
					dispatch(fetchUser())
					dispatch({
						type: AUTHENTICATION_SUCCESS,
						data: response.payload,
						role: response.payload.userType
					});

				} else {
					dispatch({
						type: AUTHENTICATION_ERROR,
						loginError: null,
					});
				}
			})
			.catch((e) => {
				dispatch({
					type: AUTHENTICATION_ERROR,
					loginError: null,
				});
			});
	};
}

/**
 * This action creator used for logout
 * @function
 * @param {self} self 
 * @returns dispatch
 */
export function logout(self) {
	return (dispatch) => {
		dispatch({
			type: AUTHENTICATION_ERROR
		});
		localStorage.removeItem('token');
		localStorage.removeItem('roleCode');
		window.location = "/";
	}
}

/**
 * This action creator used for change password
 * @function
 * @param {self} self 
 * @param {data} data
 * @returns dispatch
 */
export function changePassword(data, self) {
	return dispatch => API.post('users/change-password', data)
		.then((response) => {
			if (response.status === "success") {
				window.localStorage.setItem('token', JSON.stringify(response.token));
				dispatch({ type: LOGIN_SUCCESS, data: response.payload })
				if (response.payload.userType === "PSV") {
					self.props.navigate("/partner-dashboard")
				} else if (response.payload.userType === "OSV") {
					self.props.navigate("/organization-dashboard")
				} else if (response.payload.userType === "DNR") {
					self.props.navigate("/donor-dashboard")
				} else if (response.payload.userType === "CST") {
					self.props.navigate("/support/home")
				} else if (response.payload.userType === "Admin" || response.payload.userType === "ADM") {
					self.props.navigate("/dashboard")
				} else {
					// if (response.info === "UID" && response.payload.userType !== "Admin" && response.payload.userType !== "ADM") {
					// 	dispatch({ type: ALERT_DETAIL, data: true, message: "Missing Your ID", severity: "info" })
					// 	self.props.history.push("/id-verification")
					// } else {
					// 	self.props.history.push("/my-activity")
					// }
					if (response.payload.userType === "CLT") {
						self.props.navigate("/user/home")
					} else {
						self.props.navigate("/my-activity")
					}
				}
			} else {
				dispatch({ type: ALERT_DETAIL, data: true, message: "Please check your information and try again.", severity: "error" })
			}
		});
}