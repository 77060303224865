import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Typography, Grid, Button, Badge } from "@mui/material";
import { fetchUserProfile } from "../../actions/userAction";
import { fetchMyActivity } from "../../actions/activityAction";
import { fetchUnverifiedRecord, fetchTestResultsValues } from "../../actions/testAction";
import { END_POINT, THEMECOLOR } from "../../helpers/Constants";
import moment from 'moment';
import User from "../../assets/images/account_circle.png";
import { withRouter } from "../../withRouter";

/** Created common styles for UI  */
const styles = {
	paper: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		minHeight: '100vh',
	},
	topContainer: {
		// backgroundColor: "#20478E",
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
		height: "200px",
		padding: 25,
		borderBottomLeftRadius: "10%",
		borderBottomRightRadius: "10%"
	},
	row: {
		display: "flex",
		justifyContent: "center",
	},
	bottomContainer: {
		marginTop: -40,
		backgroundColor: "#fff",
		borderRadius: 5,
		margin: "0px 30px",
		padding: "10px",
		boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
		transition: "0.3s",
		marginBottom: 70,
	},
	userImage: {
		width: "100%",
		height: "100%",
		borderRadius: 15,
		objectFit: "cover"
	},
	textStyle: {
		fontSize: 15,
		color: '#fff',
		paddingLeft: 11,
		padding: "5px 0px",
		fontWeight: '700'
	},
	divider: {
		width: "100%",
		height: 1,
		backgroundColor: "#D1CFCF"
	},
	button: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		marginTop: 10,
		padding: 7,
		fontSize: 15,
		backgroundColor: '#fff',
		color: "#35A773",
		border: `2px solid ${THEMECOLOR.PRIMARY}`,
		textTransform: "capitalize",
		fontWeight: "600",
		borderRadius: 8,
		'&:hover': {
			backgroundColor: THEMECOLOR.PRIMARY,
		},
	},
	buttons: {
		marginTop: 10,
		padding: 7,
		fontSize: 15,
		backgroundColor: '#fff',
		border: `2px solid ${THEMECOLOR.PRIMARY}`,
		color: "#35A773",
		textTransform: "capitalize",
		// fontWeight: "bold",
		borderRadius: 8,
		'&:hover': {
			backgroundColor: THEMECOLOR.PRIMARY,
		},
	},
	adminButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		marginTop: 10,
		padding: 7,
		fontSize: 15,
		width: "300px",
		backgroundColor: "#9C1A8D",
		color: "#fff",
		fontWeight: "bold",
		borderRadius: 5,
		'&:hover': {
			backgroundColor: "#4B86E3",
		},
	},
}

/**
 * @class
 *
 * Class representing my activity component
 *
 */
class Activity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_profile: null,
			userType: null
		}
	}

	/** This is a component life cycle method and used to assigning props values to state variable  */
	static getDerivedStateFromProps(props, state) {
		if (props.user_profile !== state.user_profile) {
			return {
				user_profile: props.user_profile,
				userType: props.user_profile.userType
			}
		}
		return null;
	};

	/** This is a component life cycle method and used to fetch datas  */
	componentDidMount() {
		/** fetching user profile details. */
		this.props.dispatch(fetchUserProfile(this));
		/** fetching my activity details. */
		this.props.dispatch(fetchMyActivity());
		/** fetching test result details. */
		this.props.dispatch(fetchTestResultsValues());
	};

	/**
	 * @function
	 *
	 * function representing fetching unverified health  reacords and redireting to the TestHistory screen.
	 *
	 */
	openUnverifiedHealthRecords = () => {
		this.props.dispatch(fetchUnverifiedRecord(this, this.props.user_profile.id));
		this.props.navigate("/test-history", { 'unverified_health_records': 'UnverifiedHealthRecords', 'id': this.props.user_profile.id })
	};

	/** rendering user profile and test result information. */
	render() {
		const { user_profile } = this.props;
		let imageurl = (user_profile !== null && user_profile.imageStorageKey !== null) ? `${END_POINT}image/i/${user_profile.imageStorageKey}` : null;
		let unVerifideHealthRecords = this.props.fetch_test_results.filter((item) => item.status === "PENDING")
		return (
			<div style={styles.paper}>
				<Grid container justifyContent={"center"}>
					<Grid item xs={12}>
						<div style={styles.topContainer}>
							<div style={styles.row}>
								<div style={{
									width: "80px",
									height: "80px",
									borderBottomLeftRadius: 15,
									borderBottomRightRadius: 15,
									borderTopLeftRadius: 15,
									// border: this.state.userType === "CLT" ? '5px solid #A6FFC7' : this.state.userType === "MSP" ? '5px solid #4DC87A' : '5px solid #F0C2B6',
									// backgroundColor: this.state.userType === "CLT" ? '#A6FFC7' : this.state.userType === "MSP" ? '#4DC87A' : '#F0C2B6'
									backgroundColor: '#3B6832',
									border: '2px solid #3B6832',
								}}>
									{imageurl !== null ?
										<img
											alt="Logo"
											src={imageurl}
											style={styles.userImage}
										/> :
										<img src={User} height="80" width="80" alt="icon" style={{
											backgroundColor: "white", border: "1px solid #ddd",
											borderRadius: "10px", objectFit: "cover"
										}} />}
								</div>
								<div>
									<div style={{
										marginBottom: 5,
										// backgroundColor: this.state.userType === "CLT" ? '#A6FFC7' : this.state.userType === "MSP" ? '#4DC87A' : '#F0C2B6',
										backgroundColor: '#3B6832',
										border: '2px solid #3B6832',
										borderBottomRightRadius: 15,
										borderTopRightRadius: 15,
										// border: this.state.userType === "CLT" ? '5px solid #A6FFC7' : this.state.userType === "MSP" ? '5px solid #4DC87A' : '5px solid #F0C2B6',
									}}>
										<Typography style={styles.textStyle}>{this.state.userType !== null && (
											this.state.userType === "CLT" ?
												"User"
												:
												this.state.userType === "MSP" ?
													"Medical Service Provider"
													:
													"Verifier")}
										</Typography>
									</div>
									<Typography style={{
										fontSize: 17,
										paddingLeft: 10,
										color: "#fff",
										padding: "10px",
										fontWeight: "bold"
									}} >
										{user_profile !== null && `${user_profile.firstName} ${user_profile.lastName}`}
									</Typography>
								</div>
							</div>
							<Grid container justifyContent={"center"}>
								<Grid item xs={12} sm={6} md={3}>
									{this.state.userType !== null && this.state.userType === "MSP" ?
										<Button
											type="Button"
											fullWidth
											variant="contained"
											//href={"/qr-scanner"}
											onClick={() => this.props.navigate("/qr-scanner")}
											style={styles.button}
										>
											Verify Patient's Records
										</Button>
										:
										<div>
											{this.state.userType !== null && this.state.userType === "VFR" ?
												<Button
													type="Button"
													fullWidth
													variant="contained"
													//href={"/qr-scanner"}
													onClick={() => this.props.navigate("/qr-scanner")}
													style={styles.adminButton}
												>
													Scan User’s Badge
												</Button>
												:
												<Grid item xs={12}>
													<div style={{ float: "right" }}>
														<Badge badgeContent={unVerifideHealthRecords.length} color="secondary">
														</Badge>
													</div>
													<Button
														type="Button"
														fullWidth
														variant="contained"
														//href={"/qr-scanner"}
														style={styles.buttons}
														onClick={() => this.openUnverifiedHealthRecords()}
													>
														View My Unverified Records
													</Button>
												</Grid>
											}
										</div>
									}
								</Grid>
							</Grid>
						</div>
						<Grid container justifyContent={"center"} style={{ marginTop: -40 }}>
							<Grid item xs={12} sm={6} md={4}>
								<div style={{
									backgroundColor: "#fff",
									borderRadius: 5,
									margin: "0px 30px",
									padding: "10px",
									boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
									transition: "0.3s",
									marginBottom: 70,
									// width: this.props.myActivities.length === 0 ? window.screen.width >= 1280 ? '40%' : '100%' : null
								}} >
									<Typography style={{ ...styles.textStyle, color: '#000' }}>My Activity</Typography>
									<div style={styles.divider} />
									<div>
										{this.props.myActivities.length > 0 ?
											this.props.myActivities.reverse().map((activity, index) =>
												<div key={index} style={{ padding: 10 }}>
													<Typography style={{ fontSize: 16 }}>
														{activity.description}
													</Typography>
													<Typography style={{ color: "#000", fontSize: 13 }}>
														{moment(activity.created).format("MMM DD, YYYY hh:mm A")}
													</Typography>
													<div style={styles.divider} />
												</div>
											) :
											<Typography style={{ color: "rgba(12, 31, 22, 0.3)" }}>There are no activities</Typography>
										}
									</div>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		)
	}
};

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {
		user_profile: state.userReducer.user_profile,
		user_type: state.sessionReducer.user_type,
		myActivities: state.activityReducer.myActivities,
		fetch_test_results: state.testReducer.fetch_test_results
	};
};

Activity.propTypes = {
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(Activity));