import React, { Component } from "react";
import { Typography, Grid, Button } from "@mui/material";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import Circle from "../../assets/images/circle.svg";
import { fetchUnverifiedRecord } from "../../actions/testAction";
import { TAB_SELECTED } from "../../actions/actionTypes";
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { THEMECOLOR } from "../../helpers/Constants";

/** Created common styles for UI  */
const styles = {
	paper: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
		height: '100vh',
		padding: 20,
		paddingBottom: window.screen.width >= 640 ? 80 : 20
	},
	container: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundColor: "#FFF",
		margin: 10,
		borderRadius: 10,
		height: "80vh"
	},
	buttonContainer: {
		padding: 30
	},
	button: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		marginTop: 5,
		padding: 7,
		fontSize: 15,
		backgroundColor: THEMECOLOR.SECONDARY,
		color: "#fff",
		fontWeight: "bold",
		borderRadius: 5,
		'&:hover': {
			backgroundColor: "#4B86E3",
		}
	},
	secondButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		marginTop: 5,
		padding: 7,
		fontSize: 15,
		backgroundColor: theme.palette.editButton.main,
		color: "#fff",
		fontWeight: "bold",
		borderRadius: 5,
		'&:hover': {
			backgroundColor: "#B0BAD7",
		}
	},
	textStyle: {
		textAlign: "center",
		color: "#000",
		fontSize: 15,
		fontWeight:"bold"
	},
	centerStyle: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: 30
	},
	imgStyle: {
		height: 100,
		weight: 100
	}
};

/**
 * @class
 *
 * Class representing TestSuccess component
 *
 */
class TestSuccess extends Component {
	constructor() {
		super();
		this.state = {
			queue: ""
		}
	}

	/** This is a component life cycle method and used to fetch datas*/
	componentDidMount() {
		let queue = localStorage.getItem('queue')
		this.setState({ queue: queue })
	};

	/** This is a component life cycle method and used to call when component leave*/
	componentWillUnmount() {
		localStorage.removeItem('queue');
	};

	/**
	 * @function
	 *
	 * function representing fetching unverified health  reacords and redireting to the TestHistory screen.
	 *
	 */
	unverifiedRecord = () => {
		let id = this.props.test_result !== null ? this.props.test_result.userProfileId : null
		this.props.dispatch(fetchUnverifiedRecord(this, id))
		this.props.navigate("/test-history", { "from_scan_result": "from_scan_result", "id": id })
	};

	/**
	* @function
	*
	* function representing  redireting to the my activity screen.
	*
	*/
	returnToHome = () => {
		this.props.navigate("/my-activity")
		this.props.dispatch({ type: TAB_SELECTED, data: 0 })
	};

	/** rendering TestSccess Screen. */
	render() {
		// const { styles } = this.props;
		// let imageUrl = verification_test !== null ? `${END_POINT}image/${verification_test.testType.isVaccine === true ? "v" : "t"}/${verification_test.storageKey}` : null
		return (
			<div style={styles.paper}>
				<Grid container justifyContent="center">
					<Grid item xs={12} sm={6} md={4}>
						<div style={styles.container}>
							<div>
								<div style={styles.centerStyle}>
									<img src={Circle} alt="success" style={styles.imgStyle} />
								</div>
								<Typography style={styles.textStyle}>Test results updated successfully</Typography>
								<div style={styles.centerStyle}>
									<Button
										type="Button"
										fullWidth
										variant="contained"
										onClick={() => this.unverifiedRecord()}
										style={{ ...styles.button, textTransform: 'capitalize' }}
									>
										Same Patient – Other Unverified Records
									</Button>
								</div>
							</div>
							<div style={styles.buttonContainer}>
								<Button
									type="Button"
									fullWidth
									variant="contained"
									onClick={() => this.props.navigate(this.state.queue === "queue" ? "/my-queue" : "/qr-scanner")}
									style={{ ...styles.button, textTransform: 'capitalize' }}
								>
									{this.state.queue === "queue" ? "Return to the Queue" : "Scan Another Patient"}
								</Button>
								<Button
									type="Button"
									fullWidth
									variant="contained"
									onClick={() => this.returnToHome()}
									style={{ ...styles.secondButton, textTransform: 'capitalize' }}
								>
									Return Home
								</Button>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}
};

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {
		test_result: state.testReducer.test_result,
	};
};

TestSuccess.propTypes = {
	styles: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(TestSuccess));