import api from '../api';
import { FETCH_SETTINGS, ALERT_DETAIL, PREMIUM_USER_SUPPORT } from "./actionTypes"

export function fetchSettings() {
    return function (dispatch) {
        api.get("setting").then((response) => {
            if (response.status === "success") {
                dispatch({
                    type: FETCH_SETTINGS,
                    data: response.payload
                });
            }
        });
    }
}

export function addSettings(self, item) {
    return dispatch => {
        api.post('setting', item).then((response) => {
            if (response.status === "success") {
                dispatch(fetchSettings())
                dispatch({ type: ALERT_DETAIL, data: true, message: "Setting User added successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    dialogOpen: false,
                    id: null,
                    name: "",
                    name_error: false,
                    code: "",
                    code_error: false,
                    value: false,
                    value_error: false,
                    descriptions: "",
                    status: "ACTIVE",
                    status_error: false,
                    valueType: "BOOLEAN"
                })
            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}

export function updateSettings(self, data, code) {
    return dispatch => {
        api.put('setting/' + code, data).then((response) => {
            if (response.status === "success") {
                dispatch(fetchSettings());
                dispatch({ type: ALERT_DETAIL, data: true, message: "Setting user updated successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    dialogOpen: false,
                    id: null,
                    name: "",
                    name_error: false,
                    code: "",
                    code_error: false,
                    value: false,
                    value_error: false,
                    description: "",
                    status: "ACTIVE",
                    status_error: false,
                    valueType: "BOOLEAN"
                })
            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}

export function fetchSettingByCode(code) {
    return function (dispatch) {
        api.get("setting/"+code).then((response) => {
            if (response.status === "success") {
                dispatch({
                    type: PREMIUM_USER_SUPPORT,
                    data: response.payload
                });
            }
        });
    }
}