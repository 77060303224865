import {
	LOGIN_SUCCESS, AUTHENTICATION_ERROR, AUTHENTICATION_SUCCESS, TAB_SELECTED, ALERT_DETAIL,
	USER_CONFIRMATION_ID
} from "../actions/actionTypes";

const initialState = {
	isAuthenticated: false,
	isLoginSuccess: false,
	user: {},
	user_type: null,
	selectedTab: 0,
	openAlert: false,
	alertSeverity: null,
	alertMessage: null,
	userConfirmationId: null
};

export default function sessionReducer(state = initialState, action) {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				user_type: action.data.userType
			}
		case AUTHENTICATION_SUCCESS:
			return {
				...state,
				isLoginSuccess: true,
				user: action.data,
				user_type: action.data.userType,
			}
		case AUTHENTICATION_ERROR:
			return {
				...state,
				isLoginSuccess: false,
				user: {},
				defaultRole: {},
				user_type: null
			};
		case TAB_SELECTED:
			return {
				...state,
				selectedTab: action.data,
			};
		case ALERT_DETAIL:
			return {
				...state,
				openAlert: action.data,
				alertSeverity: action.severity,
				alertMessage: action.message,
			}
		case USER_CONFIRMATION_ID:
			return {
				...state,
				userConfirmationId: action.data,
			};
		default:
			return state;
	}
}