import { Button, Grid, Input as MaterialInput, TextField, Typography } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import InputMask from 'react-input-mask';
import { connect } from "react-redux";
import { ALERT_DETAIL } from "../../actions/actionTypes";
import { uploadTestResult } from "../../actions/testAction";
import User from "../../assets/images/account_circle.png";
import { END_POINT, THEMECOLOR } from "../../helpers/Constants";
import { withRouter } from "../../withRouter";

/** Created common styles for UI  */
const styles = {
	paper: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
		height: '100vh',
		padding: 20
	},
	container: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundColor: "#FFF",
		margin: 30,
		borderRadius: 10,
		minHeight: 300
	},
	row: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex"
	},
	userImage: {
		height: 130,
		width: "auto",
		marginTop: -30,
		borderRadius: 10
	},
	typeText: {
		fontSize: 15,
		paddingTop: 10,
		textAlign: "center",
		color: "#848484"
	},
	nameText: {
		fontSize: 17,
		paddingTop: 5,
		paddingBottom: 10,
		textAlign: "center",
		color: "#000",
		fontWeight: "bold"
	},
	divider: {
		width: "100%",
		height: 1,
		backgroundColor: "#D1CFCF"
	},
	innerContainer: {
		padding: 20
	},
	testText: {
		fontSize: 15,
		paddingTop: 3,
		color: "#6F7FAF"
	},
	resultText: {
		fontSize: 15,
		paddingTop: 3,
		color: "#000"
	},
	dateText: {
		fontSize: 13,
		paddingTop: 3,
		color: "#96A1C4"
	},
	buttonContainer: {
		padding: "0px 30px"
	},
	button: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		marginTop: 5,
		padding: 7,
		fontSize: 15,
		backgroundColor: THEMECOLOR.PRIMARY,
		color: "#fff",
		fontWeight: "bold",
		borderRadius: 5,
		'&:hover': {
			backgroundColor: THEMECOLOR.PRIMARY,
		},
	},
	secondButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		marginTop: 5,
		padding: 7,
		fontSize: 15,
		backgroundColor: "#B0BAD7",
		color: "#fff",
		fontWeight: "bold",
		borderRadius: 5,
		'&:hover': {
			backgroundColor: "#B0BAD7",
		},
	},
	rowContainer: {
		padding: 5,
		display: "flex",
		justifyContent: "space-between"
	},
	selectedButton: {
		cursor: "pointer",
		width: 120,
		backgroundColor: THEMECOLOR.PRIMARY,
		padding: 5,
		borderRadius: 10
	},
	unselectedButton: {
		cursor: "pointer",
		width: 120,
		borderWidth: 1,
		borderColor: "#BFC7DF",
		padding: 5,
		borderRadius: 10
	},
	buttonText: {
		color: "#fff",
		textAlign: "center",
		fontSize: 14,
	},
	unselectedButtonText: {
		// color: "#B0BAD7",
		color: "#35A773",
		textAlign: "center",
		fontSize: 14,
	},
	textView: {
		padding: "10px 15px"
	}
};

/**
 * @class
 *
 * Class representing EnterTestResult component
 *
 */
class EnterTestResult extends Component {
	constructor() {
		super();
		this.state = {
			testResultArr: [],
			time_of_test: null,
			date_of_test: null,
			date_of_test_error: false,
			verification_test: null
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.verification_test !== state.verification_test) {
			return {
				// testResultArr: props.verification_test.testResultItems,
				// //date_of_test: moment(props.verification_test.dateOfTest).format("MM/DD/YYYY"),
				// time_of_test: props.verification_test.timeOfTest !== null ? moment(props.verification_test, "hh:mm A").format("hh:mm A") : null,
			}
		}
		return null;
	};

	/**
	 * @function
	 *
	 * function representing change result value.
	 * @param {Object} data
	 * @param {String} value
	 */
	changeResultValue = (data, value) => {
		let testResultArr = []
		let isExist = this.state.testResultArr.find(x => x.testTypeItemId === data.id)
		if (isExist !== undefined) {
			isExist.result_value = value
			testResultArr = this.state.testResultArr.map(x => x.testTypeItemId === data.id ? isExist : x)
		} else {
			let testResultItemObj = {}
			testResultItemObj.testTypeItemId = data.id;
			testResultItemObj.result_value = value;
			testResultArr.push(testResultItemObj)
		}
		this.setState({ testResultArr: testResultArr })
	};

	/**
	 * @function
	 *
	 * function representing update TestResult.
	 *
	 */
	nextButtonAction = () => {
		//submit test
		let { testResultArr, date_of_test, time_of_test } = this.state;
		let isError = false;
		if (testResultArr.length > 0) {
			if (date_of_test !== "" && date_of_test !== null && date_of_test !== undefined) {
				let date = moment(date_of_test, "MM/DD/YYYY").format("YYYY-MM-DD")
				let todayData = new Date()
				let today = moment(todayData).format("YYYY-MM-DD")
				if (!moment(date).isValid()) {
					this.setState({ date_of_test_error: true, errorText: "Please enter valid date(MM/DD/YYYY)" });
					isError = true;
				} else if (moment(date, "YYYY-MM-DD").isAfter(today)) {
					this.setState({ date_of_test_error: true, errorText: "Future date not allowed" });
					isError = true;
				} else if (moment(date, "YYYY-MM-DD").isBefore("2020-01-01")) {
					this.setState({ date_of_test_error: true, errorText: "Before 01/01/2020 date not allowed" });
					isError = true;
				}
			} else {
				isError = true;
				this.setState({ date_of_test_error: true, errorText: "Please enter date of test" })
			}
			if (isError === false) {
				let data = {}
				data.testResultId = this.props.verification_test.id;
				data.clientUserProfileId = this.props.verification_test.clientUserProfileId;
				data.testResultValues = testResultArr;
				data.dateOfTest = moment(date_of_test, "MM/DD/YYYY").format("YYYY-MM-DD");
				data.timeOfTest = time_of_test;
				this.props.dispatch(uploadTestResult(this, data))
			}
		} else {
			this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Please select test result.​​", severity: "error" })
		}
	};

	/**
	 * @function
	 *
	 * function representing return string.
	 * @param {Object} data
	 */
	returnValue = (data) => {
		let isExist = this.state.testResultArr.find(x => x.testTypeItemId === data.id)
		if (isExist !== undefined) {
			return isExist.result_value
		} else {
			return null
		}
	};

	onChange = (event) => {
		this.setState({
			date_of_test: event.target.value,
			date_of_test_error: false
		});

		if (this.props.onChange) {
			this.props.onChange(event);
		}
	};

	/** Rendering EnterTestResult Screen  . */
	render() {
		const { verification_test, test_result } = this.props;
		let imageurl = (test_result !== null && test_result.userImage !== null) ? `${END_POINT}image/i/${test_result.userImage}` : null;
		return (
			<div style={styles.paper}>
				<Grid container justifyContent="center">
					{verification_test !== null && test_result !== null &&
						<Grid item xs={12} sm={6} md={4}>
							<div style={styles.container}>
								<div style={styles.row}>
									{imageurl !== null ?
										<img src={imageurl} alt="profile" style={styles.image} />
										:
										<img src={User} height="80" width="80" alt="profile" style={{
											backgroundColor: "white", border: "1px solid #ddd",
											borderRadius: "10px", objectFit: "cover"
										}} />}
								</div>
								<div>
									<Typography style={styles.nameText}>{test_result.firstName + " " + test_result.lastName}</Typography>
								</div>
								<div style={styles.divider} />
								<div style={styles.textView}>
									<Typography style={{ fontSize: 16, color: "gray" }}>Date of Test</Typography>
									<InputMask
										placeholder={"MM/DD/YYYY"}
										fullWidth
										style={{ borderBottomStyle: 'solid', color: "rgba(0,0,0,0.9)", borderBottomWidth: 1, borderBottomColor: 'gray' }}
										mask="99/99/9999"
										maskChar={null}
										value={this.state.date_of_test}
										onChange={this.onChange}>
										{(inputProps) => <MaterialInput {...inputProps} type="datetime" />}
									</InputMask>
									{this.state.date_of_test_error ? <Typography style={{ color: '#F44336', fontSize: 12 }}>{this.state.errorText} </Typography> : null}
								</div>
								<div style={styles.textView}>
									<Typography style={{ fontSize: 16, color: "gray" }}>Time of Test</Typography>
									<TextField
										id="time"
										type="time"
										style={styles.textField}
										value={this.state.time_of_test}
										InputLabelProps={{
											shrink: true,
										}}
										onChange={(event) => this.setState({ time_of_test: event.target.value })}
										fullWidth
										inputProps={{
											step: 300, // 5 min
										}}
									/>
								</div>
								<div style={styles.innerContainer}>
									{verification_test?.testType?.testTypeItems.map(testTypeItem =>
										<div>
											<Typography style={{ ...styles.nameText, fontWeight: "bold", textAlign: "left" }}>{testTypeItem.name}</Typography>
											{testTypeItem.valueType === "BOOLEAN" &&
												<div style={styles.rowContainer}>
													<div style={this.returnValue(testTypeItem) === "NEGATIVE" ? styles.selectedButton : styles.unselectedButton} onClick={() => this.changeResultValue(testTypeItem, "NEGATIVE")} >
														<Typography style={this.returnValue(testTypeItem) === "NEGATIVE" ? styles.buttonText : styles.unselectedButtonText}>NEGATIVE</Typography>
													</div>
													<div style={this.returnValue(testTypeItem) === "POSITIVE" ? styles.selectedButton : styles.unselectedButton} onClick={() => this.changeResultValue(testTypeItem, "POSITIVE")} >
														<Typography style={this.returnValue(testTypeItem) === "POSITIVE" ? styles.buttonText : styles.unselectedButtonText}>POSITIVE</Typography>
													</div>
												</div>
											}
										</div>
									)}
								</div>
							</div>
							<div style={{ padding: "0px 30px" }}>
								<Button
									type="Button"
									fullWidth
									variant="contained"
									color="primary"
									style={styles.button}
									onClick={() => this.nextButtonAction()}
								>
									Continue
								</Button>
							</div>
						</Grid>
					}
				</Grid>
			</div>
		);
	}
};

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {
		verification_test: state.testReducer.verification_test,
		test_result: state.testReducer.test_result,
	};
};

EnterTestResult.propTypes = {

};

export default withRouter(connect(mapStateToProps)(EnterTestResult));