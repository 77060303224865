import { FETCH_VACCINEMANUFACTURER } from "../actions/actionTypes"

const initialstate = {
    vaccinemanufacturer: []
}

export default function vaccineManufacturerReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_VACCINEMANUFACTURER:
            return {
                ...state,
                vaccinemanufacturer: action.data
            }
        default:
            return state;
    }
}