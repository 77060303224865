import React, { Component } from "react";
import { Typography, Grid, Button, Dialog, CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import { fetchUserProfile } from "../../actions/userAction";
import { uploadTestByClient, uploadVaccineTestByClient } from "../../actions/testAction";
import { SELECTED_TEST_TYPE_IMAGE } from "../../actions/actionTypes";
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { THEMECOLOR } from "../../helpers/Constants";

/** Created common styles for UI  */
const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		backgroundColor: "#fff",
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	topContainer: {
		justifyContent: 'center',
		alignItems: 'center'
	},
	imageView: {
		width: 210,
		height: 250,
		elevation: 2,
		padding: 15,
		borderRadius: 15,
		backgroundColor: "#fff",
		justifyContent: 'center',
		alignItems: 'center'
	},
	imageStyle: {
		height: "100%",
		width: "100%",
		borderRadius: 10,
		justifyContent: 'center',
		alignItems: 'center'
	},
	textView: {
		paddingHorizontal: 15,
		paddingTop: 30
	},
	topText: {
		fontSize: 22,
		textAlign: "center",

	},
	bottomText: {
		fontSize: 18,
		paddingTop: 10,
		color: "#000",
		textAlign: "center",
	},
	bottomViewText: {
		fontSize: 18,
		paddingTop: 2,
		color: "#000",
		textAlign: "center",
	},
	bottomContainer: {
		flex: 1,
		width: "90%",
		justifyContent: "center",
		alignItems: "center"
	},
	submitButton: {
		backgroundColor: THEMECOLOR.PRIMARY,
		width: "90%",
		padding: 15,
		borderRadius: 10,
		justifyContent: "center",
		alignItems: "center",
		margin: "10px 30px"
	},
	retakeButton: {
		marginVertical: 10,
		backgroundColor: theme.palette.editButton.main,
		width: "90%",
		padding: 15,
		borderRadius: 10,
		justifyContent: "center",
		alignItems: "center",
		margin: "0px 30px"
	},
	buttonText: {
		color: "#fff",
		textAlign: "center",
		fontSize: 16,
	},
	dialogPaper: {
		backgroundColor: "rgba(0,0,0,0)",
		boxShadow: "none",
		overflow: "hidden"
	}
};

/**
 * @class
 *
 * Class representing upload test image verification component
 *
 */
class UploadedTestImageVerification extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_profile: null,
			userType: null,
			isLoading: false
		}
		this.handleUnload = this.handleUnload.bind(this);
	}

	/**This is a component life cycle method and used to assigning props values to state variable.*/
	static getDerivedStateFromProps(props, state) {
		if (props.user_profile !== state.user_profile) {
			return {
				user_profile: props.user_profile,
				userType: props.user_profile.userType
			}
		}
		return null;
	};

	/** This is a component life cycle method and used to fetch datas  */
	componentDidMount() {
		window.addEventListener('beforeunload', this.handleUnload);
		if (this.props.selected_test_type === null) {
			window.location = "/my-health";
		}
		this.props.dispatch(fetchUserProfile(this));
	};

	/** This is a component life cycle method and used to remove event  */
	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.handleUnload);
	};

	handleUnload(e) {
		var message = "\o/";
		(e || window.event).returnValue = message;
		return message;
	};

	/**
	* @function
	*
	* function representing upload image to server.
	*/
	uploadDocument = () => {
		const { userType } = this.state;
		if (this.props.selected_test_type !== null) {
			if (userType === 'CLT') {
				// let clientId = this.props.user_profile !== null ? this.props.user_profile.id : null
				this.setState({ buttonVisible: false, isLoading: true })
				if (this.props.selected_test_type.isVaccine === true) {
					this.props.navigate("/user-vaccination")
				} else {
					this.props.navigate("/user-enter-test-result")
					// this.props.dispatch(uploadTestByClient(this, clientId))
				}
			} else {
				this.setState({ buttonVisible: false, isLoading: true })
				let clientId = this.props.selected_test_type !== null ? this.props.test_result.userProfileId : null
				if (this.props.selected_test_type.isVaccine === true) {
					this.props.dispatch(uploadVaccineTestByClient(this, clientId))
				} else {
					this.props.dispatch(uploadTestByClient(this, clientId))
				}
			}
		}
	};

	/**
		* @function
		*
		* function representing retake photo.
		*/
	reTakePhoto = () => {
		this.props.dispatch({ type: SELECTED_TEST_TYPE_IMAGE, data: null });
		this.props.navigate("/upload-test-type-image");
	}

	/** Rendering uploaded test image information. */
	render() {
		// const { styles, } = this.props;
		return (
			<Grid container justifyContent="center" style={{ display: "flex", flexDirection: 'row' }}>
				<Grid item xs={12} md={4} sm={6}>
					<div style={styles.container}>
						<div style={styles.imageView}>
							<img style={styles.imageStyle} src={this.props.selected_test_type_image} alt="icon" />
						</div>
						<div style={styles.textView}>
							<Typography style={styles.topText}>Is this clear enough?</Typography>
							<Typography style={styles.bottomText}>Make sure the full document is in </Typography>
							<Typography style={styles.bottomViewText}>view and the picture is not blurry. </Typography>
						</div>
						<Button style={styles.submitButton} onClick={() => this.uploadDocument()}>
							{this.props.selected_test_type !== null && (this.props.selected_test_type.isVaccine === true ?
								<Typography style={styles.buttonText}>
									Next
								</Typography>
								:
								<Typography style={styles.buttonText}>
									Continue
								</Typography>
							)}
						</Button>
						<Button style={styles.retakeButton} onClick={() => this.reTakePhoto()}>
							<Typography style={styles.buttonText}>
								Retake Photo
							</Typography>
						</Button>
					</div>
				</Grid>
				<Dialog open={this.state.isLoading}
					styles={{
						paper: styles.dialogPaper,
					}}
				>
					<div style={{ boxShadow: 0, overflow: "none" }}>
						<CircularProgress color="primary" size={40} />
					</div>
				</Dialog>
			</Grid>
		);
	}
}

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {
		selected_test_type: state.testReducer.selected_test_type,
		selected_test_type_image: state.testReducer.selected_test_type_image,
		user_profile: state.userReducer.user_profile,
		test_result: state.testReducer.test_result,
	};
};

export default withRouter(connect(mapStateToProps)(UploadedTestImageVerification));