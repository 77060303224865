import api from '../api';
import { FETCH_PARTNER, ALERT_DETAIL, FETCH_PARTNER_DETAIL } from "./actionTypes"

export function fetchPartner() {
    return function (dispatch) {
        api.get('partner').then((response) => {
            if (response.status === "success") {
                dispatch({
                    type: FETCH_PARTNER,
                    data: response.payload
                });
            }
        });
    }
}

export function fetchPartnerDetail() {
    return function (dispatch) {
        api.get('partner/my/organization').then((response) => {
            if (response.status === "success") {
                dispatch({
                    type: FETCH_PARTNER_DETAIL,
                    data: response.payload
                });
            }
        });
    }
}

export function addPartner(self, data) {
    return dispatch => {
        api.post('partner', data).then((response) => {
            if (response.status === "success") {
                dispatch(fetchPartner())
                dispatch({ type: ALERT_DETAIL, data: true, message: "Partner added successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    dialogOpen: false,
                    id: 0,
                    name: "",
                    name_error: false,
                    email: "",
                    email_error: false,
                    invalid_email_error: false,
                    phone_number: "",
                    phone_number_error: false,
                    invalid_phone_number_error: false,
                    contact_person_name: "",
                    contact_person_name_error: false,
                    address_line1: "",
                    address_line1_error: false,
                    address_line2: "",
                    address_line2_error: false,
                    city: "",
                    city_error: false,
                    selectedState: "",
                    selectedState_error: false,
                    country: "United States of America",
                    country_error: false,
                    zipcode: "",
                    zipcode_error: false,
                    invalid_zipcode_error: false,
                    status: "ACTIVE",
                    status_error: false,
                    mode: "",
                    search_phone: "",
                    invalid_search_phone_error: false,
                    search_phone_error: false,
                    primary_contact_first_name: "",
                    primary_contact_first_name_error: false,
                    primary_contact_last_name: "",
                    primary_contact_last_name_error: false,
                    primary_contact_email: "",
                    primary_contact_email_error: false,
                    primary_contact_gender: "",
                    primary_contact_gender_error: "",
                    primary_contact_data_of_birth: "",
                    primary_contact_data_of_birth_error: false,
                    invalid_primary_contact_data_of_birth_error: false,
                    primary_contact_zip_code: "",
                    primary_contact_zip_code_error: "",
                    primary_contact_country_code: "+1",
                    errorText: "",
                    invalid_primary_contact_email_error: false
                })
            } else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}


export function updatePartner(self, data, id) {
    return dispatch => {
        api.put('partner/' + id, data).then((response) => {
            if (response.status === "success") {
                dispatch(fetchPartner());
                dispatch({ type: ALERT_DETAIL, data: true, message: "Partner updated successfully", severity: "success" })
                self.setState({
                    dialogOpen: false,
                    id: 0,
                    name: "",
                    name_error: false,
                    email: "",
                    email_error: false,
                    invalid_email_error: false,
                    phone_number: "",
                    phone_number_error: false,
                    invalid_phone_number_error: false,
                    contact_person_name: "",
                    contact_person_name_error: false,
                    address_line1: "",
                    address_line1_error: false,
                    address_line2: "",
                    address_line2_error: false,
                    city: "",
                    city_error: false,
                    selectedState: "",
                    selectedState_error: false,
                    country: "United States of America",
                    country_error: false,
                    zipcode: "",
                    zipcode_error: false,
                    invalid_zipcode_error: false,
                    status: "ACTIVE",
                    status_error: false,
                    mode: "",
                    search_phone: "",
                    invalid_search_phone_error: false,
                    search_phone_error: false,
                    primary_contact_first_name: "",
                    primary_contact_first_name_error: false,
                    primary_contact_last_name: "",
                    primary_contact_last_name_error: false,
                    primary_contact_email: "",
                    primary_contact_email_error: false,
                    primary_contact_gender: "",
                    primary_contact_gender_error: "",
                    primary_contact_data_of_birth: "",
                    primary_contact_data_of_birth_error: false,
                    invalid_primary_contact_data_of_birth_error: false,
                    primary_contact_zip_code: "",
                    primary_contact_zip_code_error: "",
                    primary_contact_country_code: "+1",
                    errorText: "",
                    invalid_primary_contact_email_error: false,
                    isLoading: false,
                })
            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}