import { FETCH_ORGANIZATION, FETCH_ORGANIZATION_DETAIL, ORGANIZATION_PLAN, ORGANIZATION_PLAN_BALANCE, ORGANIZATION_MEMBER_PLAN_ASSIGNED, UPDATED_ORGANIZATION_PLAN_BALANCE, REMOVE_ORGANIZATION_MEMBER, FETCH_ORGANIZATION_DETAIL_BY_ID } from "../actions/actionTypes"


const initialstate = {
    organization: [],
    organizationDetail: null,
    organizationPlan: [],
    organizationPlanBalance: [],
    organizationMembers: [],
    organizationDetailyByID: null
}

export default function organizationReducer(state = initialstate, action) {
    switch (action.type) {

        case FETCH_ORGANIZATION:
            return {
                ...state,
                organization: action.data
            }
        case FETCH_ORGANIZATION_DETAIL:
            return {
                ...state,
                organizationDetail: action.data,
                organizationMembers: action.data.organizationMembers
            }
        case ORGANIZATION_PLAN:
            return {
                ...state,
                organizationPlan: action.data
            }
        case ORGANIZATION_PLAN_BALANCE:
            return {
                ...state,
                organizationPlanBalance: action.data
            }
        case ORGANIZATION_MEMBER_PLAN_ASSIGNED:
            return {
                ...state,
                organizationMembers: state.organizationMembers.map(x => x.id === action.data.id ? action.data : x)
            }
        case REMOVE_ORGANIZATION_MEMBER:
            return {
                ...state,
                organizationMembers: state.organizationMembers.filter(x => x.id !== action.data.id)
            }
        case UPDATED_ORGANIZATION_PLAN_BALANCE:
            return {
                ...state,
                organizationPlanBalance: state.organizationPlanBalance.map(x => x.id === action.data.id ? action.data : x)
            }
        case FETCH_ORGANIZATION_DETAIL_BY_ID:
            return {
                ...state,
                organizationDetailyByID: action.data
            }
        default:
            return state;
    }
}