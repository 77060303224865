import api from '../api';
import { FETCH_VACCINESHOT, ALERT_DETAIL } from "./actionTypes"
import { fetchVaccine } from "./vaccineAction"

/** 
 * Get Vaccine Shot entries from server
 * @function
 * @returns dispatch
 */
export function fetchVaccineShot() {
	return function (dispatch) {
		api.get('vaccine-shot').then((response) => {
			if (response.status === "success") {
				dispatch({
					type: FETCH_VACCINESHOT,
					data: response.payload
				});
			}
		});
	}
}

/**
 * This action creator used for create Vaccine Shot to server
 * @function
 * @param {self} self 
 * @param {item} item 
 * @returns dispatch
 */
export function addVaccineShot(self, item) {
	return dispatch => {
		api.post('vaccine-shot', item).then((response) => {
			if (response.status === "success") {
				dispatch(fetchVaccine());
				dispatch({ type: ALERT_DETAIL, data: true, message: "VaccineShot added successfully", severity: "success" })
				self.setState({
					isLoading: false,
					OpenShotDialog: false,
					selectedVaccineId: null,
					shotId: null,
					day: "",
					notes: "",
					notifyFirstDay: "",
					notifyFirstMessage: "",
					notifySecondDay: "",
					notifySecondMessage: "",
					notifyThirdDay: "",
					notifyThirdMessage: "",
					description: "",
					day_error: false,
					notes_error: false,
					notifyFirstDay_error: false,
					notifyFirstMessage_error: false,
					notifySecondDay_error: false,
					notifySecondMessagey_error: false,
					notifyThirdDayy_error: false,
					notifyThirdMessagey_error: false,
					descriptiony_error: false,
					status: "ACTIVE",
					status_error: false,
					mode: "",
					booster:"NO"
				})
			}
			else {
				self.setState({ isLoading: false, })
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
			}
		})
	}
}

/**
 * This action creator used for update Vaccine Shot to server
 * @function
 * @param {self} self 
 * @param {item} item 
 * @param {id} id 
 * @returns dispatch
 */
export function updataVaccineShot(self, item, id) {
	return dispatch => {
		api.put('vaccine-shot/' + id, item).then((response) => {
			if (response.status === "success") {
				dispatch(fetchVaccine())
				dispatch({ type: ALERT_DETAIL, data: true, message: "VaccineShot updated successfully", severity: "success" })
				self.setState({
					isLoading: false,
					OpenShotDialog: false,
					selectedVaccineId: null,
					shotId: null,
					day: "",
					notes: "",
					notifyFirstDay: "",
					notifyFirstMessage: "",
					notifySecondDay: "",
					notifySecondMessage: "",
					notifyThirdDay: "",
					notifyThirdMessage: "",
					description: "",
					day_error: false,
					notes_error: false,
					notifyFirstDay_error: false,
					notifyFirstMessage_error: false,
					notifySecondDay_error: false,
					notifySecondMessagey_error: false,
					notifyThirdDayy_error: false,
					notifyThirdMessagey_error: false,
					descriptiony_error: false,
					status: "ACTIVE",
					status_error: false,
					mode: ""
				})
			}
			else {
				self.setState({ isLoading: false, })
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
			}
		})
	}
}

/**
 * This action creator used for delete Vaccine Shot to server
 * @function
 * @param {self} self 
 * @param {id} id 
 * @returns dispatch
 */
export function deleteVaccineShot(self, id) {
	return function (dispatch) {
		api.delete('vaccine-shot/' + id).then((response) => {
			if (response.status === "success") {
				dispatch(fetchVaccine());
				self.setState({
					isConfirmationDialog: false,
					selectedVaccineId: null,
					shotId: null,
					day: "",
					notes: "",
					notifyFirstDay: "",
					notifyFirstMessage: "",
					notifySecondDay: "",
					notifySecondMessage: "",
					notifyThirdDay: "",
					notifyThirdMessage: "",
					description: "",
					day_error: false,
					notes_error: false,
					notifyFirstDay_error: false,
					notifyFirstMessage_error: false,
					notifySecondDay_error: false,
					notifySecondMessagey_error: false,
					notifyThirdDayy_error: false,
					notifyThirdMessagey_error: false,
					descriptiony_error: false,
					status: "ACTIVE",
					status_error: false,
					mode: ""
				})
			}
		});
	}
}