import React, { Component } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { connect } from "react-redux";
import verification from '../../assets/images/verification.png';
import IDEA from "../../assets/images/idea.svg";
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { THEMECOLOR } from "../../helpers/Constants";

const DOT_LENGTH = 10;

const styles = {
    paper: {
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        height: '1vh'
    },
    topContainer: {
        // backgroundColor: "#20478E",
        backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.SECONDARY})`,
        height: "200px",
        justifyContent: "center",
        alignItems: "center",
        padding: 25,
        borderBottomLeftRadius: "10%",
        borderBottomRightRadius: "10%",
    },
    bottomContainer: {
        marginTop: -40,
        marginLeft: "20px",
        marginRight: "20px",
        backgroundColor: "#fff",
        borderRadius: 5,
        padding: "10px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s"
    },
    textStyle: {
        fontSize: 20,
        color: "#fff"
    },
    imageView: {
        display: 'flex',
        marginTop: -50,
        borderRadius: 5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    imageStyle: {
        maxWidth: "300px",
        maxHeight: "150px",
        width: "auto",
        height: "auto",
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        marginBottom: 0
    },
    bottomText: {
        fontSize: 15,
        color: "#000",
        textAlign: "center"
    },
    topText: {
        fontSize: 22,
        textAlign: "center",
        paddingTop: 10,
        paddingBottom: 10
    },
    button: {
        padding: 10,
        borderRadius: 5,
        fontSize: 15,
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.main,
        marginTop: 40,
        marginBottom: 10,
    },
    continueText: {
        marginTop: 5,
        fontSize: 15,
        color: "#ffffff",
        textTransform: 'capitalize'
    },
    topTextView: {
        display: 'flex',
        flexDirection: "row",
        paddingHorizontal: 20,
        paddingVertical: 5,
        justifyContent: "center",
        alignItems: "center",
    },
    secondaryText: {
        fontSize: 17,
        color: "#000",
        marginLeft: 2,
        textAlign: "center",
    },
    divider: {
        width: "100%",
        height: 1,
        backgroundColor: "rgba(0,0,0,0.1)",
        marginTop: 10,
    },
    cardBottomView: {
        flexDirection: "row",
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    iconButton: {
        padding: 5,
        backgroundColor: "#F8F8FA",
        borderRadius: 15
    },
    cardBottomtext: {
        color:"#000",
        fontSize: 16,
        paddingLeft: 20,
    },
    infoContainer: {
        display: 'flex',
        height: '100%',
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
    },
    dotView: {
        display: 'flex',
        flexDirection: "row",
        width: "90%",
        alignItems: "center",
        justifyContent: "space-around",
        marginTop: -80
    },
    borderDot: {
        width: 15,
        height: 15,
        borderRadius: 12,
        backgroundColor: "#fff",
        borderWidth: 6,
        borderColor: "rgba(265,265,265,0.2)"
    },
    dotStyle: {
        width: 10,
        height: 10,
        borderRadius: 6,
        backgroundColor: "rgba(265,265,265,0.5)"
    },
};

class Verification extends Component {
    constructor() {
        super();
        this.state = {

        }
    }

    renderDotView = () => {
        let DotArray = []
        for (let i = 0; i < DOT_LENGTH; i++) {
            DotArray.push(<div key={i.toString()} id={i} style={{
                width: 4,
                height: 4,
                borderRadius: 2,
                backgroundColor: "rgba(265,265,265,0.5)"
            }} />)
        }
        return DotArray;
    };

    render() {

        return (
            <div style={styles.paper}>
                <Grid container justifyContent="center" direction='row' alignItems='center'>
                    <Grid item xs={12}>
                        <div style={styles.topContainer}>
                            <Typography style={{ ...styles.textStyle, color: "#fff", padding: "5px 0px", textAlign: 'center' }}>Verification</Typography>
                            <div style={styles.infoContainer}>
                                <div style={styles.dotView}>
                                    <div style={styles.dotStyle} />
                                    {this.renderDotView()}
                                    <div style={styles.borderDot} />
                                    {this.renderDotView()}
                                    <div style={styles.dotStyle} />
                                </div>
                            </div>
                        </div>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={6} md={4}>
                                <div style={styles.bottomContainer}>
                                    <div style={styles.imageView}>
                                        <img
                                            alt="verification"
                                            src={verification}
                                            style={styles.imageStyle}
                                        />
                                        <Typography style={styles.topText}>
                                            Take Selfie
                                        </Typography>
                                    </div>
                                    <div>
                                        <div style={styles.topTextView}>
                                            <Typography style={styles.secondaryText}>
                                                1.Put your face inside the frame and hold steady
                                            </Typography>
                                            {/* <Typography style={styles.secondaryText}>
                                            Put your face inside the frame and hold steady
                                        </Typography> */}
                                        </div>
                                        <div style={styles.topTextView}>
                                            <Typography style={styles.secondaryText}>
                                                2.Make sure your photo is not blurry
                                            </Typography>
                                            {/* <Typography style={styles.secondaryText}>
                                            Make sure your photo is not blurry
                                        </Typography> */}
                                        </div>
                                        <div style={styles.divider} />
                                        <div style={styles.cardBottomView}>
                                            <div>
                                                <div style={styles.iconButton}>
                                                    <img src={IDEA} height="20" alt="icon" style={{}} />
                                                    {/* <IDEA width={25} height={25} /> */}
                                                </div>
                                            </div>
                                            <Typography style={styles.cardBottomtext}>Tips : Take in good lighting and take off your glasses</Typography>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={6} md={4}>
                                <div style={{ padding: 30 }}>
                                    <Button
                                        type="Button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.props.navigate("/selfie-camera")}
                                        style={styles.button}
                                    >
                                        <Typography style={styles.continueText}>Open Camera</Typography>
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
};


const mapStateToProps = (state) => {
    return {

    };
};

Verification.propTypes = {

};

export default withRouter(connect(mapStateToProps)(Verification));
