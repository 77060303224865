import React, { Component } from "react";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Grid, Typography } from "@mui/material";
import Webcam from "react-webcam";
import { SELECTED_TEST_TYPE_IMAGE } from "../../actions/actionTypes";
import { isMobile } from "../../helpers/Constants"
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { THEMECOLOR } from "../../helpers/Constants";
const { innerWidth: width, innerHeight: height } = window;

const styles = {
    paper: {
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
        height: '100vh',
        // padding: 20,
        // paddingBottom: 100
    },
    mainDiv: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'hidden',
        overflowX: 'hidden'
    },
    photoContainer: {
        height: '100vh',
        backgroundColor: 'rgba(111, 111, 111, 0.2)',
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up("sm")]: {
            justifyContent: 'center',
        },
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute'
    },
    infoText: {
        fontSize: 18,
        paddingTop: 10,
        color: "#fff",
        textAlign: "center",
    },
    textContainer: {
        display: 'inline-block',
        position: 'relative',
        textAlign: 'center',
        padding: 10,
        zIndex: 100
    },
    cameraRectangle: {
        height: height / 1.7,
        width: width / 1.3,
        border: '4px solid #fff',
        display: 'inline-block',
        position: 'relative',
        zIndex: 101
    },
    buttonContainer: {
        display: 'inline-block',
        position: 'relative',
        zIndex: 100
    },
    roundButton: {
        margin: 20,
        width: height / 15,
    },
    roundButtonCircle: {
        width: '100%',
        height: 0,
        paddingBottom: '100%',
        borderRadius: '50%',
        border: '10px solid #fff',
        overflow: 'hidden',
        background: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
    }
};

class UploadTestTypeImage extends Component {
    constructor() {
        super();
        this.webcamRef = React.createRef();
        this.state = {
            openTestList: false,
            testtypes: [],
            parentWidth: 0
        }
        this.myInput = React.createRef()
        this.onImageChange = this.onImageChange.bind(this);
        this.handleUnload = this.handleUnload.bind(this);
    }

    handleUnload(e) {
        var message = "\o/";

        (e || window.event).returnValue = message;
        return message;
    };

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.props.dispatch({ type: SELECTED_TEST_TYPE_IMAGE, data: URL.createObjectURL(img) })
            //this.props.history.replace("/uploaded-test-image-verification")
            this.props.navigate("/uploaded-test-image-verification")
        }
    };

    base64toBlob = (dataURI, type) => {
        // convert base64 to raw binary data held in a string
        var byteString = atob(dataURI.split(',')[1]);
        // separate out the mime component
        // var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var bb = new Blob([ab], { type: type });
        return bb;
    };

    capture = async () => {
        const imageSrc = await this.webcamRef.current.getScreenshot();
        var filepath = await this.base64toBlob(imageSrc, "image/jpeg");
        this.setState({ imgSrc: URL.createObjectURL(filepath) });
        this.props.dispatch({ type: SELECTED_TEST_TYPE_IMAGE, data: URL.createObjectURL(filepath) })
        this.props.navigate("/uploaded-test-image-verification")
    };

    componentDidMount() {
        console.log(this.myInput.current.offsetWidth)
        window.addEventListener('beforeunload', this.handleUnload);
        window.addEventListener('resize', this.handleResize());
        if (this.props.selected_test_type === null) {
            window.location = "/my-health";
        }
    };

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleUnload);
        window.removeEventListener('resize', this.handleResize());
    };

    handleResize = (event) => {
        // console.log(this.myInput.current.offsetWidth)
        this.setState({ parentWidth: this.myInput.current.offsetWidth })
    };

    render() {
        // const { styles} = this.props;
        const videoConstraints = { width: 1280, height: 720, facingMode: "environment" };
        let isMobileView = isMobile();
        return (
            <div style={styles.paper}>
                <Grid container justifyContent="center">
                    {isMobileView === true ?
                        <Grid item xs={12} ref={this.myInput}>
                            <div style={{
                                height: '100vh',
                                [theme.breakpoints.down("sm")]: {
                                    justifyContent: 'flex-start',
                                },
                                [theme.breakpoints.up("sm")]: {
                                    justifyContent: 'center',
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                width: this.state.parentWidth
                            }}>
                                <div style={styles.textContainer}>
                                    <Typography style={styles.infoText}>
                                        Take a picture of the Patient's Test Results or Vaccination Record
                                    </Typography>
                                </div>
                                <div style={styles.cameraRectangle}>
                                </div>
                                <div style={styles.buttonContainer} onClick={() => this.capture()}>
                                    <div style={styles.roundButton}>
                                        <div style={styles.roundButtonCircle}>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={styles.mainDiv}>
                                <Webcam
                                    height={720}
                                    width={1280}
                                    audio={false}
                                    ref={this.webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                />
                            </div>
                        </Grid>
                        :
                        <Grid item xs={12} ref={this.myInput}>
                            <div style={{
                                height: '100vh',
                                [theme.breakpoints.down("sm")]: {
                                    justifyContent: 'flex-start',
                                },
                                [theme.breakpoints.up("sm")]: {
                                    justifyContent: 'center',
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: "center",
                                alignItems: 'center',
                                position: 'absolute',
                                width: this.state.parentWidth
                            }}>
                                <div style={styles.textContainer}>
                                    <Typography style={styles.infoText}>
                                        Upload an image of your Patient's Test Results or Vaccination Record
                                    </Typography>
                                </div>
                                <div>
                                    <input type="file" name="myImage" onChange={this.onImageChange} accept=".jpg,.jpeg,.png" />
                                </div>
                            </div>
                        </Grid>
                    }
                </Grid>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        selected_test_type: state.testReducer.selected_test_type,
        user_type: state.sessionReducer.user_type,
    };
};

// UploadTestTypeImage.propTypes = {
//     styles: PropTypes.object.isRequired,
// };

export default withRouter(connect(mapStateToProps)(UploadTestTypeImage));