import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { END_POINT, THEMECOLOR } from "../../helpers/Constants";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import { fetchUserProfile, fetchUserTypes, changeUserType, updateUserProfileForMobileRespansiveScreen } from "../../actions/userAction";
import moment from 'moment';
import { logout } from '../../actions/sessionAction';
import LogOut from '../../assets/images/logout.png';
import User from "../../assets/images/account_circle.png";
import IconButton from '@mui/material/IconButton';
// import ConfirmLogo from '../../assets/images/medfilo.png';
import { Typography, Grid, Button, DialogTitle, Dialog, DialogContent, DialogActions, TextField, MenuItem, Input as MaterialInput, Input } from "@mui/material";
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { FaPencilAlt } from "react-icons/fa";
// import { isMobile } from "../../helpers/Constants";
import CancelIcon from '@mui/icons-material/Cancel';

/** Created common styles for UI  */
const styles = {
	paper: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
		minHeight: '100vh',
		padding: 20
	},
	titleHead: {
		fontSize: 20,
		fontWeight: "bold",
		textAlign: "center",
		padding: 5
	},
	button: {
		marginTop: 10,
		padding: 15,
		borderRadius: 0,
		fontSize: 15,
		fontWeight: "bold"
	},
	lineStyle: {
		borderBottomStyle: "solid",
		borderBottomWidth: 1.5,
		borderBottomColor: "#727272",
		width: '35%',
		marginHorizontal: '35%',
		alignItems: 'center',
		justifyContent: "center",
	},
	cardContainer: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundColor: "#FFF",
		padding: 10,
		borderRadius: 10,
		borderTopLeftRadius: 15,
		borderTopRightRadius: 15,
		marginBottom: 70,
		border: `2px solid ${THEMECOLOR.PRIMARY}`
	},
	titleView: {
		marginTop: 20,
		alignItems: "center",
		justifyContent: "space-between"
	},
	titleViewStyle: {
		width: '100%',
		flexDirection: 'row',
		alignItems: "center",
		justifyContent: 'center'
	},
	titleText: {
		fontSize: 20,
		color: '#000000',
		textAlign: 'center',
		fontWeight: "bold"
	},
	userRow: {
		display: 'flex',
		flexDirection: 'row',
		paddingBottom: 4,
		paddingHorizontal: 10,
		borderBottomWidth: 1.4,
		borderBottomColor: '#000',
		borderBottomStyle: "solid",
	},
	userImage: {
		padding: 10,
	},
	userProfile: {
		paddingLeft: 4,
		width: "50%",
		paddingTop: 20
	},
	image: {
		width: 70,
		height: 70,
		borderRadius: 10
	},
	premium: {
		marginLeft: 5,
		padding: 5,
		borderRadius: 50,
	},
	cardView: {
		padding: 7,
		borderBottomWidth: 1,
		borderBottomStyle: "solid",
		borderBottomColor: "#D4D2CA"
	},
	subContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	cardTitle: {
		fontSize: 15,
		color: "#000000"
	},
	buttonTitle: {
		fontSize: window.screen.width >= 1280 ? 14 : 12,
		color: "#000000",
		textTransform: 'capitalize'
	},
	cardSubTitle: {
		fontSize: 15,
		paddingBottom: 2,
		color: '#000',
		fontWeight: 'bold'
	},
	title: {
		fontSize: 18,
		color: '#000000'
	},
	moreInformation: {
		borderBottomWidth: 1,
		borderBottomStyle: "solid",
		borderBottomColor: "#D4D2CA",
		display: "flex",
		flexDirection: "row",
		alignItems: 'center',
		justifyContent: 'center',
		padding: 10
	},
	subInformation: {
		fontSize: 15,
		padding: 2,
		color: '#000000',
		textAlign: 'center',
		fontWeight: 'bold',
		lineHeight: 1
	},
	subDiv: {
		paddingBottom: 6,
	}, logOutImage: {
		width: 25,
		height: 25,
		margin: 16
	},
	logOutDivider: {
		height: 1,
		backgroundColor: "#7A7A7A"
	},
	lastDivider: {
		height: 0.2,
		backgroundColor: "#D1CFCF"
	},
	noButton: {
		display: "flex",
		textAlign: 'center',
		fontSize: 15,
		color: "#FF0000",
		width: '50%'
	},
	yesButton: {
		display: 'flex',
		fontSize: 15,
		width: '50%',
		color: THEMECOLOR.SECONDARY,
		fontWeight: '700',
		textAlign: 'center'
	},
	buttonView: {
		display: 'flex',
		width: '100%',
		height: "50px"
	}
};

/**
 * @class
 *
 * Class representing MyAccount component
 *
 */
class MyAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_profile: null,
			userType: null,
			isEditDialog: false,
			first_name: "",
			first_name_error: false,
			last_name: "",
			last_name_error: false,
			email: "",
			email_error: false,
			invalid_email_error: false,
			gender: "",
			gender_error: false,
			date_of_birth: "",
			date_of_birth_error: false,
			invalid_age_error: false,
			invalid_date_of_birth_error: false,
			errorText: null,
			zip_code: "",
			zip_code_error: false,
			invalid_zip_code_error: false,
			informationDialog: false,
			logoutDialog: false,
			userTypeDialog: false
		}
		// create a ref 
		this.inputRef = React.createRef
	}

	/** This is a component life cycle method and used to assigning props values to state variable  */
	static getDerivedStateFromProps(props, state) {
		if (props.user_profile !== state.user_profile) {
			return {
				user_profile: props.user_profile,
				userType: props.user_profile.userType
			}
		}
		return null;
	};

	/** This is a component life cycle method and used to fetch datas*/
	componentDidMount() {
		/** fetching user types details. */
		this.props.dispatch(fetchUserTypes())
		/** fetching user profile details. */
		this.props.dispatch(fetchUserProfile())
		// if (this.inputRef.current) {
        //     this.inputRef.current.disabled = true;
        //     this.inputRef.current.tabIndex = -1;
        // }
	};

	/**
	* @function
	*
	* function representing change user type.
	*/
	changeUserType = () => {
		let switchUserType = this.props.user_types.find(x => x.roleType.code !== this.state.userType)
		if (switchUserType !== undefined) {
			this.props.dispatch(changeUserType(this, switchUserType.id))
		}
	};

	/**
	* @function
	*
	* function representing open dialog.
	* @param {Object} switchUserType
	*/
	changeDialogUserType = (switchUserType) => {
		this.setState({ userTypeDialog: false })
		this.props.dispatch(changeUserType(this, switchUserType.id))
	};

	/**
	* @function
	*
	* function representing close dialog.
	*/
	closeDialog = () => {
		this.setState({
			isEditDialog: false,
			first_name: "",
			first_name_error: false,
			last_name: "",
			last_name_error: false,
			email: "",
			email_error: false,
			invalid_email_error: false,
			gender: "",
			gender_error: false,
			date_of_birth: "",
			date_of_birth_error: false,
			invalid_age_error: false,
			invalid_date_of_birth_error: false,
			errorText: null,
			zip_code: "",
			zip_code_error: false,
			invalid_zip_code_error: false,
			logoutDialog: false
		})
	};

	/**
	 * @function
	 * This function representing edit user profile information.
	 * 
	 */
	submitProfile = () => {

		let { first_name, last_name, email, zip_code, gender, date_of_birth } = this.state;

		let isError = false;
		if (first_name === "" || first_name === null) {
			this.setState({ first_name_error: true });
			isError = true;
		}

		if (last_name === "" || last_name === null) {
			this.setState({ last_name_error: true });
			isError = true;
		}

		if (email === "" || email === null) {
			this.setState({ email_error: true });
			isError = true;
		} else {
			if (!this.validateEmail(email)) {
				this.setState({ invalid_email_error: true });

				isError = true;
			}
		}
		if (gender === "" || gender === null || gender === "Select gender") {
			this.setState({ gender_error: true });
			isError = true;
		}

		if (date_of_birth === "" || date_of_birth === null) {
			this.setState({ date_of_birth_error: true });
			isError = true;
		}

		if (new Date(date_of_birth) === 'Invalid Date' || date_of_birth.length < 10) {
			this.setState({ invalid_date_of_birth_error: true, errorText: "Please enter valid date of birth(MM/DD/YYYY)" });
			isError = true;
		}

		if (this.validateAge(new Date(moment(date_of_birth).format('MM/DD/YYYY')))) {
			this.setState({ invalid_age_error: true, errorText: "MedFilo is not yet available for users under 13 years of age." })
			isError = true;
		}

		if (new Date().getTime() < new Date(moment(date_of_birth).format('MM/DD/YYYY')).getTime()) {
			this.setState({ invalid_date_error: true, errorText: "Future date not allowed" });
			isError = true;
		}

		if (zip_code === "" || zip_code === null) {
			this.setState({ zip_code_error: true });
			isError = true;
		}

		if (!this.validateZipCode(zip_code)) {
			this.setState({ invalid_zip_code_error: true });
			isError = true;
		}

		if (isError === false) {
			let { user_profile } = this.state;
			let data = { user_profile };
			data.id = user_profile.id;
			data.hashedUserId = user_profile.hashedUserId;
			data.phone = user_profile.phone;
			data.firstName = first_name;
			data.lastName = last_name;
			data.email = email.toLowerCase();
			data.dateOfBirth = new Date(moment(date_of_birth).format('MM/DD/YYYY'));
			data.gender = gender;
			data.zipCode = zip_code;
			this.props.dispatch(updateUserProfileForMobileRespansiveScreen(this, data.id, data));
		}
	};

	/**
	*@function
	* This function representing email validation.
	* @param {String} email The email String
	*/
	validateEmail(email) {
		let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};

	/**
	*@function
	* This function representing Zipcode validation.
	* @param {String} code The code String
	*/
	validateZipCode(code) {
		let re = /^[\d]{5}$/;
		return code === "" ? true : re.test(code);
	};

	/**
	*@function
	* This function representing change text input value
	* @param {Object} event 
	*/
	onChange = (event) => {
		this.setState({
			date_of_birth: event.target.value,
			date_of_birth_error: false,
			invalid_age_error: false,
			invalid_date_of_birth_error: false
		});
	};

	/**
	 *@function
	 * This function representing date of birth validation.
	 * @param {String} date The date String
	 */
	validateAge(date) {
		let age = new Date().getFullYear() - new Date(date).getFullYear();
		let month = new Date().getMonth() - new Date(date).getMonth();
		if (month < 0 || (month === 0 && new Date().getDate() < new Date(date).getDate())) {
			age--;
		}
		return age <= 13
	};

	/**
	 *@function
	 * This function representing edit My Account Information dialog screen will open.
	 * 
	 */
	editProfile = () => {
		let { user_profile } = this.state;

		this.setState({
			first_name: user_profile.firstName,
			first_name_error: false,
			last_name: user_profile.lastName,
			last_name_error: false,
			email: user_profile.email,
			email_error: false,
			gender: user_profile.gender,
			gender_error: false,
			date_of_birth_error: false,
			date_of_birth: moment(user_profile.dateOfBirth).format('MM/DD/YYYY'),
			invalid_age_error: false,
			invalid_date_of_birth_error: false,
			zip_code: user_profile.zipCode,
			zip_code_error: false,
			invalid_zip_code_error: false,
			isEditDialog: true
		})
		
	};

	/**
	 * @function
	 *
	 * function representing logout the account.
	 *
	 */
	onSubmit = () => {
		this.props.dispatch(logout());
	};

	moreInformationAction = () => {
		this.props.navigate("/more-information")
	};

	/**
	*@function
	*@param {String}  param user type 
	* Function used to get full user role name   
	*/
	userTypeName = (userType) => {
		if (userType.roleType.code === "PSV") {
			return "Partner Supervisor"
		} else if (userType.roleType.code === "OSV") {
			return "Organization Supervisor"
		} else if (userType.roleType.code === "DNR") {
			return "Donor Supervisor"
		} else if (userType.roleType.code === "MSP") {
			return "Medical Service Provider"
		} else if (userType.roleType.code === "VFR") {
			return "Verifier"
		} else if (userType.roleType.code === "CST") {
			return "Customer Support"
		} else if (userType.roleType.code === "ADM" || userType.roleType.code === "Admin") {
			return "Administrator"
		} else {
			return "User"
		}
	};

	/** rendering My Account information. */
	render() {
		const { user_profile } = this.props;
		let imageurl = (user_profile !== null && user_profile.imageStorageKey !== null) ? `${END_POINT}image/i/${user_profile.imageStorageKey}` : null;
		// let isMobileView = isMobile();
		let mainUserTypes = this.props.user_types.filter(userType => userType.roleType.code === "CLT" || userType.roleType.code === "MSP" || userType.roleType.code === "VFR")
		let userTypeSwicth = this.props.user_types.find(x => x.roleType.code !== this.state.userType);
		return (
			<div style={styles.paper}>
				{/* <div style={styles.topContainer}>
							<img
								alt="Logo"
								src={ConfirmLogo}
                                height={20}
								width={window.screen.width >= 1280 ? null : "30%"}
								//style={{padding: 10}}
							/>
			  </div> */}
				<Grid container justifyContent={"center"}>
					<Grid item xs={12} md={4} sm={6}>
						<div style={styles.cardContainer}>
							<div style={styles.titleView}>
								<div style={styles.titleViewStyle}>
									<Typography style={styles.titleText}>My Profile</Typography>
								</div>
								<div style={{
									display: 'flex', justifyContent: 'center', alignItems: 'center',
								}}>
									<div style={styles.lineStyle} />
								</div>
								<div style={styles.userRow}>
									<div style={styles.userImage}>
										{imageurl !== null ?
											<img src={imageurl} height="80" width="80" alt="icon" style={{
												backgroundColor: "white", border: "1px solid #ddd",
												borderRadius: "10px", objectFit: "cover"
											}} /> :
											<img src={User} height="80" width="80" alt="icon" style={{
												backgroundColor: "white", border: "1px solid #ddd",
												borderRadius: "10px", objectFit: "cover"
											}} />}
									</div>
									<div style={styles.userProfile}>
										{user_profile !== null &&
											<Typography style={{ ...styles.cardSubTitle, color: "#000", fontWeight: 'bold' }}>{user_profile.userType === "CLT"
												? "User"
												: user_profile.userType === "MSP"
													? "Medical Service Provider"
													: "Verifier"}
											</Typography>
										}
										<Typography style={styles.title}> {user_profile !== null ? `${user_profile.firstName} ${user_profile.lastName}` : "-"}</Typography>
									</div>
									<div style={styles.premium}>
											<div style={{ display: "flex", cursor: "pointer", justifyContent: "center", alignItems: "center", border: `1px solid ${THEMECOLOR.PRIMARY}`, backgroundColor: THEMECOLOR.SECONDARY, borderRadius: 7, padding: 5 }} onClick={() => this.editProfile()} >
												<FaPencilAlt />
												<Typography style={{ marginLeft: "7px" }}>Edit</Typography>
											</div>
									</div>
								</div>
								<div style={{ height: "33%" }}>
									<div style={styles.cardView}>
										<Typography style={{ ...styles.cardSubTitle, }}>Email</Typography>
										<div style={styles.subContainer}>
											<Typography style={styles.cardTitle}>
												{user_profile !== null ? user_profile.email : "-"}
											</Typography>
										</div>
									</div>
									<div style={styles.cardView}>
										<Typography style={{ ...styles.cardSubTitle, }}>Mobile Number</Typography>
										<div style={styles.subContainer}>
											{(user_profile !== null && !!user_profile.phone) ? `(${user_profile.phone.slice(0, 3)}) ${user_profile.phone.slice(3, 6)}-${user_profile.phone.slice(6, 10)}` : "-"}
										</div>
									</div>
									<div style={styles.cardView}>
										<Typography style={{ ...styles.cardSubTitle, }}>Date of Birth</Typography>
										<div style={styles.subContainer}>
											{(user_profile !== null && !!user_profile.dateOfBirth) ? moment(user_profile.dateOfBirth).format('MM/DD/YYYY') : "-"}
										</div>
									</div>
									<div style={styles.cardView}>
										<Typography style={{ ...styles.cardSubTitle, }}>Gender</Typography>
										<div style={styles.subContainer}>
											{user_profile !== null ? user_profile.gender : "-"}
										</div>
									</div>
									<div style={styles.cardView}>
										<Typography style={{ ...styles.cardSubTitle, }}>Zip Code</Typography>
										<div style={styles.subContainer}>
											{user_profile !== null ? user_profile.zipCode : "-"}
										</div>
									</div>
									{user_profile !== null && user_profile.userType === "MSP" &&
										<div style={styles.cardView}>
											<Typography style={styles.cardSubTitle}>Professional ID Number</Typography>
											<div style={styles.subContainer}>
												{user_profile !== null && user_profile.externalId}
											</div>
										</div>
									}
									{user_profile !== null && user_profile.userType === "VFR" &&
										<div style={styles.cardView}>
											<Typography style={styles.cardSubTitle}>Organization</Typography>
											<div style={styles.subContainer}>
												{user_profile !== null && user_profile.organization}
											</div>
										</div>
									}
									<div style={{ borderTopColor: "#22488F", borderTopStyle: "solid", borderTopWidth: 1 }}>
										<Button style={styles.moreInformation} component={Link} to="/more-information">
											<Typography style={styles.buttonTitle}  >
												More Information on the MedFilo App
											</Typography>
											<KeyboardArrowRightIcon size={25} color="#7F8EB8" />
										</Button>
										<Button style={styles.moreInformation} component={Link} to="/hipaa-waiver">
											<Typography style={styles.buttonTitle} >
												HIPAA waiver
											</Typography>
											<KeyboardArrowRightIcon size={25} color={THEMECOLOR.PRIMARY} />
										</Button>
										{this.state.userType === "CLT" && (this.props.user_types.length === 1 || mainUserTypes.length === 1) &&
											<div >
												<div style={{ padding: 5 }}>
													<div style={styles.subDiv}>
														<Typography style={styles.subInformation}>Use the app as a Medical Service Provider</Typography>
														<Typography style={styles.subInformation} >or for Access Control </Typography>
													</div>
													<Typography style={{
														textAlign: 'center',
														paddingBottom: 6, fontSize: 13
													}}>Tap the button to extend app functionality</Typography>
												</div>
												<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }} >
													<Button variant="contained" color="primary" fullWidth style={{ backgroundColor: theme.palette.secondary.main, }} component={Link} to={"/professional-registration"}>
														<Typography style={{
															color: "#FFFFFF",
															fontWeight: "bold",
															fontSize: 15,
															textTransform: 'capitalize'
														}} >Extend to Another User Type</Typography>
													</Button>
												</div>
											</div>
										}
										{(this.state.userType === "CLT" && this.props.user_types.length === 2 && user_profile.status === "PENDING" && userTypeSwicth !== undefined) &&
											<div>
												{userTypeSwicth.roleType.code === "MSP" &&
													<div>
														<div style={{ padding: 5 }}>
															<div >
																<Typography style={styles.subInformation}>Your request to register as </Typography>
																<Typography style={styles.subInformation} >Medical Service Provider is pending approval</Typography>
															</div>
															<Typography style={{
																textAlign: 'center',
																paddingBottom: 6, fontSize: 13
															}}>The button below will be activated upon validation</Typography>
														</div>
														<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }}>
															<Button variant="contained" color="primary" fullWidth disabled={true} style={{ backgroundColor: THEMECOLOR.SECONDARY }}>
																<Typography style={{ color: "#FFF", fontWeight: "bold", fontSize: 15, textTransform: 'uppercase' }}>Pending Medical Service Provider Status</Typography>
															</Button>
														</div>
													</div>
												}
												{userTypeSwicth.roleType.code === "VFR" &&
													<div >
														<div style={{ padding: 5 }}>
															<div >
																<Typography style={styles.subInformation}>Your request to register as </Typography>
																<Typography style={styles.subInformation} >Verifier is pending approval</Typography>
															</div>
															<Typography style={{
																textAlign: 'center',
																paddingBottom: 6, fontSize: 13
															}}>The button below will be activated upon validation</Typography>
														</div>
														<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }}>
															<Button variant="contained" color="primary" disabled={true} fullWidth style={{ backgroundColor: THEMECOLOR.SECONDARY }}>
																<Typography style={{ color: "#FFF", fontWeight: "bold", fontSize: 15, textTransform: 'uppercase' }}>Pending Verifier Status</Typography>
															</Button>
														</div>
													</div>
												}
											</div>
										}
										{(this.state.userType === "CLT" && this.props.user_types.length === 2 && user_profile.status !== "PENDING" && userTypeSwicth !== undefined) &&
											<div>
												{userTypeSwicth.roleType.code === "MSP" &&
													<div>
														<div style={{ padding: 5 }}>
															<div style={styles.subDiv}>
																<Typography style={styles.subInformation}>Use the app as a Medical Service Provider</Typography>
															</div>
															<Typography style={{
																textAlign: 'center',
																paddingBottom: 6, fontSize: 13
															}}>Tap the button below to switch user type account</Typography>
														</div>
														<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }}>
															<Button variant="contained" color="primary" style={{ backgroundColor: THEMECOLOR.SECONDARY }} fullWidth onClick={() => this.changeUserType()}>
																<Typography style={{
																	color: "#FFFFFF",
																	fontWeight: "bold",
																	fontSize: 15,
																	textTransform: 'capitalize'
																}} >Switch to My Medical Service Provider Account</Typography>
															</Button>
														</div>
													</div>
												}
												{userTypeSwicth.roleType.code === "VFR" &&
													<div >
														<div style={{ padding: 5 }}>
															<div style={styles.subDiv}>
																<Typography style={styles.subInformation}>Use the app as an Verifier</Typography>
															</div>
															<Typography style={{
																textAlign: 'center',
																paddingBottom: 6, fontSize: 13
															}}>Tap the button below to switch user type account</Typography>
														</div>
														<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }} >
															<Button fullWidth variant="contained" color="primary" style={{ backgroundColor: theme.palette.primary.dark }} onClick={() => this.changeUserType()}>
																<Typography style={{
																	color: "#FFFFFF",
																	fontWeight: "bold",
																	fontSize: 15,
																	textTransform: 'capitalize'
																}} >Switch to My Verifier Account</Typography>
															</Button>
														</div>
													</div>
												}
												{userTypeSwicth.roleType.code === "DNR" &&
													<div >
														<div style={{ padding: 5 }}>
															<div style={styles.subDiv}>
																<Typography style={styles.subInformation}>Use the app as an Donor</Typography>
															</div>
															<Typography style={{
																textAlign: 'center',
																paddingBottom: 6, fontSize: 13
															}}>Tap the button below to switch user type account</Typography>
														</div>
														<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }} >
															<Button fullWidth variant="contained" color="primary" style={{ backgroundColor: theme.palette.primary.dark }} onClick={() => this.changeUserType()}>
																<Typography style={{
																	color: "#FFFFFF",
																	fontWeight: "bold",
																	fontSize: 15,
																	textTransform: 'capitalize'
																}} >Switch to My Donor Account</Typography>
															</Button>
														</div>
													</div>
												}
												{userTypeSwicth.roleType.code === "PSV" &&
													<div >
														<div style={{ padding: 5 }}>
															<div style={styles.subDiv}>
																<Typography style={styles.subInformation}>Use the app as an Partner Supervisor</Typography>
															</div>
															<Typography style={{
																textAlign: 'center',
																paddingBottom: 6, fontSize: 13
															}}>Tap the button below to switch user type account</Typography>
														</div>
														<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }} >
															<Button fullWidth variant="contained" color="primary" style={{ backgroundColor: theme.palette.primary.dark }} onClick={() => this.changeUserType()}>
																<Typography style={{
																	color: "#FFFFFF",
																	fontWeight: "bold",
																	fontSize: 15,
																	textTransform: 'capitalize'
																}} >Switch to My Partner Supervisor Account</Typography>
															</Button>
														</div>
													</div>
												}
												{userTypeSwicth.roleType.code === "OSV" &&
													<div >
														<div style={{ padding: 5 }}>
															<div style={styles.subDiv}>
																<Typography style={styles.subInformation}>Use the app as an Organization Supervisor</Typography>
															</div>
															<Typography style={{
																textAlign: 'center',
																paddingBottom: 6, fontSize: 13
															}}>Tap the button below to switch user type account</Typography>
														</div>
														<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }} >
															<Button fullWidth variant="contained" color="primary" style={{ backgroundColor: theme.palette.primary.dark }} onClick={() => this.changeUserType()}>
																<Typography style={{
																	color: "#FFFFFF",
																	fontWeight: "bold",
																	fontSize: 15,
																	textTransform: 'capitalize'
																}} >Switch to My Organization Supervisor Account</Typography>
															</Button>
														</div>
													</div>
												}
												{userTypeSwicth.roleType.code === "ADM" &&
													<div >
														<div style={{ padding: 5 }}>
															<div style={styles.subDiv}>
																<Typography style={styles.subInformation}>Use the app as an Admin</Typography>
															</div>
															<Typography style={{
																textAlign: 'center',
																paddingBottom: 6, fontSize: 13
															}}>Tap the button below to switch user type account</Typography>
														</div>
														<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }} >
															<Button fullWidth variant="contained" color="primary" style={{ backgroundColor: theme.palette.primary.dark }} onClick={() => this.changeUserType()}>
																<Typography style={{
																	color: "#FFFFFF",
																	fontWeight: "bold",
																	fontSize: 15,
																	textTransform: 'capitalize'
																}} >Switch to My Admin Account</Typography>
															</Button>
														</div>
													</div>
												}
												{userTypeSwicth.roleType.code === "CST" &&
													<div >
														<div style={{ padding: 5 }}>
															<div style={styles.subDiv}>
																<Typography style={styles.subInformation}>Use the app as an Customer Support</Typography>
															</div>
															<Typography style={{
																textAlign: 'center',
																paddingBottom: 6, fontSize: 13
															}}>Tap the button below to switch user type account</Typography>
														</div>
														<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }} >
															<Button fullWidth variant="contained" color="primary" style={{ backgroundColor: theme.palette.primary.dark }} onClick={() => this.changeUserType()}>
																<Typography style={{
																	color: "#FFFFFF",
																	fontWeight: "bold",
																	fontSize: 15,
																	textTransform: 'capitalize'
																}} >Switch to My Customer Support Account</Typography>
															</Button>
														</div>
													</div>
												}
											</div>
										}
										{this.state.userType !== "CLT" && this.props.user_types.length === 2 &&
											<div>
												<div style={{ padding: 5 }}>
													<div style={styles.subDiv}>
														<Typography style={styles.subInformation}>Use the app as an User</Typography>
													</div>
													<Typography style={{
														textAlign: 'center',
														paddingBottom: 6, fontSize: 13
													}}>Tap the button below to switch user type account</Typography>
												</div>
												<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }} >
													<Button fullWidth variant="contained" color="primary" style={{ backgroundColor: THEMECOLOR.SECONDARY }} onClick={() => this.changeUserType()}>
														<Typography style={{
															color: "#FFFFFF",
															fontWeight: "bold",
															fontSize: 15,
															textTransform: 'capitalize'
														}} >Switch to My Personal Account</Typography>
													</Button>
												</div>
											</div>
										}
										{this.props.user_types.length > 2 && <div>
											<Button variant="contained" aria-controls="simple-menu" aria-haspopup="true" color="primary" style={{ backgroundColor: THEMECOLOR.SECONDARY }} fullWidth onClick={() => this.setState({ userTypeDialog: true })}>
												<Typography style={{
													color: "#FFFFFF",
													fontWeight: "bold",
													fontSize: 15,
													textTransform: 'capitalize'
												}} > Switch User Type</Typography>
											</Button>
										</div>
										}
										<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 10, paddingTop: 10 }} >
											<Button variant="contained" color="primary" fullWidth style={{ backgroundColor: "red" }} onClick={() => this.setState({ logoutDialog: true })} >
												<Typography style={{
													color: "#FFFFFF",
													fontWeight: "bold",
													fontSize: 15,
													textTransform: 'capitalize'
												}} >Log Out</Typography>
											</Button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Grid>
				</Grid>
				<Dialog
					open={this.state.userTypeDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					maxWidth='xs'
					onClose={() => this.setState({ userTypeDialog: false })}
				>
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY},${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"Select user type to change"}</Typography>
					</DialogTitle>
					<div>
						{this.props.user_types.map(x => x.roleType.code !== this.state.userType && user_profile !== null &&
							<div style={{ padding: "0px 20px" }} key={x.roleType.code}>
								<MenuItem disabled={(x.roleType.code === "VFR" || x.roleType.code === "MSP") && user_profile.status === "PENDING" ? true : false} onClick={() => this.changeDialogUserType(x)}>
									<Typography>{(x.roleType.code === "VFR" || x.roleType.code === "MSP") && user_profile.status === "PENDING" ? this.userTypeName(x) + "(Pending)" : this.userTypeName(x)}</Typography>
								</MenuItem>
								<hr />
							</div>
						)}
					</div>
				</Dialog>
				<Dialog
					open={this.state.isEditDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					fullWidth
					maxWidth='sm'
				>
					<DialogTitle id="alert-dialog-title" >
						<Grid container style={{ justifyContent: 'space-between',  }} >
							<Grid item xs={11} sm={11} md={11} lg={11}>
								<Typography style={{ fontWeight: 'bold', marginTop:5, }}>
									Edit Profile
								</Typography>
							</Grid>
							<Grid item xs={1} sm={1} md={1} lg={1}>
								<IconButton onClick={() => this.closeDialog()}>
									<CancelIcon type="Button" fontSize="medium" sx={{ color: 'red',   }} />
								</IconButton>
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContent dividers>
						<Grid item xs={12} sm={12} md={12}>
							<Grid container spacing={2} style={styles.bottomContainer}>
								<Grid item xs={12} style={styles.textView}>
									<TextField
										label="First Name"
										fullWidth
										value={this.state.first_name}
										disabled
										autoComplete="off"
										onChange={(event) => event.target.value.match(/^(?!-|\s$)[a-zA-Z-\s]*$/g) && this.setState({ first_name: event.target.value, first_name_error: false })}
										error={this.state.first_name_error ? true : false}
										helperText={this.state.first_name_error === true ? "Please enter first name" : ""}
									/>
								</Grid>
								<Grid item xs={12} style={styles.textView}>
									<TextField
										label="Last Name"
										fullWidth
										disabled
										autoComplete="off"
										value={this.state.last_name}
										onChange={(event) => event.target.value.match(/^(?!-|\s$)[a-zA-Z-\s]*$/g) && this.setState({ last_name: event.target.value, last_name_error: false })}
										error={this.state.last_name_error ? true : false}
										helperText={this.state.last_name_error === true ? "Please enter last name" : ""}
									/>
								</Grid>
								<Grid item xs={12} style={styles.textView}>
									<TextField
										label="Email"
										fullWidth
										autoComplete="off"
										value={this.state.email}
										onChange={(event) => this.setState({ email: event.target.value, email_error: false, invalid_email_error: false })}
										error={
											this.state.email_error
												? true
												: this.state.invalid_email_error === true
													? true
													: false
										}
										helperText={this.state.email_error === true ? "Please enter email" : this.state.invalid_email_error === true ? "Please enter valid mail" : ""}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										select
										fullWidth
										label="Gender"
										autoComplete="off"
										value={this.state.gender}
										onChange={(event) => this.setState({ gender: event.target.value, gender_error: false })}
										error={this.state.gender_error === true ? true : false}
										helperText={this.state.gender_error === true ? "Please select the gender" : false}
									>
										<MenuItem value={"Male"} >Male</MenuItem>
										<MenuItem value={"Female"} >Female</MenuItem>
										<MenuItem value={"Others"} >Others</MenuItem>
									</TextField>
								</Grid>
								<Grid item xs={12} style={styles.textView}>
									<Typography style={{ fontSize: 16, color: this.state.date_of_birth_error || this.state.invalid_age_error || this.state.invalid_date_of_birth_error ? "#F44336" : "gray" }}>Date of Birth</Typography>
									<Input
										placeholder={"MM/DD/YYYY"}
										disabled
										fullWidth
										style={{ borderBottomStyle: 'solid', color: this.state.date_of_birth_error ? "#F44336" : "rgba(0,0,0,0.9)", borderBottomWidth: this.state.date_of_birth_error || this.state.invalid_age_error || this.state.invalid_date_of_birth_error ? 2 : 1, borderBottomColor: this.state.date_of_birth_error || this.state.invalid_age_error || this.state.invalid_date_of_birth_error ? "#F44336" : 'gray' }}
										type={'datetime'}
										mask="99/99/9999"
										// maskChar={null}
										value={this.state.date_of_birth}
										onChange={this.onChange}>
										{(inputProps) => <MaterialInput {...inputProps} disabled type="datetime" disableUnderline inputRef={this.inputRef} onChange={this.props.onChange} />}
									</Input>
									
									{this.state.date_of_birth_error ? <Typography style={{ color: '#F44336', fontSize: 12 }}>Please enter date of birth </Typography> : this.state.invalid_age_error || this.state.invalid_date_of_birth_error ? <Typography style={{ fontSize: 12, color: '#F44336', }}> {this.state.errorText}  </Typography> : null}
								</Grid>
								<Grid item xs={12} style={styles.textView}>
									<TextField
										label="Zip Code"
										fullWidth
										autoComplete="off"
										value={this.state.zip_code}
										onChange={(event) => event.target.value.match(/^\d{0,5}$/g) && this.setState({ zip_code: event.target.value, zip_code_error: false, invalid_zip_code_error: false })}
										error={
											this.state.zip_code_error ? true
												: this.state.invalid_zip_code_error
													? true
													: false
										}
										helperText={this.state.zip_code_error === true ? "Please enter zipcode" : this.state.invalid_zip_code_error ? "Please enter 5 digits valid zip code" : ""}
									/>
								</Grid>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button type="Button" color="primary" style={{ color: "red", fontSize: 15, fontWeight: "bold" }} onClick={() => this.closeDialog()}>
							Cancel
						</Button>
						<Button type="Button" color="primary" onClick={() => this.submitProfile()} style={{ color: THEMECOLOR.SECONDARY, fontSize: 15, fontWeight: "bold" }}>
							Submit
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={this.state.logoutDialog}
					maxWidth="sm"
					onClose={() => this.closeDialog()}
				>
					<div style={{ display: "flex", flexDirection: "row" }}>
						<img
							alt="Logo"
							src={LogOut}
							style={styles.logOutImage}
						/>
						<DialogTitle>
							<Typography style={{ fontSize: 20, fontWeight: "bold", color: "#000" }}>Log Out</Typography>
						</DialogTitle>
					</div>
					<div style={{ justifyContent: 'center' }}>
						<div style={styles.logOutDivider} />
					</div>
					<div style={{ margin: 20 }}>
						<Grid container spacing={2} justify="center">
							<Grid item xs={12}>
								<Typography style={{ textAlign: "center", color: "#7A7A7A" }}> Are you sure you want to log  </Typography>
								<Typography style={{ textAlign: "center", color: "#7A7A7A" }}>Out ?</Typography>
							</Grid>
						</Grid>
					</div>
					<div style={{ justifyContent: 'center' }}>
						<div style={styles.lastDivider} />
					</div>
					<div style={styles.buttonView}>
						<Button color="primary" style={styles.noButton} onClick={() => this.closeDialog()}>No</Button>
						<div style={{
							borderLeft: "1.5px solid #D1CFCF",
							height: "100%", fontWeight: "bold"
						}} />
						<Button color="primary" style={styles.yesButton} onClick={() => this.onSubmit()}>Yes</Button>
					</div>
				</Dialog>
			</div>
		);
	}
};

MyAccount.propTypes = {
	dispatch: PropTypes.func.isRequired,
};

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {
		user_profile: state.userReducer.user_profile,
		user_types: state.userReducer.user_types
	}
};

export default withRouter(connect(mapStateToProps)(MyAccount));