import { FETCH_ACTIVITY } from "../actions/actionTypes"

const initialstate = {
    myActivities: []
}

export default function activityReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_ACTIVITY:
            return {
                ...state,
                myActivities: action.data
            }

        default:
            return state;
    }
}