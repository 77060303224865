import React, { Component } from "react";
import { Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
// import { fetchTestResults, fetchTestResultsValues } from "../../actions/testAction";
import { fetchUserProfile, createPassCode } from "../../actions/userAction";
import { END_POINT, THEMECOLOR } from "../../helpers/Constants";
// import moment from 'moment';
import QRCode from "react-qr-code";
// import { fetchVaccine } from '../../actions/vaccineAction';
import User from "../../assets/images/account_circle.png";
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";

/** Created common styles for UI  */
const styles = {
	paper: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		// backgroundColor: "#20478E",
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
		minHeight: '100vh',
		padding: 20
	},
	container: {
		// boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundColor: "#FFF",
		padding: 10,
		borderRadius: 15,
		marginBottom: 50,
		height: "85vh",
		border: `2px solid ${THEMECOLOR.PRIMARY}`,
		// display: "flex",
		// flexDirection: "column",
		// justifyContent: "center",
		// aligItems: "center"
	},
	row: {
		display: "flex",
		justifyContent: "center",
		aligItems: "center"
	},
	userImage: {
		height: 80,
		width: 80,
		marginTop: 10,
		backgroundColor: "white",
		border: "1px solid #ddd",
		borderRadius: "10px",
		objectFit: "cover"
	},
	typeText: {
		fontSize: 15,
		paddingTop: 10,
		paddingLeft: 10,
		color: "#1A6636"
	},
	nameText: {
		fontSize: 17,
		paddingTop: 5,
		paddingBottom: 5,
		color: "#000",
		// fontWeight: "bold",
		paddingLeft: 10
	},
	divider: {
		width: "100%",
		height: 1,
		backgroundColor: "#D1CFCF"
	},
	innerContainer: {
		padding: 10,
	},
	qrContainer: {
		display: 'flex',
		justifyContent: "center",
		padding: 20
	},
	titleText: {
		fontSize: 20,
		color: '#000000',
		textAlign: 'center',
		fontWeight: '600'
	},
	lineStyle: {
		height: 1,
		width: 150,
		background: theme.palette.secondary.main,
	},
	divStyle: {
		display: 'flex',
		paddingTop: 5,
		paddingBottom: 5,
		justifyContent: 'center'
	},
	testText: {
		fontSize: window.screen.width >= 1280 ? 16 : 14,
		color: "#96A1C4"
	},
	dateText: {
		fontSize: window.screen.width >= 1280 ? 15 : 13,
		color: "#96A1C4"
	}
};

/**
* @class
*
* Class representing  MyBadge component
*
*/
class MyBadge extends Component {
	constructor() {
		super();
		this.state = {
			dialogOpen: false
		}
	}

	/** This is a component life cycle method and used to fetch datas  */
	componentDidMount() {
		/** fetch the userProfile details. */
		this.props.dispatch(fetchUserProfile(this));
		/** fetch the TestResults details. */
		//this.props.dispatch(fetchTestResults());
		/** fetch the TestResultsValues details. */
		//this.props.dispatch(fetchTestResultsValues());
		/** fetch the Vaccine details. */
		//this.props.dispatch(fetchVaccine());
	};

	openPassCodeQR = () => {
		this.setState({ dialogOpen: true });
		let data = {
			deviceType: 'web'
		}
		this.props.dispatch(createPassCode(data))
	};

	handleCancel = () => {
		this.setState({ dialogOpen: false })
	};

	/**
	*@function
	* This function representing return vaccine result.
	* @param {Object} test The test json object
	* @param {Object} classes
	*/
	// customVaccineResult = (test, classes) => {
	// 	let vaccine = (this.props.allVaccine !== null && test.vaccinResult !== null) ? this.props.allVaccine.find(x => test.vaccinResult.filter(y => y.vaccineId === x.id && y.status === "COMPLETED").length > 0) : null
	// 	return (
	// 		<div>
	// 			<Typography style={styles.resultText}>{(vaccine !== null && vaccine !== undefined) ? vaccine.vaccineShots.length === test.shotNumber ? "VACCINATED" : `${test.shotNumber} of ${vaccine.vaccineShots.length} shots of ${vaccine.name}` : (this.props.fetch_test_results !== undefined && this.props.fetch_test_results !== null) && this.props.fetch_test_results.filter(e => e.testTypeId === test.testTypeId && e.status === "PENDING").length > 0 ? "Record Uploaded but Not Verified" : "NOT VACCINATED"}</Typography>
	// 			<Typography style={styles.dateText}>{(vaccine !== null && vaccine !== undefined && test.vaccinResult.length > 0) ? `Verified on ${moment(test.vaccinResult[0].vaccinationDate).format("MMM DD, YYYY")} at ${moment(test.vaccinResult[0].vaccinationDate).format("hh:mm A")}` : "----"}</Typography>
	// 		</div>
	// 	)
	// }
	/** render the  MyBadge details. */
	render() {
		const { user_profile } = this.props;
		let imageurl = (user_profile !== null && user_profile.imageStorageKey !== null) ? `${END_POINT}image/i/${user_profile.imageStorageKey}` : null;
		return (
			<div style={styles.paper}>
				<Grid container justifyContent="center">
					{user_profile !== null &&
						<Grid item xs={12} sm={6} md={4}>
							<div style={styles.container}>
								<Typography style={styles.titleText}>My MedFilo Badge</Typography>
								<div style={styles.divStyle}>
									<div style={styles.lineStyle} />
								</div>
								<div style={styles.row}>
									<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
										{/* <img src={imageurl} alt="profile" className={classes.userImage} /> */}
										<Typography style={styles.typeText}>User</Typography>
										{imageurl !== null ?
											<img
												alt="Logo"
												src={imageurl}
												style={styles.userImage}
											/> :
											<img src={User} height="80" width="80" alt="icon" style={{ backgroundColor: "white", border: "1px solid #ddd", borderRadius: "10px", objectFit: "cover" }} />
										}
										<Typography style={styles.nameText}>{user_profile !== null ? `${user_profile.firstName} ${user_profile.lastName}` : ""}</Typography>
										<Typography style={{ fontWeight: "bold", fontSize: 15, color: "#1A6636", paddingBottom: 10, paddingLeft: 10 }} >{user_profile !== null ? `${"MedFilo Code : " + user_profile.userCode}` : ""}</Typography>
									</div>
								</div>
								<div style={styles.divider} />
								<div style={styles.qrContainer}>
									<QRCode value={user_profile !== null ? user_profile.hashedUserId : ""} size={200} />
								</div>
								<div style={styles.divider} />
								<div style={{ marginTop: 10, display: "flex", alignItems: "center", justifyContent: "center" }}>
									<Button style={{ marginVertical: 10, paddingVertical: 10, backgroundColor: "#E06901", borderRadius: 5 }} onClick={() => this.openPassCodeQR()}>
										<Typography style={{ color: "#fff", fontSize: 15, textAlign: "center", fontWeight: '600', }}>Tap to Show Universal Badge</Typography>
									</Button>
								</div>
								{/* <div style={styles.innerContainer}>
									{user_test_results.map(test =>
										test.isCovid === true &&
										(test.isVaccine === false &&
											<div>
												<div style={{ padding: "5px 0px" }}>
													<Typography style={styles.testText}>Latest <span style={{ fontWeight: "bold", color: "#6F7FAF" }}>{test.testname}</span></Typography>
													{test.type === "TEST" &&
														(test.isAntibody === true ?
															<Typography style={styles.resultText}>
																{(test.testResult !== null && test.testResult.length > 0) ? test.testResult[0].result_value === "POSITIVE" ? "Antibody Detected" : "No Antibody Detected" : (this.props.fetch_test_results !== undefined && this.props.fetch_test_results !== null) && this.props.fetch_test_results.filter(r => r.testTypeId === test.testTypeId && r.status === "PENDING").length > 0 ? "Test Uploaded but Not Verified" : "NOT TESTED"}
															</Typography>
															:
															<Typography style={styles.resultText}>
																{(test.testResult !== null && test.testResult.length > 0) ? test.testResult[0].result_value : (this.props.fetch_test_results !== undefined && this.props.fetch_test_results !== null) && this.props.fetch_test_results.filter(r => r.testTypeId === test.testTypeId && r.status === "PENDING").length > 0 ? "Test Uploaded but Not Verified" : "NOT TESTED"}
															</Typography>
														)}
													<Typography style={styles.dateText}>{(test.testResult !== null && test.testResult.length > 0) ? `Verified on ${moment(test.testResult[0].created).format("MMMM DD, YYYY hh:mm A")}` : "----"}</Typography>
												</div>
												<div style={styles.divider} />
											</div>
											//  :
											// <div>
											// 	<div style={{ padding: "5px 0px" }}>
											// 		<Typography style={styles.testText}>Latest <span style={{ fontWeight: "bold", color: "#6F7FAF" }}>{test.testname}</span></Typography>
											// 		{this.customVaccineResult(test, classes)}

											// 	</div>
											// </div>
										)
									)}
								</div> */}
							</div>
							<Dialog open={this.state.dialogOpen}>
								<DialogTitle style={{ color: "#000", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
									<Typography style={{ fontWeight: "bold" }}>Your Universal Badge</Typography>
									<DisabledByDefaultRoundedIcon
										style={{ color: "#000", fontSize: "30px", cursor: "pointer" }}
										onClick={() => this.handleCancel()}
									/>
								</DialogTitle>
								<DialogContent dividers style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
									{this.props.userPassCode !== null && <QRCode value={this.props.userPassCode} style={{ }} size={230} />}
								</DialogContent>
								<DialogActions style={{ color: "#000", margin: "10px" }}>Universal Badge can be read by any standard QR code scanner</DialogActions>
							</Dialog>
						</Grid>}
				</Grid>
			</div>
		);
	}
};

/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
	return {
		user_type: state.sessionReducer.user_type,
		user_profile: state.userReducer.user_profile,
		user_test_results: state.testReducer.user_test_results,
		allVaccine: state.vaccineReducer.vaccine,
		fetch_test_results: state.testReducer.fetch_test_results,
		userPassCode: state.userPassCodeReducer.userPassCode
	};
};

MyBadge.propTypes = {
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(MyBadge));