import React, { Component } from 'react';
import PropTypes from "prop-types";
import { RouterProvider} from 'react-router-dom';
import { unauthenticate, authenticate } from "../src/actions/sessionAction"
import store from '../src/store';
import router from './router';

class App extends Component {

	async componentDidMount() {
		const token = localStorage.getItem('token');
       if (token !== null) {
			 store.dispatch(authenticate());
		} else {
			store.dispatch(unauthenticate());
		}
	}
  
	render() {
		return (
			<RouterProvider router={router} />
       );
	}
}

App.propTypes = {
	classes: PropTypes.object,
};

export default App;
