import React from "react";
import { Drawer } from "@mui/material";
import { SidebarNav } from "./components";

// import users from '../../../../../src/assets/images/users.png';
// import manufacture from '../../../../../src/assets/images/manufacture.png';
// import vaccine from '../../../../../src/assets/images/vaccine.png';

import Dashboard from '@mui/icons-material/Dashboard';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { connect } from "react-redux";
import Apps from '@mui/icons-material/Apps';
import Subscriptions from '@mui/icons-material/Subscriptions';
import Message from '@mui/icons-material/Message';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import QueueIcon from '@mui/icons-material/Queue';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import SettingsIcon from '@mui/icons-material/Settings';
import { FaSyringe, FaHospitalAlt, FaUserMd, FaVial } from "react-icons/fa";
import theme from "../../../../theme";

const styles = {
	drawer: {
		width: 240,
		height: "calc(100% - 100px)",
		borderRight: "3px solid #5186E7",
		margin: "75px 0px"
	},
	root: {
		backgroundColor: "#fff",
		display: "flex",
		flexDirection: "column",
		height: "100%",
		padding: 1,
	},
	divider: {
		margin: 2,
		height: 2,
		color: "#5186E7",
		backgroundColor: "#5186E7"
	},
	nav: {
		marginBottom: 3,
	},
}

const adminMenu = [
	{
		id: 1,
		title: <span  >Dashboard</span>,
		href: "/dashboard",
		icon: <Dashboard  fontSize="small" />,
		category: {
			id: 1,
			name: "Dashboard"
		},
		order: 1

	},
	{
		id: 2,
		title: <span >User and Access</span>,
		href: "/user-access",
		icon: <SupervisorAccountIcon  fontSize="small" />,
		category: {
			id: 3,
			name: "Access"
		},
		order: 1
	},
	{
		id: 3,
		title: <span>Requests</span>,
		href: "/home",
		icon: <HowToRegIcon fontSize="small" />,
		category: {
			id: 3,
			name: "Access"
		},
		order: 2
	},
	{
		id: 4,
		title: <span >Vaccine Manufacturers</span>,
		href: "/vaccinemanufacturer",
		icon: <FaHospitalAlt fontSize={20} />,
		category: {
			id: 6,
			name: "Config"
		},
		order: 2
	},
	{
		id: 5,
		title: <span >Vaccines</span>,
		href: "/vaccine",
		icon: <FaSyringe  fontSize={20} />,
		category: {
			id: 6,
			name: "Config"
		},
		order: 1
	},
	{
		id: 6,
		title: <span >Test Types</span>,
		href: "/testTypes",
		icon: <LocalHospitalIcon  fontSize="small" />,
		category: {
			id: 6,
			name: "Config"
		},
		order: 3
	},
	// {
	// 	id: 7,
	// 	title: <span >Organizations</span>,
	// 	href: "/organization",
	// 	icon: <DomainIcon  fontSize="small" />,
	// 	category: {
	// 		id: 5,
	// 		name: "Enterprise"
	// 	},
	// 	order: 1
	// },
	// {
	// 	id: 8,
	// 	title: <span >Partners</span>,
	// 	href: "/partner",
	// 	icon: <FaHandsHelping  fontSize={20} />,
	// 	category: {
	// 		id: 5,
	// 		name: "Enterprise"
	// 	},
	// 	order: 2
	// },
	// {
	// 	id: 9,
	// 	title: <span >Donor</span>,
	// 	href: "/donor",
	// 	icon: <FaHandHoldingUsd  fontSize={20} />,
	// 	category: {
	// 		id: 5,
	// 		name: "Enterprise"
	// 	},
	// 	order: 3
	// },
	{
		id: 10,
		title: <span >Settings</span>,
		href: "/settings",
		icon: <SettingsIcon  fontSize="small" />,
		category: {
			id: 6,
			name: "Config"
		},
		order: 4
	},
	{
		id: 11,
		title: <span >Medical Service Providers</span>,
		href: "/health-care-hero",
		icon: <FaUserMd  fontSize={20} />,
		category: {
			id: 3,
			name: "Access"
		},
		order: 1
	},
	{
		id: 12,
		title: <span >Queued Test Results</span>,
		href: "/users-test-results",
		icon: <QueueIcon  fontSize="small" />,
		category: {
			id: 4,
			name: "Test Results"
		},
		order: 1
	},
	{
		id: 13,
		title: <span >App Versions</span>,
		href: "/app-versions",
		icon: <Apps  fontSize="small" />,
		category: {
			id: 6,
			name: "Config"
		},
		order: 5
	},
	{
		id: 14,
		title: <span >Subscription Plans</span>,
		href: "/user-subscription-plans",
		icon: <Subscriptions fontSize="small" />,
		category: {
			id: 6,
			name: "Config"
		},
		order: 6
	},
	// {
	// 	id: 15,
	// 	title: <span style={{ color: "#20478e" }}>Lab Test Results</span>,
	// 	href: "/lab-test-results",
	// 	icon: <img height="25" src={vaccine} alt="vaccine" />
	// },
	{
		id: 16,
		title: <span >All Test Results</span>,
		href: "/all-test-results",
		icon: <FaVial  fontSize={20} />,
		category: {
			id: 4,
			name: "Test Results"
		},
		order: 1
	},
	{
		id: 17,
		title: <span >Support Home</span>,
		href: "/support/home",
		icon: <HeadsetMicIcon fontSize="small" />,
		category: {
			id: 2,
			name: "Support"
		},
		order: 1
	},
	{
		id: 18,
		title: <span >SMS Support</span>,
		href: "/sms/support",
		icon: <Message  fontSize="small" />,
		category: {
			id: 2,
			name: "Support"
		},
		order: 2
	},
	{
		id: 19,
		title: <span >Messages</span>,
		href: "/messages-configuration",
		icon: <Message  fontSize="small" />,
		category: {
			id: 6,
			name: "Config"
		},
		order: 7
	},
]

const Sidebar = (props) => {
	// const { ...rest } = props;
	
	let pages = [];


	if (props.user_type !== null) {

		switch (props.user_type) {
			case "Admin":
				pages = adminMenu
				break;
			case "ADM":
				pages = adminMenu
				break;
			case "PSV":
				pages = [
					{
						id: 1,
						title: <span style={{ color: theme.palette.secondary.light }}>Dashboard</span>,
						href: "/partner-dashboard",
						icon: <Dashboard style={{ color: `${theme.palette.primary.light}`  }} fontSize="small" />
					},
				]
				break;
			case "OSV":
				pages = [
					{
						id: 1,
						title: <span style={{ color: theme.palette.secondary.light }}>Dashboard</span>,
						href: "/organization-dashboard",
						icon: <Dashboard style={{ color: `${theme.palette.primary.light}` }} fontSize="small" />
					},
				]
				break;
			case "DNR":
				pages = [
					{
						id: 1,
						title: <span style={{ color: theme.palette.secondary.light }}>Dashboard</span>,
						href: "/donor-dashboard",
						icon: <Dashboard style={{ color: `${theme.palette.primary.light}`  }} fontSize="small" />
					},
					{
						id: 2,
						title: <span style={{ color: theme.palette.secondary.light }}>Generate Code</span>,
						href: "/generate-credit-coupons",
						icon: <CropFreeIcon style={{ color: `${theme.palette.primary.light}`  }} fontSize="small" />
					},
				]
				break;
			case "CST":
				pages = [
					{
						id: 1,
						title: <span style={{ color: theme.palette.secondary.light }}>Home</span>,
						href: "/support/home",
						icon: <HeadsetMicIcon style={{ color: `${theme.palette.primary.light}`  }} fontSize="small" />
					},
					{
						id: 2,
						title: <span style={{ color: theme.palette.secondary.light }}>SMS Support</span>,
						href: "/sms/support",
						icon: <Message style={{ color: `${theme.palette.primary.light}` }} fontSize="small" />
					}
				]
				break;
			default:
				pages = [];
				break;
		}
	}

	return (
		<Drawer
			anchor="left"
			open={true}
			variant="persistent"
			PaperProps={{
				style: {
					width: 240,
					height: "calc(100% - 100px)",
					borderRight: `3px solid ${theme.palette.secondary.main}`,
					margin: "75px 3px"
				}
			}}
		>
			<div style={styles.root}>
				<SidebarNav
					style={styles.nav}
					pages={pages}
				/>
			</div>
		</Drawer>

	);
};

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {
		user_type: state.sessionReducer.user_type,
	};
};

export default connect(mapStateToProps)(Sidebar);