import { FETCH_ALL_INDIVIDUALS, FETCH_USER_SUBSCRIPTION_PLAN, UPDATE_INDIVIDUAL } from './actionTypes';
import API from '../api';

export function fetchAllIndividuals(self) {
	return async dispatch => {
		API.get("user-profile/all-individual").then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_ALL_INDIVIDUALS, data: response.payload });
				self.setState({ isLoading: false })
			}
		});
	}
}

export function applyStandardPlan(self) {
	return async dispatch => {
		API.post("user-subscription-plan/all-individual").then(async (response) => {
			if (response.status === "success") {
				self.setState({ isLoading: false });
				dispatch(fetchAllIndividuals(self))
			} else {
				self.setState({ isLoading: false });
			}
		});
	}
}

export function fetchUserSubscriptionPlan(self, id) {
	return async dispatch => {
		API.get("user-subscription-plan/history/" + id).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_USER_SUBSCRIPTION_PLAN, data: response.payload });
				self.setState({ isLoading: false })
			}
		});
	}
}


export function assignOrganizationPlanForUSer(self, data) {
	return async dispatch => {
		API.post("user-subscription-plan/new/subscription-plan", data).then(async (response) => {
			if (response.status === "success") {
				self.setState({
					assignSeatDialog: false,
					selectedPlan: null,
					selectedUser: null
				})
				dispatch({ type: UPDATE_INDIVIDUAL, data: response.payload });

			}
		});
	}
}