import API from '../api/index';
import {
	REGISTER_PENDING, REGISTER_SUCCESS, USER_TYPE, REGISTER_ERROR, USER_DATA,
	FORGOT_PASSWORD, ALERT_DETAIL, USER_CONFIRMATION_ID, LOGIN_SUCCESS
} from "./actionTypes";

/**
 * This action creator used for register mobile number to server
 * @function
 * @param {self} self 
 * @returns dispatch
 */
export function registerwithOtp(self) {
	return async dispatch => {
      let data = {};
		data.phone = self.state.mobile_number
		API.post("users/registerWithOTP", data).then(async (response) => {
			if (response.status === "success") {
				self.props.navigate("/otp-verification")
				dispatch({ type: ALERT_DETAIL, data: true, message: `A text message with a 6-digit verification code was just sent to ${self.state.mobile_number}`, severity: "success" })
			} else {
				dispatch({ type: ALERT_DETAIL, data: true, message: `This number already exist`, severity: "error" })
			}
		});
	}
}

/**
 * This action creator used for verify otp to server
 * @function
 * @param {self} self 
 * @returns dispatch
 */
export function verifyOtp(self) {
	return async dispatch => {
       let data = {};
		data.phone = self.props.phone
		data.otp = self.state.otp
		API.post("users/verifyOTP", data).then(async (response) => {
			if (response.status === "success") {
				if (response.payload !== null) {
					if (response.payload.isChangePassword === false) {
						dispatch({ type: USER_DATA, data: response.payload })
						self.props.navigate("/your-information");
					} else {
						dispatch({ type: USER_DATA, data: response.payload })
						self.props.navigate("/password");
					}
				} else {
					self.props.navigate("/password");
				}
			}
			else {
				self.setState({ isEnabledResendButton: true })
				dispatch({ type: ALERT_DETAIL, data: true, message: `Invalid Security Verification Code. Please check your code and try again.`, severity: "error" })
			}
		});
	}
}

/**
 * This action creator used for register info to server
 * @function
 * @param {self} self 
 * @param {data} data 
 * @returns dispatch
 */
export function registerBasicInfo(self, data) {
	return async dispatch => {
		dispatch({ type: REGISTER_PENDING });
		API.post("users/signup", data).then(async (response) => {
		if (response.status === "success") {
				self.setState({isLoading: false,first_name: "",last_name: "",email: "",gender: "",zip_code: "",date_of_birth: "",userData: null})
				await window.localStorage.setItem('token', JSON.stringify(response.token));
				dispatch({ type: LOGIN_SUCCESS, data: response.payload })
				dispatch({ type: REGISTER_SUCCESS, data: true });
				dispatch({ type: USER_DATA, data: null })
				dispatch({ type: USER_TYPE, data: response.payload.userType });
				dispatch({ type: ALERT_DETAIL, data: true, message: `Thank you for signing up with us`, severity: "success" })
			    if(response.payload.userType === "CLT"){
					self.props.navigate("/user/home")
				} else {
					self.props.navigate("/my-activity")
				}
			} else {
				self.setState({ isLoading: false })
				dispatch({ type: REGISTER_ERROR });
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
			}
		});
	}
}

/**
 * This action creator used for resend otp to server
 * @function
 * @param {self} self 
 * @returns dispatch
 */
export function resendOtp(self) {
	return async dispatch => {
		dispatch({ type: REGISTER_PENDING });
		let data = {};
		data.phone = self.props.phone
		API.post("users/resendOtp", data).then(async (response) => {
			if (response.status === "success") {
				self.setState({ isloadingOTPButton: false })
				dispatch({ type: ALERT_DETAIL, data: true, message: `A text message with a 6-digit verification code was just sent to ${self.props.phone}`, severity: "success" })
			}
		});
	}
}

/**
 * This action creator used for metch pictures to server
 * @function
 * @param {self} self 
 * @returns dispatch
 */
export function registerMatchImage(self) {
	return async dispatch => {
		dispatch({ type: REGISTER_PENDING });
		const response1 = await fetch(self.props.id_file);
        const file1 = await response1.blob();
		 const response2 = await fetch(self.props.user_img_file);
        const file2 = await response2.blob();
        let formData = new FormData();
        formData.append('file1', new File([file1], 'file_name1.jpg', { lastModified: new Date().getTime(), type: 'image/jpeg' }));
        formData.append('file2', new File([file2], 'file_name2.jpg', { lastModified: new Date().getTime(), type: 'image/jpeg' }));
		
		API.multipart("users/v2/face-match", formData).then(async (response) => {
			
			const blob = await fetch(self.props.user_img_file).then((res) => res.blob());
			let form = new FormData();
			form.append('file', new File([blob], 'file_name.jpg', { lastModified: new Date().getTime(), type: 'image/jpeg' }))
			form.append('imageInfo', "UI");
			if (response.status === "success" || response.status === "failed") {
				if (response.status === "success") {
					dispatch({ type: ALERT_DETAIL, data: true, message: `ID Card and Selfie images are matched`, severity: "success" })
					API.normalPost("upload", form).then(async (multipartResponse) => {
						if (multipartResponse.status === "success") {
							self.setState({ isLoading: false })
							dispatch({ type: REGISTER_SUCCESS, data: true });
							if (multipartResponse.payload.userType === "Admin") {
								self.props.navigate("/home")
							} else {
								self.props.navigate("/my-activity")
							}
						} else {
							self.setState({ isLoading: false })
							dispatch({ type: REGISTER_ERROR });
							dispatch({ type: ALERT_DETAIL, data: true, message: `Image`, severity: "error" })
						}
					});
				} else {
					self.setState({ isLoading: false })
					dispatch({ type: REGISTER_ERROR });
					dispatch({ type: ALERT_DETAIL, data: true, message: `ID Card and Selfie images are not matched`, severity: "error" })
				}
			} else {
				self.setState({ isLoading: false })
				dispatch({ type: ALERT_DETAIL, data: true, message: `Pictures did not match. Please make sure ID is oriented correctly.`, severity: "error" })
				dispatch({ type: REGISTER_ERROR });
			}
		});
	}
}

/**
 * This action creator used for forgot password send otp to server
 * @function
 * @param {self} self 
 * @returns dispatch
 */
export function forgotPasswordwithOtp(self) {
	return async dispatch => {
	     var data = {};
		data.phone = self.state.mobile_number
		API.post("users/forgotPassword", data).then(async (response) => {
			if (response.status === "success") {
				self.props.navigate("/otp-verification");
				dispatch({ type: ALERT_DETAIL, data: true, message: `A text message with a 6-digit verification code was just sent to ${self.state.mobile_number}`, severity: "success" })
			} else {
				dispatch({ type: ALERT_DETAIL, data: true, message: `You are not registered with this phone number`, severity: "error" })
			}
		});
	}
}

/**
 * This action creator used for verify otp to server
 * @function
 * @param {self} self 
 * @returns dispatch
 */
export function verifyForgotPasswordOtp(self) {
	return async dispatch => {
		let data = {};
		data.phone = self.props.phone
		data.otp = self.state.otp
		API.post("users/verifyOTP", data).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: USER_CONFIRMATION_ID, data: response.payload !== null ? response.payload.userId : null })
				self.props.navigate("/reset-password");
			} else {
				self.setState({ isEnabledResendButton: true })
				dispatch({ type: ALERT_DETAIL, data: true, message: `Invalid security verification code. Please check your code and try again.`, severity: "error" })
			}
		});
	}
}

/**
 * This action creator used for reset password to server
 * @function
 * @param {self} self 
 * @param {data} data 
 * @returns dispatch
 */
export function resetPassword(self, data) {
	return async dispatch => {
		API.post("users/reset-new-password", data).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: FORGOT_PASSWORD, data: false })
				self.props.navigate("/");
				dispatch({ type: ALERT_DETAIL, data: true, message: `Your password has been changed.`, severity: "success" })
			}
		});
	}
}

/**
 * This action creator used for resend sms to user to server
 * @function
 * @param {phone} phone 
 * @returns dispatch
 */
export function resendSmsToUser(phone) {
	return async dispatch => {
		let data = {};
		data.phone = phone
		API.post('users/resend-password', data).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: ALERT_DETAIL, data: true, message: `SMS sent successfully.`, severity: "success" })
			} else {
				dispatch({ type: ALERT_DETAIL, data: true, message: `SMS sent Failed`, severity: "error" })
			}
		})
	}
}