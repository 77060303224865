import API from '../api';
import {
	FETCH_ALL_USER, FETCH_USER, FETCH_USER_PROFILE, FETCH_USER_TYPES, FETCH_SEARCH_USER, FETCH_ALL_REQUESTED_USER,
	ALERT_DETAIL, FETCH_DASHBOARD_DATA, FETCH_USER_BY_PHONE, FETCH_ALL_HEALTH_CARE_HERO, FETCH_USER_PROFILES, USER_TEST_RESULTS, FETCH_TEST_DASHBOARD_DATA,
	FETCH_DASHBOARD_TEST_RESULT_DATA, FETCH_DASHBOARD_USER_DATA, FETCH_PASS_CODE
} from "./actionTypes";
import { fetchIndividualTetResult } from './testAction';
import _ from "underscore";
import moment from 'moment';

/**
 * Get user entries from server
 * @function
 * @returns dispatch
 */
export function fetchAllUsers() {
	return dispatch => API.get('user-profile/all')
		.then((response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_ALL_USER, data: response.payload })
			}
		});
}
/**
 * Get healthcarehero entries from server
 * @function
 * @returns dispatch
 */
export function fetchAllHealthCareHero() {
	return dispatch => API.get('user-profile/get-all/health-care-hero')
		.then((response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_ALL_HEALTH_CARE_HERO, data: response.payload })
			}
		});
}

/**
 * This action creator used for assign queue to user
 * @function
 * @param {id} id 
 * @param {body} body
 * @returns dispatch
 */
export function assignQueue(id, body) {
	return dispatch => API.put('user-profile/assign-queue-privilage/' + id, body)
		.then((response) => {
			if (response.status === "success") {
				dispatch({ type: ALERT_DETAIL, data: true, message: "Access successfully updated.", severity: "success" })
				dispatch(fetchAllHealthCareHero())
			} else {
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
			}
		});
}

/**
 * This action creator used for assign healhtcarehero to user
 * @function
 * @param {id} id 
 * @param {body} body
 * @returns dispatch
 */
export function assignMasterHealthcarHero(id, body) {
	return dispatch => API.put('user-profile/assign-master-privilage/' + id, body)
		.then((response) => {
			if (response.status === "success") {
				dispatch({ type: ALERT_DETAIL, data: true, message: "Access successfully updated.", severity: "success" })
				dispatch(fetchAllHealthCareHero())
			} else {
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
			}
		});
}

/**
 * Get user entry from server
 * @function
 * @returns dispatch
 */
export function fetchUser() {
	return dispatch => API.get('user-profile/')
		.then((response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_USER, data: response.payload })
				dispatch(fetchAllUsers())
			}
		});
}

/**
 * This action creator used for update user profile to server
 * @function
 * @param {self} self 
 * @param {data} data
 * @param {id} id
 * @returns dispatch
 */
export function updateUserProfile(self, id, data) {
	return dispatch => API.put('user-profile/info/' + id, data)
		.then((response) => {
			if (response.status === "success") {
				self.setState({
					open: false
				})
				dispatch({ type: ALERT_DETAIL, data: true, message: "User profile updated successfully.", severity: "success" })
				dispatch(fetchUser())
				dispatch(fetchAllUsers())
			} else {
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
			}
		});
}

/**
 * This action creator used for update user profile to server
 * @function
 * @param {self} self 
 * @param {data} data
 * @param {id} id
 * @returns dispatch
 */
export function updateUserProfileForMobileRespansiveScreen(self, id, data) {
	return dispatch => API.put('user-profile/info/' + id, data)
		.then((response) => {
			if (response.status === "success") {
				self.setState({
					isEditDialog: false,

				})
				dispatch({ type: ALERT_DETAIL, data: true, message: "User profile updated successfully.", severity: "success" })
				dispatch(fetchUserProfile())
			}
			else {
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
			}
		});
}

/**
 * This action creator used for update user Status to server
 * @function
 * @param {data} data
 * @param {id} id
 * @returns dispatch
 */
export function updateStatusUserProfile(id, data) {
	return dispatch => API.put('user-profile/' + id, data)
		.then((response) => {
			if (response.status === "success") {
				dispatch(fetchAllRequestedUsers())
			}
		});
}

/**
 * This action creator used for update password to server
 * @function
 * @param {self} self 
 * @param {data} data
 * @returns dispatch
 */
export function changePassword(self, data) {
	return function (dispatch) {
		API.post('users/resetpassword/', data).then((response) => {
			if (response.status === "success") {
				self.setState({
					openPass: false
				})
				dispatch({ type: ALERT_DETAIL, data: true, message: "password changed successfully.", severity: "success" })

			} else {
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
			}
		})
	}
}

/**
 * This action creator used for update password to server
 * @function
 * @param {self} self 
 * @returns dispatch
 */
export function fetchUserProfile(self, screen) {
	return async dispatch => {
		API.get("user-profile").then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_USER_PROFILE, data: response.payload });
				if (screen !== undefined && screen === "USERHOME") {
					self.setState({ selected_id: response.payload.id, selectedUser: response.payload })
					dispatch(fetchIndividualTetResult(self, response.payload.id))
				}

			}
		});
	}
}

/**
 * Get user type entries from server
 * @function
 * @returns dispatch
 */
export function fetchUserTypes() {
	return async dispatch => {
		API.get("users/user-type").then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_USER_TYPES, data: response.payload });
			}
		});
	}
}

/**
 * This action creator used for update user type to server
 * @function
 * @param {self} self 
 * @param {id} id
 * @returns dispatch
 */
export function changeUserType(self, id) {
	return async dispatch => {
		API.get("users/change-user-type/" + id).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: ALERT_DETAIL, data: true, message: "User type changed successfully", severity: "success", })
				window.localStorage.setItem('token', JSON.stringify(response.token));
				if (response.payload.userType === "PSV") {
					window.location.href = "/partner-dashboard";
				} else if (response.payload.userType === "OSV") {
					window.location.href = "/organization-dashboard";
				} else if (response.payload.userType === "DNR") {
					window.location.href = "/donor-dashboard";
				} else if (response.payload.userType === "CST") {
					window.location.href = "/support/home";
				} else if (response.payload.userType === "Admin" || response.payload.userType === "ADM") {
					window.location.href = "/dashboard";
				} else {
					// if (response.info === "UID" && response.payload.userType !== "Admin" && response.payload.userType !== "ADM") {
					// 	dispatch({ type: ALERT_DETAIL, data: true, message: "Missing Your ID", severity: "info" })
					// 	window.location.href = "/id-verification";
					// } else {
					// 	window.location.href = "/my-activity";
					// }

					if (response.payload.userType === "CLT") {
						window.location.href = "/user/home";
					} else {
						window.location.href = "/my-activity";
					}
				}
			} else {
				dispatch({ type: ALERT_DETAIL, data: true, message: "Please check your information and try again.", severity: "error" })
			}
		});
	}
}

/**
 * This action creator used for update user document to server
 * @function
 * @param {self} self 
 * @param {data} data
 * @returns dispatch
 */
export function uploadChangeUserDocument(self, data) {
	return async dispatch => {
		let form = new FormData();
		form.append('role', data.role);
		form.append('organization', data.organization);
		if (self.props.selected_user_type === "MSP") {
			form.append('externalId', data.organization);
		}
		if (self.props.selected_professional_image !== null) {
			form.append('imageInfo', "UD");
			const blob = await fetch(self.props.selected_professional_image).then((res) => res.blob());
			form.append('file', new File([blob], 'file_name.jpg', { lastModified: new Date().getTime(), type: 'image/jpeg' }))
		}
		form.append('userType', self.props.selected_user_type);

		API.normalPost("change-user-document-upload", form).then(async (response) => {
			if (response.status === "success") {
				self.setState({ isLoading: false })
				dispatch({ type: ALERT_DETAIL, data: true, message: "Your Record Updated", severity: "success" })
				self.props.navigate("/my-account")
			} else {
				self.setState({ isLoading: false })
				dispatch({ type: ALERT_DETAIL, data: true, message: "User does not exist", severity: "error" })
			}
		});
	}
}

/**
 * Get dashboard entries from server
 * @function
 * @returns dispatch
 */
export function fetchDashboard(self) {
	return dispatch => API.get('users/dashboard')
		.then((response) => {
			if (response.status === "success") {
				self.setState({ topDataLoading: false })
				dispatch({ type: FETCH_DASHBOARD_DATA, data: response.payload })
			} else {
				self.setState({ topDataLoading: false })
			}
		});
}

export function fetchDashboardTestResult(self) {
	return dispatch => API.get('users/dashboard/test-result')
		.then((response) => {
			if (response.status === "success") {
				self.setState({ middleDataLoading: false })
				dispatch({ type: FETCH_DASHBOARD_TEST_RESULT_DATA, data: response.payload })
			} else {
				self.setState({ middleDataLoading: false })
			}
		});
}

export function fetchDashboardUserResult(self) {
	return dispatch => API.get('users/dashboard/users')
		.then((response) => {
			if (response.status === "success") {
				self.setState({ secondDataLoading: false })
				dispatch({ type: FETCH_DASHBOARD_USER_DATA, data: response.payload })
			} else {
				self.setState({ secondDataLoading: false })
			}
		});
}

/**
 * Get user by phone from server
 * @function
 * @param {phone} phone
 * @returns dispatch
 */
export function fetchUserByPhone(phone) {
	return dispatch => API.get('users/phone-number-search/' + phone)
		.then((response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_USER_BY_PHONE, data: response.payload })
			} else {
				if (response.payload === null) {
					dispatch({ type: ALERT_DETAIL, data: true, message: "User does not exist", severity: "error" })
				}
			}
		});
}

/**
 * This action creator used for update user status to server
 * @function
 * @param {id} id
 * @param {data} data
 * @returns dispatch
 */
export function updateStatusUser(id, data) {
	return dispatch => API.put('users/inactive-user', data)
		.then((response) => {
			if (response.status === "success") {
				dispatch(fetchUser())
			}
		});
}


export function fetchUserProfiles() {
	return dispatch => API.get('user-profile/individual')
		.then((response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_USER_PROFILES, data: response.payload })
			}
		});
}


export function searchUserTestResultByPhone(self, url) {
	return async dispatch => {
		API.get("user-profile/search-user-test" + url).then(async (response) => {
			if (response.status === "success") {
				let groupUserProfiles = _.groupBy(response.payload.userProfiles, "phone")
				let groupLabTests = _.groupBy(response.payload.pendingLabTests, "phoneNumber")
				dispatch({ type: USER_TEST_RESULTS, data: { groupUserProfiles: groupUserProfiles, groupLabTests: groupLabTests } })

				if (Object.keys(groupUserProfiles).length > 1) {
					self.setState({ openDialog: true })
				} else {
					if (Object.keys(groupLabTests).length > 1) {
						self.setState({ openDialog: true })
					} else {
						if (Object.keys(groupUserProfiles).length === 1) {
							if (Object.keys(groupLabTests).length === 1) {
								if (Object.keys(groupLabTests)[0] === Object.keys(groupUserProfiles)[0]) {
									let parentUser = groupUserProfiles[Object.keys(groupUserProfiles)[0]] !== undefined ? groupUserProfiles[Object.keys(groupUserProfiles)[0]].length > 1 ? groupUserProfiles[Object.keys(groupUserProfiles)[0]].find(x => x.isParent === true) : groupUserProfiles[Object.keys(groupUserProfiles)[0]][0] : null;
									self.setState({ selectedTab: 0, selectedUser: parentUser !== undefined ? parentUser : null, selected_id: (parentUser !== undefined && parentUser !== null) ? parentUser.id : "", labUsers: [], allUserProfiles: groupUserProfiles[Object.keys(groupUserProfiles)[0]], selectedLabUser: null, selected_lab_test_id: "", allLabUsers: [] })
									let labUsers = groupLabTests[Object.keys(groupLabTests)[0]] !== undefined ? groupLabTests[Object.keys(groupLabTests)[0]] : null;
									self.setState({ labUsers: labUsers })
									if (parentUser !== null && parentUser !== undefined) {
										dispatch(fetchUnProcessedTestResult(self, parentUser))
										if ((!!self.state.firstName || !!self.state.lastName || !!self.state.dateOfBirth)) {
											self.setState({ profileLoading: true })
											dispatch(fetchRegisteredUserTestResultByPhone(self, parentUser))
										}
									}
								} else {
									self.setState({ openDialog: true })
								}
							} else {
								let parentUser = groupUserProfiles[Object.keys(groupUserProfiles)[0]] !== undefined ? groupUserProfiles[Object.keys(groupUserProfiles)[0]].length > 1 ? groupUserProfiles[Object.keys(groupUserProfiles)[0]].find(x => x.isParent === true) : groupUserProfiles[Object.keys(groupUserProfiles)[0]][0] : null;
								self.setState({ selectedTab: 0, selectedUser: parentUser !== undefined ? parentUser : null, selected_id: (parentUser !== undefined && parentUser !== null) ? parentUser.id : "", labUsers: [], allUserProfiles: groupUserProfiles[Object.keys(groupUserProfiles)[0]], selectedLabUser: null, selected_lab_test_id: "", allLabUsers: [] })
								if (parentUser !== undefined) {
									dispatch(fetchUnProcessedTestResult(self, parentUser))
									if ((!!self.state.firstName || !!self.state.lastName || !!self.state.dateOfBirth)) {
										self.setState({ profileLoading: true })
										dispatch(fetchRegisteredUserTestResultByPhone(self, parentUser))
									}
								}
							}
						} else {
							if (Object.keys(groupUserProfiles).length === 0 && Object.keys(groupLabTests).length === 1) {
								let labUsers = groupLabTests[Object.keys(groupLabTests)[0]] !== undefined ? groupLabTests[Object.keys(groupLabTests)[0]] : null;
								let groupLabUsers = labUsers !== null ? _.groupBy(labUsers, "dateOfBirth") : [];
								self.setState({ selectedTab: 1, selectedUser: null, selected_id: "", labUsers: (labUsers !== null && labUsers.length > 0) ? groupLabUsers[labUsers[0].dateOfBirth] : [], allLabUsers: labUsers, selected_lab_test_id: (labUsers !== null && labUsers.length > 0) ? labUsers[0].id : "", selectedLabUser: (labUsers !== null && labUsers.length > 0) ? labUsers[0] : null })
								if (labUsers !== null && labUsers.length > 0) {
									dispatch(fetchUnProcessedTestResult(self, labUsers[0]))
								}
							}
						}
					}
				}

				if (Object.keys(groupUserProfiles).length === 0 && Object.keys(groupLabTests).length === 0) {
					self.setState({ selectedTab: 0, selectedUser: null, selected_id: "", labUsers: [], allLabUsers: [], selected_lab_test_id: "", selectedLabUser: null })
					dispatch({ type: ALERT_DETAIL, data: true, message: "No User Found", severity: "error" })
				}
				self.setState({ isLoading: false })
			} else {
				self.setState({ selectedTab: 0, selectedUser: null, selected_id: "", labUsers: [], allLabUsers: [], selected_lab_test_id: "", selectedLabUser: null })
				dispatch({ type: ALERT_DETAIL, data: true, message: "No User Found", severity: "error" })
				self.setState({ isLoading: false })
			}
		}).catch(() => {
			self.setState({ selectedTab: 0, selectedUser: null, selected_id: "", labUsers: [], allLabUsers: [], selected_lab_test_id: "", selectedLabUser: null })
			dispatch({ type: ALERT_DETAIL, data: true, message: "No User Found", severity: "error" })
			self.setState({ isLoading: false })
		});
	}
}


export function resendSMS(self, data) {
	return dispatch => API.post('users/resend-sms', data)
		.then((response) => {
			if (response.status === "success") {
				dispatch({ type: ALERT_DETAIL, data: true, message: "Resend SMS Successfully", severity: "success" })
			}
		});
}

export function fetchTestDashboard(self) {
	return dispatch => API.get(`test-result/test-dashboard/data?date=${moment(self.state.date).format('MM/DD/YYYY')}`)
		.then((response) => {
			if (response.status === "success") {
				self.setState({ bottomDataLoading: false })
				dispatch({ type: FETCH_TEST_DASHBOARD_DATA, data: response.payload })
			} else {
				self.setState({ bottomDataLoading: false })
			}
		});
}


export function sendCustomSMS(self, data) {
	return dispatch => API.post('users/send-custom-sms', data)
		.then((response) => {
			if (response.status === "success") {
				self.setState({
					test_type: "ALL",
					test_result_status: "ALL",
					zip_code: "",
					phone_number: "",
					start_date: moment().format("MM/DD/YYYY"),
					end_date: moment().format("MM/DD/YYYY"),
					message: self.state.sms_type === "REGISTERED_USERS" ? "" : "Your test results are ready. Please click to download the MedFilo app by Premier on Apple or Android to log in and view your results now: http://onelink.to/confirmd.\nThe MedFilo app can also be used as a secure digital wallet to access all your medical records in one place for travel, work, and taking control of your health records today.\nAny questions, please call 866-451-2571",
					users: [],
					messageDisable: false,
					findUser: null,
					is_sms: false,
					is_notification: false,
					isLoading: false
				})
				dispatch({ type: ALERT_DETAIL, data: true, message: "SMS Send Successfully", severity: "success" })
			}
			self.setState({ isLoading: false })
		});
}

export function searchAllUser(self, phone) {
	return dispatch => API.get(`user-profile/search-all-user-profile?phone=${phone}`)
		.then((response) => {
			self.setState({ isLoading: false })
			if (response.status === "success") {
				dispatch({ type: FETCH_SEARCH_USER, data: response.payload })
			} else {
				dispatch({ type: ALERT_DETAIL, data: true, message: "No User Found", severity: "Error" })
			}
		});
}

export function findUserForSMS(self, phone) {
	return dispatch => API.get('user-profile/search-user-by-phone/' + phone)
		.then((response) => {
			if (response.status === "success") {
				if (response.payload !== null) {
					self.setState({ findUser: response.payload })
				} else {
					dispatch({ type: ALERT_DETAIL, data: true, message: "User does not exist", severity: "error" })
				}
			} else {
				if (response.payload === null) {
					dispatch({ type: ALERT_DETAIL, data: true, message: "User does not exist", severity: "error" })
				}
			}
		});
}


export function fetchAllRequestedUsers() {
	return dispatch => API.get('user-profile/v2/all/requested-user')
		.then((response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_ALL_REQUESTED_USER, data: response.payload })
			}
		});
}


export function fetchUnProcessedTestResult(self, data) {
	return async dispatch => {
		API.get(`user-profile/unprocessed-data/${data.firstName}/${data.lastName}/${data.dateOfBirth}`).then(async (response) => {
			if (response.status === "success") {
				self.setState({ unprocessedResults: response.payload })
			}
		})
	}
}


export function fetchRegisteredUserTestResultByPhone(self, user) {
	return async dispatch => {
		API.get(`user-profile/search-user-test?phone=${user.phone}`).then(async (response) => {
			if (response.status === "success") {
				let groupUserProfiles = _.groupBy(response.payload.userProfiles, "phone")
				let groupLabTests = _.groupBy(response.payload.pendingLabTests, "phoneNumber")
				let labUsers = !!groupLabTests[user.phone] ? groupLabTests[user.phone] : [];
				let allUserProfiles = !!groupUserProfiles[user.phone] ? groupUserProfiles[user.phone] : [];
				self.setState({ allUserProfiles: allUserProfiles, labUsers: labUsers, profileLoading: false })
			} else {
				self.setState({ profileLoading: false })
			}
		}).catch(() => {
			self.setState({ profileLoading: false })
		})
	}
}

export function findExistingUserTypes(self, phone) {
	return dispatch => {
		API.get(`user-profile/search-user-type/${phone}`).then((response) => {
			if (response.status === "success") {
				if (response.payload !== null) {
					self.setState({
						userRoleTypes: response.payload.userTypes.filter(x => x.roleType.code !== "CLT"),
						userDetails: response.payload
					})
				} else {
					dispatch({ type: ALERT_DETAIL, data: true, message: `This user doesn't exist`, severity: "error" })
				}
			}
		})
	}
}

export function revokeUserRole(self, profileId, selectedRoleId) {
	return dispatch => {
		API.delete(`user-profile/${profileId}/remove/user-type/${parseInt(selectedRoleId)}`).then((response) => {
			if (response.status === "success") {
				if (response.payload !== null) {
					self.setState({
						removeDialog: false, search_phone: "", userRoleTypes: [], userDetails: null, selectedRoleId: null
					});
					dispatch({ type: ALERT_DETAIL, data: true, message: `Role revoked successfully`, severity: "success" })
				} else {
					dispatch({ type: ALERT_DETAIL, data: true, message: `This user doesn't exist`, severity: "error" })
				}
			}
		})
	}
}

export function createPassCode(data) {
	return async dispatch => {
		API.post("user-passcode", data).then(async (response) => {
			dispatch({ type: FETCH_PASS_CODE, data: response.payload });
		});
	}
}

export function createHipaaWaiver(data, self) {
	return async dispatch => {
		API.post('user-profile/hipaa-waiver', data).then(async (response) => {
			if (response.status === 'success') {
				dispatch({ type: FETCH_USER_PROFILE, data: response.payload });
				self.setState({ isLoading: false })
			} else {
				dispatch({ type: ALERT_DETAIL, data: true, message: `There was a problem`, severity: "error" })
			}
		})
	}
};