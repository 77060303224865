import {
	TEST_RESULT, FETCH_TEST_RESULTS, FETCH_USER_RESULTS, SELECTED_TEST_TYPE,
	SELECTED_TEST_TYPE_IMAGE, VERIFICATION_TEST_VALUE, ALERT_DETAIL,
	USER_PROFILE_TEST_RESULT, FETCH_QUEUE_USERS, FETCH_USER_PROFILE, USERS_WITH_TEST_RESULTS, NEW_USERS_WITH_TEST_RESULTS, ALL_TEST_RESULTS, TEST_RESULTS_COUNT, 
	// ALL_TEST_RESULTS_UPDATE, 
	UPDATE_TEST_RESULTS, INDIVIDUAL_TEST_RESULTS
} from './actionTypes';
import API from '../api';
import { END_POINT } from '../helpers/Constants';
import moment from 'moment';

export function fetchUploadedDocument(self, qrcodeValue) {
	return async dispatch => {
		let data = {}
		data.qrCode = qrcodeValue.text;
		API.post("qrcode/verifier-scan-client-qr", data).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: TEST_RESULT, data: response.payload })
			   self.props.navigate("/scan-result", { state: { qrCode: qrcodeValue.text  } });
				
			} else {
				self.props.navigate("/my-activity")
				dispatch({ type: ALERT_DETAIL, data: true, message: "​No unverified health record found​", severity: "error" })
			}
		});
	}
}

export function fetchUserTestInformation(qrcodeValue) {
	return async dispatch => {
		let data = {}
		data.qrCode = qrcodeValue;
		API.post("qrcode/verifier-scan-client-qr", data).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: TEST_RESULT, data: response.payload })
			}
		});
	}
}

export function fetchTestByUserProfileId(id) {
	return async dispatch => {
		API.get("user-profile/covid-vaccine-record/" + id).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: USER_PROFILE_TEST_RESULT, data: response.payload })
			}
		});
	}
}

export function updateUserVaccines(id, self) {
	return async dispatch => {
		API.put("user-profile/change-user-vaccine/" + id).then(async (response) => {
			if (response.status === "success") {
				self.setState({
					vaccinDialog: false,
					selected_user_profile_id: null,
					isConfirmationDialog: false
				})
				dispatch({ type: ALERT_DETAIL, data: true, message: "​Success. You have updated the vaccine records.​​", severity: "success" })
			} else {
				self.setState({
					vaccinDialog: false,
					selected_user_profile_id: null,
					isConfirmationDialog: false
				})
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
			}
		});
	}
}

export function testUserResultValues(self, qrcodeValue) {
	return async dispatch => {
		let data = {}
		data.qrCode = qrcodeValue;
		API.post("qrcode/access-scan-client-qr", data).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: TEST_RESULT, data: response.payload })
				self.props.navigate("/scan-result")
			} else {
				self.props.navigate("/my-activity")
				dispatch({ type: ALERT_DETAIL, data: true, message: "Result not available", severity: "error" })
			}
		});
	}
}

export function fetchTestResults() {
	return async (dispatch) => {
		API.get("test-result/user-test-result/1").then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_USER_RESULTS, data: response.payload });
			}
		});
	};
}

export function fetchTestResultsValues() {
	return async (dispatch) => {
		API.get("test-result/user-test-result-values/1").then(async (response) => {
			if (response.status === "success") {
				for(let test of response.payload){
					if(test.testType.isVaccine === true){
						test.displayDate = test.vaccineResults.length > 0 ? test.vaccineResults[0].vaccinationDate : test.displayDate
					}
				}
				dispatch({ type: FETCH_TEST_RESULTS, data: response.payload });
			}
		});
	};
}

export function fetchUnverifiedRecord(self, id) {
	return async dispatch => {
		API.get("test-result/unverified-test-result/" + id).then(async (response) => {
			if (response.status === "success") {
				for(let test of response.payload){
					if(test.testType.isVaccine === true){
						test.displayDate = test.vaccineResults.length > 0 ? test.vaccineResults[0].vaccinationDate : test.displayDate
					}
				}
				dispatch({ type: FETCH_TEST_RESULTS, data: response.payload })
			}
		});
	}
}

export function uploadTestByClient(self, clientId) {
	return async dispatch => {
		let form = new FormData();

		//Convert blob to a file to upload it to server
		const blob = await fetch(self.props.selected_test_type_image).then((res) => res.blob());
		form.append('file', new File([blob], 'file_name.jpg', { lastModified: new Date().getTime(), type: 'image/jpeg' }))
		form.append('imageInfo', "UTD");
		form.append('testTypeId', self.props.selected_test_type !== null ? self.props.selected_test_type.id : null);
		form.append('clientUserProfileId', clientId);
		API.normalPost("test-result", form).then(async (response) => {
			if (response.status === "success") {
				self.setState({ isLoading: false })
				if (self.state.userType === 'CLT') {
					dispatch({ type: ALERT_DETAIL, data: true, message: "Success. You have uploaded a new document to your health record library.", severity: "success" })
					self.props.navigate('/my-health')
				} else {
					dispatch({ type: ALERT_DETAIL, data: true, message: "Success. You have uploaded a new document to patient record library." ,severity: "success" })
					dispatch({ type: VERIFICATION_TEST_VALUE, data: response.payload })
					self.props.navigate('/enter-test-result',{ replace: true })
				}
			} else {
				self.setState({ isLoading: false })
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
			}
		});
	}
}


export function userUploadTestResult(self, clientId) {
	return async dispatch => {
		let form = new FormData();

		//Convert blob to a file to upload it to server
		const blob = await fetch(self.props.selected_test_type_image).then((res) => res.blob());
		form.append('file', new File([blob], 'file_name.jpg', { lastModified: new Date().getTime(), type: 'image/jpeg' }))
		form.append('imageInfo', "UTD");
		form.append('testTypeId', self.props.selected_test_type !== null ? self.props.selected_test_type.id : null);
		form.append('clientUserProfileId', clientId);
		form.append('testResultValues', JSON.stringify(self.state.testResultArr));//testResultValues
		form.append('dateOfTest', moment(self.state.date_of_test, "MM/DD/YYYY").format("YYYY-MM-DD"));
		form.append('timeOfTest', self.state.time_of_test);

		API.normalPost("test-result", form).then(async (response) => {
			if (response.status === "success") {
				self.setState({ isLoading: false })
					dispatch({ type: ALERT_DETAIL, data: true, message: "​Success. You have uploaded a new document to your health record library.​​", severity: "success" })
					self.props.navigate('/my-health',{ replace: true })
			} else {
				self.setState({ isLoading: false })
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
			}
		});
	}
}


export function uploadTestResult(self, body) {
	return async dispatch => {
		// //api put request for server		  	 
		API.put("test-result/new-verify-test/" + body.testResultId, body).then(async (response) => {
			if (response.status === "success") {
				self.props.navigate("/test-success")
				localStorage.removeItem("userId");
				dispatch({ type: ALERT_DETAIL, data: true, message: "Your Patient’s Health Record has been updated", severity: "success" })
			} else {
				dispatch({ type: ALERT_DETAIL, data: true, message: "Test Result does not exist", severity: "error" })
			}
		});
	}
}


export function deleteTest(self) {
	return async (dispatch) => {
		API.delete("test-result/" + self.state.selectedTest.id).then(async (response) => {
			if (response.status === "success") {
				await self.setState({ deleteLoading: false, selectedTest: null, is_loading: false })
				await dispatch({ type: FETCH_TEST_RESULTS, data: response.payload });
			} else {
				await self.setState({ deleteLoading: false, selectedTest: null, is_loading: false })
			}
		});
	};
}


export function uploadVaccineTestByClient(self, clientId) {
	return async dispatch => {
		let form = new FormData();

		//Convert blob to a file to upload it to server
		const blob = await fetch(self.props.selected_test_type_image).then((res) => res.blob());
		form.append('file', new File([blob], 'file_name.jpg', { lastModified: new Date().getTime(), type: 'image/jpeg' }))
		form.append('imageInfo', "UTD");
		form.append('testTypeId', self.props.selected_test_type !== null ? self.props.selected_test_type.id : null);
		form.append('clientUserProfileId', clientId);
		API.normalPost("test-result/vaccine-document", form).then(async (response) => {
			if (response.status === "success") {
				self.setState({ isLoading: false })
				if (self.state.userType === 'CLT') {
					dispatch({ type: SELECTED_TEST_TYPE, data: null });
					dispatch({ type: SELECTED_TEST_TYPE_IMAGE, data: null });
					self.props.navigate('/my-health')
					dispatch({ type: ALERT_DETAIL, data: true, message: "​Success. You have uploaded a new document to your health record library.​​", severity: "success" })
				} else {
					dispatch({ type: ALERT_DETAIL, data: true, message: "​Success. You have uploaded a new document to patient record library.​​", severity: "success" })
					if (response.payload.testType.isVaccine === false) {
						dispatch({ type: VERIFICATION_TEST_VALUE, data: response.payload })
						self.props.navigate('/enter-test-result')
					} else {
						dispatch({ type: VERIFICATION_TEST_VALUE, data: response.payload })
						self.props.navigate('/vaccination')
					}
				}
				self.setState({ buttonVisible: false })
			} else {
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
				self.setState({ buttonVisible: true })
				self.setState({ isLoading: false })
			}
		});
	}
}

export function fetchverifyRecord() {
	return async dispatch => {
		API.get("user-profile/patients/reports/").then(async (response) => {
			if (response.status === "success") {
				await dispatch({ type: FETCH_QUEUE_USERS, data: response.payload })
			}
		});
	}
}

export function updateAssignedRecord(userId, body) {
	return async dispatch => {
		API.put(`user-profile/assign-verifier/${userId}`, body).then(async (response) => {
			if (response.status === "success") {
			}
		});
	}
}

export function updateQueueRequest(self) {
	return async dispatch => {
		API.put("user-profile/request/user-queue").then(async (response) => {
			if (response.status === "success") {
				self.setState({ isConfirmationDialog: false })
				await dispatch({ type: FETCH_USER_PROFILE, data: response.payload });
			}
		});
	}
}

export function addQueue(self, id) {
	return async dispatch => {
		API.put("test-result/add-to-queue/" + id).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: ALERT_DETAIL, data: true, message: "Your document is added to queue", severity: "success" })
			}
		});
	}
}

export function addAllDocumentToQueue(self, userProfileId) {
	return async dispatch => {
		API.put("test-result/add-all-document-to-queue/" + userProfileId).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: ALERT_DETAIL, data: true, message: "Your document is added to queue", severity: "success", })
			}
		});
	}
}


export function fetchPendingTestResultsByUser(self, offset) {
	return async dispatch => {
		API.get(`test-result/pending/user-result?offset=${offset}&limit=${50}`).then(async (response) => {
			if (response.status === "success") {
				self.setState({paginationCount: response.count})
				dispatch({ type: USERS_WITH_TEST_RESULTS, data: response.payload })
			}else{
				dispatch({ type: USERS_WITH_TEST_RESULTS, data: [] })
			}
		});
	}
}


export function assignVerifier(self, id, data) {
	return async dispatch => {
		API.put(`test-result/${id}/update-health-care-hero`, data).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: NEW_USERS_WITH_TEST_RESULTS, data: response.payload })
				self.setState({ isConfirmationDialog: false, selectedTestId: null, code: null })
			}
		});
	}
}

export function fetchAllTestResults(self, offset) {
	return async dispatch => {
		API.get(`test-result/all/tests?offset=${offset}&limit=${50}`).then(async (response) => {
			if (response.status === "success") {
				// if(offset === 0){
				// 	dispatch({ type: ALL_TEST_RESULTS, data: response.payload })
				// } else {
				// 	dispatch({ type: ALL_TEST_RESULTS_UPDATE, data: response.payload })
				// }
				self.setState({paginationCount: response.count})
				dispatch({ type: ALL_TEST_RESULTS, data: response.payload })
				dispatch({ type: TEST_RESULTS_COUNT, data: response.count })
			}
		});
	}
}


export function updateTestByAdmin(self, id, mobile) {
	return async dispatch => {
		API.put(`test-result/verify-test/admin/${id}`).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: UPDATE_TEST_RESULTS, data: response.payload })
				if(mobile !== null){
					dispatch(searchTestResults(mobile, "MOBILE", self))
				}
				dispatch({ type: ALERT_DETAIL, data: true, message: "Test Result updated successfully", severity: "success" })
				self.setState({ page:0, isConfirmationDialog: false, selectedTestId: null,isDialogConfirmation: false,selectedTestData: null })
			}
		});
	}
}


export function rejectTestByAdmin(self, id, mobile, data) {
	return async dispatch => {
		API.put(`test-result/reject-test/admin/${id}`, data).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: UPDATE_TEST_RESULTS, data: response.payload })
				if(mobile !== null){
					dispatch(searchTestResults(mobile, "MOBILE", self))
				}
				dispatch({ type: ALERT_DETAIL, data: true, message: "Test Result updated successfully", severity: "success" })
				self.setState({ page:0, isRejectDialog: false, rejectReason: "", selectedTestId: null,isDialogConfirmation: false,selectedTestData: null })
			}
		});
	}
}


export function searchTestResultByPhone(self, phone) {
	return async dispatch => {
		API.get(`test-result/fetch-phone/test-result/${phone}`).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: ALL_TEST_RESULTS, data: response.payload })
				dispatch({ type: TEST_RESULTS_COUNT, data: response.payload.length })
			} else {
				dispatch({ type: ALERT_DETAIL, data: true, message: "Phone number doesn't exist", severity: "error" })
			}
		}).catch(()=>{
			dispatch({ type: ALERT_DETAIL, data: true, message: "Phone number doesn't exist", severity: "error" })
		});
	}
}

export function fetchIndividualTetResult(self, id) {
	return async dispatch => {
		API.get(`test-result/fetch-individual/test-result/${id}`).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: INDIVIDUAL_TEST_RESULTS, data: response.payload })
			}
		});
	}
}

export function downloadTestReport(self, id) {
	return async dispatch => {
		API.get(`test-result/download/test-report/${id}`).then(async (response) => {
			if (response.status === "success") {
				let test_report_url = `${END_POINT}pdf-test-report/${response.payload.pdfReportStorageKey}`
				self.setState({ test_report_url: test_report_url, dialogOpen: true })
			}
		});
	}
}

export function deleteDuplicateTest(data) {
	return async (dispatch) => {
		API.delete(`test-result/${data.id}/duplicate-record/${data.clientUserProfileId}`).then(async (response) => {
			if (response.status === "success"){
				dispatch({ type: ALERT_DETAIL, data: true, message: "Your test result deleted successfully", severity: "success" })
			}
		});
	};
}

export function updateNewTestType(data, test_type_id,self) {
	return async (dispatch) => {
		let body = {};
		body.test_type_id = test_type_id;
		API.put(`test-result/${data.id}/change-test-type/${data.clientUserProfileId}`,body).then(async (response) => {
			if (response.status === "success"){
				dispatch({ type: ALERT_DETAIL, data: true, message: "Your test type changed successfully", severity: "success" })
			}
			self.setState({openTestTypeDialog: false, selectedTestTypeId: null, selectedTestResult: null})
		});
	};
}

export function searchTestResults(phone, result, self) {
	return async dispatch => {
		API.get(`test-result/search/test-result?phone=${phone}&result=${result}`).then(async (response) => {
			self.setState({ isLoading: false })
			if (response.status === "success") {
				dispatch({ type: ALL_TEST_RESULTS, data: response.payload })
			}else{
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "success" })
			}
		});
	}
}


export function reUploadTestDocument(self, id, data, isVaccine) {
	return async dispatch => {
		API.normalPut(`test-result/${isVaccine ? "re-upload-vaccine-document" : "re-upload-test-document"}/${id}`, data).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_TEST_RESULTS, data: response.payload })
				self.props.navigate("/test-history", { 'from_button': 'all' })
			}
		});
	}
}