import { FETCH_PENDING_LAB_TEST_RESULTS, FETCH_PENDING_LAB_TEST_RESULTS_COUNT, TEST_USERS, USER_TOKEN_INFO, SELECT_PRIMARY_CONTACT } from "../actions/actionTypes"

const initialstate = {
    labTestResults: [],
    labTestResultsCount: null,
    userTokenInfo: null,
    testUsers: null,
    token: null,
    selectPrimaryContact: null
}

export default function labTestResultsReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_PENDING_LAB_TEST_RESULTS:
            return {
                ...state,
                labTestResults: action.data
            }
        case FETCH_PENDING_LAB_TEST_RESULTS_COUNT:
            return {
                ...state,
                labTestResultsCount: action.data
            }
        case USER_TOKEN_INFO:
            return {
                ...state,
                userTokenInfo: action.data,
                token: action.token
            }
        case TEST_USERS:
            return {
                ...state,
                testUsers: action.data
            }
        case SELECT_PRIMARY_CONTACT:
            return {
                ...state,
                selectPrimaryContact: action.data
            }

        default:
            return state;
    }
}