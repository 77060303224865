import React, { Component } from "react";
import { TextField, Grid, Typography, Button, InputAdornment, IconButton } from "@mui/material";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { changePassword } from "../../actions/sessionAction";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { withRouter } from "../../withRouter";
import theme from "../../theme";

const DOT_LENGTH = 10;

const styles = {
    paper: {
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        height: '1vh'
    },
    topContainer: {
        backgroundColor: "#20478E",
        backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderBottomLeftRadius: "10%",
        borderBottomRightRadius: "10%"
    },
    bottomContainer: {
        marginTop: -40,
        backgroundColor: "#fff",
        borderRadius: 5,
        margin: "0px 30px",
        padding: "10px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s",
        width: '30px%'
    },
    titleHead: {
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "center",
        padding: 5
    },
    title: {
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "center",
        padding: 5,
        color: "#fff",
    },
    titleText: {
        fontSize: 15,
        color: "#444444",
        textAlign: "center",
        padding: "0px 15px"
    },
    buttonContainer: {
        padding: 25,
        paddingTop: 35
    },
    button: {
        fontSize: 15,
        backgroundColor: "#4B86E3",
        color: "#fff",
        fontWeight: "bold",
        borderRadius: 5,
        '&:hover': {
            backgroundColor: "#4B86E3",
        },
    },
    bottomTextContainer: {
        padding: 30,
        textAlign: "center",
    },
    inputStyle: {
        fontSize: "15px !important",
        padding: "5px !important",
    },
    inputRoot: {
        borderRadius: "0px !important"
    },
    inputLabel: {
        color: "#4B86E3"
    },
    topText: {
        textAlign: "center",
        // fontWeight: "bold",
        //fontFamily: "Rubik-Medium",
        fontSize: 18,
        paddingVertical: 5
    },
    secondaryText: {
        //fontFamily: "Rubik-Regular",
        textAlign: "center",
        fontSize: window.screen.width >= 1280 ? 16 : 14,
        paddingVertical: 5,
        color: "#7F8EB8"
    },
    innerInpuView: {
        display: "flex",
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: "#F6F6F6"
    },
    textView: {
        paddingVertical: 5
    },
    pickView: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center"
    },
    borderDot: {
        width: 24,
        height: 24,
        borderRadius: 12,
        backgroundColor: "#fff",
        borderWidth: 6,
        borderColor: "rgba(265,265,265,0.2)"
    },
    bottomTextView: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: "center",
        marginTop: 15
    },
    labelText: {
        fontSize: 16,
        color: "#7F8EB8",
        //fontFamily: "Rubik-Regular"
    },
    bottomPolicyTextView: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: "flex-start",
        marginLeft: 10,
        marginTop: 5
    },
    buttons: {
        marginTop: 50,
        marginBottom: 10,
        borderRadius: 5,
        fontSize: 15,
        fontWeight: "bold",
        // width: "40%",
        backgroundColor: theme.palette.primary.main
    },
    continueText: {
        marginTop: 5,
        fontSize: 15,
        color: "#ffffff",
        textTransform: 'capitalize'
    },
    infoContainer: {
        display: 'flex',

        width: "100%",
        //marginTop: -DEVICE_WIDTH,
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 15
    },
    dotView: {
        display: 'flex',
        flexDirection: "row",
        width: "90%",
        alignItems: "center",
        justifyContent: "space-around",
        marginTop: -100
    },
    dotStyle: {
        width: 12,
        height: 12,
        borderRadius: 6,
        backgroundColor: "rgba(265,265,265,0.5)"
    },
};

class ChangePassword extends Component {
    // static contextTypes = {
    //     router: PropTypes.object,
    // }

    constructor() {
        super();
        this.state = {
            password: "",
            confirm_password_values: "",
            password_error: false,
            invalid_password_error: false,
            confirm_password_error: false,
            invalid_confirm_password_error: false,
            secureEntry: true,
            secureEntryConfirm: true
        }
    };

    renderDotView = () => {
        let DotArray = []
        for (let i = 0; i < DOT_LENGTH; i++) {
            DotArray.push(<div key={i.toString()} id={i} style={{
                width: 4,
                height: 4,
                borderRadius: 2,
                backgroundColor: "rgba(265,265,265,0.5)"
            }} />)
        }
        return DotArray;
    };

    passwordValidation = (params) => {
        var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(\S){8,}$/
        return re.test(params)
    };

    passwordConfirmValidation = (confirm_password, password) => {
        if (confirm_password === password) {
            return true;
        }
        return false;
    };

    handleClickShowPassword = () => {
        this.setState({ secureEntry: !this.state.secureEntry })
    };

    handleClickConfirmShowPassword = () => {
        this.setState({ secureEntryConfirm: !this.state.secureEntryConfirm })
    };

    onSubmit = () => {
        let { password, confirm_password_values } = this.state;
        let isError = false;

        if (password === "" || password === null) {
            this.setState({ password_error: true });
            isError = true;
        } else {
            if (!this.passwordValidation(password)) {
                this.setState({ invalid_password_error: true });
                isError = true;
            }
        }

        if (confirm_password_values === "" || confirm_password_values === null) {
            this.setState({ confirm_password_error: true });
            isError = true;
        } else {
            if (!this.passwordConfirmValidation(confirm_password_values, password)) {
                this.setState({ invalid_confirm_password_error: true });
                isError = true;
            }
        }

        if (isError === false) {
            let data = {}
            data.password = this.state.password
            this.props.dispatch(changePassword(data, this))
        }
    };

    render() {

        return (
            <div style={styles.paper}>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <div style={styles.topContainer}>
                            <Typography style={styles.title}>Change Password</Typography>
                        </div>
                        <div style={styles.infoContainer}>
                            <div style={styles.dotView}>
                                {/* <div style={styles.dotStyle} />
                                {this.renderDotView()}
                                <div style={styles.borderDot} />
                                {this.renderDotView()}
                                <div style={styles.dotStyle} /> */}
                            </div>
                        </div>
                        <Grid container justifyContent="center" style={{ marginTop: -40 }}>
                            <Grid item xs={12} sm={6} md={4}>
                                <div style={styles.bottomContainer}>
                                    <Typography style={styles.topText}>Set Your Password</Typography>
                                    <div style={styles.textView}>
                                        <div style={styles.innerInpuView}>
                                            <div style={styles.pickView} />
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="password"
                                                variant="standard"
                                                label="Password"
                                                type={this.state.secureEntry ? "password" : "text"}
                                                name="password"
                                                autoComplete="off"
                                                InputProps={{ // <-- This is where the toggle button is added.
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={this.handleClickShowPassword}
                                                            //   onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {this.state.secureEntry ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                value={this.state.password}
                                                onChange={(event) => this.setState({ password: event.target.value, password_error: false, invalid_password_error: false })}
                                                error={this.state.password_error === true ? true : this.state.invalid_password_error === true ? true : false}
                                                helperText={this.state.password_error === true ? "Enter password" : this.state.invalid_password_error === true ? "Please enter a password with at least 8 characters, a number, an uppercase and a lowercase character." : ""}
                                            />
                                        </div>
                                    </div>
                                    <div style={styles.textView}>
                                        <div style={styles.innerInpuView}>
                                            <TextField
                                                margin="normal"
                                                variant="standard"
                                                fullWidth
                                                id="password"
                                                type={this.state.secureEntryConfirm ? "password" : "text"}
                                                label="Confirm Password"
                                                name="password"
                                                autoComplete="off"
                                                InputProps={{ // <-- This is where the toggle button is added.
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={this.handleClickConfirmShowPassword}
                                                            //   onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {this.state.secureEntryConfirm ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                value={this.state.confirm_password_values}
                                                onChange={(event) => this.setState({ confirm_password_values: event.target.value, confirm_password_error: false, invalid_confirm_password_error: false })}
                                                error={this.state.confirm_password_error === true ? true : this.state.invalid_confirm_password_error === true ? true : false}
                                                helperText={this.state.confirm_password_error === true ? "Enter confirm password" : this.state.invalid_confirm_password_error === true ? "confirm password doesn't match with password" : ""}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={6} md={4}>
                                <div style={styles.bottomTextContainer}>
                                    <Button
                                        type="Button"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        style={styles.buttons}
                                        onClick={() => this.onSubmit()}
                                    >
                                        <Typography style={styles.continueText}>Change Password</Typography>
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {

    };
};

ChangePassword.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(ChangePassword));