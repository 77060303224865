import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Typography } from '@mui/material';
import AccountCircle from "../../../../../../assets/images/account_circle.png";
import { connect } from "react-redux";



const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 100,
    height: 100
  },
  name: {
    marginTop: 1,
    fontSize: 16,
    color: "#20478E",
    fontWeight: "bold"
  },
  chip: {
    backgroundColor:"#FF8C00",
    padding: "3px 15px",
    borderRadius: 15,
    color: "#fff",
    fontSize: 13,
    fontWeight: "bold"
  }
};

const Profile = props => {
  const { className, ...rest } = props;

  return (
    <div
      {...rest}
      style={styles.root}
    >
      <Avatar
        alt="Person"
        style={styles.avatar}
        src={AccountCircle}
      />
      <Typography
        style={styles.name}
      >
        Gowtam Ramanujam
      </Typography>
      <Typography
        style={styles.chip}
      >
        Administrator
      </Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    
  };
};
export default  connect(mapStateToProps)(Profile);