import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { fetchUploadedDocument, testUserResultValues } from "../../actions/testAction";
import { fetchUserProfile } from "../../actions/userAction";
//import QrReader from 'react-qr-reader';
import QrReader from "react-qr-scanner";
import { Typography, Grid } from "@mui/material";
import theme from "../../theme";
import { withRouter } from "../../withRouter";

const { innerWidth: width, innerHeight: height } = window;

const styles = {
    paper: {
        // boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        // backgroundColor: "#20478E",
        // backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)",
        //height: '100vh',
        // padding: 20,
        // paddingBottom: 100
    },
    button: {
        marginTop: 10,
        marginBottom: 10,
        padding: 15,
        borderRadius: 10,
        fontSize: 15,
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.main
    },
    mainDiv: {
        // backgroundColor: '#0098cd',
        // height: '100vh',
        height: height,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'hidden',
        overflowX: 'hidden'
        // position: 'absolute',
        // overflowY: 'hidden'
    },
    photoContainer: {
        // border:"2px solid red",
        height: height,
        // width: '100%',
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up("sm")]: {
            justifyContent: 'center',
        },
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',

        // position: 'absolute'
    },
    infoText: {
        fontSize: 18,
        paddingTop: 10,
        color: "#000",
        textAlign: "center",
    },
    textContainer: {
        display: 'inline-block',
        position: 'relative',
        textAlign: 'center',
        padding: 10,
        zIndex: 100
    },
    cameraRectangle: {
        // marginTop: marginSpacing,
        height: height / 1.5,
        width: width / 1.3,
        border: '4px solid #0098cd',
        display: 'inline-block',
        // position: 'relative',
        zIndex: 101
    },
    buttonContainer: {
        display: 'inline-block',
        position: 'relative',
        zIndex: 100
    },
    roundButton: {
        margin: 20,
        width: height / 15,
    },
    roundButtonCircle: {
        width: '100%',
        height: 0,
        paddingBottom: '100%',
        borderRadius: '50%',
        border: '10px solid #cfdcec',
        overflow: 'hidden',
        background: '#4679BD',
        boxShadow: '0 0 3px gray'
    },
};

class QRScan extends Component {
    // static contextTypes = {
    //     router: PropTypes.object,
    // }
    constructor() {
        super();
        this.state = {
            delay: 100,
            qrcodeValue: null,
            userProfile: null,
            userType: null
        }
        this.handleScan = this.handleScan.bind(this)
        this.openImageDialog = this.openImageDialog.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.user_profile !== state.userProfile) {
            return {
                userProfile: props.user_profile,
                userType: props.user_profile.userType
            }
        }
        return null;
    }

    componentDidMount() {
        this.props.dispatch(fetchUserProfile());
        //this.props.dispatch(fetchUploadedDocument(this, { text: 'YnRalBzzb9mQ2z/OC6cuAU4pEYLD/9gif82HJMYz4FY=' }));
    }

    handleScan(result) {
        if (result && this.state.userType !== null) {
            if (this.state.userType === 'MSP') {
                this.props.dispatch(fetchUploadedDocument(this, result));
            } else {
                this.props.dispatch(testUserResultValues(this, result));
            }
        }
    }

    handleError(err) {
        alert(err)
    }

    openImageDialog() {
        this.refs.qrReader1.openImageDialog()
    }

    render() {
        // const { styles } = this.props;
        const previewStyle = {
            height: height,
            // width: 300,
            // height: '100%',
            width: '100%',
            top: '0px',
            left: 0,
            // display: 'block',
            display: 'inline-block',
            position: 'absolute',
            overflow: 'hidden',
            objectFit: 'cover'
        }

        // const previewStyle = {
        //     height: 240,
        //     width: 320,
        //   }

        return (
            <div style={styles.paper}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={6}>
                        <div style={styles.photoContainer}>
                            <div style={{ ...styles.textContainer, height: height / 4.5 }}>
                                <Typography style={{ ...styles.infoText, marginTop: 40, paddingBottom: 10 }}>
                                    Scan the QR code shown on the user's MedFilo Profile.
                                </Typography>
                            </div>
                            <div style={styles.cameraRectangle}>
                            </div>
                            <div style={{ height: height / 4.5 }}></div>
                            {/* <div style={styles.buttonContainer}>
                                <div style={{ height: 50, width: 300, backgroundColor: '#0098cd'}}>
                                    <p>{this.state.qrcodeValue}</p>
                                </div>
                            </div> */}
                        </div>
                        <div>
                            <QrReader
                                ref="qrReader1"
                                delay={this.state.delay}
                                constraints={{
                                    audio: true,
                                    video: { facingMode: "environment" }
                                }} 
                                style={previewStyle}
                                onError={this.handleError}
                                onScan={this.handleScan}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        user_profile: state.userReducer.user_profile,
    };
};

QRScan.propTypes = {
    // styles: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(QRScan));

// export default QrContainer;