import React, { Component } from "react";
import { Typography,Grid } from "@mui/material"
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { fetchUnverifiedRecord, fetchUserTestInformation, fetchverifyRecord } from "../../actions/testAction";
import { fetchUserProfile } from "../../actions/userAction"
import { FaChevronCircleRight } from "react-icons/fa";
import { withRouter } from "../../withRouter";
import { THEMECOLOR } from "../../helpers/Constants";

const styles = {
    paper: {
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        backgroundColor: THEMECOLOR.SECONDARY,
        backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
        minHeight: '100vh',
        padding: 20
    },
    container: {
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        backgroundColor: "#FFF",
        margin: 10,
        borderRadius: 10,
        padding: 10,
        minHeight: "70vh"
    },

    itemContainer: {
        boxShadow: "2px 2px 2px 2px rgb(102, 188, 70, 0.2)",
        backgroundColor: "#ffffff",
        borderRadius: 10,
        marginTop: 8,
        flexDirection: "row",
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: THEMECOLOR.PRIMARY,
        display: "flex",
        padding: 10,
        alignItems: 'center',
        justifyContent: "center",
    },
    titleText: {
        fontSize: 22,
        fontWeight: "bold",
        color: "#fff",
        padding: 10,
        textAlign: "center"
    },

    emptyText: {
        fontSize: 14,
        color: "#000",
        textAlign: "center"
    },
    queueName: {
        fontSize: 18,
        color: '#000',
        fontWeight: '700'
    },
    emailText: {
        fontSize: 12,
        color: '#000',
        marginTop: 5
    },
    lineStyle: {
        height: 2,
        width: 150,
        background: "#727272",
    },
    divStyle: {
        display: 'flex',
        paddingTop: 5,
        paddingBottom: 10,
        justifyContent: 'center'
    },

};

class TestVerifier extends Component {
    constructor() {
        super();
        this.state = {
            queue_users: null
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.queue_users !== state.queue_users) {
            return {
                queue_users: props.queue_users
            }
        }
        return null;
    }
    componentDidMount() {
        this.props.dispatch(fetchverifyRecord())
        this.props.dispatch(fetchUserProfile());
    }

    navigateUserTest = (param) => {
        this.props.dispatch(fetchUnverifiedRecord(this, param.id))
        this.props.dispatch(fetchUserTestInformation(param.hashedUserId))
        this.props.navigate("/test-history", { state: { 'queue': 'queueRecords', 'id': param.id }})
    }

    render() {
        const {  queue_users } = this.props;
        return (
            <div style={styles.paper}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <div style={styles.container}>
                            <Typography style={{ fontSize: 20, color: '#000000', textAlign: 'center' }} >My Queue</Typography>
                            <div style={styles.divStyle}>
                                <div style={styles.lineStyle} />
                            </div>
                            {queue_users !== null ?
                                queue_users.map(item =>
                                    <div style={styles.itemContainer} onClick={() => this.navigateUserTest(item)}>
                                        <div style={{padding: 10, flex: 0.8, alignItems: 'flex-start',justifyContent: "center" }}>
                                            <Typography style={styles.queueName}>{item.firstName + " " + item.lastName} </Typography>
                                            <Typography style={styles.emailText}>{item.email} </Typography>
                                        </div>
                                        <div style={{ borderLeft: '4px solid ', color:THEMECOLOR.PRIMARY , height: '80px', flex: 0.05}} />
                                        <div style={{ alignItems: 'center', justifyContent: 'center', flex: 0.25, }}>
                                            <FaChevronCircleRight style={{ fontSize: 30, color: THEMECOLOR.PRIMARY, padding: 20 }} />
                                        </div>
                                    </div>)
                                :
                                <div style={{ display: "flex", alignItems: "center", height: "70vh", justifyContent: "center" }} >
                                    <Typography style={styles.emptyText} >There are no alloted records</Typography>
                                </div>
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        queue_users: state.testReducer.queue_users,
        user_profile: state.userReducer.user_profile,
    };
};

TestVerifier.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(TestVerifier));