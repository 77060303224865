import React, { Component } from "react";
import { THEMECOLOR } from "../../helpers/Constants";
import { Button, Grid, Typography, } from "@mui/material";
import medFiloLogoNew from '../../assets/images/medFiloLogoNew.svg';
import appStoreIcon from '../../assets/images/Appstoreicon.svg';
import googlePlayIcon from '../../assets/images/google-play-badge-logo.svg'

const styles = {
    background: {
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: "center",
    },
    titleHead: {
        fontSize: 22,
        fontWeight: "bold",
        textAlign: "center",
        marginTop: 20,
        marginBottom: 10,
        color: '#ffffff'
    },
}

class MobileSignUp extends Component {
    // constructor() {
    //     super();
    // }

    render() {
        return (
            <div style={styles.background}>
                <Grid container >
                    {/* <Grid item xs={12}>
                        <Typography style={styles.titleHead}>SignUp</Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Grid container justifyContent={'center'} alignItems={'center'} >
                            <div style={{backgroundColor:'#fff', borderRadius:50}}>
                            <img
                                alt="medFiloLogoNew"
                                src={medFiloLogoNew}
                                height={250}
                                width={250}
                            />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={styles.titleHead}>
                        <Typography >To proceed further,</Typography>
                        <Typography>Please signup through our mobile application and continue</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={styles.titleHead}>
                            We are now available on
                        </Typography>
                        <Grid container justifyContent={'center'} alignItems={'center'}>
                            <Button >
                                <a target='_blank' rel="noopener noreferrer"  href={"https://apps.apple.com/us/app/medfilo/id6482108250"}>
                                    <img
                                        alt="appStoreIcon"
                                        src={appStoreIcon}
                                        height={100}
                                        width={100}
                                    />
                                </a>
                            </Button>
                            <Button>
                                <a target='_blank' rel="noopener noreferrer"  href={"https://play.google.com/store/apps/details?id=com.medfilo"}>
                                    <img
                                        alt="googlePlayIcon"
                                        src={googlePlayIcon}
                                        height={100}
                                        width={100}
                                    />
                                </a>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default MobileSignUp;