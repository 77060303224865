import React, { Component } from "react";
import { Typography, Grid, Button } from "@mui/material";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import moment from 'moment';
import { END_POINT, THEMECOLOR } from "../../helpers/Constants";
import { withRouter } from "../../withRouter";

const styles = {
    paper: {
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        backgroundColor: THEMECOLOR.PRIMARY,
        backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
        height: '100vh',
        padding: 20,
        paddingBottom: window.screen.width >= 640 ? 80 : 20
    },
    container: {
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        backgroundColor: "#FFF",
        margin: 10,
        borderRadius: 10,
    },
    imgstyle: {
        width: "100%",
        height: "auto",
        borderRadius: 10
    },
    button: {
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
        marginTop: 5,
        padding: 7,
        fontSize: 15,
        backgroundColor: THEMECOLOR.PRIMARY,
        color: "#fff",
        fontWeight: "bold",
        borderRadius: 5,
        '&:hover': {
            backgroundColor: THEMECOLOR.PRIMARY,
        },
    },
    titleText: {
        fontSize: 17,
        fontWeight: "bold",
        color: "#fff",
        textAlign: "center"
    }
};

class TestImageVerification extends Component {
    
    constructor() {
        super();
        this.state = {

        }
    }

    nextScreen = () => {
        if (this.props.verification_test.testType.isVaccine === true) {
            this.props.navigate("/vaccination")
        } else {
            this.props.navigate("/enter-test-result")
        }
    }

    render() {
        const {
            // styles,
            verification_test } = this.props;
        let imageUrl = verification_test !== null ? `${END_POINT}image/${verification_test.testType.isVaccine === true ? "v" : "t"}/${verification_test.storageKey}` : null
        return (
            <div style={styles.paper}>
                <Grid container justifyContent="center">
                    {verification_test !== null &&
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography style={styles.titleText}>Date of Upload</Typography>
                            <Typography style={{ ...styles.titleText, fontSize: 15 }}>{`${moment(verification_test.created).format("YYYY-MM-DD")} at ${moment(verification_test.created).format("HH:mm")}`}</Typography>
                            <div style={styles.container}>
                                <img src={imageUrl} alt="test-img" style={styles.imgstyle} />
                            </div>
                            <Button
                                type="Button"
                                fullWidth
                                variant="contained"
                                onClick={() => this.nextScreen()}
                                style={styles.button}
                            >
                                Continue
                            </Button>
                        </Grid>}
                </Grid>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        verification_test: state.testReducer.verification_test
    };
};

TestImageVerification.propTypes = {
    styles: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(TestImageVerification));