import React, { forwardRef, useState, Fragment, useEffect } from "react";
import { Link as RouterLink ,useLocation} from 'react-router-dom';
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiExpansionPanel from "@mui/material/Accordion";
import MuiExpansionPanelSummary from "@mui/material/AccordionSummary"
import MuiExpansionPanelDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { List, ListItem, Button, colors,Typography, Grid } from "@mui/material";
import theme from "../../../../../../theme"

const styles = {
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 5,
  },
  listbutton: {
    color: colors.blueGrey[800],
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontFamily: "Futura-Book",
    //fontWeight: theme.typography.fontWeightMedium
    '&:hover': {
      color: "#A6FFC7",
      fontFamily: "Futura-Book",
      fontWeight: "bold",
      "& $icon": {
        color: "#A6FFC7",
      },
      backgroundColor: "#12bdcf",
      // opacity:0.5
    },

  },
  button: {
    color: theme.palette.secondary.light,
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontFamily: "Futura-Book",
   
    //fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    // color: '#2afb4a',
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: 2
  },
  active: {
    color: "#12bdcf",
    fontFamily: "Futura-Book",
    fontWeight: "bold",
    "& $icon": {
      color: "#12bdcf",
    },
  },
  activeButton: {
    color: "#12bcdf",
    fontFamily: "Futura-Book",
    fontWeight: "bold",
    "& $icon": {
      color: "#12bcdf",
    },
    backgroundColor: "#fff",
  },
};

const CustomRouterLink = forwardRef(({ isActive, isPending, isTransitioning, ...props }, ref) => (

  <div ref={ref} style={{ flexGrow: 1 }}>
     <RouterLink
      {...props}
      
      style={{
        fontSize: 15,
        textTransform:"capitalize",
        fontWeight: isActive ? "bold" : "",
        color: isActive ? "green" : "red",
        viewTransitionName: isTransitioning ? "slide" : "",
      }}
    />
  </div>
));

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props;
  const location = useLocation();

  // console.log("location----->"+JSON.stringify(location))

const ExpansionPanel = styled(MuiExpansionPanel)(({ theme }) => ({
    root: {
      boxShadow: 'none',
      margin: 0,
      '&.Mui-expanded': { // Note the use of '.Mui-expanded' here instead of '&$expanded'
        margin: 0,
      },
    },
  }));


const ExpansionPanelSummary = styled(MuiExpansionPanelSummary)(({ theme }) => ({
    root: {
      backgroundColor: '#8fa3ad',
      margin: 0
    },
    content: {
      margin: 0,
    },
    expanded: {
      backgroundColor: "#12bcdf",
    },
}));

const ExpansionPanelDetails = styled(MuiExpansionPanelDetails)(({ theme }) => ({
    root: {
      width: '100%',
      padding: 4,
    },
  }));

  let category = [];
  pages.map((e) => e.hasOwnProperty('category') && category.push(e.category));

  const myOrderedArray = Array.from(new Set(category.map(a => a.id)))
    .map(id => {
      return category.find(a => a.id === id)
    })
  let result = [];

  myOrderedArray.map((i) => {
    let filteredByCategory = pages.filter((y) => y.category.name === i.name);
    let sortedCategory = filteredByCategory.sort(function (a, b) {
      var nameA = a.order;
      var nameB = b.order;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    let dataObj = {};
    dataObj.category = i;
    dataObj.pages = sortedCategory;
    return result.push(dataObj);
  });

  let currentIndex = 0;
  for (currentIndex = 0; currentIndex < result.length; currentIndex++) {
    let r = result[currentIndex].pages.findIndex(e => e.href === window.location.pathname)
    if (r > -1) {
      break;
    }
  }
  // console.log("india " + currentIndex); // 3
  const [expanded, setExpanded] = useState(currentIndex);
    useEffect(() => {
    setExpanded(currentIndex)
  }, [currentIndex]);


  // return (

  //   <List {...rest} className={clsx(classes.root, className)} style={{ backgroundColor: "#8fa3ad" }}>    
  //     {pages.map((page, index) => (
  //       <ListItem className={classes.item} disableGutters key={index}>          
  //          <Button
  //           activeclassname={classes.active}
  //           className={classes.button}
  //           component={CustomRouterLink}
  //           to={page.href}
  //         >
  //           <div className={classes.icon}>{page.icon}</div>
  //           {page.title}
  //         </Button>
  //       </ListItem>
  //     ))}
  //   </List>
  // );

  return (
    <Fragment>
      {category.length === 0 ?
        <Fragment>
          <List {...rest} style={styles.root}>
            {pages.map((page, index) => (
              <ListItem style={styles.item} key={index}>
                <Button
                  activeclassname={styles.active}
                  style={styles.button}
                  component={CustomRouterLink}
                  to={page.href}
                 >
                  <div style={styles.icon}>{page.icon}</div>
                  <Typography > {page.title}</Typography>
                 
                </Button>
               
              </ListItem>
            ))}
          </List>
        </Fragment>
        : <Fragment>
          {
            result.map((page, index) => (
              page.pages.length === 1 ?
                <ListItem style={styles.item} key={index}>
                   <Grid style={{ display:"flex", width:300, borderRadius:5, flexDirection:"row", backgroundColor: location.pathname === page.pages[0].href && "#1d8e3e" }}>
                  <Button
                    activeclassname={styles.active}
                    style={styles.button}
                    component={CustomRouterLink}
                    to={page.pages[0].href}
                    onClick={() => {
                      setExpanded(-1);
                    }}
                  >
                    <div style={{ color: location.pathname === page.pages[0].href ? '#ffff' : theme.palette.primary.main,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: 2}}>{page.pages[0].icon}</div> 
                    <Typography style={{ color: location.pathname === page.pages[0].href ? '#ffff' : theme.palette.secondary.light}} > {page.pages[0].title}</Typography>
                    
                  </Button>
                  </Grid>
                </ListItem>
                :

                <ExpansionPanel
                  expanded={expanded === index}
                  onChange={() => {
                    setExpanded(expanded !== index ? index : false);
                  }}
                  key={index}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{ color: theme.palette.secondary.light }} />} >
                    <div 
                     style={styles.button}
                    >
                     <Typography style={{ fontSize:17 }}> {page.category.name}</Typography>
                    
                    </div>
                  </ExpansionPanelSummary>
                  {page.pages.map((page, pageIndex) => (
                   <ExpansionPanelDetails key={pageIndex}>
                      <Grid style={{ display:"flex", borderRadius:6, flexDirection:"row", backgroundColor: location.pathname === page.href && "#1d8e3e"}}>
                     <Button 
                        activeclassname={styles.active}
                        style={styles.button}
                        component={CustomRouterLink}
                        to={page.href}
                        >
                        
                        <div style={{  color: location.pathname === page.href ? '#ffff' : theme.palette.primary.main,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: 2}}>{page.icon}</div>
                        <Typography style={{ color: location.pathname === page.href ? '#ffff' : theme.palette.secondary.light}}> {page.title}</Typography>
                      </Button>
                      </Grid>
                    </ExpansionPanelDetails>
                  ))}
                </ExpansionPanel>
            ))
          }
        </Fragment>
      }
    </Fragment>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
