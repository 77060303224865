import React, { Component } from "react";
import { DialogTitle, DialogContent, DialogActions, Dialog, Button, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import CheckIcon from '@mui/icons-material/Check';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { THEMECOLOR } from "../../helpers/Constants";

const styles = {
background: {
        display: 'flex',
        backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
        width: '100%',
        alignItems: 'center',
        justifyContent: "center",
        flexDirection: 'column',
        marginTop: 0
    },
    paper: {
        marginTop: 1,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: "center",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: '#ffffff'
    },
    titleHead: {
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "center",
        marginTop: 20,
        marginBottom: 10,
        color: '#ffffff'
    },
    identityContainer: {
        display: 'flex',
        position: "relative",
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        backgroundColor: "#D7F5E6",
        borderRadius: '50%',
        padding: 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 8 },
        shadowOpacity: 0.5,
        shadowRadius: 10,
        elevation: 50
    },
    welcomeText: {
        color: "#000000",
        fontSize: 15
    },
    headerText: {
        color: theme.palette.secondary.light,
        fontSize: 15,
        fontWeight: "700"
    },
    descriptionText: {
        color: "#000000",
        fontSize: 15
    },
    topView: {
        flexDirection: 'column',
        paddingTop: '15px',
        paddingBottom: '15px',
        textAlign: 'center'
    },
    secondaryContainer: {
        alignItems: "center",
        justifyContent: "center",
        paddingTop: '20px',
        width: '320px',
    },
    textStyle: {
        color: "#6D7973",
        fontSize: 15,
        textAlign: "center"
    },
    choiceLineStyle: {
        backgroundColor: "#D7F5E6",
        height: 1,
        width: 100,
        margin: 15,
    },
    lineStyle: {
        backgroundColor: "#D7F5E6",
        height: 1,
        width: '320px',
        margin: 15,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    },
    choiceContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 15,
        width: '320px'
    },
    contentView: {
        marginLeft: 20,
    },
    choiceText: {
        fontSize: 15,
        color: theme.palette.secondary.light,
        fontWeight: "bold"
    },
    choiceTextStyle: {
        fontSize: 15,
        color: theme.palette.secondary.light
    },
    learnMoreText: {
        marginTop: 5,
        fontSize: 15,
        marginLeft: -8,
        color: theme.palette.primary.main,
        fontWeight: "bold",
        textTransform: 'uppercase'
    },
    continueText: {
        fontSize: 15,
        color: "#ffffff",
        fontWeight: "bold",
        textTransform: "uppercase"
    },
    button: {
        marginTop: 10,
        marginBottom: 10,
        padding: 12,
        borderRadius: 5,
        fontSize: 15,
        fontWeight: "bold",
        width: "85%",
        backgroundColor: theme.palette.primary.main
    },
    centerModalSecondText: {
        fontSize: 14,
        color: "#7B8581",
        height: "60%",
        textAlign: "center"
    },
    dismissButton: {
        margin: 10,
        width: 150,
        height: 40,
        justifyContent: "center",
        borderRadius: 5,
        borderColor: "#1CBFD4",
        backgroundColor: theme.palette.primary.main,
        elevation: 10,
        alignSelf: 'center',
        textTransform: 'uppercase'
    },
    dismissText: {
        color: "white",
        textAlign: "center",
        fontSize: 12,
        fontWeight: "bold",
        textTransform: 'uppercase'
    },
    dialogTitleText: {
        color: theme.palette.secondary.light,
        fontSize: 25,
        fontWeight: "bold",
        textAlign: "center",
        textTransform: 'capitalize'
    },
    dialogAction: {
        alignSelf: 'center',
        justifyContent: 'center'
    }
};

class RegisterIdentityVerified extends Component {
    constructor() {
        super();
        this.state = {
            healthcareDialog: false,
            administratorDialog: false
        }
    }

    render() {

        return (
            <div>
                <div style={styles.background}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography style={styles.titleHead}>Register</Typography>
                            <div style={styles.paper}>
                                <div style={{ flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid container justifyContent="center">
                                        <Grid item style={styles.identityContainer}>
                                            <CheckIcon sx={{ fontSize: 40 }} style={{ color: theme.palette.primary.main, alignSelf: 'center' }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="center">
                                        <Grid item style={styles.topView}>
                                            <Typography style={styles.welcomeText}>Welcome to your free registration as an</Typography>
                                            <Typography style={styles.headerText}>“ <span style={{ ...styles.headerText, color: theme.palette.primary.main }}>User</span> ”</Typography>
                                            <Typography style={styles.descriptionText}>MedFilo will help you to own, share and</Typography>
                                            <Typography style={styles.welcomeText}>keep control of your health information.</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="center" style={styles.lineStyle} />
                                    <Grid container justifyContent="center">
                                        <Grid item style={styles.secondaryContainer}>
                                            <Typography style={styles.textStyle}>
                                                After registered as an User,
                                                you can easily choose the professional user type that best describes you by following the process outlined in your  'Account' tab.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent="center" style={styles.lineStyle} />
                                    <Grid container justifyContent="center">
                                        <div style={styles.choiceContainer}>
                                            <AddCircleOutlineIcon style={{ color: theme.palette.primary.main, width: 35, height: 35, alignSelf: 'flex-start' }} />
                                            <div style={styles.contentView}>
                                                <Typography style={styles.choiceText}>Medical Service Provider</Typography>
                                                <Typography style={styles.choiceTextStyle}>
                                                    Check patients’ current health status &<br />
                                                    validate their health records.
                                                </Typography>
                                                <Button color="primary" onClick={() => this.setState({ healthcareDialog: true })}>
                                                    <Typography style={styles.learnMoreText}>Learn More</Typography>
                                                </Button>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid container justifyContent="center" style={styles.lineStyle} />
                                    <Grid container justifyContent="center">
                                        <div style={styles.choiceContainer}>
                                            <AddCircleOutlineIcon style={{ color: theme.palette.primary.main, width: 35, height: 35, alignSelf: 'flex-start' }} />
                                            <div style={styles.contentView}>
                                                <Typography style={styles.choiceText}>Verifier</Typography>
                                                <Typography style={styles.choiceTextStyle}>
                                                    Check patients's current health status &
                                                    grant them access.
                                                </Typography>
                                                <Button color="primary" onClick={() => this.setState({ administratorDialog: true })}>
                                                    <Typography style={styles.learnMoreText}>Learn More</Typography>
                                                </Button>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid container justifyContent="center">
                                        <Button
                                            fullWidth
                                            type="Button"
                                            variant="contained"
                                            color="primary"
                                            style={styles.button}
                                            onClick={() => this.props.navigate("/mobile-signup")}
                                        >
                                            <Typography style={styles.continueText}>Continue</Typography>
                                        </Button>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <Dialog
                    open={this.state.healthcareDialog}
                    fullWidth={true}
                    maxWidth={'sm'}
                    PaperProps={{ sx: { borderRadius: "8px" } }}
                    onClose={() => this.setState({ healthcareDialog: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Typography style={styles.dialogTitleText}>Medical Service Provider</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography style={styles.centerModalSecondText}>With MedFilo, you can validate your patients' test results and
                            vaccination records, resulting in improved access to important healthcare
                            information and meaningful healthcare analytics.
                        </Typography>
                    </DialogContent>
                    <DialogActions style={styles.dialogAction}>
                        <Button
                            type="Button"
                            fullWidth
                            variant="contained"
                            style={styles.dismissButton}
                            onClick={() => this.setState({ healthcareDialog: false })}
                        >
                            <Typography style={styles.dismissText} >Dismiss</Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.administratorDialog}
                    fullWidth={true}
                    maxWidth={'sm'}
                    onClose={() => this.setState({ administratorDialog: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                >
                    <DialogTitle id="alert-dialog-title">
                        <Typography style={styles.dialogTitleText}>Verifier</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography style={styles.centerModalSecondText}>
                            With MedFilo, Enterprises can authenticate visitors' up-to-date health
                            information to grant access to their facilities, helping to protect guests,
                            employees, and associates. By leveraging health data, enterprises can make
                            informed decisions on both admittance and workflow.
                        </Typography>
                    </DialogContent>
                    <DialogActions style={styles.dialogAction}>
                        <Button
                            type="Button"
                            fullWidth
                            variant="contained"
                            style={styles.dismissButton}
                            onClick={() => this.setState({ administratorDialog: false })}
                        >
                            <Typography style={styles.dismissText} >Dismiss</Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {

    };
};

RegisterIdentityVerified.propTypes = {

};

export default withRouter(connect(mapStateToProps)(RegisterIdentityVerified));