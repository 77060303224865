import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Grid, Typography, Button } from "@mui/material";
import Webcam from "react-webcam";
import { SELECTED_NEW_PROFESSIONAL_IMAGE } from "../../actions/actionTypes";
import { isMobile } from "../../helpers/Constants";
import Gallery from "../../assets/images/gallery.svg";
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { THEMECOLOR } from "../../helpers/Constants";

const { innerHeight: height } = window;

const styles = {
    paper: {
        //boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        // backgroundColor: "#20478E",
        // backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)",
        backgroundColor: 'rgba(1,1,1,0.6)',
    },
    button: {
        marginTop: 10,
        marginBottom: 10,
        padding: 15,
        borderRadius: 10,
        fontSize: 15,
        fontWeight: "bold",
        backgroundColor: '#5186E7'
    },
    mainDiv: {
        height: height,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'hidden',
        overflowX: 'hidden'
    },
    photoContainer: {
        height: height,
        // width: '100%',
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up("sm")]: {
            justifyContent: 'center',
        },
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',

        position: 'absolute'
    },
    infoText: {
        fontSize: 18,
        paddingTop: 10,
        color: "#fff",
        textAlign: "center",
    },
    textContainer: {
        backgroundColor: 'rgba(1,1,1,0.6)',
        position: 'relative',
        textAlign: 'center',
        zIndex: 100
    },
    cameraRectangle: {
        height: height / 1.7,
        // width: width / 1.3,
        border: '4px solid #0098cd',
        // display: 'inline-block',
        // position: 'relative',
        //zIndex: 101
    },
    buttonContainer: {
        display: 'inline-block',
        position: 'relative',
        zIndex: 100
    },
    roundButton: {
        margin: 20,
        width: height / 15,
    },
    roundButtonCircle: {
        width: '100%',
        height: 0,
        paddingBottom: '100%',
        borderRadius: '50%',
        border: '10px solid #cfdcec',
        overflow: 'hidden',
        background: '#4679BD',
        boxShadow: '0 0 3px gray'
    },
    deskTopPhotoContainer: {
        height: '100vh',
        backgroundColor: '#20478E',
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up("sm")]: {
            justifyContent: 'center',
        },
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute'
    },
    desktopTextContainer: {
        display: 'inline-block',
        position: 'relative',
        textAlign: 'center',
        padding: 10,
        zIndex: 100
    }
};

class OpenCamera extends Component {
    // static contextTypes = {
    //     router: PropTypes.object,
    // }
    constructor() {
        super();
        this.webcamRef = React.createRef();
        this.state = {
            openTestList: false,
            testtypes: [],
            parentWidth: 0
        }
        this.myInput = React.createRef()
        this.onImageChange = this.onImageChange.bind(this);
    }


    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            //console.log("*******************" + URL.createObjectURL(img))
            this.props.dispatch({ type: SELECTED_NEW_PROFESSIONAL_IMAGE, data: URL.createObjectURL(img) })
            this.props.navigate("/professional-information")
        }
    };

    base64toBlob = (dataURI, type) => {
        // convert base64 to raw binary data held in a string
        var byteString = atob(dataURI.split(',')[1]);
        // separate out the mime component
        // var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var bb = new Blob([ab], { type: type });
        return bb;
    }

    componentDidMount() {
        //console.log(this.myInput.current.offsetWidth)
        window.addEventListener('resize', this.handleResize());
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize());
    }

    handleResize = (event) => {
        // console.log(this.myInput.current.offsetWidth)
        this.setState({ parentWidth: this.myInput.current.offsetWidth })
    }

    capture = async () => {
        const imageSrc = await this.webcamRef.current.getScreenshot();
        var filepath = await this.base64toBlob(imageSrc, "image/jpeg");
        this.setState({ imgSrc: URL.createObjectURL(filepath) });
        this.props.dispatch({ type: SELECTED_NEW_PROFESSIONAL_IMAGE, data: URL.createObjectURL(filepath) })
        this.props.navigate("/professional-information")
    }

    render() {
        // const { styles } = this.props;
        const videoConstraints = {
            height: 720,
            width: 500,
            facingMode: { exact: "environment" }
        };
        let isMobileView = isMobile();
        return (
            <div style={styles.paper}>
                <Grid container justifyContent={"center"} >
                    {isMobileView === true ?
                        <Grid item xs={12} ref={this.myInput}>
                            <div style={{
                                height: height,
                                // width: '100%',
                                [theme.breakpoints.down("sm")]: {
                                    justifyContent: 'flex-start',
                                },
                                [theme.breakpoints.up("sm")]: {
                                    justifyContent: 'center',
                                },
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',

                                position: 'absolute', width: this.state.parentWidth
                            }}>
                                <div style={{
                                    width: "100vw", height: height / 4.25, backgroundColor: 'rgba(1,1,1,0.6)',
                                    position: 'relative',
                                    textAlign: 'center',
                                    zIndex: 100
                                }} >
                                    <Typography style={{
                                        marginTop: 40, padding: 10, fontSize: 18,
                                        paddingTop: 10,
                                        color: "#fff",
                                        textAlign: "center",
                                    }}>
                                        {this.props.selected_user_type === "MSP" ? "Take a picture of your  Medical License" : "Take a picture of your Professional ID Card"}
                                    </Typography>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', width: "100vw", }}>
                                    <div style={{ backgroundColor: 'rgba(1,1,1,0.6)', width: "10vw" }}>
                                    </div>
                                    <div style={{
                                        width: "80vw", height: height / 1.7,
                                        border: '4px solid #0098cd',
                                    }}>
                                    </div>
                                    <div style={{ backgroundColor: 'rgba(1,1,1,0.6)', width: "10vw", }}>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: "100%", backgroundColor: 'rgba(1,1,1,0.6)', height: height / 4.25 }} >
                                    <div style={{
                                        paddingLeft: 50, display: 'inline-block',
                                        position: 'relative',
                                        zIndex: 100
                                    }} onClick={() => this.capture()}>
                                        <div style={styles.roundButton}>
                                            <div style={styles.roundButtonCircle}>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        paddingLeft: 50, display: 'inline-block',
                                        position: 'relative',
                                        zIndex: 100
                                    }} >
                                        <Button
                                            // variant="contained"
                                            component="label"
                                        >
                                            <img src={Gallery} alt="Gallery" style={{ width: 50 }} />
                                            <input
                                                type="file"
                                                hidden
                                                onChange={this.onImageChange}
                                                //onChange={(event) => this.onImageChange(event)}
                                                accept=".jpg,.jpeg,.png"
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div style={styles.mainDiv}>
                                <Webcam
                                    height={720}
                                    width={1280}
                                    audio={false}
                                    ref={this.webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                />
                            </div>
                        </Grid>
                        :
                        <Grid item xs={12} ref={this.myInput}>
                            <div style={{
                                width: this.state.parentWidth,
                                height: '100vh',
                                // backgroundColor: '#35A773',
                                backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY},${THEMECOLOR.PRIMARY})`,
                                [theme.breakpoints.down("sm")]: {
                                    justifyContent: 'flex-start',
                                },
                                [theme.breakpoints.up("sm")]: {
                                    justifyContent: 'center',
                                },
                                display: 'flex',
                                justifyContent: "center",
                                alignItems: 'center',
                                flexDirection: 'column',
                                position: 'absolute'
                            }}>
                                <div style={styles.desktopTextContainer}>
                                    <Typography style={styles.infoText}>
                                        {`Upload an image of your ${this.props.selected_user_type === "MSP" ? 'Medical License' : 'Professional ID Card'}`}
                                    </Typography>
                                </div>
                                <div>
                                    <input type="file" name="myImage" onChange={this.onImageChange} accept=".jpg,.jpeg,.png" />
                                </div>
                            </div>
                        </Grid>
                    }
                </Grid>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        user_type: state.sessionReducer.user_type,
        selected_user_type: state.userReducer.selected_user_type,
    };
};

OpenCamera.propTypes = {
    dispatch: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(OpenCamera));