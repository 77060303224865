import { FETCH_APP_VERSIONS } from "../actions/actionTypes"

const initialstate = {
    appVersions: []
}

export default function appVersionReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_APP_VERSIONS:
            return {
                ...state,
                appVersions: action.data
            }

        default:
            return state;
    }
}