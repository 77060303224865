import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Table, TableBody, TableCell, TableHead, TableRow,
    Typography, Grid, Button, Dialog, DialogActions,
    DialogContent, DialogTitle, TextField,
    FormControl, FormControlLabel, Radio, RadioGroup,
    FormHelperText, Toolbar, InputAdornment, MenuItem, TablePagination, Autocomplete,
    IconButton, CircularProgress
} from '@mui/material';
import { fetchOrganization, addOrganization, updateOrganization } from '../../actions/organizationAction';
import { fetchPartner } from '../../actions/partnerAction';
import { searchUser } from "../../actions/memberAction";
import TablePaginationActions from "../Dashboard/TablePaginationActions";
import { USA_STATES } from '../../helpers/Constants'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material//VisibilityOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { withRouter } from '../../withRouter';
import theme from '../../theme';
import { headerFont } from '../../helpers/Constants';

const styles = {
    textStyle: {
        fontFamily: "Futura-Heavy",
        color: "#37474f",
        //fontFamily: "bold",
        fontSize: 20,
        //padding: 10
    },
    addButton: {
        // backgroundColor: "#20478e",
        color: "white",
        fontFamily: "unicode.futurab",
        borderRadius: "10",

    },
    cancelButton: {
        backgroundColor: "#8fa3ad",
        color: "white",
        fontFamily: "unicode.futurab",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(143, 163, 173, 0.8)',
        }
    },
    editButton: {
        backgroundColor: "#8fa3ad",
        color: "white",
        fontFamily: "unicode.futurab",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(143, 163, 173, 0.8)',
        }
    },
    fontCellHeader: {
        fontFamily: "Futura-Book",
        color: "#fff",
        fontWeight: 900,
        padding: '14px 20px 14px 0px',

    },
    fontTableCellBody: {
        fontFamily: "Futura-Medium-bt",
        color: "#37474f",
        padding: '14px 20px 14px 0px',
    },
    inputStyle: {
        fontFamily: "Futura-Book",
        fontSize: 14
    },
    labelStyle: {
        fontFamily: "Futura-Book",
        fontSize: 14
    },
    inputStyleForSearchText: {
        fontSize: "15px !important",
        padding: "5px !important",
        width: 250,
    },
    inputRoot: {
        borderRadius: "0px !important"
    },
    inputLabel: {
        color: "#4B86E3"
    },
    dialogPaper: {
        backgroundColor: "rgba(0,0,0,0)",
        boxShadow: "none",
        overflow: "hidden"
    }

}

// const CssTextField = withStyles({
//     root: {
//         '& label.Mui-focused': {
//             color: '#4B86E3',
//         },
//         '& .MuiInput-underline:after': {
//             borderBottomColor: '#4B86E3',
//         },
//         '& .MuiOutlinedInput-root': {
//             '& fieldset': {
//                 borderColor: '#4B86E3',
//             },
//             '&:hover fieldset': {
//                 borderColor: '#4B86E3',
//             },
//             '&.Mui-focused fieldset': {
//                 borderColor: '#4B86E3',
//             },
//         },
//     },
// })(TextField);



class Organization extends Component {

    constructor(props) {
        super(props)
        this.state = {
            secureEntry: true,
            organization: [],
            dialogOpen: false,
            id: 0,
            name: "",
            name_error: false,
            email: "",
            email_error: false,
            invalid_email_error: false,
            phone_number: "",
            phone_number_error: false,
            invalid_phone_number_error: false,
            contact_person_name: "",
            contact_person_name_error: false,
            address_line1: "",
            address_line1_error: false,
            address_line2: "",
            address_line2_error: false,
            city: "",
            city_error: false,
            selectedState: "",
            selectedState_error: false,
            inputValue: '',
            country: "United States of America",
            country_error: false,
            zipcode: "",
            zipcode_error: false,
            invalid_zipcode_error: false,
            status: "ACTIVE",
            status_error: false,
            organization_type: "",
            organization_type_error: false,
            mode: "",
            search_phone: "",
            invalid_search_phone_error: false,
            search_phone_error: false,
            primary_contact_first_name: "",
            primary_contact_first_name_error: false,
            primary_contact_last_name: "",
            primary_contact_last_name_error: false,
            primary_contact_email: "",
            primary_contact_email_error: false,
            invalid_primary_contact_email_error: false,
            primary_contact_country_code: "+1",
            partner_ref: "NONE",
            search_text: "",
            rowsPerPage: 10,
            page: 0,
            baseUrl: "",
            userName: "",
            password: "",
            isLoading: false,
            sortBy: "NAME_ASC"
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.organization !== state.organization) {
            return {
                organization: props.organization
            }
        }
        return null;
    }

    componentDidMount() {
        this.props.dispatch(fetchOrganization())
        this.props.dispatch(fetchPartner())
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0, rowsPerPage: parseInt(event.target.value, 10)
        });
    }

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    handleClick = (item, mode) => {
        if (mode === "ADD") {
            this.setState({ mode: mode, dialogOpen: true })
        } else {
            this.setState({
                id: item.id,
                dialogOpen: true,
                partner_ref: item.partnerRef !== null ? item.partnerRef : "NONE",
                mode: mode,
                baseUrl: item.baseUrl,
                userName: item.userName,
                password: item.password,
                name: item.name,
                name_error: false,
                organization_type: item.organizationType,
                organization_type_error: false,
                email: item.email,
                email_error: false,
                phone_number: item.phoneNumber,
                phone_number_error: false,
                invalid_phone_number_error: false,
                contact_person_name: item.primaryContactName,
                contact_person_name_error: false,
                address_line1: item.addressLine1,
                address_line1_error: false,
                address_line2: item.addressLine2,
                address_line2_error: false,
                city: item.city,
                city_error: false,
                selectedState: item.state,
                selectedState_error: false,
                country: item.country,
                zipcode: item.zipcode,
                zipcode_error: false,
                invalid_zipcode_error: false,
                country_error: false,
                status: item.status,
                status_error: false,
                invalid_email_error: false,
                primary_contact_first_name: "",
                primary_contact_first_name_error: false,
                primary_contact_last_name: "",
                primary_contact_last_name_error: false,
                primary_contact_email: "",
                primary_contact_email_error: false,
                invalid_primary_contact_email_error: false,
                search_phone: "",
                invalid_search_phone_error: false,
                search_phone_error: false,
                primary_contact_country_code: "+1",

            })
        }
    }

    handleClose = () => {
        this.setState({
            dialogOpen: false,
            id: null,
            baseUrl: "",
            userName: "",
            password: "",
            name: "",
            name_error: false,
            email: "",
            email_error: false,
            phone_number: "",
            phone_number_error: false,
            invalid_phone_number_error: false,
            contact_person_name: "",
            contact_person_name_error: false,
            address_line1: "",
            address_line1_error: false,
            address_line2: "",
            address_line2_error: false,
            city: "",
            city_error: false,
            selectedState: "",
            selectedState_error: false,
            country: "United States of America",
            country_error: false,
            zipcode: '',
            zipcode_error: false,
            invalid_zipcode_error: false,
            status: "ACTIVE",
            status_error: false,
            organization_type: "",
            organization_type_error: false,
            invalid_email_error: false,
            mode: "",
            primary_contact_first_name: "",
            primary_contact_first_name_error: false,
            primary_contact_last_name: "",
            primary_contact_last_name_error: false,
            primary_contact_email: "",
            primary_contact_email_error: false,
            invalid_primary_contact_email_error: false,
            search_phone: "",
            invalid_search_phone_error: false,
            search_phone_error: false,
            primary_contact_country_code: "+1",
            partner_ref: "NONE",
        })
    }

    checkNumber = (number) => {
        var isNumber = /^-{0,1}\d+$/.test(number)
        return isNumber;
    }

    validateZipCode(code) {
        var re = /^[\d]{5}$/;
        return code === "" ? true : re.test(code);
    }

    handleClickShowPassword = () => {
        this.setState({ secureEntry: !this.state.secureEntry })
    }

    handleSubmit = (mode) => {
        let { id, name, organization_type, email, phone_number, partner_ref, address_line1,
            address_line2, city, country, zipcode, status, primary_contact_first_name,
            primary_contact_last_name, primary_contact_email,
            search_phone, primary_contact_country_code, selectedState, baseUrl, userName, password } = this.state;

        let isError = false;

        if (name === "" || name === null) {
            this.setState({ name_error: true })
            isError = true;
        }

        if (organization_type === "" || organization_type === null) {
            this.setState({ organization_type_error: true })
            isError = true;
        }

        // if (email === "" || email === null) {
        //     this.setState({ email_error: true })
        //     isError = true;
        // }

        if (email !== null && email !== "") {
            if (!this.validateEmail(this.state.email)) {
                this.setState({ invalid_email_error: true })
                isError = true
            }
        }

        // if (phone_number === "" || phone_number === null) {
        //     this.setState({ phone_number_error: true })
        //     isError = true;
        // }

        if (phone_number !== null && phone_number !== "") {
            if (this.checkNumber(phone_number) !== true || phone_number.length !== 10) {
                this.setState({ invalid_phone_number_error: true })
                isError = true
            }
        }

        // if (address_line1 === "" || address_line1 === null) {
        //     this.setState({ address_line1_error: true })
        //     isError = true;
        // }

        // if (city === "" || city === null) {
        //     this.setState({ city_error: true })
        //     isError = true;
        // }

        // if (selectedState === "" || selectedState === null) {
        //     this.setState({ selectedState_error: true })
        //     isError = true;
        // }

        // if (country === "" || country === null) {
        //     this.setState({ country_error: true })
        //     isError = true;
        // }

        // if (zipcode === "" || zipcode === null) {
        //     this.setState({ zipcode_error: true });
        //     isError = true;
        // }

        if (!this.validateZipCode(zipcode)) {
            this.setState({ invalid_zipcode_error: true });
            isError = true;
        }
        if (status === "" || status === null) {
            this.setState({ status_error: true, })
            isError = true;
        }

        if (this.state.mode === "ADD") {
            if (primary_contact_first_name === "" || primary_contact_first_name === null) {
                this.setState({ primary_contact_first_name_error: true, })
                isError = true;
            }

            if (primary_contact_last_name === "" || primary_contact_last_name === null) {
                this.setState({ primary_contact_last_name_error: true, })
                isError = true;
            }

            if (primary_contact_email === "" || primary_contact_email === null) {
                this.setState({ primary_contact_email_error: true, })
                isError = true;
            }

            if (primary_contact_email !== null && primary_contact_email !== "") {
                if (!this.validateEmail(this.state.primary_contact_email)) {
                    this.setState({ invalid_primary_contact_email_error: true })
                    isError = true
                }
            }

            if (search_phone === "" || search_phone === null) {
                this.setState({ search_phone_error: true })
                isError = true;
            } else {
                if (this.checkNumber(search_phone) !== true || search_phone.length !== 10) {
                    this.setState({ invalid_search_phone_error: true })
                    isError = true
                }
            }
        }

        if (isError === false) {
            let organizationObj = {};

            organizationObj.name = name;
            organizationObj.organizationType = organization_type;
            organizationObj.email = email;
            organizationObj.phoneNumber = phone_number;
            organizationObj.partnerRef = partner_ref !== "NONE" ? partner_ref : null;
            organizationObj.addressLine1 = address_line1;
            organizationObj.addressLine2 = address_line2;
            organizationObj.city = city;
            organizationObj.state = selectedState;
            organizationObj.country = country;
            organizationObj.zipcode = zipcode;
            organizationObj.status = status;
            organizationObj.baseUrl = baseUrl;
            organizationObj.userName = userName;
            organizationObj.password = password;

            if (mode === "ADD") {
                organizationObj.primaryContactPhone = search_phone;
                organizationObj.firstName = primary_contact_first_name;
                organizationObj.lastName = primary_contact_last_name;
                organizationObj.primaryContactemail = primary_contact_email;
                organizationObj.countryCode = primary_contact_country_code;
                this.setState({ isLoading: true })
                this.props.dispatch(addOrganization(this, organizationObj))
            } else {
                this.setState({ isLoading: true })
                this.props.dispatch(updateOrganization(this, organizationObj, id))
            }
        }
    }

    searchUser = () => {
        if (this.state.search_phone !== "" && this.state.search_phone !== null) {
            this.props.dispatch(searchUser(this, this.state.search_phone))
        }
    }

    render() {
        // const { styles } = this.props;
        const { page, rowsPerPage } = this.state;
        let organizations = this.state.sortBy === "NAME_ASC" ? this.props.organization.sort((a, b) => a.name.localeCompare(b.name)) : this.props.organization.sort((a, b) => b.name.localeCompare(a.name));
        let result = this.state.search_text !== '' ? organizations.filter(x => (x.name).toString().toLowerCase().includes((this.state.search_text).toString().toLowerCase()) || (x.phoneNumber).toString().includes((this.state.search_text).toString()) || (x.email).toString().toLowerCase().includes((this.state.search_text).toString().toLowerCase())) : organizations

        return (
            <div style={{ paddingLeft: 2, paddingRight: 10, }}>
                <Grid container spacing={0} style={{ marginTop: 10, }}>
                    <Grid item xs={12} sm={12} style={{ marginBottom: 10, marginLeft: 5 }}>
                        <Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
                            <div>
                                <Typography style={{ color: theme.palette.secondary.light, fontWeight: headerFont.fontWeight, fontFamily: headerFont.fontFamily, fontSize: headerFont.fontSize, }}>Organizations</Typography>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[20]}
                                component="div"
                                count={result.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                slotProps={{
                                    select: {
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                labelRowsPerPage={null}
                                labelDisplayedRows={
                                    ({ from, to, count }) => null
                                }
                                ActionsComponent={
                                    TablePaginationActions
                                }
                            />
                            <div style={{ display: "flex", padding: 10 }}>
                                <Button size="small" variant="contained" color="primary" style={{
                                    marginRight: 10, backgroundColor: theme.palette.primary.main,
                                    color: "white",
                                    fontFamily: "unicode.futurab",
                                    borderRadius: "10",
                                }} onClick={() => this.handleClick(null, "ADD")} >
                                    Add Organization
                                </Button>
                            </div>
                            <TextField
                                margin="normal"
                                id="search-text"
                                label="Search"
                                variant='standard'
                                placeholder="Name,Phone or Email"
                                name="mobile-number"
                                autoComplete="off"
                                value={this.state.search_text}
                                onChange={event =>
                                    this.setState({ search_text: event.target.value, page: 0 })
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ styles: { input: styles.inputStyleForSearchText, root: styles.inputRoot, inputlabel: styles.inputLabel } }}
                                autoFocus
                            />
                        </Toolbar>
                    </Grid>
                    <Grid item xs={8} sm={12} style={{ paddingTop: 10 }}>
                        <Table>
                            <TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10 }}>
                                <TableRow >
                                    <TableCell align="left" style={{
                                        fontFamily: "Futura-Book",
                                        color: "#fff",
                                        fontWeight: 900,
                                        padding: '14px 20px 14px 0px', paddingLeft: 20, display: 'flex', alignItems: "center"
                                    }}>
                                        Name
                                        {/* <div style={{ display: "grid", cursor: "pointer" }}> */}
                                        <ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_ASC" })} />
                                        <ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_DSC" })} />
                                        {/* </div> */}
                                    </TableCell>
                                    <TableCell align="center" style={styles.fontCellHeader}>E-mail</TableCell>
                                    <TableCell align="center" style={styles.fontCellHeader}>Phone Number</TableCell>
                                    <TableCell align="center" style={styles.fontCellHeader}>Contact Person</TableCell>
                                    <TableCell align="center" style={styles.fontCellHeader}>Organization Type</TableCell>
                                    <TableCell align="center" style={styles.fontCellHeader}>Status</TableCell>
                                    <TableCell align="center" style={styles.fontCellHeader}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    result.length > 0 ? result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) =>
                                        <TableRow style={{}} key={item.id}>
                                            <TableCell align="left" size="small" style={{
                                                paddingLeft: 5, fontFamily: "Futura-Medium-bt",
                                                color: "#37474f",
                                                padding: '14px 20px 14px 20px',
                                            }}>
                                                <Typography style={{ fontWeight: "bold", fontSize: 15 }}> {item.name}</Typography>
                                                <Typography style={{ fontSize: 12 }}>{((item.addressLine1 !== "" && item.addressLine1 !== null) ? item.addressLine1 + "," : "") + ((item.addressLine2 !== "" && item.addressLine2 !== null) ? item.addressLine2 + "," : "") + ((item.city !== "" && item.city !== null) ? item.city + "," : "") + ((item.state !== "" && item.state !== null) ? item.state + "," : "") + ((item.country !== "" && item.country !== null) ? item.country : "") + ((item.zipcode !== "" && item.zipcode !== null) ? "," + item.zipcode : "")}</Typography>
                                            </TableCell>
                                            <TableCell align="center" style={styles.fontTableCellBody}>{item.email}</TableCell>
                                            <TableCell align="center" style={styles.fontTableCellBody}>{item.phoneNumber}</TableCell>
                                            <TableCell align="center" style={styles.fontTableCellBody}>{item.primaryContactName}</TableCell>
                                            <TableCell align="center" style={styles.fontTableCellBody}>{item.organizationType}</TableCell>
                                            <TableCell align="center" style={styles.fontTableCellBody}>{item.status}</TableCell>
                                            <TableCell align="center" style={styles.fontTableCellBody}>
                                                <Button size="small"
                                                    // style={styles.editButton}
                                                    style={{
                                                        margin: 5, backgroundColor: "#8fa3ad",
                                                        color: "white",
                                                        fontFamily: "unicode.futurab",
                                                    }} onClick={() => this.handleClick(item, "EDIT")}>EDIT</Button>
                                                <Button size="small" style={{ backgroundColor: theme.palette.primary.main, color: "white", margin: 5 }} onClick={() => this.props.navigate(`/view-organization/${item.id}`)}>VIEW</Button>
                                            </TableCell>
                                        </TableRow>)
                                        :
                                        <TableRow >
                                            <Typography style={{ ...styles.fontTableCellBody }}>There are no organizations</Typography>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>

                <Dialog
                    maxWidth={this.state.mode === "ADD" ? "lg" : "sm"}
                    scroll="body"
                    fullWidth
                    open={this.state.dialogOpen} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "1%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{this.state.mode === "ADD" ? "Add Organization" : "Update organization"}</Typography>
                    </DialogTitle>

                    <DialogContent style={{ overflowY: "hidden", padding: 10 }}>
                        <Grid container justify="space-between">
                            <Grid item xs={this.state.mode === "ADD" ? 6 : 12} >
                                <Grid container justify="space-between" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
                                    <Grid item xs={12}>
                                        <Typography style={{ fontFamily: "Futura-Heavy" }}>Organization Details</Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{ paddingRight: 8 }}>
                                        <TextField
                                            id="name"
                                            label="Name"
                                            name="name"
                                            autoComplete="off"
                                            style={styles.margin}
                                            value={this.state.name.charAt(0).toUpperCase() + this.state.name.slice(1)}
                                            onChange={(event) => this.setState({ name: event.target.value, name_error: false })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            fullWidth
                                            error={this.state.name_error === true ? true : false}
                                            helperText={this.state.name_error === true ? "Please enter name" : false}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ paddingRight: 8, marginTop: -2 }}>
                                        <TextField
                                            id="organization_type"
                                            select
                                            label="Organization Type"
                                            name="organization_type"
                                            autoComplete="off"
                                            value={this.state.organization_type}
                                            onChange={(event) => this.setState({ organization_type: event.target.value, organization_type_error: false })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            fullWidth
                                            error={this.state.organization_type_error === true ? true : false}
                                            helperText={this.state.organization_type_error === true ? "Please select the organization type" : false}
                                        >
                                            <MenuItem value="GOVERNMENT" >GOVERNMENT</MenuItem>
                                            <MenuItem value="PRIVATE" >PRIVATE</MenuItem>
                                            <MenuItem value="NONPROFIT" >NONPROFIT</MenuItem>
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={6} style={{ paddingRight: 8 }}>

                                        <TextField
                                            id="email"
                                            label="E-mail (Optional)"
                                            name="email"
                                            autoComplete="off"
                                            style={styles.margin}
                                            value={this.state.email}
                                            onChange={(event) => this.setState({ email: event.target.value, invalid_email_error: false, email_error: false })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            fullWidth
                                            error={this.state.email_error === true ? true : this.state.invalid_email_error === true ? true : false}
                                            helperText={this.state.email_error === true ? "Please enter Email" : this.state.invalid_email_error === true ? "Please enter valid email" : false}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ paddingRight: 8 }} >
                                        <TextField
                                            id="phone_number"
                                            label="Phone Number (Optional)"
                                            name="phone_number"
                                            autoComplete="off"
                                            style={styles.margin}
                                            value={this.state.phone_number}
                                            onChange={(event) => event.target.value.match(/^\d*$/g) && this.setState({ phone_number: event.target.value, phone_number_error: false, invalid_phone_number_error: false, })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            fullWidth
                                            error={this.state.phone_number_error === true ? true : this.state.invalid_phone_number_error === true ? true : false}
                                            helperText={this.state.phone_number_error === true ? "Please enter phone number" : this.state.invalid_phone_number_error === true ? "Invalid phone number" : false}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ paddingRight: 8 }} >
                                        <TextField
                                            id="address_line1"
                                            label="Address Line1 (Optional)"
                                            name="address_line1"
                                            autoComplete="off"
                                            style={styles.margin}
                                            value={this.state.address_line1}
                                            onChange={(event) => this.setState({ address_line1: event.target.value, address_line1_error: false, })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            fullWidth
                                            error={this.state.address_line1_error === true ? true : false}
                                            helperText={this.state.address_line1_error === true ? "Please enter address" : false}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ paddingRight: 8 }} >
                                        <TextField
                                            id="address_line2"
                                            label="Address Line2 (Optional)"
                                            name="address_line1"
                                            autoComplete="off"
                                            style={styles.margin}
                                            value={this.state.address_line2}
                                            onChange={(event) => this.setState({ address_line2: event.target.value, })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} style={{ paddingRight: 8 }} >
                                        <TextField
                                            id="city"
                                            label="City (Optional)"
                                            name="city"
                                            autoComplete="off"
                                            style={styles.margin}
                                            value={this.state.city.charAt(0).toUpperCase() + this.state.city.slice(1)}
                                            onChange={(event) => this.setState({ city: event.target.value, city_error: false })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            fullWidth
                                            error={this.state.city_error === true ? true : false}
                                        // helperText={this.state.city_error === true ? "Please enter city" : false}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ paddingRight: 8 }}>
                                        <Autocomplete
                                            value={this.state.selectedState}
                                            margin="normal"
                                            style={{ paddingTop: 13 }}
                                            onChange={(event, value) => this.setState({ selectedState: value, selectedState_error: false })}
                                            inputValue={this.state.inputValue}
                                            onInputChange={(event, value) => this.setState({ inputValue: value, selectedState_error: false })}
                                            id="state"
                                            options={USA_STATES}

                                            renderInput={(params) => <TextField {...params} InputLabelProps={{
                                                shrink: true
                                            }}
                                                label="State (Optional)"
                                                error={this.state.selectedState_error === true ? true : false}
                                                helperText={this.state.selectedState_error === true ? "Please enter state" : false}
                                            />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ paddingRight: 8 }}>
                                        <TextField
                                            id="country"
                                            label="Country (Optional)"
                                            name="country"
                                            autoComplete="off"
                                            style={styles.margin}
                                            value={this.state.country.charAt(0).toUpperCase() + this.state.country.slice(1)}
                                            onChange={(event) => this.setState({ country: event.target.value, country_error: false })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            fullWidth
                                            error={this.state.country_error === true ? true : false}
                                            helperText={this.state.country_error === true ? "Please enter country" : false}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ paddingRight: 8 }}>
                                        <TextField
                                            id="zipcode"
                                            label="Zipcode (Optional)"
                                            name="zipcode"
                                            autoComplete="off"
                                            style={styles.margin}
                                            value={this.state.zipcode}
                                            onChange={(event) => event.target.value.match(/^\d{0,5}$/g) && this.setState({ zipcode: event.target.value, zipcode_error: false, invalid_zipcode_error: false })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            fullWidth
                                            error={
                                                this.state.zipcode_error ? true
                                                    : this.state.invalid_zipcode_error
                                                        ? true
                                                        : false
                                            }
                                            helperText={this.state.zipcode_error === true ? "Please enter zipcode" : this.state.invalid_zipcode_error ? "Please enter 5 digits valid zip code" : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ paddingRight: 8 }}>
                                        <TextField
                                            select
                                            id="partner"
                                            label="Partner (Optional)"
                                            name="partner"
                                            style={styles.margin}
                                            value={this.state.partner_ref}
                                            onChange={(event) => this.setState({ partner_ref: event.target.value })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            fullWidth
                                        >
                                            <MenuItem value="NONE">None</MenuItem>
                                            {this.props.partner.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    {this.state.partner_ref !== "NONE" && <Grid item xs={6} style={{ paddingRight: 8 }}>
                                        <TextField
                                            id="baseUrl"
                                            label="Url (Optional)"
                                            name="baseUrl"
                                            autoComplete="off"
                                            style={styles.margin}
                                            value={this.state.baseUrl}
                                            onChange={(event) => this.setState({ baseUrl: event.target.value })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            fullWidth
                                        />
                                    </Grid>}
                                    {this.state.partner_ref !== "NONE" && <Grid item xs={6} style={{ paddingRight: 8 }}>
                                        <TextField
                                            id="userName"
                                            label="Username (Optional)"
                                            name="userName"
                                            autoComplete="off"
                                            style={styles.margin}
                                            value={this.state.userName}
                                            onChange={(event) => this.setState({ userName: event.target.value })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            fullWidth
                                        />
                                    </Grid>}
                                    {this.state.partner_ref !== "NONE" && <Grid item xs={6} style={{ paddingRight: 8 }}>
                                        <TextField
                                            id="password"
                                            label="Password (Optional)"
                                            type={this.state.secureEntry ? "password" : "text"}
                                            name="password"
                                            autoComplete="off"
                                            style={styles.margin}
                                            value={this.state.password}
                                            onChange={(event) => this.setState({ password: event.target.value })}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            // InputProps={{
                                            //     styles: { input: styles.inputStyle }
                                            // }}
                                            fullWidth
                                            InputProps={{
                                                styles: { input: styles.inputStyle, root: styles.inputRoot },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => this.handleClickShowPassword()}
                                                        >
                                                            {this.state.secureEntry ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>}
                                    {this.state.mode === "EDIT" &&
                                        <Grid item xs={6} style={{ paddingRight: 8 }}>
                                            <div style={{ padding: "2% 0 2% 0" }}>
                                                <Typography style={{ fontFamily: "Futura-Book", fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>Status</Typography>
                                                <FormControl component="fieldset">
                                                    <RadioGroup aria-label="position" name="position" value={this.state.status}
                                                        onChange={(event) => this.setState({ status: event.target.value, status_error: false })} row>
                                                        <FormControlLabel
                                                            value="ACTIVE"
                                                            control={<Radio style={{ color: theme.palette.primary.main }} />}
                                                            label={<span style={styles.labelStyle}>Active</span>}
                                                            labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                            value="INACTIVE"
                                                            control={<Radio style={{ color: theme.palette.primary.main }} />}
                                                            label={<span style={styles.labelStyle}>In Active</span>}
                                                            labelPlacement="end"
                                                        />
                                                    </RadioGroup>
                                                    {this.state.status_error === true && <FormHelperText style={{ color: "red" }}>{"Please select status"}</FormHelperText>}
                                                </FormControl>
                                            </div>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            {this.state.mode === "ADD" &&
                                <Grid item xs={6} >
                                    <Grid container justify="space-between" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontFamily: "Futura-Heavy" }}>Primary contact</Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{ paddingRight: 8 }}>
                                            <TextField
                                                id="search-phone"
                                                label="Phone number"
                                                name="search-phone"
                                                style={styles.margin}
                                                value={this.state.search_phone}
                                                onChange={(event) => event.target.value.match(/^\d{0,10}$/g) && this.setState({
                                                    search_phone: event.target.value, search_phone_error: false, invalid_search_phone_error: false,
                                                    primary_contact_first_name: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_first_name,
                                                    primary_contact_last_name: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_last_name,
                                                    primary_contact_email: (event.target.value === "" || event.target.value.length < 10) ? "" : this.state.primary_contact_email
                                                })}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    styles: { input: styles.inputStyle },
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography>{this.state.primary_contact_country_code}</Typography>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                autoComplete="off"
                                                fullWidth
                                                error={this.state.search_phone_error ? true : this.state.invalid_search_phone_error ? true : false}
                                                helperText={this.state.search_phone_error === true ? "Please enter phone number" : this.state.invalid_search_phone_error ? "Please enter valid phone number" : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <div style={{ padding: 8, justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                <Button size="small" onClick={() => this.searchUser()} variant="contained" color="primary" style={styles.addButton}>Find User</Button>
                                            </div>
                                            <Typography style={{ fontSize: "10px", color: theme.palette.secondary.light, textAlign: "center" }}>(If user already exist)</Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{ paddingRight: 8 }}>
                                            <TextField
                                                id="first name"
                                                label="First Name"
                                                name="first name"
                                                style={styles.margin}
                                                value={this.state.primary_contact_first_name.charAt(0).toUpperCase() + this.state.primary_contact_first_name.slice(1)}
                                                autoComplete="off"
                                                onChange={(event) => this.setState({ primary_contact_first_name: event.target.value, primary_contact_first_name_error: false })}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    styles: { input: styles.inputStyle }
                                                }}
                                                fullWidth
                                                error={this.state.primary_contact_first_name_error === true ? true : false}
                                                helperText={this.state.primary_contact_first_name_error === true ? "Please enter the first name" : false}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{ paddingRight: 8 }}>
                                            <TextField
                                                id="last name"
                                                label="Last Name"
                                                name="last name"
                                                autoComplete="off"
                                                style={styles.margin}
                                                value={this.state.primary_contact_last_name.charAt(0).toUpperCase() + this.state.primary_contact_last_name.slice(1)}
                                                onChange={(event) => this.setState({ primary_contact_last_name: event.target.value, primary_contact_last_name_error: false })}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    styles: { input: styles.inputStyle }
                                                }}
                                                fullWidth
                                                error={this.state.primary_contact_last_name_error === true ? true : false}
                                                helperText={this.state.primary_contact_last_name_error === true ? "Please enter last name" : false}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{ paddingRight: 8 }} >
                                            <TextField
                                                id="email"
                                                label="E-mail"
                                                name="email"
                                                autoComplete="off"
                                                style={styles.margin}
                                                value={this.state.primary_contact_email}
                                                onChange={(event) => this.setState({ primary_contact_email: event.target.value, primary_contact_email_error: false, invalid_primary_contact_email_error: false })}
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    styles: { input: styles.inputStyle }
                                                }}
                                                fullWidth
                                                error={this.state.primary_contact_email_error === true ? true : this.state.invalid_primary_contact_email_error === true ? true : false}
                                                helperText={this.state.primary_contact_email_error === true ? "Enter the email" : this.state.invalid_primary_contact_email_error === true ? "Please enter valid email" : false}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={() => this.handleSubmit(this.state.mode)} variant="contained" color={"primaryButton"} style={styles.addButton}>Submit</Button>
                        <Button size="small" onClick={() => this.handleClose()} variant="contained" style={styles.cancelButton}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.isLoading}
                    styles={{
                        paper: styles.dialogPaper,
                    }}
                >
                    <div style={{ boxShadow: 0, overflow: "none" }}>
                        <CircularProgress color="primary" size={40} />
                    </div>
                </Dialog>


            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        organization: state.organizationReducer.organization,
        fetch_user: state.userReducer.fetch_user,
        partner: state.partnerReducer.partner,
    }
}
Organization.propTypes = {
    styles: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps)(Organization));