import api from '../api';
import { FETCH_VACCINEMANUFACTURER, ALERT_DETAIL } from "./actionTypes"

export function fetchVaccineManufacturer() {
    return function (dispatch) {
        api.get('vaccine-manufacturer').then((response) => {
            if (response.status === "success") {
                dispatch({
                    type: FETCH_VACCINEMANUFACTURER,
                    data: response.payload
                });
            }
        });
    }
}

export function addVaccineManufacturer(self, item) {
    return dispatch => {
        api.post('vaccine-manufacturer', item).then((response) => {
            //console.log("+++++++++++add++++++++++++++" + response.status)
            if (response.status === "success") {
                dispatch(fetchVaccineManufacturer())
                dispatch({ type: ALERT_DETAIL, data: true, message: "Vaccine manufacturer added successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    dialogOpen: false,
                    id: null,
                    name: "",
                    name_error: false,
                    email: "",
                    email_error: false,
                    phoneNumber: "",
                    phoneNumber_error: false,
                    address: "",
                    address_error: false,
                    city: "",
                    city_error: false,
                    state: "",
                    state_error: false,
                    country: "",
                    country_error: false,
                    status: "ACTIVE",
                    status_error: false,
                    mode: ""
                })

            } else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}

export function updateVaccineManufacturer(self, item, id) {
    return dispatch => {
        api.put('vaccine-manufacturer/' + id, item).then((response) => {

            if (response.status === "success") {
                dispatch(fetchVaccineManufacturer());
                dispatch({ type: ALERT_DETAIL, data: true, message: "Vaccine manufacturer updated successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    dialogOpen: false,
                    id: null,
                    name: "",
                    name_error: false,
                    email: "",
                    email_error: false,
                    phoneNumber: "",
                    phoneNumber_error: false,
                    address: "",
                    address_error: false,
                    city: "",
                    city_error: false,
                    state: "",
                    state_error: false,
                    country: "",
                    country_error: false,
                    status: "ACTIVE",
                    status_error: false,
                    mode: ""
                })
            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}