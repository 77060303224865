import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Table, TableBody, TableCell, TableHead, TableRow, TablePagination, CircularProgress,
    Typography, Grid, Button, Dialog, DialogActions, FormControl, Tabs, Tab, Box, Radio, RadioGroup,
    DialogContent, DialogTitle, TextField, Toolbar, Card, InputAdornment, AppBar, FormControlLabel
} from '@mui/material';
// import _ from "underscore";
import { addDonorCredits } from '../../actions/donorAction';
import { fetchCreditCoupons, generateCoupon } from "../../actions/creditCouponAction";
import { fetchUser } from '../../actions/userAction';
import TablePaginationActions from "../Dashboard/TablePaginationActions";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
// import api from "../../api";
import moment from 'moment';
// import { PUBLISHABLE_KEY } from "../../helpers/Constants";
// import { loadStripe } from "@stripe/stripe-js";
import { withRouter } from "../../withRouter";
import theme from "../../theme";

// const stripePromise = loadStripe(PUBLISHABLE_KEY);

/** Created common styles for UI  */
const styles = {
    textStyle: {
        fontFamily: "Futura-Heavy",
        color: "#37474f",
        //fontFamily: "bold",
        fontSize: 20,
        //padding: 10
    },
    creditTextStyle: {
        fontFamily: "Futura-Heavy",
        color: "#37474f",
        // fontFamily: "bold",
        fontSize: 15,
        paddingTop: 10
    },
    addButton: {
        backgroundColor: "#20478e",
        color: "white",
        fontFamily: "unicode.futurab",
        borderRadius: "10",

    },
    smallButton: {
        backgroundColor: "#16c784",
        color: "white",
        fontSize: 11,
        fontFamily: "unicode.futurab",
        borderRadius: "10",
    },
    cancelButton: {
        backgroundColor: "#8fa3ad",
        color: "white",
        fontFamily: "unicode.futurab",
        // textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(143, 163, 173, 0.8)',
        }
    },
    editButton: {
        backgroundColor: "#8fa3ad",
        color: "white",
        fontFamily: "unicode.futurab",
        // textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(143, 163, 173, 0.8)',
        }
    },
    fontCellHeader: {
        fontFamily: "Futura-Book",
        color: "#fff",
        fontWeight: 900,
        padding: '14px 20px 14px 20px',

    },
    fontTableCellBody: {
        fontFamily: "Futura-Medium-bt",
        color: "#37474f",
        padding: '14px 20px 14px 10px',
    },
    inputStyle: {
        // fontFamily: "Futura-Book",
        fontSize: 16,
        paddingLeft: 10,
        // borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
        // margin: 2,
        // "&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
        //     color: theme.palette.text.primary,
        //     fontSize: 10
        //   },
        //   "&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
        //     color: theme.palette.primary.main
        //   },
        //   '& .MuiTypography-root': {
        //     fontSize: 10,
        //     color: 'red'
        // }
    },
    labelStyle: {
        fontFamily: "Futura-Book",
        fontSize: 14
    },
    inputStyleForSearchText: {
        fontSize: "15px !important",
        padding: "5px !important",
        width: 250,
    },
    inputRoot: {
        borderRadius: "0px !important"
    },
    inputLabel: {
        color: "#4B86E3"
    },
    dialogPaper: {
        backgroundColor: "rgba(0,0,0,0)",
        boxShadow: "none",
        overflow: "hidden"
    },
    adornmentStyle: {
        '.MuiInputAdornment-root': {
            fontSize: 10,
            color: 'red'
        }
    }
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{ padding: 5 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

/**
 * @class
 *
 * Class representing CreditCoupon component
 *
 */
class CreditCoupon extends Component {

    constructor() {
        super();
        this.state = {
            creditCoupons: [],
            availableCredits: 0,
            credits: null,
            creditsError: false,
            invalidCreditsError: false,
            fetchUser: {},
            dialogOpenForAddCredits: false,
            search_text: "",
            rowsPerPage: 10,
            page: 0,
            isLoading: false,
            creditValue: 0,
            creditPerCoupon: 1,
            totalCredit: 0,
            couponCount: 0,
            limit: 1,
            couponType: 'split',
            creditPerCouponError: false,
            couponCountError: false,
            limitError: false,
            tabValue: 0
        }
    }

    /** This is a component life cycle method and used to assigning props values to state variable  */
    static getDerivedStateFromProps(props, state) {
        if (props.fetchUser !== state.fetchUser || props.creditCoupons !== state.creditCoupons) {
            return {
                availableCredits: props.fetchUser.donorAvailableCredits,
                creditCoupons: props.creditCoupons,
                fetchUser: props.fetchUser
            }
        }
        return null;
    };

    componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        this.submitAddcredits(query);
        /** fetching Users details. */
        this.props.dispatch(fetchUser());
        /** fetching user coupons. */
        this.props.dispatch(fetchCreditCoupons());
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0, rowsPerPage: parseInt(event.target.value, 10)
        });
    };

    handleClick = async (event) => {
        // const stripe = await stripePromise;
        // let data = {};
        // data.credits = parseInt(this.state.credits);
        // data.price_per_credit = 1;

        // if (parseInt(this.state.credits) > 0) {
        //     const response = await api.post(`credit-coupon/donor/create-payment-session/${this.props.fetchUser.id}`, data);

        //     if (response.status === "success") {
        //         // When the customer clicks on the button, redirect them to Checkout.
        //         let session = response.payload;
        //         const result = await stripe.redirectToCheckout({
        //             sessionId: session.id,
        //         });

        //         if (result.error) {
        //             // If `redirectToCheckout` fails due to a browser or network
        //             // error, display the localized error message to your customer
        //             // using `result.error.message`.
        //         }
        //     }
        // } else {
        //     alert('Credits should not be empty')
        // }
    };

    calculateAvailableCredit = () => {
        const { availableCredits, totalCredit } = this.state;
        if (totalCredit !== null && totalCredit !== '') {
            return (parseInt(availableCredits) - parseInt(totalCredit));
        } else {
            return (parseInt(availableCredits) - parseInt(0));
        }
    };

    /**
   * @function
   * This function representing  add Credits to Donor .
   */

    submitAddcredits = (query) => {
        let session_id = query.get("session_id");
        let successfulPayment = query.get("success");
        let cancelledPayment = query.get("cancel");

        let paymentStatus = successfulPayment !== null ? ((successfulPayment === true || successfulPayment === "true") ? 'success' : 'cancel') : 'cancel';

        // console.log(`${successfulPayment} ===== ${cancelledPayment} ===== ${session_id}`);
        if ((successfulPayment !== null || cancelledPayment !== null) && session_id !== null && session_id !== "") {
            this.props.dispatch(addDonorCredits(this, {}, session_id, paymentStatus))
        }
        // this.props.dispatch(addDonorCredits(this, {}, session_id, paymentStatus));
    };

    handleClose = () => {
        this.setState({
            dialogOpenForAddCredits: false,
            credits: "",
            creditsError: false,
            invalidCreditsError: false
        })
    };

    /**
    * @function
    * This function representing  add Credits dialog screnn wwill open .
    */
    onSumitAddcredits = () => {
        const { availableCredits, totalCredit } = this.state;
        let currentValue = (parseInt(availableCredits) - parseInt(totalCredit));
        if (currentValue < 0) {
            this.setState({ dialogOpenForAddCredits: true, credits: Math.abs(currentValue) })
        } else {
            this.setState({ dialogOpenForAddCredits: true })
        }
    };

    handleRadioChange = (event) => {
        if (event.target.value === 'split') {
            this.setState({ couponType: event.target.value, totalCredit: 0, limit: 1, creditPerCoupon: 1, couponCount: 0, limitError: false, creditPerCouponError: false, couponCountError: false });
        } else {
            this.setState({ couponType: event.target.value, totalCredit: 0, creditPerCoupon: 1, limit: 0, couponCount: 1, limitError: false, creditPerCouponError: false, couponCountError: false });
        }
    };

    generateCoupons = () => {
        let tokensToBeGenerated = this.calculateAvailableCredit();
        // if(Number.isInteger(tokensToBeGenerated) === true && tokensToBeGenerated !== 0){
        //     alert('yes')
        // }else{
        //     alert('no')
        // }
        const { couponType, couponCount, creditPerCoupon, limit } = this.state;
        if (tokensToBeGenerated >= 0) {
            let isError = false;
            // let errorMessage = '';

            if (couponCount === "" || couponCount === 0) {
                isError = true;
                // errorMessage = errorMessage + 'Number of codes should be greater than 0, '
                this.setState({ couponCountError: true });
            }

            if (creditPerCoupon === "" || creditPerCoupon === 0) {
                isError = true;
                // errorMessage = errorMessage + 'Credit should be atleast 1, '
                this.setState({ creditPerCouponError: true });
            }

            if (limit === "" || limit === 0) {
                isError = true;
                // errorMessage = errorMessage + 'Number of times should be atleast 1, '
                this.setState({ limitError: true });
            }

            if (isError === false) {
                let dataObj = {};
                dataObj.creditValue = creditPerCoupon;
                dataObj.redemptionLimit = limit;
                dataObj.description = null;
                dataObj.status = 'ACTIVE';
                dataObj.couponType = couponType;
                dataObj.isGroup = couponType === 'limit' ? true : false;
                dataObj.couponCount = couponCount;
                this.props.dispatch(generateCoupon(this, dataObj, this.props.fetchUser.id));
            }

        } else {
            alert("You don't have enough credit to generate code.")
        }
    };

    calculateNumberOfTokens = () => {
        const { totalCredit, creditPerCoupon, couponType } = this.state;
        if (couponType === 'split') {
            if (totalCredit !== '' && totalCredit !== 0 && creditPerCoupon !== '' && creditPerCoupon !== 0) {
                return parseInt(totalCredit) / parseInt(creditPerCoupon)
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    };

    calculateCreditByCount = (couponCount, creditPerCoupon, limit) => {
        // const { creditPerCoupon, couponCount } = this.state;
        let tokenCredit = 0;
        if (couponCount !== '' && couponCount !== 0 && creditPerCoupon !== '' && creditPerCoupon !== 0) {
            tokenCredit = parseInt(couponCount) * parseInt(creditPerCoupon) * parseInt(limit)
        }
        // console.log(`TokenCredit value ==> ${tokenCredit} == ${creditPerCoupon} === ${couponCount}`)
        return tokenCredit;
    };

    calculateCreditByLimit = (couponCount, creditPerCoupon, limit) => {
        // const { limit, couponCount } = this.state;
        let tokenCredit = 0;
        if (creditPerCoupon !== '' && creditPerCoupon !== 0 && limit !== '' && limit !== 0) {
            tokenCredit = parseInt(creditPerCoupon) * parseInt(limit) * parseInt(couponCount)
        }

        return tokenCredit;
    };

    handleTextChange = (event, name) => {
        const { couponType, couponCount, creditPerCoupon, limit } = this.state;
        let totalCredit = 0;
        let couponCountValue = name === 'couponCount' ? event.target.value : couponCount;
        let creditPerCouponValue = name === 'creditPerCoupon' ? event.target.value : creditPerCoupon;
        let limitValue = name === 'limit' ? event.target.value : limit;

        let errorName = `${name}Error`;
        // console.log(`couponCountValue ==> ${couponCountValue} === creditPerCouponValue ==> ${creditPerCouponValue} ==== limitValue ==> ${limitValue}`)
        switch (couponType) {
            case 'split':
                totalCredit = this.calculateCreditByCount(couponCountValue, creditPerCouponValue, limitValue);
                // console.log(`TokenCredit ==> ${totalCredit}`)
                this.setState({ totalCredit: totalCredit, [name]: event.target.value, [errorName]: false, limit: 1 });
                break;
            case 'limit':
                totalCredit = this.calculateCreditByLimit(couponCountValue, creditPerCouponValue, limitValue);
                // console.log(`TokenCredit1 ==> ${totalCredit}`)
                this.setState({ totalCredit: totalCredit, [name]: event.target.value, [errorName]: false, couponCount: 1 });
                break;
            default:
                break;
        }
    };

    handleTabChange = (e, value) => {
        if (value !== this.state.tabValue) {
            this.setState({ tabValue: value, rowsPerPage: 10, page: 0 });
        }
    };

    /** rendering DonorDashboard information. */
    render() {
        const { page, rowsPerPage, creditCoupons, tabValue } = this.state;
        // let result = this.state.search_text !== '' ? creditCoupons.filter(x => (x.code).toString().toLowerCase().includes((this.state.search_text).toString().toLowerCase())) : creditCoupons
        let individualCodes = creditCoupons.filter(coupons => coupons.isGroup === false);
        let groupCodes = creditCoupons.filter(coupons => coupons.isGroup === true);
        let result = tabValue === 0 ? individualCodes : groupCodes;
        let availableBalance = this.calculateAvailableCredit();
        return (
            <div style={{ paddingLeft: 2, paddingRight: 10, marginBottom: 50 }}>
                <Grid container spacing={2} style={{ marginTop: 5 }}>
                    <Grid item xs={12} sm={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Card style={{ marginTop: 10, width: 200, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 15 }}>
                                    <Typography style={{ color: "white", fontSize: 11 }}>AVAILABLE CREDITS</Typography>
                                    <Typography style={{ color: "white", fontSize: 22, fontWeight: "bold", paddingTop: 8 }}>{this.state.availableCredits !== null ? this.state.availableCredits : 0}</Typography>
                                    <div style={{ textAlign: "end", marginTop: "-50px" }}>
                                        <AccountBalanceWalletIcon style={{ color: "#cfd4d6", fontSize: "50px" }} />
                                    </div>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <AppBar position="static">
                                    <Tabs value={this.state.tabValue} onChange={(e, value) => this.handleTabChange(e, value)} aria-label="simple tabs example">
                                        <Tab style={{ color: "#fff" }} label="Individual" {...a11yProps(0)} />
                                        <Tab style={{ color: "#fff" }} label="Group" {...a11yProps(1)} />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={this.state.tabValue} index={0}>
                                    <Grid item xs={12} sm={12}>
                                        <Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
                                            <div>
                                                <Typography style={{ ...styles.textStyle, fontSize: "16", color: theme.palette.secondary.main, fontWeight: "bold" }}>Generated Individual Codes</Typography>
                                            </div>
                                            <TablePagination
                                                rowsPerPageOptions={[20]}
                                                component="div"
                                                count={result.length}
                                                rowsPerPage={this.state.rowsPerPage}
                                                page={this.state.page}
                                                slotProps={{
                                                    select: {
                                                        inputProps: { 'aria-label': 'rows per page' },
                                                        native: true,
                                                    }
                                                }}
                                                onChangePage={this.handleChangePage}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                labelRowsPerPage={null}
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => null
                                                }
                                                ActionsComponent={
                                                    TablePaginationActions
                                                }
                                            />
                                        </Toolbar>
                                    </Grid>
                                    <Table>
                                        <TableHead style={{ backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})` }}>
                                            <TableRow>
                                                <TableCell align="left" style={styles.fontCellHeader}>Code</TableCell>
                                                <TableCell align="center" style={styles.fontCellHeader}>Credit Value</TableCell>
                                                <TableCell align="center" style={styles.fontCellHeader}>Generated On</TableCell>
                                                {/* <TableCell align="center" style={styles.fontCellHeader}>Limit</TableCell> */}
                                                <TableCell align="center" style={styles.fontCellHeader}>Redeemed</TableCell>
                                                {/* <TableCell align="center" style={styles.fontCellHeader}>Status</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                result.length > 0 ? result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) =>
                                                    <TableRow key={item.id}>
                                                        <TableCell align="left" style={styles.fontTableCellBody}>
                                                            <Typography style={{ fontWeight: "bold", fontSize: 15 }}> {item.code}</Typography>
                                                        </TableCell>
                                                        <TableCell align="center" style={styles.fontTableCellBody}>
                                                            <Typography style={{ fontWeight: "bold", fontSize: 15 }}> {item.creditValue}</Typography>
                                                        </TableCell>
                                                        <TableCell align="center" style={styles.fontTableCellBody}>
                                                            <Typography style={{ fontWeight: "bold", fontSize: 15 }}> {moment(item.addedOn).format('YYYY-MM-DD')}</Typography>
                                                        </TableCell>
                                                        {/* <TableCell align="center" style={styles.fontTableCellBody}>
                                                            <Typography style={{ fontWeight: "bold", fontSize: 15 }}> {item.redemptionLimit}</Typography>
                                                        </TableCell> */}
                                                        <TableCell align="center" style={styles.fontTableCellBody}>
                                                            <Typography style={{ fontWeight: "bold", fontSize: 15 }}> {item.redeemed === item.redemptionLimit ? 'Yes' : 'No'}</Typography>
                                                        </TableCell>
                                                        {/* <TableCell align="center" style={styles.fontTableCellBody}>
                                                            <Badge color="primary" badgeContent={item.status}></Badge>
                                                        </TableCell> */}
                                                    </TableRow>
                                                )
                                                    :
                                                    <TableRow>
                                                        <TableCell colSpan={5} align="center" style={styles.fontTableCellBody}>
                                                            <Typography style={styles.fontTableCellBody}>There are no individual credit codes</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TabPanel>
                                <TabPanel value={this.state.tabValue} index={1}>
                                    <Grid item xs={12} sm={12}>
                                        <Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
                                            <div>
                                                <Typography style={{ ...styles.textStyle, fontSize: "16", color: theme.palette.secondary.main, fontWeight: "bold" }}>Generated Group Codes</Typography>
                                            </div>
                                            <TablePagination
                                                rowsPerPageOptions={[20]}
                                                component="div"
                                                count={result.length}
                                                rowsPerPage={this.state.rowsPerPage}
                                                page={this.state.page}
                                                slotProps={{
                                                    select: {
                                                        inputProps: { 'aria-label': 'rows per page' },
                                                        native: true,
                                                    }
                                                }}
                                                onChangePage={this.handleChangePage}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                labelRowsPerPage={null}
                                                labelDisplayedRows={
                                                    ({ from, to, count }) => null
                                                }
                                                ActionsComponent={
                                                    TablePaginationActions
                                                }
                                            />
                                        </Toolbar>
                                    </Grid>
                                    <Table>
                                        <TableHead style={{ backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})` }}>
                                            <TableRow>
                                                <TableCell align="left" style={styles.fontCellHeader}>Code</TableCell>
                                                <TableCell align="center" style={styles.fontCellHeader}>Credit Value</TableCell>
                                                <TableCell align="center" style={styles.fontCellHeader}>Generated On</TableCell>
                                                {/* <TableCell align="center" style={styles.fontCellHeader}>Limit</TableCell> */}
                                                <TableCell align="center" style={styles.fontCellHeader}>Total Uses</TableCell>
                                                <TableCell align="center" style={styles.fontCellHeader}>Remaining Uses</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                result.length > 0 ? result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) =>
                                                    <TableRow key={item.id}>
                                                        <TableCell align="left" style={styles.fontTableCellBody}>
                                                            <Typography style={{ fontWeight: "bold", fontSize: 15 }}> {item.code}</Typography>
                                                        </TableCell>
                                                        <TableCell align="center" style={styles.fontTableCellBody}>
                                                            <Typography style={{ fontWeight: "bold", fontSize: 15 }}> {item.creditValue}</Typography>
                                                        </TableCell>
                                                        <TableCell align="center" style={styles.fontTableCellBody}>
                                                            <Typography style={{ fontWeight: "bold", fontSize: 15 }}> {moment(item.addedOn).format('YYYY-MM-DD')}</Typography>
                                                        </TableCell>
                                                        <TableCell align="center" style={styles.fontTableCellBody}>
                                                            <Typography style={{ fontWeight: "bold", fontSize: 15 }}> {item.redemptionLimit}</Typography>
                                                        </TableCell>
                                                        <TableCell align="center" style={styles.fontTableCellBody}>
                                                            <Typography style={{ fontWeight: "bold", fontSize: 15 }}> {(item.redemptionLimit - item.redeemed)}</Typography>
                                                        </TableCell>
                                                        {/* <TableCell align="center" style={styles.fontTableCellBody}>
                                                            <Badge color="primary" badgeContent={item.status}></Badge>
                                                        </TableCell> */}
                                                    </TableRow>
                                                )
                                                    :
                                                    <TableRow>
                                                        <TableCell colSpan={5} align="center" style={styles.fontTableCellBody}>
                                                            <Typography style={styles.fontTableCellBody}>There are no credit codes</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ backgroundColor: '#f9f9f9' }}>
                        <div style={{ padding: 10, flexDirection: 'column', display: 'flex' }}>
                            {/* <div style={{ backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", borderRadius: 5, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", alignItems: 'center', justifyContent: 'center' }}>
                                <Typography style={{ color: "#fff", fontSize: 11, padding: 10, textAlign: 'center' }}>GENERATE CREDIT COUPONS</Typography>
                            </div> */}
                            <div style={{ marginTop: 10 }}>
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="position" name="position" value={this.state.couponType} onChange={(event) => this.handleRadioChange(event)}>
                                        <FormControlLabel
                                            value="split"
                                            control={<Radio color="primary" />}
                                            label="Individual"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="limit"
                                            control={<Radio color="primary" />}
                                            label="Group"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            {/*                             
                            <div style={{ marginTop: 15, marginBottom: 5}}>
                                <TextField
                                    id="filled-start-adornment-1"
                                    //style={clsx(styles.margin, styles.textField)}
                                    style={styles.margin}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            <div style={{textAlign: 'right', width: 120, borderRight: '1px solid rgba(0, 0, 0, 0.2)'}}>
                                                <Typography style={{ fontSize: 13, color: '#20478E', marginRight: 15 }}>Credit Per Code</Typography>
                                            </div>
                                        </InputAdornment>,
                                        classes: { input: styles.inputStyle }
                                    }}
                                    value={this.state.creditPerCoupon}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onChange = {(event) => {event.target.value.match(/^[0-9]*$/g) && this.handleTextChange(event, 'creditPerCoupon') }}
                                    error={this.state.creditPerCouponError}
                                    helperText={this.state.creditPerCouponError === true ? "Credit per code should be atleast 1" : ""}
                                />
                            </div> */}
                            {
                                this.state.couponType === 'split' &&
                                <div style={{ marginTop: 10, marginBottom: 5 }}>
                                    <TextField
                                        id="filled-start-adornment-2"
                                        //className={clsx(classes.margin, classes.textField)}
                                        style={styles.margin}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                <div style={{ textAlign: 'right', width: 120, borderRight: '1px solid rgba(0, 0, 0, 0.2)' }}>
                                                    <Typography style={{ fontSize: 13, color: '#20478E', marginRight: 15 }}>Number Of Codes</Typography>
                                                </div>
                                            </InputAdornment>,
                                            classes: { input: styles.inputStyle }
                                        }}
                                        value={this.state.couponCount}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        disabled={this.state.couponType === 'limit' ? true : false}
                                        onChange={(event) => { event.target.value.match(/^[0-9]*$/g) && this.handleTextChange(event, 'couponCount') }}
                                        error={this.state.couponCountError}
                                        helperText={this.state.couponCountError === true ? "Number of codes should be atleast 1" : ""}
                                    />
                                </div>
                            }
                            {
                                this.state.couponType === 'limit' &&
                                <div style={{ marginTop: 10, marginBottom: 5 }}>
                                    <TextField
                                        id="filled-start-adornment-3"
                                        //className={clsx(classes.margin, classes.textField)}
                                        style={styles.margin}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                <div style={{ textAlign: 'right', width: 120, borderRight: '1px solid rgba(0, 0, 0, 0.2)' }}>
                                                    <Typography style={{ fontSize: 13, color: '#20478E', marginRight: 15 }}>Number Of Uses</Typography>
                                                </div>
                                            </InputAdornment>,
                                            classes: { input: styles.inputStyle }
                                        }}
                                        value={this.state.limit}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        disabled={this.state.couponType === 'split' ? true : false}
                                        onChange={(event) => { event.target.value.match(/^[0-9]*$/g) && this.handleTextChange(event, 'limit') }}
                                        error={this.state.limitError}
                                        helperText={this.state.limitError === true ? "Number of usage should be atleast 1" : ""}
                                    />
                                </div>
                            }
                            <div style={{ marginTop: 10, marginBottom: 5 }}>
                                <TextField
                                    id="filled-start-adornment"
                                    //style={clsx(styles.margin, styles.textField)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            <div style={{ textAlign: 'right', width: 120, borderRight: '1px solid rgba(0, 0, 0, 0.2)' }}>
                                                <Typography style={{ fontSize: 13, color: '#20478E', marginRight: 15 }}>Total</Typography>
                                            </div>
                                        </InputAdornment>,
                                        endAdornment: <InputAdornment position="end">
                                            <div style={{ textAlign: 'center', width: 70, backgroundColor: '#ccc', borderRadius: 5 }}>
                                                <Typography style={{ fontSize: 13, color: '#20478E' }}>CREDITS</Typography>
                                            </div>
                                        </InputAdornment>,
                                        classes: { input: styles.inputStyle }
                                    }}
                                    value={this.state.totalCredit}
                                    variant="outlined"
                                    size="small"
                                    disabled
                                    fullWidth
                                    onChange={(event) => { event.target.value.match(/^[0-9]*$/g) && this.handleTextChange(event, 'totalCredit') }}
                                />
                            </div>
                            <div style={{ marginTop: 10, marginBottom: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography style={{ fontSize: 13, color: '#20478E' }}>Credit Balance after transaction</Typography>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: 14, color: availableBalance > 0 ? '#20478E' : '#f50057', paddingRight: 15, fontWeight: 'bold' }}>
                                        {availableBalance}
                                    </Typography>
                                    <Button size="small" variant="contained" color="primary" style={{ ...styles.smallButton, lineHeight: 1 }} onClick={() => this.onSumitAddcredits()}>
                                        ADD CREDIT
                                    </Button>
                                </div>
                            </div>

                            <Button variant="contained" color="primary" style={{ ...styles.addButton, marginTop: 20, backgroundColor: theme.palette.secondary.main }} onClick={() => this.generateCoupons()}>
                                Generate Codes
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <Dialog
                    maxWidth={"xs"}
                    open={this.state.dialogOpenForAddCredits} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"BUY CREDITS"}</Typography>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "hidden" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Card style={{ margin: 10, width: 200, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 15 }}>
                                <Typography style={{ color: "white", fontSize: 11 }}>AVAILABLE CREDITS</Typography>
                                <Typography style={{ color: "white", fontSize: 22, fontWeight: "bold", paddingTop: 8 }}>{this.state.availableCredits !== null ? this.state.availableCredits : 0}</Typography>
                                <div style={{ textAlign: "end", marginTop: "-50px" }}>
                                    <AccountBalanceWalletIcon style={{ color: "#cfd4d6", fontSize: "50px" }} />
                                </div>
                            </Card>
                        </div>
                        <TextField
                            id="credits"
                            label="Buy Credits"
                            name="credits"
                            style={styles.margin}
                            value={this.state.credits}
                            onChange={(event) => event.target.value.match(/^\d*$/g) && this.setState({ credits: event.target.value, creditsError: false, invalidCreditsError: false })}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                classes: { input: styles.inputStyle }
                            }}
                            autoComplete="off"
                            fullWidth
                            error={this.state.creditsError === true ? true : this.state.invalidCreditsError === true ? true : false}
                            helperText={this.state.creditsError === true ? "Please enter your buy credits" : this.state.invalidCreditsError === true ? "credits should not be greater than donor available credits" : false}
                        />

                    </DialogContent>
                    <DialogActions>
                        {/* <Button size="small" onClick={() => this.sumitAddcredits()} variant="contained" color="primary" style={styles.addButton}>Submit</Button> */}
                        <Button size="small" onClick={(e) => this.handleClick(e)} variant="contained" color="primary" style={styles.addButton}>Checkout</Button>
                        <Button size="small" onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.isLoading}
                    classes={{
                        paper: styles.dialogPaper,
                    }}
                >
                    <div style={{ boxShadow: 0, overflow: "none" }}>
                        <CircularProgress color="primary" size={40} />
                    </div>
                </Dialog>
            </div>
        );
    }
};
/** Get data from store and assign to props */
const mapStateToProps = (state) => {
    return {
        fetchUser: state.userReducer.fetch_user,
        creditCoupons: state.creditCouponReducer.creditCoupons
    }
};

CreditCoupon.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps)(CreditCoupon));