import React, { Component } from "react";
import { Typography, Grid, Card, Button, Dialog, TextField, DialogTitle, DialogActions, DialogContent } from "@mui/material";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchOrganizationDetailByID, fetchOrganizationPlan, AddSeatsForOrg } from '../../actions/organizationAction';
import { BiWallet } from "react-icons/bi";
import { withRouter } from "../../withRouter";
import theme from "../../theme";

const styles = {
    addButton: {
        // backgroundColor: "#20478e",
        color: "white",
        fontFamily: "unicode.futurab",
        borderRadius: "10",

    },
}

class ViewOrganization extends Component {
    constructor() {
        super();
        this.state = {
            organization_id: null,
            planData: [],
            addCreditDialog: false
        }
    }
    componentDidMount() {
        let id = this.props.params.id;
       console.log("***ssss*******"+ id)
   
        this.setState({ organization_id: id });
        this.props.dispatch(fetchOrganizationDetailByID(id))
        this.props.dispatch(fetchOrganizationPlan())

    }
    setPlanCount = (event, item) => {
        if (event.target.value.match(/^\d{0,10}$/g)) {
            let isExist = this.state.planData.find(x => x.subscriptionPlanId === item.id)
            if (isExist === undefined) {
                isExist = {};
                isExist.count = event.target.value;
                isExist.subscriptionPlanId = item.id;
                isExist.total_price = event.target.value !== "" ? parseInt(event.target.value) * parseFloat(item.price) : 0
                this.setState({ planData: [...this.state.planData, isExist] })
            } else {
                isExist.count = event.target.value;
                isExist.total_price = event.target.value !== "" ? parseInt(isExist.count) * parseFloat(item.price) : 0
                this.setState({ planData: this.state.planData.map(x => x.subscriptionPlanId === item.id ? isExist : x) })
            }
        }
    }

    returnCount = (item) => {
        let isExist = this.state.planData.find(x => x.subscriptionPlanId === item.id)
        if (isExist === undefined) {
            return 0;
        } else {
            return isExist.count;
        }
    }
    planSeatsBalance = (item) => {

        let data = this.props.organizationDetailyByID !== null ? this.props.organizationDetailyByID.organizationSubscribedPlans.find(x => x.subscriptionPlanId === item.id) : null;
        if (data === null || data === undefined) {
            return 0
        } else {
            return data.availableCount
        }
    }

    returnTotalAmount = () => {
        let totalPrice = this.state.planData.length > 0 ? this.state.planData.reduce((accumulator, currentValue) => { return accumulator + currentValue.total_price }, 0) : 0
        return totalPrice.toFixed(2);
    }

    applyCredit = async (event) => {
        let plan_data = this.state.planData.filter(x => x.count > 0 && x.count !== "")
        let data = {};
        data.planData = plan_data;
        data.organizationId = this.state.organization_id;
        if (plan_data.length > 0) {
            this.props.dispatch(AddSeatsForOrg(this, data))
        } else {
            alert('Plan counts should not be empty')
        }
    };

    render() {
        let result = this.props.organizationDetailyByID;
        // let { styles } = this.props;
        return (
            <div style={{ padding: 20 }}>
                <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", fontSize: 20 }}> Organization Details </Typography>
                {result !== null && result !== undefined &&
                    <Grid container >
                        <Grid item xs={8} style={{ backgroundColor: "gray" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Name</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{result.name}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Email</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{result.email}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Phone Number</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{result.phoneNumber}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Primary Contact</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{result.primaryContactName}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Organization Unique Code</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{result.organizationCode}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Status</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{result.status}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{ fontWeight: "bold", fontFamily: "Avenir-Heavy", }}>Address</Typography>
                                    <Typography style={{ fontFamily: "Avenir-Book" }}>{((result.addressLine1 !== "" && result.addressLine1 !== null) ? result.addressLine1 + "," : "") + ((result.addressLine2 !== "" && result.addressLine2 !== null) ? result.addressLine2 + "," : "") + ((result.city !== "" & result.city !== null) ? result.city + "," : "") + ((result.state !== "" & result.state !== null) ? result.state + "," : "") + ((result.country !== "" & result.country !== null) ? result.country : "") + ((result.zipcode !== "" && result.zipcode !== null) ? "," + result.zipcode : "")}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} >
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => this.setState({ addCreditDialog: true })} size="small" variant="contained" color="primaryButton" style={styles.addButton}>ADD PLAN</Button>
                            </div>
                            <Card style={{ margin: 10, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main},${theme.palette.primary.dark}`, borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 15 }}>
                                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                    <Typography style={{ color: "white", fontWeight: "bold", fontSize: 16 }}>MedFilo Balance</Typography>
                                    <BiWallet style={{ fontSize: 35, color: '#ffffff' }} />
                                </div>
                                <div>
                                    {this.props.organizationPlan.map(item => <div key={item.id.toString()} style={{ display: "flex", justifyContent: 'space-between', marginBottom: 10 }}>
                                        <Typography style={{ color: "#fff", fontSize: 14 }}>+ {item.name} Seats available :</Typography>
                                        <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                            <Typography style={{ color: "#fff", fontSize: 14, paddingRight: 30 }}>{this.planSeatsBalance(item)}</Typography>
                                        </div>
                                    </div>)}
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                }
                <Dialog
                    maxWidth={"sm"}
                    fullWidth
                    open={this.state.addCreditDialog} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main},${theme.palette.primary.dark}`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"Member Details"}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Card style={{ margin: 10, borderRadius: 10, padding: 15, boxShadow: "none", borderWidth: 1, borderColor: "#DAD9DE", borderStyle: "solid" }}>
                            <div style={{ paddingBottom: 15 }}>
                                <Typography style={{ color: theme.palette.primary.main, fontWeight: "bold", fontSize: 16 }}>Add Organziation Seats</Typography>
                            </div>
                            <div>
                                {this.props.organizationPlan.map(item => <div style={{ display: "flex", justifyContent: 'space-between', marginBottom: 10, paddingBottom: 5, borderBottomWidth: 1, borderBottomColor: "#DAD9DE", borderBottomStyle: "solid" }}>
                                    <Typography style={{ color: theme.palette.primary.main, fontSize: 14 }}>Add {item.name} Seat</Typography>
                                    <div style={{ display: "flex", justifyContent: 'space-between', borderLeftWidth: 1, borderLeftColor: "#DAD9DE", borderLeftStyle: "solid", paddingLeft: 15 }}>
                                        <TextField
                                            style={styles.textField}
                                            value={this.returnCount(item)}
                                            onChange={(event) => this.setPlanCount(event, item)}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>)}
                            </div>
                        </Card>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button size="small" onClick={() => this.setState({ addCreditDialog: false, planData: [] })} variant="contained" color="clearButton" style={{ color: '#fff'}}>Close</Button>
                        <Button size="small" onClick={() => this.applyCredit()} variant="contained" color="primaryButton" style={styles.addButton}>Submit</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        organizationDetailyByID: state.organizationReducer.organizationDetailyByID,
        organizationPlan: state.organizationReducer.organizationPlan
    }
}

ViewOrganization.propTypes = {
    styles: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps)(ViewOrganization));
