import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Typography, Grid, Table, TableHead, TableBody, TableCell, TableRow, Dialog, DialogActions, DialogTitle, DialogContent, CircularProgress, IconButton, Tooltip, TextField, Button, styled, Pagination } from "@mui/material";
import { fetchAllTestResults, updateTestByAdmin, searchTestResultByPhone, searchTestResults, rejectTestByAdmin } from "../../actions/testAction";
import moment from "moment";
// import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
// import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { ALERT_DETAIL } from "../../actions/actionTypes";
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import TablePaginationActions from "../Dashboard/TablePaginationActions";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { END_POINT, THEMECOLOR } from "../../helpers/Constants";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import NoImage from '../../assets/images/no-image-available.png'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { headerFont } from '../../helpers/Constants';

/**Created common styles for UI.*/
const styles = {
    // textStyle: {
    //     fontFamily: "Futura-Heavy",
    //     color: "#20478E",
    //     fontSize: 20,
    // },
    inputStyle: {
        fontSize: "15px !important",
        padding: "5px !important",
        width: 300
    },
    inputRoot: {
        borderRadius: "0px !important"
    },
    // inputLabel: {
    //     color: "#4B86E3"
    // },
    button: {
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
        margin: 15,
        padding: "10px 25px",
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        fontWeight: "bold",
        borderRadius: 5,
        // '&:hover': {
        //     backgroundColor: "#4B86E3",
        // },
    },
    clearButton: {
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
        margin: 15,
        padding: "10px 25px",
        fontSize: 12,
        // backgroundColor: "#808080",
        color: "#fff",
        fontWeight: "bold",
        borderRadius: 5,
        '&:hover': {
            backgroundColor: "#808080",
        },
    },
    fontCellHeader: {
        fontFamily: "Futura-Book",
        color: "#fff",
        fontWeight: 900,
        padding: '14px 20px 14px 0px',
    },
    tableHeader: {
        color: "#fff"
    },
    qrContainer: {
        display: 'flex',
        justifyContent: "center",
        padding: 20
    },
    cancelButton: {
        backgroundColor: "#8fa3ad",
        color: "white",
        fontFamily: "unicode.futurab",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(143, 163, 173, 0.8)',
        }
    },
    addButton: {
        backgroundColor: THEMECOLOR.SECONDARY,
        color: "white",
        fontFamily: "unicode.futurab",
        marginRight: "20",
        fontSize: 13,
        fontWeight: "bold",
        // '&:hover': {
        //     backgroundColor: 'rgba(32,71,142, 0.8)',
        // }
    },
    fontCellBody: {
        fontFamily: "Futura-Medium-bt",
        color: "#37474f",
        padding: '14px 20px 14px 0px',
    },
    buttonToolbar: {
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        bottom: "5%",
        margin: 10
    },
};

const CssTextField = styled(TextField)({
    root: {
        '& label.Mui-focused': {
            color: '#4B86E3',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#4B86E3',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#4B86E3',
            },
            '&:hover fieldset': {
                borderColor: '#4B86E3',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#4B86E3',
            },
        },
    },
});

/**
 * @class
 *
 * Class representing user test result component
 *
 */
class AllTestResults extends Component {
    constructor() {
        super();
        this.state = {
            paginationCount: 0,
            phone_number: "",
            rowsPerPage: 10,
            page: 1,
            isConfirmationDialog: false,
            selectedTestId: null,
            isLoading: false,
            sortBy: "DATE_ASC",
            isDialogConfirmation: false,
            selectedTestData: null,
            isRejectDialog: false,
            rejectReason: ""
        }
    };

    /** This is a component life cycle method and used to fetch datas*/
    componentDidMount = () => {
        this.props.dispatch({ type: 'ALL_TEST_RESULTS', data: [] })
        this.props.dispatch(fetchAllTestResults(this, 0))
    };

    handleBackButtonClick = (event) => {
        this.setState({ page: this.state.page - 1 });
    };

    /**
     * @function
     *
     * function representing table pagination.
     * @param {Object} _event
     * @param {Object} newPage
     */
    handleChangePage = (_event, newPage) => {
        this.setState({
            page: newPage
        });
        this.props.dispatch(fetchAllTestResults(this, this.props.all_test_results.length * (newPage - 1)))
    }

    // handleNextButtonClick = (event) => {
    //     const { page, rowsPerPage } = this.state;
    //     if (this.props.all_test_results.length <= (page * rowsPerPage + rowsPerPage)) {
    //         this.props.dispatch(fetchAllTestResults(this.props.all_test_results.length))
    //     } else {
    //         this.setState({ page: this.state.page + 1 });
    //     }
    // }

    submit = () => {
        let mobileNumber = this.state.selectedTestData !== null ? this.state.selectedTestData.clientUserProfile.phone : null;
        this.props.dispatch(updateTestByAdmin(this, this.state.selectedTestId, mobileNumber))
    };

    rejectSubmit = () => {
        let mobileNumber = this.state.selectedTestData !== null ? this.state.selectedTestData.clientUserProfile.phone : null;
        let data = {}
        data.reject_reason = this.state.rejectReason;
        this.props.dispatch(rejectTestByAdmin(this, this.state.selectedTestId, mobileNumber, data))
    };

    search = () => {
        if (this.state.phone_number !== "" && (this.state.phone_number.length === 10 || this.state.phone_number.length === 9)) {
            this.props.dispatch(searchTestResultByPhone(this, this.state.phone_number))
        } else {
            this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Enter valid phone number", severity: "error" })
        }
    };

    clear = () => {
        this.setState({ isLoading: true })
        this.props.dispatch({ type: 'ALL_TEST_RESULTS', data: [] })
        this.setState({ phone_number: "", isLoading: false })
        this.props.dispatch(fetchAllTestResults(this, 0))
    };

    searchByPhone = () => {
        this.props.dispatch({ type: 'ALL_TEST_RESULTS', data: [] })
        if (this.state.phone_number !== null && this.state.phone_number !== "" && (this.state.phone_number.length === 10 || this.state.phone_number.length === 9)) {
            this.setState({ isLoading: true })
            this.props.dispatch(searchTestResults(this.state.phone_number, "MOBILE", this))
        } else {
            this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Enter valid phone number", severity: "error" })
        }
    };

    searchAllUnverified = () => {
        this.props.dispatch({ type: 'ALL_TEST_RESULTS', data: [] })
        this.setState({ isLoading: true, phone_number: "" })
        this.props.dispatch(searchTestResults(null, "ALL", this))
    };

    /**
     * @function
     *
     * function representing table pagination.
     * @param {Object} _event
     * @param {Object} newPage
     */
    // handleChangePage = (_event, newPage) => {
    //     this.setState({
    //         page: newPage
    //     });
    // };

    /**
     * @function
     *
     * function representing table pagination row
     * @param {Object} event
     */
    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0, rowsPerPage: parseInt(event.target.value, 10)
        });
    };

    /** rendering Test Results information. */
    render() {
        // const { test_results_count } = this.props;
        const { selectedTestData } = this.state;
        let test_results = this.props.all_test_results.filter(x => x.displayDate !== null).sort((a, b) => new Date(a.displayDate) - new Date(b.displayDate))
        let result = test_results;
        switch (this.state.sortBy) {
            case "NAME_ASC":
                result = test_results.sort((a, b) => a.clientUserProfile.firstName.localeCompare(b.clientUserProfile.firstName))
                break;
            case "NAME_DSC":
                result = test_results.sort((a, b) => b.clientUserProfile.firstName.localeCompare(a.clientUserProfile.firstName))
                break;
            case "DATE_ASC":
                result = test_results.sort((a, b) => new Date(a.displayDate) - new Date(b.displayDate))
                break;
            case "DATE_DSC":
                result = test_results.sort((a, b) => new Date(b.displayDate) - new Date(a.displayDate))
                break;
            case "C_DATE_ASC":
                result = test_results.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                break;
            case "C_DATE_DSC":
                result = test_results.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                break;
            case "U_DATE_ASC":
                result = test_results.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt))
                break;
            case "U_DATE_DSC":
                result = test_results.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                break;
            default:
                break;
        }
        return (
            <div style={styles.paper}>
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between", margin: "20px 15px" }}>
                        <div>
                            <Typography style={{ color: theme.palette.secondary.light, fontWeight: headerFont.fontWeight, fontFamily: headerFont.fontFamily, fontSize: headerFont.fontSize, }}>Test Results</Typography>
                        </div>
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', justifyContent: "space-between", padding: 20 }}>
                        {/* <div>
                            <TablePagination
                                rowsPerPageOptions={[10]}
                                component="div"
                                count={result.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                slotProps={{
                                    select: {
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                labelRowsPerPage={null}
                                labelDisplayedRows={
                                    ({ from, to, count }) => null
                                }
                                ActionsComponent={
                                    TablePaginationActions
                                }
                            />
                        </div> */}
                        <div style={{ alignItems: 'center', display: 'flex', justifyContent: "space-between" }}>
                            <TextField
                                margin="normal"
                                id="search-mobile"
                                variant="standard"
                                label="Search Phone"
                                name="mobile-number"
                                autoComplete="off"
                                value={this.state.phone_number}
                                onChange={event =>
                                    this.setState({ phone_number: event.target.value.replace(/[^\d]+/g, ''), page: 0 })
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ maxLength: 10 }}
                                InputProps={{ classes: { input: styles.inputStyle, root: styles.inputRoot } }}
                                autoFocus
                            />
                            <Button size="small" variant="contained" color="clearButton" style={{ ...styles.clearButton, marginRight: 27, height: 40 }} onClick={() => this.clear()}>
                                Clear
                            </Button>
                            <Button size="small" variant="contained" color="primary" style={{ ...styles.button, marginRight: 27, height: 40 }} onClick={() => this.searchByPhone()}>
                                Search
                            </Button>
                        </div>
                        <div>
                            <Button size="small" variant="contained" color="primary" style={{ ...styles.button, marginRight: 27, height: 40 }} onClick={() => this.searchAllUnverified()}>
                                Show Unverified Draft Test Results
                            </Button>
                        </div>
                    </div>
                </div>
                {result.length > 0 ?
                    <div style={{ margin: "0px 15px" }}>
                        <div style={styles.root}>
                            {/* <IconButton
                            onClick={this.handleBackButtonClick}
                            disabled={page === 0}
                            aria-label="previous page"
                        >

                            <KeyboardArrowLeft color={"primary"} style={{ fontWeight: 'bold' }} />
                        </IconButton>

                        <Typography variant="caption" color="secondary" style={{ fontWeight: 'bold' }}>
                            {page === 0
                                ? `1 - ${page * rowsPerPage + rowsPerPage} of ${test_results_count}`
                                : `${(page * rowsPerPage) + 1} - ${page * rowsPerPage + rowsPerPage} of ${test_results_count}`
                            }

                        </Typography>

                        <IconButton
                            disabled={this.props.test_results_count <= (page * rowsPerPage + rowsPerPage)}
                            onClick={this.handleNextButtonClick}
                            aria-label="next page"
                        >

                            <KeyboardArrowRight color={"primary"} style={{ fontWeight: 'bold' }} />
                        </IconButton> */}

                        </div>
                        <Table>
                            <TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10 }}>
                                <TableRow>
                                    <TableCell align="center" size="small" style={{ ...styles.fontCellHeader, paddingLeft: 5, display: 'flex', alignItems: "center" }}>
                                        User Info
                                        <div style={{ display: "grid", cursor: "pointer" }}>
                                            <ArrowDropUpIcon onClick={() => this.setState({ sortBy: "NAME_ASC" })} />
                                            <ArrowDropDownIcon onClick={() => this.setState({ sortBy: "NAME_DSC" })} />
                                        </div></TableCell>
                                    <TableCell align="center" size="small" style={styles.fontCellHeader}>Test Type</TableCell>
                                    <TableCell align="center" size="small" style={styles.fontCellHeader}>
                                        <div style={{ display: 'flex', alignItems: "center" }}>Date
                                            <div style={{ display: "grid", cursor: "pointer" }}>
                                                <ArrowDropUpIcon onClick={() => this.setState({ sortBy: "DATE_ASC" })} />
                                                <ArrowDropDownIcon onClick={() => this.setState({ sortBy: "DATE_DSC" })} />
                                            </div>
                                        </div></TableCell>
                                    <TableCell align="center" size="small" style={styles.fontCellHeader}>
                                        <div style={{ display: 'flex', alignItems: "center" }}>Created Date
                                            <div style={{ display: "grid", cursor: "pointer" }}>
                                                <ArrowDropUpIcon onClick={() => this.setState({ sortBy: "C_DATE_ASC" })} />
                                                <ArrowDropDownIcon onClick={() => this.setState({ sortBy: "C_DATE_DSC" })} />
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center" size="small" style={styles.fontCellHeader}>
                                        <div style={{ display: 'flex', alignItems: "center" }}>Updated Date
                                            <div style={{ display: "grid", cursor: "pointer" }}>
                                                <ArrowDropUpIcon onClick={() => this.setState({ sortBy: "U_DATE_ASC" })} />
                                                <ArrowDropDownIcon onClick={() => this.setState({ sortBy: "U_DATE_DSC" })} />
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center" size="small" style={styles.fontCellHeader}>Status</TableCell>
                                    <TableCell align="center" size="small" style={styles.fontCellHeader}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result.length > 0 ? result.map((item) =>
                                    <TableRow key={item.id}>
                                        <TableCell align="center" style={styles.fontCellBody}>
                                            <div style={{ display: "flex" }}>
                                                <div>
                                                    <Typography style={{ fontWeight: "bold", fontSize: 14 }}>{item.clientUserProfile.firstName + " " + item.clientUserProfile.lastName}</Typography>
                                                    <Typography style={{ fontSize: 14 }}>{item.clientUserProfile.phone}</Typography>
                                                </div>
                                            </div></TableCell>
                                        <TableCell align="center" style={styles.fontCellBody}><span style={{ fontWeight: "bold" }}>{item.testType.name}</span> ({item.testType.isVaccine === true ? "Vaccine" : "Test"}) {item.shotNumber !== null ? ("Shot n°" + item.shotNumber) : ""}</TableCell>
                                        <TableCell align="center" style={styles.fontCellBody}>
                                            <div>
                                                <Typography style={{ fontSize: 14 }}>{item.displayDate !== null ? moment(item.displayDate).format("MM/DD/YYYY") : "-"}</Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" style={styles.fontCellBody}>
                                            <div>
                                                <Typography style={{ fontSize: 14 }}>{item.createdAt !== null ? moment(item.createdAt).format("MM/DD/YYYY") : "-"}</Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" style={styles.fontCellBody}>
                                            <div>
                                                <Typography style={{ fontSize: 14 }}>{item.updatedAt !== null ? moment(item.updatedAt).format("MM/DD/YYYY") : "-"}</Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" style={styles.fontCellBody}>
                                            <Typography style={{ fontSize: 14 }}>{item.status}</Typography>

                                        </TableCell>
                                        <TableCell align="center" style={styles.fontCellBody}>
                                            {item.status !== "COMPLETED" &&
                                                <Button size="small" onClick={() => this.setState({ isDialogConfirmation: true, selectedTestId: item.id, selectedTestData: item })} variant="contained" color="secondary" style={styles.addButton} >Verify</Button>
                                            }
                                        </TableCell>
                                    </TableRow>)
                                    :
                                    <TableRow>
                                        <TableCell>There are no test results</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                        <Grid display={'flex'} flexDirection={'row'} justifyContent={'center'} padding={10}>
                            <Pagination
                                count={this.state.paginationCount}
                                color="primary"
                                size="large"
                                page={this.state.page}
                                variant="outlined"
                                shape="rounded"
                                onChange={this.handleChangePage}
                            />
                        </Grid>
                    </div>
                    :
                    <div style={{ display: "flex", justifyContent: "center", paddingTop: 50 }}>
                        {this.state.isLoading ? <CircularProgress />
                            :
                            <Typography style={{ padding: 10, textAlign: "center" }}>Please use search box to locate users test results.</Typography>
                        }
                    </div>
                }

                <Dialog
                    maxWidth={"xs"}
                    open={this.state.isConfirmationDialog}>
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"Confirmation"}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div style={styles.qrContainer}>
                            {`Are you sure, you want to verify this test result ?`}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={() => this.submit()} variant="contained" color="primary" style={styles.viewGreenButton}>Yes</Button>
                        <Button size="small" onClick={() => this.setState({ isConfirmationDialog: false, selectedTestId: null })} variant="contained" color="secondary" style={styles.viewRedButton}>No</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth={"xs"}
                    open={this.state.isRejectDialog} >
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY},${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"Confirmation"}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div style={styles.qrContainer}>
                            {`Are you sure, you want to reject this test result ?`}
                        </div>
                        <div>
                            <CssTextField
                                margin="normal"
                                id="reject"
                                label="Reject Reason"
                                name="reject"
                                autoComplete="off"
                                value={this.state.rejectReason}
                                onChange={event =>
                                    this.setState({ rejectReason: event.target.value })
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ classes: { input: styles.inputStyle, root: styles.inputRoot } }}
                                autoFocus
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" disabled={this.state.rejectReason.length > 0 ? false : true} onClick={() => this.rejectSubmit()} variant="contained" color="primary" style={styles.viewGreenButton}>Yes</Button>
                        <Button size="small" onClick={() => this.setState({ isRejectDialog: false, selectedTestId: null })} variant="contained" color="secondary" style={styles.viewRedButton}>No</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    aria-labelledby="simple-dialog-title"
                    maxWidth="md"
                    fullWidth={true}
                    open={this.state.isDialogConfirmation}>
                    <DialogTitle style={{ backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 0, marginBottom: 20, }}>
                        <div style={styles.dialogTitle}>
                            <Typography style={{ ...styles.dialogTitleText, padding: "1%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>
                                Verify Test
                            </Typography>
                        </div>
                    </DialogTitle>
                    <DialogContent style={{ backgroundColor: "#fff", marginBottom: 20, height: 450 }}>
                        <Grid container spacing={2}>
                            <Grid container style={{ paddingLeft: 20, paddingRight: 20 }}>
                                {
                                    selectedTestData !== null &&
                                    <Grid item xs={5}>
                                        <Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>Name</Typography>
                                        <Typography style={{ fontSize: 15 }}>{selectedTestData.clientUserProfile.firstName + " " + selectedTestData.clientUserProfile.lastName}</Typography>
                                        <Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>Test Type</Typography>
                                        <Typography style={{ fontSize: 15, }}>{selectedTestData.testType.name} ({selectedTestData.testType.isVaccine === true ? "Vaccine" : "Test"}) {selectedTestData.shotNumber !== null ? ("Shot n°" + selectedTestData.shotNumber) : ""}</Typography>
                                        <Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>Date of Test</Typography>
                                        <Typography style={{ fontSize: 15 }}>{selectedTestData.displayDate !== null ? moment(selectedTestData.displayDate).format("MM/DD/YYYY") : "-"}</Typography>
                                        <Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>Date of Birth</Typography>
                                        <Typography style={{ fontSize: 15 }}>{selectedTestData.clientUserProfile.dateOfBirth !== null ? moment(selectedTestData.clientUserProfile.dateOfBirth).format("MM/DD/YYYY") : "-"}</Typography>
                                        {selectedTestData.status === "REJECTED" && <Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, color: "red" }}>Rejected Reason</Typography>}
                                        {selectedTestData.status === "REJECTED" && <Typography style={{ fontSize: 15 }}>{selectedTestData.rejectReason}</Typography>}
                                    </Grid>
                                }
                                <Grid item xs={7}>
                                    <Grid item xs={12} style={{ marginTop: "15px" }}>
                                        <Typography style={{ fontSize: "16", color: "#000", fontWeight: "bold" }}>Document</Typography>
                                    </Grid>
                                    <div style={{ marginBottom: "20px" }}>
                                        {selectedTestData !== null && selectedTestData.storageKey !== null ?
                                            <div>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                            <div className="tools" style={{ justifyContent: "space-between", display: "flex", width: "50%" }}>
                                                                <div className="tools" style={{ display: "flex" }}>
                                                                    <Tooltip title="Zoom In" placement="bottom-end">
                                                                        <IconButton onClick={() => zoomIn(1, 300, 'easeOut')} size="small" style={{ margin: 1, padding: 2, borderRadius: 1 }}><AddCircleIcon color="primary" /></IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Zoom Out" placement="bottom-end">
                                                                        <IconButton onClick={() => zoomOut(1, 300, 'easeOut')} size="small" style={{ margin: 1, padding: 2, borderRadius: 1 }}><RemoveCircleIcon color="secondary" /></IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                                <Tooltip title="Reset" placement="bottom-end">
                                                                    <IconButton onClick={() => resetTransform(300, 'easeOut')} size="small" style={{ margin: 1, padding: 2, borderRadius: 1 }}><CancelIcon /></IconButton>
                                                                </Tooltip>
                                                            </div>
                                                            <TransformComponent>
                                                                <img alt="document"
                                                                    src={`${END_POINT}image/${selectedTestData.testType.isVaccine === true ? "v" : "t"}/${selectedTestData.storageKey}`}
                                                                    style={{
                                                                        height: 350, width: "100%", objectFit: "cover", border: "1px solid #ddd",
                                                                        borderRadius: "4px",
                                                                    }}
                                                                />
                                                            </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            : <img alt="document"
                                                src={NoImage}
                                                style={{
                                                    height: "100%", width: "100%", border: "1px solid #ddd",
                                                    borderRadius: "4px",
                                                    padding: "8px"
                                                }}
                                            />
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Grid item xs={12}>
                        {selectedTestData !== null && <div style={styles.buttonToolbar}>
                            <Button size="small" disabled={selectedTestData.status === "REJECTED"} onClick={() => this.setState({ isRejectDialog: true, selectedTestId: selectedTestData.id })} variant="contained" color="secondary" style={{ ...styles.viewGreenButton, marginLeft: 3, background: selectedTestData.status === "REJECTED" ? "#E0E0E0" : "#FF0000" }}>Reject</Button>
                            <Button size="small" disabled={selectedTestData.status === "REJECTED"} onClick={() => this.setState({ isConfirmationDialog: true, selectedTestId: selectedTestData.id })} variant="contained" color="secondary" style={{ ...styles.viewGreenButton, marginLeft: 3, background: selectedTestData.status === "REJECTED" ? "#E0E0E0" : THEMECOLOR.SECONDARY }}>Verify</Button>
                            <Button size="small" onClick={() => this.setState({ isDialogConfirmation: false, selectedTestId: null, selectedTestData: null })} variant="contained" color="secondary" style={{ marginLeft: 3, background: "#808080" }} >Cancel</Button>
                        </div>}
                    </Grid>
                </Dialog>
            </div >
        );
    }
}

/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
    return {
        all_test_results: state.testReducer.all_test_results,
        test_results_count: state.testReducer.test_results_count
    };
};

AllTestResults.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(AllTestResults));