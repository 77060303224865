import {
    Badge, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableHead, TablePagination,
    TableRow, TextField, Toolbar, Typography, styled
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from "react";
import { connect } from 'react-redux';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { addDonorCredits, fetchDonor, updateDonor } from '../../actions/donorAction';
import { fetchOrganization } from '../../actions/organizationAction';
import { fetchSettingByCode } from '../../actions/settingsAction';
import { fetchUser } from '../../actions/userAction';
import TablePaginationActions from "../Dashboard/TablePaginationActions";
import { headerFont } from "../../helpers/Constants";
import theme from "../../theme";
import { withRouter } from "../../withRouter";

/** Created common styles for UI  */
const styles = {
    textStyle: {
        fontFamily: "Futura-Heavy",
        color: "#37474f",
        fontSize: 20
    },
    creditTextStyle: {
        fontFamily: "Futura-Heavy",
        color: "#37474f",
        fontSize: 15,
        paddingTop: 10
    },
    addButton: {
        backgroundColor: "#20478e",
        color: "white",
        fontFamily: "unicode.futurab",
        borderRadius: "10",
    },
    cancelButton: {
        backgroundColor: "#8fa3ad",
        color: "white",
        fontFamily: "unicode.futurab",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(143, 163, 173, 0.8)',
        }
    },
    editButton: {
        backgroundColor: "#8fa3ad",
        color: "white",
        fontFamily: "unicode.futurab",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(143, 163, 173, 0.8)',
        }
    },
    fontCellHeader: {
        fontFamily: "Futura-Book",
        color: "#fff",
        fontWeight: 900,
        padding: '14px 20px 14px 0px',

    },
    fontTableCellBody: {
        fontFamily: "Futura-Medium-bt",
        color: "#37474f",
        padding: '14px 20px 14px 10px',
    },
    inputStyle: {
        fontFamily: "Futura-Book",
        fontSize: 14
    },
    labelStyle: {
        fontFamily: "Futura-Book",
        fontSize: 14
    },
    inputStyleForSearchText: {
        fontSize: "15px !important",
        padding: "5px !important",
        width: 250,
    },
    inputRoot: {
        borderRadius: "0px !important"
    },
    inputLabel: {
        color: "#4B86E3"
    },
    dialogPaper: {
        backgroundColor: "rgba(0,0,0,0)",
        boxShadow: "none",
        overflow: "hidden"
    }
};

const CssTextField = styled(TextField)({
    root: {
        '& label.Mui-focused': {
            color: '#4B86E3',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#4B86E3',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#4B86E3',
            },
            '&:hover fieldset': {
                borderColor: '#4B86E3',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#4B86E3',
            },
        },
    },
});

/**
 * @class
 *
 * Class representing Donor component
 *
 */
class Donor extends Component {

    constructor() {
        super();
        this.state = {
            dialogOpen: false,
            available_credits: 0,
            credits: null,
            credits_error: false,
            invalid_credits_error: false,
            selectedOrganization: {},
            fetch_user: {},
            user_profile: null,
            dialogOpenForAddCredits: false,
            search_text: "",
            rowsPerPage: 10,
            page: 0,
            isLoading: false,
        }
    }

    /** This is a component life cycle method and used to assigning props values to state variable  */
    static getDerivedStateFromProps(props, state) {
        if (props.fetch_user !== state.fetch_user) {
            return {
                available_credits: props.fetch_user.donorAvailableCredits,
                fetch_user: props.fetch_user
            }
        }
        return null;
    };

    componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        this.sumitAddcredits(query);
        /** fetching Organizations details. */
        this.props.dispatch(fetchOrganization())
        /** fetching Donor details. */
        this.props.dispatch(fetchDonor())
        /** fetching Users details. */
        this.props.dispatch(fetchUser())
        /** fetching Setting details. */
        this.props.dispatch(fetchSettingByCode("PUS"))
    };

    creditCoupons = () => {
        this.props.navigate('/generate-credit-coupons');
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0, rowsPerPage: parseInt(event.target.value, 10)
        });
    };

    handleClick = async (event) => {
        // const stripe = await stripePromise;
        // let data = {};
        // data.credits = parseInt(this.state.credits);
        // data.price_per_credit = 1;

        // if (parseInt(this.state.credits) > 0) {
        //     const response = await api.post(`user-profile/donor/create-payment-session/${this.props.fetch_user.id}`, data);

        //     if (response.status === "success") {
        //         // When the customer clicks on the button, redirect them to Checkout.
        //         let session = response.payload;
        //         const result = await stripe.redirectToCheckout({
        //             sessionId: session.id,
        //         });

        //         if (result.error) {
        //             // If `redirectToCheckout` fails due to a browser or network
        //             // error, display the localized error message to your customer
        //             // using `result.error.message`.
        //         }
        //     }
        // } else {
        //     alert('Credits should not be empty')
        // }

        // const response = await fetch("/create-checkout-session", {
        //   method: "POST",
        // });

        // const session = await response.json();
    };

    handleClose = () => {
        this.setState({
            dialogOpen: false,
            dialogOpenForAddCredits: false,
            credits: "",
            credits_error: false,
            invalid_credits_error: false
        })
    };

    handleChange = (organization) => {
        this.setState({ selectedOrganization: organization, dialogOpen: true });
    };

    /**
   * @function
   * This function representing  add Credits to organizations .
   */
    onSumitcredits = (organization) => {
        let isError = false;

        if (this.state.credits === "" || this.state.credits === null) {
            this.setState({ credits_error: true })
            isError = true;
        }
        if (this.state.available_credits < this.state.credits) {
            this.setState({ invalid_credits_error: true })
            isError = true;
        }
        if (isError === false) {
            let data = {};
            data.organizationId = organization.id;
            data.phoneNumber = organization.phoneNumber;
            data.credits = parseInt(this.state.credits);
            this.setState({ isLoading: true })
            this.props.dispatch(updateDonor(this, data, this.props.fetch_user.id))
        }
    };

    /**
   * @function
   * This function representing  add Credits to Donor .
   */
    /*
     sumitAddcredits = () => {
         let isError = false;
         if (this.state.credits === "" || this.state.credits === null) {
             this.setState({ credits_error: true })
             isError = true;
         }
 
         if (isError === false) {
             let data = {};
             data.credits = parseInt(this.state.credits);
             this.setState({ isLoading: true })
             this.props.dispatch(updateDonorAddCredits(this, data, this.props.fetch_user.id))
         }
 
     }
     */
    sumitAddcredits = (query) => {
        let session_id = query.get("session_id");
        let successfulPayment = query.get("success");
        let cancelledPayment = query.get("cancel");

        let paymentStatus = successfulPayment !== null ? ((successfulPayment === true || successfulPayment === "true") ? 'success' : 'cancel') : 'cancel';

        // console.log(`${successfulPayment} ===== ${cancelledPayment} ===== ${session_id}`);
        if ((successfulPayment !== null || cancelledPayment !== null) && session_id !== null && session_id !== "") {
            this.props.dispatch(addDonorCredits(this, {}, session_id, paymentStatus))
        }
        // this.props.dispatch(addDonorCredits(this, {}, session_id, paymentStatus));
    };

    /**
    * @function
    * This function representing  add Credits dialog screnn wwill open .
    */
    onSumitAddcredits = () => {
        this.setState({ dialogOpenForAddCredits: true })
    };

    /** rendering DonorDashboard information. */
    render() {
        const { page, rowsPerPage } = this.state;
        let organizations = this.props.organization.sort((a, b) => a.id - b.id)
        let result = this.state.search_text !== '' ? organizations.filter(x => (x.name).toString().toLowerCase().includes((this.state.search_text).toString().toLowerCase())) : organizations
        return (
            <div style={{ paddingLeft: 2, paddingRight: 10 }}>
                <Grid container spacing={0} style={{ marginTop: 5 }}>
                    <Grid item xs={12} sm={6}>
                        <Card style={{ marginTop: 10, width: 200, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 15 }}>
                            <Typography style={{ color: "white", fontSize: 11 }}>AVAILABLE CREDITS</Typography>
                            <Typography style={{ color: "white", fontSize: 22, fontWeight: "bold", paddingTop: 8 }}>{this.state.available_credits !== null ? this.state.available_credits : 0}</Typography>
                            <div style={{ textAlign: "end", marginTop: "-50px" }}>
                                <AccountBalanceWalletIcon style={{ color: "#cfd4d6", fontSize: "50px" }} />
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div style={{ display: "flex", justifyContent: "flex-end", padding: "0px 20px" }}>
                            {this.props.premiumUserSupporting !== null && this.props.premiumUserSupporting.value === 'true' && <Button size="small" variant="contained" color="primary" style={{ ...styles.addButton, margin: 10 }} onClick={() => this.onSumitAddcredits()}>
                                Buy Credits
                            </Button>}
                            {/* {this.props.premiumUserSupporting !== null && this.props.premiumUserSupporting.value === 'true' && <Button size="small" variant="contained" color="primary" style={{ ...styles.addButton, margin: 10 }} onClick={() => this.creditCoupons()}>
                                Generate Credit Coupons
                            </Button>} */}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
                            <div>
                                <Typography style={{ color: theme.palette.secondary.light, fontWeight: headerFont.fontWeight, fontFamily: headerFont.fontFamily, fontSize: headerFont.fontSize }}>Organizations</Typography>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[20]}
                                component="div"
                                count={result.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                slotProps={{
                                    select: {
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                labelRowsPerPage={null}
                                labelDisplayedRows={
                                    ({ from, to, count }) => null
                                }
                                ActionsComponent={
                                    TablePaginationActions
                                }
                            />
                            <CssTextField
                                margin="normal"
                                id="search-text"
                                label="Search"
                                placeholder="Name"
                                name="mobile-number"
                                autoComplete="off"
                                value={this.state.search_text}
                                onChange={event =>
                                    this.setState({ search_text: event.target.value, page: 0 })
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ classes: { input: styles.inputStyleForSearchText, root: styles.inputRoot, inputlabel: styles.inputLabel } }}
                                autoFocus
                            />
                        </Toolbar>
                    </Grid>
                    <Grid item xs={8} sm={12} style={{ paddingTop: 10 }}>
                        <Table>
                            <TableHead style={{ backgroundColor: theme.palette.secondary.dark }}>
                                <TableRow>
                                    <TableCell align="center" style={{ fontFamily: "Futura-Book", color: ' #fff', fontWeight: 900, padding: '14px 0px 14px 20px', display: "flex", justifyContent: "center", alignItems: "center" }}>Name</TableCell>
                                    <TableCell align="center" style={styles.fontCellHeader}>Type</TableCell>
                                    <TableCell align="center" style={styles.fontCellHeader}>Actions</TableCell></TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    result.length > 0 ? result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) =>
                                        <TableRow key={item.id}>
                                            <TableCell align="center" style={{ ...styles.fontTableCellBody, paddingLeft: 5 }}>
                                                <Typography style={{ fontWeight: "bold", fontSize: 15 }}> {item.name}</Typography>
                                                <Typography style={{ fontSize: 12 }}>{item.addressLine1 + "," + item.addressLine2 + "," + item.city + "," + item.state + "," + item.country + "," + item.zipcode}</Typography>
                                            </TableCell>
                                            <TableCell align="center" style={styles.fontTableCellBody}>
                                                <Badge color="primary" badgeContent={item.organizationType}></Badge>
                                            </TableCell>
                                            <TableCell align="center" style={styles.fontTableCellBody}>
                                                {this.props.premiumUserSupporting !== null && this.props.premiumUserSupporting.value === 'true' &&
                                                    <Button size="small" variant="contained" color="primary" style={{ ...styles.addButton, marginLeft: 10 }} onClick={() => this.handleChange(item)}>
                                                        Apply Credits
                                                    </Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                        :
                                        <TableRow>
                                            <TableCell colSpan={3} style={{ ...styles.fontTableCellBody, textAlign: "center" }}>There are no organizations</TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Dialog
                    maxWidth={"xs"}
                    open={this.state.dialogOpen}>
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"APPLY CREDITS"}</Typography>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "hidden" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Card style={{ margin: 10, width: 200, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 15 }}>
                                <Typography style={{ color: "white", fontSize: 11 }}>AVAILABLE CREDITS</Typography>
                                <Typography style={{ color: "white", fontSize: 22, fontWeight: "bold", paddingTop: 8 }}>{this.state.available_credits !== null ? this.state.available_credits : 0}</Typography>
                                <div style={{ textAlign: "end", marginTop: "-50px" }}>
                                    <AccountBalanceWalletIcon style={{ color: "#cfd4d6", fontSize: "50px" }} />
                                </div>
                            </Card>
                        </div>
                        <TextField
                            id="credits"
                            label="Credits"
                            name="credits"
                            style={styles.margin}
                            value={this.state.credits}
                            onChange={(event) => event.target.value.match(/^\d*$/g) && this.setState({ credits: event.target.value, credits_error: false, invalid_credits_error: false })}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                classes: { input: styles.inputStyle }
                            }}
                            autoComplete="off"
                            fullWidth
                            error={this.state.credits_error === true ? true : this.state.invalid_credits_error === true ? true : false}
                            helperText={this.state.credits_error === true ? "Please enter credits" : this.state.invalid_credits_error === true ? "credits should not be greater than donor available credits" : false}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={() => this.onSumitcredits(this.state.selectedOrganization)} variant="contained" color="primary" style={styles.addButton}>Submit</Button>
                        <Button size="small" onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth={"xs"}
                    open={this.state.dialogOpenForAddCredits}>
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"BUY CREDITS"}</Typography>
                    </DialogTitle>
                    <DialogContent style={{ overflowY: "hidden" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Card style={{ margin: 10, width: 200, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 15 }}>
                                <Typography style={{ color: "white", fontSize: 11 }}>AVAILABLE CREDITS</Typography>
                                <Typography style={{ color: "white", fontSize: 22, fontWeight: "bold", paddingTop: 8 }}>{this.state.available_credits !== null ? this.state.available_credits : 0}</Typography>
                                <div style={{ textAlign: "end", marginTop: "-50px" }}>
                                    <AccountBalanceWalletIcon style={{ color: "#cfd4d6", fontSize: "50px" }} />
                                </div>
                            </Card>
                        </div>
                        <TextField
                            id="credits"
                            label="Buy Credits"
                            name="credits"
                            style={styles.margin}
                            value={this.state.credits}
                            onChange={(event) => event.target.value.match(/^\d*$/g) && this.setState({ credits: event.target.value, credits_error: false, invalid_credits_error: false })}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                classes: { input: styles.inputStyle }
                            }}
                            autoComplete="off"
                            fullWidth
                            error={this.state.credits_error === true ? true : this.state.invalid_credits_error === true ? true : false}
                            helperText={this.state.credits_error === true ? "Please enter your buy credits" : this.state.invalid_credits_error === true ? "credits should not be greater than donor available credits" : false}
                        />
                    </DialogContent>
                    <DialogActions>
                        {/* <Button size="small" onClick={() => this.sumitAddcredits()} variant="contained" color="primary" style={styles.addButton}>Submit</Button> */}
                        <Button size="small" onClick={(e) => this.handleClick(e)} variant="contained" color="primary" style={styles.addButton}>Checkout</Button>
                        <Button size="small" onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.isLoading}
                    classes={{
                        paper: styles.dialogPaper,
                    }}
                >
                    <div style={{ boxShadow: 0, overflow: "none" }}>
                        <CircularProgress color="primary" size={40} />
                    </div>
                </Dialog>
            </div >
        )
    }
}
/** Get data from store and assign to props */
const mapStateToProps = (state) => {
    return {
        organization: state.organizationReducer.organization,
        fetch_user: state.userReducer.fetch_user,
        premiumUserSupporting: state.settingsReducer.premiumUserSupporting
    }
}
Donor.propTypes = {
    dispatch: PropTypes.func.isRequired,
};
export default withRouter(connect(mapStateToProps)(Donor));