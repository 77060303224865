import React, { Component } from "react";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import {  Grid } from "@mui/material";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default class PrivacyPolicy extends Component {
    constructor() {
        super();
        this.state = {
        }
    }
    render() {
        return (
            <Grid container justifyContent="center" >
                <Grid item xs={12} style={{ display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{
                        display: "flex", border: '1px solid rgba(0, 0, 0, 0.3)',
                        width: 'auto'
                    }}>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                            <Viewer
                                fileUrl="https://cors-anywhere.herokuapp.com/https://www.confirmd.io/2020-10-14-buddycheque-terms-of-services/"
                                defaultScale={window.screen.width / 700}
                            />
                        </Worker>
                    </div>
                </Grid>
            </Grid>
        )
    }
}

