import React from "react";
import { Grid, Typography, Dialog, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
// import checkCircle from "../../assets/images/check-circle.svg";
import checkColorCircle from "../../assets/images/check-color-circle.svg";
import DoneIcon from '@mui/icons-material/Done';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { SELECTED_NEW_USER_TYPE } from "../../actions/actionTypes";
import { withRouter } from "../../withRouter";
import theme from '../../theme';
import { THEMECOLOR } from "../../helpers/Constants";

const styles = {
  root: {
    // backgroundColor: "#F5FFFB",
    backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY},${THEMECOLOR.PRIMARY})`,
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
    },
  },
  // paper: {
  //   marginTop: theme.spacing(0),
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  // titleHead: {
  //   fontSize: 20,
  //   fontWeight: "bold",
  //   textAlign: "center",
  //   padding: 5,
  // },
  uncheckedCircle: {
    border: `2px solid #B7BDBA`,
    backgroundColor: "#FFFFFF",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    marginTop: "10px"
  },
  checkedCircle: {
    backgroundColor: theme.palette.primary.main,
    width: "50px",
    height: "50px",
    borderRadius: "50%",
  },
  centerModalSecondText: {
    fontSize: 18,
    paddingTop: 10,
    color: "#000",
    height: "60%",
  },
  dismissButton: {
    margin: 10,
    width: 200,
    height: 40,
    justifyContent: "center",
    borderRadius: 5,
    borderColor: "#1CBFD4",
    backgroundColor: theme.palette.primary.main,
    elevation: 10,
    alignSelf: "center",
  },
  dismissText: {
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
  },
  dialogTitleText: {
    fontSize: 25,
    textAlign: "center",
    textTransform: "capitalize",
  },
  dialogAction: {
    alignSelf: "center",
    justifyContent: "center",
  },
};

class ProfessionalRegistration extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedOption: null,
      healthcareDialog: false,
      administratorDialog: false,
    };
  }

  handleSelect = (value, code) => {
    this.props.dispatch({ type: SELECTED_NEW_USER_TYPE, data: code });
    this.setState({ selectedOption: value });
  };
  selectOption = () => {
    if (this.state.selectedOption !== null) {
      this.props.navigate("/professional-document");
    }
  };

  render() {
    return (
      <div style={styles.root}>
        <Grid container justifyContent="center">
          <Grid item xs={11} sm={3}>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  margin: "50px 0 5px 0",
                  textAlign: "center",
                  borderRadius: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.1)"
                }}
              >
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "8px",
                    backgroundColor: THEMECOLOR.SECONDARY,
                    marginTop: "-30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                    // position: "absolute",
                  }}
                >
                  <DoneIcon style={{ height: 55, width: 55, color: "#fff" }} />
                  {/* <img src={checkCircle} alt="circle" style={{ width: "60px", height: "auto", color: "white" }} /> */}
                </div>
                <Typography style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px" }}>
                  User Selection
                </Typography>
                <Typography style={{ textAlign: "center", padding: "0px 0px 20px 0px", color: "#6E7973" }}>
                  Please select the profile that best describes your user type.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ backgroundColor: "#fff", margin: "10px 0 10px 0", textAlign: "center", borderRadius: "5px", boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.1)" }} >
                <Grid container spacing={1}>
                  <Grid item xs={2} style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                    {this.state.selectedOption === "Medical Service Providers" ? (
                      <img
                        src={checkColorCircle}
                        alt="checkColorCircle"
                        style={{ width: "30px", height: "auto", color: "white" }}
                      />
                    ) : (
                      <div
                        style={styles.uncheckedCircle}
                        onClick={() => this.handleSelect("Medical Service Providers", "MSP")}
                      />
                    )}
                  </Grid>
                  <Grid item xs={10} style={{ textAlign: "left" }}>
                    <Typography style={{ color: "#000", fontWeight: "bold", fontSize: "16px" }}>Medical Service Providers</Typography>
                    <Typography style={{ color: "#6E7973", fontSize: "14px" }}>
                      Use MedFilo, to validate patients
                    </Typography>
                    <Typography onClick={() => this.setState({ healthcareDialog: true })} style={{ color: theme.palette.primary.main, cursor: "pointer", margin: "10px 0px 10px 0px", fontWeight: "bold" }}>
                      Learn More
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ backgroundColor: "#fff", margin: "10px 0 10px 0", textAlign: "center", borderRadius: "5px", boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.1)" }} >
                <Grid container spacing={1}>
                  <Grid item xs={2} style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                    {this.state.selectedOption === "Verifier" ? (
                      <img
                        src={checkColorCircle}
                        alt="checkColorCircle"
                        style={{ width: "30px", height: "auto", color: "white" }}
                      />
                    ) : (
                      <div
                        style={styles.uncheckedCircle}
                        onClick={() => this.handleSelect("Verifier", "VFR")}
                      />
                    )}
                  </Grid>
                  <Grid item xs={10} style={{ textAlign: "left" }}>
                    <Typography style={{ color: "#000", fontWeight: "bold", fontSize: "16px" }}>Verifier</Typography>
                    <Typography style={{ color: "#6E7973", fontSize: "14px" }}>
                      Use MedFilo, to validate patients
                    </Typography>
                    <Typography
                      onClick={() => this.setState({ administratorDialog: true })}
                      style={{ color: theme.palette.primary.main, cursor: "pointer", margin: "10px 0px 10px 0px", fontWeight: "bold" }}
                    >
                      Learn More
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ backgroundColor: "#35A673", textAlign: "center", borderRadius: "5px", marginTop: "5vh" }}>
              <Typography
                onClick={() => this.selectOption()}
                style={{
                  color: "#FFFFFF",
                  fontSize: "18px",
                  padding: "10px",
                  cursor: "pointer",
                  textTransform: "uppercase"
                }}
              >
                continue as {this.state.selectedOption !== null ? this.state.selectedOption : "(SELECTED OPTION)"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.healthcareDialog}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={() => this.setState({ healthcareDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography style={styles.dialogTitleText}>Medical Service Providers</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography style={styles.centerModalSecondText}>
              With MedFilo, you can validate your patients' test results and vaccination records, resulting in improved
              access to important healthcare information and meaningful healthcare analytics.
            </Typography>
          </DialogContent>
          <DialogActions style={styles.dialogAction}>
            <Button type="Button" fullWidth variant="contained" style={styles.dismissButton} onClick={() => this.setState({ healthcareDialog: false })}>
              <Typography style={styles.dismissText} >
                Dismiss
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.administratorDialog}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={() => this.setState({ administratorDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography style={styles.dialogTitleText}>Verifier</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography style={styles.centerModalSecondText}>
              With MedFilo, Enterprises can authenticate visitors' up-to-date health information to grant access to
              their facilities, helping to protect guests, employees, and associates. By leveraging health data,
              enterprises can make informed decisions on both admittance and workflow.
            </Typography>
          </DialogContent>
          <DialogActions style={styles.dialogAction}>
            <Button type="Button" fullWidth variant="contained" style={styles.dismissButton} onClick={() => this.setState({ administratorDialog: false })}>
              <Typography style={styles.dismissText} >
                Dismiss
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </div >
    )
  }
};

ProfessionalRegistration.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user_profile: state.userReducer.user_profile,
  };
};

export default withRouter(connect(mapStateToProps)(ProfessionalRegistration));
