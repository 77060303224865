import React, { Component } from "react";
import {
	Typography, Grid, Button,
	// List, ListItemButton, ListItemText,
	DialogTitle, Dialog, DialogContent, Badge, IconButton
} from "@mui/material";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { fetchTestResults, fetchTestResultsValues, fetchUnverifiedRecord } from "../../actions/testAction";
import { fetchTesttype, fetchAlreadyVaccinated, fetchForTestTypes, fetchForVaccine } from '../../actions/testtypeAction';
import { fetchUserProfile } from "../../actions/userAction";
import { END_POINT, THEMECOLOR } from "../../helpers/Constants";
import moment from 'moment';
import { fetchVaccineWithoutBooster } from '../../actions/vaccineAction';
// import { fetchVaccine } from '../../actions/vaccineAction';
import { SELECTED_TEST_TYPE } from "../../actions/actionTypes";
import User from "../../assets/images/account_circle.png"
import Covid from "../../assets/images/covid.png"
import Antibody from "../../assets/images/antibody.png"
import Vaccination from "../../assets/images/vaccination.png"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import CancelIcon from '@mui/icons-material/Cancel';
import { saveAs } from 'file-saver'
import { withRouter } from "../../withRouter";
import theme from "../../theme";

/** Created common styles for UI  */
const styles = {
	paper: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
		height: '115vh',
		padding: 20,
		paddingBottom: 100
	},
	container: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundColor: "#FFF",
		padding: 10,
		marginTop: 10,
		borderRadius: 10,
		borderTopLeftRadius: 15,
		borderTopRightRadius: 15,
		border: `2px solid ${THEMECOLOR.PRIMARY}`
	},
	buttonContainer: {
		padding: 10,
		borderRadius: 10,
		borderTopLeftRadius: 15,
		borderTopRightRadius: 15,
	},
	row: {
		display: "flex",
		justifyContent: 'center'
	},
	userImage: {
		width: "100%",
		height: "100%",
		borderRadius: 15,
		objectFit: "cover"
	},
	typeText: {
		fontSize: 15,
		color: "#fff",
		paddingLeft: 10
	},
	nameText: {
		fontSize: 17,
		paddingTop: 5,
		paddingBottom: 10,
		paddingLeft: 15,
		color: "#FFFFFF",
		fontWeight: "bold"
	},
	divider: {
		width: "100%",
		height: 1,
		backgroundColor: "#D1CFCF"
	},
	innerContainer: {
		padding: 10,
	},
	qrContainer: {
		display: 'flex',
		justifyContent: "center",
		padding: 20
	},
	titleText: {
		fontSize: 20,
		fontWeight: "bold",
		color: '#000',
		padding: 10
	},
	moreInformation: {
		borderBottomWidth: 1,
		borderBottomStyle: "solid",
		borderBottomColor: "#D4D2CA",
		display: "flex",
		flexDirection: "row",
		justifyContent: 'space-between'
	},
	subTitleLight: {
		fontSize: 13,
		color: "#96A1C4",
		padding: 10,
		textTransform: 'capitalize'
	},
	button: {
		marginTop: 10,
		marginBottom: 10,
		padding: 15,
		borderRadius: 10,
		fontSize: 15,
		fontWeight: "bold",
		backgroundColor: '#fff',
		textTransform: 'capitalize',
		border: `2px solid ${THEMECOLOR.PRIMARY}`
	},
	button1: {
		padding: 15,
		borderRadius: 10,
		fontSize: 15,
		fontWeight: "bold",
		backgroundColor: '#5186E7',
		textTransform: 'capitalize',
		margin: theme.spacing.unit * 2,
		width: '100%'
	},
	panelButton: {
		paddingVertical: 10,
		borderBottomColor: "#D4D2CA",
		borderBottomWidth: 1,
		borderStyle: "solid"
	},
	panelTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: THEMECOLOR.SECONDARY
	},
	panelText: {
		fontSize: 13,
		color: "#000",
	},
	lineStyle: {
		height: 3,
		width: 120,
		background: "#727272",
	},
	divStyle: {
		display: 'flex',
		padding: 5,
		justifyContent: 'center'
	},
	testText: {
		fontSize: window.screen.width >= 1280 ? 16 : 14,
		color: "#96A1C4"
	},
	dateText: {
		fontSize: window.screen.width >= 1280 ? 15 : 13,
		color: "#000"
	},
	imageView: {
		borderBottomLeftRadius: 15,
		borderBottomRightRadius: 15,
		borderTopLeftRadius: 15,
		border: `5px solid ${THEMECOLOR.PRIMARY}`,
		width: "80px",
		height: "80px",
		backgroundColor: THEMECOLOR.PRIMARY,
	},
	titleView: {
		marginBottom: 5,
		backgroundColor: THEMECOLOR.PRIMARY,
		borderBottomRightRadius: 15,
		borderTopRightRadius: 15,
		border: `5px solid ${THEMECOLOR.PRIMARY}`
	},
	lastDivider: {
		height: 0.2,
		backgroundColor: "#D1CFCF"
	},
	buttonView: {
		display: 'flex',
		width: '100%',
		height: "50px"
	},
	deleteText: {
		cursor: "pointer",
		textAlign: "center",
		fontSize: 16,
		// color: "#3498db",
		color: "#000",
		textTransform: "Capitalize",
		padding: "3px 15px"
	},
	closeIcon: {
		padding: 5,
		color: "#B22222",
		backgroundColor: "#ffffff",
		'&:hover': {
			backgroundColor: "#ffffff",
		}
	},
	verticalDivider: {
		marginTop: 5,
		width: 1,
		// height: "100%",
		backgroundColor: "#D1CFCF",
		marginBottom: 5,
	},
	continueText: {
		color: THEMECOLOR.SECONDARY,
		fontWeight: "bold",
		textTransform: "uppercase"
	}
};

/**
 * @class
 *
 * Class representing MyHealth component
 *
 */
class MyHealth extends Component {
	constructor() {
		super();
		this.state = {
			openTestList: false,
			testtypes: [],
			isConfirmationDialog: false,
			imageDialog: false,
			selectedTest: null,
			imageUrl: null
		}
	}

	/** This is a component life cycle method and used to fetch datas  */
	componentDidMount() {
		/** fetching Vaccine details. */
		this.props.dispatch(fetchVaccineWithoutBooster());
		/** fetching user profile details. */
		this.props.dispatch(fetchUserProfile(this));
		/** fetching TestResults details. */
		this.props.dispatch(fetchTestResults());
		/** fetching all TestResults details. */
		this.props.dispatch(fetchTestResultsValues());
		/** fetching Testtype details. */
		this.props.dispatch(fetchTesttype());
		this.props.dispatch(fetchForTestTypes());
		this.props.dispatch(fetchForVaccine());
	};

	/**
	*@function
	* This function representing return vaccine result.
	* @param {Object} test The test json object
	* @param {Object} classes
	*/
	customVaccineResult = (test, styles) => {
		let vaccine = (this.props.allVaccine !== null && test.vaccinResult !== null) ? this.props.allVaccine.find(x => test.vaccinResult.filter(y => y.vaccineId === x.id && y.status === "COMPLETED").length > 0) : null
		return (
			<div>
				{test.booster !== undefined ?
					<Typography style={{ fontSize: 15, fontWeight: "600", color: "#000000" }}>{(vaccine !== null && vaccine !== undefined) ? vaccine.vaccineShots.length === test.shotNumber ? `Vaccinated${test.booster !== 0 ? ` + ${test.booster} Booster` : ''}` : "Partially Vaccinated" : "-"}</Typography>
					:
					<Typography style={{ fontSize: 15, fontWeight: "600", color: "#000000" }}>{(vaccine !== null && vaccine !== undefined) ? vaccine.vaccineShots.length === test.shotNumber ? "Vaccinated" : "Partially Vaccinated" : "-"}</Typography>
				}
				{
					(test.boosterDate !== undefined && test.boosterDate !== null) ?
						<Typography style={styles.dateText}>Vaccination Date: {test.boosterDate !== null ? `${moment.utc(test.boosterDate, 'YYYY-MM-DD').format('MMM DD, YYYY')}` : ""}</Typography>
						:
						<Typography style={styles.dateText}>Vaccination Date: {(test.vaccinResult.length > 0 && test.vaccinResult[0].vaccinationDate !== null) ? `${moment.utc(test.vaccinResult[0].vaccinationDate, 'YYYY-MM-DD').format('MMM DD, YYYY')}` : ""}</Typography>
				}
			</div>
		)
	};

	/**
	* @function
	*
	* function representing open test type dialog.
	* @param {string} type
	*/

	openRecord = (type) => {
		let filterTesttypes = [];
		if (type === "T") {
			filterTesttypes = !!this.props.testTypeRecords ? this.props.testTypeRecords : []
		} else {
			filterTesttypes = !!this.props.vaccineRecords ? this.props.vaccineRecords : []
		}
		this.setState({
			openTestList: true,
			testtypes: filterTesttypes
		})
	};

	/**
	* @function
	*
	* function representing Upload Test result.
	* @param {Object} testType The testType json object
	*/
	handleTestUpload = (testType) => {
		this.setState({ openTestList: false })
		if (testType.isVaccine === true) {
			let clientID = this.props.user_profile !== null ? this.props.user_profile.id : null
			this.props.dispatch(fetchAlreadyVaccinated(this, testType, clientID))
		} else {
			this.props.dispatch({ type: SELECTED_TEST_TYPE, data: testType })
			this.props.navigate("/upload-test-type-image")
		}
	};

	/**
	 * @function
	 *
	 * function representing fetching all health  reacords and redireting to the TestHistory screen.
	 *
	 */
	openHealthRecords = () => {
		this.props.dispatch(fetchTestResultsValues());
		this.props.navigate("/test-history", { state: { from_button: 'all' } })
	};

	/**
	  * @function
	  *
	  * function representing fetching unverified health  reacords and redireting to the TestHistory screen.
	  *
	  */
	openUnverifiedHealthRecords = () => {
		this.props.dispatch(fetchUnverifiedRecord(this, this.props.user_profile.id));
		this.props.navigate("/test-history", { state: { from_button: 'unverified' } })
	};

	openDialog = (test) => {
		this.setState({ imageDialog: true, selectedTest: test, imageUrl: `${END_POINT}image/${test.isVaccine === true ? "v" : "t"}/${test.storageKey}` })
	};

	closeDialog = () => {
		this.setState({ imageDialog: false, selectedTest: null, imageUrl: null })
	};

	download = (test) => {
		if (test.isLabTest && test.pdfReportStorageKey !== null) {
			saveAs(`${END_POINT}pdf-test-report/${test.pdfReportStorageKey}`, 'document.pdf') // Put your url here.
		} else {
			saveAs(`${END_POINT}image/${test.isVaccine === true ? "v" : "t"}/${test.storageKey}`, 'document.jpg') // Put your url here.
		}
	};

	/** Rendering my health information. */
	render() {
		const { user_test_results, user_profile } = this.props;
		let unverifiedrecards = this.props.fetch_test_results.filter((value) => value.status === "PENDING")
		let imageurl = (user_profile !== null && user_profile.imageStorageKey !== null) ? `${END_POINT}image/i/${user_profile.imageStorageKey}` : null;
		return (
			<div style={styles.paper}>
				<Grid container justifyContent="center">
					{user_test_results !== null &&
						<Grid item xs={12} sm={6} md={4}>
							<div style={styles.row}>
								<div style={styles.imageView}>
									{/* <img src={imageurl} alt="profile" className={classes.userImage} /> */}
									{imageurl !== null ?
										<img
											alt="Logo"
											src={imageurl}
											style={styles.userImage}
										/> :
										<img src={User} height="80" width="80" alt="icon" style={{
											backgroundColor: "white", border: "1px solid #ddd",
											borderRadius: "10px", objectFit: "cover"

										}} />}
								</div>
								<div>
									<div style={styles.titleView}>
										<Typography style={styles.typeText}>User</Typography>
									</div>
									<Typography style={styles.nameText}>{user_profile !== null ? `${user_profile.firstName} ${user_profile.lastName}` : ""}</Typography>
								</div>
							</div>
							<div style={styles.container}>
								<Typography style={styles.titleText}>My Recent Health Records</Typography>
								<div style={styles.divider} />
								<div style={styles.innerContainer}>
									{user_test_results.length > 0 ? user_test_results.map((test, index) =>
										test.isCovid === true &&
										(test.isVaccine === false ?
											test.displayDate !== null && test.testResult !== null && test.testResult.length > 0 &&
											<div key={index} >
												<div style={{ padding:10, marginBottom:6, backgroundColor: "#EBECF0", borderRadius:8 }}>
													{/* {test.type === "TEST" &&
														(test.isAntibody === true ?
															<Typography className={classes.resultText}>
																{(test.testResult !== null && test.testResult.length > 0) ? test.testResult[0].result_value === "POSITIVE" ? "Antibody Detected" : "No Antibody Detected" : (this.props.fetch_test_results !== undefined && this.props.fetch_test_results !== null) && this.props.fetch_test_results.filter(r => r.testTypeId === test.testTypeId && r.status === "PENDING").length > 0 ? "Test Uploaded but Not Verified" : "NOT TESTED"}
															</Typography>
															:
															<Typography className={classes.resultText}>
																{(test.testResult !== null && test.testResult.length > 0) ? test.testResult[0].result_value : (this.props.fetch_test_results !== undefined && this.props.fetch_test_results !== null) && this.props.fetch_test_results.filter(r => r.testTypeId === test.testTypeId && r.status === "PENDING").length > 0 ? "Test Uploaded but Not Verified" : "NOT TESTED"}
															</Typography>
														)} */}
													{/* <Typography className={classes.dateText}>{(test.testResult !== null && test.testResult.length > 0) ? `Verified on ${moment(test.testResult[0].created).format("MMM DD, YYYY hh:mm A")}` : "----"}</Typography> */}
													{/* <Typography className={classes.dateText}>{(test.testResult !== null && test.testResult.length > 0) ? `Verified on ${moment(test.testResult[0].dateOfTest).format("MMM DD, YYYY")}` : "----"}</Typography> */}
													{
														test.testResult.length > 0 &&
														<div >
															<div style={{ display: "flex" }}>
																<div>
																	{test.isAntibody ?
																		<img src={Antibody} alt="Antibody" width={30} height="auto" style={{marginLeft:10}}/>
																		: <img src={Covid} alt="Covid" width={30} height="auto" style={{marginLeft:10}} />}
																</div>
																<div style={{ paddingLeft: 10 }}>
																	<Typography style={styles.testText}><span style={{ color: "#000" }}>{test.testname}</span></Typography>
																	<Typography style={{ fontSize: 15, fontWeight: "600", color: (test.testResult[0].result_value === "INCONCLUSIVE" || (test.testResult[0].result_value === "POSITIVE" && test.isAntibody === false)) ? "red" : "#000" }}>
																		{test.testResult[0].result_value === "POSITIVE" ? "Detected" : test.testResult[0].result_value === "NEGATIVE" ? "Not Detected" : test.testResult[0].result_value === "INCONCLUSIVE" ? "Invalid" : test.testResult[0].result_value === "PENDING" ? "Pending" : "-"}
																	</Typography>
																	<Typography style={styles.dateText}>Test Date: {test.displayDate !== null ? `${moment.utc(test.displayDate, 'YYYY-MM-DD').format("MMM DD, YYYY")}` : "----"}</Typography>
																</div>
															</div>
															<div style={styles.divider} />
															{(test.isLabTest && test.pdfReportStorageKey !== null) && <div style={{ display: "flex", justifyContent: "space-between" }}>
																<Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography>
																<div style={styles.verticalDivider} />
																<Typography style={styles.deleteText} onClick={() => this.download(test)}>Download</Typography>
															</div>}
															{(test.isLabTest === false && test.storageKey !== null) && <div style={{ display: "flex", justifyContent: "space-between" }}>
																<Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography>
																<div style={styles.verticalDivider} />
																<Typography style={styles.deleteText} onClick={() => this.download(test)}>Download</Typography>
															</div>}
														</div>
													}
													{/* <div style={styles.divider} /> */}
												</div>
											</div>
											:
											test.vaccinResult.length > 0 && test.vaccinResult[0].vaccinationDate !== null &&
											<div key={index}>
												<div style={{ padding: 10, backgroundColor: "#EBECF0", borderRadius:8  }}>
													<div style={{ display: "flex" }}>
														<div>
															<img src={Vaccination} alt="Vaccination" width={30} height="auto" style={{marginLeft:10}}/>
														</div>
														<div style={{ paddingLeft: 10 }}>
															<Typography style={styles.testText}><span style={{ color: "#000" }}>{test.testname}</span></Typography>
															{this.customVaccineResult(test, styles)}
														</div>
													</div>
													<div style={styles.divider} />
													{(test.isLabTest && test.pdfReportStorageKey !== null) && <div style={{ display: "flex", justifyContent: "space-between" }}>
														<Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography>
														<div style={styles.verticalDivider} />
														<Typography style={styles.deleteText} onClick={() => this.download(test)}>Download</Typography>
													</div>}
													{(test.isLabTest === false && test.storageKey !== null) && <div style={{ display: "flex", justifyContent: "space-between" }}>
														<Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography>
														<div style={styles.verticalDivider} />
														<Typography style={styles.deleteText} onClick={() => this.download(test)}>Download</Typography>
													</div>}
													{/* <div style={styles.divider} /> */}

												</div>

											</div>
										)
									) :
										<div style={{ display: "flex", alignItems: "center", height: "10vh", justifyContent: "center" }} >
											<Typography style={styles.emptyText} > There are no test results</Typography>
										</div>}
								</div>
							</div>
							<div style={styles.buttonContainer}>
								<div style={{ float: 'right' }}>
									<Badge badgeContent={unverifiedrecards.length} color={"error"} ></Badge>
								</div>
								<Button
									type="Button"
									fullWidth
									variant="contained"
									color="primary"
									onClick={() => this.openUnverifiedHealthRecords()}
									style={styles.button}
								>
									<Typography style={styles.continueText} >View My Unverified Records</Typography>
								</Button>
								<Button
									type="Button"
									fullWidth
									variant="contained"
									color="primary"
									onClick={() => this.openHealthRecords()}
									style={styles.button}
								>
									<Typography style={styles.continueText}>View Verified health records</Typography>
								</Button>
								<Button
									type="Button"
									fullWidth
									variant="contained"
									color="primary"
									onClick={() => this.openRecord('T')}
									style={styles.button}
								>
									<Typography style={styles.continueText}>Upload Test Record</Typography>
								</Button>
								<Button
									type="Button"
									fullWidth
									variant="contained"
									color="primary"
									onClick={() => this.openRecord('V')}
									style={styles.button}
								>
									<Typography style={styles.continueText}>Upload Vaccination Record</Typography>
								</Button>
							</div>
						</Grid>}
				</Grid>
				<Dialog open={this.state.openTestList} onClose={() => this.setState({ openTestList: false })} aria-labelledby="simple-dialog-title">
					<DialogTitle id="simple-dialog-title">
						<div style={styles.divStyle}>
							<div style={styles.lineStyle} />
						</div>
					</DialogTitle>
					{/* <List>
						{this.state.testtypes.map((testType, index) => (
							<ListItemButton onClick={() => this.handleTestUpload(testType)} key={index} disabled={testType.status === "PENDING" ? true : false}>
								<ListItemText
									primary={
										<React.Fragment>
											<Typography style={styles.panelTitle}>{`${testType.name}`}</Typography>
											{testType.status !== "ACTIVE" && <Typography style={styles.panelTitle}>{`(Coming soon)`}</Typography>}
										</React.Fragment>
									}
									secondary={
										<React.Fragment>
											<Typography style={styles.panelText}>{`${testType.description}`}</Typography>
										</React.Fragment>
									}
								/>
							</ListItemButton>
						))}
					</List> */}
					<DialogContent dividers>
						{this.state.testtypes.map((testType, index) => (
							<div key={index} onClick={() => this.handleTestUpload(testType)} disabled={testType.status === "PENDING" ? true : false} style={{ borderBottom: "1px solid #EDEDED", marginTop: 10, }}>
								<div>
									<Typography style={styles.panelTitle}>{`${testType.name}`}</Typography>
									{testType.status !== "ACTIVE" && <Typography style={styles.panelTitle}>{`(Coming soon)`}</Typography>}
								</div>
								<div>
									<Typography style={styles.panelText}>{`${testType.description}`}</Typography>
								</div>
							</div>
						))}
					</DialogContent>
				</Dialog>
				<Dialog
					maxWidth={"xs"}
					open={this.state.isConfirmationDialog}>
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold", textAlign: 'center' }}>{"Confirmation"}</Typography>
					</DialogTitle>
					<DialogContent>
						<div style={styles.qrContainer} >
							{"Do you want submit to the queue ?"}
						</div>
					</DialogContent>
					<div style={{ justifyContent: 'center' }}>
						<div style={styles.lastDivider} />
					</div>
					<div style={styles.buttonView}>
						<Button style={{ flex: 1 }} onClick={() => this.setState({ isConfirmationDialog: false })} color="primary" >No</Button>
						<div style={{
							borderLeft: "1.5px solid #D1CFCF",
							height: "100%", fontWeight: "bold"
						}} />
						<Button style={{ flex: 1 }} onClick={() => this.submit()} color="primary">Yes</Button>
					</div>
				</Dialog>
				<Dialog maxWidth={"xs"} fullWidth open={this.state.imageDialog} onClose={() => this.setState({ imageDialog: false })}>
					{this.state.selectedTest !== null && <div style={{ overflow: "hidden" }}>
						<div style={{ display: "flex", justifyContent: "space-between", padding: 5, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
							<Typography style={{ display: "flex", alignItems: "center", color: '#fff' }}>{this.state.selectedTest !== null ?
								`${this.state.selectedTest.testname}`
								//  ${this.state.selectedTest.isVaccine ? (this.state.selectedTest.vaccinResult.length > 0 && this.state.selectedTest.vaccinResult[0].vaccinationDate !== null) ? `${moment(this.state.selectedTest.vaccinResult[0].vaccinationDate).format("MM/DD/YYYY")}` : "" : this.state.selectedTest.displayDate !== null ? `${moment(this.state.selectedTest.displayDate).format("MM/DD/YYYY")}` : ""}
								: ""}</Typography>
							<IconButton onClick={() => this.closeDialog()} style={styles.closeIcon}>
								<CancelIcon fontSize="medium" />
							</IconButton>
						</div>

						{this.state.selectedTest.isLabTest === true ?
							this.state.selectedTest.pdfReportStorageKey !== null ?
								<iframe title={`${this.state.selectedTest.pdfReportStorageKey}`} src={`${END_POINT}pdf-test-report/${this.state.selectedTest.pdfReportStorageKey}#toolbar=0`} style={{ width: "100%", height: "90vh" }} />
								:
								<Typography style={{ display: "flex", alignItems: "center", height: "40vh", justifyContent: "center" }}>There is no document to view</Typography>
							: <TransformWrapper>
								<TransformComponent>
									<img src={this.state.imageUrl} alt="test-img" style={{ width: "100%", height: "auto" }} />
								</TransformComponent>
							</TransformWrapper>}
					</div>}
				</Dialog>
			</div>
		);
	}
}

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {
		user_type: state.sessionReducer.user_type,
		user_profile: state.userReducer.user_profile,
		user_test_results: state.testReducer.user_test_results,
		allVaccine: state.vaccineReducer.vaccine,
		testtype: state.testtypeReducer.testtype,
		fetch_test_results: state.testReducer.fetch_test_results,
		testTypeRecords: state.testtypeReducer.testTypeRecords,
		vaccineRecords: state.testtypeReducer.vaccineRecords
	};
};

MyHealth.propTypes = {
	// classes: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(MyHealth));