import {
	FETCH_ALL_USER, FETCH_USER, FETCH_USER_PROFILE, FETCH_USER_TYPES,
	SELECTED_NEW_PROFESSIONAL_IMAGE, SELECTED_NEW_USER_TYPE, FETCH_DASHBOARD_DATA,
	FETCH_USER_BY_PHONE, FETCH_ALL_HEALTH_CARE_HERO, UPDATE_FETCH_ALL_USER, FETCH_USER_PROFILES,
	USER_TEST_RESULTS, FETCH_TEST_DASHBOARD_DATA, FETCH_SEARCH_USER, FETCH_ALL_REQUESTED_USER,
	FETCH_DASHBOARD_TEST_RESULT_DATA,FETCH_DASHBOARD_USER_DATA
} from "../actions/actionTypes";

const initialState = {
	all_users: [],
	fetch_user: {},
	user_profile: null,
	user_types: [],
	selected_user_type: null,
	selected_professional_image: null,
	dashboard_data: null,
	user_by_phone: null,
	health_care_heros: [],
	user_profiles: [],
	user_test_results: null,
	test_dashboard: null,
	search_all_user: [],
	requester_users: [],
	dashboard_test_data: null,
	dashboard_user_data: null
};

export default function userReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_USER:
			return {
				...state,
				fetch_user: action.data
			}
		case FETCH_ALL_REQUESTED_USER:
			return {
				...state,
				requester_users: action.data
			}
		case FETCH_ALL_HEALTH_CARE_HERO:
			return {
				...state,
				health_care_heros: action.data
			}
		case FETCH_ALL_USER:
			return {
				...state,
				all_users: action.data,
			}
		case FETCH_USER_PROFILE:
			return {
				...state,
				user_profile: action.data,
			}
		case FETCH_USER_TYPES:
			return {
				...state,
				user_types: action.data,
			}
		case SELECTED_NEW_USER_TYPE:
			return {
				...state,
				selected_user_type: action.data,
			}
		case SELECTED_NEW_PROFESSIONAL_IMAGE:
			return {
				...state,
				selected_professional_image: action.data,
			}
		case FETCH_DASHBOARD_DATA:
			return {
				...state,
				dashboard_data: action.data
			}
		case FETCH_USER_BY_PHONE:
			return {
				...state,
				user_by_phone: action.data
			}
		case UPDATE_FETCH_ALL_USER:
			return {
				...state,
				all_users: state.all_users.map(x => x.id === action.data.id ? action.data : x)
			}
		case FETCH_USER_PROFILES:
			return {
				...state,
				user_profiles: action.data
			}

		case USER_TEST_RESULTS:
			return {
				...state,
				user_test_results: action.data
			}
		case FETCH_TEST_DASHBOARD_DATA:
			return {
				...state,
				test_dashboard: action.data
			}
		case FETCH_SEARCH_USER:
			return {
				...state,
				search_all_user: action.data,
			}
		case FETCH_DASHBOARD_TEST_RESULT_DATA:
			return {
				...state,
				dashboard_test_data: action.data
			}
		case FETCH_DASHBOARD_USER_DATA:
			return {
				...state,
				dashboard_user_data: action.data
			}
		default:
			return state;
	}
}