import { FETCH_VACCINESHOT } from "../actions/actionTypes"

const initialstate = {
    vaccineshot: []
}

export default function vaccineShotReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_VACCINESHOT:
            return {
                ...state,
                vaccineshot: action.data
            }
        default:
            return state;
    }
}