import { FETCH_MESSAGE_CONFIGURATION } from './actionTypes';
import API from '../api';

export function fetchMessageConfiguration() {
	return async dispatch => {
		API.get("message-config").then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_MESSAGE_CONFIGURATION, data: response.payload });
			}
		});
	}
}

export function updateMessageConfiguration(self, data, id) {
	return async dispatch => {
		API.put("message-config/"+id, data).then(async (response) => {
			if (response.status === "success") {
				self.handleClose()
				dispatch(fetchMessageConfiguration());
			}
		});
	}
}