import api from '../api';
import { FETCH_VACCINE, VERIFY_VACCINATION_DETAIL, LOT_NUMBER_OPENED, LOT_NUMBER_IMAGE, ALERT_DETAIL } from "./actionTypes"
import moment from "moment"
import store from '../store';

/** 
 * Get Vaccine from server
 * @function
 * @returns dispatch
 */
export function fetchVaccine() {
	return function (dispatch) {
		//console.log("fetchVaccine=in=========>")
		api.get('vaccine/all/vaccine-shots').then((response) => {
			//console.log("fetchVaccine==========>"+JSON.stringify(response))
			if (response.status === "success") {
				dispatch({
					type: FETCH_VACCINE,
					data: response.payload
				});
			}
		});
	}
}

/** 
 * Get Vaccine from server
 * @function
 * @returns dispatch
 */
export function fetchVaccineWithoutBooster() {
	return function (dispatch) {
		api.get('vaccine').then((response) => {
			//console.log("fetchVaccineWithoutBooster==========>"+JSON.stringify(response))
			if (response.status === "success") {
				dispatch({
					type: FETCH_VACCINE,
					data: response.payload
				});
			}
		});
	}
}

/**
 * This action creator used for create vaccine to server
 * @function
 * @param {self} self 
 * @param {item} item 
 * @returns dispatch
 */
export function addVaccine(self, item) {
	return dispatch => {
		api.post('vaccine', item).then((response) => {
			if (response.status === "success") {
				dispatch(fetchVaccine());
				dispatch({ type: ALERT_DETAIL, data: true, message: "Vaccine added successfully", severity: "success" })
				self.setState({
					isLoading: false,
					selectedVaccineId: null,
					dialogOpen: false,
					manufacturerId: 0,
					name: "",
					name_error: false,
					vaccineCode: "",
					vaccineCode_error: false,
					description: "",
					description_error: false,
					note: "",
					note_error: false,
					status: "ACTIVE",
					status_error: false,
					mode: ""
				})
			}
			else {
				self.setState({ isLoading: false, })
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
			}
		})
	}
}

/**
 * This action creator used for update vaccine to server
 * @function
 * @param {self} self 
 * @param {item} item 
 * @param {id} id 
 * @returns dispatch
 */
export function updateVaccine(self, item, id) {
	return dispatch => {
		api.put('vaccine/' + id, item).then((response) => {
			if (response.status === "success") {
				dispatch(fetchVaccine());
				dispatch({ type: ALERT_DETAIL, data: true, message: "Vaccine updated successfully", severity: "success" })
				self.setState({
					isLoading: false,
					dialogOpen: false,
					selectedVaccineId: null,
					manufacturerId: 0,
					name: "",
					name_error: false,
					vaccineCode: "",
					vaccineCode_error: false,
					description: "",
					description_error: false,
					note: "",
					note_error: false,
					status: "ACTIVE",
					status_error: false,
					mode: ""
				})
			}
			else {
				self.setState({ isLoading: false, })
				dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
			}
		})
	}
}

/**
 * This action creator used for upload vaccine to server
 * @function
 * @param {self} self 
 * @param {data} data 
 * @returns dispatch
 */
export function uploadVaccine(data, self) {
	return async dispatch => {
		let { file, testResultId, vaccineId, vaccineShotId, vaccinationDate, vaccinatedBy, lotNumber, vaccineExpirationDate, lastTestResultId } = data;
		let formData = new FormData();
		if (file !== null && file !== undefined) {
			const blob = await fetch(file).then((res) => res.blob());
			formData.append('file', new File([blob], 'file_name.jpg', { lastModified: new Date().getTime(), type: 'image/jpeg' }))
		}
		formData.append("testResultId", testResultId)
		formData.append("vaccineId", vaccineId)
		formData.append("vaccineShotId", vaccineShotId)
		formData.append("vaccinationDate", vaccinationDate)
		formData.append("vaccinatedBy", vaccinatedBy)
		formData.append("lotNumber", lotNumber)
		formData.append("vaccineExpirationDate", vaccineExpirationDate)
		formData.append("lastTestResultId", lastTestResultId)

		api.normalPost("user-vaccine-result/v2/user-vaccine", formData).then(async (response) => {
			if (response.status === "success") {
				self.props.navigate("/test-success")
				self.setState({
					loading: false,
					isLoading: false,
					vaccinated_by: "",
					vaccination_date: "",
					lot_number: "",
					vaccine_expiration_date: "",
					selected_vaccine: null,
					vaccineShotId: "",
					showDropDown: false,
					disabled: true,
					lot_number_error: false,
					vaccine_expiration_date_error: false,
					invalid_date_error: false
				})
				dispatch({ type: ALERT_DETAIL, data: true, message: "Your Patient’s Health Record has been updated", severity: "success" })
				dispatch({ type: LOT_NUMBER_IMAGE, data: null })
				dispatch({ type: VERIFY_VACCINATION_DETAIL, data: null })
				dispatch({ type: LOT_NUMBER_OPENED, data: false })
				localStorage.removeItem("userId");
			} else {
				self.setState({
					isLoading: false,
					vaccinated_by: "",
					vaccination_date: "",
					lot_number: "",
					vaccine_expiration_date: "",
					selected_vaccine: null,
					vaccineShotId: "",
					showDropDown: false,
					disabled: true,
					lot_number_error: false,
					vaccine_expiration_date_error: false,
					invalid_date_error: false
				})
				dispatch({ type: ALERT_DETAIL, data: true, message: "Test Result does not exist", severity: "error" })
				dispatch({ type: LOT_NUMBER_IMAGE, data: null })
				dispatch({ type: VERIFY_VACCINATION_DETAIL, data: null })
				dispatch({ type: LOT_NUMBER_OPENED, data: false })
			}
		});
	}
}

/** 
 * Get Already Vaccinated entries from server
 * @function
 * @param {self} self 
 * @param {testId} testId 
 * @returns dispatch
 */
export function fetchAlreadyVaccined(testId, self) {
	return async dispatch => {
		let user_profile = await store.getState().userReducer.user_profile;
		api.get("test-result/in-complete-vaccine/" + testId).then(async (response) => {
			if (response.status === "success") {
				if (response.payload !== null) {
					let selectedVaccine = self.props.vaccine.find(x => x.id === response.payload.vaccineId)
					self.setState({
						disabled: false,
						vaccinated_by: user_profile !== null ? user_profile.organization : '',
						selected_vaccine: selectedVaccine,
						vaccination_date: moment().format("MM/DD/YYYY"),
						vaccineShotId: response.payload.nextVaccineShotId
					})
				}
				self.setState({ isLoading: false, alreadyVaccinated: response.payload })
			} else {
				self.setState({ isLoading: false })
			}
		});
	}
}

/**
 * This action creator used for upload vaccine by user to server
 * @function
 * @param {self} self 
 * @param {data} data 
 * @returns dispatch
 */
export function uploadVaccineByUser(data, self) {
	return async dispatch => {
		let { testTypeId, clientUserProfileId, file, lot_file, vaccineId, vaccineShotId, vaccinationDate, vaccinatedBy, lotNumber, vaccineExpirationDate } = data;
		let formData = new FormData();
		if (file !== null && file !== undefined) {
			const fileblob = await fetch(file).then((res) => res.blob());
			formData.append('file', new File([fileblob], 'file_name.jpg', { lastModified: new Date().getTime(), type: 'image/jpeg' }))
		}
		if (lot_file !== null && lot_file !== undefined) {
			const lotfileblob = await fetch(lot_file).then((res) => res.blob());
			formData.append('lot_file', new File([lotfileblob], 'file_name.jpg', { lastModified: new Date().getTime(), type: 'image/jpeg' }))
		}
		formData.append("testTypeId", testTypeId)
		formData.append("clientUserProfileId", clientUserProfileId)
		formData.append("vaccineId", vaccineId)
		formData.append("vaccineShotId", vaccineShotId)
		formData.append("vaccinationDate", vaccinationDate)
		formData.append("vaccinatedBy", vaccinatedBy)
		formData.append("lotNumber", lotNumber)
		formData.append("vaccineExpirationDate", vaccineExpirationDate)

		api.normalPost("test-result/upload-user-vaccine-document", formData).then(async (response) => {
			if (response.status === "success") {
				if (self.state.alreadyVaccinated !== null && (self.state.alreadyVaccinated.nextVaccineShotId === null || self.state.alreadyVaccinated.vaccineShotNumber === 1) && self.state.selected_vaccine.vaccineShots.length > 1) {
					self.setState({ isDialog: true, isLoading: false })
				}
				else {
					dispatch({ type: ALERT_DETAIL, data: true, message: "Your Health Record has been added", severity: "success" })
					self.props.navigate("/my-health")
					localStorage.removeItem("testType")
					self.setState({
						loading: false,
						isLoading: false,
						vaccinated_by: "",
						vaccination_date: "",
						lot_number: "",
						vaccine_expiration_date: "",
						selected_vaccine: null,
						vaccineShotId: "",
						showDropDown: false,
						disabled: true,
						lot_number_error: false,
						vaccine_expiration_date_error: false,
						invalid_date_error: false
					})
				}
			} else {
				self.setState({
					isLoading: false,
					vaccinated_by: "",
					vaccination_date: "",
					lot_number: "",
					vaccine_expiration_date: "",
					selected_vaccine: null,
					vaccineShotId: "",
					showDropDown: false,
					disabled: true,
					lot_number_error: false,
					vaccine_expiration_date_error: false,
					invalid_date_error: false
				})
				dispatch({ type: ALERT_DETAIL, data: true, message: "Test doesn't uploaded", severity: "error" })
			}
		});
	}
}

export function fetchVaccineBasedOnType(data, self) {
	return async dispatch => {
		api.get(`vaccine/tag/test-type/${data.id}`).then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_VACCINE, data: response.payload });
				self.setState({ testTypeData: data })
			}
		});
	}
}