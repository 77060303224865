import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { connect } from "react-redux";
import { FaHome, FaHeartbeat, FaIdCard, FaUser, FaUsers } from "react-icons/fa";
import { TAB_SELECTED, ALERT_DETAIL } from "../../actions/actionTypes"
import { Link } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { withRouter } from '../../withRouter';

const styles = {
	root: {
		height: '100%'
	},
	content: {
		height: '100%'
	},
	tabroot: {
		width: '100%',
		padding: "0px 10px",
		position: "fixed",
		bottom: "0",
		justifyContent: "center",
		alignItems: 'center'
	},
};

class Minimal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_profile: null,
			userType: null
		}
	}

	/** This is a component life cycle method and used to assigning props values to state variable  */
	static getDerivedStateFromProps(props, state) {
		if (props.user_profile !== state.user_profile) {
			return {
				user_profile: props.user_profile,
				userType: props.user_profile.userType
			}
		}
		return null;
	}

	/**
		 * @function
		 *
		 * function representing clicking event tab change.
		 *
		 */
	tabChange = (newValue) => {
		this.props.dispatch({ type: TAB_SELECTED, data: newValue })
	}

	/** rendering layout */
	render() {
		// const { children, classes } = this.props;
		let { userType, user_profile } = this.state;
		return (
			<div style={styles.root}>
				<Snackbar
					open={this.props.openAlert}
					onClose={() => this.props.dispatch({ type: ALERT_DETAIL, data: false, message: "", severity: "" })}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={3000} >
					<Alert icon={false} variant="filled" severity={this.props.alertSeverity} >{this.props.alertMessage}</Alert>
				</Snackbar>
				<Outlet />
				{(userType === "MSP" || userType === "VFR") &&
					<BottomNavigation
						value={this.props.selectedTab}
						onChange={(event, newValue) => {
							this.tabChange(newValue)
						}}
						showLabels
						style={styles.tabroot}
					>
						<BottomNavigationAction label="Home" icon={<FaHome />} component={Link} to={"/my-activity"} style={{ minWidth: 0 }} />
						{userType === "MSP" && user_profile.isQueueVerifyer === true &&
							<BottomNavigationAction label="Queue" icon={<FaUsers />} component={Link} to={"/my-queue"} style={{ minWidth: 0 }} />
						}
						<BottomNavigationAction label="My Badge" icon={<FaIdCard />} component={Link} to={"/my-badge"} style={{ minWidth: 0 }} />
						<BottomNavigationAction label="Account" icon={<FaUser />} component={Link} to={"/my-account"} style={{ minWidth: 0 }} />
					</BottomNavigation>
				}
				{userType === "CLT" &&
					<BottomNavigation
						value={this.props.selectedTab}
						onChange={(event, newValue) => {
							this.tabChange(newValue)
						}}
						showLabels
						style={styles.tabroot}
					>
						<BottomNavigationAction label="Home" icon={<FaHome />} component={Link} to={"/user/home"} style={{ minWidth: 0 }} />
						{/* <BottomNavigationAction label="Home" icon={<FaHome />} component={Link} to={"/my-activity"} style={{ minWidth: 0 }} /> */}
						<BottomNavigationAction label="My Badge" icon={<FaIdCard />} component={Link} to={"/my-badge"} style={{ minWidth: 0 }} />
						<BottomNavigationAction label="My Health" icon={<FaHeartbeat />} component={Link} to={"/my-health"} style={{ minWidth: 0 }} />
						<BottomNavigationAction label="Account" icon={<FaUser />} component={Link} to={"/my-account"} style={{ minWidth: 0 }} />
					</BottomNavigation>
				}
			</div>
		);
	}
}

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {
		selectedTab: state.sessionReducer.selectedTab,
		user_profile: state.userReducer.user_profile,
		openAlert: state.sessionReducer.openAlert,
		alertSeverity: state.sessionReducer.alertSeverity,
		alertMessage: state.sessionReducer.alertMessage
	};
};

Minimal.propTypes = {
	children: PropTypes.node,
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(Minimal));