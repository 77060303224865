import { FETCH_TEST_RESULT_DETAIL, FETCH_PASS_CODE } from "../actions/actionTypes"

const initialstate = {
    user_test_results: null,
    vaccines: [],
    userPassCode: null
}

export default function userPassCodeReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_TEST_RESULT_DETAIL:
            return {
                ...state,
                user_test_results: action.data,
                vaccines: action.vaccines
            }
        case FETCH_PASS_CODE:
            return {
                ...state,
                userPassCode: action.data
            };
        default:
            return state;
    }
}
