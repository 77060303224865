export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const FETCH_ALL_USER = "FETCH_ALL_USER";
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const FETCH_TEST_RESULTS = "FETCH_TEST_RESULTS";
export const VERIFICATION_TEST_VALUE = "VERIFICATION_TEST_VALUE";
export const FETCH_VACCINE = "FETCH_VACCINE";
export const FETCH_TESTTYPE = "FETCH_TESTTYPE";
export const FETCH_VACCINESHOT = "FETCH_VACCINESHOT";
export const FETCH_VACCINEMANUFACTURER = "FETCH_VACCINEMANUFACTURER";
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const ADD_PHONE = 'ADD_PHONE';
export const ADD_PASSWORD = 'ADD_PASSWORD';
export const ADD_PROFILE = 'ADD_PROFILE';
export const REGISTER_PENDING = 'REGISTER_PENDING';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const USER_TYPE = 'USER_TYPE';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const FETCH_USER = 'FETCH_USER';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const FETCH_ACTIVITY = 'FETCH_ACTIVITY';
export const TAB_SELECTED = "TAB_SELECTED";
export const TEST_RESULT = "TEST_RESULT";
export const FETCH_USER_RESULTS = "FETCH_USER_RESULTS";
export const FETCH_USER_RESULTS_VALUES = "FETCH_USER_RESULTS_VALUES";
export const FETCH_USER_TYPES = "FETCH_USER_TYPES";
export const ADD_ID_IMAGE = "ADD_ID_IMAGE";
export const ADD_USER_SELFIE = "ADD_USER_SELFIE";
export const SELECTED_TEST_TYPE = "SELECTED_TEST_TYPE";
export const SELECTED_TEST_TYPE_IMAGE = "SELECTED_TEST_TYPE_IMAGE";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const SELECTED_NEW_USER_TYPE = "SELECTED_NEW_USER_TYPE";
export const SELECTED_NEW_PROFESSIONAL_IMAGE = "SELECTED_NEW_PROFESSIONAL_IMAGE";
export const LOT_NUMBER_IMAGE = "LOT_NUMBER_IMAGE";
export const VERIFY_VACCINATION_DETAIL = "VERIFY_VACCINATION_DETAIL";
export const LOT_NUMBER_OPENED = "LOT_NUMBER_OPENED";
export const ALERT_DETAIL = "ALERT_DETAIL";
export const FETCH_DASHBOARD_DATA = "FETCH_DASHBOARD_DATA";
export const FETCH_USER_BY_PHONE = "FETCH_USER_BY_PHONE";
export const ALREADY_VACCINATED_TEST = "ALREADY_VACCINATED_TEST";
export const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION';
export const FETCH_MEMBER = "FETCH_MEMBER";
export const FETCH_PARTNER = "FETCH_PARTNER";
export const FETCH_ROLETYPE = "FETCH_ROLETYPE";
export const FETCH_PARTNER_DETAIL = "FETCH_PARTNER_DETAIL";
export const FETCH_ORGANIZATION_DETAIL = "FETCH_ORGANIZATION_DETAIL";
export const FETCH_DONOR = "FETCH_DONOR";
export const ADD_DONOR = "ADD_DONOR";
export const USER_DATA = "USER_DATA";
export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const PREMIUM_USER_SUPPORT = "PREMIUM_USER_SUPPORT";
export const FETCH_ALL_HEALTH_CARE_HERO = "FETCH_ALL_HEALTH_CARE_HERO";
export const USER_PROFILE_TEST_RESULT = "USER_PROFILE_TEST_RESULT";
export const FETCH_QUEUE_USERS = "FETCH_QUEUE_USERS";
export const USER_CONFIRMATION_ID = "USER_CONFIRMATION_ID";
export const FETCH_CREDIT_COUPONS = "FETCH_CREDIT_COUPONS";
export const ORGANIZATION_PLAN = "ORGANIZATION_PLAN";
export const USERS_WITH_TEST_RESULTS = "USERS_WITH_TEST_RESULTS";
export const NEW_USERS_WITH_TEST_RESULTS = "NEW_USERS_WITH_TEST_RESULTS";
export const ORGANIZATION_PLAN_BALANCE = "ORGANIZATION_PLAN_BALANCE";
export const ORGANIZATION_MEMBER_PLAN_ASSIGNED = "ORGANIZATION_MEMBER_PLAN_ASSIGNED";
export const UPDATED_ORGANIZATION_PLAN_BALANCE = "UPDATED_ORGANIZATION_PLAN_BALANCE";
export const FETCH_APP_VERSIONS = "FETCH_APP_VERSIONS";
export const FETCH_ALL_INDIVIDUALS = "FETCH_ALL_INDIVIDUALS";
export const FETCH_USER_SUBSCRIPTION_PLAN = "FETCH_USER_SUBSCRIPTION_PLAN";
export const UPDATE_INDIVIDUAL = "UPDATE_INDIVIDUAL";
export const REMOVE_ORGANIZATION_MEMBER = "REMOVE_ORGANIZATION_MEMBER";
export const FETCH_ORGANIZATION_DETAIL_BY_ID = "FETCH_ORGANIZATION_DETAIL_BY_ID";
export const FETCH_TEST_RESULT_DETAIL = "FETCH_TEST_RESULT_DETAIL";
export const UPDATE_FETCH_ALL_USER = "UPDATE_FETCH_ALL_USER";
export const FETCH_PENDING_LAB_TEST_RESULTS = "FETCH_PENDING_LAB_TEST_RESULTS";
export const FETCH_PENDING_LAB_TEST_RESULTS_COUNT = "FETCH_PENDING_LAB_TEST_RESULTS_COUNT";
export const ALL_TEST_RESULTS = "ALL_TEST_RESULTS";
export const ALL_TEST_RESULTS_UPDATE = "ALL_TEST_RESULTS_UPDATE";
export const UPDATE_TEST_RESULTS = "UPDATE_TEST_RESULTS";
export const TEST_RESULTS_COUNT = "TEST_RESULTS_COUNT";
export const USER_TOKEN_INFO = "USER_TOKEN_INFO";
export const TEST_USERS = "TEST_USERS";
export const FETCH_USER_PROFILES = "FETCH_USER_PROFILES";
export const INDIVIDUAL_TEST_RESULTS = "INDIVIDUAL_TEST_RESULTS";
export const SELECT_PRIMARY_CONTACT = "SELECT_PRIMARY_CONTACT";
export const USER_TEST_RESULTS = "USER_TEST_RESULTS";
export const FETCH_TEST_DASHBOARD_DATA = "FETCH_TEST_DASHBOARD_DATA";
export const FETCH_SEARCH_USER = "FETCH_SEARCH_USER";
export const FETCH_ALL_REQUESTED_USER = "FETCH_ALL_REQUESTED_USER";
export const FETCH_DASHBOARD_TEST_RESULT_DATA = "FETCH_DASHBOARD_TEST_RESULT_DATA";
export const FETCH_DASHBOARD_USER_DATA = "FETCH_DASHBOARD_USER_DATA";
export const FETCH_MESSAGE_CONFIGURATION = "FETCH_MESSAGE_CONFIGURATION";
export const FETCH_TEST_TYPES = "FETCH_TEST_TYPES";
export const FETCH_VACCINE_TEST_TYPES = "FETCH_VACCINE_TEST_TYPES";
export const FETCH_PASS_CODE = "FETCH_PASS_CODE";