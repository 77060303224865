import React, { Component } from 'react';
import {
    Grid, TextField, Typography,
    Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, InputAdornment
} from '@mui/material';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { connect } from "react-redux";
import moment from 'moment';
import { updateUserProfile, changePassword } from '../../actions/userAction';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { withRouter } from '../../withRouter';
import theme from '../../theme';

const { innerWidth, innerHeight } = window;

const styles = {
    bigAvatar: {
        margin: 10,
        width: 110,
        height: 110,
        borderColor: "#E5E8E8",
        borderWidth: "7px",
        borderStyle: "solid"
    },
    margin: {
        // '& label.Mui-focused': {
        //     color: '#12bdcf',
        // },
        // '& .MuiInput-underline:after': {
        //     borderBottomColor: '#12bdcf',
        // },
    },
    inputStyle: {
        fontFamily: "Futura-Book",
        fontSize: 14
    },
    addButton: {
        backgroundColor: "#20478e",
        color: "white",
        fontFamily: "unicode.futurab",
        borderRadius: "10"
    },
    textStyle: {
        fontFamily: "Futura-Heavy",
        color: "#37474f",
        //fontFamily: "bold",
        fontSize: 20,
        //padding: 10
    },
    cancelButton: {
        backgroundColor: "#8fa3ad",
        color: "white",
        fontFamily: "unicode.futurab",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgba(143, 163, 173, 0.8)',
        }
    },
    imagedrop: {
        minHeight: 150,
        width: 150
    },
    imageContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    input: {
        display: 'none',
    },
    browseButton: {
        marginTop: -((innerHeight / 10) * 1.14),
        marginLeft: innerWidth / 13.5,
        backgroundColor: "white",
        padding: 0,
        '&:hover': {
            backgroundColor: 'white',
        }
    }
};

class UserProfile extends Component {

    constructor() {
        super();
        this.state = {
            openPass: false,
            open: false,
            first_name: '',
            first_name_error: false,
            last_name: '',
            last_name_error: false,
            email: '',
            date_of_birth: null,
            date_of_birth_error: false,
            email_error: false,
            invalid_email_error: false,
            user: {},
            fetch_user: {},
            id: null,
            hashedUserId: null,
            password: '',
            confirm_password: '',
            password_error: false,
            invalid_password_error: false,
            confirm_password_error: false,
            invalid_confirm_password_error: false,
            secureEntry: true,
            secureEntryConfirm: true
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.fetch_user !== state.fetch_user) {
            return {
                fetch_user: props.fetch_user,
                hashedUserId: props.fetch_user.hashedUserId,
                id: props.fetch_user.id,
            }
        }
        return null;
    };

    openDialog = () => {
        const { fetch_user } = this.state;
        if (fetch_user !== null || fetch_user !== '') {
            this.setState({
                open: true,
                first_name: fetch_user.firstName,
                last_name: fetch_user.lastName,
                email: fetch_user.email,
                date_of_birth: moment(fetch_user.dateOfBirth),
            });
        }
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        let errorName = name + "_error";
        this.setState({
            [name]: value,
            [errorName]: false,

            first_name_error: false,
            last_name_error: false,
            email_error: false,
            invalid_email_error: false,
            date_of_birth_error: false,
            password_error: false,
            invalid_password_error: false,
            confirm_password_error: false,
            invalid_confirm_password_error: false,
        });
    };

    handleDateChange = (date) => {
        const currentDate = new Date();
        const targetDate = new Date(date);
        const isFuture = targetDate > currentDate;
        this.setState({ date_of_birth: date, date_of_birth_error: isFuture });
    };

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    submitProfile = () => {
        let { first_name, last_name, date_of_birth, email, date_of_birth_error } = this.state;
        console.log(first_name, last_name, date_of_birth, email)

        let isError = false;

        if (first_name === '' || first_name == null) {
            this.setState({ first_name_error: true });
            isError = true;
        }
        if (last_name === '' || last_name == null) {
            this.setState({ last_name_error: true });
            isError = true;
        }
        if (email === '' || email === null) {
            this.setState({ email_error: true });
            isError = true;
        } else {
            if (!this.validateEmail(this.state.email)) {
                this.setState({ invalid_email_error: true })
                isError = true
            }
        }
        if (date_of_birth === '' || date_of_birth === null || date_of_birth_error === true) {
            this.setState({ date_of_birth_error: true });
            isError = true;
        }

        if (isError === false) {
            let userObj = {};
            userObj.firstName = first_name;
            userObj.lastName = last_name;
            userObj.email = email;
            userObj.phone_number = this.state.phone_number;
            userObj.dateOfBirth = moment(date_of_birth).format('MM/DD/YYYY');
            userObj.hashedUserId = this.state.hashedUserId;
            this.props.dispatch(updateUserProfile(this, this.state.id, userObj));
        }
    };

    handleClose = () => {
        this.setState({
            open: false,
            openPass: false,
            password: '',
            confirm_password: '',
            first_name_error: false,
            last_name_error: false,
            email_error: false,
            invalid_email_error: false,
            date_of_birth_error: false,
            password_error: false,
            invalid_password_error: false,
            confirm_password_error: false,
            invalid_confirm_password_error: false,
            secureEntry: true,
            secureEntryConfirm: true
        });
    };

    openPasswordDialog = () => {
        this.setState({
            openPass: true
        });
    };

    passwordValidation = (params) => {
        var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(\S){8,}$/
        return re.test(params)
    };

    handleClickShowPassword = () => {
        this.setState({ secureEntry: !this.state.secureEntry })
    };

    handleClickConfirmShowPassword = () => {
        this.setState({ secureEntryConfirm: !this.state.secureEntryConfirm })
    };

    buttonchange = () => {
        let isError = false;
        let { password, confirm_password } = this.state;
        if (password !== null && password !== "") {
            if (!this.passwordValidation(password)) {
                this.setState({ invalid_password_error: true });
                isError = true;
            }
        } else {
            this.setState({ password_error: true })
            isError = true
        }

        if (confirm_password !== null && confirm_password !== "") {
            if (!(password === confirm_password)) {
                this.setState({ invalid_confirm_password_error: true })
                isError = true
            }
        } else {
            this.setState({ confirm_password_error: true })
            isError = true
        }

        if (isError === false) {
            let userObj = {};
            userObj.phone = this.state.fetch_user.phone;
            userObj.password = password;
            this.props.dispatch(changePassword(this, userObj));
            this.setState({
                password: "",
                confirm_password: "",
                password_error: false,
                invalid_password_error: false,
                confirm_password_error: false,
                invalid_confirm_password_error: false,
            });
        }
    };

    render() {
        // const { styles } = this.props;
        //const { fetch_user } = this.state;
        return (
            <Grid container spacing={0} >
                <Grid item xs={12} sm={12} style={{}}>
                    <Grid container spacing={4} style={{ padding: 30 }}>
                        <Grid item xs={12} sm={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={9}>
                                    <Typography style={{ ...styles.textStyle, fontSize: "16", color: "#000", fontWeight: "bold" }}>PERSONAL INFORMATION</Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} style={{ textAlign: "end" }}>

                                </Grid>
                                <Grid item xs={12} sm={8} style={{ marginTop: -10 }} >
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} sm={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography style={{ color: "gray", fontFamily: "Futura-Medium-bt", fontWeight: "bold" }}>First Name</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography style={{ color: "gray", fontFamily: "Futura-Book" }}>:   {Object.keys(this.state.fetch_user).length > 0 && this.state.fetch_user.firstName}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography style={{ color: "gray", fontFamily: "Futura-Medium-bt", fontWeight: "bold" }}>Last Name</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography style={{ color: "gray", fontFamily: "Futura-Book" }}>:   {Object.keys(this.state.fetch_user).length > 0 && this.state.fetch_user.lastName}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography style={{ color: "gray", fontFamily: "Futura-Medium-bt", fontWeight: "bold" }}>Phone Number</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography style={{ color: "gray", fontFamily: "Futura-Book" }}>:   {Object.keys(this.state.fetch_user).length > 0 && this.state.fetch_user.phone}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography style={{ color: "gray", fontFamily: "Futura-Medium-bt", fontWeight: "bold" }}>E-mail</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography style={{ color: "gray", fontFamily: "Futura-Book" }}>:   {Object.keys(this.state.fetch_user).length > 0 && this.state.fetch_user.email}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography style={{ color: "gray", fontFamily: "Futura-Medium-bt", fontWeight: "bold" }}>Date of Birth</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography style={{ color: "gray", fontFamily: "Futura-Book" }}>: {Object.keys(this.state.fetch_user).length > 0 && this.state.fetch_user.dateOfBirth !== null ? moment(this.state.fetch_user.dateOfBirth).format('LL') : ''} </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={8} style={{ marginTop: "10px" }}>
                                                    <Grid container spacing={1} style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                                                        <Button onClick={() => this.openDialog()} variant='contained' size="small" style={{ margin: "5px", boxShadow: "none", color: "#FFF", fontWeight: "bold", fontFamily: "Futura-Book", textTransform: "initial", borderRadius: 5 }}>
                                                            Update Profile
                                                        </Button>
                                                        <Button onClick={() => this.openPasswordDialog()} variant='contained' size="small" style={{ margin: "5px", boxShadow: "none", color: "#FFF", fontWeight: "bold", fontFamily: "Futura-Book", textTransform: "initial", borderRadius: 5 }}>
                                                            Change Password
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    disableEscapeKeyDown={true}
                    maxWidth={"xs"}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "1%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>Update Profile</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    styles={styles.margin}
                                    id="input-with-icon-textfield"
                                    label="First Name"
                                    name="first_name"
                                    InputLabelProps={{
                                        shrink: true,
                                        style: {
                                            fontFamily: "Futura-Book",
                                            fontSize: 18,
                                            fontWeight: "lighter"
                                        }
                                    }}
                                    InputProps={{
                                        styles: { input: styles.inputStyle }
                                    }}
                                    autoComplete="off"
                                    required
                                    fullWidth
                                    margin="dense"
                                    value={this.state.first_name}
                                    onChange={this.handleChange}
                                    helperText={this.state.first_name_error !== false ? "Please enter first name" : null}
                                    error={this.state.first_name_error !== false ? true : false}
                                // onClick={(e) => { if (e.key === 'Enter') { this.submitProfile(this) } }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    styles={styles.margin}
                                    id="input-with-icon-textfield"
                                    label="Last Name"
                                    name="last_name"
                                    InputLabelProps={{
                                        shrink: true,
                                        style: {
                                            fontFamily: "Futura-Book",
                                            fontSize: 18,
                                            fontWeight: "lighter"
                                        }
                                    }}
                                    InputProps={{
                                        styles: { input: styles.inputStyle }
                                    }}
                                    required
                                    fullWidth
                                    margin="dense"
                                    autoComplete="off"
                                    value={this.state.last_name}
                                    onChange={this.handleChange}
                                    helperText={this.state.last_name_error !== false ? "Please enter last name" : null}
                                    error={this.state.last_name_error}
                                // onClick={(e) => { if (e.key === 'Enter') { this.submitProfile() } }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    styles={styles.margin}
                                    id="input-with-icon-textfield"
                                    label="Email"
                                    name="email"
                                    InputLabelProps={{
                                        shrink: true,
                                        style: {
                                            fontFamily: "Futura-Book",
                                            fontSize: 18,
                                            fontWeight: "lighter"
                                        }
                                    }}
                                    InputProps={{
                                        styles: { input: styles.inputStyle }
                                    }}
                                    required
                                    fullWidth
                                    margin="dense"
                                    autoComplete="off"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    error={this.state.email_error === true ? true : this.state.invalid_email_error === true ? true : false}
                                    helperText={this.state.email_error === true ? "Please enter email" : this.state.invalid_email_error === true ? "Please enter valid email" : null}
                                // onClick={(e) => { if (e.key === 'Enter') { this.submitProfile() } }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} style={{ marginTop: 7 }}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Grid container justifyContent="space-around">
                                        <DatePicker
                                            id="date-picker-dialog"
                                            label="Date of Birth"
                                            fullWidth
                                            required
                                            disableFuture
                                            format="MM/DD/YYYY"
                                            // maxDate={new Date()}
                                            value={this.state.date_of_birth}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: {
                                                    fontFamily: "Futura-Book",
                                                    fontSize: 18,
                                                    fontWeight: "lighter"
                                                }
                                            }}
                                            InputProps={{
                                                styles: { input: styles.inputStyle }
                                            }}
                                            onChange={(date) => this.handleDateChange(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}

                                            slotProps={{
                                                textField: {
                                                    error: this.state.date_of_birth_error,
                                                    helperText: `${this.state.date_of_birth_error === true ? "Please enter date of birth" : ''}`,
                                                },
                                            }}
                                            margin="dense"
                                        // error={this.state.date_of_birth_error}
                                        // helperText={this.state.date_of_birth_error === true ? "Please enter date of birth" : false}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.submitProfile()} variant="contained" color="primary" styles={styles.addButton}>
                            Submit
                        </Button>
                        <Button onClick={() => this.handleClose()} variant="contained" color="secondary" styles={styles.cancelButton}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openPass}
                    onClose={this.handleClose}
                    disableEscapeKeyDown={true}
                    maxWidth={"xs"}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title" style={{ padding: 0, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                        <Typography style={{ padding: "1%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>Change Password</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="password"
                                    label="Password"
                                    type={this.state.secureEntry ? "password1" : "text"}
                                    name="password"
                                    autoComplete="off"
                                    InputProps={{ // <-- This is where the toggle button is added.
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                //   onMouseDown={handleMouseDownPassword}
                                                >
                                                    {this.state.secureEntry ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    value={this.state.password}
                                    onChange={(event) => this.setState({ password: event.target.value, password_error: false, invalid_password_error: false })}
                                    error={this.state.password_error === true ? true : this.state.invalid_password_error === true ? true : false}
                                    helperText={this.state.password_error === true ? "Enter password" : this.state.invalid_password_error === true ? "Please enter a password with at least 8 characters, a number, an uppercase and a lowercase character." : ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} >
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="confirm password"
                                    type={this.state.secureEntryConfirm ? "password2" : "text"}
                                    label="Confirm Password"
                                    name="password"
                                    autoComplete="off"
                                    InputProps={{ // <-- This is where the toggle button is added.
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickConfirmShowPassword}
                                                //   onMouseDown={handleMouseDownPassword}
                                                >
                                                    {this.state.secureEntryConfirm ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    value={this.state.confirm_password}
                                    onChange={(event) => this.setState({ confirm_password: event.target.value, confirm_password_error: false, invalid_confirm_password_error: false })}
                                    error={this.state.confirm_password_error === true ? true : this.state.invalid_confirm_password_error === true ? true : false}
                                    helperText={this.state.confirm_password_error === true ? "Enter confirm password" : this.state.invalid_confirm_password_error === true ? "confirm password doesn't match with password" : ""}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.buttonchange()} variant="contained" color="primary" styles={styles.addButton}>
                            Submit
                        </Button>
                        <Button onClick={() => this.handleClose()} variant="contained" color="secondary" styles={styles.cancelButton}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        fetch_user: state.userReducer.fetch_user,
    }
};

UserProfile.propTypes = {
    // styles: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(UserProfile));