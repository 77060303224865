import api from '../api';
import { FETCH_ORGANIZATION, ALERT_DETAIL, FETCH_ORGANIZATION_DETAIL, ORGANIZATION_PLAN, ORGANIZATION_PLAN_BALANCE, ORGANIZATION_MEMBER_PLAN_ASSIGNED, UPDATED_ORGANIZATION_PLAN_BALANCE,FETCH_ORGANIZATION_DETAIL_BY_ID } from "./actionTypes"

export function fetchOrganization() {
    return function (dispatch) {
        api.get('organization').then((response) => {
            if (response.status === "success") {
                dispatch({
                    type: FETCH_ORGANIZATION,
                    data: response.payload
                });
            }
        });
    }
}


export function fetchOrganizationDetail() {
    return function (dispatch) {
        api.get('organization/my/all-organization').then((response) => {
            if (response.status === "success") {
                dispatch({
                    type: FETCH_ORGANIZATION_DETAIL,
                    data: response.payload
                });
            }
        });
    }
}

export function fetchOrganizationDetailByID(id) {
    return function (dispatch) {
        api.get('organization/'+id).then((response) => {
            if (response.status === "success") {
                dispatch({
                    type: FETCH_ORGANIZATION_DETAIL_BY_ID,
                    data: response.payload
                });
            }
        });
    }
}

export function addOrganization(self, data) {
    return dispatch => {
        api.post('organization', data).then((response) => {
            if (response.status === "success") {
                dispatch(fetchOrganization())
                dispatch({ type: ALERT_DETAIL, data: true, message: "Organization added successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    dialogOpen: false,
                    id: null,
                    name: "",
                    name_error: false,
                    email: "",
                    email_error: false,
                    invalid_email_error: false,
                    phone_number: "",
                    phone_number_error: false,
                    invalid_phone_number_error: false,
                    contact_person_name: "",
                    contact_person_name_error: false,
                    address_line1: "",
                    address_line1_error: false,
                    address_line2: "",
                    address_line2_error: false,
                    city: "",
                    city_error: false,
                    selectedState: "",
                    selectedState_error: false,
                    country: "United States of America",
                    country_error: false,
                    zipcode: "",
                    zipcode_error: false,
                    invalid_zipcode_error: false,
                    status: "ACTIVE",
                    status_error: false,
                    organization_type: "",
                    organization_type_error: false,
                    mode: "",
                    search_phone: "",
                    invalid_search_phone_error: false,
                    search_phone_error: false,
                    primary_contact_first_name: "",
                    primary_contact_first_name_error: false,
                    primary_contact_last_name: "",
                    primary_contact_last_name_error: false,
                    primary_contact_email: "",
                    primary_contact_email_error: false,
                    invalid_primary_contact_email_error: false,
                    primary_contact_country_code: "+1",
                    partner_ref: "NONE",
                })
            } else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}


export function updateOrganization(self, data, id) {
    return dispatch => {
        api.put('organization/' + id, data).then((response) => {
            if (response.status === "success") {
                dispatch(fetchOrganization());
                dispatch({ type: ALERT_DETAIL, data: true, message: "Organization updated successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    dialogOpen: false,
                    id: null,
                    name: "",
                    name_error: false,
                    email: "",
                    email_error: false,
                    invalid_email_error: false,
                    phone_number: "",
                    phone_number_error: false,
                    invalid_phone_number_error: false,
                    contact_person_name: "",
                    contact_person_name_error: false,
                    address_line1: "",
                    address_line1_error: false,
                    address_line2: "",
                    address_line2_error: false,
                    city: "",
                    city_error: false,
                    selectedState: "",
                    selectedState_error: false,
                    country: "United States of America",
                    country_error: false,
                    zipcode: "",
                    zipcode_error: false,
                    invalid_zipcode_error: false,
                    status: "ACTIVE",
                    status_error: false,
                    organization_type: "",
                    organization_type_error: false,
                    mode: "",
                    search_phone: "",
                    invalid_search_phone_error: false,
                    search_phone_error: false,
                    primary_contact_first_name: "",
                    primary_contact_first_name_error: false,
                    primary_contact_last_name: "",
                    primary_contact_last_name_error: false,
                    primary_contact_email: "",
                    primary_contact_email_error: false,
                    invalid_primary_contact_email_error: false,
                    primary_contact_country_code: "+1",
                    partner_ref: "NONE",
                })
            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}


export function addCredits(self, data) {
    return dispatch => {
        api.put('organization/buy/credits', data).then((response) => {
            if (response.status === "success") {
                dispatch(fetchOrganizationDetail());
                dispatch({ type: ALERT_DETAIL, data: true, message: "Credits added successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    credit_dialog: false,
                    add_credit: ""
                })
            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}

export function addOrganizationCredits(self, data, session_id, paymentStatus) {
    return dispatch => {
        api.put(`organization/buy/credits?session_id=${session_id}`, data).then((response) => {
            if (response.status === "success") {
                dispatch(fetchOrganizationDetail());
                if (paymentStatus === 'success' && response.showMessage === true) {
                    dispatch({ type: ALERT_DETAIL, data: true, message: "Credits added successfully", severity: "success" })
                }
                self.setState({
                    isLoading: false,
                    credit_dialog: false,
                    add_credit: ""
                })
            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}

export function applyCredits(self, data) {
    return dispatch => {
        api.put('organization-member/apply/credits', data).then((response) => {
            if (response.status === "success") {
                dispatch(fetchOrganizationDetail());
                dispatch({ type: ALERT_DETAIL, data: true, message: "Credits applied successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    apply_credit_dialog: false,
                    nonPremiumUsers: []
                })
            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}

export function uploadMember(self, data) {
    return dispatch => {
        let form = new FormData();
        form.append('file', data)
        api.normalPost('organization-member/upload-members', form).then((response) => {
            if (response.status === "success") {
                dispatch(fetchOrganizationDetail());
                dispatch({ type: ALERT_DETAIL, data: true, message: "Member uploaded successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    result_array: response.payload,
                    result_dialog: true,
                    uploadMemberdialogOpen: false
                })

            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}


export function fetchOrganizationPlan() {
    return dispatch => {
        api.get('subscription-plan/all/plan').then((response) => {
            if (response.status === "success") {
                let organizationPlan = response.payload.filter(x => x.isEnterprisePlan === true)
                dispatch({ type: ORGANIZATION_PLAN, data: organizationPlan })
            }
        })
    }
}


export function fetchOrganizationPlanBalance() {
    return dispatch => {
        api.get('organization-subscription-plan/organization/plans').then((response) => {
            if (response.status === "success") {
                dispatch({ type: ORGANIZATION_PLAN_BALANCE, data: response.payload })
            }
        })
    }
}

export function addSubscriptionPlan(self, data, paymentStatus) {
    return dispatch => {
        api.post(`organization-subscription-plan`, data).then((response) => {
            if (response.status === "success") {
                if (paymentStatus === 'success' && response.showMessage === true) {
                    dispatch({ type: ORGANIZATION_PLAN_BALANCE, data: response.payload })
                    dispatch({ type: ALERT_DETAIL, data: true, message: "Plan added successfully", severity: "success" })
                }
                self.setState({
                    isLoading: false,
                    credit_dialog: false,
                    plan_data: []
                })
            }
            else {
                self.setState({ isLoading: false, plan_data: [] })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}


export function assignSubscriptionPlan(self, data) {
    return dispatch => {
        api.post(`user-subscription-plan/transfer/organization-plan`, data).then((response) => {
            if (response.status === "success") {
                dispatch({ type: UPDATED_ORGANIZATION_PLAN_BALANCE, data: response.payload })
                dispatch({ type: ORGANIZATION_MEMBER_PLAN_ASSIGNED, data: response.member })
                self.setState({ assignSeatDialog: false, selectedMember: null, selectedPlan: null, selectedPlanCount: "", selectedOrganizationPlan: null })
            } else {
                self.setState({ isLoading: false, plan_data: [] })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}

export function AddSeatsForOrg(self, data) {
    return function (dispatch) {
        api.post('organization-subscription-plan/create-organization-plan', data).then((response) => {
            if (response.status === "success") {
                self.setState({addCreditDialog: false, planData: []})
                dispatch(fetchOrganizationDetailByID(self.state.organization_id))
            }
        });
    }
}
