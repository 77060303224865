import api from '../api';
import { FETCH_MEMBER, ALERT_DETAIL, REMOVE_ORGANIZATION_MEMBER, UPDATE_FETCH_ALL_USER } from "./actionTypes"
import { fetchOrganizationDetail } from "./organizationAction"
import moment from "moment";

export function fetchMember() {
    return function (dispatch) {
        api.get("organization-member").then((response) => {
            if (response.status === "success") {
                dispatch({
                    type: FETCH_MEMBER,
                    data: response.payload
                });
            }
        });
    }
}

export function addMember(self, item) {
    return dispatch => {
        api.post('organization-member', item).then((response) => {
            if (response.status === "success") {
                dispatch(fetchOrganizationDetail())
                dispatch({ type: ALERT_DETAIL, data: true, message: "Member added successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    dialogOpen: false,
                    id: null,
                    primary_contact_first_name: "",
                    primary_contact_first_name_error: false,
                    primary_contact_last_name: "",
                    primary_contact_last_name_error: false,
                    primary_contact_email: "",
                    primary_contact_email_error: false,
                    primary_contact_gender: "",
                    primary_contact_gender_error: false,
                    primary_contact_date_of_birth: "",
                    primary_contact_date_of_birth_error: false,
                    invalid_primary_contact_date_of_birth_error: false,
                    invalid_date_error: false,
                    primary_contact_zip_code: "",
                    primary_contact_zip_code_error: "",
                    primary_contact_country_code: "+1",
                    search_phone: ""
                })
            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}


export function searchUser(self, phone) {
    return dispatch => {
        api.get('user-profile/search/' + phone).then((response) => {
            if (response.status === "success") {
                //console.log(JSON.stringify(response))
                if (response.payload !== null) {
                    self.setState({
                        primary_contact_first_name: response.payload.firstName,
                        primary_contact_last_name: response.payload.lastName,
                        primary_contact_email: response.payload.email,
                        primary_contact_gender: response.payload.Gender,
                        primary_contact_date_of_birth: response.payload.dateOfBirth !== null ? moment(response.payload.dateOfBirth).format("MM/DD/YYYY") : "",
                        primary_contact_zip_code: response.payload.zipCode,
                        primary_contact_first_name_error: false,
                        primary_contact_last_name_error: false,
                        primary_contact_email_error: false,
                        primary_contact_gender_error: false,
                        invalid_primary_contact_email_error: false,
                        primary_contact_date_of_birth_error: false,
                        invalid_primary_contact_date_of_birth_error: false,
                        invalid_date_error: false,
                        primary_contact_zip_code_error: "",
                    })
                } else {
                    dispatch({ type: ALERT_DETAIL, data: true, message: `This user doesn't exist`, severity: "error" })
                }
            }
        })
    }
}

export function removeMember(self, data) {
    return function (dispatch) {
        api.put(`organization-member/${data.id}/remove-member`).then((response) => {
            if (response.status === "success") {
                self.setState({removeDialog: false, selectedMemberDetail: null})
                dispatch({
                    type: REMOVE_ORGANIZATION_MEMBER,
                    data: data
                });
            }
        });
    }
}

/**
 * This action creator used for search user entry from server
 * @function
 * @param {self} self 
 * @param {phone} phone 
 * @returns dispatch
 */
 export function findExistingUser(self, phone) {
	return dispatch => {
		api.get('user-profile/search/' + phone).then((response) => {
			if (response.status === "success") {
				if (response.payload !== null) {
					self.setState({
						primary_contact_first_name: response.payload.firstName,
						primary_contact_last_name: response.payload.lastName,
						primary_contact_email: response.payload.email,
						primary_contact_first_name_error: false,
						primary_contact_last_name_error: false,
						primary_contact_email_error: false
					})
				} else {
					dispatch({ type: ALERT_DETAIL, data: true, message: `This user doesn't exist`, severity: "error" })
				}
			}
		})
	}
}

/**
 * This action creator used for search user entry from server
 * @function
 * @param {self} self 
 * @param {phone} userObj 
 * @returns dispatch
 */
 export function updateUserRole(self, userObj) {
   return dispatch => {
       api.put('user-profile/update/user-role', userObj).then((response) => {
           if (response.status === "success") {
               if (response.payload !== null) {
                   self.setState({
                       roleDialog: false,
                       search_phone: "",
                       invalid_search_phone_error: false,
                       search_phone_error: false,
                       primary_contact_first_name: "",
                       primary_contact_first_name_error: false,
                       primary_contact_last_name: "",
                       primary_contact_last_name_error: false,
                       primary_contact_email: "",
                       primary_contact_email_error: false,
                       invalid_primary_contact_email_error: false,
                       primary_contact_country_code: "+1"
                   });
                   dispatch({ type: UPDATE_FETCH_ALL_USER, data : response.payload})
                   dispatch({ type: ALERT_DETAIL, data: true, message: `Updated Successfully`, severity: "success" })
               } else {
                   dispatch({ type: ALERT_DETAIL, data: true, message: `This user doesn't exist`, severity: "error" })
               }
           }else{
               dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
           }
       })
   }
}