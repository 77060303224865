import React, { Component } from "react";
import { Typography, Grid, IconButton, Dialog } from "@mui/material";
import { connect } from "react-redux";
import { END_POINT, THEMECOLOR } from "../../helpers/Constants";
import moment from 'moment';
import _ from "underscore"
import { fetchTestResultDetail } from "../../actions/userPassCodeAction";
import Covid from "../../assets/images/covid.png";
import Antibody from "../../assets/images/antibody.png";
import Vaccination from "../../assets/images/vaccination.png";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import CancelIcon from '@mui/icons-material/Cancel';
import User from "../../assets/images/account_circle.png"
import { withRouter } from "../../withRouter";

/** Created common styles for UI  */
const styles = {
	paper: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundColor: THEMECOLOR.SECONDARY,
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY},${THEMECOLOR.PRIMARY})`,
		height: '100vh',
		padding: 20
	},
	container: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundColor: "#FFF",
		margin: 30,
		borderRadius: 10
	},
	row: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex"
	},
	userImage: {
		height: 130,
		width: 130,
		marginTop: -30,
		borderRadius: 10,
		objectFit: "cover"
	},
	typeText: {
		fontSize: 15,
		paddingTop: 10,
		textAlign: "center",
		color: "#848484"
	},
	nameText: {
		fontSize: 17,
		paddingTop: 5,
		paddingBottom: 10,
		textAlign: "center",
		color: "#000",
		fontWeight: "bold"
	},
	divider: {
		width: "100%",
		height: 1,
		backgroundColor: "#D1CFCF"
	},
	innerContainer: {
		padding: 10
	},
	testText: {
		fontSize: 15,
		paddingTop: 3,
		color: "#000"
	},
	resultText: {
		fontSize: 15,
		paddingTop: 3,
		color: "#000"
	},
	dateText: {
		fontSize: 13,
		paddingTop: 3,
		color: "#000"
	},
	buttonContainer: {
		padding: "0px 30px"
	},
	panelButton: {
		paddingVertical: 10,
		borderBottomColor: "#D4D2CA",
		borderBottomWidth: 1,
		borderStyle: "solid"
	},
	panelTitle: {
		fontSize: 16,
		fontWeight: "600",
		color: "#000"
	},
	panelText: {
		fontSize: 13,
		color: "#000",
	},
	lineStyle: {
		height: 3,
		width: 120,
		background: "#727272",
	},
	divStyle: {
		display: 'flex',
		padding: 5,
		justifyContent: 'center'
	},
	deleteText: {
		cursor: "pointer",
		textAlign: "center",
		fontSize: 16,
		color: THEMECOLOR.SECONDARY,
		textTransform: "Capitalize",
		padding: "8px 15px"
	},
	closeIcon: {
		padding: 5,
		color: "#B22222",
		backgroundColor: "#ffffff",
		'&:hover': {
			backgroundColor: "#ffffff",
		}
	},
}

/**
 * @class
 *
 * Class representing ScanResult component
 *
 */
class VerifierTestResult extends Component {
	constructor() {
		super();
		this.state = {
			testtypes: [],
			error: false,
			errorMessage: "",
			imageDialog: false,
			selectedTest: null,
			imageUrl: null
		}
	}

	/** This is a component life cycle method and used to fetch datas*/
	async componentDidMount() {
		let qrcode = this.props.params.qrcode;
		/** fetching TestResult details. */
		this.props.dispatch(fetchTestResultDetail(this, qrcode))
	}

	/**
	 * This function represents to display vaccine result
	 * @function 
	 * @param {*} test 
	 * @param {*} classes 
	 * @param {*} uploadRecord 
	 * @returns 
	 */
	customVaccineResult = (test, classes, uploadRecord) => {
		let activeVaccines = this.props.vaccines !== null ? this.props.vaccines.filter(x => x.status === "ACTIVE") : null
		let vaccine = (activeVaccines !== null && test.vaccinResult !== null) ? activeVaccines.find(x => test.vaccinResult.filter(y => y.vaccineId === x.id && y.status === "COMPLETED").length > 0) : null
		return (
			<div>
				{(test.booster !== undefined && test.booster !== null) ?
					<Typography style={classes.resultText}>{(vaccine !== null && vaccine !== undefined) && `${test.shotNumber !== null ? vaccine.vaccineShots.length === test.shotNumber ? "Vaccinated" + (test.booster !== 0 ? ` + ${test.booster} Booster` : '') : "Partially Vaccinated" : ""}`}</Typography>
					:
					<Typography style={classes.resultText}>{(vaccine !== null && vaccine !== undefined) && `${test.shotNumber !== null ? vaccine.vaccineShots.length === test.shotNumber ? "Vaccinated" : "Partially Vaccinated" : ""}`}</Typography>
				}
				{
					(test.boosterDate !== undefined && test.boosterDate !== null) ?
						<Typography style={classes.dateText}>Vaccination Date: {test.boosterDate !== null ? `${moment.utc(test.boosterDate, 'YYYY-MM-DD').format('MMM DD, YYYY')}` : ""}</Typography>
						:
						<Typography style={classes.dateText}>{(vaccine !== null && vaccine !== undefined && test.vaccinResult.length > 0 && test.vaccinResult[0].vaccinationDate !== null) ? `Vaccination Date: ${moment.utc(test.vaccinResult[0].vaccinationDate, 'YYYY-MM-DD').format("MMM DD, YYYY")}` : "----"}</Typography>
				}
			</div>
		)
	}

	openDialog = (test) => {
		this.setState({ imageDialog: true, selectedTest: test, imageUrl: `${END_POINT}image/${test.isVaccine === true ? "v" : "t"}/${test.storageKey}` })
	}

	closeDialog = () => {
		this.setState({ imageDialog: false, selectedTest: null, imageUrl: null })
	}

	/** Rendering a screen for displaying the test results and for uploading the test documents */
	render() {
		const { user_test_results } = this.props;
		let imageUrl = (user_test_results !== null && user_test_results.userImage !== null) ? `${END_POINT}image/i/${user_test_results.userImage}` : null;
		let uploadRecord = _.pick(user_test_results, "testUploadRecords")
		return (
			<div style={styles.paper}>
				<Grid container justify="center">
					{this.state.error === false ? user_test_results !== null &&
						<Grid item xs={12} sm={6} md={4}>
							<div style={styles.container}>
								<div style={styles.row}>
									{/* <img src={imageUrl} alt="profile" className={classes.userImage} /> */}
									{imageUrl !== null ?
										<img
											alt="Logo"
											src={imageUrl}
											style={styles.userImage}
										/> :
										<img src={User} height="80" width="80" alt="icon" style={{
											backgroundColor: "white", border: "1px solid #ddd",
											borderRadius: "10px", objectFit: "cover"

										}} />}
								</div>
								<div>
									<Typography style={styles.typeText}>User</Typography>
									<Typography style={styles.nameText}>{user_test_results.firstName + " " + user_test_results.lastName}</Typography>
								</div>
								<div style={styles.divider} />
								<div style={styles.innerContainer}>
									{user_test_results.test.map(test =>
										test.isCovid === true &&
										(test.isVaccine === false ?
											(test.testResult !== null && test.testResult.length > 0 && test.displayDate !== null) && <div>
												<div style={{ display: "flex", padding: "5px 0px" }}>
													<div>
														{test.isAntibody ?
															<img src={Antibody} width={30} height="auto" alt="Antibody" />
															: <img src={Covid} width={30} height="auto" alt="Covid" />}
													</div>
													<div style={{ paddingLeft: "10px" }}>
														<Typography style={styles.testText}>Latest <span style={{ fontWeight: "bold" }}>{test.testname}</span></Typography>
														{test.type === "TEST" &&
															(test.testResult !== null && test.testResult.length > 0) && <Typography style={{ ...styles.resultText, color: (test.testResult[0].result_value === "INCONCLUSIVE" || (test.testResult[0].result_value === "POSITIVE" && test.isAntibody === false)) ? "red" : "#000" }} >{(test.testResult[0].result_value === "POSITIVE" ? "Detected" : test.testResult[0].result_value === "NEGATIVE" ? "Not Detected" : test.testResult[0].result_value === "INCONCLUSIVE" ? "Invalid" : test.testResult[0].result_value === "PENDING" ? "Pending" : "-")}</Typography>
														}
														<Typography style={styles.dateText}>{(test.testResult !== null && test.testResult.length > 0 && test.displayDate !== null) ? `Test Date: ${moment().utc(test.displayDate, 'YYYY-MM-DD').format("MMM DD, YYYY")}` : "----"}</Typography>
													</div>
												</div>
												<div>
													{(test.isLabTest === true && test.pdfReportStorageKey !== null) && <Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography>}
													{(test.isLabTest === false && test.storageKey !== null) && <Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography>}
												</div>
												<div style={styles.divider} />

											</div>
											:
											(test.vaccinResult !== null && test.vaccinResult.length > 0 && test.vaccinResult[0].vaccinationDate !== null) &&
											<div>
												<div style={{ display: "flex", padding: "5px 0px" }}>
													<div>
														<img src={Vaccination} width={30} height="auto" alt="Vaccination" />
													</div>
													<div style={{ paddingLeft: "10px" }}>
														<Typography style={styles.testText}>Latest <span style={{ fontWeight: "bold" }}>{test.testname}</span></Typography>
														{this.customVaccineResult(test, styles, uploadRecord)}
													</div>
												</div>
												<div>
													{(test.isLabTest === true && test.pdfReportStorageKey !== null) && <Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography>}
													{(test.isLabTest === false && test.storageKey !== null) && <Typography style={styles.deleteText} onClick={() => this.openDialog(test)}>View</Typography>}
												</div>
											</div>
										)
									)}
								</div>

							</div>
						</Grid> : <Grid item xs={12} sm={6} md={4} style={{ backgroundColor: "#ffffff", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Typography style={{ fontSize: 20, color: "red", textAlign: "center" }}>{this.state.errorMessage}</Typography>
					</Grid>}
				</Grid>
				<Dialog maxWidth={"xs"} fullWidth open={this.state.imageDialog} onClose={() => this.setState({ imageDialog: false })}>
					{this.state.selectedTest !== null && <div style={{ overflow: "hidden" }}>
						<div style={{ display: "flex", justifyContent: "space-between", padding: 5, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY},${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
							<Typography style={{ ...styles.description, display: "flex", alignItems: "center", color: '#fff' }}>{this.state.selectedTest !== null ?
								`${this.state.selectedTest.testname}`
								//  ${this.state.selectedTest.isVaccine ? (this.state.selectedTest.vaccinResult.length > 0 && this.state.selectedTest.vaccinResult[0].vaccinationDate !== null) ? `${moment(this.state.selectedTest.vaccinResult[0].vaccinationDate).format("MM/DD/YYYY")}` : "" : this.state.selectedTest.displayDate !== null ? `${moment(this.state.selectedTest.displayDate).format("MM/DD/YYYY")}` : ""}
								: ""}</Typography>
							<IconButton onClick={() => this.closeDialog()} style={styles.closeIcon}>
								<CancelIcon fontSize="medium" />
							</IconButton>
						</div>

						{this.state.selectedTest.isLabTest === true ?
							this.state.selectedTest.pdfReportStorageKey !== null ?
								<iframe src={`${END_POINT}pdf-test-report/${this.state.selectedTest.pdfReportStorageKey}#toolbar=0`} style={{ width: "100%", height: "90vh" }} title="report" />
								:
								<Typography style={{ display: "flex", alignItems: "center", height: "40vh", justifyContent: "center" }}>There is no document to view</Typography>
							: <TransformWrapper>
								<TransformComponent>
									<img src={this.state.imageUrl} alt="test-img" style={{ width: "100%", height: "auto" }} />
								</TransformComponent>
							</TransformWrapper>}

					</div>}
				</Dialog>
			</div>
		);
	}
}

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {
		user_test_results: state.userPassCodeReducer.user_test_results,
		vaccines: state.userPassCodeReducer.vaccines,
	};
};

VerifierTestResult.propTypes = {

};

export default withRouter(connect(mapStateToProps)(VerifierTestResult));