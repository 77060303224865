import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import {
	TextField, Typography, Button, Table, TableHead, TableBody, TableCell, TableRow, Dialog, DialogActions, DialogTitle, DialogContent, Pagination, Grid
} from "@mui/material";
import { fetchPendingTestResultsByUser, assignVerifier } from "../../actions/testAction";
import Expressicon from "../../assets/images/Expressicon.png";
import moment from "moment";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { THEMECOLOR, headerFont } from '../../helpers/Constants';

/**Created common styles for UI.*/
const styles = {
	textStyle: {
		fontFamily: "Futura-Heavy",
		color: "#20478E",
		fontSize: 20,
	},
	inputStyle: {
		fontSize: "15px !important",
		padding: "5px !important",
		width: 300
	},
	inputRoot: {
		borderRadius: "0px !important"
	},
	inputLabel: {
		color: "#4B86E3"
	},
	button: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		margin: 15,
		padding: "10px 25px",
		fontSize: 12,
		// backgroundColor: "#4B86E3",
		color: "#fff",
		fontWeight: "bold",
		borderRadius: 5,
		'&:hover': {
			backgroundColor: "#4B86E3",
		},
	},
	fontCellHeader: {
		fontFamily: "Futura-Book",
		color: "#fff",
		fontWeight: 900,
		padding: '14px 20px 14px 0px',
	},
	fontCellBody: {
		fontFamily: "Futura-Medium-bt",
		color: "#37474f",
		padding: '14px 20px 14px 0px',
	},
	tableHeader: {
		color: "#fff"
	},
	qrContainer: {
		display: 'flex',
		justifyContent: "center",
		padding: 20
	},
	cancelButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		fontFamily: "unicode.futurab",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(143, 163, 173, 0.8)',
		}
	},
	addButton: {
		//backgroundColor: theme.palette.secondary.light,
		color: "white",
		fontFamily: "unicode.futurab",
		marginRight: "20",
		fontSize: 13,
		fontWeight: "bold",
		'&:hover': {
			backgroundColor: 'rgba(32,71,142, 0.8)',
		}
	},
	clearButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		margin: 15,
		padding: "10px 25px",
		fontSize: 12,
		// backgroundColor: "#808080",
		color: "#fff",
		fontWeight: "bold",
		borderRadius: 5,
		'&:hover': {
			backgroundColor: "#808080",
		},
	},
	viewGreenButton:{
		backgroundColor:theme.palette.primary
	},
	viewRedButton:{
		backgroundColor:theme.palette.error.main
	}
};

/**
 * @class
 *
 * Class representing user test result component
 *
 */
class UsersScreen extends Component {
	constructor() {
		super();
		this.state = {
			paginationCount: 0,
			search_text: "",
			rowsPerPage: 10,
			page: 1,
			code: null,
			isConfirmationDialog: false,
			selectedTestId: null,
			sortBy: "DATE_ASC",
			is_search: false
		}
	}

	/** This is a component life cycle method and used to fetch datas*/
	componentDidMount = () => {
		/** fetching Pending test results. */
		this.props.dispatch(fetchPendingTestResultsByUser(this, 0))
	}

	/**
		 * @function
		 *
		 * function representing table pagination.
		 * @param {Object} _event
		 * @param {Object} newPage
		 */
	handleChangePage = (event, newPage) => {
		this.setState({
			page: newPage
		});
		this.props.dispatch(fetchPendingTestResultsByUser(this, this.props.users_pending_test_results.length * (newPage - 1)))
	}

	/**
		 * @function
		 *
		 * function representing table pagination row.
		 * @param {Object} event
		 */
	handleChangeRowsPerPage = (event) => {
		this.setState({
			page: 0, rowsPerPage: parseInt(event.target.value, 10)
		});
	}

	/**
	* @function
	*
	* function representing assigning health hero.
	* 
	*/
	assignHealthhero = () => {
		let data = {}
		data.code = this.state.code;
		this.props.dispatch(assignVerifier(this, this.state.selectedTestId, data))
	}

	search = () => {
		if (!!this.state.search_text) {
			this.setState({ is_search: true })
		}
	}

	/** rendering Users Test Results information. */
	render() {
		// const { page, rowsPerPage } = this.state;
		let users = this.props.users_pending_test_results;
		switch (this.state.sortBy) {
			case "NAME_ASC":
				users = this.props.users_pending_test_results.sort((a, b) => a.clientUserProfile.firstName.localeCompare(b.clientUserProfile.firstName))
				break;
			case "NAME_DSC":
				users = this.props.users_pending_test_results.sort((a, b) => b.clientUserProfile.firstName.localeCompare(a.clientUserProfile.firstName))
				break;
			case "DATE_ASC":
				users = this.props.users_pending_test_results.sort((a, b) => new Date(a.assignedDateTime) - new Date(b.assignedDateTime))
				break;
			case "DATE_DSC":
				users = this.props.users_pending_test_results.sort((a, b) => new Date(b.assignedDateTime) - new Date(a.assignedDateTime))
				break;
			default:
				break;
		}
		let result = this.state.is_search ? users.filter(x => (x.clientUserProfile.phone).toString() === (this.state.search_text).toString() || x.clientUserProfile.firstName.toLowerCase().toString() === (this.state.search_text.toLowerCase()).toString() || (x.clientUserProfile.lastName.toLowerCase().toString()) === (this.state.search_text.toLowerCase()).toString() || (x.clientUserProfile.firstName + x.clientUserProfile.lastName).toLowerCase().toString() === (this.state.search_text.toLowerCase()).toString()) : users
		return (
			<div style={styles.paper}>
				<div>
					<div style={{ display: "flex", justifyContent: "space-between", margin: "20px 15px" }}>
						<Typography style={{ color: theme.palette.secondary.light, fontWeight: headerFont.fontWeight, fontFamily: headerFont.fontFamily, fontSize: headerFont.fontSize, }}>Queued Test Results</Typography>
						{/* <TablePagination
							rowsPerPageOptions={[20]}
							component="div"
							count={result.length}
							rowsPerPage={this.state.rowsPerPage}
							page={this.state.page}
							slotProps={{
								select: {
									inputProps: { 'aria-label': 'rows per page' },
									native: true,
								}
							}}
							onChangePage={this.handleChangePage}
							onChangeRowsPerPage={this.handleChangeRowsPerPage}
							labelRowsPerPage={null}
							labelDisplayedRows={
								() => null
							}
							ActionsComponent={
								TablePaginationActions
							}
						/> */}
						<div>
							<TextField
								margin="normal"
								id="search-text"
								variant="standard"
								label="Search"
								placeholder="Name or Phone"
								name="mobile-number"
								autoComplete="off"
								value={this.state.search_text}
								onChange={event =>
									this.setState({ search_text: event.target.value, page: 0, is_search: false })
								}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ classes: { input: styles.inputStyle, root: styles.inputRoot, inputlabel: styles.inputLabel } }}
								autoFocus
							/>
							<Button size="small" variant="contained" color="clearButton" style={{ ...styles.clearButton, marginRight: 27, height: 40 }} onClick={() => this.setState({ search_text: "", page: 0, is_search: false })} >
								Clear
							</Button>
							<Button size="small" variant="contained" color={"secondary"} style={{ ...styles.button, backgroundColor: theme.palette.secondary.main, marginRight: 27, height: 40 }} onClick={() => this.search()} >
								Search
							</Button>
						</div>
					</div>
				</div>
				<div style={{ margin: "0px 15px" }}>
					<Table>
						<TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10 }}>
							<TableRow>
								<TableCell align="left" style={{ ...styles.fontCellHeader, paddingLeft: 5, display: 'flex', alignItems: "center" }}>
									User Info
									{/* <div style={{ display: "grid", cursor: "pointer" }}> */}
									<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_ASC" })} />
									<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_DSC" })} />
									{/* </div> */}
								</TableCell>
								<TableCell align="left" size="small" style={styles.fontCellHeader}>Test Type</TableCell>
								<TableCell align="left" size="small" style={{ ...styles.fontCellHeader, display: 'flex', alignItems: "center" }}>Date
									{/* <div style={{ display: "grid", cursor: "pointer" }}> */}
									<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "DATE_ASC" })} />
									<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "DATE_DSC" })} />
									{/* </div> */}
								</TableCell>
								<TableCell align="left" size="small" style={styles.fontCellHeader}>Verifier</TableCell>
								<TableCell align="left" size="small" style={styles.fontCellHeader}>Status</TableCell>
								<TableCell align="left" size="small" style={styles.fontCellHeader}>Action</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{result.length > 0 ? result.map((item) =>
								<TableRow key={item.id}>
									<TableCell align="left" style={styles.fontCellBody}>
										<div style={{ display: "flex" }}>
											<div>
												<Typography style={{ fontWeight: "bold", fontSize: 14 }}>{item.clientUserProfile.firstName + " " + item.clientUserProfile.lastName}</Typography>
												<Typography style={{ fontSize: 14 }}>{item.clientUserProfile.phone}</Typography>
											</div>
											{item.isExpress === true && <img src={Expressicon} style={{ paddingLeft: 10 }} height={17} width="auto" alt="express" />}
										</div></TableCell>
									<TableCell align="left" style={styles.fontCellBody}><span style={{ fontWeight: "bold" }}>{item.testType.name}</span> ({item.testType.isVaccine === true ? "Vaccine" : "Test"})</TableCell>
									<TableCell align="left" style={styles.fontCellBody}>
										<div>
											<Typography style={{ fontSize: 14 }}>{item.assignedDateTime !== null ? moment(item.assignedDateTime).format("MM/DD/YYYY") : "-"}</Typography>
										</div>
									</TableCell>
									<TableCell align="left" style={styles.fontCellBody}>
										{(item.assignedRef !== null && item.assignedVerifierProfile !== null) ?
											<div>
												<Typography style={{ fontWeight: "bold", fontSize: 14 }}>{item.assignedVerifierProfile.firstName + " " + item.assignedVerifierProfile.lastName}</Typography>
												<Typography style={{ fontSize: 14 }}>{item.assignedVerifierProfile.phone}</Typography>
												<Typography>{item.assignedVerifierProfile.isMasterHealthcareHero === true ? "(Master)" : ""}</Typography>
											</div>
											: "-"}
									</TableCell>
									<TableCell align="left" style={styles.fontCellBody}>{item.assignedRef === null ?
										<Typography style={{ color: "#FFF", backgroundColor: "red", padding: "0px 5px", fontSize: 12, fontWeight: "bold", borderRadius: 5, height: 30, alignContent: "center", textAlign: "center" }}>{"NOT-ASSIGNED"} </Typography>
										:
										<Typography style={{ color: "#FFF", backgroundColor: "green", padding: "0px 5px", fontSize: 12, fontWeight: "bold", borderRadius: 5, height: 30, alignContent: "center", textAlign: "center" }}> {"ASSIGNED"}</Typography>}</TableCell>
									<TableCell align="left" style={styles.fontCellBody}>
										{(item.assignedRef === null && item.assignedVerifierProfile === null) ?
											<div style={{ display: "grid" }}>
												{/* {item.isExpress !== true && <Button size="small" onClick={() => this.setState({ isConfirmationDialog: true, selectedTestId: item.id, code: "HCH" })} variant="contained" color="secondary" style={styles.addButton} >Assign Verifier</Button>} */}
												<Button size="small" onClick={() => this.setState({ isConfirmationDialog: true, selectedTestId: item.id, code: "MHCH" })} variant="contained" color="primary" style={{ ...styles.addButton, marginTop: 5 }}>Assign Master Verifier</Button>
											</div>
											:
											(item.assignedRef !== null && item.assignedVerifierProfile !== null && item.assignedStatus !== "MHCH") ?
												<div style={{ display: "flex" }}>
													<Button size="small" onClick={() => this.setState({ isConfirmationDialog: true, selectedTestId: item.id, code: "MHCH" })} variant="contained" color="primary" style={styles.addButton} >Assign Master Verifier</Button>
												</div> : "-"}
									</TableCell>
								</TableRow>)
								:
								<TableRow>
									<TableCell colSpan={6} style={{textAlign:"center"}}>There are no users</TableCell>
								</TableRow>
							}
						</TableBody>
					</Table>
					<Grid display={'flex'} flexDirection={'row'} justifyContent={'center'} padding={10}>
						<Pagination
							count={this.state.paginationCount}
							color="primary"
							size="large"
							page={this.state.page}
							variant="outlined"
							shape="rounded"
							onChange={this.handleChangePage}
						/>
					</Grid>
				</div>
				<Dialog
					maxWidth={"xs"}
					open={this.state.isConfirmationDialog} >
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"Confirmation"}</Typography>
					</DialogTitle>
					<DialogContent>
						<div style={styles.qrContainer}>
							{`Are you sure, you want to assign this document to ${this.state.code === "MHCH" ? "master" : "queue"} verifier?`}
						</div>
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={() => this.assignHealthhero()} variant="contained" color="primary" style={styles.viewGreenButton}>Yes</Button>
						<Button size="small" onClick={() => this.setState({ isConfirmationDialog: false, selectedTestId: null, code: null })} variant="contained" color="secondary" style={styles.viewRedButton}>No</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
	return {
		users_pending_test_results: state.testReducer.users_pending_test_results,
	};
};

UsersScreen.propTypes = {
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(UsersScreen));