import React, { Component } from "react";
import {
	Typography, Button, Dialog, DialogActions,
	DialogContent, DialogTitle, Grid, TablePagination,
	Table, TableBody, TableCell, TableHead, TableRow, Toolbar, TextField
} from "@mui/material";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { fetchAllHealthCareHero } from "../../actions/userAction";
import TablePaginationActions from "../Dashboard/TablePaginationActions";
import { assignQueue, assignMasterHealthcarHero } from "../../actions/userAction";
import { ALERT_DETAIL } from "../../actions/actionTypes";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import moment from "moment";
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { THEMECOLOR, headerFont } from '../../helpers/Constants';

function TabContainer(props) {
	return (
		<Typography component="div" style={{ padding: 8 * 3 }}>
			{props.children}
		</Typography>
	);
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

/**Created common styles for UI.*/
const styles = {
	textStyle: {
		fontFamily: "Futura-Heavy",
		color: "#20478E",
		fontSize: 20,
	},
	topContainer: {
		padding: "5px 10px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		backgroundColor: "#5186E7",
	},
	titleText: {
		color: "#fff",
		fontSize: 16,
		fontWeight: "bold"
	},
	iconButton: {
		padding: 0
	},
	iconStyle: {
		color: "#fff"
	},
	collapesContainer: {
		height: 250,
		overflowY: "auto"
	},
	collapesRow: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottom: "2px solid #5186E7",
		paddingBottom: 10,
		margin: 15,
	},
	buttonToolbar: {
		display: "flex",
		justifyContent: "center",
		alignItems: 'center',
		margin: 10
	},
	viewButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		backgroundColor: "#FF8C00",
		color: "#fff",
		fontWeight: 'bold',
		fontSize: 13,
		padding: "5px 15px",
		'&:hover': {
			backgroundColor: "#FF8C00",
		},
	},
	viewRedButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		backgroundColor: "#FF0000",
		color: "#fff",
		fontWeight: 'bold',
		fontSize: 13,
		padding: "5px 15px",
		'&:hover': {
			backgroundColor: "#FF0000",
		},
	},
	viewGreenButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		backgroundColor: THEMECOLOR.SECONDARY,
		color: "#fff",
		fontWeight: 'bold',
		fontSize: 13,
		padding: "5px 15px",
		margin: "5px 0px",
		'&:hover': {
			backgroundColor: "#20478e",
		},
	},
	rejectButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		backgroundColor: "#FF0000",
		color: "#fff",
		fontSize: 13,
		padding: "5px 15px",
		'&:hover': {
			backgroundColor: "#FF0000",
		},
		marginRight: 20
	},
	acceptButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		backgroundColor: "#32CD32",
		color: "#fff",
		fontSize: 13,
		padding: "5px 15px",
		'&:hover': {
			backgroundColor: "#32CD32",
		},
	},
	closeButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		backgroundColor: "#5186E7",
		color: "#fff",
		fontSize: 13,
		padding: "5px 15px",
		'&:hover': {
			backgroundColor: "#5186E7",
		},
		position: "fixed",
		left: "47%",
		bottom: "5%",
	},
	dialogPaper: {
		backgroundColor: "transparent",
		overflow: "hidden",
		boxShadow: "none",
		position: "relative"
	},
	statusView: {
		display: "flex",
		justifyContent: "flex-end",
		width: "40%",
	},
	dialogTitle: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "0px"
	},
	dialogTitleText: {
		fontSize: 18,
		fontWeight: "bold",
		paddingLeft: 10,
	},
	closeIcon: {
		backgroundColor: '#fff',
		color: "red"
	},
	fontCellHeader: {
		fontFamily: "Futura-Book",
		color: "#fff",
		fontWeight: 900,
		padding: '14px 20px 14px 0px',

	},
	fontTableCellBody: {
		fontFamily: "Futura-Medium-bt",
		color: "#37474f",
		padding: '14px 20px 14px 0px',
	},
	chipBadge: {
		backgroundColor: 'red',
		color: "#FFFFFF"
	},
	activeChip: {
		margin: theme.spacing(1),
		backgroundColor: '#3f51b5',
		color: "#FFFFFF",
		padding: "3px 3px",
		fontWeight: 'bold'
	},
	inActiveChip: {
		padding: "3px 3px",
		margin: theme.spacing(1),
		fontWeight: 'bold',
		backgroundColor: 'gray',
		color: "#FFFFFF",
		'&:hover': {
			backgroundColor: 'rgba(39,44,15, 0.8)',
		}
	},
	clearButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		margin: 15,
		padding: "10px 25px",
		fontSize: 12,
		backgroundColor: "#808080",
		color: "#fff",
		fontWeight: "bold",
		borderRadius: 5,
		'&:hover': {
			backgroundColor: "#808080",
		},
	},
	button: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		margin: 15,
		padding: "10px 25px",
		fontSize: 12,
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		fontWeight: "bold",
		borderRadius: 5,
		'&:hover': {
			backgroundColor: "#4B86E3",
		},
	},
};

/**
 * @class
 *
 * Class representing HealthCareHero component
 *
 */

class HealthCareHero extends Component {
	constructor() {
		super();
		this.state = {
			health_care_heros: [],
			rowsPerPage: 10,
			page: 0,
			isConfirmationDialog: false,
			access_type: null,
			selectedUser: null,
			sortBy: "DATE_DSC",
			search_by_phone: ""
		}
	}


	/**This is a component life cycle method and used to assigning props values to state variable.*/
	static getDerivedStateFromProps(props, state) {
		if (props.health_care_heros !== state.health_care_heros) {
			let filterDate = props.health_care_heros.filter(item => item.status === "ACTIVE");
			return {
				health_care_heros: filterDate
			}
		}
		return [];
	};

	/** This is a component life cycle method and used to fetch datas  */
	componentDidMount = () => {
		/** fetching HealthCareHero details. */
		this.props.dispatch(fetchAllHealthCareHero())
	};

	/**
	 * @function
	 *
	 * function representing table pagination.
	 * @param {Object} _event
	 * @param {Object} newPage
	 */
	handleChangePage = (_event, newPage) => {
		this.setState({
			page: newPage
		});
	};

	/**
	 * @function
	 *
	 * function representing table pagination row
	 * @param {Object} event
	 */
	handleChangeRowsPerPage = (event) => {
		this.setState({
			page: 0, rowsPerPage: parseInt(event.target.value, 10)
		});
	};

	/**
	 * @function
	 *
	 * function representing open confirmation dialog.
	 * @param {Object} user
	 * @param {String} type
	 */
	confirmationDialog = (user, type) => {
		let filterMasterHero = this.state.health_care_heros.filter(item => (item.status === "ACTIVE" && item.isMasterHealthcareHero === true))
		let filterHealthHero = this.state.health_care_heros.filter(item => (item.status === "ACTIVE" && item.isQueueVerifyer === true))
		if (type === "MASTER" && user.isMasterHealthcareHero === true && filterMasterHero.length <= 1) {
			this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Atleast one master medical service provider needed.", severity: "error" })
		} else if (type === "QUEUE" && user.isQueueVerifyer === true && filterHealthHero.length <= 1) {
			this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Atleast one medical service provider needed.", severity: "error" })
		} else {
			this.setState({
				isConfirmationDialog: true,
				selectedUser: user,
				access_type: type
			})
		}
	};

	/**
	 * @function
	 *
	 * function representing giving grant access or revoke access to QueueUsers.
	 *
	 */
	submit = () => {
		this.setState({ isConfirmationDialog: false })
		let { selectedUser, access_type } = this.state;
		let body = {}
		if (access_type === "MASTER") {
			body.isMasterHealthcareHero = selectedUser.isMasterHealthcareHero === true ? false : true;
			this.props.dispatch(assignMasterHealthcarHero(selectedUser.id, body))
		} else if (access_type === "QUEUE") {
			body.is_queue_verifyer = selectedUser.isQueueVerifyer === true ? false : true;
			this.props.dispatch(assignQueue(selectedUser.id, body))
		}
	};

	searchByPhone = () => {
		let data = this.state.health_care_heros.filter(item => item.status === "ACTIVE" && item.phone === this.state.search_by_phone);
		this.props.dispatch({ type: "FETCH_ALL_HEALTH_CARE_HERO", data: data })
	};

	clearSearch = () => {
		this.setState({ search_by_phone: "" })
		this.props.dispatch(fetchAllHealthCareHero())
	};

	/** rendering HealthCareHero information. */
	render() {
		const { page, rowsPerPage, health_care_heros } = this.state;
		let filtered_health_care_heros = health_care_heros.length > 0 ? health_care_heros.filter(item => (item.status === "ACTIVE")) : []
		let result = filtered_health_care_heros;
		switch (this.state.sortBy) {
			case "NAME_ASC":
				result = filtered_health_care_heros.sort((a, b) => a.firstName.localeCompare(b.firstName))
				break;
			case "NAME_DSC":
				result = filtered_health_care_heros.sort((a, b) => b.firstName.localeCompare(a.firstName))
				break;
			case "DATE_ASC":
				result = filtered_health_care_heros.sort((a, b) => (a.roleTypes.length > 0 && b.roleTypes.length > 0) && new Date(a.roleTypes[0].createdAt) - new Date(b.roleTypes[0].createdAt))
				break;
			case "DATE_DSC":
				result = filtered_health_care_heros.sort((a, b) => (a.roleTypes.length > 0 && b.roleTypes.length > 0) && new Date(b.roleTypes[0].createdAt) - new Date(a.roleTypes[0].createdAt))
				break;
			default:
				break;
		};

		return (
			<div style={{ padding: 5 }}>
				<div>
					<Grid item xs={12} sm={12} style={{ marginTop: 10, }}>
						<Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
							<div>
								<Typography style={{ color: theme.palette.secondary.light, fontWeight: headerFont.fontWeight, fontFamily: headerFont.fontFamily, fontSize: headerFont.fontSize, }}>Medical Service Providers</Typography>
								<TablePagination
									rowsPerPageOptions={[10]}
									component="div"
									count={result.length}
									rowsPerPage={this.state.rowsPerPage}
									page={this.state.page}
									slotProps={{
										select: {
											inputProps: { 'aria-label': 'rows per page' },
											native: true,
										}
									}}
									onPageChange={this.handleChangePage}
									onRowsPerPageChange={this.handleChangeRowsPerPage}
									labelRowsPerPage={null}
									labelDisplayedRows={
										({ from, to, count }) => null
									}
									ActionsComponent={
										TablePaginationActions
									}
								/>
							</div>
							<div style={{ alignItems: 'center', display: 'flex', justifyContent: "space-between" }}>
								<TextField
									margin="normal"
									variant="standard"
									id="search-name"
									label="Search Phone"
									name="mobile-number"
									autoComplete="off"
									value={this.state.search_by_phone}
									onChange={event =>
										this.setState({ search_by_phone: event.target.value, page: 0 })
									}
									InputLabelProps={{
										shrink: true,
									}}
									inputProps={{ maxLength: 10 }}
									InputProps={{ classes: { input: styles.inputStyle, root: styles.inputRoot } }}
									autoFocus
								/>
								<Button size="small" variant="contained" color="secondary" style={{ ...styles.clearButton, marginRight: 27, height: 40 }} onClick={() => this.clearSearch()}>
									Clear
								</Button>
								<Button size="small" variant="contained" style={{ ...styles.button, marginRight: 27, height: 40 }} onClick={() => this.searchByPhone()}>
									Search
								</Button>
							</div>
						</Toolbar>
					</Grid>
					<Grid item xs={8} sm={12} style={{ marginTop: 10, marginLeft: 5 }}>
						<Table>
							<TableHead style={{ backgroundColor: '#36454F', borderRadius: 10 }}>
								<TableRow>
									<TableCell align="center" style={styles.fontCellHeader}>
										<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
											Name
											{/* <div style={{  cursor: "pointer", marginTop: 6 }}> */}
											<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_ASC" })} />
											<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_DSC" })} />
											{/* </div> */}
										</div>
									</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>
										<div style={{ display: "flex", alignItems: "center" }}>
											Date
											{/* <div style={{ cursor: "pointer", marginTop: 6 }}> */}
											<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "DATE_ASC" })} />
											<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "DATE_DSC" })} />
											{/* </div> */}
										</div>
									</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}> Organization</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}> External Id</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}> Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									result.length > 0 ? result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) =>
										<TableRow key={item.id} style={{ backgroundColor: index % 2 === 0 ? "#fff" : "whitesmoke" }}>
											<TableCell align="center" size="small" style={{ ...styles.fontTableCellBody, paddingLeft: 5, width: "30%" }}>
												<Typography style={{ fontWeight: "bold", fontSize: 15 }}> {`${item.firstName} ${item.lastName}`}</Typography>
												<Typography style={{ fontSize: 14 }}>{item.phone}</Typography>
											</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.roleTypes.map(role => moment(role.createdAt).format("MM/DD/YYYY"))}</TableCell>
											<TableCell align="center" style={{ ...styles.fontTableCellBody, width: "25%" }}>{item.organization}</TableCell>
											<TableCell align="center" style={{ ...styles.fontTableCellBody, width: "25%" }}>{item.externalId}</TableCell>
											<TableCell align="center" style={{ ...styles.fontTableCellBody, width: "25%" }}>
												{
													(item.isMasterHealthcareHero === false && item.isQueueVerifyer === false) &&
													<div>
														<Button
															onClick={() => this.confirmationDialog(item, "MASTER")}
															type="Button"
															fullWidth
															variant="contained"
															style={styles.viewGreenButton}
														>
															Grant Master Medical Service Provider Access
														</Button>
														<Button
															onClick={() => this.confirmationDialog(item, "QUEUE")}
															type="Button"
															fullWidth
															variant="contained"
															style={styles.viewGreenButton}
														>
															Grant Queue Access
														</Button>
													</div>
												}
												{
													item.isMasterHealthcareHero === true &&
													<Button
														onClick={() => this.confirmationDialog(item, "MASTER")}
														type="Button"
														variant="contained"
														style={styles.viewRedButton}
													>
														Revoke Master Medical Service Provider Access
													</Button>
												}
												{
													item.isQueueVerifyer === true &&
													<Button
														onClick={() => this.confirmationDialog(item, "QUEUE")}
														type="Button"
														variant="contained"
														style={styles.viewRedButton}
													>
														Revoke Queue Access
													</Button>
												}
											</TableCell>
										</TableRow>)
										:
										<TableRow>
											<TableCell colSpan={5} style={{...styles.fontTableCellBody, textAlign:'center'}}>There are no Medical Service Providers</TableCell>
										</TableRow>
								}
							</TableBody>
						</Table>
					</Grid>
				</div>
				<Dialog
					maxWidth={"xs"}
					open={this.state.isConfirmationDialog} >
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"Confirmation"}</Typography>
					</DialogTitle>
					<DialogContent>
						<div style={styles.qrContainer}>
							{`Are you sure want to ${this.state.selectedUser !== null && (this.state.selectedUser.isQueueVerifyer === false && this.state.selectedUser.isMasterHealthcareHero === false) ? "give grant" : "revoke"} access to this user ?`}
						</div>
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={() => this.submit()} variant="contained" color="secondary" style={styles.viewGreenButton}>Update</Button>
						<Button size="small" onClick={() => this.setState({ isConfirmationDialog: false })} variant="contained" color="secondary" style={styles.viewRedButton}>Close</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
};

/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
	return {
		health_care_heros: state.userReducer.health_care_heros
	};
};

HealthCareHero.propTypes = {
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(HealthCareHero));