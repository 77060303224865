import { FETCH_MEMBER } from "../actions/actionTypes"

const initialstate = {
    member: []
}

export default function memberReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_MEMBER:
            return {
                ...state,
                member: action.data
            }
        default:
            return state;
    }
}