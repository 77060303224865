import React, { Component } from "react";
import { connect } from "react-redux";
import vaccine from '../../assets/images/syringe.png';
import moment from 'moment';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import InputMask from "react-input-mask";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { uploadVaccineByUser, fetchVaccineBasedOnType } from "../../actions/vaccineAction";
import { END_POINT, THEMECOLOR } from "../../helpers/Constants";
import { fetchUserProfile } from "../../actions/userAction";
import { VERIFY_VACCINATION_DETAIL, LOT_NUMBER_OPENED } from "../../actions/actionTypes";
import User from "../../assets/images/account_circle.png";
import { Grid, Typography, CircularProgress, Button, InputBase, Dialog, DialogContent, DialogActions } from "@mui/material";
import { withRouter } from "../../withRouter";
import { fetchAlreadyVaccinated } from "../../actions/testtypeAction";

const styles = {
	paper: {
		width: '100%',
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
		height: '100vh',
		paddingBottom: window.screen.width >= 640 ? 80 : 20,
		alignItems: 'center',
		justifyContent: "center",
	},
	container: {
		display: 'flex',
		width: '100%',
		margin: "0px 10px 0px 10px",
		minHeight: "590px",
	},
	headerText: {
		color: '#ffffff',
		fontSize: 18,
		marginTop: 10,
		fontWeight: '700',
		textAlign: 'center'
	},
	userRow: {
		display: 'flex',
		alignItems: "center",
		flexDirection: "row",
		padding: 20,
	},
	userImage: {
		padding: 10,
	},
	image: {
		width: 100,
		height: "auto",
		borderRadius: 14,
		backgroundColor: "#fff"
	},
	userProfile: {
		paddingLeft: 20,
	},
	title: {
		fontSize: 18,
		color: "#fff",
	},
	name: {
		marginLeft: "10px",
		fontSize: 20,
		color: "#fff",
		fontWeight: 'bold'
	},
	cardItemView: {
		padding: "20px",
		paddingBottom: 20,
		flex: 1,
		backgroundColor: "#FFF",
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		borderRadius: "10px"
	},
	titleView: {
		display: 'flex',
		flexDirection: "row",
		justifyContent: 'center',
		alignItems: "center"
	},
	imageStyle: {
		width: 25,
		height: 25
	},
	dropDownText: {
		color: "#FFF",
		fontSize: 15,
		marginLeft: 17,
		fontWeight: "100",
		textTransform: 'capitalize'
	},
	backgroundDropDown: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: "#EAEDED",
		borderRadius: 10,
		elevation: 2,
		paddingBottom: 6
	},
	activityContainer: {
		paddingTop: 20,
		paddingBottom: 20
	},
	dropDownButtonStyle: {
		display: 'flex',
		flexDirection: "row",
		border: "1px solid #EAEDED",
		padding: 12,
		elevation: 3,
		alignSelf: 'center',
		alignItems: "center",
		borderRadius: 10,
		width: "100%",
		justifyContent: "space-between",
		backgroundColor: THEMECOLOR.PRIMARY
	},
	formView: {
		display: 'flex',
		paddingTop: 6,
		paddingBottom: 6,
		flexDirection: 'column'
	},
	formTitleText: {
		color: "#000",
		fontSize: 14,
		paddingLeft: 4,
		paddingBottom: 2
	},
	textInputStyleDisabled: {
		borderRadius: 5,
		padding: 5,
		paddingLeft: 10,
		paddingRight: 10,
		border: "1px solid #EAEDED",
		color: "#000",
		backgroundColor: "whitesmoke",
		fontSize: 15,
		marginTop: 2,
	},
	lotView: {
		display: 'flex',
		flexDirection: "row",
		alignSelf: 'center',
		alignItems: "center",
		justifyContent: 'space-between',
		width: '100%'
	},
	textInputOuterFlexItem1: {
		width: "76%",
	},
	nextButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		marginTop: 5,
		padding: 7,
		fontSize: 15,
		backgroundColor: THEMECOLOR.PRIMARY,
		color: "#FFF",
		textTransform: "uppercase",
		fontWeight: "bold",
		borderRadius: 10,
		'&:hover': {
			backgroundColor: THEMECOLOR.PRIMARY,
		},
	},
	dropDownStyle: {
		border: "1px solid #EAEDED",
		borderRadius: 10,
		paddingLeft: 10,
		paddingRight: 10,
		marginLeft: 12,
		marginRight: 12,
	},
	dropDownItemStyle: {
		padding: 10,
		borderBottom: "1px solid #D4D2CA",
	},
	dropDownItemTextStyle: {
		color: "#000",
		fontSize: 15,
		paddingTop: 5,
		paddingBottom: 5,
		textTransform: 'capitalize'
	},
	lotimage: {
		width: "auto",
		height: 27
	},
	errorTextStyle: {
		fontSize: 13,
		color: "red"
	},
	dialogPaper: {
		backgroundColor: "rgba(0,0,0,0)",
		boxShadow: "none",
		overflow: "hidden"
	},
	selectedVaccineButton: {
		color: "#7F8EB8",
		fontSize: 15,
		marginLeft: 17,
		fontWeight: "100",
		textTransform: 'capitalize'
	}
};

class Vaccination extends Component {
	// static contextTypes = {
	//     router: PropTypes.object,
	// }
	constructor() {
		super();
		this.state = {
			vaccination_date: '',
			vaccinated_by: '',
			lot_number: '',
			vaccine_expiration_date: '',
			selected_vaccine: null,
			vaccineShotId: "",
			showDropDown: false,
			disabled: true,
			lot_number_error: false,
			vaccine: [],
			alreadyVaccinated: null,
			vaccination_date_error: false,
			invalid_vaccination_date_error: false,
			vaccinated_by_error: false,
			invalid_date_error: false,
			vaccine_expiration_date_error: false,
			lot_number_image: null,
			lot_number_base64: null,
			isLoading: false,
			errorText: "",
			expirationErrorText: "",
			testTypeData: null,
			isDialog: false
		}
	}

	componentDidMount = () => {

		this.props.dispatch(fetchVaccineBasedOnType(JSON.parse(localStorage.getItem("testType")), this))
		this.props.dispatch(fetchUserProfile())
		// this.props.dispatch(fetchVaccine())
		let alreadyVaccinated = this.props.already_vaccinated;
		if (alreadyVaccinated !== null && alreadyVaccinated !== undefined) {
			let disabled = alreadyVaccinated.nextVaccineShotId === null ? true : false
			let selectedVaccine = alreadyVaccinated.nextVaccineShotId === null ? null : alreadyVaccinated.vaccine
			this.setState({ alreadyVaccinated: alreadyVaccinated, disabled: disabled, vaccineShotId: alreadyVaccinated.nextVaccineShotId, selected_vaccine: selectedVaccine })
		}
	};

	static getDerivedStateFromProps(props, state) {
		if (props.vaccine !== state.vaccine && props.lot_number_opened === false) {
			return {
				vaccine: props.vaccine,
			};
		} else if (props.vaccine !== state.vaccine && props.lot_number_opened === true && props.verify_vaccination_detail !== null) {
			return {
				isLoading: false,
				disabled: false,
				vaccine: props.vaccine,
				selected_vaccine: props.verify_vaccination_detail.selected_vaccine,
				vaccineShotId: props.verify_vaccination_detail.vaccineShotId,
				vaccination_date: props.verify_vaccination_detail.vaccination_date,
				vaccinated_by: props.verify_vaccination_detail.vaccinated_by,
				lot_number: props.verify_vaccination_detail.lot_number,
				vaccine_expiration_date: props.verify_vaccination_detail.vaccine_expiration_date,
				alreadyVaccinated: props.verify_vaccination_detail.alreadyVaccinated
			};
		}
		return null;
	};

	validateDate(date) {
		var re = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/;
		return re.test(date);
	};

	validateExpirationDate(date) {
		var re = /^((0?[1-9]|1[012])[- /.](19|20)?[0-9]{2})*$/;
		return re.test(date);
	};

	selectVaccine = (vaccine) => {
		let { vaccineShots } = vaccine;
		let sortVaccineShots = vaccineShots.sort(function (a, b) { return a.day - b.day })
		let shotId = sortVaccineShots.length > 0 ? !!sortVaccineShots[0].id && sortVaccineShots[0].id : null
		this.setState({
			selected_vaccine: vaccine,
			disabled: false,
			vaccineShotId: shotId,
			showDropDown: !this.state.showDropDown,
		})
	};

	showDropDownMenus = () => {
		this.setState({
			showDropDown: !this.state.showDropDown,
			// vaccinated_by: this.props.user_profile !== null ? this.props.user_profile.organization : null,
			// vaccination_date: moment().format("MM/DD/YYYY"),
			lot_number: "",
			vaccine_expiration_date: "",
			selected_vaccine: null,
			vaccineShotId: "",
			disabled: true,
			lot_number_error: false,
			vaccine_expiration_date_error: false,
			vaccinated_by_error: false,
			vaccination_date_error: false,
			invalid_vaccination_date_error: false,
			invalid_date_error: false
		});
	};

	openCamera = () => {
		let {
			vaccinated_by,
			vaccination_date,
			lot_number,
			vaccine_expiration_date,
			selected_vaccine,
			vaccineShotId,
			alreadyVaccinated
		} = this.state;
		let data = {}
		// data.testResultId = this.props.verification_test !== null ? this.props.verification_test.id : null;
		data.selected_vaccine = selected_vaccine;
		data.vaccineShotId = vaccineShotId;
		data.vaccination_date = vaccination_date;
		data.vaccinated_by = vaccinated_by;
		data.lot_number = lot_number;
		data.vaccine_expiration_date = vaccine_expiration_date;
		data.alreadyVaccinated = alreadyVaccinated;
		this.props.dispatch({ type: VERIFY_VACCINATION_DETAIL, data: data })
		this.props.dispatch({ type: LOT_NUMBER_OPENED, data: true })
		this.props.navigate("/lot-number-camera")
	};

	checkFutureDate(date) {
		let month = date.substring(0, 2);
		let year = date.substring(3, 7);
		var d = new Date();
		d.setFullYear(year);
		d.setMonth(month)
		d.setDate(null)
		return moment(d).format("MM/DD/YYYY")
	};

	onSubmit = () => {
		let {
			vaccinated_by,
			vaccination_date,
			lot_number,
			vaccine_expiration_date,
			selected_vaccine,
			vaccineShotId } = this.state;
		let isError = false;

		// if (lot_number === "" || lot_number === null || lot_number === undefined) {
		//     this.setState({ lot_number_error: true })
		//     isError = true
		// }

		if (vaccinated_by === "" || vaccinated_by === null || vaccinated_by === undefined) {
			this.setState({ vaccinated_by_error: true })
			isError = true
		}

		if (vaccination_date === "" || vaccination_date === null || vaccination_date === undefined) {
			this.setState({ vaccination_date_error: true })
			isError = true
		} else {
			let today = moment().format("MM/DD/YYYY")
			if (!this.validateDate(vaccination_date) || vaccination_date.length < 10) {
				this.setState({ invalid_vaccination_date_error: true, errorText: "Please enter valid date(MM/DD/YYYY)" });
				isError = true;
			}
			else if (moment(vaccination_date).isAfter(today)) {
				this.setState({ invalid_vaccination_date_error: true, errorText: "Future date not allowed" });
				isError = true;
			}
		}

		if (vaccine_expiration_date !== "" && vaccine_expiration_date !== null && vaccine_expiration_date !== undefined) {
			let today = moment().format("MM/DD/YYYY")
			if (!this.validateExpirationDate(vaccine_expiration_date) || vaccine_expiration_date.length < 7) {
				this.setState({ invalid_date_error: true, expirationErrorText: "Please enter valid date(MM/YYYY)" });
				isError = true;
			} else if (moment(this.checkFutureDate(vaccine_expiration_date)).isSameOrBefore(today)) {
				this.setState({ invalid_date_error: true, expirationErrorText: "Please enter future date" });
				isError = true;
			}
		}

		if (isError === false) {
			let data = {}
			data.vaccineId = selected_vaccine.id;
			data.vaccineShotId = vaccineShotId;
			data.vaccinationDate = vaccination_date;
			data.vaccinatedBy = vaccinated_by;
			data.lotNumber = lot_number;
			data.vaccineExpirationDate = (vaccine_expiration_date !== "" && vaccine_expiration_date !== undefined && vaccine_expiration_date !== null) ? this.checkFutureDate(vaccine_expiration_date) : null;
			data.lot_file = this.props.lot_number_image;
			data.file = this.props.selected_test_type_image;
			data.testTypeId = this.props.selected_test_type !== null ? this.props.selected_test_type.id : null;
			data.clientUserProfileId = this.props.user_profile !== null ? this.props.user_profile.id : null;

			this.setState({ isLoading: true })
			this.props.dispatch(uploadVaccineByUser(data, this))
		}
	};

	returnShotIndex = () => {
		let index = (this.state.selected_vaccine !== null && this.state.selected_vaccine !== undefined) ? this.state.selected_vaccine.vaccineShots.sort(function (a, b) { return a.day - b.day }).findIndex(x => x.id === this.state.vaccineShotId) : 0
		return index + 1
	};

	returnIsBooster = () => {
		let selectedVaccine = (this.state.selected_vaccine !== null && this.state.selected_vaccine !== undefined) ? this.state.vaccine.find(x => x.id === this.state.selected_vaccine.id) : null
		let isBooster = (selectedVaccine !== null && selectedVaccine !== undefined) ? selectedVaccine.vaccineShots.find(x => x.id === this.state.vaccineShotId && x.isBooster === true) : null;
		let isBoosterText = (isBooster !== null && isBooster !== undefined) ? `(Booster)` : ` `
		return isBoosterText;
	};

	uploadAction = () => {
		this.setState({ isDialog: false, isLoading: true })
		let clientUserProfileId = this.props.user_profile !== null ? this.props.user_profile : null;
		let data = JSON.parse(localStorage.getItem("testType"));
		this.props.dispatch(fetchAlreadyVaccinated(this, data, clientUserProfileId.id))
	};

	closeDialog = () => {
		this.setState({ isDialog: false })
		this.props.navigate("/my-health")
		localStorage.removeItem("testType")
	};

	render() {
		const { user_profile } = this.props;
		let imageurl = (user_profile !== null && user_profile.imageStorageKey !== null) ? `${END_POINT}image/i/${user_profile.imageStorageKey}` : null;
		return (
			<div style={styles.paper}>
				<Grid container justifyContent="center">
					<Grid item xs={12} sm={6} md={4}>
						<Typography style={styles.headerText}>Enter {this.state.testTypeData?.name}</Typography>
						<Grid style={styles.userRow}>
							<div style={styles.userImage}>
								{imageurl !== null ?
									<img src={imageurl} alt="icon" height="80" width="80"
										style={{
											backgroundColor: "white", border: "1px solid #ddd",
											borderRadius: "10px", objectFit: "cover"
										}} />
									:
									<img src={User} alt="icon" height="80" width="80"
										style={{
											backgroundColor: "white", border: "1px solid #ddd",
											borderRadius: "10px", objectFit: "cover"
										}}
									/>}
							</div>
							{user_profile !== null && <div style={styles.userProfile}>
								<div style={{ display: "flex", flexDirection: "row" }}>
									<Typography style={styles.title}>First Name:</Typography>
									<Typography style={styles.name}>{`${user_profile.firstName}`}</Typography>
								</div>
								<div style={{ display: "flex", flexDirection: "row" }}>
									<Typography style={styles.title}>Last Name:</Typography>
									<Typography style={styles.name}>{`${user_profile.lastName}`}</Typography>
								</div>
							</div>}
						</Grid>
					</Grid>
					<Grid container justifyContent="center" style={styles.container}>
						<Grid item xs={12} sm={6} md={4}>
							<div style={styles.cardItemView}>
								<div style={styles.titleView}>
									{this.state.alreadyVaccinated !== null && (this.state.alreadyVaccinated.nextVaccineShotId === null ?
										<Typography >Shot n°1</Typography>
										:
										<Typography >Shot n° {this.state.alreadyVaccinated.vaccineShotNumber} {this.returnIsBooster()}</Typography>
									)}
								</div>
								<div style={styles.activityContainer}>
									<div style={styles.backgroundDropDown}>
										<Button
											style={styles.dropDownButtonStyle}
											onClick={() => this.showDropDownMenus()}
											disabled={this.state.alreadyVaccinated !== null && (this.state.alreadyVaccinated.nextVaccineShotId === null ? false : true)}
										>
											<img src={vaccine} alt="icon" style={styles.imageStyle} />
											<Typography style={(this.state.selected_vaccine !== null && this.state.selected_vaccine !== undefined) ? styles.dropDownText : styles.dropDownText}>
												{(this.state.selected_vaccine !== null && this.state.selected_vaccine !== undefined) ? (this.state.selected_vaccine.displayName) : "Select Vaccine Manufacturer"}
											</Typography>
											<ExpandMoreOutlinedIcon style={{ color: "#FFF", width: 25, height: 25 }} />
										</Button>
									</div>
								</div>
								{this.state.showDropDown === true ?
									<div style={styles.dropDownStyle}>
										{this.state.vaccine.length > 0 ?
											this.state.vaccine.map(item =>
												<Button fullWidth key={item.name} style={styles.dropDownItemStyle} onClick={() => {
													this.selectVaccine(item)
												}}>
													<Typography style={styles.dropDownItemTextStyle}>{item.displayName}</Typography>
												</Button>) :
											<div style={styles.dropDownStyle}>
												<Typography style={styles.dropDownItemTextStyle}>There are no vaccines</Typography>
											</div>
										}
									</div> :
									<div>
										<div style={styles.formView}>
											<Typography style={styles.formTitleText}>Vaccination Date (MM/DD/YYYY)</Typography>

											<InputMask mask="99/99/9999" disabled={this.state.disabled}
												style={{
													display: 'flex',
													borderRadius: 5,
													padding: 12,
													border: "1px solid #EAEDED",
													backgroundColor: "whitesmoke",
													color: "#000",
													fontSize: 15,
													marginTop: 2,
												}}
												alwaysShowMask={false}
												maskChar={null}
												placeholder="MM/DD/YYYY"
												// maskPlaceholderTextColor={"whitesmoke"}
												onChange={(event) => this.setState({ vaccination_date: event.target.value, vaccination_date_error: false, invalid_vaccination_date_error: false })}
												value={this.state.vaccination_date} />
											{this.state.vaccination_date_error === true && <Typography style={styles.errorTextStyle}>Please enter vaccination date</Typography>}
											{this.state.invalid_vaccination_date_error === true && <Typography style={styles.errorTextStyle}>{this.state.errorText}</Typography>}
										</div>
										<div style={styles.formView}>
											<Typography style={styles.formTitleText}>Vaccinated By</Typography>
											<InputBase
												disabled={this.state.disabled}
												keyboardtype={"default"}
												type={'string'}
												placeholder="Vaccination Site/ Healthcare Professional"
												fullWidth
												style={styles.textInputStyleDisabled}
												autoCapitalize='none'
												maxLength={10}
												value={this.state.vaccinated_by}
												onChange={(event) => this.setState({ vaccinated_by: event.target.value, vaccinated_by_error: false, })}
											/>
											{this.state.vaccinated_by_error === true && <Typography style={styles.errorTextStyle}>Please enter vaccinated organization</Typography>}
										</div>
										<div style={styles.formView}>
											<Typography style={styles.formTitleText}>Lot Number (if available)</Typography>
											<div style={styles.lotView}>
												<div style={styles.textInputOuterFlexItem1}>
													<InputBase
														disabled={this.state.disabled}
														keyboardtype={"number-pad"}
														type={'string'}
														fullWidth
														placeholder="Numeric or Alphanumeric"
														style={styles.textInputStyleDisabled}
														autoCapitalize='none'
														maxLength={10}
														value={this.state.lot_number}
														onChange={(event) => this.setState({ lot_number: event.target.value.replace(/[^A-Za-z0-9 ]/ig, ''), lot_number_error: false })}
													/>
												</div>
												<div>
													<Button
														style={styles.textInputStyleDisabled}
														onClick={() => this.openCamera()}
														disabled={this.state.disabled}
													>
														{this.props.lot_number_image !== null ?
															<img alt="lot" src={this.props.lot_number_image} style={styles.lotimage} />
															: <CameraAltIcon
																style={{ width: 22, height: 22, color: "#000", padding: 5 }}
															/>}
													</Button>
												</div>
											</div>
											{this.state.lot_number_error === true && <Typography style={styles.errorTextStyle}>Please enter lot number</Typography>}
										</div>
										<div style={styles.formView}>
											<Typography style={styles.formTitleText}>Vaccine Expiration Date (if available)</Typography>

											<InputMask mask="99/9999"
												style={{
													borderRadius: 5,
													display: 'flex',
													padding: 10,
													border: "1px solid #EAEDED",
													backgroundColor: "whitesmoke",
													color: "#000",
													fontSize: 15,
													marginTop: 2,
												}}
												alwaysShowMask={false}
												disabled={this.state.disabled}
												maskChar={null}
												placeholder="MM/YYYY"
												onChange={(event) => this.setState({ vaccine_expiration_date: event.target.value, vaccine_expiration_date_error: false, invalid_date_error: false })}
												value={this.state.vaccine_expiration_date} />
											{/* {this.state.vaccine_expiration_date_error === true && <Typography className={classes.errorTextStyle}>Please enter vaccine expiration date</Typography>} */}
											{this.state.invalid_date_error === true && <Typography style={styles.errorTextStyle}>{this.state.expirationErrorText}</Typography>}

										</div>
										<Grid container justify="center">
											<Button
												type="Button"
												fullWidth
												variant="contained"
												color="primary"
												onClick={() => this.onSubmit()}
												disabled={this.state.disabled}
												style={styles.nextButton}
											>
												Next
											</Button>
										</Grid>
										<Typography style={{ color: 'grey', fontSize: 10, marginTop: 10 }}>
											Warning: Fraud and/or falsification of medical records may be subject to severe criminal and civil penalties.
										</Typography>
									</div>
								}
							</div>
						</Grid>
					</Grid>
				</Grid>
				<Dialog
					open={this.state.isDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					maxWidth='xs'>
					<DialogContent>
						<Typography style={{ fontWeight: "bold" }}>
							Have you already received your 2nd shot and would you like to upload it? (This is necessary even if the 2nd shot is recorded on the same document as the 1st shot.)
						</Typography>
					</DialogContent>
					<DialogActions style={{ justifyContent: "center" }}>
						<Button type="Button" variant="contained" style={{ fontSize: 15, fontWeight: "bold", backgroundColor: "#D4D2CA", color: "#000" }} onClick={() => this.closeDialog()}>
							Not yet
						</Button>
						<Button type="Button" color="primary" variant="contained" style={{ fontSize: 15, fontWeight: "bold", backgroundColor: THEMECOLOR.PRIMARY, color: "#fff" }} onClick={() => this.uploadAction()}>
							Upload 2nd Shot
						</Button>
					</DialogActions>
				</Dialog>
				{this.state.isLoading && (
					<div
						style={{
							position: 'fixed',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(255, 255, 255, 0.8)',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							zIndex: 9999
						}}
					>
						<CircularProgress style={{ color: '#000' }} />
					</div>
				)}
			</div>
		);
	}
};

const mapStateToProps = (state) => {
	return {
		vaccine: state.vaccineReducer.vaccine,
		verification_test: state.testReducer.verification_test,
		test_result: state.testReducer.test_result,
		user_profile: state.userReducer.user_profile,
		lot_number_image: state.vaccineReducer.lot_number_image,
		verify_vaccination_detail: state.vaccineReducer.verify_vaccination_detail,
		lot_number_opened: state.vaccineReducer.lot_number_opened,
		selected_test_type: state.testReducer.selected_test_type,
		selected_test_type_image: state.testReducer.selected_test_type_image,
		already_vaccinated: state.testtypeReducer.already_vaccinated,
		testtype: state.testtypeReducer.testtype
	};
};

Vaccination.propTypes = {
	// classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps)(Vaccination))