import api from '../api';
import { FETCH_TEST_RESULT_DETAIL } from "./actionTypes"

export function fetchTestResultDetail(self,data) {
    return function (dispatch) {
        api.get('user-passcode/' + data).then((response) => {
                if (response.status === "success") {
                    if(response.payload !== null){
                        dispatch({
                            type: FETCH_TEST_RESULT_DETAIL,
                            data: response.payload,
                            vaccines: response.vaccineData
                        });
                    } else {
                        self.setState({error: true, errorMessage: response.message})
                    }
                }
            });
    }
}