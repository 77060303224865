import React, { Component } from "react";
import { Grid, Typography, TextField, Button, FormHelperText, Dialog, DialogContent, DialogTitle, DialogActions } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { THEMECOLOR } from "../../helpers/Constants";
import { ALERT_DETAIL } from "../../actions/actionTypes";

const DOT_LENGTH = 10;

/** Created common styles for UI */
const styles = {
    paper: {
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        height: '1vh'
    },
    topContainer: {
        backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderBottomLeftRadius: "10%",
        borderBottomRightRadius: "10%"
    },
    bottomContainer: {
        marginTop: -40,
        backgroundColor: "#fff",
        borderRadius: 10,
        margin: "0px 10px",
        padding: "10px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s",
        width: '40px%',
    },
    title: {
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "center",
        padding: 5,
        color: "#fff",
    },
    topText: {
        textAlign: "center",
        fontSize: 18,
        paddingVertical: 5,
        fontWeight: "bold"
    },
    secondaryText: {
        textAlign: "center",
        fontSize: window.screen.width >= 1280 ? 16 : 14,
        paddingVertical: 5,
        color: "#000"
    },
    innerInpuView: {
        display: "flex",
        flexDirection: 'column',
        borderBottomWidth: 1,
        borderBottomColor: "#F6F6F6"
    },
    textView: {
        paddingVertical: 5
    },
    pickView: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center"
    },
    infoContainer: {
        display: 'flex',
        height: '100%',
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 15
    },
    dotView: {
        display: 'flex',
        flexDirection: "row",
        width: "90%",
        alignItems: "center",
        justifyContent: "space-around",
        marginTop: -100
    },
    borderDot: {
        width: 24,
        height: 24,
        borderRadius: 12,
        backgroundColor: "#fff",
        borderWidth: 6,
        borderColor: "rgba(265,265,265,0.2)"
    },
    continueText: {
        fontSize: 15,
        color: "#ffffff",
        fontWeight: "bold",
        textTransform: 'uppercase'
    },
    buttonContainers: {
        padding: 30,
        borderRadius: 10,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
    },
    buttons: {
        marginTop: 20,
        marginBottom: 10,
        padding: 15,
        borderRadius: 5,
        fontSize: 15,
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.main
    },
    buttonDisable: {
        marginTop: 20,
        marginBottom: 10,
        padding: 15,
        borderRadius: 5,
        fontSize: 15,
        fontWeight: "bold",
        backgroundColor: "gray",
    },
    buttonMouseOver: {
        marginTop: 20,
        marginBottom: 10,
        padding: 15,
        borderRadius: 10,
        fontSize: 15,
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.main,
    },
    dotStyle: {
        width: 12,
        height: 12,
        borderRadius: 6,
        backgroundColor: "rgba(265,265,265,0.5)"
    }
};

class DeleteOtpScreen extends Component {
    constructor() {
        super();
        this.state = {
            otp: "",
            otpError: false,
            invalidOtpError: false,
            isEnabledResendButton: false,
            resendHover: false,
            isDialog: false
        }
    }

    renderDotView = () => {
        let DotArray = [];
        for (let i = 0; i < DOT_LENGTH; i++) {
            DotArray.push(<div key={i.toString()} id={i}
                style={{
                    width: 4,
                    height: 4,
                    borderRadius: 2,
                    backgroundColor: "rgba(265,265,265,0.5)"
                }} />)
        }
        return DotArray;
    };

    otpValidation = (otp) => {
        return /^\d{6}$/.test(otp);
    };

    resendOtp = () => {
        //     this.setState({ otp: "" })
        //     this.props.dispatch(resendOtp(this));
    };

    handleSubmit = () => {
        this.setState({ isDialog: true })
        // let { otp } = this.state;
        // let isError = false;
        // if (otp === "" || otp === null) {
        //     this.setState({ otpError: true });
        //     isError = true;
        // } else if (!this.otpValidation(otp)) {
        //     this.setState({ invalidOtpError: true });
        //     isError = true;
        // }

        // if (isError === false || isError === true) {

        // }
    };

    onChangeOtp = (event) => {
        this.setState({ otp: event.target.value, otpError: false, invalidOtpError: false, isEnabledResendButton: false },
            // () => {
            // if (this.state.otp.length === 6) {
            //     this.onSubmit();
            // }
            //  }
        );
    };

    changeColorForMouseOver = () => {
        this.setState({ resendHover: true })
    };

    changeColorForMouseLeave = () => {
        this.setState({ resendHover: false })
    };

    handleCancel = () => {
        this.setState({ isDialog: false })
        this.props.navigate("/")
    };

    handleDelete = () => {
        this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Your Account has been deleted successfully", severity: "success" })
        this.props.navigate("/")
    };

    render() {
        return (
            <div style={styles.paper}>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <div style={styles.topContainer}>
                            <Typography style={styles.title}>Security Code Verification</Typography>
                        </div>
                        <div>
                            <div style={styles.infoContainer}>
                                <div style={styles.dotView}>
                                    <div style={styles.dotStyle} />
                                    {this.renderDotView()}
                                    <div style={styles.borderDot} />
                                    {this.renderDotView()}
                                    <div style={styles.dotStyle} />
                                </div>
                            </div>
                        </div>
                        <Grid container justifyContent="center">
                            <Grid item style={{ marginTop: -40 }} xs={12} sm={6} md={4}>
                                <div style={styles.bottomContainer}>
                                    <Typography style={styles.topText}>Enter Security Verification Code</Typography>
                                    <Typography style={styles.secondaryText}>Enter the security verification code sent to your mobile</Typography>
                                    <div style={styles.textView}>
                                        <div style={styles.innerInpuView}>
                                            <div style={styles.pickView} />
                                            <TextField
                                                fullWidth
                                                id="otp"
                                                name="otp"
                                                margin="normal"
                                                color={"primary"}
                                                variant="standard"
                                                label="Security Verification Code"
                                                autoComplete="off"
                                                value={this.state.otp}
                                                onChange={(event) => this.onChangeOtp(event)}
                                                error={this.state.otpError}
                                            />
                                            {this.state.otpError &&
                                                <FormHelperText style={{ color: theme.palette.secondary.error, marginLeft: "5px" }}>Enter security verification code</FormHelperText>
                                            }
                                            {this.state.invalidOtpError &&
                                                <FormHelperText style={{ color: theme.palette.secondary.error, marginLeft: "5px" }}>Enter valid security verification code</FormHelperText>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={6} md={4}>
                                <div style={styles.buttonContainers}>
                                    {this.state.isEnabledResendButton === false ?
                                        <Button
                                            onMouseOver={() => this.changeColorForMouseOver()}
                                            onMouseLeave={() => this.changeColorForMouseLeave()}
                                            type="Button"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            style={this.state.resendHover ? styles.buttonMouseOver : styles.buttonDisable}>
                                            <Typography style={styles.continueText}>Resend</Typography>
                                        </Button> :
                                        <Button
                                            type="Button"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            style={styles.buttons}
                                            onClick={() => this.resendOtp()}
                                        >
                                            <Typography style={styles.continueText}>Resend</Typography>
                                        </Button>
                                    }
                                    <Button
                                        type="Button"
                                        fullWidth
                                        variant="contained"
                                        color={"primary"}
                                        style={styles.buttons}
                                        onClick={() => this.handleSubmit()}
                                    >
                                        <Typography style={styles.continueText}>Continue</Typography>
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                        <Dialog
                            maxWidth={"sm"}
                            open={this.state.isDialog}>
                            <DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
                                <Typography style={{ fontSize: 16, padding: "2%", color: "#fff", fontWeight: "bold", textAlign: "center", textTransform: "uppercase" }}>Delete Confirmation</Typography>
                            </DialogTitle>
                            <DialogContent style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ ...styles.secondaryText, fontSize: 14 }}>Are you sure you want to delete your account?</div>
                                <div style={{ ...styles.secondaryText, fontSize: 14 }}>This action is irreversible!!!</div>
                            </DialogContent>
                            <DialogActions>
                                <Button size="small" onClick={() => this.handleDelete()} variant="contained" color="primary" style={{ backgroundColor: theme.palette.error.main, color: "#fff", fontSize: 14, textTransform: "none" }}>Yes I am sure, delete my account</Button>
                                <Button size="small" onClick={() => this.handleCancel()} variant="contained" color="secondary" style={{ backgroundColor: theme.palette.primary.main, color: "#fff", fontSize: 14, textTransform: "none" }}>No, do NOT delete my account</Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            </div>
        )
    }
};

const mapStateToProps = () => {
    return {

    }
};

export default withRouter(connect(mapStateToProps)(DeleteOtpScreen));