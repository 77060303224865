import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Typography, Grid, Dialog, CircularProgress, Button } from "@mui/material";
import { connect } from "react-redux";
import { registerMatchImage } from "../../actions/registerAction";
import { withRouter } from "../../withRouter";
import theme from "../../theme";

const styles = {
    mainContainer: {
        backgroundColor: "#FFFFFF"
    },
    infoText: {
        textAlign: "center",
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 16,
        color: "rgba(0,0,0,0.5)"
    },
    iconStyle: {
        marginRight: 10
    },
    uploadText: {
        padding: 3,
        fontSize: 16,
        color: "rgba(0,0,0,0.5)"
    },
    scanText: {
        paddingTop: 5,
        fontSize: 18,
        color: "#000"
    },
    submitButton: {
        marginTop: 40,
        backgroundColor: "#5186E7",
        marginHorizontal: 15,
        padding: 15,
        borderRadius: 10
    },
    buttonText: {
        color: "#fff",
        textAlign: "center",
        fontSize: 20,
        textTransform: 'capitalize'
    },
    iconButton: {
        backgroundColor: "#F8F8FA",
        borderRadius: 20
    },
    buttonContainer: {
        flex: 0.15 / 1,
        justifyContent: "center",
        alignSelf: "center",
        width: "80%",
        borderRadius: 8,
        backgroundColor: "#4B86E3",
        // paddingVertical: 15,
        marginBottom: 20
    },
    outerModalView: {
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "center",
        padding: 20,
        backgroundColor: "rgba(0,0,0,0.5)"
    },
    innerModalView: {
        width: "90%"
    },
    modalCard: {
        padding: 10,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: 10
    },
    modalImageStyle: {
        width: 120,
        height: 100,
        marginTop: 25,
        resizeMode: "contain"
    },
    modalTopTextView: {
        paddingVertical: 5
    },
    modalTopText: {
        fontSize: 20,
        paddingTop: 15,
        textAlign: "center"
    },
    modalSecondText: {
        fontSize: 16,
        lineHeight: 25,
        paddingVertical: 10,
        color: "#7F8EB8",
        textAlign: "center"
    },
    modalBottomText: {
        fontSize: 18,
        paddingVertical: 10,
        color: "#79a2ec",
        textAlign: "center"
    },
    divider: {
        width: "105%",
        height: 2,
        marginVertical: 2,
        backgroundColor: "rgba(0,0,0,0.1)"
    },
    modalButton: {
        marginTop: 15,
        backgroundColor: "white",
        width: "100%",
        padding: 15,
        borderRadius: 10,
        justifyContent: "center",
        alignItems: "center"
    },
    modalButtonText: {
        textAlign: "center",
        fontSize: 18
    },
    outerContainer: {
        paddingTop: 20,
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
        borderColor: "rgba(0, 0, 0, 0.2)",
        borderStyle: "solid",
        borderWidth: 0.5,
        shadowColor: "#fff",
        marginBottom: 18,
        shadowRadius: 4,
        //shadowOpacity: 10,
        elevation: 3,
        alignItems: 'center',
        justifyContent: 'center',
        width: "90%",
        // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s"
    },
    cardContainer: {
        padding: "0 10px 0 10px",
    },
    passportImage: {
        width: "70%",
        height: '180px',
        objectFit: 'cover',
        borderRadius: 7
    },
    selfieImage: {
        width: "70%",
        height: '180px',
        objectFit: 'cover',
        borderRadius: 7
    },
    bottomHeaderContainer: {
        justifyContent: 'center',
        alignItems: "center",
        borderTopStyle: 'solid',
        borderTopColor: "rgba(0, 0, 0, 0.1)",
        borderTopWidth: 1,
        width: "100%",
        textAlign: 'center',
        transition: "0.3s",
        //boxShadow: "0 0 4px 0 rgba(0,0,0,0.2)",
    },
    spinnerTextStyle: {
        color: '#FFF'
    },
    scrollContainer: {
        paddingTop: 18
    },
    button: {
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
        width: "85%",
        //width: "400px",
        marginBottom: 10,
        padding: 10,
        borderRadius: 5,
        fontSize: 15,
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.main
    },
    mainTitleText: {
        padding: 10,
        textAlign: "center",
        fontSize: 20,
        fontWeight: 700
    },
    dialogPaper: {
        backgroundColor: "rgba(0,0,0,0)",
        boxShadow: "none",
        overflow: "hidden"
    },
    retakePhotoButton: {
        marginBottom: 10,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
        fontSize: 12
    }
};

class IDcardandSelfieVerification extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false
        }
    }

    submitAction = async () => {
        this.setState({ isLoading: true })
        await this.props.dispatch(registerMatchImage(this));
    };

    render() {

        return (
            <Grid container justifyContent={"center"}>
                <Grid xs={12} md={4} sm={6}  >
                    <div style={styles.mainContainer}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            //backgroundColor:'red'
                        }}>
                            <Typography style={styles.mainTitleText}>Verification</Typography>
                            <div style={styles.outerContainer}>
                                <div style={{
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <img
                                        alt="verification"
                                        src={this.props.id_file}
                                        style={styles.passportImage}
                                    />

                                </div>
                                <div style={styles.cardContainer}>
                                    <Typography style={styles.infoText}>
                                        Picture of your passport or ID to verify ​your information.
                                    </Typography>
                                    {/* <Typography className={classes.infoText}></Typography> */}
                                </div>
                                <div style={styles.bottomHeaderContainer}>
                                    <Typography style={styles.scanText}>Driver's License</Typography>
                                    <Typography style={styles.uploadText}>
                                        {"Uploaded"}
                                    </Typography>
                                    <Button
                                        type="Button"
                                        // fullWidth
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.props.navigate('/id-camera', { reTakeId: "reTakeId" })}
                                        style={styles.retakePhotoButton}
                                    >
                                        <Typography style={styles.buttonText}>Retake ID</Typography>
                                    </Button>
                                </div>
                            </div>
                            <div style={styles.outerContainer}>
                                <div style={{
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <img
                                        alt="verification"
                                        src={this.props.user_img_file}
                                        style={styles.passportImage}
                                    />
                                </div>
                                <div style={styles.cardContainer}>
                                    <Typography style={styles.infoText}>
                                        Your selfie to match with your ID to verify identity.
                                    </Typography>
                                    {/* <Typography className={classes.infoText}>to verify identity.</Typography> */}
                                </div>
                                <div style={styles.bottomHeaderContainer}>
                                    <Typography style={styles.scanText}>Selfie Photo</Typography>
                                    <Typography style={styles.uploadText}>
                                        {"Uploaded"}
                                    </Typography>
                                    <Button
                                        type="Button"
                                        // fullWidth
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.props.navigate('/selfie-camera', { reTakeSelfie: "reTakeSelfie" })}
                                        style={styles.retakePhotoButton}
                                    >
                                        <Typography style={styles.buttonText}>  Retake Photo</Typography>
                                    </Button>
                                </div>
                            </div>
                            {/* <Button className={classes.buttonContainer} onClick={() => this.submitData()}> */}
                            <Button
                                type="Button"
                                // fullWidth
                                variant="contained"
                                color="primary"
                                onClick={() => this.submitAction()}
                                style={styles.button}
                            >
                                <Typography style={styles.buttonText}>  Confirm </Typography>
                            </Button>
                        </div>
                    </div>
                </Grid>
                <Dialog open={this.state.isLoading}
                    classes={{
                        paper: styles.dialogPaper,
                    }}
                >
                    <div style={{ boxShadow: 0, overflow: "none" }}>
                        <CircularProgress color="primary" size={40} />
                    </div>
                </Dialog>
            </Grid>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        id_file: state.registerReducer.id_file,
        id_file_base64: state.registerReducer.id_file_base64,
        user_img_file: state.registerReducer.user_img_file,
        user_img_file_base64: state.registerReducer.user_img_file_base64,
    };
};

IDcardandSelfieVerification.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(IDcardandSelfieVerification));