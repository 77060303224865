import React, { Component } from "react";
import { Typography, Grid } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import IdCard from "../../assets/images/idCard.svg";
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { THEMECOLOR } from "../../helpers/Constants";

const DOT_LENGTH = 10;

const styles = {
  topContainer: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
    height: "20vh",
    //display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 25,
    borderBottomLeftRadius: "10%",
    borderBottomRightRadius: "10%",
  },
  textStyle: {
    fontSize: 20,
    color: "#fff",
  },
  infoContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    //marginTop: -DEVICE_WIDTH,
    justifyContent: "center",
    alignItems: "center",
  },
  dotView: {
    display: "flex",
    flexDirection: "row",
    width: "90%",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: -80,
  },
  borderDot: {
    width: 15,
    height: 15,
    borderRadius: 12,
    backgroundColor: "#fff",
    borderWidth: 6,
    borderColor: "rgba(265,265,265,0.2)",
  },
  dotStyle: {
    width: 10,
    height: 10,
    borderRadius: 6,
    backgroundColor: "rgba(265,265,265,0.5)",
  },
  imageStyle: {
    width: "200px", borderRadius: "5px", margin: 15,
    [theme.breakpoints.up('sm')]: {
      width: "300px"
    },
  }
};
class professionalDoc extends Component {
  // static contextTypes = {
  //   router: PropTypes.object,
  // };
  constructor() {
    super();
    this.state = {};
  }

  renderDotView = () => {
    let DotArray = [];
    for (let i = 0; i < DOT_LENGTH; i++) {
      DotArray.push(
        <div
          key={i.toString()}
          id={i}
          style={{
            width: 4,
            height: 4,
            borderRadius: 2,
            backgroundColor: "rgba(265,265,265,0.5)",
          }}
        />
      );
    }
    return DotArray;
  };

  render() {
    // const { styles } = this.props;

    return (
      <div style={{ backgroundColor: "#efefef", height: "100vh" }}>
        <Grid container justifyContent={"center"} alignContent="space-between">
          <Grid item xs={12}>
            <div style={styles.topContainer}>
              <Typography
                style={{
                  padding: "5px 0px", textAlign: "center", fontSize: 20,
                  color: "#fff",
                }}
              >
                Professional ID
              </Typography>
              <div style={styles.infoContainer}>
                <div style={styles.dotView}>
                  <div style={styles.dotStyle} />
                  {this.renderDotView()}
                  <div style={styles.borderDot} />
                  {this.renderDotView()}
                  <div style={styles.dotStyle} />
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={11}
            sm={4}
            style={{
              textAlign: "center",
              marginTop: "-10vh",
              height: "60vh",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  padding: "20px",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  dispaly: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  justifyContent: "space-around",
                  marginTop: "2vh",
                }}
              >
                <img src={IdCard} height={100} alt="icon" />
                <Typography style={{ color: "grey", fontsize: "20px", margin: "10px 0" }}>Scan your Professional ID Card.</Typography>
                <Typography style={{ fontSize: "14px", margin: "25px 0", padding: "0 25px" }}> Make Sure entire document is within frame</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  dispaly: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  justifyContent: "space-around",
                  marginTop: "15vh",

                }}
              >
                <Typography
                  //   component={Link}              
                  //   to={"/professional-document"}
                  onClick={() => this.props.navigate("/open-camera")}
                  style={{
                    color: "#FFFFFF",
                    padding: "15px",
                    fontSize: "18px",
                    textDecoration: "none",
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "5px",
                    cursor: "pointer"
                  }}
                >
                  Open Camera
                </Typography>
                {this.props.selected_user_type !== "MSP" &&
                  <Typography
                    //   component={Link}              
                    onClick={() => this.props.navigate("/professional-information", { skip: "skip" })}
                    style={{
                      color: "#FFFFFF",
                      padding: "15px",
                      fontSize: "18px",
                      textDecoration: "none",
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: "5px",
                      margin: "10px 0 0 0",
                      cursor: "pointer"
                    }}
                  >
                    Skip
                  </Typography>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_type: state.sessionReducer.user_type,
    selected_user_type: state.userReducer.selected_user_type,
  };
};

professionalDoc.propTypes = {
  styles: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(professionalDoc));
