
import { FETCH_SETTINGS, PREMIUM_USER_SUPPORT } from "../actions/actionTypes"

const initialstate = {
    settings: [],
    premiumUserSupporting: null
}

export default function settingsReducer(state = initialstate, action) {

    switch (action.type) {

        case FETCH_SETTINGS:
            return {
                ...state,
                settings: action.data
            }
        case PREMIUM_USER_SUPPORT:
            return {
                ...state,
                premiumUserSupporting: action.data
            }
        default:
            return state;
    }
}