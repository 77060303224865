import { FETCH_CREDIT_COUPONS } from "../actions/actionTypes"

const initialstate = {
    creditCoupons: []
}

export default function creditCouponReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_CREDIT_COUPONS:
            return {
                ...state,
                creditCoupons: action.data
            }
        default:
            return state;
    }
}