import React, { Component } from "react";
import { Typography, Grid, Button, InputBase, CircularProgress, Dialog, DialogTitle, IconButton } from "@mui/material";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import vaccine from '../../assets/images/syringe.png';
import moment from 'moment';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import InputMask from "react-input-mask";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { uploadVaccine, fetchAlreadyVaccined, fetchVaccineBasedOnType } from "../../actions/vaccineAction";
import { END_POINT, THEMECOLOR } from "../../helpers/Constants";
import { fetchUserProfile } from "../../actions/userAction";
import { VERIFY_VACCINATION_DETAIL, LOT_NUMBER_OPENED } from "../../actions/actionTypes";
import CancelIcon from '@mui/icons-material/Cancel';
import User from "../../assets/images/account_circle.png";
import { withRouter } from "../../withRouter";

/** Created common styles for UI  */
const styles = {
	paper: {
		width: '100%',
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
		height: '100vh',
		paddingBottom: window.screen.width >= 640 ? 80 : 20,
		alignItems: 'center',
		justifyContent: "center",
	},
	container: {
		display: 'flex',
		width: '100%',
		margin: "0px 10px 0px 10px",
		minHeight: "590px",
	},
	button: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		marginTop: 5,
		padding: 7,
		fontSize: 15,
		backgroundColor: "#4B86E3",
		color: "#fff",
		fontWeight: "bold",
		borderRadius: 5,
		'&:hover': {
			backgroundColor: "#4B86E3",
		},
	},
	headerText: {
		color: '#ffffff',
		fontSize: 18,
		marginTop: 10,
		fontWeight: '700',
		textAlign: 'center'
	},
	userRow: {
		display: 'flex',
		alignItems: "center",
		flexDirection: "row",
		padding: 20,
	},
	userImage: {
		padding: 10,
	},
	image: {
		width: 100,
		height: "auto",
		borderRadius: 14,
		backgroundColor: "#fff"
	},
	userProfile: {
		paddingLeft: 20,
	},
	title: {
		fontSize: 18,
		color: "#fff",
	},
	name: {
		marginLeft: "10px",
		fontSize: 20,
		color: "#fff",
		fontWeight: 'bold'
	},
	cardItemView: {
		// padding: 10,
		// paddingBottom: 20,
		// flex: 1,
		padding: "20px",
		paddingBottom: 20,
		flex: 1,
		backgroundColor: "#FFF",
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		borderRadius: "10px"
	},
	titleView: {
		display: 'flex',
		flexDirection: "row",
		justifyContent: 'center',
		alignItems: "center"
	},
	imageStyle: {
		width: 25,
		height: 25,
	},
	dropDownText: {
		color: "#FFF",
		fontSize: 15,
		marginLeft: 17,
		fontWeight: "100",
		textTransform: 'capitalize'
	},
	backgroundDropDown: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: "#EAEDED",
		borderRadius: 10,
		elevation: 2,
		paddingBottom: 6,
	},
	activityContainer: {
		paddingTop: 20,
		paddingBottom: 20
	},
	dropDownButtonStyle: {
		display: 'flex',
		flexDirection: "row",
		border: "1px solid #EAEDED",
		padding: 12,
		elevation: 3,
		alignSelf: 'center',
		alignItems: "center",
		borderRadius: 10,
		width: "100%",
		justifyContent: "space-between",
		backgroundColor: THEMECOLOR.PRIMARY
	},
	formView: {
		display: 'flex',
		paddingTop: 6,
		paddingBottom: 6,
		flexDirection: 'column'
	},
	formTitleText: {
		color: "#000",
		fontSize: 14,
		paddingLeft: 4,
		paddingBottom: 2
	},
	textInputStyleDisabled: {
		borderRadius: 5,
		padding: 5,
		paddingLeft: 10,
		paddingRight: 10,
		border: "1px solid #EAEDED",
		backgroundColor: "whitesmoke",
		color: "#000",
		fontSize: 15,
		marginTop: 2,
	},
	lotView: {
		display: 'flex',
		flexDirection: "row",
		alignSelf: 'center',
		alignItems: "center",
		justifyContent: 'space-between',
		width: '100%'
	},
	textInputOuterFlexItem1: {
		width: "76%",
	},
	nextButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		marginTop: 5,
		padding: 7,
		fontSize: 15,
		backgroundColor: THEMECOLOR.PRIMARY,
		color: "#FFF",
		textTransform: "uppercase",
		fontWeight: "bold",
		borderRadius: 10,
		'&:hover': {
			backgroundColor: THEMECOLOR.PRIMARY,
		},
	},
	nextText: {
		marginTop: 5,
		fontSize: 15,
		color: "#ffffff",
		textTransform: 'capitalize'
	},
	dropDownStyle: {
		border: "1px solid #EAEDED",
		borderRadius: 10,
		paddingLeft: 10,
		paddingRight: 10,
		marginLeft: 12,
		marginRight: 12,
	},
	dropDownItemStyle: {
		padding: 10,
		borderBottom: "1px solid #D4D2CA",
	},
	dropDownItemTextStyle: {
		color: "#6F7FAF",
		fontSize: 15,
		paddingTop: 5,
		paddingBottom: 5,
		textTransform: 'capitalize'
	},
	lotimage: {
		width: "auto",
		height: 27
	},
	errorTextStyle: {
		fontSize: 13,
		color: "red"
	},
	dialogPaper: {
		backgroundColor: "rgba(0,0,0,0)",
		boxShadow: "none",
		overflow: "hidden"
	},
	imageButton: {
		padding: "5px 10px",
		borderRadius: 5,
		fontSize: 15,
		fontWeight: "bold",
		backgroundColor: '#35A773',
		color: "#fff"
	},
	closeIcon: {
		padding: 5,
		color: "#B22222"
	},
	dialogTitle: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "0px"
	},
};

/**
 * @class
 *
 * Class representing Vaccination component
 *
 */
class Vaccination extends Component {
	constructor() {
		super();
		this.state = {
			vaccination_date: '',
			vaccinated_by: '',
			lot_number: '',
			vaccine_expiration_date: '',
			selected_vaccine: null,
			vaccineShotId: "",
			showDropDown: false,
			disabled: true,
			lot_number_error: false,
			vaccine: [],
			alreadyVaccinated: null,
			vaccination_date_error: false,
			invalid_vaccination_date_error: false,
			vaccinated_by_error: false,
			invalid_date_error: false,
			vaccine_expiration_date_error: false,
			lot_number_image: null,
			lot_number_base64: null,
			isLoading: false,
			testimageurl: null,
			imageDialog: false,
			errorText: "",
			expirationErrorText: ""
		}
	}

	componentDidMount = () => {
		/** fetching user profile details. */
		this.props.dispatch(fetchUserProfile())
		/** fetching vaccine details. */
		// this.props.dispatch(fetchVaccine())
		let testID = this.props.verification_test !== null ? this.props.verification_test.id : null;
		/** fetching vaccine details based on type. */
		this.props.dispatch(fetchVaccineBasedOnType(this.props.verification_test?.testType, this))
		/** fetching already vaccined  user details. */
		this.props.dispatch(fetchAlreadyVaccined(testID, this))
		if (this.props.verification_test !== null && this.props.verification_test.vaccineResults.length > 0) {
			let vaccineResult = this.props.verification_test.vaccineResults[0];
			this.setState({
				isLoading: false,
				disabled: false,
				vaccineId: vaccineResult.vaccineId,
				vaccineShotId: vaccineResult.vaccineShotId,
				vaccination_date: moment(vaccineResult.vaccinationDate).format("MM/DD/YYYY"),
				vaccinated_by: vaccineResult.vaccinatedBy,
				lot_number: vaccineResult.lotNumber,
				vaccineShotNumber: this.props.verification_test.shotNumber,
				vaccine_expiration_date: vaccineResult.vaccineExpirationDate !== null ? moment(vaccineResult.vaccineExpirationDate).format("MM/YYYY") : null
			})
		} else {
			let alreadyVaccinated = this.props.already_vaccinated

			if (alreadyVaccinated !== null && alreadyVaccinated !== undefined && alreadyVaccinated.nextVaccineShotId !== null) {
				this.setState({
					isLoading: false,
					disabled: false,
					vaccineId: alreadyVaccinated.vaccineId,
					vaccineShotId: alreadyVaccinated.vaccineShotNumber === 1 ? alreadyVaccinated.nextVaccineShotId : parseInt(alreadyVaccinated.nextVaccineShotId) + 1,
					vaccineShotNumber: alreadyVaccinated.vaccineShotNumber,
				})
			} else {
				this.setState({
					isLoading: false,
					disabled: true,
					vaccineId: null,
					vaccineShotId: null,
					vaccineShotNumber: null,
				})
				/** fetching already vaccined  user details. */
				this.props.dispatch(fetchAlreadyVaccined(testID, this))
			}
		}
	}

	/** This is a component life cycle method and used to assigning props values to state variable  */
	static getDerivedStateFromProps(props, state) {
		if (props.vaccine !== state.vaccine && props.lot_number_opened === false) {
			let selected_vaccine = props.vaccine.find(x => x.id === state.vaccineId)
			return {
				vaccine: props.vaccine,
				selected_vaccine: selected_vaccine !== undefined ? selected_vaccine : null,
			};
		} else if (props.vaccine !== state.vaccine && props.lot_number_opened === true && props.verify_vaccination_detail !== null) {
			return {
				isLoading: false,
				disabled: false,
				vaccine: props.vaccine,
				selected_vaccine: props.verify_vaccination_detail.selected_vaccine,
				vaccineShotId: props.verify_vaccination_detail.vaccineShotId,
				vaccination_date: props.verify_vaccination_detail.vaccination_date,
				vaccinated_by: props.verify_vaccination_detail.vaccinated_by,
				lot_number: props.verify_vaccination_detail.lot_number,
				vaccine_expiration_date: props.verify_vaccination_detail.vaccine_expiration_date,
				alreadyVaccinated: props.verify_vaccination_detail.alreadyVaccinated
			};
		}
		return null;
	}

	/**
	 * @function
	 *
	 * function representing date validation.
	 * @param {String} date The date string
	 */
	validateDate(date) {
		var re = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/;
		return re.test(date);
	}

	/**
	* @function
	*
	* function representing ExpirationDate validation.
	* @param {String} date The date string
	*/
	validateExpirationDate(date) {
		var re = /^((0?[1-9]|1[012])[- /.](19|20)?[0-9]{2})*$/;
		return re.test(date);
	}

	/**
	* @function
	*
	* function representing select vaccine.
	* @param {Object} vaccine The vaccine json object
	*/
	selectVaccine = (vaccine) => {
		let { vaccineShots } = vaccine;
		let sortVaccineShots = vaccineShots.sort(function (a, b) { return a.day - b.day })
		let shotId = sortVaccineShots.length > 0 ? sortVaccineShots[0].id : null
		this.setState({
			selected_vaccine: vaccine,
			disabled: false,
			vaccineShotId: shotId,
			showDropDown: !this.state.showDropDown,
		})
	}


	showDropDownMenus = () => {
		this.setState({
			showDropDown: !this.state.showDropDown,
			vaccinated_by: this.props.user_profile !== null ? this.props.user_profile.organization : null,
			vaccination_date: moment().format("MM/DD/YYYY"),
			lot_number: "",
			vaccine_expiration_date: "",
			// selected_vaccine: null,
			vaccineShotId: "",
			disabled: true,
			lot_number_error: false,
			vaccine_expiration_date_error: false,
			vaccinated_by_error: false,
			vaccination_date_error: false,
			invalid_vaccination_date_error: false,
			invalid_date_error: false
		});
	};

	openCamera = () => {
		let {
			vaccinated_by,
			vaccination_date,
			lot_number,
			vaccine_expiration_date,
			selected_vaccine,
			vaccineShotId,
			alreadyVaccinated
		} = this.state;
		let data = {}
		data.testResultId = this.props.verification_test !== null ? this.props.verification_test.id : null;
		data.selected_vaccine = selected_vaccine;
		data.vaccineShotId = vaccineShotId;
		data.vaccination_date = vaccination_date;
		data.vaccinated_by = vaccinated_by;
		data.lot_number = lot_number;
		data.vaccine_expiration_date = vaccine_expiration_date;
		data.alreadyVaccinated = alreadyVaccinated;
		this.props.dispatch({ type: VERIFY_VACCINATION_DETAIL, data: data })
		this.props.dispatch({ type: LOT_NUMBER_OPENED, data: true })
		this.props.navigate("/lot-number-camera")
	};

	/**
	 * @function
	 *
	 * function representing FutureDate validation.
	 * @param {String} date The date string
	 */
	checkFutureDate(date) {
		let month = date.substring(0, 2);
		let year = date.substring(3, 7);
		var d = new Date();
		d.setFullYear(year);
		d.setMonth(month)
		d.setDate(null)
		return moment(d).format("MM/DD/YYYY")
	};

	/**
	* @function
	*
	* function representing submit vaccine information.
	*/
	onSubmit = () => {
		let {
			vaccinated_by,
			vaccination_date,
			lot_number,
			vaccine_expiration_date,
			selected_vaccine,
			vaccineShotId } = this.state;
		let isError = false;

		if (vaccinated_by === "" || vaccinated_by === null || vaccinated_by === undefined) {
			this.setState({ vaccinated_by_error: true })
			isError = true
		}

		if (vaccination_date === "" || vaccination_date === null || vaccination_date === undefined) {
			this.setState({ vaccination_date_error: true })
			isError = true
		} else {
			let today = moment().format("MM/DD/YYYY")
			if (!this.validateDate(vaccination_date) || vaccination_date.length < 10) {
				this.setState({ invalid_vaccination_date_error: true, errorText: "Please enter valid date(MM/DD/YYYY)" });
				isError = true;
			}
			else if (moment(vaccination_date).isAfter(today)) {
				this.setState({ invalid_vaccination_date_error: true, errorText: "Future date not allowed" });
				isError = true;
			}
		}

		if (vaccine_expiration_date !== "" && vaccine_expiration_date !== null && vaccine_expiration_date !== undefined) {
			let today = moment().format("MM/DD/YYYY")
			if (!this.validateExpirationDate(vaccine_expiration_date) || vaccine_expiration_date.length < 7) {
				this.setState({ invalid_date_error: true, expirationErrorText: "Please enter valid date(MM/YYYY)" });
				isError = true;
			}
			else if (moment(this.checkFutureDate(vaccine_expiration_date)).isSameOrBefore(today)) {
				this.setState({ invalid_date_error: true, expirationErrorText: "Please enter future date" });
				isError = true;
			}
		}

		if (isError === false) {
			let data = {}
			data.testResultId = this.props.verification_test !== null ? this.props.verification_test.id : null;
			data.vaccineId = selected_vaccine.id;
			data.vaccineShotId = vaccineShotId;
			data.vaccinationDate = vaccination_date;
			data.vaccinatedBy = vaccinated_by;
			data.lotNumber = lot_number;
			data.vaccineExpirationDate = (vaccine_expiration_date !== "" && vaccine_expiration_date !== undefined && vaccine_expiration_date !== null) ? this.checkFutureDate(vaccine_expiration_date) : null;
			// data.lastTestResultId = this.state.alreadyVaccinated !== null ? this.state.alreadyVaccinated.lastTestResultId : null
			data.file = this.props.lot_number_image;
			this.setState({ isLoading: true })
			this.props.dispatch(uploadVaccine(data, this))
		}
	};
	/** this function used to open image dialog */
	openImage = () => {
		let testimageurl = `${END_POINT}image/v/${this.props.verification_test.storageKey}`;
		this.setState({ testimageurl: testimageurl, imageDialog: true })
	};

	/** this function used to close image dialog */
	closeDialog = () => {
		this.setState({ testimageurl: null, imageDialog: false })
	};

	/** this function used to get the index of the vaccine shot */
	returnShotIndexText = () => {
		let index = (this.state.selected_vaccine !== null && this.state.selected_vaccine !== undefined) ? this.state.selected_vaccine.vaccineShots.sort(function (a, b) { return a.day - b.day }).findIndex(x => x.id === this.state.vaccineShotId) : 0;
		let isBooster = (this.state.selected_vaccine !== null && this.state.selected_vaccine !== undefined) ? this.state.selected_vaccine.vaccineShots.find(x => x.id === this.state.vaccineShotId && x.isBooster === true) : null;
		let isBoosterText = (isBooster !== null && isBooster !== undefined) ? `(Booster)` : ` `
		let text = `Shot n° ${index + 1} ${isBoosterText}`
		return text;
	};

	returnShotIndex = () => {
		let index = (this.state.selected_vaccine !== null && this.state.selected_vaccine !== undefined) ? this.state.selected_vaccine.vaccineShots.sort(function (a, b) { return a.day - b.day }).findIndex(x => x.id === this.state.vaccineShotId) : 0;
		return index + 1;
	};

	/** Rendering vaccination screen to verify all vaccine records details for test verifier */
	render() {
		const { test_result } = this.props;
		let imageurl = (test_result !== null && test_result.userImage !== null) ? `${END_POINT}image/i/${test_result.userImage}` : null;
		return (
			<div style={styles.paper}>
				<Grid container justifyContent="center">
					<Grid item xs={12} sm={6} md={4}>
						<Typography style={styles.headerText}>{this.props.verification_test.name}</Typography>
						<Grid style={styles.userRow}>
							<div cstyle={styles.userImage}>
								{imageurl !== null ?
									<img src={imageurl} alt="icon" style={styles.image} />
									:
									<img src={User} height="80" width="80" alt="icon" style={{
										backgroundColor: "white", border: "1px solid #ddd",
										borderRadius: "10px", objectFit: "cover"
									}} />}
							</div>
							{test_result !== null && <div style={styles.userProfile}>
								<div style={{ display: "flex", flexDirection: "row" }}>
									<Typography style={styles.title}>First Name:</Typography>
									<Typography style={styles.name}>{`${test_result.firstName}`}</Typography>
								</div>
								<div style={{ display: "flex", flexDirection: "row" }}>
									<Typography style={styles.title}>Last Name:</Typography>
									<Typography style={styles.name}>{`${test_result.lastName}`}</Typography>
								</div>
								<Button
									type="Button"
									variant="contained"
									color="primary"
									onClick={() => this.openImage()}
									style={styles.imageButton}>
									View Vaccination Record
								</Button>
							</div>}
						</Grid>
					</Grid>
					<Grid container justifyContent="center" style={styles.container}>
						<Grid item xs={12} sm={6} md={4}>
							<div style={styles.cardItemView}>
								<div style={styles.titleView}>
									{this.state.alreadyVaccinated === null ?
										<Typography >Shot n°1</Typography>
										:
										<Typography >{this.returnShotIndexText()}</Typography>
									}
								</div>
								<div style={styles.activityContainer}>
									<div style={styles.backgroundDropDown}>
										<Button
											style={styles.dropDownButtonStyle}
											onClick={() => this.showDropDownMenus()}
											disabled={this.state.alreadyVaccinated === null ? false : true}
										>
											<img src={vaccine} alt="icon" style={styles.imageStyle} />
											<Typography style={(this.state.selected_vaccine !== null && this.state.selected_vaccine !== undefined) ? styles.dropDownText : styles.dropDownText}>
												{(this.state.selected_vaccine !== null && this.state.selected_vaccine !== undefined) ? (this.state.selected_vaccine.displayName) : "Select Vaccine Manufacturer"}
											</Typography>
											<ExpandMoreOutlinedIcon style={{ color: "#FFF", width: 25, height: 25 }} />
										</Button>
									</div>
								</div>
								{this.state.showDropDown === true ?
									<div style={styles.dropDownStyle}>
										{this.state.vaccine.length > 0 ?
											this.state.vaccine.map(item =>
												<Button fullWidth key={item.name} style={styles.dropDownItemStyle} onClick={() => {
													this.selectVaccine(item)
												}}>
													<Typography style={styles.dropDownItemTextStyle}>{item.displayName}</Typography>
												</Button>) :
											<div style={styles.dropDownStyle}>
												<Typography style={styles.dropDownItemTextStyle}>There are no vaccines</Typography>
											</div>
										}
									</div> :
									<div>
										{this.state.alreadyVaccinated !== null &&
											<div style={{ borderWidth: 1, backgroundColor: "#F2F4F4", borderStyle: "solid", borderColor: "#000", borderRadius: 10, padding: 5, alignItems: "center", justifyContent: 'center' }}>
												<Typography> Shot n°{this.returnShotIndex() - 1} has been injected on:{moment(this.state.alreadyVaccinated.vaccinationDate).format("MM/DD/YYYY")}</Typography>
											</div>}
										<div style={styles.formView}>
											<Typography style={styles.formTitleText}>Vaccination Date (MM/DD/YYYY)</Typography>
											<InputMask mask="99/99/9999" disabled={this.state.disabled}
												style={{
													display: 'flex',
													borderRadius: 5,
													padding: 12,
													border: "1px solid #EAEDED",
													color: "#000",
													fontSize: 15,
													marginTop: 2,
												}}
												alwaysShowMask={false}
												maskChar={null}
												placeholder="MM/DD/YYYY"
												maskPlaceholderTextColor={"gray"}
												onChange={(event) => this.setState({ vaccination_date: event.target.value, vaccination_date_error: false, invalid_vaccination_date_error: false })}
												value={this.state.vaccination_date} />
											{this.state.vaccination_date_error === true && <Typography style={styles.errorTextStyle}>Please enter vaccination date</Typography>}
											{this.state.invalid_vaccination_date_error === true && <Typography style={styles.errorTextStyle}>{this.state.errorText}</Typography>}
										</div>
										<div style={styles.formView}>
											<Typography style={styles.formTitleText}>Vaccinated by </Typography>
											<InputBase
												disabled={this.state.disabled}
												keyboardType={"default"}
												type={'string'}
												fullWidth
												style={styles.textInputStyleDisabled}
												autoCapitalize='none'
												maxLength={10}
												value={this.state.vaccinated_by}
												onChange={(event) => this.setState({ vaccinated_by: event.target.value, vaccinated_by_error: false, })}
												baseColor={"gray"}
											/>
											{this.state.vaccinated_by_error === true && <Typography style={styles.errorTextStyle}>Please enter vaccinated organization</Typography>}
										</div>
										<div style={styles.formView}>
											<Typography style={styles.formTitleText}>Lot Number </Typography>
											<div style={styles.lotView}>
												<div style={styles.textInputOuterFlexItem1}>
													<InputBase
														disabled={this.state.disabled}
														keyboardType={"number-pad"}
														type={'string'}
														fullWidth
														style={styles.textInputStyleDisabled}
														autoCapitalize='none'
														maxLength={10}
														value={this.state.lot_number}
														onChange={(event) => this.setState({ lot_number: event.target.value.replace(/[^A-Za-z0-9 ]/ig, ''), lot_number_error: false })}
														baseColor={"gray"}
													/>
												</div>
												<div>
													<Button
														style={styles.textInputStyleDisabled}
														onClick={() => this.openCamera()}
														disabled={this.state.disabled}
													>
														{this.props.lot_number_image !== null ?
															<img alt="lot" src={this.props.lot_number_image} style={styles.lotimage} />
															: <CameraAltIcon
																style={{ width: 22, height: 22, color: "#000", padding: 5 }}
															/>}
													</Button>
												</div>
											</div>
											{this.state.lot_number_error === true && <Typography style={styles.errorTextStyle}>Please enter lot number</Typography>}
										</div>
										<div style={styles.formView}>
											<Typography style={styles.formTitleText}>Vaccine Expiration Date (MM/YYYY)</Typography>
											<InputMask mask="99/9999" style={{
												borderRadius: 5,
												display: 'flex',
												padding: 10,
												border: "1px solid #EAEDED",
												color: "#000",
												fontSize: 15,
												marginTop: 2,
											}}
												alwaysShowMask={false}
												disabled={this.state.disabled}
												maskChar={null}
												placeholder="MM/YYYY"
												onChange={(event) => this.setState({ vaccine_expiration_date: event.target.value, vaccine_expiration_date_error: false, invalid_date_error: false })}
												value={this.state.vaccine_expiration_date} />
											{this.state.invalid_date_error === true && <Typography style={styles.errorTextStyle}>{this.state.expirationErrorText}</Typography>}
										</div>
										<Grid container justify="center">
											<Button
												type="Button"
												fullWidth
												variant="contained"
												color="primary"
												onClick={() => this.onSubmit()}
												disabled={this.state.disabled}
												style={styles.nextButton}
											>
												<Typography style={styles.nextText}>Next</Typography>
											</Button>
										</Grid>
									</div>
								}
							</div>
						</Grid>
					</Grid>
				</Grid>
				<Dialog maxWidth={"xs"} fullWidth open={this.state.imageDialog} onClose={() => this.setState({ imageDialog: false })}>
					<DialogTitle id="form-dialog-title" style={{ padding: 0, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
							<div style={{ flex: 1 }}>
								{this.state.lot_number !== null && this.state.lot_number !== "" &&
									<Typography style={{ padding: "1%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>Lot Number : {this.state.lot_number} </Typography>
								}
								{this.state.vaccination_date !== null && this.state.vaccination_date !== "" &&
									<Typography style={{ padding: "1%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>Vaccination Date : {this.state.vaccination_date} </Typography>
								}
							</div>
							<IconButton onClick={() => this.closeDialog()} style={styles.closeIcon}>
								<CancelIcon fontSize="medium" />
							</IconButton>
						</div>
					</DialogTitle>
					<div style={{ overflow: "hidden" }}>
						<img src={this.state.testimageurl} alt="test-img" style={{ width: "100%", height: "auto" }} />
					</div>
				</Dialog>
				<Dialog open={this.state.isLoading}
					style={
						styles.dialogPaper
					}
				>
					<div style={{ boxShadow: 0, overflow: "none" }}>
						<CircularProgress color="primary" size={40} />
					</div>
				</Dialog>
			</div>
		);
	}
};

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {
		vaccine: state.vaccineReducer.vaccine,
		verification_test: state.testReducer.verification_test,
		test_result: state.testReducer.test_result,
		user_profile: state.userReducer.user_profile,
		lot_number_image: state.vaccineReducer.lot_number_image,
		verify_vaccination_detail: state.vaccineReducer.verify_vaccination_detail,
		lot_number_opened: state.vaccineReducer.lot_number_opened,
		already_vaccinated: state.testtypeReducer.already_vaccinated
	};
};

Vaccination.propTypes = {
	// classes: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps)(Vaccination));