import { Button, Checkbox, CircularProgress, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import SignatureCanvas from 'react-signature-canvas';
import { ALERT_DETAIL } from '../../actions/actionTypes';
import { createHipaaWaiver, fetchUserProfile } from '../../actions/userAction';
import Eraser from "../../assets/images/eraser.svg";
import Logo from '../../assets/images/medfilo.png';
import Reject from "../../assets/images/reject.svg";
import Tick from "../../assets/images/tick.svg";
import { END_POINT, THEMECOLOR } from "../../helpers/Constants";
import { withRouter } from "../../withRouter";

const styles = {
    topContainer: {
        backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderBottomLeftRadius: "10%",
        borderBottomRightRadius: "10%"
    },
    button: {
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
        padding: 7,
        fontSize: 15,
        backgroundColor: ' #35A773',
        border: `2px solid ${THEMECOLOR.PRIMARY}`,
        color: "#fff",
        textTransform: "uppercase",
        fontWeight: "bold",
        borderRadius: 5,
        marginTop: 10,
        '&:hover': {
            backgroundColor: THEMECOLOR.PRIMARY,
        },
    },
};

class HipaaWaiver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sigCanvas: null,
            signImage: false,
            url: null,
            checkBoxKey: false,
            isLoading: false
        };
        this.myRef = React.createRef();
    }

    _sigPad = null;

    static getDerivedStateFromProps(props, state) {
        if (props.user_profile !== state.user_profile) {
            return {
                user_profile: props.user_profile,
            }
        }
        return null;
    };

    getSignaturePad = () => {
        return this._sigPad
    };

    componentDidMount() {
        /** fetching user profile details. */
        this.props.dispatch(fetchUserProfile());
    };

    handleErase = () => {
        this._sigPad.clear()
    };

    handleGenerate = () => {
        if (this._sigPad.isEmpty()) {
            this.setState({ signImage: false, sigCanvas: null })
            this.props.dispatch({ type: ALERT_DETAIL, data: true, message: "Signature should be provided", severity: "error" })
        } else {
            this.setState({ signImage: true, sigCanvas: this._sigPad.getTrimmedCanvas().toDataURL('image/png') })
        }
    };

    handleReject = () => {
        this.setState({ signImage: false, sigCanvas: null })
    };

    handleCheckBoxChange = (event) => {
        this.setState({ checkBoxKey: event.target.checked })
    };

    handleSubmit = (name) => {

        const obj = {
            name: name,
            signature: this.state.sigCanvas
        };

        this.setState({ isLoading: true })
        this.props.dispatch(createHipaaWaiver(obj, this))
    };

    render() {
        const { user_profile } = this.props;
        return (
            <Grid container>
                <Grid item xs={12} style={styles.topContainer}>
                    <img alt="Logo" src={Logo} height={150} width={150} />
                </Grid>
                <Grid item xs={12}>
                    {user_profile && user_profile?.hipaaStorageKey !== null ?
                        <Grid container justifyContent="center">
                            <Grid item xs={10} sm={8} md={6} lg={5} style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}>
                                <iframe title={`${user_profile?.hipaaStorageKey}`} src={`${END_POINT}file/h/${user_profile?.hipaaStorageKey}#toolbar=0`} style={{ width: "100%", height: "90vh" }} />
                            </Grid>
                        </Grid>
                        :
                        <Grid container justifyContent="center">
                            <Grid item xs={10} sm={8} md={6} lg={5} style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}>
                                <Grid container spacing={1} justifyContent="center">
                                    <Grid item xs={10} style={{ textAlign: "center", paddingTop: "20px" }}>
                                        <Typography style={{ fontWeight: 'bold' }}>HIPAA  Acknowledgment</Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography style={{ paddingTop: "10px", textAlign: 'justify' }}>
                                            In accordance with the privacy regulations promulgated under the Health Insurance
                                            Portability and Accountability Act, 45 (forty-five) CFR parts 160 and 164 (the “Privacy Regulations”),
                                            MedFilo Inc, and Nurses Direct Employee understand and agree to abide by the Facility
                                            privacy policies and to not use or further disclose a patient's personal health information except
                                            as expressly permitted by the Agreement or as otherwise authorized in writing by the patient through
                                            a consent or authorization meeting the requirements of the Privacy Regulations. MedFilo Inc
                                            and Nurse Employee may only use a patient's personal health information for the sole purpose of treatment,
                                            and/or health care operations and may not release any information to unauthorized parties. MedFilo Inc
                                            and Nurse Employee agree to implement appropriate safeguards to prevent the unauthorized use and
                                            disclosure of any patient's personal health information received by Facility under this Agreement. In
                                            addition MedFilo Inc and Nurse Employee shall make available to the Facility the protected
                                            health information for amendment purposes, should changes to the information be necessary or to provide
                                            an accounting of disclosures of the protection health information. If any unauthorized disclosure of
                                            personal health information occurs, MedFilo Inc, and Nurse Employee shall immediately contact
                                            Facility to inform them of the disclosure and any remedial action taken to prevent further disclosures
                                            MedFilo Inc, and Nurse Employee understand that any unauthorized disclosure of a patient's
                                            personal health information is grounds for immediate termination of the Agreement and/or a staffing
                                            assignment.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Grid container style={{ justifyContent: 'space-between', alignItems: "center" }}>
                                            <Grid item xs={8}>
                                                <Typography style={{ fontWeight: 'bold' }}>Signature:</Typography>
                                            </Grid>
                                            <Grid item xs={4} style={{ display: "flex", flexDirection: "row" }}>
                                                {this.state.signImage ?
                                                    <Grid item xs={12} style={{ display: "felx", flexDirection: "row", justifyContent: "flex-end" }}>
                                                        <Button onClick={() => this.handleReject()}>
                                                            <img
                                                                alt="Reject"
                                                                src={Reject}
                                                                height={25}
                                                                width={25}
                                                            />
                                                        </Button>
                                                    </Grid>
                                                    :
                                                    <Grid item xs={12} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                                        <Button onClick={() => this.handleErase()}>
                                                            <img
                                                                alt="Eraser"
                                                                src={Eraser}
                                                                height={25}
                                                                width={25}
                                                            />
                                                        </Button>
                                                        <Button onClick={() => this.handleGenerate()}>
                                                            <img
                                                                alt="Tick"
                                                                src={Tick}
                                                                height={25}
                                                                width={25}
                                                            />
                                                        </Button>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={10} style={{ border: "2px solid #D3D3D3", padding: 0 }}>
                                        {this.state.signImage ?
                                            <Grid container>
                                                <img src={this.state.sigCanvas} alt={'signature'} className="forsign" />
                                            </Grid>
                                            :
                                            <Grid container>
                                                <SignatureCanvas
                                                    canvasProps={{ className: 'forsign' }}
                                                    ref={(ref) => { this._sigPad = ref }}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Grid container>
                                            <Grid item xs={12} sm={6}>
                                                <Typography style={{ fontWeight: 'bold' }}>Name: {`${user_profile?.firstName} ${user_profile?.lastName}`}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography sx={{ fontWeight: 'bold', textAlign: { xs: 'left', sm: 'right' } }}>Date: {moment().format('MM/DD/YYYY')}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Grid container>
                                            <Grid item xs={1}>
                                                <Checkbox
                                                    disabled={false}
                                                    value={this.state.checkBoxKey}
                                                    style={{ height: 25, width: 25 }}
                                                    onChange={(e) => this.handleCheckBoxChange(e)}
                                                />
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography>I agree to abide by all facility policies in regards to HIPAA.</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {this.state.signImage && this.state.checkBoxKey &&
                                            <Grid container style={{ justifyContent: 'center', padding: "0px 80px 20px 80px" }}>
                                                <Button
                                                    variant="contained"
                                                    type="Button"
                                                    fullWidth
                                                    style={styles.button}
                                                    onClick={() => this.handleSubmit(`${user_profile?.firstName} ${user_profile?.lastName}`)}
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                {this.state.isLoading && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 9999
                        }}
                    >
                        <CircularProgress style={{ color: '#000' }} />
                    </div>
                )}
            </Grid>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        user_profile: state.userReducer.user_profile
    }
};

export default withRouter(connect(mapStateToProps)(HipaaWaiver));