import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchDashboard, updateStatusUser, fetchTestDashboard, fetchDashboardTestResult, fetchDashboardUserResult } from '../../actions/userAction';
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import { FETCH_USER_BY_PHONE, USER_PROFILE_TEST_RESULT } from "../../actions/actionTypes"
import QRCode from "react-qr-code";
import { fetchTestByUserProfileId, updateUserVaccines } from "../../actions/testAction";
import { resendSmsToUser } from '../../actions/registerAction';
import moment from "moment";
import theme from "../../theme";
import { Button, Grid, Card, Typography, CircularProgress, Dialog, DialogTitle, DialogActions, DialogContent, List, ListItem, Popover, Table, TableHead, TableBody, TableCell, TableRow, Divider, Paper, } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

/**Created common styles for UI.*/
const styles = {
	inputStyle: {
		fontSize: "15px !important",
		padding: "5px !important",
		width: 300
	},
	inputRoot: {
		borderRadius: "0px !important"
	},
	inputLabel: {
		color: "#4B86E3"
	},
	button: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		margin: 15,
		padding: "10px 25px",
		fontSize: 15,
		backgroundColor: "#4B86E3",
		color: "#fff",
		fontWeight: "bold",
		borderRadius: 5,
		'&:hover': {
			backgroundColor: "#4B86E3",
		},
	},
	fontCellHeader: {
		color: "#fff",
		fontSize: 18,
		fontWeight: "bold"
	},
	tableHeader: {
		color: "#fff"
	},
	qrContainer: {
		display: 'flex',
		justifyContent: "center",
		padding: 20
	},
	cancelButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		fontFamily: "unicode.futurab",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(143, 163, 173, 0.8)',
		}
	},
	addButton: {
		backgroundColor: "#20478e",
		color: "white",
		fontFamily: "unicode.futurab",
		marginRight: "20",
		'&:hover': {
			backgroundColor: 'rgba(32,71,142, 0.8)',
		}
	},
	yesButton: {
		backgroundColor: "#20478e",
		color: "white",
		fontFamily: "unicode.futurab",
		marginRight: "20",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(32,71,142, 0.8)',
		}
	}
}

// const CssTextField = withStyles({
// 	root: {
// 		'& label.Mui-focused': {
// 			color: '#4B86E3',
// 		},
// 		'& .MuiInput-underline:after': {
// 			borderBottomColor: '#4B86E3',
// 		},
// 		'& .MuiOutlinedInput-root': {
// 			'& fieldset': {
// 				borderColor: '#4B86E3',
// 			},
// 			'&:hover fieldset': {
// 				borderColor: '#4B86E3',
// 			},
// 			'&.Mui-focused fieldset': {
// 				borderColor: '#4B86E3',
// 			},
// 		},
// 	},
// })(TextField);

/**
 * @class
 *
 * Class representing Dashboard component
 *
 */
class Dashboard extends Component {
	constructor() {
		super();
		this.state = {
			search_text: "",
			rowsPerPage: 10,
			page: 0,
			selectedUser: null,
			dialogOpen: false,
			vaccinDialog: false,
			selected_user_profile_id: null,
			isConfirmationDialog: false,
			deleteConfirmationDialog: false,
			moreButtonAction: false,
			anchorEl: null,
			popoverId: null,
			date: moment(),
			topDataLoading: false,
			middleDataLoading: false,
			bottomDataLoading: false,
			secondDataLoading: false

		}
	}

	/** This is a component life cycle method and used to fetch datas  */
	componentDidMount = () => {
		/** fetching dashboard details. */
		//  this.props.dispatch(fetchDashboardUserResult(this))
		//  this.props.dispatch(fetchDashboard(this))
		//  this.props.dispatch(fetchDashboardTestResult(this))
		/** fetching users details. */
		// this.props.dispatch(fetchAllUsers())
		//this.props.dispatch(fetchTestDashboard(this))
		this.props.dispatch({ type: FETCH_USER_BY_PHONE, data: null })
	}

	/**
	 * @function
	 *
	 * function representing table pagination.
	 * @param {Object} _event
	 * @param {number} newPage
	 */
	handleChangePage = (_event, newPage) => {
		this.setState({
			page: newPage
		});
	}

	/**
	 * @function
	 *
	 * function representing table pagination rows.
	 * @param {Object} event
	 */
	handleChangeRowsPerPage = (event) => {
		this.setState({
			page: 0, rowsPerPage: parseInt(event.target.value, 10)
		});
	}

	/**
	 * @function
	 *
	 * function representing click event select user.
	 * @param {Object} event 
	 * @param {Object} user 
	 */
	handleClick = (event, user) => {
		this.setState({ selectedUser: user })
		this.setState({ anchorEl: event.currentTarget, moreButtonAction: Boolean(event.currentTarget), popoverId: Boolean(event.currentTarget) ? 'simple-popover' : undefined });
	};

	/**
	 * @function
	 *
	 * function representing click event open menu.
	 * @param {Object} event 
	 */
	handleClose = (event) => {
		this.setState({ anchorEl: event.currentTarget, moreButtonAction: false, popoverId: Boolean(event.currentTarget) ? 'simple-popover' : undefined });
	};

	/**
	 *@function
	 * This function representing to shows vaccine details.
	 * @param {Number} id The id Number
	 */
	showTestDetails = (id) => {
		this.props.dispatch(fetchTestByUserProfileId(id))
		this.setState({
			vaccinDialog: true,
			selected_user_profile_id: id, moreButtonAction: false
		})
	}

	/**
	 *@function
	 * This function representing resend SMS to user.
	 * @param {Number} phone The phone Number
	 */
	resendSMS = (phone) => {
		this.props.dispatch(resendSmsToUser(phone))
	}

	/**
	 * @function
	 * This function representing close vaccine deatails dialog screen.
	 */
	closeDialog = () => {
		this.setState({ vaccinDialog: false, selected_user_profile_id: null })
		this.props.dispatch({ type: USER_PROFILE_TEST_RESULT, data: [] })
	}

	/**
	 * @function
	 *
	 * function representing update user vaccine details.
	 *
	 */
	updateShots = () => {
		if (this.state.selected_user_profile_id !== null) {
			this.props.dispatch(updateUserVaccines(this.state.selected_user_profile_id, this))
		}
	}

	/**
	 * @function
	 *
	 * function representing update vaccine details confirmation dialog screen will open.
	 *
	 */
	confirmationDialog = () => {
		this.setState({
			isConfirmationDialog: true
		})
	}

	/**
	 * @function
	 *
	 * function representing close confirmation dialog screen.
	 * @param {Object} user
	 */
	deleteConfirmationAction = (user) => {
		this.setState({ deleteConfirmationDialog: true, selectedUser: user, moreButtonAction: false })
	}

	/**
	 * @function
	 *
	 * function representing close confirmation dialog screen.
	 * @param {Object} param
	 */
	deleteConfirmationButtonAction = (param) => {
		if (param === "Y") {
			this.setState({ deleteConfirmationDialog: false })
			let dataObj = {};
			dataObj.hashedUserId = this.state.selectedUser.hashedUserId;
			dataObj.updateUserStatus = this.state.selectedUser.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
			this.props.dispatch(updateStatusUser(this.state.selectedUser.id, dataObj))
		} else {
			this.setState({ deleteConfirmationDialog: false })
		}

	}

	handleDateChange = (date) => {
		// this.setState({ date: moment(date).format("MM/DD/YYYY") }, () => this.props.dispatch(fetchTestDashboard(this)));
		this.setState({ date: date })
	};
	fetchUserData = () => {
		//console.log("fetchUserData===>")
		this.setState({
			topDataLoading: true,
			secondDataLoading: true
		});
		this.props.dispatch(fetchDashboardUserResult(this))
		this.props.dispatch(fetchDashboard(this))
	};
	fetchTestResult = () => {
		this.setState({
			middleDataLoading: true,
		});
		this.props.dispatch(fetchDashboardTestResult(this))
	};
	fetchTestResultByDate = () => {
		this.setState({
			bottomDataLoading: true
		});
		this.props.dispatch(fetchTestDashboard(this))
	};
	/** rendering Dashboard information. */
	render() {
		// const { classes } = this.props;
		// const { page, rowsPerPage } = this.state;
		// let users = this.props.all_users.sort((a, b) => a.id - b.id)
		// let result = this.state.search_text !== '' ? users.filter(x => (x.phone).toString() === (this.state.search_text).toString() || (x.email).toString() === (this.state.search_text).toString() || (x.zipCode !== null ? x.zipCode.toString() : "") === (this.state.search_text).toString()) : users
		return (
			<div style={styles.paper}>
				<Paper style={{ padding: 5, margin: 5 }}>
					<div style={{ display: 'flex', justifyContent: "space-between" }}>
						<Typography style={{ fontWeight: "bold", padding: "10px 0px", color: theme.palette.secondary.light, fontSize: 18 }}>Users</Typography>
						<Button variant="contained" size="small" color="primary" style={{ margin: 7 }} onClick={() => this.fetchUserData()}>Fetch User</Button>
					</div>
					<Grid container >
						<Grid item xs={12} sm={6} md={3}>
							<Card style={{ margin: 10, 
							border: `1px solid ${theme.palette.primary.main}`,
								// backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
								 borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 10 }}>
								<Typography style={{ color: theme.palette.secondary.light, fontSize: 11, }}>TOTAL USERS</Typography>
								<div style={{ display: 'flex', justifyContent: "space-between" }}>
									<div>
										{
											this.state.secondDataLoading ?
												<CircularProgress size={16} style={{ 'color': '#FFFFFF' }} />
												:
												this.props.dashboard_user_data !== null ?
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>{this.props.dashboard_user_data.total_users}</Typography>
													:
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>-</Typography>
										}
									</div>
									<div style={{ textAlign: "end" }}>
										<PeopleIcon style={{ color: theme.palette.primary.main, fontSize: "50px" }} />
									</div>
								</div>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Card style={{ margin: 10,
							border: `1px solid ${theme.palette.primary.main}`,
								//  backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
								  borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 10 }}>
								<Typography style={{ color: theme.palette.secondary.light, fontSize: 11 }}>REGISTERED USERS</Typography>
								<div style={{ display: 'flex', justifyContent: "space-between" }}>
									<div>
										{
											this.state.secondDataLoading ?
												<CircularProgress size={16} style={{ 'color': '#FFFFFF' }} />
												:
												this.props.dashboard_user_data !== null ?
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>{this.props.dashboard_user_data.enrolled_users}</Typography>
													:
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>-</Typography>
										}
									</div>
									<div style={{ textAlign: "end" }}>
										<PeopleIcon style={{ color: theme.palette.primary.main, fontSize: "50px" }} />
									</div>
								</div>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Card style={{ margin: 10,
							border: `1px solid ${theme.palette.primary.main}`,
								//  backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
								  borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 10 }}>
								<Typography style={{ color: theme.palette.secondary.light, fontSize: 11 }}>UNREGISTERED USERS</Typography>
								<div style={{ display: 'flex', justifyContent: "space-between" }}>
									<div>
										{
											this.state.secondDataLoading ?
												<CircularProgress size={16} style={{ 'color': '#FFFFFF' }} />
												:
												this.props.dashboard_user_data !== null ?
													<Typography style={{ color:theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>{this.props.dashboard_user_data.un_registered_users}</Typography>
													:
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>-</Typography>
										}
									</div>
									<div style={{ textAlign: "end" }}>
										<PeopleIcon style={{ color: theme.palette.primary.main, fontSize: "50px" }} />
									</div>
								</div>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Card style={{ margin: 10, 
							border: `1px solid ${theme.palette.primary.main}`,
								// backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, 
								borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 10 }}>
								<Typography style={{ color: theme.palette.secondary.light, fontSize: 11 }}>ACTIVE USERS</Typography>
								<div style={{ display: 'flex', justifyContent: "space-between" }}>
									<div>
										{
											this.state.secondDataLoading ?
												<CircularProgress size={16} style={{ 'color': '#FFFFFF' }} />
												:
												this.props.dashboard_user_data !== null ?
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>{this.props.dashboard_user_data.active_users}</Typography>
													:
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>-</Typography>
										}
									</div>
									<div style={{ textAlign: "end" }}>
										<PeopleIcon style={{ color: theme.palette.primary.main, fontSize: "50px" }} />
									</div>
								</div>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Card style={{ margin: 10,
							border: `1px solid ${theme.palette.primary.main}`,
								//  backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
								  borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 10 }}>
								<Typography style={{ color: theme.palette.secondary.light, fontSize: 11 }}>Medical Service Providers</Typography>
								<div style={{ display: 'flex', justifyContent: "space-between" }}>
									<div>
										{
											this.state.topDataLoading ?
												<CircularProgress size={16} style={{ 'color': '#FFFFFF' }} />
												:
												this.props.dashboard_data !== null ?
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>{this.props.dashboard_data.verifier_users}</Typography>
													:
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>-</Typography>
										}
									</div>
									<div style={{ textAlign: "end" }}>
										<PeopleIcon style={{ color: theme.palette.primary.main, fontSize: "50px" }} />
									</div>
								</div>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Card style={{ margin: 10,
							border: `1px solid ${theme.palette.primary.main}`,
								//  backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
								  borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 10 }}>
								<Typography style={{ color: theme.palette.secondary.light, fontSize: 11 }}>ACCESS USERS</Typography>
								<div style={{ display: 'flex', justifyContent: "space-between" }}>
									<div>
										{
											this.state.topDataLoading ?
												<CircularProgress size={16} style={{ 'color': '#FFFFFF' }} />
												:
												this.props.dashboard_data !== null ?
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>{this.props.dashboard_data.access_users}</Typography>
													:
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>-</Typography>
										}
									</div>
									<div style={{ textAlign: "end" }}>
										<PeopleIcon style={{ color: theme.palette.primary.main, fontSize: "50px" }} />
									</div>
								</div>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Card style={{ margin: 10,
							border: `1px solid ${theme.palette.primary.main}`,
								//  backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
								  borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 10 }}>
								<Typography style={{ color:  theme.palette.secondary.light, fontSize: 11 }}>UPLOADED DOCUMENTS</Typography>
								<div style={{ display: 'flex', justifyContent: "space-between" }}>
									<div>
										{
											this.state.topDataLoading ?
												<CircularProgress size={16} style={{ 'color': '#FFFFFF' }} />
												:
												this.props.dashboard_data !== null ?
													<Typography style={{ color:  theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>{this.props.dashboard_data.documents_uploaded}</Typography>
													:
													<Typography style={{ color:  theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>-</Typography>
										}
									</div>
									<div style={{ textAlign: "end" }}>
										<DescriptionIcon style={{ color: theme.palette.primary.main, fontSize: "50px" }} />
									</div>
								</div>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Card style={{ margin: 10,
							border: `1px solid ${theme.palette.primary.main}`,
								//  backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
								  borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 10 }}>
								<Typography style={{ color: theme.palette.secondary.light, fontSize: 11 }}>VERIFIED DOCUMENTS</Typography>
								<div style={{ display: 'flex', justifyContent: "space-between" }}>
									<div>
										{
											this.state.topDataLoading ?
												<CircularProgress size={16} style={{ 'color': '#FFFFFF' }} />
												:
												this.props.dashboard_data !== null ?
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>{this.props.dashboard_data.verified_documents}</Typography>
													:
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>-</Typography>
										}
									</div>
									<div style={{ textAlign: "end" }}>
										<DescriptionIcon style={{ color: theme.palette.primary.main, fontSize: "50px" }} />
									</div>
								</div>
							</Card>
						</Grid>
					</Grid>
				</Paper>
				<Paper style={{ padding: 5, margin: 5 }}>
					<div style={{ display: 'flex', justifyContent: "space-between" }}>
						<Typography style={{ fontWeight: "bold", padding: "10px 0px", color: theme.palette.secondary.light, fontSize: 18, }}>Consolidated Test Results</Typography>
						<Button variant="contained" size="small" color="primary" style={{ margin: 7 }} onClick={() => this.fetchTestResult()}>Fetch Test Results</Button>
					</div>
					<Grid container >
						<Grid item xs={12} sm={6} md={4}>
							<Card style={{ margin: 10,
							border: `1px solid ${theme.palette.primary.main}`,
								//  backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
								  borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 10 }}>
								<Typography style={{ color: theme.palette.secondary.light, fontSize: 11 }}>TEST RESULTS FOR REGISTERED USERS</Typography>
								<div style={{ display: 'flex', justifyContent: "space-between" }}>
									<div>
										{
											this.state.middleDataLoading ?
												<CircularProgress size={16} style={{ 'color': '#FFFFFF' }} />
												:
												this.props.dashboard_test_data !== null ?
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>{this.props.dashboard_test_data.totalTestResults}</Typography>
													:
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>-</Typography>
										}
									</div>
									<div style={{ textAlign: "end" }}>
										<DescriptionIcon style={{ color: theme.palette.primary.main, fontSize: "50px" }} />
									</div>
								</div>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Card style={{ margin: 10,
							border: `1px solid ${theme.palette.primary.main}`,
								//  backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
								  borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 10 }}>
								<Typography style={{ color: theme.palette.secondary.light, fontSize: 11 }}>TEST RESULTS FOR UNREGISTERED USERS</Typography>
								<div style={{ display: 'flex', justifyContent: "space-between" }}>
									<div>
										{
											this.state.middleDataLoading ?
												<CircularProgress size={16} style={{ 'color': '#FFFFFF' }} />
												:
												this.props.dashboard_test_data !== null ?
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>{this.props.dashboard_test_data.totalPendingTestResults}</Typography>
													:
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>-</Typography>
										}
									</div>
									<div style={{ textAlign: "end" }}>
										<DescriptionIcon style={{ color: theme.palette.primary.main, fontSize: "50px" }} />
									</div>
								</div>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Card style={{ margin: 10,
							border: `1px solid ${theme.palette.primary.main}`,
								//  backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
								  borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 10 }}>
								<Typography style={{ color: theme.palette.secondary.light, fontSize: 11 }}>UNPROCESSED TEST RESULTS</Typography>
								<div style={{ display: 'flex', justifyContent: "space-between" }}>
									<div>
										{
											this.state.middleDataLoading ?
												<CircularProgress size={16} style={{ 'color': '#FFFFFF' }} />
												:
												this.props.dashboard_test_data !== null ?
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>{this.props.dashboard_test_data.totalUnProcessedTestResults}</Typography>
													:
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>-</Typography>
										}
									</div>
									<div style={{ textAlign: "end" }}>
										<DescriptionIcon style={{ color: theme.palette.primary.main, fontSize: "50px" }} />
									</div>
								</div>
							</Card>
						</Grid>
					</Grid>
				</Paper>
				<Paper style={{ padding: 5, margin: 5 }}>
					<div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between" }}>
						<Typography style={{ fontWeight: "bold", padding: "10px 0px", color: theme.palette.secondary.light, fontSize: 18, }}>Test Result For {moment(this.state.date).format("MM/DD/YYYY")}</Typography>
						<div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                        <Grid style={{ marginTop: 20 }}>
						 <LocalizationProvider dateAdapter={AdapterMoment} locale="en-gb"  >
									<DatePicker
										fullWidth
										size="small"
										disableToolbar
										format="MM/DD/YYYY"
										value={this.state.date}
										onChange={this.handleDateChange}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										slotProps={{ textField: { size: 'small' } }}
										variant="filled"
										style={{marginRight:10, marginTop: 60}}
										disableFuture										
									/>
								</LocalizationProvider>
								</Grid>
								<div style={{width:10}}/>
							<Grid>
                           <Button variant="contained" size="small" color="primary" style={{ marginTop: 20 }} onClick={() => this.fetchTestResultByDate()}>Fetch Test Results</Button>
						   </Grid>
						</div>
					</div>
					<Grid container >
						<Grid item xs={12} sm={6} md={4}>
							<Card style={{ margin: 10,
							border: `1px solid ${theme.palette.primary.main}`,
								//  backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
								  borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 10 }}>
								<Typography style={{ color: theme.palette.secondary.light, fontSize: 11 }}>TEST RESULTS FOR REGISTERED USERS</Typography>
								<div style={{ display: 'flex', justifyContent: "space-between" }}>
									<div>
										{
											this.state.bottomDataLoading ?
												<CircularProgress size={16} style={{ 'color': '#FFFFFF' }} />
												:
												this.props.test_dashboard !== null ?
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>{this.props.test_dashboard.testResults}</Typography>
													:
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>-</Typography>
										}
									</div>
									<div style={{ textAlign: "end" }}>
										<DescriptionIcon style={{ color: theme.palette.primary.main, fontSize: "50px" }} />
									</div>
								</div>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Card style={{ margin: 10,
							border: `1px solid ${theme.palette.primary.main}`,
								//  backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, 
								 borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 10 }}>
								<Typography style={{ color: theme.palette.secondary.light, fontSize: 11 }}>TEST RESULTS FOR UNREGISTERED USERS</Typography>
								<div style={{ display: 'flex', justifyContent: "space-between" }}>
									<div>
										{
											this.state.bottomDataLoading ?
												<CircularProgress size={16} style={{ 'color': '#FFFFFF' }} />
												:
												this.props.test_dashboard !== null ?
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>{this.props.test_dashboard.pendingTestResults}</Typography>
													:
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>-</Typography>
										}
									</div>
									<div style={{ textAlign: "end" }}>
										<DescriptionIcon style={{ color: theme.palette.primary.main, fontSize: "50px" }} />
									</div>
								</div>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Card style={{ margin: 10,
							border: `1px solid ${theme.palette.primary.main}`,
								//  backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
								  borderRadius: 10, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 10 }}>
								<Typography style={{ color: theme.palette.secondary.light, fontSize: 11 }}>UNPROCESSED TEST RESULTS</Typography>
								<div style={{ display: 'flex', justifyContent: "space-between" }}>
									<div>
										{
											this.state.bottomDataLoading ?
												<CircularProgress size={16} style={{ 'color': '#FFFFFF' }} />
												:
												this.props.test_dashboard !== null ?
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>{this.props.test_dashboard.unProcessedTestResults}</Typography>
													:
													<Typography style={{ color: theme.palette.secondary.light, fontSize: 22, paddingTop: 8 }}>-</Typography>
										}
									</div>
									<div style={{ textAlign: "end" }}>
										<DescriptionIcon style={{ color: theme.palette.primary.main, fontSize: "50px" }} />
									</div>
								</div>
							</Card>
						</Grid>
					</Grid>
				</Paper>
				{/* <hr style={{ margin: 10 }} /> */}
				{/* <div>
					<div style={{ display: "flex", justifyContent: "space-between", margin: "0px 15px" }}>
						<Typography style={{ textAlign: "center", fontWeight: "bold", padding: 10 }}>Users</Typography>
						<TablePagination
							rowsPerPageOptions={[20]}
							component="div"
							count={result.length}
							rowsPerPage={this.state.rowsPerPage}
							page={this.state.page}
							slotProps={{
								select: {
									inputProps: { 'aria-label': 'rows per page' },
									native: true,
								}
							}}
							onChangePage={this.handleChangePage}
							onChangeRowsPerPage={this.handleChangeRowsPerPage}
							labelRowsPerPage={null}
							labelDisplayedRows={
								() => null
							}
							ActionsComponent={
								TablePaginationActions
							}
						/>
						<CssTextField
							margin="normal"
							id="search-text"
							label="Search"
							placeholder="Phone, Email Or Zipcode"
							name="mobile-number"
							autoComplete="off"
							value={this.state.search_text}
							onChange={event =>
								this.setState({ search_text: event.target.value, page: 0 })
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{ classes: { input: classes.inputStyle, root: classes.inputRoot } }}
							autoFocus
						/>
					</div>
				</div> */}
				{/* <div style={{ margin: "0px 15px" }}>
					<Table >
						<TableHead style={{ backgroundColor: "#20478E", padding: 10 }}>
							<TableRow >
								<TableCell align="left" size="small" style={classes.fontCellHeader}>S.no</TableCell>
								<TableCell align="left" size="small" style={classes.fontCellHeader}>Phone</TableCell>
								<TableCell align="left" size="small" style={classes.fontCellHeader}>Email</TableCell>
								<TableCell align="left" size="small" style={classes.fontCellHeader}>User Code</TableCell>
								<TableCell align="left" size="small" style={classes.fontCellHeader}>Zipcode	</TableCell>
								<TableCell align="left" size="small" style={classes.fontCellHeader}>Action	</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{result.length > 0 ? result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) =>
								<TableRow key={index.toString()} >
									<TableCell style={classes.fontCellBody} style={{ color: user.status === "INACTIVE" && "#989898" }} > {user.id} </TableCell>
									<TableCell align="left" style={classes.fontCellBody} style={{ color: user.status === "INACTIVE" && "#989898" }} >{user.phone}</TableCell>
									<TableCell align="left" style={classes.fontCellBody} style={{ color: user.status === "INACTIVE" && "#989898" }} >{user.email}</TableCell>
									<TableCell align="left" style={classes.fontCellBody} style={{ color: user.status === "INACTIVE" && "#989898" }} >{user.userCode}</TableCell>
									<TableCell align="left" style={classes.fontCellBody} style={{ color: user.status === "INACTIVE" && "#989898" }} >{user.zipCode}</TableCell>
									<TableCell align="left" style={classes.fontCellBody}>
										<div style={{ textAlign: "start", marginTop: "10px" }}>
											<MoreVert style={{ color: "blue", fontSize: "25px" }} onClick={(e) => { this.handleClick(e, user) }} />
										</div>
									</TableCell>
								</TableRow>)
								:
								<Typography>There are no users</Typography>}
						</TableBody>
					</Table>
				</div> */}
				<Dialog
					maxWidth={"xs"}
					open={this.state.dialogOpen} >
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"QR Code"}</Typography>
					</DialogTitle>
					<DialogContent>
						<div style={styles.qrContainer}>
							<QRCode value={this.state.selectedUser !== null ? this.state.selectedUser.hashedUserId : ""} size={200} />
						</div>
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={() => this.setState({ dialogOpen: false, selectedUser: null })} variant="contained" color="secondary" style={styles.cancelButton}>Close</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					maxWidth={"lg"}
					fullWidth
					open={this.state.vaccinDialog} >
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"Vaccine Records"}</Typography>
					</DialogTitle>
					<DialogContent>
						{this.props.userTestTypeResult.length > 0 ?
							<Table>
								<TableHead style={{ backgroundColor: "#20478E", padding: 10 }}>
									<TableRow >
										<TableCell align="left" size="small" style={styles.tableHeader}>Test Type</TableCell>
										<TableCell align="left" size="small" style={styles.tableHeader}>Document Uploaded Date</TableCell>
										<TableCell align="left" size="small" style={styles.tableHeader}>Vaccination Date</TableCell>
										<TableCell align="left" size="small" style={styles.tableHeader}>Vaccination Manufacturer</TableCell>
										<TableCell align="left" size="small" style={styles.tableHeader}>Status	</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.props.userTestTypeResult.map((data, index) =>
										<TableRow key={index.toString()}>
											<TableCell align="left" style={styles.fontCellBody}>{`${data.testType.name} - shot ${data.shotNumber}`}</TableCell>
											<TableCell align="left" style={styles.fontCellBody}>{moment(data.createdAt).format("MM/DD/YYYY")}</TableCell>
											<TableCell align="left" style={styles.fontCellBody}>{data.vaccineResults.length > 0 ? moment(data.vaccineResults[0].vaccinationDate).format("MM/DD/YYYY") : "-"}</TableCell>
											<TableCell align="left" style={styles.fontCellBody}>{data.vaccineResults.length > 0 ? data.vaccineResults[0].vaccine.name : "-"}</TableCell>
											<TableCell align="left" style={styles.fontCellBody}>{data.vaccineResults.length > 0 ? data.vaccineResults[0].status !== "PENDING" ? data.vaccineResults[0].status : "NOT VACCINATED" : "NOT VACCINATED"}</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
							:
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<Typography>There are no vaccine record for this user </Typography>
							</div>
						}
					</DialogContent>
					<DialogActions>
						{
							this.props.userTestTypeResult.length > 0 &&
							<Button size="small" onClick={() => this.confirmationDialog()} variant="contained" color="secondary" style={styles.addButton}>Update</Button>
						}
						<Button size="small" onClick={() => this.closeDialog()} variant="contained" color="secondary" style={styles.cancelButton}>Close</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					maxWidth={"xs"}
					open={this.state.isConfirmationDialog} >
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"Confirmation"}</Typography>
					</DialogTitle>
					<DialogContent>
						<div style={styles.qrContainer}>
							Are you sure want to update the Vaccine Record ?
						</div>
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={() => this.updateShots()} variant="contained" color="secondary" style={styles.addButton}>Update</Button>
						<Button size="small" onClick={() => this.setState({ isConfirmationDialog: false })} variant="contained" color="secondary" style={styles.cancelButton}>Close</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					maxWidth={"xs"}
					open={this.state.deleteConfirmationDialog} >
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "2%", ffontSize: 18, color: "#fff", fontWeight: "bold", textAlign: "center" }}>Confirmation!</Typography>
					</DialogTitle>
					<DialogContent>
						<div style={styles.qrContainer}>
							Are you sure want to {this.state.selectedUser !== null && this.state.selectedUser.status === "ACTIVE" ? "inactive" : "active"} the {this.state.selectedUser !== null && this.state.selectedUser.phone}?
						</div>
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={() => this.deleteConfirmationButtonAction("Y")} variant="contained" color="secondary" style={styles.yesButton}>Yes</Button>
						<Button size="small" onClick={() => this.deleteConfirmationButtonAction("N")} variant="contained" color="secondary" style={styles.cancelButton}>No</Button>
					</DialogActions>
				</Dialog>
				<div>
					<Popover
						id={this.state.popoverId}
						open={this.state.moreButtonAction}
						anchorEl={this.state.anchorEl}
						onClose={this.handleClose}
						style={{ borderRadius: '20px', border: '1px', borderColor: "red" }}>
						<List style={styles.root}>
							<ListItem>
								<Typography style={{ fontSize: 15, paddingTop: 4 }} onClick={() => this.setState({ dialogOpen: true, moreButtonAction: false })} > View QR Code </Typography>
							</ListItem>
							<Divider />
							<ListItem>
								<Typography style={{ fontSize: 15, paddingTop: 4 }} onClick={() => this.showTestDetails(this.state.selectedUser !== null && this.state.selectedUser.id)}> Details</Typography>
							</ListItem>
							{this.state.selectedUser !== null && this.state.selectedUser.roleTypes[0].roleType.code !== "ADM" &&
								<Divider />
							}
							{this.state.selectedUser !== null && this.state.selectedUser.isChangePassword === true &&
								<ListItem>
									<Typography style={{ fontSize: 15, paddingTop: 4 }} onClick={() => this.showTestDetails(this.state.selectedUser !== null && this.state.selectedUser.id)}> Resend SMS</Typography>
								</ListItem>
							}

							{this.state.selectedUser !== null && this.state.selectedUser.roleTypes[0].roleType.code !== "ADM" &&
								<ListItem>
									<Typography style={{ fontSize: 15, paddingTop: 4, textTransform: 'capitalize' }} onClick={() => this.deleteConfirmationAction(this.state.selectedUser)} > {this.state.selectedUser !== null && this.state.selectedUser.status === "ACTIVE" ? "Active" : "Inactive"} </Typography>
								</ListItem>
							}
						</List>
					</Popover>
				</div>

				<Dialog
					maxWidth={"xs"}
					open={false} >
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: "linear-gradient(to bottom right,#20478E, #5186E7)", boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold", textAlign: "center" }}>{this.state.selectedUser !== null && this.state.selectedUser.status === "ACTIVE" ? "InActive " : "Active "}  User Confirmation!</Typography>
					</DialogTitle>
					<DialogContent>
						<div style={styles.qrContainer}>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell align="left" size="small" style={styles.tableHeader}>Test Type</TableCell>
										<TableCell align="left" size="small" style={styles.tableHeader}>Document Uploaded Date</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</div>
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={() => this.deleteConfirmationButtonAction("Y")} variant="contained" color="secondary" style={styles.addButton}>Yes</Button>
						<Button size="small" onClick={() => this.deleteConfirmationButtonAction("N")} variant="contained" color="secondary" style={styles.cancelButton}>No</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
	return {
		dashboard_data: state.userReducer.dashboard_data,
		user_by_phone: state.userReducer.user_by_phone,
		all_users: state.userReducer.all_users,
		userTestTypeResult: state.testtypeReducer.userTestTypeResult,
		test_dashboard: state.userReducer.test_dashboard,
		dashboard_test_data: state.userReducer.dashboard_test_data,
		dashboard_user_data: state.userReducer.dashboard_user_data
	};
};

Dashboard.propTypes = {
	// classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Dashboard);