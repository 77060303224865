import React, { Component } from "react";
import { Typography, Grid, Button, TextField, MenuItem, Dialog, DialogTitle, CircularProgress, Input as MaterialInput, FormHelperText } from "@mui/material";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { registerBasicInfo } from "../../actions/registerAction";
import { ADD_PROFILE } from "../../actions/actionTypes";
import InputMask from 'react-input-mask';
import moment from 'moment'
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { THEMECOLOR } from "../../helpers/Constants";

const DOT_LENGTH = 10;

/** Created common styles for UI  */
const styles = {
	paper: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		height: '1vh'
	},
	topContainer: {
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
		height: "200px",
		justifyContent: "center",
		alignItems: "center",
		padding: 25,
		borderBottomLeftRadius: "10%",
		borderBottomRightRadius: "10%",

	},
	bottomContainer: {
		marginTop: -40,
		backgroundColor: "#fff",
		borderRadius: 10,
		margin: "0px 10px",
		padding: "10px",
		boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
		transition: "0.3s"
	},
	textStyle: {
		fontSize: 20,
		color: "#fff",
		padding: "5px 0px",
		textAlign: 'center'
	},
	topText: {
		textAlign: "center",
		fontSize: 20,
		paddingVertical: 5,
	},
	textView: {
		marginTop: 5,
	},
	button: {
		marginTop: 10,
		marginBottom: 10,
		padding: 10,
		borderRadius: 10,
		fontSize: 15,
		fontWeight: "bold",
		width: "80%",
		backgroundColor: theme.palette.primary.main
	},
	continueText: {
		marginTop: 5,
		fontSize: 15,
		color: "#ffffff",
		textTransform: 'uppercase'
	},
	infoContainer: {
		display: 'flex',
		height: '100%',
		width: "100%",
		justifyContent: "center",
		alignItems: "center"
	},
	dotView: {
		display: 'flex',
		flexDirection: "row",
		width: "90%",
		alignItems: "center",
		justifyContent: "space-around",
		marginTop: -80
	},
	borderDot: {
		width: 15,
		height: 15,
		borderRadius: 12,
		backgroundColor: "#fff",
		borderWidth: 6,
		borderColor: "rgba(265,265,265,0.2)"
	},
	dotStyle: {
		width: 10,
		height: 10,
		borderRadius: 6,
		backgroundColor: "rgba(265,265,265,0.5)"
	},
	dialogPaper: {
		backgroundColor: "rgba(0,0,0,0)",
		boxShadow: "none",
		overflow: "hidden"
	}
};

/**
 * @class
 *
 * Class representing YourInformation component
 *
 */
class YourInformation extends Component {

	constructor(props) {
		super(props);
		this.state = {
			first_name: "",
			first_name_error: false,
			invalid_first_name_error: false,
			last_name: "",
			last_name_error: false,
			invalid_last_name_error: false,
			email: "",
			email_error: false,
			invalid_email_error: false,
			zip_code: "",
			gender_error: false,
			gender: "",
			genders: ["Male", "Female", "Others"],
			invalid_zip_code_error: false,
			date_of_birth: "",
			date_of_birth_error: false,
			invalid_date_of_birth: false,
			invalid_age_error: false,
			errorText: null,
			value: '',
			confirmDialog: false,
			checkSubmit: 1,
			isLoading: false,
			userData: null
		}
	}
	/** This is a component life cycle method and used to assigning props values to state variable  */
	static getDerivedStateFromProps(props, state) {
		if (state.userData !== props.userData && props.userData !== null) {
			return {
				first_name: props.userData.firstName,
				last_name: props.userData.lastName,
				email: props.userData.email,
				gender: props.userData.Gender,
				zip_code: props.userData.zipCode,
				date_of_birth: props.userData.dateOfBirth,
				userData: props.userData
			};
		}
		return null;
	}

	//This function using to returning a dotted view
	renderDotView = () => {
		let DotArray = []
		for (let i = 0; i < DOT_LENGTH; i++) {
			DotArray.push(<div key={i.toString()} id={i} style={{
				width: 4,
				height: 4,
				borderRadius: 2,
				backgroundColor: "rgba(265,265,265,0.5)"
			}} />)
		}
		return DotArray;
	}

	/**
	*
	* This function representing email validation.
	* @param {String} email The email string
	*/
	validateEmail(email) {
		var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	/**
	*
	* This function representing ZipCode validation.
	* @param {String} code The code string
	*/
	validateZipCode(code) {
		var re = /^[\d]{5}$/;
		return code === "" ? true : re.test(code);
	}



	isValidDate(dateString) {
		// Parse the date parts to integers
		const parts = dateString.split('/');
		const day = parseInt(parts[1], 10);
		const month = parseInt(parts[0], 10);
		const year = parseInt(parts[2], 10);

		// Check the ranges of month and year
		if (year < 1000 || year > 3000 || month === 0 || month > 12) {
			return false;
		}

		// Check the number of days in the month
		const daysInMonth = new Date(year, month, 0).getDate();
		return !(day <= 0 || day > daysInMonth);

	}



	/**
	 *
	 * This function representing date_of_birth validation.
	 * @param {String} date The date string
	 */
	validateAge(date) {
		var age = new Date().getFullYear() - new Date(date).getFullYear();
		var month = new Date().getMonth() - new Date(date).getMonth();
		if (month < 0 || (month === 0 && new Date().getDate() < new Date(date).getDate())) {
			age--;
		}
		return age <= 13
	}

	/**
	 * @function
	 * This function representing  submit user information to register .
	 */
	onSubmit = async () => {
		let { first_name, last_name, email, zip_code, gender, date_of_birth } = this.state;

		console.log(this.isValidDate(date_of_birth))

		let isError = false;
		if (first_name === "" || first_name === null) {
			this.setState({ first_name_error: true });
			isError = true;
		}

		if (last_name === "" || last_name === null) {
			this.setState({ last_name_error: true });
			isError = true;
		}

		if (email === "" || email === null) {
			this.setState({ email_error: true });
			isError = true;
		} else {
			if (!this.validateEmail(email)) {
				this.setState({ invalid_email_error: true });

				isError = true;
			}
		}
		if (gender === "" || gender === null || gender === "Select gender") {
			this.setState({ gender_error: true });
			isError = true;
		}

		if (date_of_birth === "" || date_of_birth === null) {
			this.setState({ date_of_birth_error: true });
			isError = true;
		}

		if ((date_of_birth !== "" || date_of_birth !== null) && this.isValidDate(date_of_birth) === false) {
			this.setState({ invalid_date_of_birth_error: true, errorText: "Please enter valid date of birth(MM/DD/YYYY)" });
			isError = true;
		}


		if (new Date(date_of_birth) === 'Invalid Date' || date_of_birth.length < 10) {
			this.setState({ invalid_date_of_birth_error: true, errorText: "Please enter valid date of birth(MM/DD/YYYY)" });
			isError = true;
		}

		if (this.validateAge(new Date(moment(date_of_birth).format('MM/DD/YYYY')))) {
			this.setState({ date_of_birth_error: true, invalid_age_error: true, errorText: "MedFilo is not yet available for users under 13 years of age." })
			isError = true;
		}

		if (new Date().getTime() < new Date(moment(date_of_birth).format('MM/DD/YYYY')).getTime()) {
			this.setState({ invalid_date_error: true, errorText: "Future date not allowed" });
			isError = true;
		}

		if (zip_code === "" || zip_code === null) {
			this.setState({ invalid_zip_code_error: true });
			isError = true;
		}

		if (!this.validateZipCode(zip_code)) {
			this.setState({ invalid_zip_code_error: true });
			isError = true;
		}

		if (isError === false) {
			if (this.state.checkSubmit === 1) {
				this.setState({ confirmDialog: true })
			}
			else {
				this.setState({ isLoading: true })
				this.props.dispatch({
					type: ADD_PROFILE,
					email: email.toLowerCase(),
					firstName: first_name,
					lastName: last_name,
				});
				let { phone, password } = this.props;
				let data = {};
				data.phone = phone;
				data.password = password;
				data.email = email.toLowerCase();
				data.firstName = first_name;
				data.lastName = last_name;
				data.dateOfBirth = date_of_birth;
				data.gender = gender;
				data.zipCode = zip_code;
				data.userType = "CLT";
				this.props.dispatch(registerBasicInfo(this, data));
				this.setState({ checkSubmit: 1 })
			}
		}
	};

	/**
	*
	* This function representing user submited data confirmation.
	* @param {Object} param The param json object
	*/
	clickButton = async (param) => {
		if (param === "Y") {
			await this.setState({ checkSubmit: 2, confirmDialog: false })
			await this.onSubmit()
		} else {
			this.setState({ checkSubmit: 1, confirmDialog: false })
		}
	}

	/**
	*
	* This function used to change date input value.
	* @param {Object} event The event object
	*/
	onChange = (event) => {
		this.setState({
			date_of_birth: event.target.value,
			date_of_birth_error: false,
			invalid_age_error: false,
			invalid_date_of_birth_error: false
		});
	}

	/**
   * @function
   * This function representing  confirmation dialog screen will close.
   */
	closeDialog = () => {
		this.setState({
			confirmDialog: false
		})
	}
	/** rendering YourInformation screen . */
	render() {
		return (
			<div style={styles.paper}>
				<Grid container justifyContent="center">
					<Grid item xs={12}>
						<div style={styles.topContainer}>
							<Typography style={styles.textStyle}>Sign Up</Typography>
							<div style={styles.infoContainer}>
								<div style={styles.dotView}>
									<div style={styles.dotStyle} />
									{this.renderDotView()}
									<div style={styles.borderDot} />
									{this.renderDotView()}
									<div style={styles.dotStyle} />
								</div>
							</div>
							{/* <Typography style={styles.textStyle} style={{ color: "#000", padding: "5px 0px" }}>ID Verification</Typography> */}
						</div>
						<Grid container justifyContent="center">
							<Grid item style={{ marginTop: -70 }} xs={12} sm={6} md={4}>
								<div style={styles.bottomContainer} >
									<Typography style={styles.topText}>Your Information</Typography>
									<div style={styles.textView}>
										<TextField
											label="First Name"
											fullWidth
											variant="standard"
											value={this.state.first_name.charAt(0).toUpperCase() + this.state.first_name.slice(1)}
											autoComplete="off"
											onChange={(event) => event.target.value.match(/^(?!-|\s$)[a-zA-Z-\s]*$/g) && this.setState({ first_name: event.target.value, first_name_error: false })}
											error={
												this.state.first_name_error ? true : false
											}
											helperText={this.state.first_name_error === true ? "Please enter first name" : ""}
										/>
									</div>
									<div style={styles.textView}>
										<TextField
											label="Last Name"
											fullWidth
											variant="standard"
											color={"primary"}
											value={this.state.last_name.charAt(0).toUpperCase() + this.state.last_name.slice(1)}
											autoComplete="off"
											onChange={(event) => event.target.value.match(/^(?!-|\s$)[a-zA-Z-\s]*$/g) && this.setState({ last_name: event.target.value, last_name_error: false })}
											error={
												this.state.last_name_error ? true : false
											}
											helperText={this.state.last_name_error === true ? "Please enter last name" : ""}
										/>
									</div>
									<div style={styles.textView}>
										<TextField
											label="Email"
											fullWidth
											variant="standard"
											autoComplete="off"
											color={"primary"}
											value={this.state.email}
											onChange={(event) => this.setState({ email: event.target.value, email_error: false, invalid_email_error: false })}
											error={this.state.email_error}
										/>
										{this.state.email_error &&
											<FormHelperText style={{ color: theme.palette.secondary.error, marginLeft: "5px" }}>Please enter email</FormHelperText>
										}
										{this.state.invalid_email_error &&
											<FormHelperText style={{ color: theme.palette.secondary.error, marginLeft: "5px" }}>Please enter valid mail</FormHelperText>
										}
									</div>
									<TextField
										select
										label="Gender"
										autoComplete="off"
										variant="standard"
										color={"primary"}
										value={this.state.gender}
										onChange={(event) => this.setState({ gender: event.target.value, gender_error: false })}
										fullWidth
										error={this.state.gender_error === true ? true : false}
										helperText={this.state.gender_error === true ? "" : false}
									>
										<MenuItem value={"Male"} >Male</MenuItem>
										<MenuItem value={"Female"} >Female</MenuItem>
										<MenuItem value={"Others"} >Others</MenuItem>
									</TextField>
									{this.state.gender_error &&
										<FormHelperText style={{ color: theme.palette.secondary.error, marginLeft: "5px" }}>Please select the gender</FormHelperText>
									}
									<div style={styles.textView}>
										<Typography style={{ fontSize: 16, color: this.state.date_of_birth_error || this.state.invalid_age_error || this.state.invalid_date_of_birth_error ? "#F44336" : "gray" }}>Date of Birth</Typography>
										<InputMask
											placeholder={"MM/DD/YYYY"}
											fullWidth
											style={{ borderBottomStyle: 'solid', color: this.state.date_of_birth_error ? "#F44336" : "rgba(0,0,0,0.9)", borderBottomWidth: this.state.date_of_birth_error || this.state.invalid_age_error || this.state.invalid_date_of_birth_error ? 2 : 1, borderBottomColor: this.state.date_of_birth_error || this.state.invalid_age_error || this.state.invalid_date_of_birth_error ? "#F44336" : 'gray' }}
											type={'datetime'}
											variant="standard"
											mask="99/99/9999"
											maskChar={null}
											value={this.state.date_of_birth}
											onChange={this.onChange} >
											{(inputProps) => <MaterialInput {...inputProps} type="datetime" disableUnderline onChange={this.props.onChange} />}
										</InputMask>
										{this.state.date_of_birth_error ? <Typography style={{ color: '#F44336', fontSize: 12 }}>Please enter date of birth </Typography> : this.state.invalid_age_error || this.state.invalid_date_of_birth_error ? <Typography style={{ fontSize: 12, color: '#F44336', }}> {this.state.errorText}  </Typography> : null}
									</div>
									<div style={styles.textView}>
										<TextField
											label="Zip Code"
											fullWidth
											variant="standard"
											color={"primary"}
											autoComplete="off"
											value={this.state.zip_code}
											onChange={(event) => event.target.value.match(/^\d{0,5}$/g) && this.setState({ zip_code: event.target.value, zip_code_error: false, invalid_zip_code_error: false })}
											error={this.state.zip_code_error}
											// helperText={this.state.zip_code_error === true ? "Please enter zipcode" : this.state.invalid_zip_code_error ? "Please enter 5 digits valid zip code" : ""}
										/>
										{this.state.zip_code_error &&
											<FormHelperText style={{ color: theme.palette.secondary.error, marginLeft: "5px" }}>Please enter zipcode</FormHelperText>
										}
										{this.state.invalid_zip_code_error &&
											<FormHelperText style={{ color: theme.palette.secondary.error, marginLeft: "5px" }}>Please enter 5 digits valid zip code</FormHelperText>
										}
									</div>
									<Grid container justifyContent="center">
										<Button
											type="Button"
											fullWidth
											variant="contained"
											color={"primary"}
											onClick={() => this.onSubmit()}
											style={styles.button}
										>
											<Typography style={styles.continueText}>Submit</Typography>
										</Button>
									</Grid>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Dialog
					open={this.state.confirmDialog}
					fullWidth={true}
					maxWidth={'sm'}
					onClose={() => this.setState({ checkSubmit: 1, confirmDialog: false, })}
					
				>
					<div >
						<DialogTitle>
							<div style={{ disply: 'flex', flexDirection: "row", height: 40, alignItems: "center", justifyContent: "center" }} >
								<Typography style={{ padding: 10, fontWeight: 'bold', color: "#000", fontSize: 18, textAlign: "center" }}> {"Confirmation"} </Typography>
								<div style={{
									width: "100%",
									height: 1,
									backgroundColor: "#D1CFCF"
								}}></div>
							</div>
						</DialogTitle>
						{/* <div style={{ height: 20 }} /> */}
						
						<Grid container >
							<Grid item xs={6} sm={6} >
								<Typography style={{ color: "#000", fontWeight: 'bold', marginLeft:'5vw',}}>First Name	: </Typography>
							</Grid>
							<Grid item xs={6} sm={6} >
								<Typography style={{ color: "#000", textAlign: "left" }}>{this.state.first_name}</Typography>
							</Grid>
							<Grid item xs={6} sm={6}>
								<Typography style={{ color: "#000", marginLeft:'5vw', fontWeight: 'bold', }}>Last Name	: </Typography>
							</Grid>
							<Grid item xs={6} sm={6}>
								<Typography style={{ color: "#000", textAlign: "left" }}>{this.state.last_name}</Typography>
							</Grid>
							<Grid item xs={6} sm={6}>
								<Typography style={{ color: "#000", fontWeight: 'bold',marginLeft:'5vw', }}>Email	: </Typography>
							</Grid>
							<Grid item xs={6} sm={6}>
								<Typography style={{ color: "#000", textAlign: "left" }}>{this.state.email}</Typography>
							</Grid>
							<Grid item xs={6} sm={6}>
								<Typography style={{ color: "#000", fontWeight: 'bold', marginLeft:'5vw', }}>Gender	: </Typography>
							</Grid>
							<Grid item xs={6} sm={6}>
								<Typography style={{ color: "#000", textAlign: "left" }}>{this.state.gender}</Typography>
							</Grid>
							<Grid item xs={6} sm={6}>
								<Typography style={{ color: "#000", fontWeight: 'bold', marginLeft:'5vw', }}>Date Of Birth	: </Typography>
							</Grid>
							<Grid item xs={6} sm={6}>
								<Typography style={{ color: "#000", textAlign: "left" }}>{this.state.date_of_birth}</Typography>
							</Grid>
							<Grid item xs={6} sm={6}>
								<Typography style={{ color: "#000", fontWeight: 'bold', marginLeft:'5vw', }}>Zip Code	: </Typography>
							</Grid>
							<Grid item xs={6} sm={6}>
								<Typography style={{ color: "#000", textAlign: "left" }}>{this.state.zip_code}</Typography>
							</Grid>
						</Grid>
						
						<div style={{ margin: 10 }}>
							<Typography style={{ fontWeight: "bold", color: "#000", textAlign: "center" }}> Are you sure the information is correct?. </Typography>
						</div>
						{/* <div style={{ height: 30 }} /> */}
					</div>
					<div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', }}>
						<div style={{ alignItems: 'center', justifyContent: 'center' }}>
							<Button style={{ margin: 10, width: 100, height: 40, justifyContent: "center", borderRadius: 10, borderColor: "#DC143C", backgroundColor: "#DC143C", elevation: 10, fontWeight: "bold", color: "white", textAlign: "center" }} onClick={() => this.clickButton("N")}>No</Button>
						</div>
						<div style={{ alignItems: 'center', justifyContent: 'center' }} >
							<Button style={{ marginLeft: 10, margin: 10, width: 100, height: 40, justifyContent: "center", borderRadius: 10, borderColor: theme.palette.primary.main, backgroundColor: theme.palette.primary.main, elevation: 10, fontWeight: "bold", color: "white", textAlign: "center" }} onClick={() => this.clickButton("Y")}>Yes</Button>
						</div>
					</div>
				</Dialog>
				<Dialog open={this.state.isLoading}
					classes={{
						paper: styles.dialogPaper,
					}}
				>
					<div style={{ boxShadow: 0, overflow: "none" }}>
						<CircularProgress color="primary" size={40} />
					</div>
				</Dialog>
			</div >
		);
	}
}

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {
		email: state.registerReducer.email,
		firstName: state.registerReducer.firstName,
		lastName: state.registerReducer.lastName,
		phone: state.registerReducer.phone,
		password: state.registerReducer.password,
		userType: state.registerReducer.userType,
		isregisterPending: state.registerReducer.isregisterPending,
		userData: state.registerReducer.userData,
	};
};

YourInformation.propTypes = {
	// classes: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(YourInformation));