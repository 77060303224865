import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Typography } from "@mui/material"
import Webcam from "react-webcam";
import { LOT_NUMBER_IMAGE } from "../../actions/actionTypes";
import { isMobile, THEMECOLOR } from "../../helpers/Constants";
import { withRouter } from "../../withRouter";

const { innerWidth: width, innerHeight: height } = window;

const styles = {
    paper: {
        boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
        backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
        height: '100vh',
    },
    mainDiv: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'hidden',
        overflowX: 'hidden'
    },
    photoContainer: {
        height: height,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: 'center',
        position: 'absolute'
    },
    infoText: {
        fontSize: 18,
        paddingTop: 10,
        color: "#fff",
        textAlign: "center",
    },
    textContainer: {
        display: 'inline-block',
        position: 'relative',
        textAlign: 'center',
        padding: 10,
        zIndex: 100
    },
    cameraRectangle: {
        height: height / 1.7,
        width: width / 1.3,
        border: '4px solid #fff',
        display: 'inline-block',
        position: 'relative',
        zIndex: 101
    },
    buttonContainer: {
        display: 'inline-block',
        position: 'relative',
        zIndex: 100
    },
    roundButton: {
        margin: 20,
        width: height / 15,
    },
    roundButtonCircle: {
        width: '100%',
        height: 0,
        paddingBottom: '100%',
        borderRadius: '50%',
        border: '10px solid #fff',
        overflow: 'hidden',
        background: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
    }
};

class LotNumberCamera extends Component {
    // static contextTypes = {
    //     router: PropTypes.object,
    // }
    constructor() {
        super();
        this.webcamRef = React.createRef();
        this.state = {
            openTestList: false,
            testtypes: [],
            parentWidth: 0
        }
        this.myInput = React.createRef()
        this.onImageChange = this.onImageChange.bind(this);
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.props.dispatch({ type: LOT_NUMBER_IMAGE, data: URL.createObjectURL(img) })
            this.props.navigate("/user-vaccination")
        }
    };

    base64toBlob = (dataURI, type) => {
        // convert base64 to raw binary data held in a string
        var byteString = atob(dataURI.split(',')[1]);
        // separate out the mime component
        // var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var bb = new Blob([ab], { type: type });
        return bb;
    };

    capture = async () => {
        const imageSrc = await this.webcamRef.current.getScreenshot();
        var filepath = await this.base64toBlob(imageSrc, "image/jpeg");
        this.setState({ imgSrc: URL.createObjectURL(filepath) });
        this.props.dispatch({ type: LOT_NUMBER_IMAGE, data: URL.createObjectURL(filepath) })
        this.props.navigate("/vaccination")
    };

    componentDidMount() {
        console.log(this.myInput.current.offsetWidth)
        window.addEventListener('resize', this.handleResize());
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize());
    };

    handleResize = (event) => {
        this.setState({ parentWidth: this.myInput.current.offsetWidth })
    };

    render() {
        let isMobileView = isMobile();
        const videoConstraints = {
            width: 1280,
            height: 720,
            facingMode: { exact: "environment" }
        };

        return (
            <div style={styles.paper}>
                <Grid container justifyContent="center">
                    {isMobileView === true ?
                        <Grid item xs={12} ref={this.myInput}>
                            <div style={{ ...styles.photoContainer, width: this.state.parentWidth }}>
                                <div style={styles.textContainer}>
                                    <Typography style={styles.infoText}>
                                        Take a picture of the vaccine lot number (optional){" "}
                                    </Typography>
                                </div>
                                <div style={styles.cameraRectangle}>
                                </div>
                                <div style={styles.buttonContainer} onClick={() => this.capture()}>
                                    <div style={styles.roundButton}>
                                        <div style={styles.roundButtonCircle}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={styles.mainDiv}>
                                <Webcam
                                    height={720}
                                    width={1280}
                                    audio={false}
                                    ref={this.webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                />
                            </div>
                        </Grid>
                        :
                        <Grid item xs={12} ref={this.myInput}>
                            <div style={{ ...styles.photoContainer, width: this.state.parentWidth }}>
                                <div style={styles.textContainer}>
                                    <Typography style={styles.infoText}>
                                        {'Upload an image of the vaccine lot number (optional)'}
                                    </Typography>
                                </div>
                                <div>
                                    <input type="file" name="myImage" onChange={this.onImageChange} accept=".jpg,.jpeg,.png" />
                                </div>
                            </div>
                        </Grid>
                    }
                </Grid>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        verify_vaccination_detail: state.vaccineReducer.verify_vaccination_detail,
        lot_number_opened: state.vaccineReducer.lot_number_opened,
    };
};

export default withRouter(connect(mapStateToProps)(LotNumberCamera));