import api from '../api';
import { FETCH_DONOR, ADD_DONOR, ALERT_DETAIL } from "./actionTypes"
import { fetchUser } from './userAction'

export function fetchDonor() {
    return function (dispatch) {
        api.get("user-profile/donar/profile").then((response) => {
            if (response.status === "success") {
                dispatch({
                    type: FETCH_DONOR,
                    data: response.payload
                });
            }
        });
    }
}

export function addDonor(self, item) {
    return dispatch => {
        api.post('user-profile/new-donar', item).then((response) => {
            if (response.status === "success") {
                dispatch({ type: ADD_DONOR, data: response.payload })
                dispatch({ type: ALERT_DETAIL, data: true, message: "Donor added successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    dialogOpen: false,
                    primary_contact_first_name: "",
                    primary_contact_first_name_error: false,
                    primary_contact_last_name: "",
                    primary_contact_last_name_error: false,
                    primary_contact_email: "",
                    primary_contact_email_error: false,
                    primary_contact_gender: "",
                    primary_contact_gender_error: "",
                    primary_contact_data_of_birth: "",
                    primary_contact_data_of_birth_error: false,
                    primary_contact_zip_code: "",
                    primary_contact_zip_code_error: "",
                    primary_contact_country_code: "+1",
                    search_phone: "",
                    organization_name: "",
                    search_phone_error: false,
                    invalid_search_phone_error: false,
                })
            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}



export function updateDonor(self, data, userProfileId) {
    return dispatch => {
        api.put('user-profile/donor-gift/' + userProfileId, data).then((response) => {
            if (response.status === "success") {
                dispatch(fetchDonor());
                dispatch(fetchUser());
                dispatch({ type: ALERT_DETAIL, data: true, message: "Credits applied successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    dialogOpen: false,
                    credits: "",
                    credits_error: false,
                    invaluid_credits_error: false,
                })
            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}

export function updateDonorAddCredits(self, data, userProfileId) {
    return dispatch => {
        api.put('user-profile/buy-credits/' + userProfileId, data).then((response) => {
            if (response.status === "success") {
                dispatch(fetchDonor());
                dispatch(fetchUser());
                dispatch({ type: ALERT_DETAIL, data: true, message: "Credits added successfully", severity: "success" })
                self.setState({
                    isLoading: false,
                    dialogOpenForAddCredits: false,
                    credits: "",
                    credits_error: false,
                    invaluid_credits_error: false,
                })
            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}

export function addDonorCredits(self, data, session_id, paymentStatus) {
    return dispatch => {
        api.put(`user-profile/donor/buy-credits?session_id=${session_id}`, data).then((response) => {
            if (response.status === "success") {
                dispatch(fetchDonor());
                dispatch(fetchUser());
                if(paymentStatus === 'success' && response.showMessage === true){
                    dispatch({ type: ALERT_DETAIL, data: true, message: "Credits added successfully", severity: "success" })
                }
                
                self.setState({
                    isLoading: false,
                    dialogOpenForAddCredits: false,
                    credits: "",
                    credits_error: false,
                    invaluid_credits_error: false,
                })
            }
            else {
                self.setState({ isLoading: false, })
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}

