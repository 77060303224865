import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#339F5B",
      light: '#339F5B',
      dark: '#4DC87A',
    },
    secondary: {
      main: '#1A6636',
      light: '#000',
      dark: '	#36454F',
      error: '#491B0F', 
    },
    buttonColor: {
      main: '#A6FFC7'
    },
    //custom theme created for hover button
    clearButton: {
      main: '#737373',
      dark: '#339F5B'
    },
    editButton: {
      main: '#737373',
      dark: '#491B0F'
    },
    primaryButton: {
      main: '#339F5B',
    },
    error: {
      main: "#FF0000"
    }
  },
});

export default theme;