import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Typography, IconButton ,useTheme} from '@mui/material';
import theme1 from '../../theme';

const useStyles1 = {
  root: {
    flexShrink: 0,
    color: theme1.palette.text.secondary,
    marginLeft: theme1.spacing(2.5),
  },
};

function TablePaginationActions(props, name) {
  // const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  let from = (parseInt(page) * parseInt(rowsPerPage)) + 1;
  let intermediate = (parseInt(page) + 1) * parseInt(rowsPerPage);
  let to = intermediate > count ? count : ((parseInt(page) + 1) * parseInt(rowsPerPage));
  // alert(JSON.stringify(props))

  function handleFirstPageButtonClick(event) {
    onPageChange(event, 0);
  }

  function handleBackButtonClick(event) {
    onPageChange(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onPageChange(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }
  return (
    <div style={useStyles1.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon color={'primary'} /> : <FirstPageIcon color={'primary'} />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight color={"primary"} style={{ fontWeight: 'bold' }} />
        ) : (
          <KeyboardArrowLeft color={"primary"} style={{ fontWeight: 'bold' }} />
        )}
      </IconButton>

      <Typography variant="caption" color="secondary" style={{ fontWeight: 'bold' }}>
        {count === 0
          ? `0 - ${to} of ${count}`
          : `${from} - ${to} of ${count}`
        }

      </Typography>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft color={"primary"} style={{ fontWeight: 'bold' }} />
        ) : (
          <KeyboardArrowRight color={"primary"} style={{ fontWeight: 'bold' }} />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon color={'primary'} /> : <LastPageIcon color={'primary'} />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

export default TablePaginationActions;