import api from '../api';
import { FETCH_ROLETYPE } from "./actionTypes";


export function fetchRoleType() {
    return function (dispatch) {
        api.get('role-type').then((response) => {
                if (response.status === "success") {
                    dispatch({
                        type: FETCH_ROLETYPE,
                        data: response.payload
                    });
                }
            });
    }
}

export function addRoleType(self, item) {
    return dispatch => {
        api.post('role-type', item).then((response) => {

            if (response.status === "success") {
                dispatch(fetchRoleType())
            }
            self.setState({
                dialogOpen: false,
                id: null,
                name: "",
                name_error: false,
                code: "",
                code_error: false,
                status: "ACTIVE",
                status_error: false,
                mode: ""
            })
        })
    }
}
export function updateRoleType(self, item, id) {
    return dispatch => {
        api.put('role-type/' + id, item).then((response) => {

            if (response.status === "success") {
                dispatch(fetchRoleType());
            }
            self.setState({
                dialogOpen: false,
                id: null,
                name: "",
                name_error: false,
                code: "",
                code_error: false,
                status: "ACTIVE",
                status_error: false,
                mode: ""
            })
        })
    }
}
