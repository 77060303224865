import { FETCH_VACCINE, LOT_NUMBER_IMAGE, VERIFY_VACCINATION_DETAIL, LOT_NUMBER_OPENED } from "../actions/actionTypes"

const initialstate = {
    vaccine: [],
    lot_number_image: null,
    verify_vaccination_detail: null,
    lot_number_opened: false
}

export default function vaccineReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_VACCINE:
            return {
                ...state,
                vaccine: action.data
            }
        case LOT_NUMBER_IMAGE:
            return {
                ...state,
                lot_number_image: action.data
            }
        case VERIFY_VACCINATION_DETAIL:
            return {
                ...state,
                verify_vaccination_detail: action.data
            }
        case LOT_NUMBER_OPENED:
            return {
                ...state,
                lot_number_opened: action.data
            }
        default:
            return state;
    }
}