import { FETCH_ALL_INDIVIDUALS, FETCH_USER_SUBSCRIPTION_PLAN, UPDATE_INDIVIDUAL } from "../actions/actionTypes"

const initialstate = {
    allIndividuals: [],
    user_subscription_plan: null
}

export default function userSubscriptionPlanReducer(state = initialstate, action) {
    switch (action.type) {
        case FETCH_ALL_INDIVIDUALS:
            return {
                ...state,
                allIndividuals: action.data
            }
        case FETCH_USER_SUBSCRIPTION_PLAN:
            return {
                ...state,
                user_subscription_plan: action.data
            }
        case UPDATE_INDIVIDUAL:
            return {
                ...state,
                allIndividuals: state.allIndividuals.map(x => x.id === action.data.id ? action.data : x)
            }
        default:
            return state;
    }
}