import {
    REGISTER_PENDING, REGISTER_SUCCESS, REGISTER_ERROR,
    ADD_PROFILE, ADD_PHONE, USER_TYPE, ADD_PASSWORD,
    ADD_ID_IMAGE, ADD_USER_SELFIE, FORGOT_PASSWORD, USER_DATA
} from "../actions/actionTypes";

const initialstate = {
    isregisterSuccess: false,
    isregisterPending: false,
    isregisterError: false,
    phone: null,
    password: null,
    email: null,
    firstName: null,
    lastName: null,
    userType: null,
    id_file: null,
    id_file_base64: null,
    user_img_file: null,
    user_img_file_base64: null,
    forgot_password: false,
    userData: null
}

export default function registerReducer(state = initialstate, action) {
    switch (action.type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                isregisterSuccess: true,
                isregisterPending: false,
                isregisterError: false,
            };
        case REGISTER_PENDING:
            return {
                ...state,
                isregisterSuccess: false,
                isregisterPending: true,
                isregisterError: false,
            };
        case REGISTER_ERROR:
            return {
                ...state,
                isregisterSuccess: false,
                isregisterPending: false,
                isregisterError: true,
            };
        case ADD_PHONE:
            return {
                ...state,
                phone: action.data
            };
        case ADD_PASSWORD:
            return {
                ...state,
                password: action.data
            };
        case ADD_PROFILE:
            return {
                ...state,
                email: action.email,
                firstName: action.firstName,
                lastName: action.lastName,
            };
        case USER_TYPE:
            return {
                ...state,
                userType: action.data
            };
        case ADD_ID_IMAGE:
            return {
                ...state,
                id_file: action.data,
                id_file_base64: action.base64
            };
        case ADD_USER_SELFIE:
            return {
                ...state,
                user_img_file: action.data,
                user_img_file_base64: action.base64
            };
        case FORGOT_PASSWORD:
            return {
                ...state,
                forgot_password: action.data
            };
        case USER_DATA:
            return {
                ...state,
                userData: action.data
            };
        default:
            return state;
    }
}