import { FETCH_ACTIVITY } from './actionTypes';
import API from '../api';

export function fetchMyActivity(self) {
	return async dispatch => {
        API.get("activity").then(async (response) => {
			if (response.status === "success") {
				dispatch({ type: FETCH_ACTIVITY, data: response.payload });
			} 
		});
	}
}

