import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Table, Typography, TextField, Button, TableHead, TablePagination, TableBody, TableCell, TableRow, Dialog, DialogActions, DialogTitle, DialogContent, Chip, Radio, Badge, CircularProgress } from "@mui/material";
import TablePaginationActions from "../Dashboard/TablePaginationActions";
import { fetchAllIndividuals, applyStandardPlan, assignOrganizationPlanForUSer } from "../../actions/userSubscriptionPlanAction";
import moment from "moment";
import Premiumicon from "../../assets/images/Premiumicon.png";
import Standardicon from "../../assets/images/Standardicon.png";
import Basicicon from "../../assets/images/Basicicon.png";
import { FETCH_USER_SUBSCRIPTION_PLAN } from '../../actions/actionTypes';
import { fetchOrganizationPlan } from "../../actions/organizationAction";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { headerFont, THEMECOLOR } from '../../helpers/Constants';

/**Created common styles for UI.*/
const styles = {
	textStyle: {
		fontFamily: "Futura-Heavy",
		color: "#37474f",
		fontSize: 20,
	},
	inputStyle: {
		fontSize: "15px !important",
		padding: "5px !important",
		width: 300
	},
	inputRoot: {
		borderRadius: "0px !important"
	},
	inputLabel: {
		color: "#4B86E3"
	},
	// button: {
	// 	boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
	// 	margin: 15,
	// 	padding: "10px 25px",
	// 	fontSize: 15,
	// 	backgroundColor: "#4B86E3",
	// 	color: "#fff",
	// 	fontWeight: "bold",
	// 	borderRadius: 5,
	// 	'&:hover': {
	// 		backgroundColor: "#4B86E3",
	// 	},
	// },
	fontCellHeader: {
		fontFamily: "Futura-Book",
		color: "#fff",
		fontWeight: 900,
		padding: '14px 20px 14px 0px',
	},
	tableHeader: {
		color: "#fff"
	},
	qrContainer: {
		display: 'flex',
		justifyContent: "center",
		padding: 20
	},
	cancelButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		fontFamily: "unicode.futurab",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(143, 163, 173, 0.8)',
		}
	},
	addButton: {
		color: "#fff",
		fontFamily: "unicode.futurab",
		fontSize: 13,
		fontWeight: "bold",
		margin: 10,
		'&:hover': {
			backgroundColor: 'rgba(32,71,142, 0.8)',
		}
	},
	activeChip: {
		margin: 1,
		backgroundColor: theme.palette.primary.main,
		color: "#FFFFFF",
		padding: "3px 3px",
		fontWeight: 'bold'
	},
	inActiveChip: {
		padding: "3px 3px",
		margin: 1,
		fontWeight: 'bold',
		backgroundColor: 'gray',
		color: "#FFFFFF",
		'&:hover': {
			backgroundColor: 'rgba(39,44,15, 0.8)',
		}
	},
	chipBadge: {
		color: "#FFFFFF"
	},
	dialogPaper: {
		backgroundColor: "rgba(0,0,0,0)",
		boxShadow: "none",
		overflow: "hidden"
	},
	fontCellBody: {
		fontFamily: "Futura-Medium-bt",
		color: "#37474f",
		padding: '14px 20px 14px 0px',
	}
}

/**
 * @class
 *
 * Class representing User Subscription Result component
 *
 */
class UserSubscriptionPlans extends Component {
	constructor() {
		super();
		this.state = {
			search_text: "",
			rowsPerPage: 10,
			page: 0,
			code: null,
			isConfirmationDialog: false,
			filterStatus: "ALL",
			isLoading: false,
			assignSeatDialog: false,
			selectedPlan: null,
			selectedUser: null,
			sortBy: "NAME_ASC"
		}
	}

	/** This is a component life cycle method and used to fetch datas*/
	componentDidMount = () => {
		/** fetching all individual users. */
		this.props.dispatch(fetchAllIndividuals(this))
		/** fetching organization plan. */
		this.props.dispatch(fetchOrganizationPlan())
	}

	/**
		 * @function
		 *
		 * function representing table pagination.
		 * @param {Object} _event
		 * @param {Object} newPage
		 */
	handleChangePage = (_event, newPage) => {
		this.setState({
			page: newPage
		});
	}

	/**
		 * @function
		 *
		 * function representing table pagination row.
		 * @param {Object} event
		 */
	handleChangeRowsPerPage = (event) => {
		this.setState({
			page: 0, rowsPerPage: parseInt(event.target.value, 10)
		});
	}

	/**
	* @function
	*
	* function representing apply standard plan to user.
	* 
	*/
	applyStandardPlan = () => {
		this.setState({ isLoading: true })
		this.props.dispatch(applyStandardPlan(this))
		this.setState({ isConfirmationDialog: false });
	}

	/**
	* @function
	*
	* function representing return count.
	* @param {String} filterStatus
	*/
	returnCount = (filterStatus) => {
		const { allIndividuals } = this.props;
		if (filterStatus === "ALL") {
			let result = allIndividuals;
			return result.length;
		} else if (filterStatus === "NO_PLAN") {
			let result = allIndividuals.filter(x => x.userSubscriptionPlan === null);
			return result.length;
		} else if (filterStatus === "BASIC") {
			let result = allIndividuals.filter(x => x.userSubscriptionPlan !== null && x.userSubscriptionPlan.isUnlimited === false && x.userSubscriptionPlan.subscriptionType !== 'ANNUAL');
			return result.length;
		} else if (filterStatus === "STANDARD") {
			let result = allIndividuals.filter(x => x.userSubscriptionPlan !== null && x.userSubscriptionPlan.isUnlimited === false && x.userSubscriptionPlan.subscriptionType === 'ANNUAL');
			return result.length;
		} else if (filterStatus === "PREMIUM") {
			let result = allIndividuals.filter(x => x.userSubscriptionPlan !== null && x.userSubscriptionPlan.isUnlimited === true);
			return result.length;
		}
		return 0;
	}

	/**
	* @function
	*
	* function representing navigate to next page.
	*/
	nextPage = (item) => {
		this.props.dispatch({ type: FETCH_USER_SUBSCRIPTION_PLAN, data: null })
		this.props.navigate(`/user-subscription-plan/${item.id}`)
	}

	/**
	* @function
	*
	* function representing assign subscription plan.
	*/
	assignSubscriptionPlan = () => {
		let isError = false;
		if (this.state.selectedPlan === null || this.state.selectedPlan === undefined) {
			isError = true;
			alert("Please select plan")
		}

		if (isError === false) {
			let data = {}
			data.userProfileId = this.state.selectedUser.id;
			data.subscriptionPlanId = this.state.selectedPlan.id;
			this.props.dispatch(assignOrganizationPlanForUSer(this, data))
		}
	}

	/** rendering User Subscription Plan information. */
	render() {
		const { allIndividuals } = this.props;
		const { page, rowsPerPage, filterStatus } = this.state;
		let result = [];
		if (filterStatus === "ALL") {
			result = allIndividuals;
		} else if (filterStatus === "NO_PLAN") {
			result = allIndividuals.filter(x => x.userSubscriptionPlan === null);
		} else if (filterStatus === "BASIC") {
			result = allIndividuals.filter(x => x.userSubscriptionPlan !== null && x.userSubscriptionPlan.isUnlimited === false && x.userSubscriptionPlan.subscriptionType !== 'ANNUAL');
		} else if (filterStatus === "STANDARD") {
			result = allIndividuals.filter(x => x.userSubscriptionPlan !== null && x.userSubscriptionPlan.isUnlimited === false && x.userSubscriptionPlan.subscriptionType === 'ANNUAL');
		} else if (filterStatus === "PREMIUM") {
			result = allIndividuals.filter(x => x.userSubscriptionPlan !== null && x.userSubscriptionPlan.isUnlimited === true);
		}
		let sortedResult = result;
		switch (this.state.sortBy) {
			case "NAME_ASC":
				sortedResult = result.sort((a, b) => a.firstName.localeCompare(b.firstName))
				break;
			case "NAME_DSC":
				sortedResult = result.sort((a, b) => b.firstName.localeCompare(a.firstName))
				break;
			case "START_DATE_ASC":
				sortedResult = result.sort((a, b) => (a.userSubscriptionPlan !== null && b.userSubscriptionPlan !== null) && new Date(a.userSubscriptionPlan.subscriptionDate) - new Date(b.userSubscriptionPlan.subscriptionDate))
				break;
			case "START_DATE_DSC":
				sortedResult = result.sort((a, b) => (a.userSubscriptionPlan !== null && b.userSubscriptionPlan !== null) && new Date(b.userSubscriptionPlan.subscriptionDate) - new Date(b.userSubscriptionPlan.subscriptionDate))
				break;
			case "END_DATE_ASC":
				sortedResult = result.sort((a, b) => (a.userSubscriptionPlan !== null && b.userSubscriptionPlan !== null) && new Date(a.userSubscriptionPlan.expiryDate) - new Date(b.userSubscriptionPlan.expiryDate))
				break;
			case "END_DATE_DSC":
				sortedResult = result.sort((a, b) => (a.userSubscriptionPlan !== null && b.userSubscriptionPlan !== null) && new Date(b.userSubscriptionPlan.expiryDate) - new Date(b.userSubscriptionPlan.expiryDate))
				break;
			default:
				break;
		}
		let resultData = this.state.search_text !== '' ? sortedResult.filter(x => x.phone === this.state.search_text) : sortedResult
		return (
			<div style={styles.paper}>
				<div>
					<div style={{ display: "flex", justifyContent: "space-between", marginTop: 20, marginLeft: 10 }}>
						<Typography style={{ color: theme.palette.secondary.light, fontWeight: headerFont.fontWeight, fontFamily: headerFont.fontFamily, fontSize: headerFont.fontSize, }} >Users Subscription Plan</Typography>
						<TablePagination
							rowsPerPageOptions={[20]}
							component="div"
							count={resultData.length}
							rowsPerPage={this.state.rowsPerPage}
							page={this.state.page}
							slotProps={{
								select: {
									inputProps: { 'aria-label': 'rows per page' },
									native: true,
								}
							}}
							onPageChange={this.handleChangePage}
							onRowsPerPageChange={this.handleChangeRowsPerPage}
							labelRowsPerPage={null}
							labelDisplayedRows={
								({ from, to, count }) => null
							}
							ActionsComponent={
								TablePaginationActions
							}
						/>
						<TextField
							margin="normal"
							id="search-text"
							label="Search"
							placeholder="Phone"
							name="mobile-number"
							autoComplete="off"
							variant="standard"
							value={this.state.search_text}
							onChange={event =>
								this.setState({ search_text: event.target.value.replace(/[^\d]+/g, ''), page: 0 })
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{ classes: { input: styles.inputStyle, root: styles.inputRoot, inputlabel: styles.inputLabel } }}
							autoFocus
						/>
					</div>
					<div style={{ display: "flex", justifyContent: "space-between", }}>

						<Badge
							color="error"
							onClick={() => this.setState({ filterStatus: 'ALL', page: 0 })}
							badgeContent={this.returnCount("ALL")} max={9999}
							style={{ ...styles.chipBadge, margin: "10px 15px", }}
						>
							<Chip size="small" label={`ALL`} clickable style={filterStatus === 'ALL' ? { ...styles.activeChip, padding: '5px 15px', borderRadius: 8 } : { ...styles.inActiveChip, padding: '5px 15px', borderRadius: 8 }} />
						</Badge>
						<Badge
							color={"error"}
							onClick={() => this.setState({ filterStatus: 'NO_PLAN', page: 0 })}
							badgeContent={this.returnCount("NO_PLAN")} max={9999}
							style={{ ...styles.chipBadge, margin: "10px 15px", }}
						>
							<Chip size="small" label={`NO PLAN`} clickable style={filterStatus === 'NO_PLAN' ? { ...styles.activeChip, padding: '5px 15px', borderRadius: 8 } : { ...styles.inActiveChip, padding: '5px 15px', borderRadius: 8 }} />
						</Badge>
						<Badge
							color={"error"}
							onClick={() => this.setState({ filterStatus: 'BASIC', page: 0 })}
							badgeContent={this.returnCount("BASIC")} max={9999}
							style={{ ...styles.chipBadge, margin: "10px 15px", }}
						>
							<Chip size="small" label={`BASIC`} clickable style={filterStatus === 'BASIC' ? { ...styles.activeChip, padding: '5px 15px', borderRadius: 8 } : { ...styles.inActiveChip, padding: '5px 15px', borderRadius: 8 }} />
						</Badge>
						<Badge
							color={"error"}
							onClick={() => this.setState({ filterStatus: 'STANDARD', page: 0 })}
							badgeContent={this.returnCount("STANDARD")} max={9999}
							style={{ ...styles.chipBadge, margin: "10px 15px", }}
						>
							<Chip size="small" label={`STANDARD`} clickable style={filterStatus === 'STANDARD' ? { ...styles.activeChip, padding: '5px 15px', borderRadius: 8 } : { ...styles.inActiveChip, padding: '5px 15px', borderRadius: 8 }} />
						</Badge>
						<Badge
							color={"error"}
							onClick={() => this.setState({ filterStatus: 'PREMIUM', page: 0 })}
							badgeContent={this.returnCount("PREMIUM")} max={9999}
							style={{ ...styles.chipBadge, margin: "10px 15px", }}
						>
							<Chip size="small" label={`PREMIUM`} clickable style={filterStatus === 'PREMIUM' ? { ...styles.activeChip, padding: '5px 15px', borderRadius: 8 } : { ...styles.inActiveChip, padding: '5px 15px', borderRadius: 8 }} />
						</Badge>
						{filterStatus === "NO_PLAN" && resultData.length > 0 && <Button size="small" onClick={() => this.setState({ isConfirmationDialog: true })} variant="contained" color="secondary" style={styles.addButton} >Apply Standard Plan</Button>}
					</div>
				</div>
				<div style={{ margin: "0px 15px" }}>
					<Table >
						<TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10 }}>
							<TableRow>
								<TableCell align="center" style={styles.fontCellHeader}>
									<div style={{ display: "flex", justifyContent: 'center', alignItems: "center" }}>
										User Info
										<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_ASC" })} />
										<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_DSC" })} />
									</div>
								</TableCell>
								<TableCell align="center" style={styles.fontCellHeader}>Subscription Plan</TableCell>
								<TableCell align="center" style={styles.fontCellHeader}>
									<div style={{ display: "flex", justifyContent: 'center', alignItems: "center" }}>
										Start Date
										<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "START_DATE_ASC" })} />
										<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "START_DATE_DSC" })} />
									</div>
								</TableCell>
								<TableCell align="center" style={styles.fontCellHeader}>
									<div style={{ display: "flex", justifyContent: 'center', alignItems: "center" }}>
										End Date
										<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "END_DATE_ASC" })} />
										<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "END_DATE_DSC" })} />
									</div>
								</TableCell>
								<TableCell align="center" style={styles.fontCellHeader}>Plan Type</TableCell>
								<TableCell align="center" style={styles.fontCellHeader}>Action</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{resultData.length > 0 && resultData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) =>
								<TableRow key={item.id} style={{ backgroundColor: index % 2 === 0 ? "#fff" : "whitesmoke" }}>
									<TableCell align="center" style={styles.fontCellBody}>
										<Typography style={{ fontWeight: "bold", fontSize: 14 }}>{item.firstName + " " + item.lastName}</Typography>
										<Typography style={{ fontSize: 14 }}>{item.phone}</Typography>
									</TableCell>
									<TableCell align="center" style={styles.fontCellBody}><Typography>
										{item.userSubscriptionPlan !== null ? (item.userSubscriptionPlan.subscriptionPlan.isUnlimited === true) ? <img alt="premium" src={Premiumicon} height={25} width="auto" /> : (item.userSubscriptionPlan.subscriptionPlan.isUnlimited === false && item.userSubscriptionPlan.subscriptionPlan.subscriptionType === 'ANNUAL') ? <img alt="standard" src={Standardicon} height={25} width="auto" /> : <img alt="basic" src={Basicicon} height={25} width="auto" /> : "-"}
									</Typography></TableCell>
									<TableCell align="center" style={styles.fontCellBody}><Typography>{item.userSubscriptionPlan !== null ? moment(item.userSubscriptionPlan.subscriptionDate).format("MM/DD/YYYY") : "-"}</Typography></TableCell>
									<TableCell align="center" style={styles.fontCellBody}><Typography>{(item.userSubscriptionPlan !== null && item.userSubscriptionPlan.expiryDate !== null) ? moment(item.userSubscriptionPlan.expiryDate).format("MM/DD/YYYY") : "-"}</Typography></TableCell>
									<TableCell align="center" style={styles.fontCellBody}><Typography>{item.userSubscriptionPlan !== null ? item.userSubscriptionPlan.isFreePlan === true ? "FREE" : "PAID" : "-"}</Typography></TableCell>
									<TableCell align="center" style={styles.fontCellBody}>
										<div>
											{(item.organizationMembers.length > 0 && item.userSubscriptionPlan === null) && <Button size="small" onClick={() => this.setState({ assignSeatDialog: true, selectedUser: item })} variant="contained" color="primaryButton" style={styles.addButton} >Assign Plan</Button>}
											<Button size="small" onClick={() => this.nextPage(item)} variant="contained" color="secondary" style={styles.cancelButton} >View</Button>
										</div>
									</TableCell>
								</TableRow>)}
						</TableBody>
					</Table>
					{resultData.length === 0 && <Typography style={{ textAlign: "center", padding: 15, fontSize: 17 }}>There are no members</Typography>}
				</div>
				<Dialog
					maxWidth={"xs"}
					open={this.state.isConfirmationDialog}>
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{"Confirmation"}</Typography>
					</DialogTitle>
					<DialogContent>
						<div style={styles.qrContainer}>
							{`Are you sure, you want to apply standard plan for all users?`}
						</div>
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={() => this.applyStandardPlan()} variant="contained" color="primary" style={styles.viewGreenButton}>Yes</Button>
						<Button size="small" onClick={() => this.setState({ isConfirmationDialog: false })} variant="contained" color="secondary" style={styles.viewRedButton}>No</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.isLoading}
					classes={{
						paper: styles.dialogPaper,
					}}
				>
					<div style={{ boxShadow: 0, overflow: "none" }}>
						<CircularProgress color="primary" size={40} />
					</div>
				</Dialog>

				<Dialog
					maxWidth={"sm"}
					open={this.state.assignSeatDialog} >
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "2%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>Assign seat to member</Typography>
					</DialogTitle>
					<DialogContent >
						<div>
							{this.props.organizationPlan.map(item =>
								item.code !== "OBSC" && <div style={{ display: "flex", alignItems: "center" }}>
									<Radio
										checked={this.state.selectedPlan !== null ? this.state.selectedPlan.id === item.id : ""}
										onChange={() => this.setState({ selectedPlan: item })}
										value={item.id}
										name="radio-button-demo"
										inputProps={{ 'aria-label': 'A' }}
									/>
									<div style={{ display: "flex", justifyContent: 'space-between' }}>
										<Typography style={{ color: "#000", fontSize: 14 }}>{item.name} Seat</Typography>
									</div>
								</div>)}
						</div>
					</DialogContent>
					<DialogActions style={{ display: "flex", justifyContent: "center" }}>
						<Button size="small" onClick={() => this.assignSubscriptionPlan()} variant="contained" color="primary" style={styles.addButton}>Submit</Button>
						<Button size="small" onClick={() => this.setState({ assignSeatDialog: false })} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
					</DialogActions>
				</Dialog>

			</div>
		)
	}
};

/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
	return {
		allIndividuals: state.userSubscriptionPlanReducer.allIndividuals,
		organizationPlan: state.organizationReducer.organizationPlan,
	};
};

UserSubscriptionPlans.propTypes = {
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(UserSubscriptionPlans));