import React, { Component } from "react";
import { Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import Logo from '../../assets/images/medfilo.png';
// import PrivacyNewpdf from '../../assets/images/Privacynew.pdf'
// import TermsNewpdf from '../../assets/images/Termsnew.pdf'
import { THEMECOLOR } from "../../helpers/Constants";

const styles = {
	paper: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
		height: '100vh',

	},
	logOutText: {
		fontSize: 16,
		color: "#fff",
		textDecorationLine: "underline"
	},
	image: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "0 2%",
		height: "150px",
		width: "150px"
	},
	bottomsheet: {
		dispaly: 'flex',
		justifyContent: 'center',
		backgroundColor: "#fff",
		borderTopLeftRadius: 20,
		borderTopRightRadius: 20,
		position: 'fixed',
		alignItems: 'center',
		alignSelf: 'center',
		width: "100%",
		height: "40vh",
		bottom: '0px'
	},
	titleText: {
		fontSize: 20,
		color: '#000000',
		fontWeight: "bold",
		textAlign: "center"
	},
	titleView: {
		marginTop: '30px',
		display: 'flex',
		justifyContent: 'center'
	},
	contentText: {
		fontSize: 15,
		color: "#000000",
	},
	contentTexts: {
		fontSize: 15,
		color: "#000000",
		paddingLeft: 10

	},
	content: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'row',
		margin: 20,
	},
	linkText: {
		fontSize: 15,
		color: THEMECOLOR.SECONDARY,
		textDecorationLine: "underline",
		paddingLeft: 10
	},
	supportContent: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'row',
		marginHorizontal: 20,
		paddingBottom: 10,
		borderBottomWidth: 1,
		borderBottomColor: "#D4D2CA",
		margin: 20,
	},
	supportText: {
		fontSize: 14,
		fontWeight: "bold",
		color: "#000000"
	},
	confirmContent: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'row',
		marginHorizontal: 20,
		margin: 10
	},
	confirmText: {
		fontSize: 13,
		color: "#000000",
		fontWeight: "bold",
	},
	supperScript: {
		lineHeight: 2,
		fontSize: 8,
		color: "#000000"
	},
	textStyle: {
		fontSize: 13,
		color: "#000000",
		paddingLeft: 5
	},
	confirmLinkText: {
		fontSize: 13,
		color: '#007AFF',
		textDecorationLine: "underline",
		paddingLeft: 10
	},
	bottomContent: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'row',
		marginHorizontal: 20,
		marginBottom: 20,
		margin: 7
	},
	bottomText: {
		fontSize: 11,
		color: "#000000"
	},
	orgLinkText: {
		fontSize: 11,
		color: '#007AFF',
		textDecorationLine: "underline",
		marginHorizontal: 5,
	},
	divider: {
		width: "100%",
		height: 1,
		backgroundColor: "#D1CFCF"
	},
	lineStyle: {
		height: 1,
		width: 120,
		background: "#000",
	},
	divStyle: {
		display: 'flex',
		padding: 5,
		justifyContent: 'center'
	},
	imageContainer: {
		marginTop: (window.innerHeight / 3) - 100,
	},
	logOutImage: {
		width: 25,
		height: 25,
		margin: 16
	},
	noButton: {
		display: "flex",
		justifyContent: "flex-start",
		fontSize: 16,
		textAlign: "center",
		color: "#96A1C4"
	},
	yesButton: {
		fontSize: 16,
		textAlign: "center",
		paddingVertical: 15,
		color: "#6F7FAF",
		fontWeight: '700'
	},
	logOutDivider: {
		height: 2,
		backgroundColor: "#2BB5E4",

	},
	lastDivider: {
		height: 0.2,
		backgroundColor: "#D1CFCF"

	},
	verticleLine: {
		height: 0.2,
		backgroundColor: "#D1CFCF"
	}
};

/**
* @class
*
* Class representing  MoreInformation component
*
*/

class MoreInformation extends Component {

	constructor() {
		super();
		this.state = {}
	}

	/** render the  MoreInformation details. */
	render() {

		return (
			<div style={styles.paper}>
				<Grid container style={{ justifyContent: "flex-end" }} />
				<Grid container justifyContent="center" style={styles.imageContainer}>
					<img
						alt="Logo"
						src={Logo}
						style={styles.image}
					/>
				</Grid>
				<div style={styles.bottomsheet}>
					<div style={styles.titleView}>
						<Typography style={styles.titleText}>App Information</Typography>
					</div>
					{/* <div style={styles.divStyle}>
						<div style={styles.lineStyle} />
					</div> */}
					<div style={styles.divider} />
					<div style={styles.content}>
						<Typography style={styles.contentText}>Our</Typography>
						<a style={styles.linkText} rel="noopener noreferrer" target='_blank' href={"https://medfilo.com/privacy-policy/"}  ><Typography >Privacy Policy</Typography> </a>
						<Typography style={styles.contentTexts}> and </Typography>
						<a style={styles.linkText} rel="noopener noreferrer" target='_blank' href={"https://medfilo.com/terms-of-use/"}> <Typography >Terms of services</Typography></a>
					</div>
					<div style={styles.supportContent}>
						<Typography style={styles.supportText}>Support contact</Typography>
						<a href="mailto:app.support@confirmd.io" style={styles.linkText}>info@medfilo.com</a>
					</div>
					<div style={styles.divider} />
					{/* <div style={styles.confirmContent}>
						<Typography style={styles.confirmText}>MedFilo </Typography>
						<Typography style={styles.supperScript}>TM</Typography>
						<Typography style={styles.textStyle}> by MedFilo -</Typography>
						<a target='_blank' rel="noopener noreferrer" href="https://www.medfilo.io" style={styles.confirmLinkText} >medfilo.io</a>
					</div>
					<div style={styles.bottomContent}>
						<Typography style={styles.bottomText}>The International Public Safety Data Institute -</Typography>
						<a target='_blank' rel="noopener noreferrer" href="https://www.i-psdi.org" style={styles.orgLinkText} >i-psdi.org</a>
					</div> */}
				</div>
			</div>
		);
	}
}

/** Get data from store and assign to props */
const mapStateToProps = (state) => {
	return {

	};
};

export default connect(mapStateToProps)(MoreInformation);