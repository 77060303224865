import api from '../api';
import { FETCH_CREDIT_COUPONS, ALERT_DETAIL } from "./actionTypes";
import { fetchUser } from './userAction'

export function fetchCreditCoupons() {
    return function (dispatch) {
        api.get("credit-coupon").then((response) => {
            if (response.status === "success") {
                dispatch({
                    type: FETCH_CREDIT_COUPONS,
                    data: response.payload
                });
            }
        });
    }
}

export function generateCoupon(self, data, userProfileId) {
    return dispatch => {
        api.post(`credit-coupon/${userProfileId}`, data).then((response) => {
            if (response.status === "success") {
                // console.log(`Hit ===AC`)
                const { couponType } = self.state;
                dispatch(fetchCreditCoupons());
                dispatch(fetchUser());
                dispatch({ type: ALERT_DETAIL, data: true, message: "Credit codes generated successfully", severity: "success" })
                self.setState({
                    // isLoading: false,
                    // dialogOpen: false,
                    limit: couponType === 'split' ? 1 : 0,
                    couponCount: 0,
                    creditPerCoupon: 1,
                    totalCredit: 0,
                    creditValue: 0
                })
            }
            else {
                // self.setState({ isLoading: false, })
                // console.log('Hit Err')
                dispatch({ type: ALERT_DETAIL, data: true, message: response.message, severity: "error" })
            }
        })
    }
}