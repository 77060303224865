import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Table, TableBody, TableCell, TableHead, TableRow,
	Typography, Grid, Button, Dialog, DialogActions,
	DialogContent, DialogTitle, TextField,
	FormControl, FormControlLabel, Radio, RadioGroup,
	FormHelperText, Toolbar, CircularProgress, TablePagination
} from "@mui/material";
import { fetchVaccineManufacturer, addVaccineManufacturer, updateVaccineManufacturer } from '../../actions/vaccinemanufacturerAction';
import TablePaginationActions from "../Dashboard/TablePaginationActions";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { withRouter } from "../../withRouter";
import theme from '../../theme';
import { headerFont } from '../../helpers/Constants';

/**Created common styles for UI.*/
const styles = {
	textStyle: {
		fontFamily: "Futura-Heavy",
		color: "#20478E",
		fontSize: 20,
	},
	addButton: {
		// backgroundColor: "#20478e",
		color: "white",
		fontFamily: "unicode.futurab",
		borderRadius: "10",
		textTransform: 'none',
	},
	cancelButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		fontFamily: "unicode.futurab",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(143, 163, 173, 0.8)',
		}
	},
	editButton: {
		backgroundColor: "#8fa3ad",
		color: "white",
		fontFamily: "unicode.futurab",
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'rgba(143, 163, 173, 0.8)',
		}
	},
	fontCellHeader: {
		fontFamily: "Futura-Book",
		color: "#fff",
		fontWeight: 900,
		padding: '14px 20px 14px 0px',

	},
	fontTableCellBody: {
		fontFamily: "Futura-Medium-bt",
		color: "#37474f",
		padding: '14px 20px 14px 0px',
	},
	inputStyle: {
		fontFamily: "Futura-Book",
		fontSize: 14
	},
	labelStyle: {
		fontFamily: "Futura-Book",
		fontSize: 14
	},
	dialogPaper: {
		backgroundColor: "rgba(0,0,0,0)",
		boxShadow: "none",
		overflow: "hidden"
	}
}

/**
 * @class
 *
 * Class representing VaccineManufacturer component
 *
 */
class VaccineManufacturer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			vaccinemanufacturer: [],
			dialogOpen: false,
			id: null,
			name: "",
			name_error: false,
			email: "",
			email_error: false,
			invalidEmail_error: false,
			phoneNumber: "",
			phoneNumber_error: false,
			invalidPhoneNumber_error: false,
			address: "",
			address_error: false,
			city: "",
			city_error: false,
			state: "",
			state_error: false,
			country: "",
			country_error: false,
			status: "ACTIVE",
			status_error: false,
			invalid_code_error: null,
			mode: "",
			search_text: "",
			rowsPerPage: 10,
			page: 0,
			isLoading: false,
			sortBy: "NAME_ASC"
		}
	}

	/**This is a component life cycle method and used to assigning props values to state variable.*/
	static getDerivedStateFromProps(props, state) {
		if (props.vaccinemanufacturer !== state.vaccinemanufacturer) {
			return {
				vaccinemanufacturer: props.vaccinemanufacturer
			}
		}
		return null;
	}

	/** This is a component life cycle method and used to fetch datas*/
	componentDidMount() {
		/** fetching VaccineManufacturer details. */
		this.props.dispatch(fetchVaccineManufacturer())
	}

	/**
	  * @function
	 *
	 * function representing table pagination.
	 * @param {Object} _event
	 * @param {Object} newPage
	 */
	handleChangePage = (_event, newPage) => {
		this.setState({
			page: newPage
		});
	}

	/**
	 * @function
	 *
	 * function representing table pagination rows.
	 * @param {Object} event
	 */
	handleChangeRowsPerPage = (event) => {
		this.setState({
			page: 0, rowsPerPage: parseInt(event.target.value, 10)
		});
	}

	/**
	 * @function
	 * This function representing email validation.
	 * @param {String} email The email string
	 */
	validateEmail(email) {
		let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	/**
		* @function
		* This function representing add or edit vaccine manufacturer dialog screen will open.
		* @param {Object} item The item json object
		* @param {String} mode The mode string
		*/
	handleClick = (item, mode) => {
		if (mode === "ADD") {
			this.setState({ mode: mode, dialogOpen: true })
		}
		else {
			this.setState({
				id: item.id,
				dialogOpen: true,
				mode: mode,
				name: item.name,
				name_error: false,
				email: item.email,
				email_error: false,
				phoneNumber: item.phoneNumber,
				phoneNumber_error: false,
				address: item.address,
				address_error: false,
				city: item.city,
				city_error: false,
				state: item.state,
				state_error: false,
				country: item.country,
				country_error: false,
				status: item.status,
				status_error: false,
				invalid_code_error: null,
			})
		}
	}

	/**
	 *  @function
	 * This function representing close add or edit vaccine manufacturer dialog screen.
	 */
	handleClose = () => {
		this.setState({
			dialogOpen: false,
			id: null,
			name: "",
			name_error: false,
			email: "",
			email_error: false,
			phoneNumber: "",
			phoneNumber_error: false,
			address: "",
			address_error: false,
			city: "",
			city_error: false,
			state: "",
			state_error: false,
			country: "",
			country_error: false,
			status: "ACTIVE",
			status_error: false,
			invalid_code_error: null,
			mode: ""
		})
	};

	/**
	 * @function
	 * This function representing number validation.
	 * @param {number} number The number string
	 */
	checkNumber = (number) => {
		let isNumber = /^-{0,1}\d+$/.test(number)
		return isNumber;
	};

	/**
	 * This function representing add new VaccineManufacturer and edit VaccineManufacturer details.
	 * @param {String} mode The mode string
	 */
	handleSubmit = (mode) => {
		let { id, name, email, phoneNumber, address, city, state, country, status } = this.state;
		let isError = false;
		if (name === "" || name === null) {
			this.setState({ name_error: true })
			isError = true;
		}
		if (email !== null && email !== "") {
			if (!this.validateEmail(this.state.email)) {
				this.setState({ invalidEmail_error: true })
				isError = true
			}
		}
		if (phoneNumber !== null && phoneNumber !== "") {
			if (this.checkNumber(phoneNumber) !== true || phoneNumber.length !== 10) {
				this.setState({ invalidPhoneNumber_error: true })
				isError = true
			}
		}
		if (status === "" || status === null) {
			this.setState({ status_error: true, })
			isError = true;
		}
		if (isError === false) {
			let vaccinemanufacturerObj = {};
			vaccinemanufacturerObj.name = name;
			vaccinemanufacturerObj.email = email;
			vaccinemanufacturerObj.phoneNumber = phoneNumber;
			vaccinemanufacturerObj.address = address;
			vaccinemanufacturerObj.city = city;
			vaccinemanufacturerObj.state = state;
			vaccinemanufacturerObj.country = country;
			vaccinemanufacturerObj.status = status;
			if (mode === "ADD") {
				this.setState({ isLoading: true })
				this.props.dispatch(addVaccineManufacturer(this, vaccinemanufacturerObj))
			}
			else {
				this.setState({ isLoading: true })
				this.props.dispatch(updateVaccineManufacturer(this, vaccinemanufacturerObj, id))
			}
		}
	};

	/** rendering VaccineManufacturers information. */
	render() {
		const { page, rowsPerPage } = this.state;
		let VaccineManufacturers = this.state.sortBy === "NAME_ASC" ? this.state.vaccinemanufacturer.sort((a, b) => a.name.localeCompare(b.name)) : this.state.vaccinemanufacturer.sort((a, b) => b.name.localeCompare(a.name))
		let result = this.state.search_text !== '' ? VaccineManufacturers.filter(x => (x.name).toString().toLowerCase().includes((this.state.search_text).toString().toLowerCase())) : VaccineManufacturers;
		return (
			<div style={{ paddingLeft: 10, paddingRight: 10 }}>
				<Grid container spacing={0} style={{ marginTop: 5 }}>
					<Grid item xs={12} sm={12}>
						<Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
							<div>
								<Typography style={{ color: theme.palette.secondary.light, fontWeight: headerFont.fontWeight, fontFamily: headerFont.fontFamily, fontSize: headerFont.fontSize, }}>Vaccine Manufacturer</Typography>
							</div>
							<TablePagination
								rowsPerPageOptions={[20]}
								component="div"
								count={result.length}
								rowsPerPage={this.state.rowsPerPage}
								page={this.state.page}
								slotProps={{
									select: {
										inputProps: { 'aria-label': 'rows per page' },
										native: true,
									}
								}}
								onPageChange={this.handleChangePage}
								onRowsPerPageChange={this.handleChangeRowsPerPage}
								labelRowsPerPage={null}
								labelDisplayedRows={
									({ from, to, count }) => null
								}
								ActionsComponent={
									TablePaginationActions
								}
							/>
							<div style={{ display: "flex", padding: 10 }}>
								<Button size="small" variant="contained" color={"primary"} style={{ ...styles.addButton, marginRight: 10 }} onClick={() => this.handleClick(null, "ADD")} >
									Add Vaccine Manufacturer
								</Button>
							</div>
							<TextField
								margin="normal"
								variant='standard'
								color={'primary'}
								id="search-text"
								label="Search"
								placeholder="Name"
								name="mobile-number"
								autoComplete="off"
								value={this.state.search_text}
								onChange={event =>
									this.setState({ search_text: event.target.value, page: 0 })
								}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ classes: { input: styles.inputStyleForSearchText, root: styles.inputRoot, inputlabel: styles.inputLabel } }}
								autoFocus
							/>
						</Toolbar>
					</Grid>
					<Grid item xs={8} sm={12} style={{ paddingTop: 10 }}>
						<Table>
							<TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10 }}>
								<TableRow>
									<TableCell align="center" style={{ fontFamily: "Futura-Book", color: ' #fff', fontWeight: 900, padding: '14px 0px 14px 20px', display: "flex", justifyContent: "center", alignItems: "center" }}>
										Name
										<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_ASC" })} />
										<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_DSC" })} />
									</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>E-mail</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Phone Number</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Address</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>City</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>State</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Country</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Status</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Edit</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									result.length > 0 ? result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
										<TableRow key={item.id} style={{ backgroundColor: index % 2 === 0 ? "#fff" : "whitesmoke" }}>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.name}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.email}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.phoneNumber}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.address}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.city}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.state}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.country}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.status}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>
												<Button size="small" style={styles.editButton} onClick={() => this.handleClick(item, "EDIT")}>EDIT</Button>
											</TableCell>
										</TableRow>)
									)
										:
										<TableRow>
											<TableCell colSpan={9} style={{...styles.fontTableCellBody, textAlign:'center'}}>There are no test types</TableCell>
										</TableRow>
								}
							</TableBody>
						</Table>
					</Grid>
				</Grid>
				<Dialog
					maxWidth={"sm"}
					open={this.state.dialogOpen}>
					<DialogTitle id="form-dialog-title" style={{ padding: 0, marginBottom: 20, backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)" }}>
						<Typography style={{ padding: "1%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}>{this.state.mode === "ADD" ? "Add Vaccine Manufacturer" : "Update Vaccine Manufacturer"}</Typography>
					</DialogTitle>
					<DialogContent style={{ overflowY: "hidden" }}>
						<Grid container justify="space-between" style={{ marginTop: -20, padding: "2% 5% 0 5%" }}>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									id="name"
									label="Name"
									name="name"
									style={styles.margin}
									value={this.state.name.charAt(0).toUpperCase() + this.state.name.slice(1)}
									autoComplete="new_password"
									onChange={(event) => this.setState({ name: event.target.value.trimStart(), name_error: false })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle }
									}}
									fullWidth
									error={this.state.name_error === true ? true : false}
									helperText={this.state.name_error === true ? "Please enter name" : false}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									id="email"
									label="E-mail"
									name="email"
									autoComplete="new_password"
									style={styles.margin}
									value={this.state.email}
									onChange={(event) => this.setState({ email: event.target.value.trimStart(), invalidEmail_error: false })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle }
									}}
									fullWidth
									error={this.state.invalidEmail_error === true ? true : false}
									helperText={this.state.invalidEmail_error === true ? "Please enter valid email" : false}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }} >
								<TextField
									id="phoneNumber"
									label="Phone Number"
									name="phoneNumber"
									autoComplete="new_password"
									style={styles.margin}
									value={this.state.phoneNumber}
									onChange={(event) => event.target.value.match(/^\d*$/g) && this.setState({ phoneNumber: event.target.value, invalidPhoneNumber_error: false, })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle }
									}}
									fullWidth
									error={this.state.invalidPhoneNumber_error === true ? true : false}

									helperText={this.state.invalidPhoneNumber_error === true ? "Invalid mobile number" : false}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }} >
								<TextField
									id="address"
									label="Address"
									name="address"
									autoComplete="new_password"
									style={styles.margin}
									value={this.state.address}
									onChange={(event) => this.setState({ address: event.target.value.trimStart() })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle }
									}}
									fullWidth
									error={this.state.address_error === true ? true : false}
									helperText={this.state.address_error === true ? "Please enter address" : false}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									id="city"
									label="City"
									name="city"
									autoComplete="new_password"
									style={styles.margin}
									value={this.state.city}
									onChange={(event) => this.setState({ city: event.target.value.trimStart() })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle }
									}}
									fullWidth
									error={this.state.city_error === true ? true : false}
									helperText={this.state.city_error === true ? "Please enter city" : false}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									id="state"
									label="State"
									name="state"
									autoComplete="new_password"
									style={styles.margin}
									value={this.state.state}
									onChange={(event) => this.setState({ state: event.target.value.trimStart() })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle }
									}}
									fullWidth
									error={this.state.state_error === true ? true : false}
									helperText={this.state.state_error === true ? "Please enter state" : false}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<TextField
									id="country"
									label="Country"
									name="country"
									style={styles.margin}
									value={this.state.country}
									onChange={(event) => this.setState({ country: event.target.value.trimStart() })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										classes: { input: styles.inputStyle }
									}}
									fullWidth
									error={this.state.country_error === true ? true : false}
									helperText={this.state.country_error === true ? "Please enter country" : false}
								/>
							</Grid>
							<Grid item xs={6} style={{ paddingRight: 8 }}>
								<div style={{ padding: "2% 0 2% 0" }}>
									<Typography style={{ fontFamily: "Futura-Book", fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' }}>Status</Typography>
									<FormControl component="fieldset">
										<RadioGroup aria-label="position" name="position" value={this.state.status}
											onChange={(event) => this.setState({ status: event.target.value, status_error: false })} row>
											<FormControlLabel
												value="ACTIVE"
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>Active</span>}
												labelPlacement="end"
											/>
											<FormControlLabel
												value="INACTIVE"
												control={<Radio style={{ color: theme.palette.primary.main }} />}
												label={<span style={styles.labelStyle}>In Active</span>}
												labelPlacement="end"
											/>
										</RadioGroup>
										{this.state.status_error === true && <FormHelperText style={{ color: "red" }}>{"Please select status"}</FormHelperText>}
									</FormControl>
								</div>

							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button size="small" onClick={() => this.handleSubmit(this.state.mode)} variant="contained" color="primary" style={styles.addButton}>Submit</Button>
						<Button size="small" onClick={() => this.handleClose()} variant="contained" color="secondary" style={styles.cancelButton}>Cancel</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.isLoading}
					classes={{
						paper: styles.dialogPaper,
					}}
				>
					<div style={{ boxShadow: 0, overflow: "none" }}>
						<CircularProgress color="primary" size={40} />
					</div>
				</Dialog>
			</div>
		)
	}
};

/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
	return {
		vaccinemanufacturer: state.vaccineManufacturerReducer.vaccinemanufacturer,
	}
};

VaccineManufacturer.propTypes = {
	dispatch: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps)(VaccineManufacturer));