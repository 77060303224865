import React, { Component } from "react";
import {
	Badge, Typography, Button, Dialog, DialogActions,
	DialogContent, DialogTitle, Grid, Tabs, Tab, TablePagination,
	Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Chip,
} from "@mui/material";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { fetchAllRequestedUsers, updateStatusUserProfile } from "../../actions/userAction";
import CloseIcon from '@mui/icons-material/Close';
import { END_POINT } from "../../helpers/Constants";
import _ from 'underscore';
import moment from 'moment';
import NoImage from '../../assets/images/no-image-available.png';
import TablePaginationActions from "../Dashboard/TablePaginationActions";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { withRouter } from "../../withRouter";
import theme from "../../theme";

function TabContainer(props) {
	return (
		<Typography component="div" style={{ padding: 8 * 3 }}>
			{props.children}
		</Typography>
	);
};

TabContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

/**Created common styles for UI.*/
const styles = {
	topContainer: {
		padding: "5px 10px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		backgroundColor: "#5186E7",
	},
	titleText: {
		color: "#fff",
		fontSize: 16,
		fontWeight: "bold"
	},
	iconButton: {
		padding: 0
	},
	iconStyle: {
		color: "#fff"
	},
	collapesContainer: {
		height: 250,
		overflowY: "auto"
	},
	collapesRow: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottom: "2px solid #5186E7",
		paddingBottom: 10,
		margin: 15,
	},
	buttonToolbar: {
		display: "flex",
		justifyContent: "center",
		alignItems: 'center',
		bottom: "5%",
		margin: 10
	},
	viewButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		backgroundColor: "#FF8C00",
		color: "#fff",
		fontWeight: 'bold',
		fontSize: 13,
		padding: "5px 15px",
		'&:hover': {
			backgroundColor: "#FF8C00",
		},
	},
	rejectButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		backgroundColor: "#B22222",
		color: "#fff",
		fontSize: 13,
		padding: "5px 15px",
		'&:hover': {
			backgroundColor: "#B22222",
		},
		marginRight: 20
	},
	acceptButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		backgroundColor: "#32CD32",
		color: "#fff",
		fontSize: 13,
		padding: "5px 15px",
		'&:hover': {
			backgroundColor: "#32CD32",
		},
	},
	closeButton: {
		boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
		backgroundColor: "#5186E7",
		color: "#fff",
		fontSize: 13,
		padding: "5px 15px",
		'&:hover': {
			backgroundColor: "#5186E7",
		},
		position: "fixed",
		left: "47%",
		bottom: "5%",
	},
	dialogPaper: {
		backgroundColor: "transparent",
		overflow: "hidden",
		boxShadow: "none",
		position: "relative"
	},
	statusView: {
		display: "flex",
		justifyContent: "flex-end",
		width: "40%",
	},
	dialogTitle: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "0px"
	},
	dialogTitleText: {
		fontSize: 18,
		fontWeight: "bold",
		paddingLeft: 10,
		color: "#fff"
	},
	closeIcon: {
		backgroundColor: '#fff',
		color: "red"
	},
	fontCellHeader: {
		fontFamily: "Futura-Book",
		color: "#fff",
		fontWeight: 900,
		padding: '14px 20px 14px 0px',
		border: "none",
	},
	fontTableCellBody: {
		fontFamily: "Futura-Medium-bt",
		color: "#37474f",
		padding: '14px 20px 14px 0px',
	},
	chipBadge: {
		backgroundColor: 'red',
		color: "#FFFFFF"
	},
	activeChip: {
		// margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
		color: "#FFFFFF",
		padding: "3px 3px",
		fontWeight: 'bold'
	},
	inActiveChip: {
		padding: "3px 3px",
		// margin: theme.spacing(1),
		fontWeight: 'bold',
		backgroundColor: 'gray',
		color: "#FFFFFF",
		'&:hover': {
			backgroundColor: 'rgba(39,44,15, 0.8)',
		}
	},
};

/**
 * @class
 *
 * Class representing Requests component
 *
 */

class Home extends Component {
	constructor() {
		super();
		this.state = {
			statusDialog: false,
			viewImageDialog: false,
			selectedStatus: "",
			selectedUser: null,
			all_users: [],
			verifier: [],
			access_user: [],
			isLoading: false,
			value: 0,
			search_text: "",
			rowsPerPage: 10,
			page: 0,
			filterStatus: "PENDING",
			sortBy: "DATE_ASC"
		}
	}

	/**
	 * @function
	 *
	 * function representing tab changing.
	 * @param {Object} _event
	 * @param {Object} value
	 */
	handleChange = (_event, value) => {
		this.setState({ value: value, page: 0, filterStatus: "PENDING", });
	};


	/**This is a component life cycle method and used to assigning props values to state variable.*/
	static getDerivedStateFromProps(props, _state) {
		let users = props.requester_users
		let verifier = users.filter(x => x.userTypes.find(r => r.roleType.code === "MSP")).sort((a, b) => new Date(b.requested_date) - new Date(a.requested_date))
		let access_user = users.filter(x => x.userTypes.find(r => r.roleType.code === "VFR")).sort((a, b) => new Date(b.requested_date) - new Date(a.requested_date))
		return {
			all_users: props.requester_users,
			verifier: verifier,
			access_user: access_user
		};
	}

	/** This is a component life cycle method and used to fetch datas  */
	componentDidMount = () => {
		/** fetching user details. */
		this.props.dispatch(fetchAllRequestedUsers())
	};

	/**
	 * @function
	 *
	 * function representing close dialog.
	 *
	 */
	closeDialog = () => {
		this.setState({
			statusDialog: false,
			viewImageDialog: false,
			selectedStatus: "",
			selectedUser: null

		})
	};

	/**
	 * @function
	 *
	 * function representing table pagination.
	 * @param {Object} _event
	 * @param {Object} newPage
	 */
	handleChangePage = (_event, newPage) => {
		this.setState({
			page: newPage
		});
	};

	/**
	 * @function
	 *
	 * function representing table pagination row
	 * @param {Object} event
	 */
	handleChangeRowsPerPage = (event) => {
		this.setState({
			page: 0, rowsPerPage: parseInt(event.target.value, 10)
		});
	};

	/**
 * @function
 *
 * function representing open image dialog
 * @param {Object} item
 * @param {String} userType
 */
	openImageDialog = (item, userType) => {
		this.setState({ isLoading: true })
		setTimeout(() => { this.setState({ isLoading: false }) }, 3000)
		if (userType === "MSP") {
			this.setState({
				viewImageDialog: true,
				selectedUser: item,
			})
		} else {
			this.setState({
				viewImageDialog: true,
				selectedUser: item,
			})
		}
	};

	/**
	 * @function
	 *
	 * function representing open status dialog
	 * @param {String} status
	 */
	openStatusDialog = (status) => {
		this.setState({
			statusDialog: true,
			selectedStatus: status,
			viewImageDialog: false
		})
	};

	/**
	 * @function
	 *
	 * function representing update UserProfile details.
	 *
	 */
	updateUserProfile = () => {
		let data = {}
		data.status = this.state.selectedStatus;
		this.props.dispatch(updateStatusUserProfile(this.state.selectedUser.id, data))
		this.closeDialog()
	};

	/**
	 * @function
	 * This function representing verifier count.
	 * @param {String} value The value string
	 */
	verifierCount = (value) => {
		return this.state.verifier.filter(item => item.status === value).length
	};

	/**
	 * @function
	 * This function representing access user count.
	 * @param {String} value The value string
	 */
	accessUserCount = (value) => {
		return this.state.access_user.filter(item => item.status === value).length
	};

	/** rendering verifier requests and access user requests information. */
	render() {
		const { value, page, rowsPerPage, filterStatus } = this.state;
		let resultVerifier = this.state.verifier.length > 0 ? this.state.verifier.filter(item => item.status === filterStatus || (filterStatus === "ACTIVE" && item.status === "INACTIVE")) : []
		let resultAccessUser = this.state.access_user.length > 0 ? this.state.access_user.filter(item => item.status === filterStatus || (filterStatus === "ACTIVE" && item.status === "INACTIVE")) : []
		let sortResultVerifier = resultVerifier;
		switch (this.state.sortBy) {
			case "NAME_ASC":
				sortResultVerifier = resultVerifier.sort((a, b) => a.firstName.localeCompare(b.firstName))
				break;
			case "NAME_DSC":
				sortResultVerifier = resultVerifier.sort((a, b) => b.firstName.localeCompare(a.firstName))
				break;
			case "DATE_ASC":
				sortResultVerifier = resultVerifier.sort((a, b) => new Date(a.requested_date) - new Date(b.requested_date))
				break;
			case "DATE_DSC":
				sortResultVerifier = resultVerifier.sort((a, b) => new Date(b.requested_date) - new Date(a.requested_date))
				break;
			default:
				break;
		}
		let sortResultAccessUser = resultAccessUser;
		switch (this.state.sortBy) {
			case "NAME_ASC":
				sortResultAccessUser = resultAccessUser.sort((a, b) => a.firstName.localeCompare(b.firstName))
				break;
			case "NAME_DSC":
				sortResultAccessUser = resultAccessUser.sort((a, b) => b.firstName.localeCompare(a.firstName))
				break;
			case "DATE_ASC":
				sortResultAccessUser = resultAccessUser.sort((a, b) => new Date(a.requested_date) - new Date(b.requested_date))
				break;
			case "DATE_DSC":
				sortResultAccessUser = resultAccessUser.sort((a, b) => new Date(b.requested_date) - new Date(a.requested_date))
				break;
			default:
				break;
		}
		return (
			<div style={{ padding: 5 }}>
				<div>
					<Tabs
						value={value}
						onChange={this.handleChange}
						indicatorColor="primary"
						textColor="primary"
						fontWeight="900"
						style={{ margin: 0, width: 600, backgroundColor: 'white' }}
					>
						<Tab
							label={<span style={{ fontSize: "16", color: theme.palette.secondary.light, fontWeight: "bold" }}>Medical Service Provider Requests</span>}>
						</Tab>
						<Tab
							label={<span style={{ fontSize: "16", color: theme.palette.secondary.light, fontWeight: "bold" }}>Verifier Requests</span>}>
						</Tab>
					</Tabs>
					<Grid item xs={12} sm={12}>
						<Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
							<div>
								<Typography
									style={{ fontSize: "16", color: theme.palette.secondary.light, fontWeight: "bold" }}
								>{value === 0 ? "Medical Service Provider Requests" : "Verifier Requests"}</Typography>
							</div>
							<Badge
								color={"error"}
								onClick={() => this.setState({ filterStatus: 'PENDING', page: 0 })}
								// onDelete={() => null}
								badgeContent={value === 0 ? this.verifierCount('PENDING') : this.accessUserCount('PENDING')} max={999}
							>
								<Chip size="small" label={`PENDING`} clickable style={filterStatus === 'PENDING' ? { ...styles.activeChip, padding: "5px 15px", borderRadius: 8 } : { ...styles.inActiveChip, padding: "5px 15px", borderRadius: 8 }} />
							</Badge>
							<Badge
								color={"error"}
								onClick={() => this.setState({ filterStatus: 'REJECTED', page: 0 })}
								// onDelete={() => null}
								badgeContent={value === 0 ? this.verifierCount('REJECTED') : this.accessUserCount('REJECTED')} max={999}
							>
								<Chip size="small" label={`REJECTED`} clickable style={filterStatus === 'REJECTED' ? { ...styles.activeChip, padding: "5px 15px", borderRadius: 8 } : { ...styles.inActiveChip, padding: "5px 15px", borderRadius: 8 }} />
							</Badge>
							<Badge
								color={"error"}
								onClick={() => this.setState({ filterStatus: 'ACTIVE', page: 0 })}
								// onDelete={() => null}
								badgeContent={value === 0 ? this.verifierCount('ACTIVE') : this.accessUserCount('ACTIVE')} max={999}
							>
								<Chip size="small" label={`APPROVED`} clickable style={filterStatus === 'ACTIVE' ? { ...styles.activeChip, padding: "5px 15px", borderRadius: 8 } : { ...styles.inActiveChip, padding: "5px 15px", borderRadius: 8, }} />
							</Badge>
							<TablePagination
								rowsPerPageOptions={[20]}
								component="div"
								count={value === 0 ? this.verifierCount(this.state.filterStatus) : this.accessUserCount(this.state.filterStatus)}
								rowsPerPage={this.state.rowsPerPage}
								page={this.state.page}
								slotProps={{
									select: {
										inputProps: { 'aria-label': 'rows per page' },
										native: true,
									}
								}}
								onPageChange={this.handleChangePage}
								onRowsPerPageChange={this.handleChangeRowsPerPage}
								labelRowsPerPage={null}
								labelDisplayedRows={
									({ from, to, count }) => null
								}
								ActionsComponent={
									TablePaginationActions
								}
							/>
							<div style={{ display: "flex", padding: 10 }}>
							</div>
						</Toolbar>
					</Grid>
					<Grid item xs={8} sm={12} style={{ paddingTop: 0 }}>
						{value === 0 && <Table>
							<TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10 }}>
								<TableRow>
									<TableCell align="center" style={{ fontFamily: "Futura-Book", color: ' #fff', fontWeight: 900, padding: '14px 0px 14px 20px', display: "flex", justifyContent: "center", alignItems: "center" }}>
										<div style={{ paddingLeft: 5, display: "flex", alignItems: "center" }}>
											Name
											{/* <div style={{ display: "grid", cursor: "pointer" }}> */}
											<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_ASC" })} />
											<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_DSC" })} />
											{/* </div> */}
										</div>
									</TableCell>
									{/* <TableCell /> */}
									<TableCell align="center" style={styles.fontCellHeader}>
										<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
											Requested Date
											{/* <div style={{ display: "grid", cursor: "pointer" }}> */}
											<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "DATE_ASC" })} />
											<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "DATE_DSC" })} />
											{/* </div> */}
										</div>
									</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Status</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}> Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									sortResultVerifier.length > 0 ? sortResultVerifier.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) =>
										<TableRow key={item.id} style={{ backgroundColor: index % 2 === 0 ? "#fff" : "whitesmoke" }}>
											<TableCell align="center" size="small" style={{...styles.fontTableCellBody,fontWeight: "bold", fontSize: 15}}>
												{`${item.firstName} ${item.lastName}`}
											</TableCell>
											{/* <TableCell /> */}
											<TableCell align="center" style={styles.fontTableCellBody}>{item.requested_date !== null ? moment(item.requested_date, 'YYYY-MM-DD').format('MM-DD-YYYY') : "-"}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.status}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>
												<Button
													onClick={() => this.openImageDialog(item, "MSP")}
													type="Button"
													variant="contained"
													style={styles.viewButton}
												>
													View Information
												</Button>
											</TableCell>
										</TableRow>)
										:
										<TableRow>
											<TableCell colSpan={4}  style={{...styles.fontTableCellBody, textAlign:'center'}}>There are no medical service providers</TableCell>
										</TableRow>
								}
							</TableBody>
						</Table>}
					</Grid>
					<Grid item xs={8} sm={12} style={{ paddingTop: 0 }}>
						{value === 1 && <Table>
							<TableHead style={{ backgroundColor: theme.palette.secondary.dark, padding: 10 }}>
								<TableRow>
									<TableCell align="center" style={{ fontFamily: "Futura-Book", color: ' #fff', fontWeight: 900, padding: '14px 0px 14px 20px', display: "flex", justifyContent: "center", alignItems: "center" }}>
										<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
											Name
											{/* <div style={{ display: "grid", cursor: "pointer" }}> */}
											<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_ASC" })} />
											<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "NAME_DSC" })} />
											{/* </div> */}
										</div>
									</TableCell>
									{/* <TableCell /> */}
									<TableCell align="center" style={styles.fontCellHeader}>
										<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
											Requested Date
											{/* <div style={{ display: "grid", cursor: "pointer" }}> */}
											<ArrowDropUpIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "DATE_ASC" })} />
											<ArrowDropDownIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ sortBy: "DATE_DSC" })} />
											{/* </div> */}
										</div>
									</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Status</TableCell>
									<TableCell align="center" style={styles.fontCellHeader}>Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									sortResultAccessUser.length > 0 ? sortResultAccessUser.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, _index) =>
										<TableRow key={item.id}>
											<TableCell align="center" size="small" style={{...styles.fontTableCellBody,fontWeight: "bold", fontSize: 15}}>
												 {`${item.firstName} ${item.lastName}`}
											</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.requested_date !== null ? moment(item.requested_date, 'YYYY-MM-DD').format('MM-DD-YYYY') : "-"}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>{item.status}</TableCell>
											<TableCell align="center" style={styles.fontTableCellBody}>
												<Button
													onClick={() => this.openImageDialog(item, "VFR")}
													type="Button"
													variant="contained"
													style={styles.viewButton}
												>
													View Information
												</Button>
											</TableCell>
										</TableRow>)
										:
										<TableRow>
											<TableCell colSpan={4} style={{...styles.fontTableCellBody, textAlign:'center'}}>There are no verifier users</TableCell>
										</TableRow>
								}
							</TableBody>
						</Table>}
					</Grid>
				</div>
				<Dialog
					onClose={() => this.closeDialog()}
					aria-labelledby="simple-dialog-title"
					maxWidth="xs"
					open={this.state.statusDialog}>
					<DialogContent>
						<Typography>
							Are you sure you want to {this.state.selectedStatus === "REJECTED" ? "reject" : "approve"} this user?
						</Typography>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => this.closeDialog()}
							type="Button"
							variant="contained"
							style={styles.rejectButton}
						>
							No
						</Button>
						<Button
							onClick={() => this.updateUserProfile()}
							type="Button"
							variant="contained"
							style={styles.acceptButton}
						>
							Yes
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					onClose={() => this.closeDialog()}
					aria-labelledby="simple-dialog-title"
					maxWidth="md"
					open={this.state.viewImageDialog}>
					<DialogTitle style={{ backgroundImage: `linear-gradient(to bottom right,${theme.palette.primary.main}, ${theme.palette.primary.dark})`, boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)", padding: 0, marginBottom: 20, }}>
						<div style={styles.dialogTitle}>
							<Typography style={styles.dialogTitleText}
							//  style={{ padding: "1%", fontFamily: "Futura-Heavy", color: "#fff", fontWeight: "bold" }}
							>
								Verify Document
							</Typography>
							<CloseIcon onClick={() => this.closeDialog()} color="secondary" fontSize="large" style={{ fontWeight: 'bold', marginRight: 10, cursor: "pointer", color: "#fff" }} />
						</div>
					</DialogTitle>
					<DialogContent style={{ backgroundColor: "#fff", marginBottom: 20 }}>
						<Grid container spacing={2}>
							<Grid container style={{ paddingLeft: 20, paddingRight: 20, marginTop: "20px" }}>
								<Grid item xs={12}>
									<Typography style={{ fontSize: "16", color: "#20478E", fontWeight: "bold" }}>{value === 0 ? "Medical Service Provider Information" : "Verifier Information"}</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>First Name</Typography>
									<Typography style={{ fontSize: 15, }}>{_.isEmpty(this.state.selectedUser) !== true && this.state.selectedUser.firstName}</Typography>
									<Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>Last Name</Typography>
									<Typography style={{ fontSize: 15, }}>{_.isEmpty(this.state.selectedUser) !== true && this.state.selectedUser.lastName}</Typography>
									<Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>Phone Number</Typography>
									<Typography style={{ fontSize: 15, }}>{_.isEmpty(this.state.selectedUser) !== true && this.state.selectedUser.phone}</Typography>
									<Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>Email</Typography>
									<Typography style={{ fontSize: 15, }}>{_.isEmpty(this.state.selectedUser) !== true && this.state.selectedUser.email}</Typography>
									<Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>Date of Birth</Typography>
									<Typography style={{ fontSize: 15, }}>{_.isEmpty(this.state.selectedUser) !== true && moment(this.state.selectedUser.dateOfBirth).format('LL')}</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>Gender</Typography>
									<Typography style={{ fontSize: 15, }}>{_.isEmpty(this.state.selectedUser) !== true && this.state.selectedUser.Gender}</Typography>
									<Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>Zipcode</Typography>
									<Typography style={{ fontSize: 15, }}>{_.isEmpty(this.state.selectedUser) !== true && this.state.selectedUser.zipCode}</Typography>
									<Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>Professional ID Number (NPI)</Typography>
									<Typography style={{ fontSize: 15, }}>{_.isEmpty(this.state.selectedUser) !== true && this.state.selectedUser.externalId}</Typography>
									<Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>Role</Typography>
									<Typography style={{ fontSize: 15, }}>{_.isEmpty(this.state.selectedUser) !== true && this.state.selectedUser.role}</Typography>
									<Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>Organization</Typography>
									<Typography style={{ fontSize: 15, }}>{_.isEmpty(this.state.selectedUser) !== true && this.state.selectedUser.organization}</Typography>
								</Grid>
								<Grid item xs={4} >
									<Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>Profile Image</Typography>
									{this.state.selectedUser !== null && this.state.selectedUser.imageStorageKey !== null ?
										<div style={{ paddingTop: 10, }}>
											<img alt="selfie"
												src={`${END_POINT}image/i/${this.state.selectedUser.imageStorageKey}`}
												style={{
													height: "150px", width: "70%", border: "1px solid #ddd",
													borderRadius: "4px",
													padding: "8px",
													imageOrientation: "from-image"
												}}
											/>
										</div> :
										<div style={{ paddingTop: 10, }}>
											<img alt="selfie"
												src={NoImage}
												style={{
													height: "150px", width: "70%", border: "1px solid #ddd",
													borderRadius: "4px",
													padding: "8px",
													imageOrientation: "from-image"
												}}
											/>
										</div>
									}
									{_.isEmpty(this.state.selectedUser) !== true && this.state.selectedUser.externalId !== null &&
										<div>
											{/* <Typography style={{ fontSize: 15, fontWeight: "bold", paddingTop: 10, }}>Professional ID Number</Typography>
											<Typography style={{ fontSize: 15, }}>{_.isEmpty(this.state.selectedUser) !== true && this.state.selectedUser.externalId}</Typography> */}
										</div>}
								</Grid>
							</Grid>
							<Grid item xs={12} style={{ paddingLeft: 20, paddingRight: 20 }} >
								<Typography style={{ paddingBottom: 20, fontSize: "16", color: "#20478E", fontWeight: "bold" }}>Document</Typography>
								<div style={{ marginBottom: "20px", height: '300px' }}>
									{this.state.selectedUser !== null && this.state.selectedUser.docStorageKey !== null ?
										<TransformWrapper>
											<TransformComponent>
												<img alt="document"
													src={`${END_POINT}image/d/${this.state.selectedUser.docStorageKey}`}
													style={{
														height: "100%", width: "100%", border: "1px solid #ddd",
														borderRadius: "4px",
														padding: "8px"
													}}
												/>
											</TransformComponent>
										</TransformWrapper>
										: <img alt="document"
											src={NoImage}
											style={{
												height: "100%", width: "100%", border: "1px solid #ddd",
												borderRadius: "4px",
												padding: "8px"
											}}
										/>
									}
								</div>
							</Grid>
						</Grid>
					</DialogContent>
					<Grid item xs={12} >
						{this.state.selectedUser !== null && (this.state.selectedUser.status === "PENDING" || this.state.selectedUser.status === "REJECTED") &&
							<div style={styles.buttonToolbar}>
								{this.state.selectedUser.status !== "REJECTED" &&
									<Button
										onClick={() => this.openStatusDialog("REJECTED")}
										type="Button"
										variant="contained"
										style={styles.rejectButton}
									>
										Reject
									</Button>}
								<Button
									onClick={() => this.openStatusDialog("ACTIVE")}
									type="Button"
									variant="contained"
									style={styles.acceptButton}
								>
									Approve
								</Button>
							</div>
						}
					</Grid>
				</Dialog>
			</div>
		);
	}
};

/** Get data from store and assign to props. */
const mapStateToProps = (state) => {
	return {
		requester_users: state.userReducer.requester_users
	};
};

Home.propTypes = {
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(Home));