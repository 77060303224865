import React, { Component } from "react";
import { Typography, Grid, TextField, CircularProgress, Dialog } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchUserProfile, uploadChangeUserDocument } from "../../actions/userAction"
import IdCard from "../../assets/images/idCard.svg";
import { withRouter } from "../../withRouter";
import theme from "../../theme";
import { THEMECOLOR } from "../../helpers/Constants";

const styles = {
	paper: {
		boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.2)",
		// backgroundColor: "#20478E",
		backgroundImage: `linear-gradient(to bottom right,${THEMECOLOR.SECONDARY}, ${THEMECOLOR.PRIMARY})`,
		height: "100vh",
		padding: 20,
		paddingBottom: 100,
	},
	dialogPaper: {
		backgroundColor: "rgba(0,0,0,0)",
		boxShadow: "none",
		overflow: "hidden"
	}
};

class ProfessionalInformation extends Component {
	// static contextTypes = {
	// 	router: PropTypes.object,
	// };
	constructor() {
		super();
		this.state = {
			openTestList: false,
			testtypes: [],
			user_profile: null,
			userType: null,
			role: null,
			employer: null,
			id_number: null,
			isLoading: false,
			mode: ""
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (props.user_profile !== state.user_profile) {
			return {
				user_profile: props.user_profile,
				userType: props.user_profile !== undefined ? props.user_profile.userType : null
			}
		}
		return null;
	}

	componentDidMount() {
		//let param = _.isEmpty(this.props.history.location.state) !== true ? this.props.history.location.state.skip : ""
		let param = ""
		this.setState({ mode: param })
		this.props.dispatch(fetchUserProfile())
	}

	submit = () => {
		let { role, employer, id_number } = this.state;
		let isError = false;
		if (role === "" || role === null) {
			this.setState({ role_error: true });
			isError = true;
		}
		if (employer === "" || employer === null) {
			this.setState({ employer_error: true });
			isError = true;
		}
		if (this.props.selected_user_type === "MSP") {
			if (id_number === "" || id_number === null) {
				this.setState({ id_number_error: true });
				isError = true;
			}
		}

		if (isError === false) {
			this.setState({ isLoading: true })
			var data = {};
			data.role = role
			data.organization = employer;
			data.externalId = id_number;
			this.props.dispatch(uploadChangeUserDocument(this, data));
		}

	}


	render() {
		const { 
			// styles, 
			selected_professional_image } = this.props;

		return (
			<div style={{ backgroundColor: "#fff", height: "100vh" }}>
				<Grid container justifyContent="center">
					<Grid iem xs={11} sm={4}>
						<Grid item xs={10} sm={10} style={{ textAlign: "center" }}>
							{this.state.mode === "skip" ?
								<img src={IdCard} style={{ width: "100%", borderRadius: "5px", margin: 25, }} alt="icon" /> :
								<img src={selected_professional_image} style={{ width: "100%", borderRadius: "5px", margin: 25 }} alt="icon" />
							}
						</Grid>
						<Grid item xs={12} style={{ marginTop: "10vh" }}>
							<Grid container>
								<TextField
									id="role"
									label="Role/Title"
									name="role"
									style={styles.margin}
									value={this.state.role}
									onChange={(event) => this.setState({ role: event.target.value, role_error: false })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										styles: { input: styles.inputStyle },
									}}

									fullWidth
									error={this.state.role_error === true ? true : false}
									helperText={this.state.role_error === true ? "Please enter role name" : false}
								/>
								<TextField
									id="employer"
									label="Current Organization/Employer"
									name="employer"
									style={styles.margin}
									value={this.state.employer}
									onChange={(event) => this.setState({ employer: event.target.value, employer_error: false })}
									margin="normal"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										styles: { input: styles.inputStyle },
									}}

									fullWidth
									error={this.state.employer_error === true ? true : false}
									helperText={this.state.employer_error === true ? "Please enter current organization/employer name" : false}
								/>
								{this.props.selected_user_type !== null && this.props.selected_user_type === "MSP" &&
									<TextField
										id="id_number"
										label="Medical/Professional ID Number"
										name="id_number"
										style={styles.margin}
										value={this.state.id_number}
										onChange={(event) => this.setState({ id_number: event.target.value, id_number_error: false })}
										margin="normal"
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											styles: { input: styles.inputStyle },
										}}

										fullWidth
										error={this.state.id_number_error === true ? true : false}
										helperText={this.state.id_number_error === true ? "Please enter medical/professional ID number" : false}
									/>
								}
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							style={{
								dispaly: "flex",
								flexDirection: "column",
								textAlign: "center",
								justifyContent: "center",
								marginTop: this.props.selected_user_type === "MSP" ? "6vh" : "15vh",

							}}
						>
							<Typography
								onClick={() => this.submit()}
								style={{
									color: "#FFFFFF",
									padding: "15px",
									fontSize: "18px",
									textDecoration: "none",
									backgroundColor: theme.palette.primary.main,
									borderRadius: "5px",
									margin: "0px 0 10px 0",
									cursor: "pointer"
								}}
							>
								Finish
							</Typography>
							{this.state.mode !== "skip" &&
								<Typography
									onClick={() => this.props.navigate("/open-camera")}
									style={{
										color: "#FFFFFF",
										padding: "15px",
										fontSize: "18px",
										textDecoration: "none",
										backgroundColor: theme.palette.primary.main,
										borderRadius: "5px",
										margin: "10px 0 0 0",
										cursor: "pointer"
									}}
								>
									Retake Document
								</Typography>}

						</Grid>
					</Grid>
				</Grid>
				<Dialog open={this.state.isLoading}
					styles={{
						paper: styles.dialogPaper,
					}}
				>
					<div style={{ boxShadow: 0, overflow: "none" }}>
						<CircularProgress color="primary" size={40} />
					</div>
				</Dialog>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user_type: state.sessionReducer.user_type,
		selected_user_type: state.userReducer.selected_user_type,
		selected_professional_image: state.userReducer.selected_professional_image,
	};
};

ProfessionalInformation.propTypes = {
	styles: PropTypes.object.isRequired,
	navigate: PropTypes.func.isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(ProfessionalInformation));
