import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Snackbar, Alert } from "@mui/material";
import { ALERT_DETAIL } from "../../actions/actionTypes"
import { Outlet } from 'react-router-dom';

const styles ={
	root: {
		// paddingTop: 64,
		height: '100%'
	},
	content: {
		height: '100%'
	}
};


class Minimal extends Component {
	// static contextTypes = {
	// 	router: PropTypes.object,
	// }

	render() {
		
		return (
			<div style={styles.root}>
				<Snackbar
					open={this.props.openAlert}
					onClose={() => this.props.dispatch({ type: ALERT_DETAIL, data: false, message: "", severity: "" })}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={3000} >
					<Alert icon={false} variant="filled" severity={this.props.alertSeverity} >{this.props.alertMessage}</Alert>
				</Snackbar>
				<Outlet />
			</div>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		openAlert: state.sessionReducer.openAlert,
		alertSeverity: state.sessionReducer.alertSeverity,
		alertMessage: state.sessionReducer.alertMessage
	};
};


Minimal.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string
};

export default connect(mapStateToProps)(Minimal);